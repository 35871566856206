import { Response } from '@Src/_basic/object/ApiType';
import {
  GetTestInDistrictParams,
  TestInDistrictArea,
  TestInDistrictDetial,
  TestInDistrictFactory,
} from '@Src/_basic/object/TestInDistrictType';
import { Api } from '../Api';
import { ExptTestInDistrictProtocol } from './ExptTestInDistrictProtocol';

export class ExptTestInDistrictApi {
  public static getExptTestInDistrictByArea(
    params: GetTestInDistrictParams,
    onSuccess?: (data: Array<TestInDistrictArea>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ExptTestInDistrictProtocol.GET_EXPT_TESTINDISTRICT_BY_AREA, config, onSuccess, onFailed, onFinally);
  }
  public static getExptTestInDistrictByFactory(
    params: GetTestInDistrictParams,
    onSuccess?: (data: Array<TestInDistrictFactory>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ExptTestInDistrictProtocol.GET_EXPT_TESTINDISTRICT_BY_FACTORY, config, onSuccess, onFailed, onFinally);
  }
  public static getPaginationReqTestInDistrictFactory(
    params: GetTestInDistrictParams,
    onSuccess?: (data: TestInDistrictDetial) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(
      ExptTestInDistrictProtocol.GET_PAGINATION_REQ_TESTINDISTRICT_FACTORY,
      config,
      onSuccess,
      onFailed,
      onFinally,
    );
  }
}
