import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function EyeClose(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="42" height="25" viewBox="0 0 42 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.82274 0.171884C2.75674 -0.259194 3.86336 0.148506 4.29444 1.08251C11.0789 15.7823 31.3795 15.7823 38.164 1.08251C38.5951 0.148506 39.7017 -0.259194 40.6357 0.171884C41.5697 0.602961 41.9774 1.70958 41.5463 2.64358C33.4301 20.2288 9.02838 20.2288 0.912118 2.64358C0.48104 1.70958 0.888741 0.602961 1.82274 0.171884Z"
        fill="#003087"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0938 8.59624C32.9252 7.99037 34.0902 8.17314 34.6961 9.00447L41.3612 18.1498C41.9671 18.9811 41.7843 20.1462 40.953 20.7521C40.1217 21.358 38.9566 21.1752 38.3507 20.3439L31.6856 11.1985C31.0797 10.3672 31.2625 9.20211 32.0938 8.59624Z"
        fill="#003087"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2298 12.1074C22.2585 12.1074 23.0924 12.9413 23.0924 13.97V22.9726C23.0924 24.0012 22.2585 24.8352 21.2298 24.8352C20.2011 24.8352 19.3672 24.0012 19.3672 22.9726V13.97C19.3672 12.9413 20.2011 12.1074 21.2298 12.1074Z"
        fill="#003087"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3652 8.59625C11.1965 9.20212 11.3793 10.3672 10.7734 11.1985L4.10821 20.3439C3.50233 21.1752 2.33725 21.358 1.50592 20.7521C0.674598 20.1462 0.491835 18.9811 1.09771 18.1498L7.76289 9.00446C8.36877 8.17313 9.53385 7.99037 10.3652 8.59625Z"
        fill="#003087"
      />
    </SvgIcon>
  );
}
