import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FactoryData } from '@Src/_basic/object/FactoryType';

interface initialState {
  targetId: number;
  factoryData: FactoryData | any;
  factoryUpdateData: FactoryData | any;
  activeFactoryData: FactoryData | any;
}
export const factorySlice = createSlice({
  name: 'factorySlice',
  initialState: {
    targetId: 0,
    factoryData: [],
    factoryUpdateData: [],
    activeFactoryData: [],
  } as initialState,
  reducers: {
    setTargetId: (state, action: PayloadAction<number>) => {
      state.targetId = action.payload;
    },
    setFactoryData: (state, action: PayloadAction<FactoryData>) => {
      state.factoryData = action.payload;
    },
    setFactoryUpdateData: (state, action: PayloadAction<FactoryData>) => {
      state.factoryUpdateData = action.payload;
    },
    setActiveFactoryData: (state, action: PayloadAction<FactoryData>) => {
      state.activeFactoryData = action.payload;
    },
  },
});
