import React, { useEffect, useState } from 'react';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import { useTranslation } from 'react-i18next';
import { Options } from '@Src/_basic/components/YatungFormSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungButton from '@Src/_basic/components/YatungButton';
import { StationsData } from '@Src/_basic/object/SiteType';
import YatungDivider from '@Src/_basic/components/YatungDivider';

interface Props {
  index: number;
  factoryBranchCode: string;
  value: StationsData;
  setCreateGuardhouse: (e: StationsData[]) => void;
  createGuardhouse: StationsData[];
  stationsOption: Options[];
}
export default function CreateGuardhouseForm(props: Props) {
  const { index, value, setCreateGuardhouse, createGuardhouse, factoryBranchCode, stationsOption } = props;
  const { t: i18T } = useTranslation();
  const [stationTypeName, setStationTypeName] = useState<string | undefined>('');
  const [inputValues, setInputValues] = useState<string>('');
  const [passWordInput, setPassWordInput] = useState<string>('');

  const getTypeId = () => {
    const typeId = stationsOption.filter((data: Options) => data.value === value.stationTypeId);
    const code = typeId.map((data: Options) => {
      return data.code;
    });
    setStationTypeName(code[0]);
  };
  useEffect(() => {
    getTypeId();
  }, []);
  const handleInput = (e: SelectChangeEvent) => {
    setInputValues(e.target.value);
  };
  const handlePwInput = (e: SelectChangeEvent) => {
    setPassWordInput(e.target.value);
  };

  const getInputValue = () => {
    const updatedArray = createGuardhouse.map((data: StationsData, i: number) => {
      if (data.id === value.id) {
        return { ...data, stationName: inputValues };
      }
      return data;
    });
    setCreateGuardhouse(updatedArray);
  };
  useEffect(() => {
    getInputValue();
  }, [inputValues]);
  const getPassWordInput = () => {
    const updatedArray = createGuardhouse.map((data: StationsData, i: number) => {
      if (data.id === value.id) {
        return { ...data, password: passWordInput };
      }
      return data;
    });
    setCreateGuardhouse(updatedArray);
  };
  useEffect(() => {
    getPassWordInput();
  }, [passWordInput]);

  const getRemoveData = () => {
    const filteredGuardhouse = createGuardhouse.filter((v: StationsData) => v.id !== value.id);
    setCreateGuardhouse(filteredGuardhouse);
  };

  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.SITES')} />
        </Grid>
        <Grid container item xs={10}>
          <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>
            {i18T('SITEMANAGEMENT.GUARDHOUSE')}
          </Typography>
        </Grid>
        <Grid container item xs={1} justifyContent={'flex-start'}>
          <YatungButton
            color="red"
            text={'刪除'}
            onClick={() => {
              getRemoveData();
            }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.STATION_NAME')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungInput sx={{ width: '930px' }} onBlur={handleInput} defaultValue={value.stationName} type="text" />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.SITE_TYPE')} />
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{stationTypeName}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.SITE_NUMBER')} />
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{index + 1}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.ACCOUNT')} />
        </Grid>
        <Grid container item xs={11}>
          <Typography
            sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}
          >{`${factoryBranchCode}_${stationTypeName}_${index + 1}`}</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ my: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.PASSWORD')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungInput sx={{ width: '930px' }} onBlur={handlePwInput} defaultValue={value.password} type="password" />
        </Grid>
      </Grid>
      <YatungDivider />
    </>
  );
}
