export function entityToRequestConverter(legalStandard: any) {
  return {
    ...legalStandard,
    factoryIds: getFactoryIds(legalStandard),
    specifications: getSpecifications(legalStandard),
  };
}

function getFactoryIds(legalStandard: any) {
  return legalStandard.factories.map((factory: any) => factory.id);
}

function getSpecifications(legalStandard: any) {
  return legalStandard.specifications.map((specification: any) => {
    return {
      id: specification.id,
      sourceIds: getSourceIds(specification),
      specificationIds: getSpecificationIds(specification),
      details: getDetails(specification),
    };
  });
}

function getSourceIds(specification: any) {
  return specification.sources.map((source: any) => source.id);
}

function getSpecificationIds(specification: any) {
  return specification.specifications.map((specificationItem: any) => specificationItem.id);
}

function getDetails(specification: any) {
  return specification.details.map((detail: any) => {
    return {
      ...detail,
      experimentDetectionItemId: detail.experimentDetectionItem.id,
      notificationPriorityId: detail.notificationPriority.id,
    };
  });
}

export function getMappedSpecificationDetail(detectionItems: any, details: any) {
  const oldDetailsMap: Map<number, any> = new Map();
  details.forEach((detail: any) => {
    detail.type < 2 &&
      oldDetailsMap.set(detail.experimentDetectionItem?.id || detail.experimentDetectionItemId, detail);
  });
  const oldDetailsMap2: Map<number, any> = new Map();
  details.forEach((detail: any) => {
    detail.type > 1 &&
      oldDetailsMap2.set(detail.experimentDetectionItem?.id || detail.experimentDetectionItemId, detail);
  });

  return detectionItems.map((detectionItem: any) => {
    return {
      ...detectionItem,
      notificationPriorityId:
        oldDetailsMap.get(detectionItem.id)?.notificationPriorityId ||
        oldDetailsMap.get(detectionItem.id)?.notificationPriority?.id ||
        0,
      notificationPriorityId2:
        oldDetailsMap2.get(detectionItem.id)?.notificationPriorityId ||
        oldDetailsMap2.get(detectionItem.id)?.notificationPriority?.id ||
        0,
      minValue: oldDetailsMap.get(detectionItem.id)?.minValue,
      maxValue: oldDetailsMap.get(detectionItem.id)?.maxValue,
      minValue2: oldDetailsMap2.get(detectionItem.id)?.minValue,
      maxValue2: oldDetailsMap2.get(detectionItem.id)?.maxValue,
      rejectMinValue: oldDetailsMap.get(detectionItem.id)?.rejectMinValue,
      rejectMaxValue: oldDetailsMap.get(detectionItem.id)?.rejectMaxValue,
      type: oldDetailsMap.get(detectionItem.id)?.type,
      type2: oldDetailsMap2.get(detectionItem.id)?.type,
    };
  });
}
