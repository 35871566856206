import YatungButton from '@Src/_basic/components/YatungButton';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  handleCloseModal: () => void;
}
export default function VehicleManagementCreateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { handleCloseModal } = props;

  return (
    <Stack flexDirection={'row'} justifyContent={'flex-end'} sx={{ mt: 3 }}>
      <YatungButton
        text={i18T('GLOBAL.CANCEL')}
        color="red"
        variant="contained"
        size="medium"
        onClick={handleCloseModal}
        sx={{
          fontSize: '18px',
          minWidth: '10%',
          mr: 1,
        }}
      />
      <YatungButton
        text={i18T('GLOBAL.FINISH')}
        color="blue"
        variant="contained"
        size="medium"
        type="submit"
        sx={{
          fontSize: '18px',
          minWidth: '10%',
          mr: 1,
        }}
      />
    </Stack>
  );
}
