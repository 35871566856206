import {
  ChildrenTypography,
  TestCardContainer,
  TestVerifyResultStyles,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import FineSieveLineChart from '@Src/_basic/components/YatungTest/FineSieveLineChart';
import TabCoarseSieveLineChart from '@Src/_basic/components/YatungTest/TabCoarseSieveLineChart';
import TestDetailDialog from '@Src/_basic/components/YatungTest/testDetail/TestDetailDialog';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import BillingIcon from '@Src/_basic/icons/Billing';
import SearchIcon from '@Src/_basic/icons/Search';
import { TestItemEnum } from '@Src/_basic/object/TestType';
import { FineSieve } from '@Src/_basic/object/test/sandRock/FineSieveType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisReportDialog from './AnalysisReportDialog';

interface Props {
  children: React.ReactNode;
  tester: string;
  exptId: number;
  exptId1?: number | null;
  exptId2?: number | null;
  exptItemCode: TestItemEnum;
  verifyPassedResults: any;
  verifyCustomResults: any;
  StackMode?: 'type_1' | 'type_2';
  ratio?: number;
}

function ReportBlock({
  children,
  tester,
  exptId,
  exptItemCode,
  verifyPassedResults,
  verifyCustomResults,
  ratio,
  StackMode,
  exptId1,
  exptId2,
}: Props) {
  const { t: i18T } = useTranslation();
  const [open, setOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [openLineChart, setOpenLineChart] = useState<boolean>(false);

  const [testFormS1, setTestFormS1] = useState<FineSieve>();
  const [testFormS2, setTestFormS2] = useState<FineSieve>();

  const isLegalPassed = useMemo(() => {
    if (StackMode) {
      switch (StackMode) {
        case 'type_1':
          return isLegalStandardPassed(verifyPassedResults?.type1);
        case 'type_2':
          return isLegalStandardPassed(verifyPassedResults?.type2);
      }
    } else if (verifyPassedResults) return isLegalStandardPassed(verifyPassedResults);
  }, [verifyPassedResults, StackMode]);

  const isCustomPassed = useMemo(() => {
    if (StackMode) {
      switch (StackMode) {
        case 'type_1':
          return isLegalStandardPassed(verifyCustomResults?.type1);
        case 'type_2':
          return isLegalStandardPassed(verifyCustomResults?.type2);
      }
    } else if (verifyCustomResults) return isCustomStandardPassed(verifyCustomResults);
  }, [verifyCustomResults, StackMode]);

  const isCustomRejected = useMemo(() => {
    return isRejected(verifyCustomResults);
  }, [verifyCustomResults]);

  const verifyResultText: Array<string> = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  useEffect(() => {
    if (
      (exptItemCode === TestItemEnum.FINE_AGG_SIEVE_ANALYSIS ||
        exptItemCode === TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS) &&
      !exptId2
    ) {
      TestApi.getTestDetail({ exptItemCode: exptItemCode as TestItemEnum, exptId: Number(exptId) }, (data) =>
        setTestFormS1(data),
      );
      return;
    }

    if (!exptId1 || !exptId2 || !exptItemCode) return;
    TestApi.getTestDetail({ exptItemCode: exptItemCode as TestItemEnum, exptId: Number(exptId1) }, (data) =>
      setTestFormS1(data),
    );
    TestApi.getTestDetail({ exptItemCode: exptItemCode as TestItemEnum, exptId: Number(exptId2) }, (data) =>
      setTestFormS2(data),
    );
  }, [exptId1, exptId2, exptItemCode, exptId]);

  return (
    <TestCardContainer sx={exptItemCode === TestItemEnum.CONCRETE_COMP_STR_TEST ? { width: '100%', padding: 5 } : {}}>
      {openLineChart ? (
        testFormS1 && (
          <Stack sx={{ height: '600px', position: 'relative' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenLineChart(false)}
              aria-label="close"
              sx={{
                position: 'absolute',
                left: -10,
                top: 10,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CloseIcon />
            </IconButton>
            {exptItemCode === TestItemEnum.FINE_AGG_SIEVE_ANALYSIS ? (
              testFormS2 && ratio ? (
                <FineSieveLineChart testFormS1={testFormS1} testFormS2={testFormS2} ratio={ratio / 100} />
              ) : (
                <FineSieveLineChart testFormS1={testFormS1} />
              )
            ) : testFormS2 && ratio ? (
              <TabCoarseSieveLineChart testFormS1={testFormS1} testFormS2={testFormS2} ratio={ratio / 100} />
            ) : (
              <TabCoarseSieveLineChart testFormS1={testFormS1} />
            )}
          </Stack>
        )
      ) : (
        <>
          <Stack>{children}</Stack>
          <Stack flexDirection="row" pt={3} pb={1}>
            <Stack flex={1} gap={2}>
              <Stack flexDirection="row" justifyContent="space-between" sx={{ position: 'relative' }}>
                <ChildrenTypography style={{ flex: 0.5 }}>檢測結果: </ChildrenTypography>
                {verifyResultText && (
                  <ChildrenTypography
                    style={isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal}
                  >
                    {verifyResultText[0]}
                  </ChildrenTypography>
                )}
                <Stack flexDirection="row" sx={{ position: 'absolute', right: 0, top: -10 }}>
                  {testFormS1 && (
                    <Button
                      sx={{ bgcolor: '#ccc', borderRadius: 30, ':hover': { bgcolor: '#333' } }}
                      onClick={() => setOpenLineChart(true)}
                    >
                      <BillingIcon />
                    </Button>
                  )}
                  <Button
                    sx={{ color: '#000', borderRadius: 30 }}
                    onClick={() => {
                      if (exptId1 && exptId2 && ratio !== undefined) {
                        setReportOpen(true);
                      }
                      // navigate(`/report/sieve-report/${exptId1}/${exptId2}/${exptItemCode}`, {
                      //   state: { _ratio: ratio / 100 },
                      // });
                      else setOpen(true);
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Stack>
              </Stack>

              <Stack flexDirection="row">
                <ChildrenTypography style={{ flex: 0.5 }}>合約規範: </ChildrenTypography>
                {verifyResultText && (
                  <ChildrenTypography
                    style={
                      isCustomPassed && !isCustomRejected
                        ? TestVerifyResultStyles.normal
                        : TestVerifyResultStyles.abnormal
                    }
                  >
                    {verifyResultText[1] ?? verifyResultText[0]}
                  </ChildrenTypography>
                )}
              </Stack>
              <Stack flexDirection="row">
                <ChildrenTypography style={{ flex: 0.5 }}>{`${i18T(
                  'MONTHLY_REPORT.BASE.TESTER',
                )}:`}</ChildrenTypography>
                <ChildrenTypography>{tester}</ChildrenTypography>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}

      <TestDetailDialog exptItemCode={exptItemCode} exptId={exptId} open={open} closeHandler={() => setOpen(false)} />
      <AnalysisReportDialog
        form1Id={exptId1 as number}
        form2Id={exptId2 as number}
        _ratio={(ratio as number) / 100}
        exptItemCode={exptItemCode}
        open={reportOpen}
        closeHandler={() => setReportOpen(false)}
      />
    </TestCardContainer>
  );
}

export default memo(ReportBlock);
