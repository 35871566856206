import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import { TextOptions } from '@Src/_basic/components/YatungTextSelect';
import YutungButtonAlert from '@Src/_basic/components/YutungButtonAlert';
import AddEquipment from '@Src/_basic/icons/AddEquipment';
import ViewQR from '@Src/_basic/icons/ViewQR';
import { EquipmentData, FileData, UpdateEquipmentRequest } from '@Src/_basic/object/EquipmentManagementType';
import { EquipmentApi } from '@Src/_basic/protocol/equipment/EquipmentApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useEquipment } from '@Src/redux/equipment/equipmentAction';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import QRCodeCanvas from 'qrcode.react';
import React, { createContext, useCallback, useContext, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import EquipmentUpdateField from './component/EquipmentUpdateField';

interface Props {
  initialUpdtaeEquipValue: UpdateEquipmentRequest;
  updateModalOpen: boolean;
  onUpdateGoBackButtonClick: () => void;
  userGroupsData: any;
  entityOptions: TextOptions[];
  updateFactory: number | undefined;
  setUpdateFactory: (e: number) => void;
  updatePrioedNumber: number;
  setUpdatePrioedNumber: (e: number) => void;
  updatePrioedUnit: string;
  setUpdtaePrioedUnit: (e: string) => void;
  updateKeeper: string | undefined;
  setUpdateKeeper: (e: string) => void;
  updateNeedMaintenance: boolean | undefined;
  setUpdateNeedMaintenance: (e: boolean) => void;
  equipmentByIdData: EquipmentData | undefined;
  refreshUploadFileData: () => void;
  getEquipmentData: () => void;
  fileName: FileData[];
}

export type UpdateEquipmentUIEvents = {
  formData: UpdateEquipmentRequest;
  formDataDispatch: React.Dispatch<any>;
  clearFormData: () => void;
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'updateValue':
      return { ...state, ...action.payload };
    default:
      return { state };
  }
}

const UpdateEquipmentUIContent = createContext<Partial<UpdateEquipmentUIEvents>>({});

export function useUpdateEquipmentUI() {
  return useContext(UpdateEquipmentUIContent);
}

export const UpdateEquipmentUIConsumer = UpdateEquipmentUIContent.Consumer;

export default function EquipmentUpdateCard(props: Props) {
  const {
    initialUpdtaeEquipValue,
    updateModalOpen,
    onUpdateGoBackButtonClick,
    userGroupsData,
    entityOptions,
    equipmentByIdData,
    refreshUploadFileData,
    updateFactory,
    setUpdateFactory,
    updatePrioedNumber,
    setUpdatePrioedNumber,
    updatePrioedUnit,
    setUpdtaePrioedUnit,
    updateKeeper,
    setUpdateKeeper,
    updateNeedMaintenance,
    setUpdateNeedMaintenance,
    getEquipmentData,
    fileName,
  } = props;

  const [loading, setLoading] = useState(false);
  const [formData, formDataDispatch] = useReducer(reducer, initialUpdtaeEquipValue);
  const [files, setFiles] = useState<FileList>();
  const { uploadEquipmentReports } = useEquipment();
  const { userData } = useAuth();

  const clearFormData = () => {
    formDataDispatch({ type: 'updateValue', payload: { ...initialUpdtaeEquipValue } });
  };

  const value = { loading, setLoading, formData, formDataDispatch, clearFormData };

  const jsonFile = {
    id: equipmentByIdData?.id,
    name: equipmentByIdData?.name,
    propertyCode: equipmentByIdData?.propertyCode,
    lastMaintenanceTime: moment(equipmentByIdData?.lastMaintenanceTime).format('YYYY/MM/DD HH:mm'),
    nextMaintenanceTime: moment(equipmentByIdData?.nextMaintenanceTime).format('YYYY/MM/DD HH:mm'),
    notificationTime: moment(equipmentByIdData?.notificationTime).format('YYYY/MM/DD HH:mm'),
    periodNumber: equipmentByIdData?.periodNumber,
    periodUnit: equipmentByIdData?.periodUnit,
    keeper: equipmentByIdData?.keeperName,
    factoryId: equipmentByIdData?.factoryId,
    factoryName: equipmentByIdData?.factoryName,
    needMaintenance: equipmentByIdData?.needMaintenance,
    fileName: fileName.map((name) => name.uploadFileName),
  };

  const qrcode = JSON.stringify(jsonFile); //QRcode的value使用json格式顯示，並轉成string

  const [equipmentTimeByIdData, setEquipmentTimeByIdData] = useState<EquipmentData | any>({
    id: 0,
    name: '',
    propertyCode: '',
    periodNumber: 0,
    periodUnit: '',
    keeper: '',
    factoryId: 0,
    needMaintenace: false,
    removed: false,
  });

  const [updateTimeOpen, setUpdateTimeOpen] = useState<boolean>(false);
  //利用Alert顯示下次通知時間，並且要在外層(不然會被Goback關閉)，並setUpdateTimeOpen到裡層拿取handleSubmit的資料並在成功後動作

  const { t: i18T } = useTranslation();

  const updateSampleSchema = Yup.object().shape({
    name: Yup.string().required('請輸入儀器名稱'),
  });

  const handleChange = (event: any) => {
    const files = event.target.files;
    if (files) {
      setFiles(files);
    }
  };

  const handleSubmit = useCallback(
    (request: UpdateEquipmentRequest) => {
      if (clearFormData) {
        EquipmentApi.updateEquipment(
          request,
          (datas: EquipmentData) => {
            setEquipmentTimeByIdData(datas); //拿取成功後資料
            setUpdateTimeOpen(true);
            onUpdateGoBackButtonClick();
            if (files) {
              uploadEquipmentReports(
                {
                  uploaderId: userData?.account || '',
                  itemId: equipmentByIdData?.id,
                },
                files,
              );
            }
          },
          undefined,
          () => {
            clearFormData();
            setFiles(undefined); //清空上傳的新增名稱
            getEquipmentData(); //送出結束重整儀器資料
          },
        );
      }
    },
    [setEquipmentTimeByIdData, setUpdateTimeOpen, onUpdateGoBackButtonClick, uploadEquipmentReports],
  );

  const initialEquipmentValue = {
    id: equipmentByIdData?.id,
    name: equipmentByIdData?.name,
    propertyCode: equipmentByIdData?.propertyCode,
    notificationTime: formData?.notificationTime ? formData?.notificationTime : null, //有使用者選擇才傳選擇的日期，沒選擇傳null讓後端處理通知日期
    periodNumber: updatePrioedNumber ? updatePrioedNumber : equipmentByIdData?.periodNumber,
    periodUnit: updatePrioedUnit ? updatePrioedUnit : equipmentByIdData?.periodUnit,
    keeper: updateKeeper ? updateKeeper : equipmentByIdData?.keeper,
    factoryId: updateFactory ? updateFactory : equipmentByIdData?.factoryId,
    needMaintenance: updateNeedMaintenance,
  };

  const initialEquipmentTimeValue = {
    notificationTime: formData?.notificationTime ? formData?.notificationTime : equipmentByIdData?.notificationTime, //顯示原有的日期值以及改變後的值
  };

  return (
    <UpdateEquipmentUIContent.Provider value={value}>
      <Grid container justifyContent={'center'} item xs={12}>
        <YutungButtonAlert
          open={updateTimeOpen}
          setOpen={setUpdateTimeOpen}
          title={
            i18T('下次校驗時間：') +
            moment(equipmentTimeByIdData?.nextMaintenanceTime).format('YYYY/MM/DD') +
            i18T(' , ') +
            i18T('下次通知時間：') +
            moment(equipmentTimeByIdData?.notificationTime).format('YYYY/MM/DD')
          }
          type="info"
        />
        <YatungDialog
          open={updateModalOpen}
          handleClose={onUpdateGoBackButtonClick}
          icon={<AddEquipment sx={{ width: 40, height: 50, mr: 1, alignSelf: 'center' }} />}
          headerComponent={
            <div>
              <Stack>
                <Stack flexDirection="row" justifyContent="flex-end">
                  <AddEquipment sx={{ width: 40, height: 50, mr: 1, alignSelf: 'center' }} />
                  <Stack flexDirection={'column'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '44px', pl: 1, mr: 6 }}>編輯狀態</Typography>
                  </Stack>
                  <Stack flexDirection={'column'} justifyContent={'center'}>
                    <Tooltip
                      placement="right-end"
                      title={
                        <QRCodeCanvas
                          size={256}
                          viewBox={`0 0 256 256`}
                          value={`${qrcode}`}
                          bgColor="#FFF"
                          style={{ border: '10px solid #FFF' }}
                        />
                      }
                      arrow
                    >
                      <div>
                        <YatungButton
                          text={'檢視QR'}
                          startIcon={<ViewQR sx={{ width: 20, height: 30, alignSelf: 'center', color: 'gray' }} />}
                          color="yellow"
                          sx={{ maxHeight: '40px' }}
                        />
                      </div>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </div>
          }
          body={
            <Formik
              initialValues={initialEquipmentValue}
              validationSchema={updateSampleSchema}
              onSubmit={(request: UpdateEquipmentRequest) => handleSubmit(request)}
              enableReinitialize
            >
              {({ values }) => {
                return (
                  <Form>
                    <EquipmentUpdateField
                      equipmentByIdData={equipmentByIdData}
                      userGroupsData={userGroupsData}
                      entityOptions={entityOptions}
                      values={values}
                      initialEquipmentTimeValue={initialEquipmentTimeValue}
                      updateFactory={updateFactory}
                      setUpdateFactory={setUpdateFactory}
                      updatePrioedNumber={updatePrioedNumber}
                      setUpdatePrioedNumber={setUpdatePrioedNumber}
                      updatePrioedUnit={updatePrioedUnit}
                      setUpdtaePrioedUnit={setUpdtaePrioedUnit}
                      updateKeeper={updateKeeper}
                      setUpdateKeeper={setUpdateKeeper}
                      updateNeedMaintenance={updateNeedMaintenance}
                      setUpdateNeedMaintenance={setUpdateNeedMaintenance}
                      onUpdateGoBackButtonClick={onUpdateGoBackButtonClick}
                      fileName={fileName}
                      handleChange={handleChange}
                      files={files}
                      refreshUploadFileData={refreshUploadFileData}
                    />
                  </Form>
                );
              }}
            </Formik>
          }
        />
      </Grid>
    </UpdateEquipmentUIContent.Provider>
  );
}
