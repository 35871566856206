import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Calculator(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M20.4,2H3.6C3.3,2,3,2.3,3,2.6v18c0,0.4,0.3,0.6,0.6,0.6h16.7c0.4,0,0.6-0.3,0.6-0.6v-18C21,2.3,20.7,2,20.4,2zM19.7,19c0,0.6-0.4,1-1,1H5.3c-0.6,0-1-0.4-1-1V4.3c0-0.6,0.4-1,1-1h13.4c0.6,0,1,0.4,1,1V19z M5.7,7.8V5.7c0-0.4,0.3-0.6,0.6-0.6h11c0.4,0,0.6,0.3,0.6,0.6v2.1c0,0.4-0.3,0.6-0.6,0.6h-11C6,8.5,5.7,8.2,5.7,7.8z M6.2,14.3c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v0c0,0.6-0.4,1-1,1C6.7,15.3,6.2,14.9,6.2,14.3L6.2,14.3z M9.2,14.3c0-0.6,0.5-1,1-1c0.5,0,1,0.5,1,1c0,0.6-0.5,1-1,1C9.7,15.3,9.2,14.8,9.2,14.3z M9.2,17.3c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1s-0.4,1-1,1C9.7,18.3,9.2,17.9,9.2,17.3z M6.2,17.3c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1s-0.4,1-1,1C6.7,18.3,6.2,17.9,6.2,17.3z"/>
      <path d="M8.2,11.3c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1C7.8,12.3,8.2,11.9,8.2,11.3z"/>
      <path d="M11.2,11.3c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1C10.8,12.3,11.2,11.9,11.2,11.3z"/>
      <path d="M14.2,11.3c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1C13.8,12.3,14.2,11.9,14.2,11.3z"/>
      <path d="M17.2,11.3c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1C16.8,12.3,17.2,11.9,17.2,11.3z"/>
      <path d="M14.2,14.3c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1C13.8,15.3,14.2,14.9,14.2,14.3z"/>
      <path d="M17.2,14.3c0-0.5-0.5-1-1-1s-1,0.4-1,1c0,0.5,0.5,1,1,1S17.2,14.8,17.2,14.3z"/>
      <path d="M17.2,17.3c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1s0.4,1,1,1C16.8,18.3,17.2,17.9,17.2,17.3z"/>
      <path d="M14.2,17.3c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1s0.4,1,1,1C13.8,18.3,14.2,17.9,14.2,17.3z"/>
    </SvgIcon>
  );
}
