import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import YatungButton from '@Src/_basic/components/YatungButton';

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
  children: React.ReactNode;
  canSubmit?: boolean;
}

export default function EditForm({ onSubmit, onCancel, children, canSubmit = true }: Props) {
  const { t: i18T } = useTranslation();
  return (
    <Box>
      {children}
      <Stack flexDirection="row" justifyContent="center" sx={{ pt: 1 }}>
        <YatungButton
          text={i18T('GLOBAL.CANCEL')}
          onClick={onCancel}
          color="red"
          width={96}
          sx={{ mx: 2 }}
        />
        <YatungButton
          text={i18T('GLOBAL.FINISH')}
          disabled={!canSubmit}
          onClick={onSubmit}
          color="blue"
          width={96}
          sx={{ mx: 2 }}
        />
      </Stack>
    </Box>
  );
}
