import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import AddCircleFill from '@Src/_basic/icons/AddCircleFill';

const Button = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px dashed #697EA3',
  backgroundColor: '#F9F9F9',
  userSelect: 'none',
  color: '#003087',
  borderRadius: '8px',
  height: '120px',
  width: '120px',
  gap: '8px',
});

const ButtonLabel = styled(Typography)({
  color: '#697EA3',
  fontFamily: 'Microsoft JhengHei',
  fontWeight: 400,
  size: '20px',
});

interface Props {
  onClick?: () => void;
}

export default function AddSignatureItemButton(props: Props) {
  const handleClick = () => {
    props.onClick?.();
  };

  return (
    <Button onClick={handleClick}>
      <AddCircleFill />
      <ButtonLabel>新增</ButtonLabel>
    </Button>
  );
}
