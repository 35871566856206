import { ChildrenTypography, TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import { MonthlyReportCoarseAggAnalysis } from '@Src/_basic/object/MonthlyReportType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { SetStateAction, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportBlock from './components/ReportBlock';
import RowContent from './components/RowContent';
import ToggleBlockButton from './components/ToggleBlockButton';

type FilterType = 'abnormal' | 'screening1' | 'screening1_5' | 'screening0_5' | 'screening4' | 'screening8' | null;

interface AbnormalSummary {
  abnormal: number;
  screening0_5: number;
  screening1: number;
  screening1_5: number;
  screening4: number;
  screening8: number;
}

const CoarseAggregateAnalysisReport = ({ report }: { report: Array<MonthlyReportCoarseAggAnalysis> }) => {
  const { t: i18T } = useTranslation();
  const [abnormalSummary, setAbnormalSummery] = useState<AbnormalSummary>({
    abnormal: 0,
    screening1: 0,
    screening1_5: 0,
    screening0_5: 0,
    screening4: 0,
    screening8: 0,
  });
  const [selectedFilter, setSelectedFilter] = useState<SetStateAction<FilterType>>(null);

  const [reports, setReports] = useState<Array<MonthlyReportCoarseAggAnalysis>>([]);
  const [filteredReport, setFilteredReport] = useState<Array<MonthlyReportCoarseAggAnalysis>>([]);

  const handleFilterChange = (filter: FilterType) => () => {
    const newFilter = selectedFilter === filter ? null : filter;
    const filteredReport = reports.filter((i: MonthlyReportCoarseAggAnalysis) =>
      newFilter ? i[newFilter]?.isAbnormal : true,
    );
    setSelectedFilter(newFilter);
    setFilteredReport(filteredReport);
  };

  useEffect(() => {
    const _report = report.map((d: MonthlyReportCoarseAggAnalysis) => ({
      ...d,
      abnormal: {
        isAbnormal:
          d['screening1']?.isAbnormal ||
          d['screening1_5']?.isAbnormal ||
          d['screening0_5']?.isAbnormal ||
          d['screening4']?.isAbnormal ||
          d['screening8']?.isAbnormal,
      },
    }));

    const abnormalDay = new Set();
    const screening1AbnormalDay = new Set();
    const screening1_5AbnormalDay = new Set();
    const screening0_5AbnormalDay = new Set();
    const screening4AbnormalDay = new Set();
    const screening8AbnormalDay = new Set();

    _report.forEach((item) => {
      item.abnormal?.isAbnormal ? abnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening1?.isAbnormal ? screening1AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening1_5?.isAbnormal ? screening1_5AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening0_5?.isAbnormal ? screening0_5AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening4?.isAbnormal ? screening4AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening8?.isAbnormal ? screening8AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
    });

    const abnormalSummary = {
      abnormal: abnormalDay.size,
      screening1: screening1AbnormalDay.size,
      screening1_5: screening1_5AbnormalDay.size,
      screening0_5: screening0_5AbnormalDay.size,
      screening4: screening4AbnormalDay.size,
      screening8: screening8AbnormalDay.size,
    };

    setReports(_report);
    setFilteredReport(_report);
    setAbnormalSummery(abnormalSummary);
  }, [report]);

  return (
    <>
      <TestCardContainer sx={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <ToggleBlockButton
          isToggled={selectedFilter === 'abnormal'}
          onPress={handleFilterChange('abnormal')}
          top={
            <>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE_ANALYSIS`)}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.abnormal ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.abnormal}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening1_5'}
          onPress={handleFilterChange('screening1_5')}
          top={
            <>
              <ChildrenTypography>{`3/8"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack mt={3}>
              <ChildrenTypography style={abnormalSummary.screening1_5 ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.screening1_5}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening1'}
          onPress={handleFilterChange('screening1')}
          top={
            <>
              <ChildrenTypography>{`#4"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening1 ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.screening1}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening0_5'}
          onPress={handleFilterChange('screening0_5')}
          top={
            <>
              <ChildrenTypography>{`#16"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening0_5 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening0_5}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening4'}
          onPress={handleFilterChange('screening4')}
          top={
            <>
              <ChildrenTypography>{`#30"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening4 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening4}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening8'}
          onPress={handleFilterChange('screening8')}
          top={
            <>
              <ChildrenTypography>{`#50"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening8 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening8}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
      </TestCardContainer>
      {filteredReport &&
        filteredReport?.map((item: MonthlyReportCoarseAggAnalysis) => (
          <ReportBlock
            key={item.exptId}
            tester={item.tester}
            exptId={item.exptId}
            exptItemCode={item.exptItemCode}
            verifyPassedResults={item.verifyPassedResults}
            verifyCustomResults={item.verifyCustomResults}
            exptId1={item.exptId1}
            exptId2={item.exptId2}
            ratio={item.ratio}
          >
            <RowContent
              labels={[
                i18T(`MONTHLY_REPORT.BASE.TEST_DATE`),
                `1.5"\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
                `1"\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
              ]}
              values={[
                moment(item.testDate).format('YYYY/MM/DD'),
                [
                  item.exptId1
                    ? item.screening1_5?.result.toFixed(2) ?? '0'
                    : item.screening1_5?.result.toFixed(2) ?? '0',
                  item.screening1_5?.isAbnormal,
                ],
                [
                  item.exptId1 ? item.screening1?.result.toFixed(2) ?? '0' : item.screening1?.result.toFixed(2) ?? '0',
                  item.screening1?.isAbnormal,
                ],
              ]}
            />
            <RowContent
              labels={[
                `1/2"\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
                `#4\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
                `#8\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
              ]}
              values={[
                [
                  item.exptId1
                    ? item.screening0_5?.result.toFixed(2) ?? '0'
                    : item.screening0_5?.result.toFixed(2) ?? '0',
                  item.screening0_5?.isAbnormal,
                ],
                [
                  item.exptId1 ? item.screening4?.result.toFixed(2) ?? '0' : item.screening4?.result.toFixed(2) ?? '0',
                  item.screening4?.isAbnormal,
                ],
                [
                  item.exptId1 ? item.screening8?.result.toFixed(2) ?? '0' : item.screening8?.result.toFixed(2) ?? '0',
                  item.screening8?.isAbnormal,
                ],
              ]}
            />
          </ReportBlock>
        ))}
    </>
  );
};

export default memo(CoarseAggregateAnalysisReport);
