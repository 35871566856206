import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  SxProps,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, { forwardRef } from 'react';

interface Props extends StandardTextFieldProps {
  type?: string;
  text?: string;
  disabledText?: string;
  textColor?: string;
  endTextColor?: string;
  position?: `${'start' | 'end' | 'startEnd'}`;
  icon?: string;
  form?: { touched: string; errors: string };
  autoComplete?: string;
  onChange?: any;
  onBlur?: any;
  setInputProps: JSX.Element;
  style?: any;
  sx?: SxProps;
  handleInputType?: () => void;
  isIconButton?: boolean;
  handlePwInput?: () => void;
}

const Input = styled(TextField)(({ theme, style }) => ({
  color: '#6C708C',
  borderRadius: 8,
  background: 'white',
  '& fieldset': {
    borderColor: '#6C708C',
  },
  '& .MuiInputBase-root': {
    borderRadius: 8,
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    fontSize: 20,
    padding: '8px 12px',
    height: 'auto',
    ...style,
  },
}));

const YatungInput = forwardRef<HTMLDivElement, any>(
  (
    {
      text,
      textColor,
      endTextColor,
      disabledText,
      position,
      icon,
      type,
      setInputProps,
      style,
      handleInputType,
      isIconButton,
      handlePwInput,
      ...props
    }: any,
    ref,
  ) => {
    const textStyle = {
      fontSize: 18,
      fontWeight: 400,
      color: textColor,
      px: 1,
    };

    const getInputProps = () => {
      switch (position) {
        case 'end':
          return {
            endAdornment: (
              <InputAdornment position="end">
                {isIconButton ? <IconButton onClick={handleInputType}>{icon}</IconButton> : <>{icon}</>}
                <Typography sx={textStyle}>{text}</Typography>
              </InputAdornment>
            ),
          };
        case 'start':
          return {
            startAdornment: (
              <InputAdornment position="start">
                {icon}
                <Typography sx={textStyle}>{text}</Typography>
              </InputAdornment>
            ),
          };
        case 'startEnd':
          return {
            startAdornment: (
              <InputAdornment position="start">
                {icon}
                <Typography sx={textStyle}>{text}</Typography>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography
                  sx={{
                    ...textStyle,
                    color: endTextColor,
                  }}
                >
                  {disabledText}
                </Typography>
              </InputAdornment>
            ),
          };
        default:
          return {};
      }
    };

    return (
      <Input
        ref={ref}
        variant="outlined"
        type={type}
        InputProps={setInputProps ? setInputProps : getInputProps()}
        style={style}
        onBlur={handlePwInput}
        {...props}
      />
    );
  },
);

YatungInput.displayName = 'YatungInput';

export default YatungInput;
