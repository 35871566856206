import axios from 'axios';
import { Response } from '@Src/_basic/object/ApiType';
import { ExperimentResultTypeProtocol } from './ExperimentResultTypeProtocol';
import { ExperimentResultTypeData } from '@Src/_basic/object/ExperimentResultTypeType';
import { SearchExperimentResultTypesParams } from '@Src/_basic/object/ExperimentResultTypeTypeType';
import { Api } from '../Api';

export class ExperimentResultTypeApi {
  public static getExperimentResultTypesBySearch(
    params: SearchExperimentResultTypesParams,
    onSuccess?: (data: Array<ExperimentResultTypeData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ExperimentResultTypeProtocol.EXPERIMENT_RESULT_TYPES_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }
}
