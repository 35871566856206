import React, { createContext, useState } from 'react';

interface Props {
  children: React.JSX.Element;
}

interface CreatePropportionSelect {
  areaId: number;
  factoryId: number;
}

export const ProportionContext = createContext<any>(null);

export const ProportionProvider = ({ children }: Props) => {
  const [createPropportionSelect, setCreatePropportionSelect] = useState<CreatePropportionSelect | undefined>();
  const [createPropportionForm, setCreatePropportionForm] = useState<any>();

  return (
    <ProportionContext.Provider
      value={{
        createPropportionSelect,
        setCreatePropportionSelect,
        createPropportionForm,
        setCreatePropportionForm,
      }}
    >
      {children}
    </ProportionContext.Provider>
  );
};
