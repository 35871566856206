import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { materialInspectionSlice } from './materialInspectionSlice';

export function useMaterialInspection() {
  const { date } = useSelector((state: RootState) => state.materialInspectionDate);
  const dispatch = useDispatch();
  const setDate = (_date: any) => dispatch(materialInspectionSlice.actions.setDate(_date));
  return {
    date,
    setDate,
  };
}
