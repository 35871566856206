import { Box, Card, CardContent, IconButton, Stack } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CalcProportion } from '@Src/_basic/helpers/Proportion';

import EditIcon from '@Src/_basic/icons/EditV2';
import SearchIcon from '@Src/_basic/icons/SearchV2';
import TransferIcon from '@Src/_basic/icons/Transfer';

import { ProportionResponse } from '@Src/_basic/object/ProportionType';
import RowContent from './RowContent';

interface Props {
  areaName?: string;
  factoryName?: string;
  type?: number;
  proportion: ProportionResponse;
  isVersionList?: boolean;
  hideViewProportionButton?: boolean;
}

const FormulaCard = ({ areaName, factoryName, proportion, isVersionList, hideViewProportionButton }: Props) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>(0);

  const {
    propName,
    dsgnStr,
    slump,
    grainSize,
    waterColloidRatio,

    version,
    propNum,
    cementWt,
    slagWt,
    flyAshWt,
    microsilicaWt,

    stone1Pct,
    stone2Pct,
    stone3Pct,
    stone4Pct,
    sand1Pct,
    sand2Pct,
    sand3Pct,
    sand4Pct,
    chemAdmx1UsagePct,
    chemAdmx2UsagePct,
    chemAdmx3UsagePct,
    chemAdmx4UsagePct,
    chemAdmx5UsagePct,
    chemAdmx6UsagePct,
    chemAdmx1Name,
    chemAdmx2Name,
    chemAdmx3Name,
    chemAdmx4Name,
    chemAdmx5Name,
    chemAdmx6Name,

    tags,

    maintProp,
    id,
    annualShipmentAndCostStatistics,
  }: ProportionResponse = proportion;

  const {
    annualShipmentCount,
    latestShipmentDate,
    annualQtySummary,
    annualRejectSummary,
    annualRejectRate,
    highestStrengthOfYear,
    lowestStrengthOfYear,
    averageStrengthOfYear,
    highestCostOfYear,
    lowestCostOfYear,
    averageCostOfYear,
    strengthCostPerKg,
  } = annualShipmentAndCostStatistics || {};

  const {
    arrivedDesignStrPct,
    cementitiousWt,
    sandAndStoneWt,
    sandPct,
    stone1Wt,
    stone2Wt,
    stone3Wt,
    stone4Wt,
    sand1Wt,
    sand2Wt,
    sand3Wt,
    sand4Wt,
    chemAdmx1Wt,
    chemAdmx2Wt,
    chemAdmx3Wt,
    chemAdmx4Wt,
    chemAdmx5Wt,
    chemAdmx6Wt,

    cementPct,
    slagPct,
    flyAshPct,
    microsilicaPct,
  } = new CalcProportion(proportion, maintProp);

  const handleTabChange = () => {
    setTab(tab + 1 > 2 ? 0 : tab + 1);
  };

  const handleEdit = useCallback(() => {
    navigate(`/proportionManage/proportionList/${id}/${version}`, { state: { proportion } });
  }, [proportion, navigate, id, version]);

  const handleViewSingleProportion = useCallback(() => {
    navigate(`/proportionManage/proportionList/version/${proportion.propName}/${proportion.factoryId}`);
  }, [proportion, navigate]);

  return (
    <Card
      sx={{
        my: 2,
        width: '440px',
        boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
        background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
      }}
    >
      <CardContent>
        <>
          {!isVersionList ? (
            <>
              <RowContent
                labels={['區/廠', '配比名稱', '配比版本']}
                values={[`${areaName}/${factoryName}`, propName, version]}
              />
              <RowContent labels={['坍(流)度(cm)', '粒徑(mm)']} values={[slump, grainSize]} />
              <RowContent
                labels={['設計水膠比', '達成設計\n強度(%)', '設計強度(kgf/cm²)']}
                values={[waterColloidRatio.toFixed(1), arrivedDesignStrPct?.toFixed(1), dsgnStr]}
              />
            </>
          ) : (
            <RowContent
              labels={['配比版本', '設計\n水膠比', '達成設計強度(%)', '設計強度']}
              values={[version, waterColloidRatio.toFixed(1), arrivedDesignStrPct?.toFixed(1), dsgnStr]}
            />
          )}
          <Box>
            <Stack sx={{ my: 1 }} flexDirection="row" justifyContent="flex-end">
              <IconButton sx={{ color: '#002F8C' }} onClick={handleTabChange}>
                <TransferIcon />
              </IconButton>
            </Stack>
            {tab === 0 && (
              <>
                <RowContent
                  labels={['年出貨工地數', '最近出貨日期']}
                  values={[annualShipmentCount ?? '無資料', latestShipmentDate ?? '無資料']}
                  columnWidth={[40, 40]}
                />
                <RowContent
                  labels={['年出貨方數', '年退轉方數', '年退轉率(%)']}
                  values={[annualQtySummary ?? '無資料', annualRejectSummary ?? '無資料', annualRejectRate ?? '無資料']}
                />
                <RowContent
                  labels={['年最高強度', '年最低強度', '年平均強度(kgf/cm²)']}
                  values={[
                    highestStrengthOfYear ?? '無資料',
                    lowestStrengthOfYear ?? '無資料',
                    averageStrengthOfYear ?? '無資料',
                  ]}
                />
                <RowContent
                  labels={['年\n最高成本', '年\n最低成本', '年\n平均成本', 'kg\n強度成本']}
                  values={[
                    highestCostOfYear ?? '無資料',
                    lowestCostOfYear ?? '無資料',
                    averageCostOfYear ?? '無資料',
                    strengthCostPerKg ?? '無資料',
                  ]}
                />
              </>
            )}
            {tab === 1 && (
              <>
                <RowContent
                  labels={['膠結材總量(kg)', '砂石總量(kg)', '砂率(%)']}
                  values={[cementitiousWt.toFixed(1), sandAndStoneWt.toFixed(1), sandPct.toFixed(1)]}
                  columnWidth={[40, 35, 25]}
                />
                <RowContent
                  labels={['水泥(kg)', '爐石(kg)', '飛砂(kg)', '矽灰(kg)']}
                  values={[cementWt?.toFixed(1), slagWt?.toFixed(1), flyAshWt?.toFixed(1), microsilicaWt?.toFixed(1)]}
                />
                <RowContent
                  labels={['石1(kg)', '石2(kg)', '石3(kg)', '石4(kg)']}
                  values={[stone1Wt?.toFixed(1), stone2Wt?.toFixed(1), stone3Wt?.toFixed(1), stone4Wt?.toFixed(1)]}
                />
                <RowContent
                  labels={['砂1(kg)', '砂2(kg)', '砂3(kg)', '砂4(kg)']}
                  values={[sand1Wt?.toFixed(1), sand2Wt?.toFixed(1), sand3Wt?.toFixed(1), sand4Wt?.toFixed(1)]}
                />
                <RowContent
                  labels={[
                    chemAdmx1Name ? `藥1添加率(kg)\n${chemAdmx1Name}` : '',
                    chemAdmx2Name ? `藥2添加率(kg)\n${chemAdmx2Name}` : '',
                    chemAdmx3Name ? `藥3添加率(kg)\n${chemAdmx3Name}` : '',
                  ]}
                  labelsx={{ fontSize: '16px' }}
                  values={[chemAdmx1Wt.toFixed(1), chemAdmx2Wt.toFixed(1), chemAdmx3Wt.toFixed(1)]}
                />
                {maintProp.chemAdmx4Id && (
                  <RowContent
                    labels={[
                      chemAdmx4Name ? `藥4添加率(kg)\n${chemAdmx4Name}` : '',
                      chemAdmx5Name ? `藥5添加率(kg)\n${chemAdmx5Name}` : '',
                      chemAdmx6Name ? `藥6添加率(kg)\n${chemAdmx6Name}` : '',
                    ]}
                    labelsx={{ fontSize: '16px' }}
                    justifyContent="flex-start"
                    columnWidth={[33, 33, 33]}
                    values={[
                      chemAdmx4Wt.toFixed(1),
                      ...(maintProp.chemAdmx5Id ? [chemAdmx5Wt.toFixed(1)] : []),
                      ...(maintProp.chemAdmx6Id ? [chemAdmx6Wt.toFixed(1)] : []),
                    ]}
                  />
                )}
              </>
            )}
            {tab === 2 && (
              <>
                <RowContent
                  labels={['膠結材總量(kg)', '砂石總量(kg)', '砂率(%)']}
                  values={[cementitiousWt.toFixed(1), sandAndStoneWt.toFixed(1), sandPct.toFixed(1)]}
                  columnWidth={[40, 35, 25]}
                />
                <RowContent
                  labels={['水泥(%)', '爐石(%)', '飛砂(%)', '矽灰(%)']}
                  values={[cementPct.toFixed(1), slagPct.toFixed(1), flyAshPct.toFixed(1), microsilicaPct.toFixed(1)]}
                />
                <RowContent
                  labels={['石1(%)', '石2(%)', '石3(%)', '石4(%)']}
                  values={[stone1Pct?.toFixed(1), stone2Pct?.toFixed(1), stone3Pct?.toFixed(1), stone4Pct?.toFixed(1)]}
                />
                <RowContent
                  labels={['砂1(%)', '砂2(%)', '砂3(%)', '砂4(%)']}
                  values={[sand1Pct?.toFixed(1), sand2Pct?.toFixed(1), sand3Pct?.toFixed(1), sand4Pct?.toFixed(1)]}
                />
                {maintProp.chemAdmx1Id && (
                  <RowContent
                    labels={[
                      chemAdmx1Name ? `藥1添加率(kg)\n${chemAdmx1Name}` : '',
                      chemAdmx2Name ? `藥2添加率(kg)\n${chemAdmx2Name}` : '',
                      chemAdmx3Name ? `藥3添加率(kg)\n${chemAdmx3Name}` : '',
                    ]}
                    labelsx={{ fontSize: '16px' }}
                    values={[
                      chemAdmx1UsagePct?.toFixed(1),
                      ...(maintProp.chemAdmx2Id ? [chemAdmx2UsagePct?.toFixed(1)] : []),
                      ...(maintProp.chemAdmx3Id ? [chemAdmx3UsagePct?.toFixed(1)] : []),
                    ]}
                  />
                )}
                {maintProp.chemAdmx4Id && (
                  <RowContent
                    labels={[
                      chemAdmx4Name ? `藥4添加率(kg)\n${chemAdmx4Name}` : '',
                      chemAdmx5Name ? `藥5添加率(kg)\n${chemAdmx5Name}` : '',
                      chemAdmx6Name ? `藥6添加率(kg)\n${chemAdmx6Name}` : '',
                    ]}
                    labelsx={{ fontSize: '16px' }}
                    justifyContent="flex-start"
                    columnWidth={[33, 33, 33]}
                    values={[
                      chemAdmx4UsagePct?.toFixed(1),
                      ...(maintProp.chemAdmx5Id ? [chemAdmx5UsagePct?.toFixed(1)] : []),
                      ...(maintProp.chemAdmx6Id ? [chemAdmx6UsagePct?.toFixed(1)] : []),
                    ]}
                  />
                )}
              </>
            )}
          </Box>
          <Stack sx={{ my: 1 }} flexDirection="row" justifyContent="flex-end">
            <IconButton onClick={handleEdit} sx={{ color: '#002F8C' }}>
              <EditIcon />
            </IconButton>
            {!hideViewProportionButton && (
              <IconButton onClick={handleViewSingleProportion} sx={{ color: '#002F8C' }}>
                <SearchIcon />
              </IconButton>
            )}
          </Stack>
          {tags && (
            <Stack sx={{ my: 1 }} flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
              {tags.map((item) => (
                <Box
                  key={`tag-${item.id}`}
                  sx={{
                    backgroundColor: '#003087',
                    m: 0.5,
                    py: 1,
                    px: 2,
                    color: 'white',
                    fontSize: '15px',
                    borderRadius: '8px',
                  }}
                >
                  {item?.tagName}
                </Box>
              ))}
            </Stack>
          )}
        </>
      </CardContent>
    </Card>
  );
};

export default memo(FormulaCard);
