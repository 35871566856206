import { Response } from '@Src/_basic/object/ApiType';
import { MaterialStatus } from '@Src/_basic/object/MarterialStatusType';
import { Api } from '../Api';
import { MaterialStatusProportion } from './MaterialStatusProportion';

export class MaterialStatusApi {
  //取得拌合機配比選項
  public static getAllMaterialStatus(
    onSuccess?: (data: Array<MaterialStatus>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(MaterialStatusProportion.GET_ALL_MATERIAL_STATUS, undefined, onSuccess, onFailed, onFinally);
  }
}
