import { numberComma } from '@Src/_basic/helpers/NumberHelpers';
import { Box, Typography, styled } from '@mui/material';
import React, { CSSProperties } from 'react';

const Title = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '16px',
  letterSpacing: '0.205em',
}));

const Value = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '32px',
  fontWeight: '700',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '29px',
}));

const Unit = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '16px',
  letterSpacing: '0.205em',
}));

interface Props {
  title: string;
  value: number;
  unit: string;
  backgroundColor?: CSSProperties['backgroundColor'];
  style?: CSSProperties;
}

export default function StatisticsCard(props: Props) {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '96px',
        width: '196px',
        background: '#E4E4E4',
        borderRadius: '6px',
        boxShadow: '2px 4px 3px rgba(0, 0, 0, 0.2)',
        ...props.style,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '9px',
          height: '80px',
          minWidth: '180px',
          py: '9px',
          background: props.backgroundColor ? props.backgroundColor : '#697EA3',
          borderRadius: '6px',
          boxShadow: 'inset 2px 4px 3px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Title noWrap>{props.title}</Title>
        </Box>
        <Box sx={{ mx: '8px', borderBottom: '1px solid #FFFFFF' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            px: '12px',
          }}
        >
          <Box></Box>
          <Box>
            <Value>{numberComma(props.value.toFixed(0))}</Value>
          </Box>
          <Box>
            <Unit>{props.unit}</Unit>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
