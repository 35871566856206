import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CreateUnitButton(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M22.0606 2.43939H19.8788V4.62121H22.0606V2.43939Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.56061V0.5H6.06061V3.16667H17.9394V0.5H24V6.56061H21.3333V18.4394H24V24.5H17.9394V21.8333H6.06061V24.5H0V18.4394H2.66667V6.56061H0ZM0.484848 0.984848H5.57576V6.07576H0.484848V0.984848ZM20.8485 18.4394V6.56061H17.9394V3.65152H6.06061V6.56061H3.15152V18.4394H6.06061V21.3485H17.9394V18.4394H20.8485ZM5.57576 18.9242H0.484848V24.0152H5.57576V18.9242ZM23.5152 18.9242H18.4242V24.0152H23.5152V18.9242ZM23.5152 0.984848H18.4242V6.07576H23.5152V0.984848Z"
        fill="white"
      />
    </SvgIcon>
  );
}
