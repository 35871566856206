import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import ReportGroupManagementCardScreen from './screens/ReportGroupManagementCardScreen';
import ReportGroupManagementCreateCardScreen from './screens/ReportGroupManagementCreateCardScreen';
import ReportGroupManagementUpdateCardScreen from './screens/ReportGroupManagementUpdateCardScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <ReportGroupManagementCardScreen />,
  },
  {
    path: '/ReportGroupManagementCreate/*',
    element: <ReportGroupManagementCreateCardScreen />,
  },
  {
    path: '/ReportGroupManagementUpdate/:id',
    element: <ReportGroupManagementUpdateCardScreen />,
  },
];

const ReportGroupManagement = () => PermissionRoute(routesConfig);

export default memo(ReportGroupManagement);
