import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PersonTitle(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="23" height="14" viewBox="0 0 23 14" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2326 10.2308C11.2326 8.79844 8.38165 8.07692 6.95349 8.07692C5.52533 8.07692 2.67442 8.79844 2.67442 10.2308V11.3077H11.2326V10.2308ZM3.50495 9.68567C3.28485 9.89022 3.2093 10.0705 3.2093 10.2308V10.7692H10.6977V10.2308C10.6977 10.0705 10.6221 9.89022 10.402 9.68567C10.1783 9.47777 9.84266 9.28314 9.42968 9.11658C8.60287 8.78313 7.60068 8.61539 6.95349 8.61539C6.3063 8.61539 5.30411 8.78313 4.4773 9.11658C4.06432 9.28314 3.72871 9.47777 3.50495 9.68567Z" fill="#222"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.09302 4.84615C9.09302 6.03613 8.13556 7 6.95349 7C5.77142 7 4.81395 6.03613 4.81395 4.84615C4.81395 3.65618 5.77142 2.69231 6.95349 2.69231C8.13556 2.69231 9.09302 3.65618 9.09302 4.84615ZM6.95349 6.46154C7.84017 6.46154 8.55814 5.73877 8.55814 4.84615C8.55814 3.95354 7.84017 3.23077 6.95349 3.23077C6.0668 3.23077 5.34884 3.95354 5.34884 4.84615C5.34884 5.73877 6.0668 6.46154 6.95349 6.46154Z" fill="#222"><path fillRule="evenodd" clipRule="evenodd" d="M11.2326 10.2308C11.2326 8.79844 8.38165 8.07692 6.95349 8.07692C5.52533 8.07692 2.67442 8.79844 2.67442 10.2308V11.3077H11.2326V10.2308ZM3.50495 9.68567C3.28485 9.89022 3.2093 10.0705 3.2093 10.2308V10.7692H10.6977V10.2308C10.6977 10.0705 10.6221 9.89022 10.402 9.68567C10.1783 9.47777 9.84266 9.28314 9.42968 9.11658C8.60287 8.78313 7.60068 8.61539 6.95349 8.61539C6.3063 8.61539 5.30411 8.78313 4.4773 9.11658C4.06432 9.28314 3.72871 9.47777 3.50495 9.68567Z" fill="#222"/></path>
      <path d="M12.8372 3.23077C12.8372 2.93341 13.0767 2.69231 13.3721 2.69231H18.186C18.4814 2.69231 18.7209 2.93341 18.7209 3.23077C18.7209 3.52813 18.4814 3.76923 18.186 3.76923H13.3721C13.0767 3.76923 12.8372 3.52813 12.8372 3.23077Z" fill="#222"/>
      <path d="M12.8372 7C12.8372 6.70264 13.0767 6.46154 13.3721 6.46154H18.186C18.4814 6.46154 18.7209 6.70264 18.7209 7C18.7209 7.29736 18.4814 7.53846 18.186 7.53846H13.3721C13.0767 7.53846 12.8372 7.29736 12.8372 7Z" fill="#222"/>
      <path d="M12.8372 10.7692C12.8372 10.4719 13.0767 10.2308 13.3721 10.2308H20.3256C20.621 10.2308 20.8605 10.4719 20.8605 10.7692C20.8605 11.0666 20.621 11.3077 20.3256 11.3077H13.3721C13.0767 11.3077 12.8372 11.0666 12.8372 10.7692Z" fill="#222"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.13953 0C0.95792 0 0 0.964327 0 2.15385V11.8462C0 13.0357 0.95792 14 2.13953 14H20.8605C22.0421 14 23 13.0357 23 11.8462V2.15385C23 0.964327 22.0421 0 20.8605 0H2.13953ZM20.8605 0.538462H2.13953C1.25331 0.538462 0.534884 1.26169 0.534884 2.15385V11.8462C0.534884 12.7383 1.25331 13.4615 2.13953 13.4615H20.8605C21.7467 13.4615 22.4651 12.7383 22.4651 11.8462V2.15385C22.4651 1.26169 21.7467 0.538462 20.8605 0.538462Z" fill="#222"/>
    </SvgIcon>
  );
}
