import YatungFadeMenu from '@Src/_basic/components/YatungFadeMenu';
import AnalyticsIcon from '@Src/_basic/icons/Analytics';
import { useAuth } from '@Src/redux/auth/authActions';
import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function YatungDashboardMenu() {
  const { t: i18T } = useTranslation();
  const { userApiPermission } = useAuth();
  const [newRouteSideMenu, setNewRouteSideMenu] = React.useState<Array<any>>([]);

  const routeSideMenu = [
    {
      title: i18T('POWERBI.WATER_DECADE'),
      toUrl: '/PowerbiManagePage/WaterDecade',
      code: 'WD057',
    },
    {
      title: i18T('POWERBI.PELLETS'),
      toUrl: '/PowerbiManagePage/Pellets',
      code: 'WD058',
    },
    {
      title: i18T('POWERBI.CEMENTITIOUS'),
      toUrl: '/PowerbiManagePage/Cementitious',
      code: 'WD059',
    },
    {
      title: i18T('POWERBI.RETURN_AND_LOSS'),
      toUrl: '/PowerbiManagePage/ReturnAndLoss',
      code: 'WD060',
    },
    {
      title: i18T('POWERBI.RECLAIMED_WATER_AND_STONE'),
      toUrl: '/PowerbiManagePage/RemainingKPI',
      code: 'WD061',
    },
    // {
    //     title: '配比試驗',
    //     toUrl: '/MatchingRatio'
    // }
    {
      title: i18T('POWERBI.FACTORY_DEVIATION'),
      toUrl: '/PowerbiManagePage/FactoryDeviation',
      code: 'WD062',
    },
  ];

  useEffect(() => {
    const routeSideMenuTemp: Array<any> = [];
    routeSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeSideMenuTemp.push(item);
      }
    });
    setNewRouteSideMenu(routeSideMenuTemp);
  }, [userApiPermission]);
  return (
    <>
      <Stack alignItems="center" justifyContent="flex-start" flexDirection="row" sx={{ px: 1, color: 'white' }}>
        <Stack alignItems="center" justifyContent="center" sx={{ width: '40px' }}>
          <AnalyticsIcon />
        </Stack>
        <Typography sx={{ fontSize: 24, fontWeight: 900 }}>{i18T('POWERBI.ROUTE_TITLE')}</Typography>
      </Stack>
      {newRouteSideMenu.length > 0 && (
        <YatungFadeMenu text={i18T('POWERBI.SECONDARY_ROUTE_TITLE')} option={newRouteSideMenu} />
      )}
    </>
  );
}
