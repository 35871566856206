import { QrcodeData } from '@Src/_basic/object/QrcodeType';
import { AllTest, TestItemEnum } from '@Src/_basic/object/TestType';
import { GroupDataByCementCompStrResponse } from '@Src/_basic/object/test/cementtitiousPowder/CementCompStrType';
import { GroupDataByFlyAshActivityResponse } from '@Src/_basic/object/test/cementtitiousPowder/FlyAshActivityType';
import { GroupDataBySlagActivityResponse } from '@Src/_basic/object/test/cementtitiousPowder/SlagActivityType';
import { QrcodeApi } from '@Src/_basic/protocol/qrcode/QrcodeApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import ExportChemAdmxActivityTable from '@Src/app/module/test/cementitiousLiquid/chemAdmxMultiTest/chemAdmxActivity/ExportChemAdmxActivityTable';
import ExportChemAdmxPentaTable from '@Src/app/module/test/cementitiousLiquid/chemAdmxMultiTest/chemAdmxPentaTest/ExportChemAdmxPentaTable';
import ExportChemicalChlorideTable from '@Src/app/module/test/cementitiousLiquid/chemicalChloride/ExportChemicalChlorideTable';
import ExportChemicalSolidContentTable from '@Src/app/module/test/cementitiousLiquid/chemicalSolidContent/ExportChemicalSolidContentTable';
import ExportChemicalSpecificGravityTable from '@Src/app/module/test/cementitiousLiquid/chemicalSpecificGravity/ExportChemicalSpecificGravityTable';
import ExportMixingChlorideTable from '@Src/app/module/test/cementitiousLiquid/mixingChloride/ExportMixingChlorideTable';
import ExportRecycleChlorideTable from '@Src/app/module/test/cementitiousLiquid/recycleChloride/ExportRecycleChlorideTable';
import ExportRecycleSpecificGravityTable from '@Src/app/module/test/cementitiousLiquid/recycleSpecificGravity/ExportRecycleSpecificGravityTable';
import ExportCementCompStrTable from '@Src/app/module/test/cementitiousPowder/cementCompStr/ExportCementCompStrTable';
import FlyAshActivityCompleteTable from '@Src/app/module/test/cementitiousPowder/flyAshActivity/FlyAshActivityCompleteTable';
import ExportFlyAshLValueTable from '@Src/app/module/test/cementitiousPowder/flyAshLValue/ExportFlyAshLValueTable';
import ExportFlyAshLossOnIgnitionTable from '@Src/app/module/test/cementitiousPowder/flyAshLossOnIgnition/ExportFlyAshLossOnIgnitionTable';
import ExportFlyAshSlumpTable from '@Src/app/module/test/cementitiousPowder/flyAshSlump/ExportFlyAshSlumpTable';
import ExportFlyAshWaterDemandTable from '@Src/app/module/test/cementitiousPowder/flyAshWaterDemand/ExportFlyAshWaterDemandTable';
import SlagActivityCompleteTable from '@Src/app/module/test/cementitiousPowder/slagActivity/SlagActivityCompleteTable';
import ExportSlagWaterDemandTable from '@Src/app/module/test/cementitiousPowder/slagWaterDemand/ExportSlagWaterDemandTable';
import ExportSpecificSurfaceTable from '@Src/app/module/test/cementitiousPowder/specificSurface/ExportSpecificSurfaceTable';
import ExportConcreteSlumpTable from '@Src/app/module/test/concrete/concreteSlump/ExportConcreteSlumpTable';
import ExportConcreteSlumpFlowTable from '@Src/app/module/test/concrete/concreteSlumpFlow/ExportConcreteSlumpFlowTable';
import ExportConcreteStrengthTable from '@Src/app/module/test/concrete/concreteStrength/ExportConcreteStrengthTable';
import ExportUTestTable from '@Src/app/module/test/concrete/uTest/ExportUTestTable';
import ExportVTestTable from '@Src/app/module/test/concrete/vTest/ExportVTestTable';
import ExportAmsTable from '@Src/app/module/test/sandRock/ams/ExportAmsTable';
import ExportAutoclaveTestTable from '@Src/app/module/test/sandRock/autoclaveTest/ExportAutoclaveTestTable';
import ExportChlorideTable from '@Src/app/module/test/sandRock/chloride/ExportChlorideTable';
import ExportCoarseAbrasiveTable from '@Src/app/module/test/sandRock/coarseAbrasive/ExportCoarseAbrasiveTable';
import ExportCoarseAggOblatenessTable from '@Src/app/module/test/sandRock/coarseAggOblateness/ExportCoarseAggOblatenessTable';
import ExportCoarseAggSgWabsTable from '@Src/app/module/test/sandRock/coarseAggSgWabs/ExportCoarseAggSgWabsTable';
import ExportFineAggSgWabsTable from '@Src/app/module/test/sandRock/fineAggSgWabs/ExportFineAggSgWabsTable';
import ExportHighTempCookingTable from '@Src/app/module/test/sandRock/highTempCooking/ExportHighTempCookingTable';
import ExportMoistureContentTable from '@Src/app/module/test/sandRock/moistureContent/ExportMoistureContentTable';
import ExportRmbtTable from '@Src/app/module/test/sandRock/rmbt/ExportRmbtTable';
import ExportXrfTable from '@Src/app/module/test/sandRock/xrf/ExportXrfTable';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Stack } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TestCardContainer, TitleTypography } from '../../MuiStyleComponent/TestComponent';
import YatungLoading from '../../YatungLoading';
import ColFieldInfo from '../ColFieldInfo';
import TestFormConcreteHeader from '../TestFormConcreteHeader';
import TestFormHeader from '../TestFormHeader';
import CoarseSieveAnalysisCommonTable from './CoarseSieveAnalysisCommonTable';
import FineSieveAnalysisCommonTable from './FineSieveAnalysisCommonTable';

interface Props {
  testDetail: AllTest;
  testDataByCementCompStr:
  | GroupDataByFlyAshActivityResponse
  | GroupDataBySlagActivityResponse
  | Array<GroupDataByCementCompStrResponse>
  | undefined;
  allTable?: boolean;
  canEdit?: boolean;
}

function SwitchTestTable({ testDetail, testDataByCementCompStr, allTable, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  const [qrcodeData, setQrcodeData] = useState<QrcodeData>();
  const [open, setOpen] = useState<boolean>(true);

  const [updateTestDetail, setUpdateTestDetail] = useState<AllTest>(testDetail);
  const [updateTestDataByCementCompStr, setUpdateTestDataByCementCompStr] = useState<
    | GroupDataByFlyAshActivityResponse
    | GroupDataBySlagActivityResponse
    | Array<GroupDataByCementCompStrResponse>
    | undefined
  >(testDataByCementCompStr);

  const [loading, setLoading] = useState<boolean>(false);

  const afterUpdateTest = useCallback((test: AllTest) => {
    setLoading(true);

    TestApi.getTestDetail(
      { exptId: test.id, exptItemCode: test.exptItemCode },
      (_data) => setUpdateTestDetail(_data),
      (err) => console.log(err),
      () => setLoading(false),
    );

    switch (test.exptItemCode) {
      case TestItemEnum.FLY_ASH_ACTIVITY_TEST:
        TestApi.getGroupDataByFlyAshActivity(
          { exptId: test.id },
          (data) => setUpdateTestDataByCementCompStr(data),
          undefined,
          () => setLoading(false),
        );
        break;
      case TestItemEnum.SLAG_ACTIVITY_TEST:
        TestApi.getGroupDataBySlagActivity(
          { exptId: test.id },
          (data) => setUpdateTestDataByCementCompStr(data),
          undefined,
          () => setLoading(false),
        );
        break;
      case TestItemEnum.CEMENT_COMP_STR_TEST:
        TestApi.getGroupDataByCementCompStr(
          { exptId: test.id },
          (data) => setUpdateTestDataByCementCompStr(data),
          undefined,
          () => setLoading(false),
        );
        break;
      default:
    }
  }, []);

  useEffect(() => {
    setUpdateTestDataByCementCompStr(testDataByCementCompStr);
  }, [testDataByCementCompStr]);

  useEffect(() => {
    switch (testDetail.exptItemCode) {
      case TestItemEnum.CHEM_ADMX_COMP_STR:
      case TestItemEnum.CHEM_ADMX_PENTA_TEST:
      case TestItemEnum.MIXING_WATER_CHL_CONTENT:
      case TestItemEnum.RECLAIMED_WATER_CHL_CONTENT:
      case TestItemEnum.RECLAIMED_WATER_SOLID_CONTENT:
      case TestItemEnum.RECLAIMED_WATER_SPECIFIC_WEIGHT:
      case TestItemEnum.CONCRETE_SLUMP_TEST:
      case TestItemEnum.CONCRETE_SLUMP_FLOW_TEST:
      case TestItemEnum.CONCRETE_V_FUNNEL_TEST:
      case TestItemEnum.CONCRETE_U_BOX_TEST:
      case TestItemEnum.CONCRETE_COMP_STR_TEST:
        break;
      default:
        QrcodeApi.getQrcodeData(
          {
            ...(testDetail?.sampleId && { sampleId: testDetail?.sampleId }),
            ...(testDetail?.materialReceiptId && { materialReceiptId: testDetail?.materialReceiptId }),
            ...(testDetail?.specimen?.id && { specimenId: testDetail?.specimen?.id }),
          },
          (_data) => setQrcodeData(_data),
        );
    }
  }, [testDetail]);

  useEffect(() => {
    setUpdateTestDetail(testDetail);
  }, [testDetail]);

  const CollapseCardContainer = ({ children }: { children: JSX.Element | null }): JSX.Element | null => {
    return (
      <Stack position={'relative'} my={2}>
        <TestCardContainer
          style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: open ? '20px' : 5 }}
        >
          <TitleTypography>試驗結果</TitleTypography>
          <IconButton
            sx={{
              height: 40,
              width: 40,
            }}
            onClick={() => setOpen((prev) => !prev)}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TestCardContainer>
        {open && children}
      </Stack>
    );
  };

  const props = { testDetail: updateTestDetail, afterUpdateTest, canEdit };

  const showCommonTable = (): JSX.Element | null => {
    switch (testDetail.exptItemCode) {
      // 粒料試驗
      case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS: // 細粒料篩分析
        return <FineSieveAnalysisCommonTable {...props} />;
      case TestItemEnum.AMS_ANALYSIS: // "砂_AMS爐碴檢測"
        return <ExportAmsTable {...props} />;
      case TestItemEnum.XRF_ANALYSIS: // "砂_XRF成分分析"
        return <ExportXrfTable {...props} />;
      case TestItemEnum.HIGH_TEMP_COOKING_ANALYSIS: // "砂_高溫蒸煮試驗"
        return <ExportHighTempCookingTable {...props} />;
      case TestItemEnum.AUTOCLAVE_TEST: // "砂_熱壓膨脹試驗"
        return <ExportAutoclaveTestTable {...props} />;
      case TestItemEnum.FINE_AGG_SG_WABS_ANALYSIS: // "砂_細粒料比重吸水率"
        return <ExportFineAggSgWabsTable {...props} />;
      case TestItemEnum.RMBT: // "砂_亞甲基藍試驗"
        return <ExportRmbtTable {...props} />;
      case TestItemEnum.CHLORIDE_ION_CONTENT_TEST: // "砂_氯離子含量試驗"
        return <ExportChlorideTable {...props} />;
      case TestItemEnum.SAND_MOISTURE_CONTENT_TEST: // "砂_總含水率試驗"
      case TestItemEnum.STONE_MOISTURE_CONTENT_TEST: // "石_總含水率試驗"
        return <ExportMoistureContentTable {...props} />;
      case TestItemEnum.COARSE_AGG_SG_WABS_ANALYSIS: // "石_粗粒料比重吸水率"
        return <ExportCoarseAggSgWabsTable {...props} />;
      case TestItemEnum.OBLATENESS_TEST: // "石_扁平率試驗"
        return <ExportCoarseAggOblatenessTable {...props} />;
      case TestItemEnum.LOS_ANGELES_ABRASION_TEST: // 石_洛杉磯試驗
        return <ExportCoarseAbrasiveTable {...props} />;
      case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS: // 石_粗粒料篩分析
        return <CoarseSieveAnalysisCommonTable {...props} />;

      //   // 拌合水 & 回收水
      case TestItemEnum.MIXING_WATER_CHL_CONTENT: // 拌合水_氯離子試驗
        return <ExportMixingChlorideTable {...props} />;
      case TestItemEnum.RECLAIMED_WATER_CHL_CONTENT: // 回收水_氯離子試驗
        return <ExportRecycleChlorideTable {...props} />;
      case TestItemEnum.RECLAIMED_WATER_SPECIFIC_WEIGHT: // 回收水_比重試驗
        return <ExportRecycleSpecificGravityTable {...props} />;

      //   // 膠結材 (粉料)
      case TestItemEnum.SLAG_SPECIFIC_SURFACE: // "爐石_比表面積"
      case TestItemEnum.CEMENT_SPECIFIC_SURFACE: // "水泥_比表面積",
      case TestItemEnum.FLY_ASH_SPECIFIC_SURFACE_TEST: // "飛灰_比表面積試驗"
        return <ExportSpecificSurfaceTable {...props} />;
      case TestItemEnum.FLY_ASH_L_VALUE_TEST: // 飛灰_L值試驗
        return <ExportFlyAshLValueTable {...props} />;
      case TestItemEnum.FLY_ASH_LOSS_ON_IGNITION_TEST: // 飛灰_燒失量
        return <ExportFlyAshLossOnIgnitionTable {...props} />;
      case TestItemEnum.FLY_ASH_ACTIVITY_TEST: // 飛灰_活性試驗
        return !Array.isArray(updateTestDataByCementCompStr) ? (
          <FlyAshActivityCompleteTable {...props} testData={updateTestDataByCementCompStr} />
        ) : null;
      case TestItemEnum.FLY_ASH_WATER_DEMAND_TEST: // 飛灰_需水量
        return <ExportFlyAshWaterDemandTable {...props} />;
      case TestItemEnum.SLAG_ACTIVITY_TEST: // 爐石_活性試驗
        return !Array.isArray(updateTestDataByCementCompStr) ? (
          <SlagActivityCompleteTable {...props} testData={updateTestDataByCementCompStr} />
        ) : null;
      case TestItemEnum.SLAG_WATER_DEMAND_TEST: // 爐石_需水量
        return <ExportSlagWaterDemandTable {...props} />;
      case TestItemEnum.CEMENT_COMP_STR_TEST: // 水泥_強度
        return (
          <>
            {Array.isArray(updateTestDataByCementCompStr) &&
              updateTestDataByCementCompStr.map(
                (value, index) =>
                  value.age &&
                  value.specimen && (
                    <ExportCementCompStrTable
                      {...props}
                      key={index}
                      formData={{
                        age: value.age,
                        id: value.id,
                        specimenId: value.specimen.id,
                        specimenData: [
                          ...(value.specimen1CompStr ? [value.specimen1CompStr] : []),
                          ...(value.specimen2CompStr ? [value.specimen2CompStr] : []),
                          ...(value.specimen3CompStr ? [value.specimen3CompStr] : []),
                        ],
                      }}
                    />
                  ),
              )}
          </>
        );
      case TestItemEnum.FLY_ASH_SLUMP_TEST: // 飛灰_淨漿流度
        return <ExportFlyAshSlumpTable {...props} />;

      // 膠結材 (液料)
      case TestItemEnum.CHEM_ADMX_SOLID_CONTENT: // 藥劑_固含量
        return <ExportChemicalSolidContentTable {...props} />;
      case TestItemEnum.CHEM_ADMX_SPECIFIC_WEIGHT: // 藥劑_比重
        return <ExportChemicalSpecificGravityTable {...props} />;
      case TestItemEnum.CHEM_ADMX_CHL_CONTENT: // 藥劑_氯離子
        return <ExportChemicalChlorideTable {...props} />;
      default:
        return null;
    }
  };

  const showChemAdmxMulti = (): JSX.Element | null => {
    switch (testDetail.exptItemCode) {
      case TestItemEnum.CHEM_ADMX_COMP_STR:
        return <ExportChemAdmxActivityTable {...props} />;
      case TestItemEnum.CHEM_ADMX_PENTA_TEST:
        return <ExportChemAdmxPentaTable {...props} />;
      default:
        return null;
    }
  };

  const showConcreteTable = (): JSX.Element | null => {
    switch (testDetail.exptItemCode) {
      case TestItemEnum.CONCRETE_SLUMP_FLOW_TEST: // 混凝土_坍流度
        return <ExportConcreteSlumpFlowTable {...props} />;
      case TestItemEnum.CONCRETE_SLUMP_TEST: // 混凝土_坍度
        return <ExportConcreteSlumpTable {...props} />;
      case TestItemEnum.CONCRETE_V_FUNNEL_TEST: // 混凝土_V型試驗
        return <ExportVTestTable {...props} />;
      case TestItemEnum.CONCRETE_U_BOX_TEST: // 混凝土_U型試驗
        return <ExportUTestTable {...props} />;
      case TestItemEnum.CONCRETE_COMP_STR_TEST: // 混凝土_抗壓
        return <ExportConcreteStrengthTable {...props} allTable={allTable} />;
      default:
        return null;
    }
  };

  const showTable = (): JSX.Element | null => {
    switch (testDetail.exptItemCode) {
      case TestItemEnum.CONCRETE_SLUMP_TEST:
      case TestItemEnum.CONCRETE_SLUMP_FLOW_TEST:
      case TestItemEnum.CONCRETE_V_FUNNEL_TEST:
      case TestItemEnum.CONCRETE_U_BOX_TEST:
      case TestItemEnum.CONCRETE_COMP_STR_TEST:
        return (
          <>
            <TestCardContainer>
              <TestFormConcreteHeader testDetail={updateTestDetail} />
            </TestCardContainer>
            {loading ? <YatungLoading overlay={true} /> : showConcreteTable()}
          </>
        );
      case TestItemEnum.CHEM_ADMX_COMP_STR:
      case TestItemEnum.CHEM_ADMX_PENTA_TEST:
        return loading ? <YatungLoading overlay={true} /> : showChemAdmxMulti();
      default:
        return (
          <>
            {(qrcodeData || testDetail?.createTime) && (
              <TestCardContainer>
                <TestFormHeader qrcodeData={qrcodeData} testDetail={updateTestDetail} />
              </TestCardContainer>
            )}

            {!!testDetail?.exptUnitName && (
              <TestCardContainer>
                <ColFieldInfo
                  title={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_UNIT')}
                  titleStyle={{
                    fontSize: '24px',
                    fontWeight: '900',
                    color: '#003087',
                  }}
                >
                  {updateTestDetail.exptUnitName}
                </ColFieldInfo>
              </TestCardContainer>
            )}
            <CollapseCardContainer>
              {loading ? <YatungLoading overlay={true} /> : showCommonTable()}
            </CollapseCardContainer>
          </>
        );
    }
  };

  return showTable();
}

export default memo(SwitchTestTable);
