import { Box } from '@mui/material';
import React from 'react';

const background = '/media/images/home_bg.jpg';

export default function HomePage() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        height: '100%',
        backgroundColor: '#F2F2F2',
      }}
    ></Box>
  );
}
