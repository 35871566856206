import YatungButton from '@Src/_basic/components/YatungButton';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { KeyManagementData, PostParams } from '@Src/_basic/object/KeyManagementType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { KeyManagementApi } from '@Src/_basic/protocol/keyManagement/KeyManagementApi';
import { Grid, Pagination, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyNoteModal from './KeyNoteModal';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import CodeColumnFormatter from './columnFormatter/CodeColumnFormatter';
import CreateTimeColumnFormatter from './columnFormatter/CreateTimeColumnFormatter';
import DescriptionColumnFormatter from './columnFormatter/DescriptionColumnFormatter';
import StateColumnFormatter from './columnFormatter/StateColumnFormatter';

interface Props {
  ids: any;
  email: string;
  account: string;
}
export default function KeyManagementTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { ids, email, account } = props;

  const [keyManagementData, setkeyManagementData] = useState<Array<KeyManagementData>>([]);
  const [newKeyManagementData, setNewKeyManagementData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [clickButton, setClickButton] = useState<boolean>(true);
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [isNoteAlert, setIsNoteAlert] = useState<boolean>(false);
  const [noteAlertText, setNoteAlertText] = useState<string>('');
  const [noteId, setNoteId] = useState<number>(0);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 1,
    pageSize: 5,
    total: 0,
    totalCount: 0,
  });
  const [notes, setNotes] = useState<string>('');

  const getNewFactoryData = () => {
    const keyManagementDataTemp = keyManagementData?.map((data: KeyManagementData, index: number) => {
      return { ...data, newId: index + 1 + (pageInfo.page - 1) * pageInfo.pageSize };
    });
    setNewKeyManagementData(keyManagementDataTemp);
  };
  useEffect(() => {
    getNewFactoryData();
  }, [keyManagementData]);

  useEffect(() => {
    const { pageSize } = pageInfo;

    setLoading(true);
    KeyManagementApi.getApplySearch(
      { account: account, page: pageInfo.page, size: pageSize, sort: 'createTime,asc' },
      (res) => {
        setkeyManagementData(res.data);
        setPageInfo({
          ...pageInfo,
          page: pageInfo.page,
          total: res.maxPage,
        });
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [ids, pageInfo.page]);

  const getButtonText = (status: string) => {
    switch (status) {
      case '註銷':
        return i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.LOGOUT');
      case '備註':
        return i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.NOTE');
      case '逾期':
        return i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.OVERDUE');
      case '啟用':
        return i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.ENABLE');
      default:
        return '';
    }
  };

  const handleLogout = (id: number) => {
    const { pageSize } = pageInfo;
    setLoading(true);

    KeyManagementApi.getApplyCancel(
      id,
      (res) => {
        KeyManagementApi.getApplySearch(
          { account: account, page: pageInfo.page, size: pageSize, sort: 'createTime,asc' },
          (res) => {
            setkeyManagementData(res.data);
            setPageInfo({
              ...pageInfo,
              page: pageInfo.page,
              total: res.maxPage,
            });
          },
          undefined,
          undefined,
        );
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };

  const handleGoToNoteModal = (id: number, description: string) => {
    setNoteId(id);
    setNotes(description);
    setNoteModalOpen(true);
  };
  const handleCloseNoteModal = () => {
    setNoteModalOpen(false);
  };

  const handleCreateKey = () => {
    const { pageSize } = pageInfo;

    const params: PostParams = {
      email: email,
      account: account,
    };
    setLoading(true);

    KeyManagementApi.postApplySend(
      params,
      (res) => {
        setIsShowAlert(true);
        setAlertText(i18T('GLOBAL.KEY_ALERT'));
        if (clickButton) {
          setClickButton(false);
          setTimeout(() => {
            // 7秒后启用按钮
            setClickButton(true);
          }, 7000); // 7秒 = 7000毫秒
        }
        KeyManagementApi.getApplySearch(
          { account: account, page: pageInfo.page, size: pageSize, sort: 'createTime,asc' },
          (res) => {
            setkeyManagementData(res.data);
            setPageInfo({
              ...pageInfo,
              page: pageInfo.page,
              total: res.maxPage,
            });
          },
          undefined,
          undefined,
        );
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };

  const handleSubmit = () => {
    const { pageSize } = pageInfo;
    setLoading(true);

    KeyManagementApi.putApplyDescription(
      { id: noteId, description: notes },
      (res) => {
        KeyManagementApi.getApplySearch(
          { account: account, page: pageInfo.page, size: pageSize, sort: 'createTime,asc' },
          (res) => {
            setkeyManagementData(res.data);
            setPageInfo({
              ...pageInfo,
              page: pageInfo.page,
              total: res.maxPage,
            });
          },
          undefined,
          undefined,
        );
      },
      undefined,
      () => {
        handleCloseNoteModal();
        setLoading(false);
      },
    );
  };

  const columns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.TABLE.NO'),
      dataField: 'newId',
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.KEY'),
      dataField: 'code',
      formatter: CodeColumnFormatter,
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.STATE'),
      dataField: 'isStatus',
      formatter: StateColumnFormatter,
      formatExtraData: {
        getButtonText: getButtonText,
      },
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.CREATE_TIME'),
      dataField: 'createTime',
      formatter: CreateTimeColumnFormatter,
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.NOTE'),
      dataField: 'description',
      formatter: DescriptionColumnFormatter,
      formatExtraData: {
        setIsNoteAlert: setIsNoteAlert,
        isNoteAlert: isNoteAlert,
        setNoteAlertText: setNoteAlertText,
        noteAlertText: noteAlertText,
      },
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onLogout: handleLogout,
        onGoToNoteModal: handleGoToNoteModal,
        getButtonText: getButtonText,
      },
    },
  ];
  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} sx={{ my: 1 }}>
        <Typography sx={{ color: '#6C708C', fontSize: 28 }}>
          {i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.KEY_MANAGEMENT')}
        </Typography>
        <YatungButton
          text={i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.CREATE_KEY')}
          color={'green'}
          variant="contained"
          size="large"
          onClick={handleCreateKey}
          disabled={!clickButton || ids === undefined || loading}
        />
      </Stack>
      <YatungTable columns={columns} data={newKeyManagementData} loading={loading} />
      <Grid container justifyContent={'center'} item xs={12} sx={{ mt: 2 }}>
        <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
      </Grid>
      <YatungConfirmAlert
        headerText={i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.CREATE_KEY')}
        isShowAlert={isShowAlert}
        onClose={() => setIsShowAlert(false)}
        alertText={alertText}
      />
      <YatungConfirmAlert
        isKeyManagement
        headerText={i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.NOTE')}
        isShowAlert={isNoteAlert}
        onClose={() => setIsNoteAlert(false)}
        noteText={noteAlertText}
      />
      <KeyNoteModal
        open={noteModalOpen}
        onModalClose={handleCloseNoteModal}
        onSubmit={handleSubmit}
        setNotes={setNotes}
        notes={notes}
      />
    </>
  );
}
