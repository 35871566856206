import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { UserApiPermissionHelpers } from '@Src/_basic/helpers/UserApiPermissionHelpers';
import { CreateExptScheduleFormType } from '@Src/_basic/object/ExptScheduleType';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { Box, SelectChangeEvent, Stack, Typography, styled } from '@mui/material';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import PeriodForm from '../PeriodForm';
import MemoCard from './components/MemoCard';
import RequirementCard from './components/RequirementCard';
import SelectExptCard from './components/SelectExptCard';

const Label = styled(Typography)(({ theme }) => ({
  color: '#6C708C',
  fontSize: '20px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
}));

const schema = Yup.object({
  exptItems: Yup.array()
    .of(
      Yup.object({
        exptItemId: Yup.number().required('請選擇試驗'),
        operator: Yup.string().required('請指派人員'),
      }),
    )
    .required('請至少選擇一個試驗')
    .min(1, '請至少選擇一個試驗'),
});

const testSchema = Yup.object({
  exptItems: Yup.array().required('請選擇試驗').min(1, '請至少選擇一個試驗'),
});
interface Props {
  initialValues: CreateExptScheduleFormType;
  onSubmit: (request: CreateExptScheduleFormType) => void;
  onSubmitByTest: (request: CreateExptScheduleFormType) => void;
}

export default function CreateExptScheduleForm({ initialValues, onSubmit, onSubmitByTest }: Props) {
  // TODO using i18n
  const { actionLoading } = useApi();
  const { userApiPermission, userAreaOptionsData } = useAuth();

  const handleSubmit = (values: CreateExptScheduleFormType) => {
    console.log('CreateExptScheduleForm', values);
    onSubmit(values);
  };

  const handleSubmitByTest = (values: CreateExptScheduleFormType) => {
    console.log('CreateExptScheduleFormByTest', values);
    onSubmitByTest(values);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: UserApiPermissionHelpers('WD063', userApiPermission) ? schema : testSchema,
    onSubmit: UserApiPermissionHelpers('WD063', userApiPermission) ? handleSubmit : handleSubmitByTest,
    enableReinitialize: true,
  });

  const factoryOptions = useMemo(() => {
    return userAreaOptionsData.find((option) => option.value === formik.values.areaId)?.factories || [];
  }, [userAreaOptionsData, formik.values.areaId]);

  const handleChangeArea = (e: SelectChangeEvent<unknown>) => {
    formik.setFieldValue('areaId', e.target.value as number);
    formik.setFieldValue('factoryId', 0);
  };
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Box>
          <Stack direction="row" spacing={2} sx={{ py: 1 }}>
            <YatungSelect
              disabled={userAreaOptionsData.length === 0 || actionLoading}
              options={[...userAreaOptionsData]}
              value={formik.values.areaId}
              onChange={handleChangeArea}
            />
            <YatungSelect
              disabled={factoryOptions.length === 0 || actionLoading}
              options={[...factoryOptions]}
              value={formik.values.factoryId}
              onChange={(e) => formik.setFieldValue('factoryId', e.target.value)}
            />
          </Stack>
          <Box>
            {UserApiPermissionHelpers('WD063', userApiPermission) ? (
              <YatungRadioButton
                groupName="isRequirement"
                value={formik.values.isRequirement}
                onChange={(e) => formik.setFieldValue('isRequirement', e.target.value)}
                controlData={[
                  { label: '需求單', value: true },
                  { label: '試驗單', value: false },
                ]}
                labelTextStyle={{
                  fontSize: '24px',
                  fontWeight: '900',
                  fontFamily: 'Microsoft JhengHei',
                  color: '#002F8C',
                }}
                sx={{
                  color: '#003087',
                  '&.Mui-checked': {
                    color: '#003087',
                  },
                }}
              />
            ) : (
              <YatungRadioButton
                groupName="isTest"
                value={formik.values.isTest}
                onChange={(e) => formik.setFieldValue('isTest', e.target.value)}
                controlData={[{ label: '試驗單', value: true }]}
                labelTextStyle={{
                  fontSize: '24px',
                  fontWeight: '900',
                  fontFamily: 'Microsoft JhengHei',
                  color: '#002F8C',
                }}
                sx={{
                  color: '#003087',
                  '&.Mui-checked': {
                    color: '#003087',
                  },
                }}
              />
            )}
          </Box>
        </Box>
        <Box>{/* 掃碼槍在這 */}</Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '29px',
        }}
        gap="16px"
      >
        {UserApiPermissionHelpers('WD063', userApiPermission) ? (
          <>
            <RequirementCard formik={formik} />
            <SelectExptCard formik={formik} />
            <MemoCard formik={formik} invisible={formik.values.isRequirement !== 'true'} />
          </>
        ) : (
          <>
            <RequirementCard formik={formik} />
            <SelectExptCard formik={formik} />
            <MemoCard formik={formik} invisible={formik.values.isRequirement !== 'false'} />
          </>
        )}
      </Box>
      <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '51px' }}>
        {UserApiPermissionHelpers('WD063', userApiPermission) ? (
          <Box sx={{ opacity: formik.values.isRequirement === 'true' ? 1 : 0 }}>
            <Stack gap="20px">
              <Box sx={{ padding: '10px 0px 10px 0px', borderBottom: '1px #697EA3 solid' }}>
                <Label>排程設定</Label>
              </Box>

              <Stack direction="row" alignItems="center">
                <Label>名稱：</Label>
                <YatungInput
                  name="title"
                  defaultValue={formik.values.title}
                  onBlur={(e: any) => formik.setFieldValue('title', e.target.value)}
                />
              </Stack>
              <Stack direction="row" alignItems="flex-start">
                <Label>排程設定：</Label>
                <PeriodForm
                  periodType={formik.values.period?.periodType}
                  periodWeekDays={formik.values.period?.periodWeekDays}
                  periodDates={formik.values.period?.periodDates}
                  setFieldValue={formik.setFieldValue}
                  style={{ marginLeft: '50px' }}
                />
              </Stack>
            </Stack>
          </Box>
        ) : (
          <Box sx={{ opacity: formik.values.isRequirement === 'false' ? 1 : 0 }}>
            <Stack gap="20px">
              <Box sx={{ padding: '10px 0px 10px 0px', borderBottom: '1px #697EA3 solid' }}>
                <Label>排程設定</Label>
              </Box>

              <Stack direction="row" alignItems="center">
                <Label>名稱：</Label>
                <YatungInput
                  name="title"
                  defaultValue={formik.values.title}
                  onBlur={(e: any) => formik.setFieldValue('title', e.target.value)}
                />
              </Stack>
              <Stack direction="row" alignItems="flex-start">
                <Label>排程設定：</Label>
                <PeriodForm
                  periodType={formik.values.period?.periodType}
                  periodWeekDays={formik.values.period?.periodWeekDays}
                  periodDates={formik.values.period?.periodDates}
                  setFieldValue={formik.setFieldValue}
                  style={{ marginLeft: '50px' }}
                />
              </Stack>
            </Stack>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <YatungButton
            color="green"
            text="完成"
            onClick={() => {
              formik.submitForm();
            }}
          />
        </Box>
      </Stack>
    </>
  );
}
