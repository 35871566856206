import YatungButton from '@Src/_basic/components/YatungButton';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import PersonIcon from '@Src/_basic/icons/PersonIcon';
import { CreateEntityRequest } from '@Src/_basic/object/EntityType';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FactoryAndStationName } from '../../../screens/EntityScreen';

interface Props {
  values: CreateEntityRequest;
  setFieldValue: (name: string, value: string) => void;
  factoryAndStationOptions: FactoryAndStationName;
  handleClose: () => void;
}
export default function EntityCreateField({ values, setFieldValue, handleClose, factoryAndStationOptions }: Props) {
  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack flexDirection={'column'} sx={{ mt: 1 }}>
        <YatungFormLabel label={i18T('ENTITYMANAGEMENT.ENTITY')} sx={{ my: 1 }} />
        <YatungInput
          id="name"
          name="name"
          value={values.name}
          icon={<PersonIcon sx={{ width: 30, height: 30 }} />}
          onChange={(e: any) => setFieldValue('name', e.target.value)}
          sx={{ width: '100%' }}
        />
        <YatungFormLabel label={i18T('ENTITYMANAGEMENT.FACTORY_TYPE')} sx={{ my: 1 }} />
        <YatungSelect
          disabled={true}
          options={[factoryAndStationOptions.factory]}
          value={values.factory_id}
          width={'100%'}
        />

        <YatungFormLabel label={i18T('ENTITYMANAGEMENT.SITE_TYPE')} sx={{ my: 1 }} />
        <YatungSelect
          id="stationId"
          disabled={true}
          options={[factoryAndStationOptions.station]}
          value={values.stationId}
          width={'100%'}
        />
        <Stack
          spacing={3}
          sx={{ bgcolor: 'white', p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
          display="flex"
          flexDirection="column"
        >
          <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <YatungButton
              text={i18T('ENTITYMANAGEMENT.CANCEL')}
              onClick={handleClose}
              sx={{
                color: '#ffffff',
                bgcolor: '#CB333B',
                fontSize: '24px',
                width: 120,
                mr: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#CB333B',
                },
              }}
            />
            <YatungButton
              text={i18T('ENTITYMANAGEMENT.COMPLETE')}
              type="submit"
              sx={{
                border: '1px solid #fff',
                color: '#ffffff',
                bgcolor: '#002F8C',
                fontSize: '24px',
                width: 120,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#002F8C',
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
