import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../_basic/components/YatungFormInput';

interface Props {
  initialValues: any;
  validationSchema: any;
  handleLogin: (require: any) => void;
}
export default function LoginFormCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { initialValues, validationSchema, handleLogin } = props;
  return (
    <Card sx={{ borderRadius: 5, border: '2px solid #ffffff' }}>
      <CardHeader
        title={i18T('GLOBAL.PLATFORM_NAME')}
        titleTypographyProps={{
          variant: 'h4',
          fontFamily: 'Inter',
          fontWeight: 600,
        }}
        sx={{
          textAlign: 'center',
          color: '#ffffff',
          background: '#003087',
          paddingX: 15,
        }}
      />
      <CardContent>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
          <Form>
            <Grid container justifyContent={'center'}>
              <Grid container justifyContent={'center'} item xs={12}>
                <Field
                  id="userName"
                  name="userName"
                  type="userName"
                  label=""
                  component={Input}
                  placeholder={i18T('AUTH.USERNAME_PLACEHOLDER')}
                  sx={{ width: '80%' }}
                  required
                />
              </Grid>
              <Grid container justifyContent={'center'} item xs={12} sx={{ mt: 3 }}>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  label=""
                  placeholder={i18T('AUTH.PASSWORD_PLACEHOLDER')}
                  sx={{ width: '80%' }}
                  component={Input}
                  required
                />
              </Grid>
            </Grid>

            <Grid container justifyContent={'center'} sx={{ my: 2 }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: '#ffffff',
                  bgcolor: '#003087',
                  fontSize: '36px',
                  px: 8,
                  py: 'auto',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#003087',
                  },
                }}
              >
                {i18T('AUTH.LOGIN')}
              </Button>
            </Grid>
            {/* <Link to={'/forget-password'}>{i18T('AUTH.FORGET_PASSWORD')}</Link> */}
          </Form>
        </Formik>
      </CardContent>
    </Card>
  );
}
