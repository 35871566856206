import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { GetLangData, LangData } from '../object/MultilingualManagementType';
import { LanguageApi } from '../protocol/languageManagement/LanguageApi';

export default function useI18n() {
  //將i18n包成function並分別傳出init和i18n本身
  const [isInitialized, setIsInitialized] = useState(false);

  const init = () => {
    //全部以init執行setIsInitialized在APP.tsx來判斷是否取到資料，取到資料後才會載入登入頁面
    const params: GetLangData = {
      platformId: 1,
    };
    LanguageApi.getLangData(params, (datas: Array<LangData>) => {
      //當成功時，將資料傳入不用分成兩個，直接在Success裡面做
      LanguageApi.getMultilingualPlatformAllFileData('web', (data: any) => {
        setIsInitialized(true); //讓登入頁面先載入
        i18n
          .use(initReactI18next)
          .init({
            compatibilityJSON: 'v3',
            lng: datas[0].name, //預設語言中文，取第一筆拿到的名稱當成預設語言
            fallbackLng: datas[0].name,
            resources: data,
            interpolation: {
              escapeValue: false,
            },
          });
      });
    }); //獲取語言選項
  };

  useEffect(() => {
    init();
  }, []);

  return {
    i18n,
    init,
    isInitialized,
  };
}
