import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungImg from '@Src/_basic/components/YatungImg';
import { InitialWeightUnitData, WeightUnitData } from '@Src/_basic/object/WeightUnitManageType';
import { Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicWeightMeasuresField from './BasicWeightMeasuresField';

const CreateUnitImage = '/media/images/unit.png';

interface Props {
  conversionValue: Array<WeightUnitData>;
  isMeasuresDialog: boolean;
  onMeasuresGoBackButtonClick: () => void;
}

export default function BasicWeightMeasuresForm(props: Props) {
  const { t: i18T } = useTranslation();
  const { isMeasuresDialog, onMeasuresGoBackButtonClick, conversionValue } = props;
  const [measuresValue, setMeasuresValue] = useState<InitialWeightUnitData>({
    ton: 0,
    meter: 0,
    kilometer: 0,
    cm: 0,
    psi: 0,
  });
  const handleSubmit = () => {
    console.log('values');
  };
  const getAllConversionValue = () => {
    const valueTon = conversionValue?.filter((value: WeightUnitData) => value.convertUnitId === 3);
    const valueKilometer = conversionValue?.filter((value: WeightUnitData) => value.convertUnitId === 4);
    const valueMeter = conversionValue?.filter((value: WeightUnitData) => value.convertUnitId === 5);
    const valueCm = conversionValue?.filter((value: WeightUnitData) => value.convertUnitId === 7);
    const valuePSI = conversionValue?.filter((value: WeightUnitData) => value.convertUnitId === 9);

    setMeasuresValue({
      ton: valueTon[0]?.convertValue,
      meter: valueMeter[0]?.convertValue,
      kilometer: valueKilometer[0]?.convertValue,
      cm: valueCm[0]?.convertValue,
      psi: valuePSI[0]?.convertValue,
    });
  };
  const initialValues: InitialWeightUnitData = {
    ton: measuresValue?.ton,
    meter: measuresValue?.meter,
    kilometer: measuresValue?.kilometer,
    cm: measuresValue?.cm,
    psi: measuresValue?.psi,
  };
  useEffect(() => {
    if (isMeasuresDialog) {
      getAllConversionValue();
    }
  }, [isMeasuresDialog]);
  return (
    <Formik initialValues={initialValues} onSubmit={() => handleSubmit()} enableReinitialize>
      {(formik) => {
        return (
          <Form>
            <YatungDialog
              isMeasures
              open={isMeasuresDialog}
              handleClose={onMeasuresGoBackButtonClick}
              icon={
                <YatungImg
                  img={CreateUnitImage}
                  sx={{
                    width: 28,
                    height: 28,
                    zIndex: 999,
                    alignSelf: 'center',
                    display: 'flex',
                    textAlign: 'center',
                    mr: 1,
                  }}
                />
              }
              title={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.BASIC_WEIGHTS_MEASURES')}
              action={
                <Stack direction="row" spacing={2}>
                  <YatungButton
                    text={i18T('GLOBAL.CANCEL')}
                    onClick={onMeasuresGoBackButtonClick}
                    color="red"
                    sx={{
                      fontSize: '20px',
                      width: 75,
                      alignSelf: 'center',
                    }}
                  />
                </Stack>
              }
              body={<BasicWeightMeasuresField />}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
