import { Grid, Pagination } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiTableType, ContentAndGroupsTableType } from '@Src/_basic/object/AccountRightType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { ApiApi } from '@Src/_basic/protocol/accountRights/fetAuth/api/ApiApi';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { MenuApi } from '@Src/_basic/protocol/accountRights/fetAuth/menu/MenuApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { useLocation } from 'react-router-dom';
import ApiGroupManagementCreateCard from '../ApiGroupManagementCreateCard';
import ApiGroupManagementUpdateCard from '../ApiGroupManagementUpdateCard';
import ApiGroupManagementSearchBar from './ApiGroupManagementSearchBar';
import ApiGroupManagementTable from './ApiGroupManagementTable';

type Qurey = {
  searchInput: string;
  page: number;
};

export default function ApiGroupManagementCard() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { setSaveRequest, request } = useRequestSaving<Qurey>();

  const initPageInfo = useMemo(
    () => ({
      page: location.pathname === request?.page && request?.request ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [location.pathname, request],
  );

  const initSearchInput = useMemo(
    () => (location.pathname === request?.page && request?.request ? request.request.searchInput : ''),
    [location.pathname, request],
  );

  const [pageInfo, setPageInfo] = useState<PageInfo>(initPageInfo);
  const [searchInput, setSearchInput] = useState<string>(initSearchInput);

  const effectRef = useRef<boolean>(false);
  const [isVisable, setIsVisable] = useState<boolean>(false);
  const [modelOpen, setModelOpen] = useState<'CREATE' | 'UPDATE' | 'DELETE' | null>(null);

  const [apiList, setApiList] = useState<Array<ApiTableType>>([]);
  const [apiGroupData, setApiGroupData] = useState<Array<ContentAndGroupsTableType>>([]);
  const [selectedGroupData, setSelectedGroupData] = useState<ContentAndGroupsTableType | undefined>(undefined);

  const updateAndDeleteModel = useCallback((data: ContentAndGroupsTableType, model: 'UPDATE' | 'DELETE') => {
    setModelOpen(model);
    setSelectedGroupData(data);
  }, []);

  const closeModel = useCallback(() => {
    setModelOpen(null);
    setSelectedGroupData(undefined);
  }, []);

  const searchInputHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value);
      setPageInfo({
        ...pageInfo,
        page: 1,
      });
      setIsVisable(false);
    },
    [pageInfo],
  );

  const getAllApiGroup = useCallback(
    (page: number) => {
      MenuApi.postGetRightsMenus(
        {
          pageNumber: page - 1,
          pageSize: pageInfo.pageSize,
          keywords: [searchInput],
        },
        (_data) => {
          setApiGroupData(
            _data.content.map((item, index) => ({
              ...item,
              index: index + 1 + (page - 1) * pageInfo.pageSize,
            })),
          );
          setPageInfo({
            ...pageInfo,
            page,
            total: _data.totalPages,
          });
          setSaveRequest({
            page: location.pathname,
            request: {
              searchInput,
              page,
            },
          });
          setIsVisable(true);
        },
      );
    },
    [pageInfo, searchInput, location.pathname, setSaveRequest],
  );

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      setPageInfo({
        ...pageInfo,
        page,
      });
      getAllApiGroup(page);
    },
    [pageInfo, getAllApiGroup],
  );

  const postGetRightsApis = useCallback(() => {
    ApiApi.postGetRightsApis(
      {
        pageNumber: 0,
        pageSize: 99999,
        state: '1',
      },
      (_data) => setApiList(_data.content.map((item) => ({ ...item, titleName: `${item.code}${item.name}` }))),
    );
  }, []);

  const getCurrentPageApiGroup = useCallback(() => getAllApiGroup(pageInfo.page), [pageInfo.page, getAllApiGroup]);

  const postDeleteMenuById = useCallback(() => {
    if (!selectedGroupData) return;
    MenuApi.postDeleteRightsMenu(selectedGroupData.id, getCurrentPageApiGroup);
  }, [getCurrentPageApiGroup, selectedGroupData]);

  useEffect(() => {
    postGetRightsApis();
  }, [postGetRightsApis]);

  useEffect(() => {
    if (!effectRef.current) {
      effectRef.current = true;
      getCurrentPageApiGroup();
    }
  }, [effectRef]);

  return (
    <>
      <YatungPage
        title={i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.ROUTE_TITLE')}
        body={
          <>
            <ApiGroupManagementSearchBar
              searchInput={searchInput}
              searchInputHandler={searchInputHandler}
              getAllApiGroup={getCurrentPageApiGroup}
              openCreateCard={() => setModelOpen('CREATE')}
            />
            {
              <YatungFadeInOut isVisible={isVisable} y={20}>
                <>
                  <ApiGroupManagementTable updateAndDeleteModel={updateAndDeleteModel} apiGroupData={apiGroupData} />
                  <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
                    <Pagination
                      count={pageInfo.total}
                      page={pageInfo.page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Grid>
                </>
              </YatungFadeInOut>
            }
          </>
        }
        contentBgColor="#FFF"
      />
      {modelOpen === 'CREATE' && (
        <ApiGroupManagementCreateCard
          open={modelOpen === 'CREATE'}
          closeModel={closeModel}
          apiList={apiList}
          getAllApiGroup={getCurrentPageApiGroup}
        />
      )}
      {!!selectedGroupData && modelOpen === 'UPDATE' && (
        <ApiGroupManagementUpdateCard
          open={modelOpen === 'UPDATE'}
          closeModel={closeModel}
          selectedGroupData={selectedGroupData}
          apiList={apiList}
          getAllApiGroup={getCurrentPageApiGroup}
        />
      )}
      <YatungConfirmAlert isShowAlert={modelOpen === 'DELETE'} onClose={closeModel} onOk={postDeleteMenuById} />
    </>
  );
}
