import { Response } from '@Src/_basic/object/ApiType';
import { PostMaterialNumberRequest, PutMaterialNumberRequest } from '@Src/_basic/object/MaterialNumberManageType';
import { AttributeData } from '@Src/_basic/object/AttributeType';
import { Api } from '../Api';
import { AttributeProtocol } from './AttributeProtocol';

export class AttributeApi {
  // 取得所有屬性
  public static getAllAttributes(
    onSuccess?: (data: Array<AttributeData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(AttributeProtocol.ALL_ATTRIBUTES, undefined, onSuccess, onFailed, onFinally);
  }

  // 更新來源
  public static putAttribute(
    request: PutMaterialNumberRequest,
    onSuccess?: (data: AttributeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(AttributeProtocol.ATTRIBUTE_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增屬性
  public static postAttribute(
    request: PostMaterialNumberRequest,
    onSuccess?: (data: AttributeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(AttributeProtocol.ATTRIBUTE_URL, request, undefined, onSuccess, onFailed, onFinally);
  }
}
