import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { memo } from 'react';
import YatungButton from '../YatungButton';

// 使用 styled 方法定義一個具有特定樣式的 Box 元素
const CustomBox = styled(Box)({
  background: '#ccc', // 使用你想要的顏色
  borderRadius: '4px',
  padding: '8px', // 使用你想要的 padding
});

// 使用 styled 方法定義一個具有特定樣式的 Typography 元素
const CustomTypography = styled(Typography)({
  color: 'white', // 使用你想要的文字顏色
  textAlign: 'center',
});

interface Props {
  list: Array<{ label: string; value: string }>;
  selected: string;
  onSelect: (value: string) => void;
}

function Tab({ list, selected, onSelect }: Props) {
  return (
    <Box display="flex" flexDirection="row" marginBottom={2} justifyContent="space-between">
      {list.map((value, index) => (
        <YatungButton
          key={index}
          width={400}
          color={selected === value.value ? 'blue' : 'inactive_blue'}
          style={{
            justifyContent: 'flex-end',
            flex: 1,
            ...(list.length - 1 > index && { marginRight: '1px' }),
          }}
          onClick={() => onSelect(value.value)}
        >
          <CustomTypography style={{ padding: 10 }}>{value.label}</CustomTypography>
        </YatungButton>
      ))}
    </Box>
  );
}

export default memo(Tab);
