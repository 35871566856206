import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';

import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungTextInput from '@Src/_basic/components/YatungTextInput';
import { CanAddMaterialPrice, PostMaterialPriceRequest } from '@Src/_basic/object/MaterialPriceType';
import { MaterialPriceApi } from '@Src/_basic/protocol/materialPrice/MaterialPriceApi';
import { SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import EditForm from '../../../components/EditForm';

const textStyle = { fontSize: 20 };

interface Props {
  open?: boolean;
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (data: PostMaterialPriceRequest) => void;
  title: string;
  icon: JSX.Element;
  factoryId: number;
  allTypes: Array<Options>;
}

export default function AddModal({ open, handleClose, handleSubmit, title, icon, factoryId, allTypes }: Props) {
  const { t: i18T } = useTranslation();

  const [selectType, setSelectType] = useState(0);

  const [allMaterialPriceList, setAllMaterialPriceList] = useState<Array<CanAddMaterialPrice>>([]);
  const [selectTypeMaterialPriceList, setSelectTypeMaterialPriceList] = useState<Array<CanAddMaterialPrice>>([]);
  const [selectedMaterialPrice, setSelectedMaterialPrice] = useState<number>(0);

  const [costInput, seCostInput] = useState<string>('');
  const [falseCostInput, setFalseCostInput] = useState<string>('');

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const onSubmit = useCallback(() => {
    const data = {
      specificationId: selectedMaterialPrice,
      cost: +costInput || 0,
      falseCost: +falseCostInput || 0,
      factoryId,
    };

    const isValid = Object.values(data).every((value) => value !== 0 && !isNaN(value));

    if (isValid) handleSubmit(data);
    else {
      setAlertOpen(true);
      setAlertText('請確認輸入的數值是否正確');
    }
  }, [costInput, falseCostInput, selectedMaterialPrice, factoryId, handleSubmit]);

  const getCanAddMaterialPrice = useCallback(() => {
    MaterialPriceApi.getCanAddMaterialPrice({ factoryId }, setAllMaterialPriceList);
  }, [factoryId]);

  const reset = useCallback((value = 0) => {
    setSelectType(value);
    setSelectedMaterialPrice(0);
    seCostInput('');
    setFalseCostInput('');
  }, []);

  const selectTypeHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const value = e.target.value as number;
      if (value) {
        setSelectTypeMaterialPriceList(allMaterialPriceList.filter((item) => item.typeId === value));
        reset(value);
      }
    },
    [allMaterialPriceList, reset],
  );

  useEffect(() => {
    setSelectType(0);
    getCanAddMaterialPrice();
  }, [factoryId, getCanAddMaterialPrice]);

  useEffect(() => {
    reset();
  }, [open, reset]);

  return (
    <YatungDialog
      open={open}
      handleClose={handleClose}
      icon={icon}
      title={title}
      body={
        <EditForm
          onSubmit={onSubmit}
          onCancel={handleClose}
          canSubmit={Boolean(selectedMaterialPrice && costInput && falseCostInput && factoryId)}
        >
          <Stack
            sx={{ pt: 4, pb: 2, position: 'relative' }}
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography sx={textStyle}>{`${i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE')}:`}</Typography>
              <YatungSelect options={allTypes} value={selectType} onChange={selectTypeHandler} />
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography sx={textStyle}>{`${i18T('APPLICATIONSMANAGEMENT.TABLE.MATERIAL_NAME')}:`}</Typography>
              <YatungSelect
                options={selectTypeMaterialPriceList?.map((item) => ({ value: item.id, text: item.name }))}
                value={selectedMaterialPrice ?? 0}
                onChange={(e) => setSelectedMaterialPrice(e.target.value as number)}
              />
            </Stack>
          </Stack>
          <Stack sx={{ pt: 2, pb: 4 }} direction="row" gap={1.5} alignItems="center" justifyContent="center">
            <Stack direction="row" alignItems="center">
              <Typography sx={{ ...textStyle, marginLeft: '5px' }}>{`${i18T(
                'APPLICATIONSMANAGEMENT.TABLE.COST',
              )}:`}</Typography>
              <YatungTextInput
                value={costInput}
                onChange={(e) => seCostInput(e.target.value)}
                size="small"
                style={{ width: '70%', marginLeft: '15px' }}
              />
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography sx={textStyle}>{`${i18T('APPLICATIONSMANAGEMENT.TABLE.FALSE_COST')}:`}</Typography>
              <YatungTextInput
                value={falseCostInput}
                onChange={(e) => setFalseCostInput(e.target.value)}
                size="small"
                style={{ width: '60%', marginLeft: '10px' }}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{ position: 'absolute', bottom: 20, right: 30 }}
            direction="column"
            alignItems="flex-end"
            justifyContent="center"
          >
            <Typography>{`${i18T('APPLICATIONSMANAGEMENT.TABLE.UPDATE_TIME')}`}</Typography>
            <Typography>{`${moment(new Date()).format('YYYY-MM-DD')}`}</Typography>
            <Typography>{`${moment(new Date()).format('HH:mm:ss')}`}</Typography>
          </Stack>
          <YatungConfirmAlert isShowAlert={alertOpen} onClose={() => setAlertOpen(false)} alertText={alertText} />
        </EditForm>
      }
    />
  );
}
