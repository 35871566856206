import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDatePicker from '@Src/_basic/components/YatungDatePicker';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import EditIcon from '@Src/_basic/icons/Edit';
import { MaterialReceiptsProtocol } from '@Src/_basic/protocol/materialReceipt/MaterialReceiptsProtocol';
import { Box, Dialog, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function MaterialReceiptAreaExportContent({ handleCloseModal }: any) {
  const { t: i18T } = useTranslation();
  const [dateTime, setDateTime] = useState<Date>(new Date());
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);

  const handleConfirmButtonClick = async () => {
    const url = `${MaterialReceiptsProtocol.GET_ALL_MATERIAL_RECEIPTS_EXCEL}?monthTime=${Number(dateTime)}`;

    try {
      const response = await fetch(url, {
        mode: 'no-cors',
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.text();
      //如果第一個字元是{，代表是錯誤訊息，否則就是檔案
      if (data[0] === '{') {
        setErrorMessageOpen(true);
      } else {
        window.location.href = url;
      }
    } catch (error) {
      console.error('error:', error);
    }
    //可能有更優下載解法，暫時用
  };

  const errorMessageClose = () => {
    setErrorMessageOpen(false);
  };

  return (
    <>
      <YatungDialog
        open={errorMessageOpen}
        handleClose={errorMessageClose}
        postion={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
        }}
        title={i18T('GLOBAL.WARN')}
        titleColor={{ color: '#CB333B', bgcolor: '#003087' }}
        body={
          <>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <Box component={'div'} sx={{ fontSize: 28, fontWeight: 400, fontFamily: 'Inter', color: '#7C878E' }}>
                {i18T('GLOBAL.EXPORT_DENIED')}
              </Box>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <YatungButton
                size={'large'}
                color="blue"
                type={'button'}
                text={i18T('GLOBAL.SURE')}
                sx={{
                  fontSize: '24px',
                  px: 2,
                }}
                onClick={errorMessageClose}
              />
            </Grid>
          </>
        }
      />
      <Box
        sx={{
          bgcolor: '#003087',
          color: 'white',
          boxShadow: 16,
          borderRadius: '20px',
          p: 2,
          opacity: 1,
          padding: '50px',
        }}
      >
        <Stack>匯出全區入料資訊</Stack>
        <Stack spacing={1} direction="row">
          <Grid container alignItems={'center'} item xs={12} direction={'column'}>
            <Grid style={{ display: 'flex', margin: '30px' }}>
              <Stack style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>選擇月份：</Stack>
              <YatungDatePicker
                label=""
                mode="Month"
                value={dateTime}
                disableFuture={true}
                onChange={(val: Date | null) => {
                  if (val) setDateTime(val);
                }}
              />
            </Grid>

            <Grid>
              <YatungButton
                text="取消"
                startIcon={<EditIcon />}
                onClick={handleCloseModal}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#CB333B',
                  fontSize: '24px',
                  width: 120,
                  mr: 1,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#CB333B',
                  },
                }}
              />
              <YatungButton
                text="匯出"
                startIcon={<EditIcon />}
                disabled={!dateTime}
                onClick={handleConfirmButtonClick}
                sx={{
                  border: '1px solid #fff',
                  color: '#ffffff',
                  bgcolor: '#002F8C',
                  fontSize: '24px',
                  width: 120,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#002F8C',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
}

export default function MaterialReceiptAreaExportModal({ open = false, ...props }: any) {
  return (
    <Dialog
      open={open}
      onClose={props.handleCloseModal}
      PaperProps={{ style: { borderRadius: 20, backgroundColor: '#003087' } }}
    >
      <MaterialReceiptAreaExportContent {...props} />
    </Dialog>
  );
}
