import { Box, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import YatungPage from '@Src/_basic/components/YatungPage';

import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { CalcProportion, generateAreaFactors } from '@Src/_basic/helpers/Proportion';
import { AreaData } from '@Src/_basic/object/AreaType';
import { FactoryItem } from '@Src/_basic/object/FactoryType';

import {
  ImportUpdateProportion,
  ProportionMaintenanceResponse,
  ProportionResponse,
} from '@Src/_basic/object/ProportionType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import { useAuth } from '@Src/redux/auth/authActions';
import EditProportionForm from '../../components/EditProportionForm';

interface AreaOptions extends Options {
  factories: Options[];
}

interface CaculateData {
  sandAndStoneWt: number;
  sandPct: number;
}

export default function ProportionEdit() {
  const { t: i18T } = useTranslation();
  const {
    state: { proportion, afterProportionMaintenance },
  } = useLocation();
  const { userGroupsData } = useAuth();

  // 選擇版本
  const [selectedVersion, setSelectedVersion] = useState<number>();
  // 版本Options
  const [versionOptions, setVersionOptions] = useState<Array<Options>>();
  // 當作initValue的proportion
  const [afterProportion, setAfterProportion] = useState<ProportionResponse>(proportion);
  // 計算後的配比參數
  const [caculatedData, setCaculatedData] = useState<CaculateData>({
    sandAndStoneWt: 0,
    sandPct: 0,
  });
  // 區選項
  const [areaItems, setAreaItems] = useState<Array<AreaOptions>>([]);
  // 廠選項
  const [factoryItems, setFactoryItems] = useState<Array<Options>>([]);
  // 選擇區
  const [selectedArea, setSelectedArea] = useState<Options>({
    value: 0,
    text: '請選擇 - - -',
  });
  // 選擇廠
  const [selectedFactory, setSelectedFactory] = useState<Options>({
    value: 0,
    text: '請選擇 - - -',
  });
  // 配比維護表
  const [proportionMaintenance, setProportionMaintenance] = useState<ProportionMaintenanceResponse | undefined>(
    proportion.maintProp,
  );

  // 帶入Form的預設值
  const initialValues: ImportUpdateProportion = useMemo(
    () => ({
      selectedMixer: afterProportion.selectedMixer,
      propName: afterProportion.propName,
      slump: afterProportion.slump,
      grainSize: afterProportion.grainSize,
      quantityMixingWater: afterProportion.quantityMixingWater,
      waterColloidRatio: Number(afterProportion.waterColloidRatio?.toFixed(2)),
      cementWt: Number(afterProportion.cementWt?.toFixed(2)),
      slagWt: Number(afterProportion.slagWt?.toFixed(2)),
      flyAshWt: Number(afterProportion.flyAshWt?.toFixed(2)),
      microsilicaWt: Number(afterProportion.microsilicaWt?.toFixed(2)),
      stone1Pct: Number(afterProportion.stone1Pct),
      stone2Pct: Number(afterProportion.stone2Pct),
      stone3Pct: Number(afterProportion.stone3Pct),
      stone4Pct: Number(afterProportion.stone4Pct),
      sand1Pct: Number(afterProportion.sand1Pct),
      sand2Pct: Number(afterProportion.sand2Pct),
      sand3Pct: Number(afterProportion.sand3Pct),
      sand4Pct: Number(afterProportion.sand4Pct),
      ...(afterProportion.chemAdmx1Name && { chemAdmx1UsagePct: afterProportion.chemAdmx1UsagePct ?? 0 }),
      ...(afterProportion.chemAdmx2Name && { chemAdmx2UsagePct: afterProportion.chemAdmx2UsagePct ?? 0 }),
      ...(afterProportion.chemAdmx3Name && { chemAdmx3UsagePct: afterProportion.chemAdmx3UsagePct ?? 0 }),
      ...(afterProportion.chemAdmx4Name && { chemAdmx4UsagePct: afterProportion.chemAdmx4UsagePct ?? 0 }),
      ...(afterProportion.chemAdmx5Name && { chemAdmx5UsagePct: afterProportion.chemAdmx5UsagePct ?? 0 }),
      ...(afterProportion.chemAdmx6Name && { chemAdmx6UsagePct: afterProportion.chemAdmx6UsagePct ?? 0 }),
      sandAndStoneTtlWt: afterProportion.sandAndStoneTtlWt ?? Number(caculatedData?.sandAndStoneWt?.toFixed(2)),
      sandPct: afterProportion.sandPct ?? Number(caculatedData?.sandPct?.toFixed(4)),
      normalPortlandCementRatio: afterProportion.normalPortlandCementRatio ?? 1,
      recycledWater: afterProportion.recycledWater ?? 0,
    }),
    [afterProportion, caculatedData],
  );

  // 選擇區
  const handleAreaSelectChange = useCallback(
    (area: number) => {
      setSelectedFactory({
        value: 0,
        text: '請選擇 - - -',
      });
      const selected = areaItems.find((i: AreaOptions) => i.value === area);
      if (selected) {
        setFactoryItems(selected.factories);
        setSelectedArea({
          value: area,
          text: selected.text,
        });
      }
    },
    [areaItems],
  );

  // 選擇廠
  const handleFactorySelectChange = useCallback(
    (factoryId: number) => {
      const factory = factoryItems.find((item) => item.value === factoryId);
      if (factory) {
        setSelectedFactory({
          text: factory.text,
          value: factoryId,
        });
      }
    },
    [factoryItems],
  );

  // 選擇版本
  const handlerSelectVersion = useCallback(
    (id: number) => {
      setSelectedVersion(id);
      ProportionApi.getSearchProportion(
        {
          page: 1,
          pageSize: 1,
          id: id,
          factoryId: proportion.factoryId,
          removed: false,
        },
        (_data) => {
          setAfterProportion(_data.proportions[0]);
          if (afterProportionMaintenance) setProportionMaintenance(afterProportionMaintenance);
          else setProportionMaintenance(_data.proportions[0].maintProp);
        },
      );
    },
    [proportion],
  );

  // 取得所有區
  useEffect(() => {
    if (!userGroupsData?.areas) return;

    const areaOptions: AreaOptions[] = userGroupsData.areas.map(({ id, name, factoryList }: AreaData) => ({
      value: id,
      text: name,
      factories: factoryList.map(({ factoryId, factoryName }: FactoryItem) => ({
        value: factoryId,
        text: factoryName,
      })),
    }));
    generateAreaFactors(userGroupsData.areas);
    setAreaItems(areaOptions);
  }, [userGroupsData?.areas]);

  useEffect(() => {
    if (areaItems.length < 0 || !proportion) return;

    setAfterProportion(proportion);
    let factoryId: number;

    if (afterProportionMaintenance) {
      setProportionMaintenance(afterProportionMaintenance);
      factoryId = afterProportionMaintenance.factoryId;
    } else {
      factoryId = proportion.maintProp.factoryId;
    }

    const area = areaItems.find((a) => a.factories.find((factory) => factory.value === factoryId));
    if (area) {
      const factory = area.factories.find((f) => f.value === factoryId);
      setSelectedArea({
        text: area.text,
        value: area.value,
      });
      setFactoryItems(area.factories);
      if (factory) {
        setSelectedFactory({
          text: factory.text,
          value: factory.value,
        });
      }
    }
  }, [areaItems, proportion, afterProportionMaintenance]);

  useEffect(() => {
    ProportionApi.getAllProportionVersion(
      {
        id: proportion.id,
        factoryId: proportion.factoryId,
      },
      (_data) => {
        setVersionOptions(Object.entries(_data).map(([key, value]) => ({ text: key, value: value })));
        setSelectedVersion(proportion.id);
      },
    );
  }, [proportion]);

  useEffect(() => {
    if (proportionMaintenance && afterProportion) {
      const { sandAndStoneWt, sandPct } = new CalcProportion(afterProportion, proportionMaintenance);
      setCaculatedData({ sandAndStoneWt, sandPct });
    }
  }, [proportionMaintenance, afterProportion]);

  useEffect(() => {
    if (!selectedFactory.value || !proportion) return;
    if (selectedFactory.value === proportion.maintProp.factoryId) {
      if (afterProportionMaintenance) setProportionMaintenance(afterProportionMaintenance);
      else setProportionMaintenance(proportion.maintProp);
    } else {
      ProportionApi.getDefaultProportionMaintenance(
        { factoryId: selectedFactory.value },
        (_data) => setProportionMaintenance(_data),
        () => setProportionMaintenance(undefined),
      );
    }
  }, [selectedFactory.value, proportion, afterProportionMaintenance]);

  return (
    <YatungPage
      title={i18T('PROPORTION_MANAGEMENT.PROPORTION_EDIT.TITLE')}
      body={
        <Box>
          <Stack flexDirection="row" alignItems="center" sx={{ py: 3 }} gap={3}>
            <YatungSelect
              options={areaItems}
              value={selectedArea.value}
              onChange={(e) => handleAreaSelectChange(e.target.value as number)}
            />
            <YatungSelect
              disabled={factoryItems.length === 0}
              options={factoryItems}
              value={selectedFactory.value}
              onChange={(e) => handleFactorySelectChange(e.target.value as number)}
            />
            {versionOptions && (
              <YatungSelect
                helperText="目前版本"
                options={versionOptions}
                value={selectedVersion}
                onChange={(e) => handlerSelectVersion(e.target.value as number)}
              />
            )}
          </Stack>
          {proportionMaintenance && !!versionOptions && !!selectedFactory.value && (
            <EditProportionForm
              selectedFactoryId={selectedFactory.value}
              proportion={afterProportion}
              initialValues={initialValues}
              proportionMaintenance={proportionMaintenance}
            />
          )}
        </Box>
      }
      contentBgColor="#FFF"
    />
  );
}
