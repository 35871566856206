import React, { useEffect, useState } from 'react';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import { ReplaceLangFileData } from '@Src/_basic/object/MultilingualManagementType';
import UploadForm from './UploadForm';

interface Props {
  open?: boolean;
  handleClose: () => void;
  handleUploadGoBackButtonClick: () => void;
  title: string;
  icon: JSX.Element;
  langName: string;
  platformName: string;
  idValue: number | undefined;
}

export default function UploadLangFileModel({
  open,
  handleClose,
  handleUploadGoBackButtonClick,
  title,
  icon,
  langName,
  platformName,
  idValue,
}: Props) {
  const [files, setFiles] = useState<FileList>();

  const list = [''];

  const saveTimeout = new Map(); //建立字典來查詢

  if (files) {
    for (let i = 0; i < files?.length; i++) {
      list.push(i ? `、${files[i]?.name}` : files[i]?.name); //紀錄選擇的檔案
    }
  }

  const handleChange = (event: any) => {
    const files = event.target.files;
    if (files) {
      setFiles(files);
    }
  };

  useEffect(() => {
    handleChange;
  }, [files]);

  const initialReplaceFileLangValue: ReplaceLangFileData = {
    langFileInfoId: idValue,
  };

  return (
    <>
      <YatungDialog
        open={open}
        handleClose={handleClose}
        icon={icon}
        title={title}
        body={
          <UploadForm
            initialValues={initialReplaceFileLangValue}
            handleUploadGoBackButtonClick={handleUploadGoBackButtonClick}
            langName={langName}
            platformName={platformName}
            idValue={idValue}
          />
        }
      />
    </>
  );
}
