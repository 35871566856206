import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { Grid, Pagination, Stack } from '@mui/material';

import YatungButton from '@Src/_basic/components/YatungButton';
import SearchIcon from '@Src/_basic/icons/Search';
import { PageableSampleCourseData, SampleCourseData } from '@Src/_basic/object/SampleCourseType';
import { SearchTypeParams, TypeData } from '@Src/_basic/object/TypeType';
import { SampleCourseApi } from '@Src/_basic/protocol/sampleCourse/SampleCourseApi';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { useNavigate } from 'react-router';
import SampleCourseTable from '../components/SampleCourseTable';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';

const background = '/media/images/applicationsMange/materialReceiptsBG.png';

interface AreaOptions extends Options {
  factories: Array<Options>;
}

export default function SampleCourseScreens() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const [sample, setSample] = useState<Array<SampleCourseData>>([]);
  const { userAreaOptionsData } = useAuth();
  const [query, setQuery] = useState<any>({
    startTime: new Date(new Date().setDate(1)).setHours(0, 0, 0, 0),
    endTime: new Date().setHours(23, 59, 59, 999),
    factoryId: 0,
    typeId: 0,
  });
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalCount: 0,
  });
  const [types, setTypes] = useState<Options[]>([]);
  const [factories, setFactories] = useState<Array<Options>>([]);
  const [selectedArea, setSelectedArea] = useState<number>(0); //選擇的區域
  const [searchInput, setSearchInput] = useState<string>(''); //搜尋

  const navigate = useNavigate();

  const getSearchSample = (page = 1) => {
    const selectParams = { ...query, fuzzySampleId: searchInput }; //選擇的參數

    if (selectParams['factoryId'] < 0) {
      //如果沒有選擇廠區，就不要傳廠區參數
      delete selectParams['factoryId'];
      if (selectParams['typeId'] <= 0) delete selectParams['typeId'];
    } else {
      if (selectParams['typeId'] <= 0) delete selectParams['typeId'];
    }
    const { pageSize } = pageInfo;

    SampleCourseApi.getSampleCourse(
      { ...selectParams, page, pageSize },
      (data: PageableSampleCourseData) => {
        onGetPageableMaterialReceiptsBySearchSuccess(data, page);
      },
      undefined,
      () => {},
    );
  };

  const handleQueryChange = (field: string) => async (value: unknown) => {
    setQuery({
      ...query,
      [field]: value,
    });
  };

  const handleEndDatePickerChange = (endTime: number) => {
    setQuery({
      ...query,
      startTime: query.startTime > endTime ? endTime - 86399000 : query.startTime,
      endTime,
    });
  };

  const handleAreaSelectChange = (area: number) => {
    const selected = userAreaOptionsData.find((i: AreaOptions) => i.value === area);
    if (selected) {
      setFactories(selected.factories);
      setSelectedArea(area);
      handleQueryChange('factoryId')(0);
    }
  };

  const onGetPageableMaterialReceiptsBySearchSuccess = (data: PageableSampleCourseData, page: number) => {
    setSample(data.data);
    setPageInfo({
      ...pageInfo,
      page,
      total: data.maxPage,
      totalCount: data.elements,
    });
  };

  const getAllFactoryRelatedOptions = (factoryId: number) => {
    const searchParams: SearchTypeParams = { factoryId };
    TypeApi.getTypesBySearch(searchParams, typesDataToOptions);
  };

  const typesDataToOptions = (data: Array<TypeData>) => {
    const allTypesRemoveSome = data.filter(
      (value: any) => value.id !== 9 && value.id !== 11 && value.name !== '拌合水' && value.id !== 8 && value.id !== 7,
    );
    const typesOptions = allTypesRemoveSome.map((type: TypeData) => ({ value: type.id, text: type.name }));
    setTypes(typesOptions);
  };

  const getAllReceipts = (page = 1) => {
    //取得所有的樣品單
    const params = { ...query };
    if (params['factoryId'] > 0) {
      if (params['typeId'] <= 0) delete params['typeId'];
      const { pageSize } = pageInfo;
      SampleCourseApi.getSampleCourse(
        { ...params, page, pageSize },
        (data: PageableSampleCourseData) => {
          onGetPageableMaterialReceiptsBySearchSuccess(data, page);
        },
        undefined,
        () => {},
      );
    }
  };

  const handleNavi = (id: string, type: string, time: string) => {
    navigate(`/qualityManagement/SampleCourse/tree?id=${id}&type=${type}&time=${time}`);
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    getAllReceipts(page);
    getSearchSample(page);
    setPageInfo({
      ...pageInfo,
      page,
    });
  };

  const refresh = () => {
    getAllReceipts();
  };

  const searchSampleId = () => {
    getSearchSample();
  };

  useEffect(() => {
    if (searchInput) {
      searchSampleId();
    }
  }, []);

  useEffect(() => {
    if (query.factoryId > 0) {
      getAllFactoryRelatedOptions(query.factoryId);
    }
  }, [query.factoryId]);

  useEffect(() => {
    refresh();
  }, [query.factoryId, query.typeId, query.startTime, query.endTime]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.TITLE')}
      backgroundImage={background}
      body={
        <Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <YatungDateSelect
                label="開始"
                value={query.startTime}
                onChange={(val) => val && handleQueryChange('startTime')(new Date(val).getTime())}
                maxDate={query.endTime}
              />
              <YatungDateSelect
                label="結束"
                value={query.endTime}
                onChange={(val) => val && handleEndDatePickerChange(new Date(val).getTime())}
              />
              <YatungSelect
                disabled={actionLoading}
                options={userAreaOptionsData}
                value={selectedArea}
                onChange={(e) => handleAreaSelectChange(e.target.value as number)}
              />
              <YatungSelect
                disabled={factories.length === 0 || actionLoading}
                options={factories}
                value={query.factoryId}
                onChange={(e) => handleQueryChange('factoryId')(e.target.value)}
              />
              <YatungSelect
                disabled={types.length === 0 || actionLoading}
                options={[{ text: i18T('GLOBAL.ALL'), value: 0 }, ...types]}
                value={query.typeId}
                onChange={(e) => handleQueryChange('typeId')(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <YatungInput onChange={(e: any) => setSearchInput(e.target.value)} value={searchInput} />
              <YatungButton
                text={i18T('GLOBAL.SEARCh_BUTTON')}
                startIcon={<SearchIcon />}
                onClick={searchSampleId}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#003087',
                  fontSize: '20px',
                  width: 90,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#003087',
                  },
                }}
              />
            </Stack>
          </Stack>
          <SampleCourseTable
            rows={sample}
            onViewDetail={(id: string, type: string, time: string) => handleNavi(id, type, time)}
          />
          <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid>
        </Stack>
      }
    />
  );
}
