import YatungFadeMenu from '@Src/_basic/components/YatungFadeMenu';
import { useAuth } from '@Src/redux/auth/authActions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function MonthlyReport() {
  const { t: i18T } = useTranslation();
  const { userApiPermission } = useAuth();
  const [newRouteSideMenu, setNewRouteSideMenu] = React.useState<Array<any>>([]);

  const routeSideMenu = [
    {
      title: i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_ANALYSIS.TITLE'),
      toUrl: '/report/monthly-report/fineAggregateAnalysis',
      code: 'WD043',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_AMS_DETECTION.TITLE'),
      toUrl: '/report/monthly-report/fineAggregateAMS',
      code: 'WD044',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_ABSORPTION.TITLE'),
      toUrl: '/report/monthly-report/fineAggregateAbsorption',
      code: 'WD045',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_CHLORIDE_DETECTION.TITLE'),
      toUrl: '/report/monthly-report/fineAggregateChlorideIons',
      code: 'WD046',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_R_VALUE.TITLE'),
      toUrl: '/report/monthly-report/fineAggregateRValue',
      code: 'WD047',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.COARSE_AGGREGATE_ANALYSIS.TITLE'),
      toUrl: '/report/monthly-report/coarseAggregateAnalysis',
      code: 'WD048',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.COARSE_AGGREGATE_ABSORPTION.TITLE'),
      toUrl: '/report/monthly-report/coarseAggregateAbsorption',
      code: 'WD049',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.FLY_ASH_L_VALUE.TITLE'),
      toUrl: '/report/monthly-report/flyAshLValue',
      code: 'WD050',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.FLY_ASH_LOSS_OF_IGNITION.TITLE'),
      toUrl: '/report/monthly-report/flyAshLossOfIgnition',
      code: 'WD051',
    },
    {
      title: i18T('REPORT.MONTHLY_REPORT.CONCRETE_ANTI_STRESS.TITLE'),
      toUrl: '/report/monthly-report/concreteAntiStress',
      code: 'WD052',
    },
  ];

  useEffect(() => {
    const routeSideMenuTemp: Array<any> = [];
    routeSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeSideMenuTemp.push(item);
      }
    });
    setNewRouteSideMenu(routeSideMenuTemp);
  }, [userApiPermission]);
  return (
    <>
      {newRouteSideMenu.length > 0 && (
        <YatungFadeMenu text={i18T('REPORT.SIDE_MENU.MONTHLY_REPORT')} option={newRouteSideMenu} />
      )}
    </>
  );
}
