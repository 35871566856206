import { Typography } from '@mui/material';
import { AreaManagementData } from '@Src/_basic/object/AreaType';
import moment from 'moment';
import React from 'react';

interface Props {
  row: AreaManagementData;
}
export default function CreateTimeColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{moment(row.createTime).format('YYYY/MM/DD')}</Typography>;
}
