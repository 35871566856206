import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import ConfirmIcon from '@Src/_basic/icons/Confirm';

import LabelInput from '../../components/LabelInput';
import LabelInputV2 from '../../components/LabelInputV2';
import RowContent from '../../components/RowContent';

import { checkIsNaNToString } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import arrowDownImg from './arrow_down.png';

const selectedTabBtnStyle = {
  fontSize: '24px',
  py: '16px',
  width: '240px',
};

const unSelectedTabBtnStyle = {
  fontSize: '16px',
  py: '8px',
  backgroundColor: '#697EA3',
  width: '144px',
};

const rowBoxStyle = { backgroundColor: '#697EA3', borderRadius: '4px', mt: 1, p: 1, width: '600px' };
const rowLabelsx = {
  fontWeight: 400,
  fontSize: 16,
  color: 'white',
  textAlign: 'center',
  lineHeight: 1,
  pt: 1.5,
  letterSpacing: 4,
};
const rowValuesx = { fontWeight: 700, fontSize: 24, color: 'white', textAlign: 'center', lineHeight: 1, pb: 1.5 };

const ArrowRow = ({ labels = ['1', '2', '3', '4'] }: { labels?: Array<string> }) => {
  return (
    <Stack flexDirection="row" justifyContent="space-between">
      {labels.map((item) => (
        <Stack justifyContent="center" alignItems="center" sx={{ width: `${100 / labels.length}%` }} key={item}>
          <img src={arrowDownImg} style={{ width: '44px', height: '24px' }} />
        </Stack>
      ))}
    </Stack>
  );
};

const additionalWaterContent = (
  originalWeight: number,
  surfaceMoisture: number,
  moisture: string,
  absorption: number,
) => {
  const afterAbsorption = absorption ?? 0;
  const afterMoisture = +moisture ?? 0;
  const result =
    originalWeight * (1 + surfaceMoisture / 100) * (afterMoisture / 100 - afterAbsorption / 100 - 1) + originalWeight;
  return Number(result.toFixed(2));
};

export default function SafeWaterCalculation() {
  const { t: i18T } = useTranslation();
  const { proportionId, versionId } = useParams();
  const navigate = useNavigate();
  const {
    state: { proportion, calcData, afterProportionMaintenance },
  } = useLocation();

  // const [proportion, setproportion] = useState<Formula | null>(null);
  const [selectedMixer, setSelectedMixer] = useState<string>('mixer1');
  const [surfaceMoisture, setSurfaceMoisture] = useState<any>({
    stone1: 1,
    stone2: 1,
    stone3: 1,
    stone4: 1,
    sand1: 1,
    sand2: 1,
    sand3: 1,
    sand4: 1,
  });

  const [mixerData, setMixerData] = useState<any>(null);

  const navigateBack = () => {
    navigate(`/proportionManage/proportionList/${proportionId}/${versionId}`, {
      state: { proportion, afterProportionMaintenance },
    });
  };

  const handleSurfaceMoistureChange = (key: string) => (val: string) => {
    setSurfaceMoisture({
      ...surfaceMoisture,
      [key]: val,
    });
  };

  const mixingWaterFixCalculation = () => {
    return proportion && calcData
      ? Number(calcData.quantityMixingWater) +
          additionalWaterContent(
            calcData.sand1Wt,
            surfaceMoisture.sand1,
            mixerData[selectedMixer]?.moistureAnlys?.sand1,
            mixerData[selectedMixer]?.sgwabsAnlys?.sand1,
          ) +
          additionalWaterContent(
            calcData.sand2Wt,
            surfaceMoisture.sand2,
            mixerData[selectedMixer]?.moistureAnlys?.sand2,
            mixerData[selectedMixer]?.sgwabsAnlys?.sand2,
          ) +
          additionalWaterContent(
            calcData.sand3Wt,
            surfaceMoisture.sand3,
            mixerData[selectedMixer]?.moistureAnlys?.sand3,
            mixerData[selectedMixer]?.sgwabsAnlys?.sand3,
          ) +
          additionalWaterContent(
            calcData.sand4Wt,
            surfaceMoisture.sand4,
            mixerData[selectedMixer]?.moistureAnlys?.sand4,
            mixerData[selectedMixer]?.sgwabsAnlys?.sand4,
          ) +
          additionalWaterContent(
            calcData.stone1Wt,
            surfaceMoisture.stone1,
            mixerData[selectedMixer]?.moistureAnlys?.stone1,
            mixerData[selectedMixer]?.sgwabsAnlys?.stone1,
          ) +
          additionalWaterContent(
            calcData.stone2Wt,
            surfaceMoisture.stone2,
            mixerData[selectedMixer]?.moistureAnlys?.stone2,
            mixerData[selectedMixer]?.sgwabsAnlys?.stone2,
          ) +
          additionalWaterContent(
            calcData.stone3Wt,
            surfaceMoisture.stone3,
            mixerData[selectedMixer]?.moistureAnlys?.stone3,
            mixerData[selectedMixer]?.sgwabsAnlys?.stone3,
          ) +
          additionalWaterContent(
            calcData.stone4Wt,
            surfaceMoisture.stone4,
            mixerData[selectedMixer]?.moistureAnlys?.stone4,
            mixerData[selectedMixer]?.sgwabsAnlys?.stone4,
          )
      : 0;
  };

  const getDrugAdditionWeight = () => {
    return calcData.chemAdmx1Wt
      ? Number(calcData?.chemAdmx1Wt) +
          Number(calcData?.chemAdmx2Wt) +
          Number(calcData?.chemAdmx3Wt) +
          Number(calcData?.chemAdmx4Wt) +
          Number(calcData?.chemAdmx5Wt) +
          Number(calcData?.chemAdmx6Wt)
      : 0;
  };

  const actualWaterBinderRatioCalculation: () => number = () => {
    // 實際水膠比 = ( 拌合水修正 + 藥 1 (kg) + 藥 2 (kg) + 藥 3 (kg) ) / 膠結材總量
    return (mixingWaterFixCalculation() + getDrugAdditionWeight()) / calcData?.cementitiousWt;
  };

  const expectedStrength = () => {
    //預期強度 = 實際水膠比 x 強度係數a + 強度係數b
    return (
      actualWaterBinderRatioCalculation() * proportion.maintProp?.paramAStr +
      proportion.maintProp?.paramBStr
    )?.toFixed(2);
  };

  useEffect(() => {
    ProportionApi.getSafetyWater(
      {
        factoryId: afterProportionMaintenance.factoryId,
      },
      (_data) => {
        setMixerData(_data);
      },
    );
  }, [afterProportionMaintenance]);

  return (
    <YatungPage
      title={i18T('PROPORTION_MANAGEMENT.SAFE_WATER_CALCULATION.TITLE')}
      body={
        <>
          <Stack flexDirection="row" justifyContent="flex-end" sx={{ my: 2 }}>
            <YatungButton color="blue" onClick={navigateBack} startIcon={<ConfirmIcon />}>
              {i18T('GLOBAL.FINISH')}
            </YatungButton>
          </Stack>
          <Box sx={{ p: 2, border: '1px solid #6C708C', borderRadius: '8px', minWidth: '1480px' }}>
            {
              //proportion && caculatedData && factoryFactor &&
            }
            {mixerData && calcData && (
              <>
                <Stack flexDirection="row" alignItems="center" sx={{ pb: 2, borderBottom: '1px solid #697EA3' }}>
                  <LabelInput
                    label={`${i18T(`PROPORTION.PROP_NAME`)} :`}
                    value={proportion?.propName}
                    editable={false}
                  />
                  <LabelInput
                    label={`${i18T(`PROPORTION.DESIGN_STR`)}(kgf/cm²)`}
                    value={proportion?.dsgnStr < 0 ? 0 : proportion?.dsgnStr}
                    editable={false}
                  />
                </Stack>
                <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between" sx={{ py: 1 }}>
                  <Box sx={{ width: '450px' }}>
                    <Card
                      sx={{
                        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                        background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                      }}
                    >
                      <CardContent>
                        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                          <LabelInputV2
                            label={`${i18T(`PROPORTION.QUANTITY_MIXER_WATER`)}(kg)`}
                            value={formatNumber(proportion?.quantityMixingWater?.toFixed(4))}
                            editable={false}
                            valueWidth="190px"
                            letterSpacing={1}
                          />
                          <LabelInputV2
                            label={`${i18T(`PROPORTION.WATER_COLLOID_RATIO`)}(W/B)`}
                            value={formatNumber(proportion?.waterColloidRatio?.toFixed(2))}
                            editable={false}
                            valueWidth="190px"
                            letterSpacing={1}
                          />
                        </Stack>
                      </CardContent>
                    </Card>
                    <Card sx={{ mt: 2, boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
                      <CardContent>
                        <YatungButton
                          color="blue"
                          onClick={() => setSelectedMixer('mixer1')}
                          sx={{
                            mr: '16px',
                            ...(selectedMixer === 'mixer1' ? selectedTabBtnStyle : unSelectedTabBtnStyle),
                          }}
                        >
                          {i18T(`PROPORTION.MIXER_NUMBER`, { number: 1 })}
                        </YatungButton>
                        <YatungButton
                          color="blue"
                          onClick={() => setSelectedMixer('mixer2')}
                          sx={{
                            ...(selectedMixer === 'mixer2' ? selectedTabBtnStyle : unSelectedTabBtnStyle),
                          }}
                        >
                          {i18T(`PROPORTION.MIXER_NUMBER`, { number: 2 })}
                        </YatungButton>
                        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE_SIEVEANLYS`, { number: 1 })}(%)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE_SGWABSANLYS`, { number: 1 })}(%)`,
                            ]}
                            values={[
                              mixerData[selectedMixer]?.moistureAnlys?.stone1?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                              mixerData[selectedMixer]?.sgwabsAnlys?.stone1?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            borderColor={'#fff'}
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE_SIEVEANLYS`, { number: 2 })}(%)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE_SGWABSANLYS`, { number: 2 })}(%)`,
                            ]}
                            values={[
                              mixerData[selectedMixer]?.moistureAnlys?.stone2?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                              mixerData[selectedMixer]?.sgwabsAnlys?.stone2?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            borderColor={'#fff'}
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE_SIEVEANLYS`, { number: 3 })}(%)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE_SGWABSANLYS`, { number: 3 })}(%)`,
                            ]}
                            values={[
                              mixerData[selectedMixer]?.moistureAnlys?.stone3?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                              mixerData[selectedMixer]?.sgwabsAnlys?.stone3?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            borderColor={'#fff'}
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE_SIEVEANLYS`, { number: 4 })}(%)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE_SGWABSANLYS`, { number: 4 })}(%)`,
                            ]}
                            values={[
                              mixerData[selectedMixer]?.moistureAnlys?.stone4?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                              mixerData[selectedMixer]?.sgwabsAnlys?.stone4?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            borderColor={'#fff'}
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND_SIEVEANLYS`, { number: 1 })}(%)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND_SGWABSANLYS`, { number: 1 })}(%)`,
                            ]}
                            values={[
                              mixerData[selectedMixer]?.moistureAnlys?.sand1?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                              mixerData[selectedMixer]?.sgwabsAnlys?.sand1?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            borderColor={'#fff'}
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND_SIEVEANLYS`, { number: 2 })}(%)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND_SGWABSANLYS`, { number: 2 })}(%)`,
                            ]}
                            values={[
                              mixerData[selectedMixer]?.moistureAnlys?.sand2?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                              mixerData[selectedMixer]?.sgwabsAnlys?.sand2?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            borderColor={'#fff'}
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND_SIEVEANLYS`, { number: 3 })}(%)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND_SGWABSANLYS`, { number: 3 })}(%)`,
                            ]}
                            values={[
                              mixerData[selectedMixer]?.moistureAnlys?.sand3?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                              mixerData[selectedMixer]?.sgwabsAnlys?.sand3?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            borderColor={'#fff'}
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND_SIEVEANLYS`, { number: 4 })}(%)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND_SGWABSANLYS`, { number: 4 })}(%)`,
                            ]}
                            values={[
                              mixerData[selectedMixer]?.moistureAnlys?.sand4?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                              mixerData[selectedMixer]?.sgwabsAnlys?.sand4?.toFixed(4) ?? i18T('GLOBAL.NO_INFO'),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            showBorder={false}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box sx={{ width: '650px', mx: 1 }}>
                    <Card sx={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
                      <CardContent>
                        <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                          <LabelInputV2
                            label={`${i18T('PROPORTION.SAFE_WATER_CACULATION.SURFACE_SIEVEANLYS_SETTING', {
                              item: i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 1 }),
                            })}(%)`}
                            value={surfaceMoisture.stone1}
                            onChange={(text: string) => {
                              handleSurfaceMoistureChange('stone1')(text);
                            }}
                            letterSpacing={1}
                          />
                          <LabelInputV2
                            label={`${i18T('PROPORTION.SAFE_WATER_CACULATION.SURFACE_SIEVEANLYS_SETTING', {
                              item: i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 2 }),
                            })}(%)`}
                            value={surfaceMoisture.stone2}
                            onChange={(text: string) => {
                              handleSurfaceMoistureChange('stone2')(text);
                            }}
                            letterSpacing={1}
                          />
                        </Stack>
                        <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                          <LabelInputV2
                            label={`${i18T('PROPORTION.SAFE_WATER_CACULATION.SURFACE_SIEVEANLYS_SETTING', {
                              item: i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 3 }),
                            })}(%)`}
                            value={surfaceMoisture.stone3}
                            onChange={(text: string) => {
                              handleSurfaceMoistureChange('stone3')(text);
                            }}
                            letterSpacing={1}
                          />
                          <LabelInputV2
                            label={`${i18T('PROPORTION.SAFE_WATER_CACULATION.SURFACE_SIEVEANLYS_SETTING', {
                              item: i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 4 }),
                            })}(%)`}
                            value={surfaceMoisture.stone4}
                            onChange={(text: string) => {
                              handleSurfaceMoistureChange('stone4')(text);
                            }}
                            letterSpacing={1}
                          />
                        </Stack>
                        <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                          <LabelInputV2
                            label={`${i18T('PROPORTION.SAFE_WATER_CACULATION.SURFACE_SIEVEANLYS_SETTING', {
                              item: i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 1 }),
                            })}(%)`}
                            value={surfaceMoisture.sand1}
                            onChange={(text: string) => {
                              handleSurfaceMoistureChange('sand1')(text);
                            }}
                            letterSpacing={1}
                          />
                          <LabelInputV2
                            label={`${i18T('PROPORTION.SAFE_WATER_CACULATION.SURFACE_SIEVEANLYS_SETTING', {
                              item: i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 2 }),
                            })}(%)`}
                            value={surfaceMoisture.sand2}
                            onChange={(text: string) => {
                              handleSurfaceMoistureChange('sand2')(text);
                            }}
                            letterSpacing={1}
                          />
                        </Stack>
                        <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                          <LabelInputV2
                            label={`${i18T('PROPORTION.SAFE_WATER_CACULATION.SURFACE_SIEVEANLYS_SETTING', {
                              item: i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 3 }),
                            })}(%)`}
                            value={surfaceMoisture.sand3}
                            onChange={(text: string) => {
                              handleSurfaceMoistureChange('sand3')(text);
                            }}
                            letterSpacing={1}
                          />
                          <LabelInputV2
                            label={`${i18T('PROPORTION.SAFE_WATER_CACULATION.SURFACE_SIEVEANLYS_SETTING', {
                              item: i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 4 }),
                            })}(%)`}
                            value={surfaceMoisture.sand4}
                            onChange={(text: string) => {
                              handleSurfaceMoistureChange('sand4')(text);
                            }}
                            letterSpacing={1}
                          />
                        </Stack>
                        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: 20,
                              color: 'white',
                              textAlign: 'center',
                              lineHeight: 1,
                              py: 1,
                              letterSpacing: 4,
                              borderBottom: '1px solid white',
                            }}
                          >
                            {i18T('PROPORTION.SAFE_WATER_CACULATION.CHANGE_BY_MACHINE_SURFACE')}
                          </Typography>
                          <Box sx={{ my: 1 }}>
                            <RowContent
                              labels={[
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 1 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 2 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 3 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 4 })}(kg)`,
                              ]}
                              values={[
                                calcData?.sand1Wt ?? i18T('GLOBAL.NO_INFO'),
                                calcData?.sand2Wt ?? i18T('GLOBAL.NO_INFO'),
                                calcData?.sand3Wt ?? i18T('GLOBAL.NO_INFO'),
                                calcData?.sand4Wt ?? i18T('GLOBAL.NO_INFO'),
                              ]}
                              labelsx={rowLabelsx}
                              valuesx={rowValuesx}
                              showBorder={false}
                              kgFixed={true}
                            />
                            <ArrowRow />
                            <RowContent
                              labels={[
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 1 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 2 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 3 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 4 })}(kg)`,
                              ]}
                              values={[
                                calcData?.sand1Wt * (1 + surfaceMoisture.sand1 / 100),
                                calcData?.sand2Wt * (1 + surfaceMoisture.sand2 / 100),
                                calcData?.sand3Wt * (1 + surfaceMoisture.sand3 / 100),
                                calcData?.sand4Wt * (1 + surfaceMoisture.sand4 / 100),
                              ]}
                              labelsx={rowLabelsx}
                              valuesx={rowValuesx}
                              showBorder={false}
                              kgFixed={true}
                            />
                          </Box>
                          <Box sx={{ my: 1 }}>
                            <RowContent
                              labels={[
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 1 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 2 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 3 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 4 })}(kg)`,
                              ]}
                              values={[
                                calcData?.stone1Wt ?? i18T('GLOBAL.NO_INFO'),
                                calcData?.stone2Wt ?? i18T('GLOBAL.NO_INFO'),
                                calcData?.stone3Wt ?? i18T('GLOBAL.NO_INFO'),
                                calcData?.stone4Wt ?? i18T('GLOBAL.NO_INFO'),
                              ]}
                              labelsx={rowLabelsx}
                              valuesx={{ ...rowValuesx, fontSize: '28px' }}
                              showBorder={false}
                              kgFixed={true}
                            />
                            <ArrowRow />
                            <RowContent
                              labels={[
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 1 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 2 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 3 })}(kg)`,
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 4 })}(kg)`,
                              ]}
                              values={[
                                calcData?.stone1Wt * (1 + surfaceMoisture.stone1 / 100),
                                calcData?.stone2Wt * (1 + surfaceMoisture.stone2 / 100),
                                calcData?.stone3Wt * (1 + surfaceMoisture.stone3 / 100),
                                calcData?.stone4Wt * (1 + surfaceMoisture.stone4 / 100),
                              ]}
                              labelsx={rowLabelsx}
                              valuesx={{ ...rowValuesx, fontSize: '28px' }}
                              showBorder={false}
                              kgFixed={true}
                            />
                          </Box>
                          <Box sx={{ my: 1 }}>
                            <RowContent
                              labels={[
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.DESIGN_WATER`)}(kg)`,
                                ...(calcData.chemAdmx1Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 1 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx2Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 2 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx3Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 3 })}(kg)`]
                                  : []),
                              ]}
                              values={[
                                calcData.quantityMixingWater,
                                ...(calcData.chemAdmx1Wt !== null ? [calcData.chemAdmx1Wt] : []),
                                ...(calcData.chemAdmx2Wt !== null ? [calcData.chemAdmx2Wt] : []),
                                ...(calcData.chemAdmx3Wt !== null ? [calcData.chemAdmx3Wt] : []),
                              ]}
                              labelsx={{ ...rowLabelsx, letterSpacing: 0 }}
                              valuesx={rowValuesx}
                              showBorder={false}
                              kgFixed={true}
                            />
                            <ArrowRow
                              labels={[
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.DESIGN_WATER`)}(kg)`,
                                ...(calcData.chemAdmx1Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 1 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx2Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 2 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx3Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 3 })}(kg)`]
                                  : []),
                              ]}
                            />
                            <RowContent
                              labels={[
                                `${i18T(`PROPORTION.SAFE_WATER_CACULATION.DESIGN_WATER`)}(kg)`,
                                ...(calcData.chemAdmx1Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 1 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx2Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 2 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx3Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 3 })}(kg)`]
                                  : []),
                              ]}
                              values={[
                                calcData.quantityMixingWater -
                                  (calcData?.stone1Wt * (1 + surfaceMoisture.stone1 / 100) -
                                    calcData?.stone1Wt +
                                    (calcData?.stone2Wt * (1 + surfaceMoisture.stone2 / 100) - calcData?.stone2Wt) +
                                    (calcData?.stone3Wt * (1 + surfaceMoisture.stone3 / 100) - calcData?.stone3Wt) +
                                    (calcData?.stone4Wt * (1 + surfaceMoisture.stone4 / 100) - calcData?.stone4Wt) +
                                    (calcData?.sand1Wt * (1 + surfaceMoisture.sand1 / 100) - calcData?.sand1Wt) +
                                    (calcData?.sand2Wt * (1 + surfaceMoisture.sand2 / 100) - calcData?.sand2Wt) +
                                    (calcData?.sand3Wt * (1 + surfaceMoisture.sand3 / 100) - calcData?.sand3Wt) +
                                    (calcData?.sand4Wt * (1 + surfaceMoisture.sand4 / 100) - calcData?.sand4Wt)),
                                ...(calcData.chemAdmx1Wt !== null ? [calcData.chemAdmx1Wt] : []),
                                ...(calcData.chemAdmx2Wt !== null ? [calcData.chemAdmx2Wt] : []),
                                ...(calcData.chemAdmx3Wt !== null ? [calcData.chemAdmx3Wt] : []),
                              ]}
                              labelsx={{ ...rowLabelsx, letterSpacing: 0 }}
                              valuesx={rowValuesx}
                              showBorder={false}
                              kgFixed={true}
                            />
                          </Box>
                          <Box sx={{ my: 1 }}>
                            <RowContent
                              labels={[
                                ...(calcData.chemAdmx4Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 4 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx5Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 5 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx6Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 6 })}(kg)`]
                                  : []),
                              ]}
                              values={[
                                ...(calcData.chemAdmx4Wt !== null ? [calcData.chemAdmx4Wt] : []),
                                ...(calcData.chemAdmx5Wt !== null ? [calcData.chemAdmx5Wt] : []),
                                ...(calcData.chemAdmx6Wt !== null ? [calcData.chemAdmx6Wt] : []),
                              ]}
                              labelsx={{ ...rowLabelsx, letterSpacing: 0 }}
                              valuesx={rowValuesx}
                              showBorder={false}
                              kgFixed={true}
                            />
                            <ArrowRow
                              labels={[
                                ...(calcData.chemAdmx4Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 4 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx5Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 5 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx6Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 6 })}(kg)`]
                                  : []),
                              ]}
                            />
                            <RowContent
                              labels={[
                                ...(calcData.chemAdmx4Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 4 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx5Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 5 })}(kg)`]
                                  : []),
                                ...(calcData.chemAdmx6Wt !== null
                                  ? [`${i18T(`PROPORTION.SAFE_WATER_CACULATION.CHEM_ADMX`, { number: 6 })}(kg)`]
                                  : []),
                              ]}
                              values={[
                                ...(calcData.chemAdmx4Wt !== null ? [calcData.chemAdmx4Wt] : []),
                                ...(calcData.chemAdmx5Wt !== null ? [calcData.chemAdmx5Wt] : []),
                                ...(calcData.chemAdmx6Wt !== null ? [calcData.chemAdmx6Wt] : []),
                              ]}
                              labelsx={{ ...rowLabelsx, letterSpacing: 0 }}
                              valuesx={rowValuesx}
                              showBorder={false}
                              kgFixed={true}
                            />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box sx={{ width: '400px' }}>
                    <Card sx={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
                      <CardContent>
                        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: 20,
                              color: 'white',
                              textAlign: 'center',
                              lineHeight: 1,
                              py: 1,
                              letterSpacing: 4,
                              borderBottom: '1px solid white',
                            }}
                          >
                            {i18T(`PROPORTION.SAFE_WATER_CACULATION.REAL_STATE_WATER_CONTENT`)}
                          </Typography>
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 1 })}\n(kg)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 2 })}\n(kg)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 3 })}\n(kg)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.SAND`, { number: 4 })}\n(kg)`,
                            ]}
                            values={[
                              checkIsNaNToString(
                                additionalWaterContent(
                                  calcData.sand1Wt,
                                  surfaceMoisture.sand1,
                                  mixerData[selectedMixer]?.moistureAnlys?.sand1,
                                  mixerData[selectedMixer]?.sgwabsAnlys?.sand1,
                                ),
                              ),
                              checkIsNaNToString(
                                additionalWaterContent(
                                  calcData.sand2Wt,
                                  surfaceMoisture.sand2,
                                  mixerData[selectedMixer]?.moistureAnlys?.sand2,
                                  mixerData[selectedMixer]?.sgwabsAnlys?.sand2,
                                ),
                              ),
                              checkIsNaNToString(
                                additionalWaterContent(
                                  calcData.sand3Wt,
                                  surfaceMoisture.sand3,
                                  mixerData[selectedMixer]?.moistureAnlys?.sand3,
                                  mixerData[selectedMixer]?.sgwabsAnlys?.sand3,
                                ),
                              ),
                              checkIsNaNToString(
                                additionalWaterContent(
                                  calcData.sand4Wt,
                                  surfaceMoisture.sand4,
                                  mixerData[selectedMixer]?.moistureAnlys?.sand4,
                                  mixerData[selectedMixer]?.sgwabsAnlys?.sand4,
                                ),
                              ),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            showBorder={false}
                            kgFixed={true}
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 1 })}\n(kg)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 2 })}\n(kg)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 3 })}\n(kg)`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.STONE`, { number: 4 })}\n(kg)`,
                            ]}
                            values={[
                              checkIsNaNToString(
                                additionalWaterContent(
                                  calcData.stone1Wt,
                                  surfaceMoisture.stone1,
                                  mixerData[selectedMixer]?.moistureAnlys?.stone1,
                                  mixerData[selectedMixer]?.sgwabsAnlys?.stone1,
                                ),
                              ),
                              checkIsNaNToString(
                                additionalWaterContent(
                                  calcData.stone2Wt,
                                  surfaceMoisture.stone2,
                                  mixerData[selectedMixer]?.moistureAnlys?.stone2,
                                  mixerData[selectedMixer]?.sgwabsAnlys?.stone2,
                                ),
                              ),
                              checkIsNaNToString(
                                additionalWaterContent(
                                  calcData.stone3Wt,
                                  surfaceMoisture.stone3,
                                  mixerData[selectedMixer]?.moistureAnlys?.stone3,
                                  mixerData[selectedMixer]?.sgwabsAnlys?.stone3,
                                ),
                              ),
                              checkIsNaNToString(
                                additionalWaterContent(
                                  calcData.stone4Wt,
                                  surfaceMoisture.stone4,
                                  mixerData[selectedMixer]?.moistureAnlys?.stone4,
                                  mixerData[selectedMixer]?.sgwabsAnlys?.stone4,
                                ),
                              ),
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            showBorder={false}
                            kgFixed={true}
                          />
                        </Box>
                        <Box sx={{ ...rowBoxStyle, width: 'auto', backgroundColor: '#002F8C' }}>
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.FIX_MIXER_WATER`)}`,
                              `${i18T(`PROPORTION.EXPECTED_STR`)}`,
                            ]}
                            values={[
                              {
                                text: checkIsNaNToString(mixingWaterFixCalculation(), true),
                                unit: 'kg',
                              },
                              {
                                text: checkIsNaNToString(actualWaterBinderRatioCalculation(), true),
                                unit: 'W/B',
                              },
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            borderColor="#fff"
                          />
                          <RowContent
                            labels={[
                              `${i18T(`PROPORTION.TRUTH_WATER_COLLOID_RATIO`)}`,
                              `${i18T(`PROPORTION.SAFE_WATER_CACULATION.ARRIVE_DESIGN_STR`)}`,
                            ]}
                            values={[
                              {
                                text: checkIsNaNToString(expectedStrength()),
                                unit: 'Kgf/cm²',
                              },
                              {
                                //達成設計強度 = 預期強度 / 設計強度
                                text: checkIsNaNToString(
                                  +((Number(expectedStrength()) / proportion.dsgnStr) * 100)?.toFixed(2),
                                ),
                                unit: '%',
                              },
                            ]}
                            labelsx={rowLabelsx}
                            valuesx={rowValuesx}
                            showBorder={false}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Stack>
              </>
            )}
          </Box>
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
