import React from 'react';
import UsersManageRouter from './UsersManageRouter';

export default function UsersManagePage() {
  return (
    <>
      <UsersManageRouter />
    </>
  );
}
