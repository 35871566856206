import { Box, Typography, styled } from '@mui/material';
import React from 'react';

const Text = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  textIndent: '0.615em',
  letterSpacing: '0.615em',
}));

interface Props {
  value: string;
}

export default function ChartTitle(props: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '170px',
        height: '34px',
        background: '#003087',
        borderRadius: '50px',
      }}
    >
      <Text>{props.value}</Text>
    </Box>
  );
}
