import { TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungButton from '@Src/_basic/components/YatungButton';
import CoarseSieveReportTable from '@Src/_basic/components/YatungTest/CoarseSieveReportTable';
import FineSieveLineChart from '@Src/_basic/components/YatungTest/FineSieveLineChart';
import FineSieveReportTable from '@Src/_basic/components/YatungTest/FineSieveReportTable';
import TabCoarseSieveLineChart from '@Src/_basic/components/YatungTest/TabCoarseSieveLineChart';
import { AllTest, TestItemEnum } from '@Src/_basic/object/TestType';
import { CoarseSieveLegalTypeEnum } from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import { ReportApi } from '@Src/_basic/protocol/report/ReportApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { CardContent, Slider, Stack, Typography, styled } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const PrettoSlider = styled(Slider)({
  color: 'rgba(0, 28, 135, 0.7)',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: 'rgba(0, 28, 135, 1)',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const RevisionSieveReport = () => {
  const navigate = useNavigate();
  const { form1Id, form2Id, exptItemCode } = useParams();
  const { state } = useLocation();
  const { t: i18T } = useTranslation();
  const { userGroupsData } = useAuth();

  const [testFormS1, setTestFormS1] = useState<AllTest>();
  const [testFormS2, setTestFormS2] = useState<AllTest>();
  const [ratio, setRatio] = useState<number>(0);

  const createPost = useCallback(() => {
    if (!userGroupsData?.account || !form1Id || !form2Id || !exptItemCode) return;
    ReportApi.postReport(
      {
        createrId: userGroupsData.account,
        exptId1: Number(form1Id),
        exptId2: Number(form2Id),
        ratio: ratio * 100,
        factoryId: 3,
        exptItemCode: exptItemCode as TestItemEnum,
      },
      (_data) => {
        navigate('/report/sieve-report', { state: { keepPrevious: true } });
        console.log(_data);
      },
      (err) => {
        console.log(err);
      },
    );
  }, [userGroupsData, form1Id, form2Id, ratio, navigate, exptItemCode]);

  const switchReportTable = useCallback(
    (_testItemCode: TestItemEnum) => {
      if (!testFormS1 || !testFormS2) return null;

      switch (_testItemCode) {
        case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS:
          return <FineSieveReportTable testForm={[testFormS1, testFormS2]} ratio={ratio} />;
        case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS:
          return (
            <CoarseSieveReportTable
              testForm={[testFormS1, testFormS2]}
              ratio={ratio}
              type={CoarseSieveLegalTypeEnum.TYPE1}
            />
          );
        default:
          return null;
      }
    },
    [testFormS1, testFormS2, ratio],
  );

  const switchLineChart = useCallback(
    (_testItemCode: TestItemEnum) => {
      if (!testFormS1 || !testFormS2) return null;

      switch (_testItemCode) {
        case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS:
          return <FineSieveLineChart testFormS1={testFormS1} testFormS2={testFormS2} ratio={ratio} />;
        case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS:
          return <TabCoarseSieveLineChart testFormS1={testFormS1} testFormS2={testFormS2} ratio={ratio} />;
        default:
          return null;
      }
    },
    [testFormS1, testFormS2, ratio],
  );

  const goBackHandler = useCallback(() => {
    if (state?.searchParams) navigate('/view/sieve-report', { state: { searchParams: state?.searchParams } });
    else navigate('/report/sieve-report', { state: { keepPrevious: true } });
  }, [navigate, state]);

  useEffect(() => {
    if (!state?.searchParams) return;
    setRatio(state?._ratio);
  }, [state]);

  useEffect(() => {
    if (!form1Id || !form2Id || !exptItemCode) return;
    TestApi.getTestDetail({ exptItemCode: exptItemCode as TestItemEnum, exptId: Number(form1Id) }, (data) =>
      setTestFormS1(data),
    );
    TestApi.getTestDetail({ exptItemCode: exptItemCode as TestItemEnum, exptId: Number(form2Id) }, (data) =>
      setTestFormS2(data),
    );
  }, [form1Id, form2Id, exptItemCode]);

  return (
    <CardContent sx={{ borderRadius: '30px', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2);', position: 'relative' }}>
      <Stack flexDirection="column" gap={2}>
        <TestCardContainer sx={{ height: '700px' }}>
          <Stack sx={{ height: state?.searchParams ? '600px' : '550px' }}>
            {switchLineChart(exptItemCode as TestItemEnum)}
          </Stack>

          <Stack sx={state?.searchParams ? { mt: 2 } : { mt: 6 }}>
            {!state?.searchParams && (
              <Stack flexDirection="row">
                <Typography sx={{ fontSize: 22, color: '#003087', fontWeight: 'bold', mx: 2 }}>0</Typography>
                <PrettoSlider
                  max={100}
                  defaultValue={0}
                  valueLabelDisplay="on"
                  aria-label="pretto slider"
                  onChange={(e, value) => setRatio((value as number) / 100)}
                />
                <Typography sx={{ fontSize: 22, color: '#003087', fontWeight: 'bold', mx: 2 }}>100</Typography>
              </Stack>
            )}

            <Typography sx={{ fontSize: 22, alignSelf: 'center' }}>
              {`S1${i18T('REPORT.SIEVE_REPORT.BASIC.SIEVE')}:`}
              <span style={{ borderBottom: '1px solid gray', margin: '0 4px' }}>{(ratio * 100).toFixed(0)}</span>%
            </Typography>
          </Stack>
        </TestCardContainer>

        {switchReportTable(exptItemCode as TestItemEnum)}

        <Stack flexDirection="row" justifyContent="flex-end" gap={4} sx={{ my: 4 }}>
          <YatungButton color="red" width={100} text={'返回'} onClick={goBackHandler} />
          {!state?.searchParams && <YatungButton color="green" width={100} text={'生成'} onClick={createPost} />}
        </Stack>
      </Stack>
    </CardContent>
  );
};

export default memo(RevisionSieveReport);
