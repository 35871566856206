import React from 'react';
import { Typography } from '@mui/material';

export type ExtraData = {
  getButtonText: (status: string) => string;
};

interface Props {
  cellContent: boolean;
  formatExtraData: ExtraData;
}
export default function RemovedColumnFormatter(props: Props) {
  const { cellContent, formatExtraData } = props;
  return (
    <Typography
      variant="inherit"
      sx={{
        color: cellContent ? '#CB333B' : '#3E9B2F',
        fontWeight: 400,
        fontSize: 16,
      }}
    >
      {cellContent ? formatExtraData.getButtonText('停止') : formatExtraData.getButtonText('使用中')}
    </Typography>
  );
}
