import { ButtonProps, CircularProgress, Button as MuiButton, styled } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    blue: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    gray: PaletteOptions['primary'];
    light_red: PaletteOptions['primary'];
    inactive_blue: PaletteOptions['primary'];
    inactive_green: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    blue: true;
    red: true;
    green: true;
    yellow: true;
    gray: true;
    light_red: true;
    inactive_blue: true;
    inactive_green: true;
  }
}

export interface Props extends ButtonProps {
  text?: string | any;
  onClick?: (event?: any, event1?: any) => void;
  onSubmit?: () => void;
  loading?: boolean;
  width?: number;
}

const colorTheme = createTheme({
  palette: {
    blue: {
      main: '#003087',
    },
    light_red: {
      main: '#FC0000',
    },
    red: {
      main: '#CB333B',
    },
    green: {
      main: '#00D03A',
    },
    yellow: {
      main: '#F0CA00',
    },
    gray: {
      main: '#7C878E',
    },
    inactive_blue: {
      main: '#697EA3',
    },
    inactive_green: {
      main: '#01CE3ACC',
    },
  },
});

const Button = styled(MuiButton)((e) => ({
  padding: '8px 12px',
  borderRadius: 8,
  lineHeight: '1.4375rem',
  fontSize: '16px',
  color: '#fff',
}));

export default function YatungButton(props: Props) {
  return (
    <ThemeProvider theme={colorTheme}>
      <Button
        variant={props.variant ? props.variant : 'contained'}
        type={props.type}
        color={props.color}
        size={props.size}
        startIcon={props.loading ? <CircularProgress size={20} /> : props.startIcon}
        endIcon={props.endIcon}
        sx={{ fontSize: '20px', whiteSpace: 'nowrap', width: props.width ?? null, ...props.sx }}
        onClick={props.onClick}
        onSubmit={props.onSubmit}
        disabled={props.disabled}
      >
        {props.text}
        {props.children}
      </Button>
    </ThemeProvider>
  );
}
