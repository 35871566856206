import React from 'react';
import FactoryManagementUpdateCard from '../components/FactoryManagementUpdateCard';

export default function FactoryManagementUpdateCardScreen() {
  return (
    <>
      <FactoryManagementUpdateCard />
    </>
  );
}
