import { Protocol } from '../Protocol';

export class EntityProtocol {
  public static readonly GET_PAGINATION_ALL_NAME_ENTITIES: string = `${Protocol.YATUNG_API_ROOT}/name/all`;
  public static readonly GET_USER: string = `${Protocol.YATUNG_API_ROOT}/users/pageable`;
  public static readonly GET_ENTITY_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/name`;
  public static readonly CREATE_ENTITY: string = `${Protocol.YATUNG_API_ROOT}/name`;
  public static readonly UPDATE_ENTITY: string = `${Protocol.YATUNG_API_ROOT}/name`;
  public static readonly DELETE_ENTITY: string = `${Protocol.YATUNG_API_ROOT}/name`;
}
