import React from 'react';
import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import * as Yup from 'yup';
import {
  WarehouseAndStateData,
  WarehouseData,
  UpdateInitialStorageYard,
} from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { Formik, Form } from 'formik';
import { Grid } from '@mui/material';
import YatungPaper from '@Src/_basic/components/YatungPaper';
import { FeedTroughMaintenanceApi } from '@Src/_basic/protocol/feedTroughMaintenanceManagement/FeedTroughMaintenanceApi';
import StorageYardUpdateBody from './components/StorageYardUpdateBody';
import StorageYardUpdateActions from './components/StorageYardUpdateActions';
import { useTranslation } from 'react-i18next';

interface Props {
  data: WarehouseAndStateData;
  selectedFactory: number;
}
export default function StorageYardUpdatePaper(props: Props) {
  const { t: i18T } = useTranslation();
  const { data, selectedFactory } = props;
  const feedTroughMaintenanceSchema = Yup.object().shape({
    typeId: Yup.number().positive(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.TYPE')),
    name: Yup.string().required(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NAME')),
    specificationId: Yup.number().positive(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.SPEFICATION')),
    capacity: Yup.number().min(1, i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NUMBER')),
  });
  const { setStorageYardeData, storageYardeData } = useFeedTroughMaintenance();
  const initialFeedTroughMaintenance: UpdateInitialStorageYard = {
    id: data.id,
    typeId: data.typeId,
    code: data.code,
    name: data.name,
    specificationId: data.specificationId ? data.specificationId : 0,
    capacity: data.capacity ? data.capacity : 0,
  };
  const handleSaveSubmit = async (request: UpdateInitialStorageYard) => {
    FeedTroughMaintenanceApi.putStorage(
      request,
      (datas: WarehouseData[]) => {
        FeedTroughMaintenanceApi.getStorageBySearch(
          { factoryId: selectedFactory, isYard: true },
          (values: WarehouseAndStateData[]) => {
            mappingData(values);
          },
          undefined,
        );
      },
      undefined,
    );
  };
  const mappingData = (values: WarehouseAndStateData[]) => {
    const newArray = storageYardeData.map((value: WarehouseAndStateData) => {
      const newObject = values.find((v: WarehouseAndStateData) => v.id === value.id);
      return { ...value, ...newObject };
    });
    const mappedData = newArray.map((v: WarehouseAndStateData) => {
      if (v.id === data.id) {
        return { ...v, state: false };
      } else {
        return v;
      }
    });
    setStorageYardeData(mappedData);
  };
  return (
    <Formik
      key={data.id}
      initialValues={initialFeedTroughMaintenance}
      validationSchema={feedTroughMaintenanceSchema}
      onSubmit={(require) => handleSaveSubmit(require)}
      enableReinitialize
    >
      {({ values }) => {
        return (
          <Form>
            <Grid container justifyContent={'center'} item xs={12}>
              <YatungPaper
                body={<StorageYardUpdateBody values={values} selectedFactory={selectedFactory} />}
                actions={<StorageYardUpdateActions data={data} />}
                sx={{ borderRadius: 5, width: 610, height: 550, boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
