import { CalcCoarseSieve, CalcComboSieveAnalysis, CalcFineSieve } from '@Src/_basic/helpers/CalcTestForm';
import { CoarseSieve } from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import { ResponsiveLine } from '@nivo/line';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Axis, LineData } from './FineSieveLineChart';

interface Props {
  testFormS1: CoarseSieve;
  testFormS2?: CoarseSieve;
  lowerAxis: Array<Axis>;
  upperAxis: Array<Axis>;
  ratio?: number;
}

function sievePercentageConverter(formData: CalcFineSieve | CalcComboSieveAnalysis) {
  return [
    {
      x: '#8',
      y: formData.poreDiameterRemandPercentage7,
    },
    {
      x: '#4',
      y: formData.poreDiameterRemandPercentage6,
    },
    {
      x: '1/2"',
      y: formData.poreDiameterRemandPercentage4,
    },
    {
      x: '1"',
      y: formData.poreDiameterRemandPercentage2,
    },
    {
      x: '1 1/2"',
      y: formData.poreDiameterRemandPercentage1,
    },
  ];
}

const CoarseSieveLineChart = ({ testFormS1, testFormS2, ratio, lowerAxis, upperAxis }: Props) => {
  const { t: i18T } = useTranslation();

  const [lineData, setLineData] = useState<Array<LineData>>();

  const calcTestFormS1 = useMemo(() => new CalcCoarseSieve(testFormS1), [testFormS1]);
  const calcTestFormS2 = useMemo(() => testFormS2 && new CalcCoarseSieve(testFormS2), [testFormS2]);

  useEffect(() => {
    if (!lowerAxis || !upperAxis || !calcTestFormS1) return;

    if (!calcTestFormS2) {
      setLineData([
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.UPPER_STANDARD')})`,
          data: upperAxis,
        },
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.TEST_VALUE')})`,
          data: sievePercentageConverter(calcTestFormS1),
        },
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.LOWER_STANDARD')})`,
          data: lowerAxis,
        },
      ]);
    } else {
      const calcComboTestForm = new CalcComboSieveAnalysis(calcTestFormS1, calcTestFormS2, ratio as number);
      setLineData([
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.UPPER_STANDARD')})`,
          data: upperAxis,
        },
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.TEST_VALUE')})`,
          data: sievePercentageConverter(calcComboTestForm),
        },
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.LOWER_STANDARD')})`,
          data: lowerAxis,
        },
      ]);
    }
  }, [lowerAxis, upperAxis, calcTestFormS1, calcTestFormS2, ratio, i18T]);

  return (
    <>
      {lineData && (
        <ResponsiveLine
          data={lineData}
          colors={['rgba(0, 28, 135, 1)', 'rgba(108, 220, 245, 1)', 'rgba(0, 28, 135, 0.8)']}
          margin={{ top: 60, right: 20, bottom: 40, left: 40 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          lineWidth={3}
          pointSize={8}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          useMesh={true}
          legends={[
            {
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 80,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </>
  );
};

export default memo(CoarseSieveLineChart);
