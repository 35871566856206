import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import SiteManagementCreateScreen from './screens/SiteManagementCreateScreen';
import SiteManagementScreen from './screens/SiteManagementScreen';
import SiteManagementUpdateScreen from './screens/SiteManagementUpdateScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <SiteManagementScreen />,
  },
  {
    path: '/SiteManagementCreate/*',
    element: <SiteManagementCreateScreen />,
  },
  {
    path: '/SiteManagementUpdate/:id',
    element: <SiteManagementUpdateScreen />,
  },
];

const SiteManagementRouter = () => PermissionRoute(routesConfig);

export default memo(SiteManagementRouter);
