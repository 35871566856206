import { TextOptions } from '@Src/_basic/components/YatungTextSelect';
import { MultilingualFileData } from '@Src/_basic/object/MultilingualManagementType';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialState {
  data: MultilingualFileData | undefined;
  option: TextOptions[] ;
}

export const languageSlice = createSlice({
  name: 'languageSlice',
  initialState: {
    data: undefined,
    option: [],
  } as initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<MultilingualFileData>) => {
      state.data = action.payload;
    },
    setOption: (state, action: PayloadAction<TextOptions[]>) => {
      state.option = action.payload;
    },
  },
});