import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { CalcRecycleSpecificGravity } from '@Src/_basic/helpers/CalcTestForm';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  ImportRecycleSpecificGravity,
  PutRecycleSpecificGravityRequest,
  RecycleSpecificGravity,
} from '@Src/_basic/object/test/cementtitiousLiquid/RecycleSpecificGravityType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: RecycleSpecificGravity;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  specificWeight: basicNumberSchema,
});

function ExportRecycleSpecificGravityTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);
  const calcTestForm = useMemo(() => new CalcRecycleSpecificGravity(testDetail), [testDetail]);

  const initialValues = useMemo(
    () => ({
      specificWeight: testDetail.specificWeight,
    }),
    [testDetail],
  );

  const firstHeader = useMemo(
    () => ({
      title: i18T(`TEST.RECYCLE_SPECIFIC_GRAVITY.SPECIFIC_GRAVITY`),
      values: [`${i18T(`TEST.RECYCLE_SPECIFIC_GRAVITY.SOLID_CONTENT`)}(%)`],
      validationResult: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const firstInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.specificWeight?.toFixed(2)),
        values: [calcTestForm?.solidContent < 0 ? 0 : formatNumber(calcTestForm?.solidContent?.toFixed(2))],
        validationResult: testDetail?.verifyPassedResults?.chlorideIonContent,
      },
    ],
    [testDetail?.specificWeight, calcTestForm?.solidContent, testDetail?.verifyPassedResults?.chlorideIonContent],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportRecycleSpecificGravity = {
        specificWeight: checkIsNaN(+values.specificWeight),
      };

      TestApi.putTest<PutRecycleSpecificGravityRequest>(
        {
          exptItemCode: testDetail.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const firstView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <ColFieldInfo
            titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
            title={i18T(`TEST.RECYCLE_SPECIFIC_GRAVITY.SPECIFIC_GRAVITY`)}
          >
            <LabelInput
              valueWidth="150px"
              value={formik.values.specificWeight}
              isError={!!(formik.touched.specificWeight && formik.errors.specificWeight)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('specificWeight', e.target.value)}
            />
          </ColFieldInfo>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstInfo} />;
      default:
        break;
    }
  }, [edit, formik, firstInfo, firstHeader, i18T]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {firstView}
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportRecycleSpecificGravityTable);
