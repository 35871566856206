import {
  LegalStandardV2SpecificationDetailsResponse,
  SearchLegalStandardSpecificationDetailsParams,
} from '@Src/_basic/object/LegalStandardV2Type';
import { Response } from '../../object/ApiType';
import { Api } from '../Api';
import { LegalStandardV2Protocol } from './LegalStandardV2Protocol';

export class LegalStandardV2Api {
  // 取得符合條件的CNS規範規格明細
  public static getLegalStandardV2SpecificationDetailsBySearch(
    params: SearchLegalStandardSpecificationDetailsParams,
    onSuccess?: (_data: Array<LegalStandardV2SpecificationDetailsResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(
      LegalStandardV2Protocol.SEARCH_LEGAL_STANDARD_V2_SPECIFICATION_DETAILS,
      config,
      onSuccess,
      onFailed,
      onFinally,
    );
  }
}
