import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import QRCode from 'qrcode';

interface Props {
  text: string;
  width: number;
  height: number;
}
export default function YatungQRCode({ text = '123', width = 90, height = 90 }: Props) {
  const [dataUrl, setDataUrl] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateQR = async () => {
    try {
      const url = await QRCode.toDataURL(text);
      setDataUrl(url as string);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    generateQR();
  }, [generateQR, text]);

  return (
    <Box
      component="img"
      src={dataUrl}
      sx={{
        width,
        height,
      }}
    />
  );
}
