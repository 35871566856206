import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { EquipmentData } from '@Src/_basic/object/EquipmentManagementType';
import { useApi } from '@Src/redux/api/apiAction';
import moment from 'moment';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import FactoryColumnFormatter from './columnFormatter/FactoryColumnFormatter';
import NameColumnFormatter from './columnFormatter/NameColumnFormatter';
import PeriodColumnFormatter from './columnFormatter/PeriodColumnFormatter';
import PropertyCodeColumnFormatter from './columnFormatter/PropertyCodeColumnFormatter';
import StateColumnFormatter from './columnFormatter/StateColumnFormatter';

interface Props {
  equipmentData: EquipmentData[] | undefined;
  handleUpdateAndDelete: (id: number, modal: 'UPDATE' | 'DELETE') => void;
}
function EquipmentManagementTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { equipmentData, handleUpdateAndDelete } = props;

  const getButtonText = (status: string) => {
    switch (status) {
      case '編輯':
        return i18T('GLOBAL.UPDATE');
      case '刪除':
        return i18T('SAMPLEMANAGEMENT.DELETE');
      case '需維護':
        return i18T('GLOBAL.NEED_MAINTENANCE');
      case '正常':
        return i18T('GLOBAL.NORMAL');
      default:
        '';
    }
  };

  const tableData = useMemo(() => {
    return equipmentData?.map((table: EquipmentData, index: number) => ({ ...table, number: index + 1 }));
  }, [equipmentData]);

  const columns: Array<Column> = [
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.NO'),
      dataField: 'number',
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.EQUIPMENT'),
      dataField: 'name',
      formatter: NameColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.EQUIPMENT_NUMBER'),
      dataField: 'types',
      formatter: PropertyCodeColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.MAINTENANCETIME'),
      dataField: 'name',
      formatter: PeriodColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.KEEPERNAME'),
      dataField: 'keeperName',
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.FACTORY_TYPE'),
      dataField: 'factoryId',
      formatter: FactoryColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.CREATE_TIME'),
      dataField: 'createTime',
      formatter: ({ row }) => {
        return <>{moment(row.createTime).format('YYYY/MM/DD')}</>;
      },
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.LASTMAINTENANCETIME'),
      dataField: 'lastMaintenanceTime',
      formatter: ({ row }) => {
        return <>{moment(row.lastMaintenanceTime).format('YYYY/MM/DD')}</>; //轉換時間
      },
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.NEXTMAINTENANCETIME'),
      dataField: 'nextMaintenanceTime',
      formatter: ({ row }) => {
        return <>{moment(row.nextMaintenanceTime).format('YYYY/MM/DD')}</>; //轉換時間
      },
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.NOTIFICATION_TIME'),
      dataField: 'notificationTime',
      formatter: ({ row }) => {
        return <>{moment(row.notificationTime).format('YYYY/MM/DD')}</>; //轉換時間
      },
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.STATE'),
      dataField: 'needMaintenance',
      formatter: StateColumnFormatter,
      formatExtraData: { getButtonText: getButtonText },
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.ACTION'),
      dataField: '',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onEdit: (id: number) => handleUpdateAndDelete(id, 'UPDATE'),
        onStop: (id: number) => handleUpdateAndDelete(id, 'DELETE'),
        getButtonText: getButtonText,
      },
    },
  ];
  return <YatungTable columns={columns} data={tableData} loading={actionLoading} />;
}

export default memo(EquipmentManagementTable);
