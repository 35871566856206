import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Code(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2.5,18.9c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S3.5,18.9,2.5,18.9z M2.5,15.9c-0.5,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S3,15.9,2.5,15.9z M22.1,18.3H7.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h14.8c0.8,0,1.4,0.6,1.4,1.4C23.5,17.7,22.9,18.3,22.1,18.3z M7.2,16.5c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h14.8c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H7.2z M2.5,13.6c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C4.4,12.7,3.5,13.6,2.5,13.6z M2.5,10.6c-0.5,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S3,10.6,2.5,10.6z M22.1,13H7.2c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h14.8c0.8,0,1.4,0.6,1.4,1.4S22.9,13,22.1,13zM7.2,11.2c-0.2,0-0.4,0.2-0.4,0.4S7,12,7.2,12h14.8c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H7.2z M2.5,8.3c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S3.5,8.3,2.5,8.3z M2.5,5.3c-0.5,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S3,5.3,2.5,5.3z M22.1,7.7H7.2c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h14.8c0.8,0,1.4,0.6,1.4,1.4S22.9,7.7,22.1,7.7z M7.2,5.9C7,5.9,6.8,6.1,6.8,6.3c0,0.2,0.2,0.4,0.4,0.4h14.8c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4H7.2z" />
    </SvgIcon>
  );
}
