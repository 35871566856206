import {
  ConcreteHeaderData,
  PageableProjectsData,
  ProjectData,
  ProjectInfoData,
} from '@Src/_basic/object/ProductionHistoryType';
import { projectInfos, projects } from '@Src/_basic/protocol/productionHistory/_mocks_/ProductionHistoryTableMock';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialState {
  project: ProjectData;
  pageableProjects: PageableProjectsData | undefined;
  projectInfo: ProjectInfoData;
  concreteHeader: ConcreteHeaderData | undefined;
}

export const productionHistorySlice = createSlice({
  name: 'productionHistorySlice',
  initialState: {
    project: projects[0],
    pageableProjects: undefined,
    projectInfo: projectInfos[0],
    concreteHeader: undefined,
  } as initialState,
  reducers: {
    setProject: (state, action: PayloadAction<ProjectData>) => {
      state.project = action.payload;
    },
    setPageableProjects: (state, action: PayloadAction<PageableProjectsData | undefined>) => {
      state.pageableProjects = action.payload;
    },
    setProjectInfo: (state, action: PayloadAction<ProjectInfoData>) => {
      state.projectInfo = action.payload;
    },
    setConcreteHeader: (state, action: PayloadAction<ConcreteHeaderData>) => {
      state.concreteHeader = action.payload;
    },
    clearConcreteHeader: (state) => {
      state.concreteHeader = undefined;
    },
  },
});
