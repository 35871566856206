import { Box, Modal, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import YatungPageTitle from '@Src/_basic/components/YatungPageTitle';
import YatungQRCode from '@Src/_basic/components/YatungQRcode';

import CarPlateIcon from '@Src/_basic/icons/CarPlate';
import ClockIcon from '@Src/_basic/icons/Clock';
import ContentMangementIcon from '@Src/_basic/icons/ContentMangement';
import EditIcon from '@Src/_basic/icons/Edit';
import MenuIcon from '@Src/_basic/icons/Menu';
import SourceIcon from '@Src/_basic/icons/Source';
import StaffIcon from '@Src/_basic/icons/Staff';
import StorageIcon from '@Src/_basic/icons/Storage';
import WeightIcon from '@Src/_basic/icons/Weight';

import trunckImg from '@Basic/assets/trunck.svg';
import { MaterialReceiptsData } from '@Src/_basic/object/MaterialReceiptsType';
import { MaterialReceiptsApi } from '@Src/_basic/protocol/materialReceipt/MaterialReceiptsApi';
import { useApi } from '@Src/redux/api/apiAction';

interface Props {
  id: string;
  open: boolean;
  handleCloseModal: () => void;
}

const MaterialInfoDetailItem = ({ Icon, label, value = '' }: any) => {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
      direction="row"
      sx={{ p: 1, mt: 1, mb: 1, width: '320px', fontSize: '20px' }}
    >
      {Icon && <Icon sx={{ mr: 1 }} />}
      <Typography
        sx={{ minWidth: '60px', fontSize: '18px', flex: 'none', padding: '0 8px 0 0' }}
      >{`${label} :`}</Typography>
      {value}
    </Stack>
  );
};

function MaterialInfoDetailContent({ id, handleCloseModal }: Omit<Props, 'open'>) {
  const { actionLoading } = useApi();

  const [detail, setDetail] = useState<MaterialReceiptsData | undefined>(undefined);
  const [qrCodeData, setQRCodeData] = useState('');

  useEffect(() => {
    MaterialReceiptsApi.getReceipt(id, setDetail);
    MaterialReceiptsApi.getReceiptQRCode(id, (_data) => setQRCodeData(JSON.stringify(_data)));
  }, [id]);

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Box
        sx={{
          minWidth: 680,
          bgcolor: '#003087',
          color: 'white',
          boxShadow: 16,
          borderRadius: '20px',
          p: 2,
          opacity: actionLoading ? 0.8 : 1,
        }}
      >
        <Stack spacing={1} direction="row">
          <div>
            <YatungPageTitle
              title={`編號:${id}`}
              sx={{ flex: '1 0 0', borderBottom: '1px solid #FFFFFF', pb: 1 }}
              textColor="white"
              iconColor="white"
            />
            <Stack spacing={1} direction="row" sx={{ flex: '1 0 0' }}>
              <Box sx={{ width: '50%' }}>
                <MaterialInfoDetailItem
                  Icon={ClockIcon}
                  label="建單時間"
                  value={moment(detail?.createTime).format('YYYY/MM/DD_HH:mm')}
                />
                <MaterialInfoDetailItem
                  Icon={ClockIcon}
                  label="結單時間"
                  value={moment(detail?.receiptCompleteTime).format('YYYY/MM/DD_HH:mm')}
                />
                <MaterialInfoDetailItem Icon={CarPlateIcon} label="車號" value={detail?.plateNumber} />
                <MaterialInfoDetailItem Icon={ContentMangementIcon} label="規格" value={detail?.specification} />
                <MaterialInfoDetailItem Icon={StorageIcon} label="料庫" value={detail?.storage} />
                <MaterialInfoDetailItem Icon={SourceIcon} label="來源" value={detail?.source} />
                <MaterialInfoDetailItem Icon={StaffIcon} label="供應商" value={detail?.supplier} />
                <MaterialInfoDetailItem Icon={WeightIcon} label="總重" value={`${detail?.grossWeight} KG`} />
                <MaterialInfoDetailItem Icon={WeightIcon} label="空重" value={`${detail?.emptyWeight || 0} KG`} />
                {detail && (
                  <MaterialInfoDetailItem
                    Icon={WeightIcon}
                    label="淨重"
                    value={`${detail.grossWeight - (detail?.emptyWeight || 0)} KG`}
                  />
                )}
              </Box>
              <Box sx={{ width: '50%' }}>
                <MaterialInfoDetailItem Icon={StaffIcon} label="人員" value={detail?.creater} />
                <MaterialInfoDetailItem Icon={MenuIcon} label="送貨單淨重" value={`${detail?.exportValue || 0} KG`} />
                <MaterialInfoDetailItem
                  Icon={WeightIcon}
                  label="差異量"
                  value={`${detail?.diff !== null ? detail?.diff : 0} KG`}
                />
                <MaterialInfoDetailItem Icon={MenuIcon} label="提單單號" value={detail?.billOfLandingCode} />
                <MaterialInfoDetailItem Icon={MenuIcon} label="送單單號" value={detail?.deliveryTicketCode} />
              </Box>
            </Stack>
          </div>
          <Stack spacing={1} sx={{ p: 1, pt: 8, pl: 4 }} alignItems="center" justifyContent="space-between">
            <Box sx={{ width: '210px', height: '210px' }}>
              {qrCodeData && <YatungQRCode text={qrCodeData} width={210} height={210} />}
            </Box>
            <YatungImg img={trunckImg} sx={{ width: '180px', pt: 2, pb: 2 }} />
            <Typography sx={{ color: '#F0CA00', fontSize: '30px', letterSpacing: 0.515, pb: 2 }}>
              {detail?.status}
            </Typography>
            <YatungButton
              text="關閉"
              startIcon={<EditIcon />}
              onClick={handleCloseModal}
              sx={{
                color: '#ffffff',
                bgcolor: '#CB333B',
                alignSelf: 'flex-end',
                fontSize: '24px',
                width: 120,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#CB333B',
                },
              }}
            />
          </Stack>
        </Stack>
      </Box>
      {actionLoading && <YatungLoading overlay={true} />}
    </Stack>
  );
}

export default function MaterialInfoDetailModal({ open = false, ...props }: Props) {
  return (
    <Modal open={open}>
      <MaterialInfoDetailContent {...props} />
    </Modal>
  );
}
