/* eslint-disable no-unused-vars */
import { Response } from '@Src/_basic/object/ApiType';
import { CreateTagRequest, EditTagRequest, GetAllTagRequest, GetTagRequest, TagData } from '@Src/_basic/object/TagType';
import { Api } from '../Api';
import { TagProtocol } from './TagProtocol';

export class TagApi {
  // 取得所有配比標籤
  public static getAllProportionTags(
    params: GetAllTagRequest,
    onSuccess?: (data: Array<TagData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TagProtocol.GET_ALL_PROPORTION_TAG, config, onSuccess, onFailed, onFinally);
  }

  // 建立配比標籤
  public static postCreateProportionTag(
    request: CreateTagRequest,
    onSuccess?: (data: TagData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(TagProtocol.PROPORTION_TAG, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得特定配比標籤
  public static getProportionTag(
    params: GetTagRequest,
    onSuccess?: (data: TagData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TagProtocol.PROPORTION_TAG, config, onSuccess, onFailed, onFinally);
  }

  // 編輯配比標籤
  public static putEditProportionTag(
    request: EditTagRequest,
    onSuccess?: (data: TagData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(TagProtocol.PROPORTION_TAG, request, undefined, onSuccess, onFailed, onFinally);
  }
}
