import YatungLoading from '@Src/_basic/components/YatungLoading';
import YatungProgressBar from '@Src/_basic/components/YatungProgressBar';
import DistrictIcon from '@Src/_basic/icons/DistrictIcon';
import { TestInDistrictArea } from '@Src/_basic/object/TestInDistrictType';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface Props {
  testInDistrictAreaData: Array<TestInDistrictArea>;
  loading: boolean;
}
export default function TestInDistrictByAreaCard(props: Props) {
  const navigate = useNavigate();
  const { t: i18T } = useTranslation();

  const { testInDistrictAreaData, loading } = props;

  const TestDistrictDetailItem = ({ label, value = '' }: any) => {
    return (
      <Stack spacing={1} alignItems="center" justifyContent="space-between" direction="row">
        <Typography
          sx={{ fontFamily: 'Abel', fontWeight: 400, fontSize: 26, color: '#FFFFFF' }}
        >{`${label} : `}</Typography>
        <Typography
          sx={{ fontFamily: 'Abel', fontWeight: 400, fontSize: 26, color: '#FFFFFF' }}
        >{`${value}件`}</Typography>
      </Stack>
    );
  };
  const handleGotoNextPage = (id: number, areaName: string) => {
    navigate(`/realTimeMonitoring/testInDistrict/testsDistrictByFactory/${id}`, { state: { areaName } });
  };
  return (
    <>
      {loading && <YatungLoading overlay={true} />}
      {!loading && (
        <Stack display={'flex'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
          {testInDistrictAreaData.map((data: TestInDistrictArea, index: number) => {
            const progress = data.total == 0 ? 0 : Math.floor((data.completed / data.total) * 100);
            const getColor = () => {
              if (progress > 80) {
                return '#7C878E';
              } else if (progress > 50) {
                return '#697EA3';
              } else {
                return '#003087';
              }
            };

            return (
              <React.Fragment key={data.areaId}>
                <Stack sx={{ cursor: 'pointer', mt: 1 }}>
                  <Card
                    sx={{ width: '488px', height: '360px', borderRadius: '13px', background: '#FFFFFF' }}
                    onClick={() => handleGotoNextPage(data.areaId, data.areaName)}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          width: '462px',
                          height: '335px',
                          borderRadius: '11px',
                          background: getColor(),
                          position: 'relative',
                          p: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: '210px',
                            height: '50px',
                            borderLeft: '1px solid #fff',
                            borderBottom: '1px solid #fff',
                            position: 'absolute',
                            borderBottomLeftRadius: '11px',
                            borderTopRightRadius: '11px',
                            background: getColor(),
                            top: 0,
                            right: 0,
                            p: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 25,
                              fontFamily: 'Abel',
                              fontWeight: 400,
                              color: '#FFFFFF',
                              textAlign: 'center',
                            }}
                          >
                            {data.areaName}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: 25,
                            fontFamily: 'Abel',
                            fontWeight: 400,
                            color: '#FFFFFF',
                          }}
                        >
                          {i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.COMPLETENESS')}
                        </Typography>
                        <Stack spacing={1} alignItems="center" justifyContent="space-between" direction="row">
                          <Typography sx={{ fontSize: 60, fontFamily: 'Abel', fontWeight: 400, color: '#FFFFFF' }}>
                            {`${progress}％`}
                          </Typography>
                          <DistrictIcon sx={{ width: 75, height: 75 }} />
                        </Stack>
                        <YatungProgressBar percent={progress} />
                        <TestDistrictDetailItem
                          label={i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.INSPECTION_QUANTITY')}
                          value={`${data.completed}／${data.total}`}
                        />
                        <TestDistrictDetailItem
                          label={i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.ABNORMAL')}
                          value={data.abnormal}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Stack>
              </React.Fragment>
            );
          })}
        </Stack>
      )}
    </>
  );
}
