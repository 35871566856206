import React, { memo } from 'react';

import YatungButton, { Props as YatungButtonProps } from '@Src/_basic/components/YatungButton';

export interface CheckButtonProps extends YatungButtonProps {
  checked?: boolean;
}

const checkedStyle = {
  backgroundColor: '#002F8C',
  '&:hover': {
    backgroundColor: '#002F8C',
  },
};

const uncheckedStyle = {
  backgroundColor: '#D6E0EB',
  color: '#6C708C',
  '&:hover': {
    backgroundColor: '#D6E0EB',
  },
};

const CheckButton = ({ checked = false, sx, ...props }: CheckButtonProps) => {
  return (
    <YatungButton
      sx={{
        p: '8px',
        mr: 1,
        mb: 1,
        minWidth: '108px',
        fontSize: '16px',
        ...(checked ? checkedStyle : uncheckedStyle),
        ...sx,
      }}
      {...props}
    />
  );
};

export default memo(CheckButton);
