import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SearchV2(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M17.6,16.8l4.3,4.3c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.6,0.2-0.8,0l-4.3-4.3c-3.6,3.2-9.1,2.9-12.4-0.6C1.1,13.5,1.2,8,4.6,4.6C8,1.2,13.5,1.1,17,4.4C20.5,7.7,20.7,13.2,17.6,16.8L17.6,16.8z M10.9,18.7c2.8,0,5.3-1.5,6.7-3.9c1.4-2.4,1.4-5.4,0-7.8c-1.4-2.4-4-3.9-6.7-3.9c-4.3,0-7.8,3.5-7.8,7.8C3.1,15.2,6.6,18.7,10.9,18.7z"/>
    </SvgIcon>
  );
}
