import { ChildrenTypography, TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import { MonthlyReportFineAggAms } from '@Src/_basic/object/MonthlyReportType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { SetStateAction, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportBlock from './components/ReportBlock';
import RowContent from './components/RowContent';
import ToggleBlockButton from './components/ToggleBlockButton';

type FilterType = 'phenolphthalein' | 'ph' | 'magnetic' | null;

const FineAggregateAMSReport = ({ report }: { report: Array<MonthlyReportFineAggAms> }) => {
  const { t: i18T } = useTranslation();
  const [abnormalSummary, setAbnormalSummery] = useState({
    phenolphthalein: 0,
    ph: 0,
    magnetic: 0,
  });
  const [selectedFilter, setSelectedFilter] = useState<SetStateAction<FilterType>>(null);

  const [reports, setReports] = useState<Array<MonthlyReportFineAggAms>>([]);
  const [filteredReport, setFilteredReport] = useState<Array<MonthlyReportFineAggAms>>([]);

  const handleFilterChange = (filter: FilterType) => () => {
    const newFilter = selectedFilter === filter ? null : filter;
    const filteredReport = reports?.filter((i: MonthlyReportFineAggAms) =>
      newFilter ? i[newFilter]?.isAbnormal : true,
    );
    setSelectedFilter(newFilter);
    setFilteredReport(filteredReport);
  };

  useEffect(() => {
    const phenolphthaleinAbnormalDay = new Set();
    const phAbnormalDay = new Set();
    const magneticAbnormalDay = new Set();

    report.forEach((item: any) => {
      item.phenolphthalein?.isAbnormal ? phenolphthaleinAbnormalDay.add(moment(item.testDate).format('MM-DD')) : null;
      item.ph?.isAbnormal ? phAbnormalDay.add(moment(item.testDate).format('MM-DD')) : null;
      item.magnetic?.isAbnormal ? magneticAbnormalDay.add(moment(item.testDate).format('MM-DD')) : null;
    });

    const abnormalSummary = {
      phenolphthalein: phenolphthaleinAbnormalDay.size,
      ph: phAbnormalDay.size,
      magnetic: magneticAbnormalDay.size,
    };

    setReports(report);
    setFilteredReport(report);
    setAbnormalSummery(abnormalSummary);
  }, [report]);

  return (
    <>
      <TestCardContainer sx={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between' }}>
        <ToggleBlockButton
          isToggled={selectedFilter === 'phenolphthalein'}
          onPress={handleFilterChange('phenolphthalein')}
          top={
            <>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.AMS.PHENOLPHTHALEIN`)}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1} flexDirection="row">
              <ChildrenTypography style={abnormalSummary.phenolphthalein ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.phenolphthalein}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAY`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'ph'}
          onPress={handleFilterChange('ph')}
          top={
            <>
              <ChildrenTypography>{`ph${i18T(`MONTHLY_REPORT.BASE.VALUE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack flexDirection="row" mt={3}>
              <ChildrenTypography style={abnormalSummary.ph ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.ph}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAY`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'magnetic'}
          onPress={handleFilterChange('magnetic')}
          top={
            <>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.AMS.MAGNETIC_ATTRACTION`)}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1} flexDirection="row">
              <ChildrenTypography style={abnormalSummary.magnetic ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.magnetic}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAY`)}</ChildrenTypography>
            </Stack>
          }
        />
      </TestCardContainer>
      {filteredReport &&
        filteredReport?.map((item: MonthlyReportFineAggAms) => (
          <ReportBlock
            key={item.exptId}
            tester={item.tester}
            exptId={item.exptId}
            exptItemCode={item.exptItemCode}
            verifyPassedResults={item.verifyPassedResults}
            verifyCustomResults={item.verifyCustomResults}
          >
            <RowContent
              widths={[32, 32, 35]}
              labels={[
                i18T(`MONTHLY_REPORT.BASE.TEST_DATE`),
                i18T(`MONTHLY_REPORT.BASE.SAMPLING_DATE`),
                `${i18T(`MONTHLY_REPORT.LOSS_ON_IGNITION.BEFORE_LOSS_ON_IGNITION_WEIGHT`)}(g)`,
              ]}
              values={[
                moment(item.testDate).format('YYYY/MM/DD'),
                moment(item.sampleDate).format('YYYY/MM/DD'),
                item.samplePosition,
              ]}
            />
            <RowContent
              widths={[32, 32, 35]}
              labels={[
                `${i18T(`MONTHLY_REPORT.AMS.PHENOLPHTHALEIN`)}\n${i18T(`MONTHLY_REPORT.AMS.CHANGE_COLOR`)}`,
                i18T(`MONTHLY_REPORT.AMS.BEFORE_WATER_PH_VALUE`),
                i18T(`MONTHLY_REPORT.AMS.AFTER_WATER_PH_VALUE`),
              ]}
              values={[
                [item?.phenolphthalein?.result, item?.phenolphthalein?.isAbnormal],
                [item?.ph?.before?.toFixed(2) ?? '0', item?.ph.after ? true : item?.ph?.isAbnormal],
                [item?.ph?.after?.toFixed(2) ?? '0', item?.ph?.isAbnormal],
              ]}
            />
            <RowContent
              widths={[32, 32, 35]}
              labels={[
                `${i18T(`MONTHLY_REPORT.AMS.BEFORE_MAGNETIC_WEIGHT`)}(g)`,
                `${i18T(`MONTHLY_REPORT.AMS.AFTER_MAGNETIC_WEIGHT`)}(g)`,
                `${i18T(`MONTHLY_REPORT.AMS.MAGNETIC_ATTRACTION`)}(%)`,
              ]}
              values={[
                item?.magnetic?.before?.toFixed(2),
                item?.magnetic?.after?.toFixed(2),
                [-item?.magnetic?.amount?.toFixed(2) ?? '0', item?.magnetic?.isAbnormal],
              ]}
            />
          </ReportBlock>
        ))}
    </>
  );
};

export default memo(FineAggregateAMSReport);
