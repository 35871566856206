import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungImg from '@Src/_basic/components/YatungImg';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OrganizeManagementCreateForm from './OrganizeManagementCreateForm';

const createImage = '/media/images/create.png';

interface Props {
  showCreateCard: boolean;
  setShowCreateCard: (e: boolean) => void;
  setAlertIsOpen: (e: boolean) => void;
  setSetAlertTitle: (e: string) => void;
}

export default function OrganizeManagementCreateCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { showCreateCard, setShowCreateCard, setAlertIsOpen, setSetAlertTitle } = props;

  const handleGoBackButtonClick = () => {
    setShowCreateCard(false);
  };

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={showCreateCard}
        handleClose={handleGoBackButtonClick}
        icon={
          <YatungImg
            img={createImage}
            sx={{
              width: 28,
              height: 28,
              zIndex: 999,
              alignSelf: 'center',
              display: 'flex',
              textAlign: 'center',
              mr: 1,
            }}
          />
        }
        title={i18T('GLOBAL.CREATE')}
        body={
          <OrganizeManagementCreateForm
            onGoBackButtonClick={handleGoBackButtonClick}
            setAlertIsOpen={setAlertIsOpen}
            setSetAlertTitle={setSetAlertTitle}
          />
        }
      />
    </Grid>
  );
}
