import YatungPage from '@Src/_basic/components/YatungPage';
import YatungPaperWithTabs from '@Src/_basic/components/YatungPaperWithTabs';
import { DisplayProps as TabProps } from '@Src/_basic/components/YatungPaperWithTabs/components/Tab';
import { PrintInfoProps } from '@Src/_basic/object/PrintProductionType';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useElementSize } from 'usehooks-ts';
import CarbonEmission from './components/CarbonEmission';
import ConcreteQuality from './components/ConcreteQuality';
import Information from './components/Information';
import Monitor from './components/Monitor';
import PrintSelectProportion from './components/print/PrintSelectProportion';

export default function ViewProductionHistoryScreen() {
  const params = useParams();
  const { t: i18T } = useTranslation();
  const [titleRef, titleSize] = useElementSize();

  const { projectInfo, getProjectInfoByOrderId } = useProductionHistory();

  const [viewSelectProportion, setViewSelectProportion] = useState(false);
  const [info, setInfo] = useState<PrintInfoProps>();

  const tabs: Array<TabProps> = useMemo(() => {
    return [
      {
        label: i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.TITLE`),
        backgroundColor: 'inactive_blue',
        activeBackgroundColor: 'blue',
      },
      {
        label: i18T(`REPORT.PRODUCTION_HISTORY.CONCRETE_QUALITY.TITLE`),
        backgroundColor: 'inactive_blue',
        activeBackgroundColor: 'blue',
      },
      {
        label: i18T(`REPORT.PRODUCTION_HISTORY.CARBON_EMISSION.TITLE`),
        backgroundColor: 'inactive_green',
        activeBackgroundColor: 'green',
      },
      {
        label: i18T(`REPORT.PRODUCTION_HISTORY.MONITOR.TITLE`),
        backgroundColor: 'inactive_green',
        activeBackgroundColor: 'green',
      },
    ];
  }, [i18T]);

  const screens: Array<JSX.Element> = useMemo(() => {
    return [<Information key={1} />, <ConcreteQuality key={2} />, <CarbonEmission key={3} />, <Monitor key={4} />];
  }, []);

  const handleViewSelectProportion = useCallback(() => {
    setViewSelectProportion(true);
  }, []);

  const handleCloseSelectPrintProject = useCallback(() => {
    setViewSelectProportion(false);
  }, []);

  useEffect(() => {
    if (params.orderId) {
      getProjectInfoByOrderId(params.orderId);
    }
  }, [params.orderId]);

  useEffect(() => {
    setInfo({
      factoryId: projectInfo.factoryId,
      engName: projectInfo.engName,
      orderId: params.orderId as string,
    });
  }, [projectInfo]);

  return (
    <>
      <YatungPage
        title={i18T('REPORT.PRODUCTION_HISTORY.TITLE')}
        body={
          <>
            <Box ref={titleRef}>
              <Typography
                sx={{
                  color: '#7C878E',
                  fontSize: '30px',
                  fontWeight: 400,
                  lineHeight: '36px',
                  letterSpacing: '0.255em',
                }}
              >
                {projectInfo.orderId === params.orderId ? projectInfo.engName : 'Loading . . .'}
              </Typography>
            </Box>
            <Box sx={{ height: `calc(100% - ${titleSize.height}px)` }}>
              <YatungPaperWithTabs
                tabs={tabs}
                screens={screens}
                loading={projectInfo.orderId === params.orderId}
                handleViewSelectProportion={handleViewSelectProportion}
              />
            </Box>
          </>
        }
      />
      {info && viewSelectProportion && (
        <PrintSelectProportion open={viewSelectProportion} onClose={handleCloseSelectPrintProject} info={info} />
      )}
    </>
  );
}
