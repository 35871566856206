import YatungPaper from '@Src/_basic/components/YatungPaper';
import {
  UpdateInitialFeedTroughRequest,
  WarehouseAndStateData,
  WarehouseData,
} from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { FeedTroughMaintenanceApi } from '@Src/_basic/protocol/feedTroughMaintenanceManagement/FeedTroughMaintenanceApi';
import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FeedTroughMaintenanceManagementUpdateActions from './components/FeedTroughMaintenanceManagementUpdateActions';
import FeedTroughMaintenanceManagementUpdateBody from './components/FeedTroughMaintenanceManagementUpdateBody';

interface Props {
  data: WarehouseAndStateData;
  selectedFactory: number;
}
export default function FeedTroughMaintenanceManagementUpdatePaper(props: Props) {
  const { t: i18T } = useTranslation();
  const { data, selectedFactory } = props;
  const feedTroughMaintenanceSchema = Yup.object().shape({
    typeId: Yup.number().positive(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.TYPE')),
    name: Yup.string().required(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NAME')),
    specificationId: Yup.number().positive(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.SPEFICATION')),
    volume: Yup.number().min(1, i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NUMBER')),
    specificWeight: Yup.number().min(1, i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NUMBER')),
  });
  const { setFeedTroughMaintenanceData, feedTroughMaintenanceData } = useFeedTroughMaintenance();
  const initialFeedTroughMaintenance: UpdateInitialFeedTroughRequest = {
    id: data.id,
    typeId: data.typeId,
    code: data.code,
    name: data.name,
    specificationId: data.specificationId ? data.specificationId : 0,
    volume: data.volume ? data.volume : 0,
    specificWeight: data.specificWeight ? data.specificWeight : 0,
  };
  const handleSaveSubmit = async (request: UpdateInitialFeedTroughRequest) => {
    FeedTroughMaintenanceApi.putStorage(
      { ...request, capacity: request.volume * request.specificWeight },
      (datas: WarehouseData[]) => {
        FeedTroughMaintenanceApi.getStorageBySearch(
          { factoryId: selectedFactory, isYard: false },
          (values: WarehouseAndStateData[]) => {
            mappingData(values);
          },
          undefined,
        );
      },
      undefined,
    );
  };
  const mappingData = (values: WarehouseAndStateData[]) => {
    const newArray = feedTroughMaintenanceData.map((value: WarehouseAndStateData) => {
      const obj2 = values.find((v: WarehouseAndStateData) => v.id === value.id);
      return { ...value, ...obj2 };
    });
    const mappedData = newArray.map((v: WarehouseAndStateData) => {
      if (v.id === data.id) {
        return { ...v, state: false };
      } else {
        return v;
      }
    });
    setFeedTroughMaintenanceData(mappedData);
  };
  return (
    <Formik
      initialValues={initialFeedTroughMaintenance}
      validationSchema={feedTroughMaintenanceSchema}
      onSubmit={(require) => handleSaveSubmit(require)}
      enableReinitialize
    >
      {({ values }) => {
        return (
          <Form>
            <Grid container justifyContent={'center'} item xs={12}>
              <YatungPaper
                body={<FeedTroughMaintenanceManagementUpdateBody values={values} selectedFactory={selectedFactory} />}
                actions={<FeedTroughMaintenanceManagementUpdateActions data={data} />}
                sx={{ borderRadius: 5, width: 610, height: 550, boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
