import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { InformAndFineRecordList } from '@Src/_basic/object/FineRecordType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { FineRecordApi } from '@Src/_basic/protocol/fineRecord/FineRecordApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Grid, Pagination, Stack } from '@mui/material';
import React, { Reducer, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import RecordTable from '../components/RecordTable';

type QueryType = {
  factoryId: number;
  startTime: number;
  endTime: number;
};

type FilterValueType = QueryType & {
  timeInterval: number;
  areaId: number;
};

type SearchParams = QueryType & {
  page: number;
  pageSize: number;
};

type AsyncStorageSavingType = SearchParams & FilterValueType;

const textStyle = {
  fontSize: 18,
  fontWeight: 400,
  fontFamily: 'Inter',
  color: 'black',
};

const RecordListScreen = () => {
  const { t: i18T } = useTranslation();
  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  // const [pageInfo, setPageInfo] = useState(initPageInfo);
  const [fineRecordList, setFineRecordList] = useState<Array<InformAndFineRecordList>>([]);

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue = useMemo(() => {
    const defaultStart = new Date().getTime() - 1000 * 60 * 60 * 24;
    const defaultEnd = new Date().getTime();

    const {
      areaId = 0,
      factoryId = 0,
      timeInterval = 0,
      startTime = defaultStart,
      endTime = defaultEnd,
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      timeInterval,
      startTime,
      endTime,
    };
  }, [request, location.pathname]);

  const initQueryValue = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
      startTime: initFilterValue.startTime,
      endTime: initFilterValue.endTime,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const searchParams: SearchParams = useMemo(() => {
    return {
      ...query,
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
    };
  }, [query, pageInfo.page, pageInfo.pageSize]);

  const handlePageChange = useCallback((e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
  }, []);

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const reset = useCallback(() => {
    setFineRecordList([]);
    pageInfoDispatch({
      total: 0,
      page: 0,
      totalCount: 0,
    });
  }, []);

  useEffect(() => {
    if (!filterValue.timeInterval || !searchParams.factoryId) {
      reset();
    } else {
      setSaveRequest({ page: location.pathname, request: { ...filterValue, ...searchParams } });
      getRecordist(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getRecordist = useCallback(
    (searchParams: SearchParams) => {
      FineRecordApi.getFineRecordList(
        {
          ...searchParams,
          timezone: 'Asia/Taipei',
          sort: ['id,asc'],
        },
        (_data) => {
          setFineRecordList(
            _data.informAndFineRecordList.map((item, index) => ({
              ...item,
              index: index + 1 + pageInfo.pageSize * (pageInfo.page - 1),
            })),
          );
          pageInfoDispatch({
            total: _data.maxPage,
            totalCount: _data.totalElements,
          });
        },
        (err) => {
          setIsShowAlert(true);
          setAlertText(typeof err.message === 'string' ? err.message : JSON.stringify(err.message));
        },
      );
    },
    [pageInfo],
  );

  return (
    <YatungPage
      title={i18T('QUALITY_MANAGE.SIDE_MENU.STANDARDIZED_PENALTY_MANAGEMENT.INFORM_AND_FIND_RECORD')}
      body={
        <Stack>
          <YatungCombinationSelect<QueryType, FilterValueType>
            filterValue={filterValue}
            handleChange={handleChange}
            handleFilterChange={handleFilterChange}
            selectOptions={['AreaAndFactory', 'TimeInterval']}
          />
          <RecordTable data={fineRecordList} />
          <YatungConfirmAlert isShowAlert={isShowAlert} onClose={() => setIsShowAlert(false)} alertText={alertText} />
          {!!pageInfo.totalCount && (
            <Grid container alignItems={'center'} item xs={12} sx={{ p: 1 }}>
              <Grid container justifyContent={'center'} item xs={11}>
                <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
              </Grid>

              <Grid container justifyContent={'flex-end'} item xs={1}>
                <YatungPaperLabel label={`總數：${pageInfo.totalCount}`} sx={textStyle} />
              </Grid>
            </Grid>
          )}
        </Stack>
      }
    />
  );
};

export default memo(RecordListScreen);
