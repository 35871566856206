import axios from 'axios';
import { Pageable, Response } from '@Src/_basic/object/ApiType';
import { CustomStandardV2Protocol } from './CustomStandardV2Protocol';
import { CreateCustomStandardRequest, CustomStandardData, SearchCustomStandardsParams, SearchPageableCustomStandardsParams } from '@Src/_basic/object/CustomStandardType';
import { Api } from '../../Api';

export class StandardV2Api {
  public static getCustomStandardById(
    id: number,
    onSuccess?: (data: CustomStandardData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(CustomStandardV2Protocol.CUSTOM_STANDARD_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static getCustomStandardsBySearch(
    params: SearchCustomStandardsParams,
    onSuccess?: (data: Array<CustomStandardData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(CustomStandardV2Protocol.CUSTOM_STANDARDS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }


  public static getPageableCustomStandardsBySearch(
    params: SearchPageableCustomStandardsParams,
    onSuccess?: (data: Pageable<CustomStandardData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(CustomStandardV2Protocol.PAGEABLE_CUSTOM_STANDARDS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getCancelablePageableCustomStandardsBySearch(
    params: SearchPageableCustomStandardsParams,
    onSuccess?: (data: Pageable<CustomStandardData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers:{
        'Cancelable': true,
      },
      params,
    };

    Api.get(CustomStandardV2Protocol.PAGEABLE_CUSTOM_STANDARDS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getActivatedCustomStandardIds(
    params: SearchCustomStandardsParams,
    onSuccess?: (data: Array<number>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {

    const config = {
      params,
    }

    Api.get(CustomStandardV2Protocol.GET_ACTIVATED_CUSTOM_STANDARD_IDS, config, onSuccess, onFailed, onFinally);
  }

  public static toggleCustomStandardEnableStatusById(
    id: number,
    onSuccess?: (data: CustomStandardData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.patch(CustomStandardV2Protocol.TOGGLE_CUSTOM_STANDARD_ENABLE_STATUS, null, config, onSuccess, onFailed, onFinally);
  }

  public static createCustomStandard(
    request: CreateCustomStandardRequest,
    onSuccess?: (data: CustomStandardData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(CustomStandardV2Protocol.CREATE_CUSTOM_STANDARD, request, undefined, onSuccess, onFailed, onFinally);
  }
}
