import React, { forwardRef } from 'react';
import SandAndRockIcon from '@Src/_basic/icons/SandAndRock';

const SandAndRockRibbon = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <div ref={ref} className="ribbon sand-and-rock-ribbon">
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '5px',
        }}
      >
        <SandAndRockIcon />
      </div>
    </div>
  );
});

SandAndRockRibbon.displayName = 'SandAndRockRibbon';

export default SandAndRockRibbon;
