import YatungDialog from '@Src/_basic/components/YatungDialog';
import { Options } from '@Src/_basic/components/YatungSelect';
import VehicleModal from '@Src/_basic/icons/VehicleModal';
import { PostVehicleData } from '@Src/_basic/object/VehicleType';
import { VehicleAPi } from '@Src/_basic/protocol/Vehicle/VehicleAPi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import VehicleManagementCreateField from './components/VehicleManagementCreateField';
import VehicleManagementCreateSubmit from './components/VehicleManagementCreateSubmit';

interface Props {
  modalOpen: boolean;
  handleCloseModal: () => void;
  typeOption: Options[];
  factoryOption: Options[];
  getVehicleData: () => void;
}

const vahicleSchema = Yup.object().shape({
  plateNumber: Yup.string().required('請輸入車牌號碼'),
  type: Yup.number().typeError('請選擇類別').min(1, '請選擇至少一個類別').required('請選擇類別'),
  factory: Yup.number().typeError('請選擇廠別').min(1, '請選擇至少一個廠別').required('請選擇廠別'),
});

function VehicleManagementCreateCard({
  modalOpen,
  handleCloseModal,
  typeOption,
  factoryOption,
  getVehicleData,
}: Props) {
  const { t: i18T } = useTranslation();

  const initialValues: PostVehicleData = useMemo(
    () => ({
      plateNumber: '',
      type: [],
      factory: [],
    }),
    [],
  );

  const handleSubmit = useCallback(
    (request: PostVehicleData) => {
      VehicleAPi.PostVehicle(request, () => {
        getVehicleData();
        handleCloseModal();
      });
    },
    [getVehicleData, handleCloseModal],
  );

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={modalOpen}
        handleClose={handleCloseModal}
        icon={<VehicleModal sx={{ width: 40, height: 40, mr: 1, alignSelf: 'center' }} />}
        title={i18T('USERSMANAGEMENU.VEHICLE_MANAGEMENT.CREATE_VEHICLE')}
        body={
          <Formik
            initialValues={initialValues}
            validationSchema={vahicleSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <VehicleManagementCreateField
                    values={values}
                    setValues={setFieldValue}
                    typeOption={typeOption}
                    factoryOption={factoryOption}
                  />
                  <VehicleManagementCreateSubmit handleCloseModal={handleCloseModal} />
                </Form>
              );
            }}
          </Formik>
        }
      />
    </Grid>
  );
}

export default memo(VehicleManagementCreateCard);
