import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../Api';
import { SampleSourceProtocol } from './SampleSourceProtocol';
import { SampleSourceData, SearchSampleSourceParams } from '@Src/_basic/object/SampleSourceType';

export class SampleSourceApi {
  public static getSampleSourceBySearch(
    params: SearchSampleSourceParams,
    onSuccess?: (data: Array<SampleSourceData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SampleSourceProtocol.ALL_SAMPLE_SOURCE, config, onSuccess, onFailed, onFinally);
  }
}
