import { Protocol } from '../../Protocol';

export class OrganizationProtocol {
  public static readonly GET_ALL_ORGANIZATION: string = `${Protocol.YATUNG_API_ROOT}/users/organizations/all` // 取得全部組織清單
  public static readonly GET_PAGEABLE_ORGANIZATION: string = `${Protocol.YATUNG_API_ROOT}/users/organizations/pageable`; // 取得分頁組織清單
  public static readonly GET_ORGANIZATIONS: string = `${Protocol.YATUNG_API_ROOT}/users/organizations`; // 取得組織清單
  public static readonly POST_CREATE_ORGANIZATION: string = `${Protocol.YATUNG_API_ROOT}/users/organizations`; // 新增組織
  public static readonly PUT_UPDATE_ORGANIZATION: string = `${Protocol.YATUNG_API_ROOT}/users/organizations`; // 修改組織
  public static readonly DELETE_ORGANIZATION: string = `${Protocol.YATUNG_API_ROOT}/users/organizations`; // 刪除組織
}
