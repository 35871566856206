import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function Cementitious(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_636_57985)">
        <path
          d="M6.93424 5.45836L5.50854 0.244404H6.15011L7.23974 4.49093H7.29066L8.40066 0.244404H9.11351L10.2235 4.49093H10.2744L11.3641 0.244404H12.0056L10.5799 5.45836H9.92819L8.77745 1.30349H8.73672L7.58598 5.45836H6.93424Z"
          fill="white"
        />
        <path d="M15.4502 0L13.7699 6.24249H13.22L14.9003 0H15.4502Z" fill="white" />
        <path
          d="M17.14 5.45836V0.244404H18.9629C19.3261 0.244404 19.6256 0.307203 19.8616 0.4328C20.0975 0.556699 20.2732 0.723878 20.3886 0.934337C20.504 1.1431 20.5617 1.37477 20.5617 1.62936C20.5617 1.8534 20.5218 2.0384 20.442 2.18436C20.364 2.33033 20.2604 2.44574 20.1314 2.5306C20.0041 2.61546 19.8658 2.67826 19.7165 2.719V2.76991C19.876 2.7801 20.0364 2.83611 20.1976 2.93794C20.3589 3.03978 20.4938 3.18574 20.6024 3.37583C20.711 3.56593 20.7654 3.79845 20.7654 4.0734C20.7654 4.33478 20.7059 4.56985 20.5871 4.77861C20.4683 4.98737 20.2808 5.15286 20.0245 5.27506C19.7682 5.39726 19.4347 5.45836 19.024 5.45836H17.14ZM17.7714 4.89827H19.024C19.4364 4.89827 19.7292 4.8185 19.9023 4.65896C20.0771 4.49772 20.1645 4.30253 20.1645 4.0734C20.1645 3.89689 20.1195 3.73395 20.0296 3.5846C19.9396 3.43354 19.8115 3.31304 19.6452 3.22308C19.4788 3.13143 19.282 3.0856 19.0545 3.0856H17.7714V4.89827ZM17.7714 2.53569H18.9425C19.1326 2.53569 19.304 2.49835 19.4568 2.42368C19.6112 2.349 19.7334 2.24377 19.8234 2.10799C19.915 1.97221 19.9609 1.81266 19.9609 1.62936C19.9609 1.40023 19.8811 1.2059 19.7215 1.04636C19.562 0.885117 19.3091 0.804497 18.9629 0.804497H17.7714V2.53569Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.0683 7.21465H1.06939V23.8613H25.0683V7.21465ZM0.930664 7.07593V24H25.207V7.07593H0.930664Z"
          fill="white"
        />
        <path
          d="M6.09522 10.0557C5.88692 10.0557 5.68715 9.97103 5.53985 9.82034C5.39256 9.66965 5.30981 9.46527 5.30981 9.25216C5.30981 8.91151 5.55935 8.6369 5.7876 8.38572L6.09522 8.04688L6.40305 8.38595C6.63124 8.63716 6.88063 8.91171 6.88063 9.25216C6.88063 9.46527 6.79788 9.66965 6.65059 9.82034C6.5033 9.97103 6.30352 10.0557 6.09522 10.0557Z"
          fill="white"
        />
        <path
          d="M20.6599 11.7293C20.3028 11.7293 19.9604 11.5842 19.7079 11.3259C19.4554 11.0676 19.3135 10.7172 19.3135 10.3519C19.3135 9.76789 19.7413 9.29713 20.1326 8.86653L20.6599 8.28566L21.1876 8.86694C21.5788 9.29759 22.0064 9.76824 22.0064 10.3519C22.0064 10.7172 21.8645 11.0676 21.612 11.3259C21.3595 11.5842 21.017 11.7293 20.6599 11.7293Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.43448 9.88811C5.61287 10.0677 5.85483 10.1686 6.10711 10.1686C6.35939 10.1686 6.60134 10.0677 6.77974 9.88811C6.87059 9.79664 6.94117 9.68856 6.98856 9.57097C7.93175 9.88264 9.20481 10.0133 10.5915 9.48312C11.7919 8.77845 14.6818 7.66916 16.6387 8.86937C17.2123 9.08986 18.2055 9.39351 19.2376 9.54984C19.1031 9.79499 19.0168 10.0567 19.0168 10.3409C19.0168 10.7764 19.1886 11.194 19.4944 11.5019C19.8002 11.8098 20.215 11.9828 20.6474 11.9828C21.0799 11.9828 21.4947 11.8098 21.8005 11.5019C22.1063 11.194 22.2781 10.7764 22.2781 10.3409C22.2781 10.0045 22.1571 9.69963 21.9784 9.41638C22.8172 9.20293 24.1125 8.98963 24.6534 9.48312V12.3473H1.48682V8.86937C2.21261 8.33375 3.75529 7.59619 5.34112 8.65518C5.23124 8.825 5.15587 9.00813 5.15587 9.21089C5.15587 9.4649 5.25609 9.7085 5.43448 9.88811ZM2.61716 11.521C2.73184 11.6365 2.88738 11.7013 3.04956 11.7013C3.21174 11.7013 3.36728 11.6365 3.48196 11.521C3.59665 11.4056 3.66107 11.2489 3.66107 11.0857C3.66107 10.8248 3.4669 10.6144 3.28923 10.4219L3.04956 10.1621L2.81006 10.4218C2.63234 10.6142 2.43805 10.8246 2.43805 11.0857C2.43805 11.2489 2.50248 11.4056 2.61716 11.521ZM13.2928 11.1719C13.3693 11.2489 13.473 11.2922 13.5811 11.2922C13.6892 11.2922 13.7929 11.2489 13.8694 11.1719C13.9458 11.095 13.9888 10.9906 13.9888 10.8817C13.9888 10.7078 13.8593 10.5675 13.7409 10.4392L13.5811 10.266L13.4214 10.4391C13.303 10.5674 13.1734 10.7077 13.1734 10.8817C13.1734 10.9906 13.2164 11.095 13.2928 11.1719Z"
          fill="white"
        />
        <mask
          id="mask0_636_57985"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x={1}
          y={12}
          width={24}
          height={12}
        >
          <path d="M1.4812 12.9028H24.6478V23.307H1.4812V12.9028Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_636_57985)">
          <path
            d="M1.48068 13.5967C1.86375 13.5967 2.17429 13.2862 2.17429 12.9031C2.17429 12.52 1.86375 12.2095 1.48068 12.2095C1.09761 12.2095 0.787073 12.52 0.787073 12.9031C0.787073 13.2862 1.09761 13.5967 1.48068 13.5967Z"
            fill="white"
          />
          <path
            d="M4.39385 13.5967C4.77692 13.5967 5.08746 13.2862 5.08746 12.9031C5.08746 12.52 4.77692 12.2095 4.39385 12.2095C4.01078 12.2095 3.70024 12.52 3.70024 12.9031C3.70024 13.2862 4.01078 13.5967 4.39385 13.5967Z"
            fill="white"
          />
          <path
            d="M7.30701 13.5967C7.69008 13.5967 8.00062 13.2862 8.00062 12.9031C8.00062 12.52 7.69008 12.2095 7.30701 12.2095C6.92394 12.2095 6.6134 12.52 6.6134 12.9031C6.6134 13.2862 6.92394 13.5967 7.30701 13.5967Z"
            fill="white"
          />
          <path
            d="M10.2202 13.5967C10.6032 13.5967 10.9138 13.2862 10.9138 12.9031C10.9138 12.52 10.6032 12.2095 10.2202 12.2095C9.8371 12.2095 9.52656 12.52 9.52656 12.9031C9.52656 13.2862 9.8371 13.5967 10.2202 13.5967Z"
            fill="white"
          />
          <path
            d="M13.1333 13.5967C13.5164 13.5967 13.8269 13.2862 13.8269 12.9031C13.8269 12.52 13.5164 12.2095 13.1333 12.2095C12.7503 12.2095 12.4397 12.52 12.4397 12.9031C12.4397 13.2862 12.7503 13.5967 13.1333 13.5967Z"
            fill="white"
          />
          <path
            d="M16.0465 13.5967C16.4296 13.5967 16.7401 13.2862 16.7401 12.9031C16.7401 12.52 16.4296 12.2095 16.0465 12.2095C15.6634 12.2095 15.3529 12.52 15.3529 12.9031C15.3529 13.2862 15.6634 13.5967 16.0465 13.5967Z"
            fill="white"
          />
          <path
            d="M18.9597 13.5967C19.3427 13.5967 19.6533 13.2862 19.6533 12.9031C19.6533 12.52 19.3427 12.2095 18.9597 12.2095C18.5766 12.2095 18.2661 12.52 18.2661 12.9031C18.2661 13.2862 18.5766 13.5967 18.9597 13.5967Z"
            fill="white"
          />
          <path
            d="M21.8728 13.5967C22.2559 13.5967 22.5664 13.2862 22.5664 12.9031C22.5664 12.52 22.2559 12.2095 21.8728 12.2095C21.4898 12.2095 21.1792 12.52 21.1792 12.9031C21.1792 13.2862 21.4898 13.5967 21.8728 13.5967Z"
            fill="white"
          />
          <path
            d="M24.786 13.5967C25.1691 13.5967 25.4796 13.2862 25.4796 12.9031C25.4796 12.52 25.1691 12.2095 24.786 12.2095C24.4029 12.2095 24.0924 12.52 24.0924 12.9031C24.0924 13.2862 24.4029 13.5967 24.786 13.5967Z"
            fill="white"
          />
          <path
            d="M1.48068 18.8681C1.86375 18.8681 2.17429 18.5576 2.17429 18.1745C2.17429 17.7914 1.86375 17.4809 1.48068 17.4809C1.09761 17.4809 0.787073 17.7914 0.787073 18.1745C0.787073 18.5576 1.09761 18.8681 1.48068 18.8681Z"
            fill="white"
          />
          <path
            d="M4.39385 18.8681C4.77692 18.8681 5.08746 18.5576 5.08746 18.1745C5.08746 17.7914 4.77692 17.4809 4.39385 17.4809C4.01078 17.4809 3.70024 17.7914 3.70024 18.1745C3.70024 18.5576 4.01078 18.8681 4.39385 18.8681Z"
            fill="white"
          />
          <path
            d="M7.30701 18.8681C7.69008 18.8681 8.00062 18.5576 8.00062 18.1745C8.00062 17.7914 7.69008 17.4809 7.30701 17.4809C6.92394 17.4809 6.6134 17.7914 6.6134 18.1745C6.6134 18.5576 6.92394 18.8681 7.30701 18.8681Z"
            fill="white"
          />
          <path
            d="M10.2202 18.8681C10.6032 18.8681 10.9138 18.5576 10.9138 18.1745C10.9138 17.7914 10.6032 17.4809 10.2202 17.4809C9.8371 17.4809 9.52656 17.7914 9.52656 18.1745C9.52656 18.5576 9.8371 18.8681 10.2202 18.8681Z"
            fill="white"
          />
          <path
            d="M13.1333 18.8681C13.5164 18.8681 13.8269 18.5576 13.8269 18.1745C13.8269 17.7914 13.5164 17.4809 13.1333 17.4809C12.7503 17.4809 12.4397 17.7914 12.4397 18.1745C12.4397 18.5576 12.7503 18.8681 13.1333 18.8681Z"
            fill="white"
          />
          <path
            d="M16.0465 18.8681C16.4296 18.8681 16.7401 18.5576 16.7401 18.1745C16.7401 17.7914 16.4296 17.4809 16.0465 17.4809C15.6634 17.4809 15.3529 17.7914 15.3529 18.1745C15.3529 18.5576 15.6634 18.8681 16.0465 18.8681Z"
            fill="white"
          />
          <path
            d="M18.9597 18.8681C19.3427 18.8681 19.6533 18.5576 19.6533 18.1745C19.6533 17.7914 19.3427 17.4809 18.9597 17.4809C18.5766 17.4809 18.2661 17.7914 18.2661 18.1745C18.2661 18.5576 18.5766 18.8681 18.9597 18.8681Z"
            fill="white"
          />
          <path
            d="M21.8728 18.8681C22.2559 18.8681 22.5664 18.5576 22.5664 18.1745C22.5664 17.7914 22.2559 17.4809 21.8728 17.4809C21.4898 17.4809 21.1792 17.7914 21.1792 18.1745C21.1792 18.5576 21.4898 18.8681 21.8728 18.8681Z"
            fill="white"
          />
          <path
            d="M24.786 18.8681C25.1691 18.8681 25.4796 18.5576 25.4796 18.1745C25.4796 17.7914 25.1691 17.4809 24.786 17.4809C24.4029 17.4809 24.0924 17.7914 24.0924 18.1745C24.0924 18.5576 24.4029 18.8681 24.786 18.8681Z"
            fill="white"
          />
          <path
            d="M2.17429 23.446C2.17429 23.829 1.86375 24.1396 1.48068 24.1396C1.09761 24.1396 0.787073 23.829 0.787073 23.446C0.787073 23.0629 1.09761 22.7523 1.48068 22.7523C1.86375 22.7523 2.17429 23.0629 2.17429 23.446Z"
            fill="white"
          />
          <path
            d="M5.08746 23.446C5.08746 23.829 4.77692 24.1396 4.39385 24.1396C4.01078 24.1396 3.70024 23.829 3.70024 23.446C3.70024 23.0629 4.01078 22.7523 4.39385 22.7523C4.77692 22.7523 5.08746 23.0629 5.08746 23.446Z"
            fill="white"
          />
          <path
            d="M8.00062 23.446C8.00062 23.829 7.69008 24.1396 7.30701 24.1396C6.92394 24.1396 6.6134 23.829 6.6134 23.446C6.6134 23.0629 6.92394 22.7523 7.30701 22.7523C7.69008 22.7523 8.00062 23.0629 8.00062 23.446Z"
            fill="white"
          />
          <path
            d="M10.9138 23.446C10.9138 23.829 10.6032 24.1396 10.2202 24.1396C9.8371 24.1396 9.52656 23.829 9.52656 23.446C9.52656 23.0629 9.8371 22.7523 10.2202 22.7523C10.6032 22.7523 10.9138 23.0629 10.9138 23.446Z"
            fill="white"
          />
          <path
            d="M13.8269 23.446C13.8269 23.829 13.5164 24.1396 13.1333 24.1396C12.7503 24.1396 12.4397 23.829 12.4397 23.446C12.4397 23.0629 12.7503 22.7523 13.1333 22.7523C13.5164 22.7523 13.8269 23.0629 13.8269 23.446Z"
            fill="white"
          />
          <path
            d="M16.7401 23.446C16.7401 23.829 16.4296 24.1396 16.0465 24.1396C15.6634 24.1396 15.3529 23.829 15.3529 23.446C15.3529 23.0629 15.6634 22.7523 16.0465 22.7523C16.4296 22.7523 16.7401 23.0629 16.7401 23.446Z"
            fill="white"
          />
          <path
            d="M19.6533 23.446C19.6533 23.829 19.3427 24.1396 18.9597 24.1396C18.5766 24.1396 18.2661 23.829 18.2661 23.446C18.2661 23.0629 18.5766 22.7523 18.9597 22.7523C19.3427 22.7523 19.6533 23.0629 19.6533 23.446Z"
            fill="white"
          />
          <path
            d="M22.5664 23.446C22.5664 23.829 22.2559 24.1396 21.8728 24.1396C21.4898 24.1396 21.1792 23.829 21.1792 23.446C21.1792 23.0629 21.4898 22.7523 21.8728 22.7523C22.2559 22.7523 22.5664 23.0629 22.5664 23.446Z"
            fill="white"
          />
          <path
            d="M25.4796 23.446C25.4796 23.829 25.1691 24.1396 24.786 24.1396C24.4029 24.1396 24.0924 23.829 24.0924 23.446C24.0924 23.0629 24.4029 22.7523 24.786 22.7523C25.1691 22.7523 25.4796 23.0629 25.4796 23.446Z"
            fill="white"
          />
          <path
            d="M0.925795 15.5388C0.925795 15.9219 0.615255 16.2324 0.232184 16.2324C-0.150886 16.2324 -0.461426 15.9219 -0.461426 15.5388C-0.461426 15.1557 -0.150886 14.8452 0.232184 14.8452C0.615255 14.8452 0.925795 15.1557 0.925795 15.5388Z"
            fill="white"
          />
          <path
            d="M3.83896 15.5388C3.83896 15.9219 3.52842 16.2324 3.14535 16.2324C2.76228 16.2324 2.45174 15.9219 2.45174 15.5388C2.45174 15.1557 2.76228 14.8452 3.14535 14.8452C3.52842 14.8452 3.83896 15.1557 3.83896 15.5388Z"
            fill="white"
          />
          <path
            d="M6.75212 15.5388C6.75212 15.9219 6.44158 16.2324 6.05851 16.2324C5.67544 16.2324 5.3649 15.9219 5.3649 15.5388C5.3649 15.1557 5.67544 14.8452 6.05851 14.8452C6.44158 14.8452 6.75212 15.1557 6.75212 15.5388Z"
            fill="white"
          />
          <path
            d="M9.66528 15.5388C9.66528 15.9219 9.35474 16.2324 8.97167 16.2324C8.5886 16.2324 8.27806 15.9219 8.27806 15.5388C8.27806 15.1557 8.5886 14.8452 8.97167 14.8452C9.35474 14.8452 9.66528 15.1557 9.66528 15.5388Z"
            fill="white"
          />
          <path
            d="M12.5784 15.5388C12.5784 15.9219 12.2679 16.2324 11.8848 16.2324C11.5018 16.2324 11.1912 15.9219 11.1912 15.5388C11.1912 15.1557 11.5018 14.8452 11.8848 14.8452C12.2679 14.8452 12.5784 15.1557 12.5784 15.5388Z"
            fill="white"
          />
          <path
            d="M15.4916 15.5388C15.4916 15.9219 15.1811 16.2324 14.798 16.2324C14.4149 16.2324 14.1044 15.9219 14.1044 15.5388C14.1044 15.1557 14.4149 14.8452 14.798 14.8452C15.1811 14.8452 15.4916 15.1557 15.4916 15.5388Z"
            fill="white"
          />
          <path
            d="M18.4048 15.5388C18.4048 15.9219 18.0942 16.2324 17.7112 16.2324C17.3281 16.2324 17.0176 15.9219 17.0176 15.5388C17.0176 15.1557 17.3281 14.8452 17.7112 14.8452C18.0942 14.8452 18.4048 15.1557 18.4048 15.5388Z"
            fill="white"
          />
          <path
            d="M21.3179 15.5388C21.3179 15.9219 21.0074 16.2324 20.6243 16.2324C20.2413 16.2324 19.9307 15.9219 19.9307 15.5388C19.9307 15.1557 20.2413 14.8452 20.6243 14.8452C21.0074 14.8452 21.3179 15.1557 21.3179 15.5388Z"
            fill="white"
          />
          <path
            d="M24.2311 15.5388C24.2311 15.9219 23.9206 16.2324 23.5375 16.2324C23.1544 16.2324 22.8439 15.9219 22.8439 15.5388C22.8439 15.1557 23.1544 14.8452 23.5375 14.8452C23.9206 14.8452 24.2311 15.1557 24.2311 15.5388Z"
            fill="white"
          />
          <path
            d="M26.3119 16.2324C26.695 16.2324 27.0055 15.9219 27.0055 15.5388C27.0055 15.1557 26.695 14.8452 26.3119 14.8452C25.9289 14.8452 25.6183 15.1557 25.6183 15.5388C25.6183 15.9219 25.9289 16.2324 26.3119 16.2324Z"
            fill="white"
          />
          <path
            d="M0.232184 21.5038C0.615255 21.5038 0.925795 21.1933 0.925795 20.8102C0.925795 20.4272 0.615255 20.1166 0.232184 20.1166C-0.150886 20.1166 -0.461426 20.4272 -0.461426 20.8102C-0.461426 21.1933 -0.150886 21.5038 0.232184 21.5038Z"
            fill="white"
          />
          <path
            d="M3.14535 21.5038C3.52842 21.5038 3.83896 21.1933 3.83896 20.8102C3.83896 20.4272 3.52842 20.1166 3.14535 20.1166C2.76228 20.1166 2.45174 20.4272 2.45174 20.8102C2.45174 21.1933 2.76228 21.5038 3.14535 21.5038Z"
            fill="white"
          />
          <path
            d="M6.05851 21.5038C6.44158 21.5038 6.75212 21.1933 6.75212 20.8102C6.75212 20.4272 6.44158 20.1166 6.05851 20.1166C5.67544 20.1166 5.3649 20.4272 5.3649 20.8102C5.3649 21.1933 5.67544 21.5038 6.05851 21.5038Z"
            fill="white"
          />
          <path
            d="M8.97167 21.5038C9.35474 21.5038 9.66528 21.1933 9.66528 20.8102C9.66528 20.4272 9.35474 20.1166 8.97167 20.1166C8.5886 20.1166 8.27806 20.4272 8.27806 20.8102C8.27806 21.1933 8.5886 21.5038 8.97167 21.5038Z"
            fill="white"
          />
          <path
            d="M11.8848 21.5038C12.2679 21.5038 12.5784 21.1933 12.5784 20.8102C12.5784 20.4272 12.2679 20.1166 11.8848 20.1166C11.5018 20.1166 11.1912 20.4272 11.1912 20.8102C11.1912 21.1933 11.5018 21.5038 11.8848 21.5038Z"
            fill="white"
          />
          <path
            d="M14.798 21.5038C15.1811 21.5038 15.4916 21.1933 15.4916 20.8102C15.4916 20.4272 15.1811 20.1166 14.798 20.1166C14.4149 20.1166 14.1044 20.4272 14.1044 20.8102C14.1044 21.1933 14.4149 21.5038 14.798 21.5038Z"
            fill="white"
          />
          <path
            d="M17.7112 21.5038C18.0942 21.5038 18.4048 21.1933 18.4048 20.8102C18.4048 20.4272 18.0942 20.1166 17.7112 20.1166C17.3281 20.1166 17.0176 20.4272 17.0176 20.8102C17.0176 21.1933 17.3281 21.5038 17.7112 21.5038Z"
            fill="white"
          />
          <path
            d="M20.6243 21.5038C21.0074 21.5038 21.3179 21.1933 21.3179 20.8102C21.3179 20.4272 21.0074 20.1166 20.6243 20.1166C20.2413 20.1166 19.9307 20.4272 19.9307 20.8102C19.9307 21.1933 20.2413 21.5038 20.6243 21.5038Z"
            fill="white"
          />
          <path
            d="M23.5375 21.5038C23.9206 21.5038 24.2311 21.1933 24.2311 20.8102C24.2311 20.4272 23.9206 20.1166 23.5375 20.1166C23.1544 20.1166 22.8439 20.4272 22.8439 20.8102C22.8439 21.1933 23.1544 21.5038 23.5375 21.5038Z"
            fill="white"
          />
          <path
            d="M27.0055 20.8102C27.0055 21.1933 26.695 21.5038 26.3119 21.5038C25.9289 21.5038 25.6183 21.1933 25.6183 20.8102C25.6183 20.4272 25.9289 20.1166 26.3119 20.1166C26.695 20.1166 27.0055 20.4272 27.0055 20.8102Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_636_57985">
          <rect x="0.930664" width="24.2764" height={24} fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
