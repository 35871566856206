import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

type Value = number | string | Record<'text' | 'unit', string | number> | undefined;

interface RowContentProps {
  labels: string[];
  values: Value[];
  columnWidth?: (number | null)[];
  showBorder?: boolean;
  borderColor?: string;
  labelsx?: SxProps<Theme> | undefined;
  valuesx?: SxProps<Theme> | undefined;
  labelBorder?: boolean;
  justifyContent?: string;
  kgFixed?: boolean;
}

const fixDecimalPlaces = (value: number | string, places = 5) => {
  const factor = Math.pow(10, places);
  return typeof value === 'number' ? Math.floor(value * factor) / factor : value;
};

const RowContent = ({
  labels,
  values,
  columnWidth = [],
  showBorder = true,
  labelsx = {},
  valuesx = {},
  borderColor = '#9A99A1',
  labelBorder = false,
  justifyContent = 'space-between',
  kgFixed,
}: RowContentProps) => {
  const width = Math.floor(100 / labels.length);
  const labelStyle = {
    fontWeight: 700,
    fontSize: '20px',
    color: '#002F8C',
    p: 1,
  };

  const valueStyle = {
    fontWeight: 400,
    fontSize: '16px',
    color: '#9A99A1',
    p: 1,
  };

  const borderBottom = `1px solid ${borderColor}`;

  return (
    <Box>
      <Stack
        flexDirection="row"
        justifyContent={justifyContent}
        alignItems="center"
        sx={{
          ...(labelBorder ? { borderBottom } : {}),
        }}
      >
        {labels.map((l, i) => (
          <Typography
            key={`${l}_${i}`}
            sx={{ width: `${columnWidth[i] || width}%`, whiteSpace: 'pre-line', ...labelStyle, ...labelsx }}
          >
            {l}
          </Typography>
        ))}
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent={justifyContent}
        alignItems="center"
        sx={{
          ...(showBorder ? { borderBottom } : {}),
        }}
      >
        {values.map((v, i) => (
          <Typography key={`${v}_${i}`} sx={{ width: `${columnWidth[i] || width}%`, ...valueStyle, ...valuesx }}>
            {typeof v === 'object' ? (
              <>
                {fixDecimalPlaces(v.text)}
                <Typography component="span" sx={{ ...valuesx, px: 1, fontSize: '12px' }}>
                  {v.unit}
                </Typography>
              </>
            ) : (
              v && fixDecimalPlaces(v, kgFixed ? 0 : 5)
            )}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default RowContent;
