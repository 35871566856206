import { apiSlice } from '../api/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';

export function useApi() {
  const { actionLoading } = useSelector((state: RootState) => state.api);

  return {
    actionLoading,
  };
}
