import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Group(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="48" height="32" viewBox="0 0 48 32" fill="none">
      <path
        d="M16.9759 13.5338C16.2698 13.7939 15.5093 13.9355 14.7168 13.9355C10.9973 13.9355 7.982 10.8159 7.982 6.96774C7.982 3.11957 10.9973 0 14.7168 0C15.5093 0 16.2698 0.141602 16.9759 0.401669C14.3675 1.3623 12.5001 3.93941 12.5001 6.96774C12.5001 9.99608 14.3675 12.5732 16.9759 13.5338Z"
        fill="white"
      />
      <path
        d="M17.2253 15.6591C10.0316 16.7859 4.51812 23.2107 4.51812 30.9677C4.51812 31.3146 4.52915 31.6588 4.55094 32H0.0328159C0.0110306 31.6588 0 31.3146 0 30.9677C0 22.4162 6.70066 15.4839 14.9663 15.4839C15.7341 15.4839 16.4886 15.5437 17.2253 15.6591Z"
        fill="white"
      />
      <path
        d="M30.182 13.5338C30.8881 13.7939 31.6486 13.9355 32.441 13.9355C36.1606 13.9355 39.1759 10.8159 39.1759 6.96774C39.1759 3.11957 36.1606 0 32.441 0C31.6486 0 30.8881 0.141602 30.182 0.401669C32.7904 1.3623 34.6578 3.93941 34.6578 6.96774C34.6578 9.99608 32.7904 12.5732 30.182 13.5338Z"
        fill="white"
      />
      <path
        d="M29.9326 15.6591C37.1263 16.7859 42.6398 23.2107 42.6398 30.9677C42.6398 31.3146 42.6287 31.6588 42.607 32H47.1251C47.1469 31.6588 47.1579 31.3146 47.1579 30.9677C47.1579 22.4162 40.4572 15.4839 32.1916 15.4839C31.4238 15.4839 30.6693 15.5437 29.9326 15.6591Z"
        fill="white"
      />
      <path
        d="M29.9232 6.96774C29.9232 10.8159 26.9078 13.9355 23.1883 13.9355C19.4688 13.9355 16.4535 10.8159 16.4535 6.96774C16.4535 3.11957 19.4688 0 23.1883 0C26.9078 0 29.9232 3.11957 29.9232 6.96774Z"
        fill="white"
      />
      <path
        d="M38.3712 32C38.393 31.6588 38.404 31.3146 38.404 30.9677C38.404 22.4162 31.7034 15.4839 23.4378 15.4839C15.1721 15.4839 8.47148 22.4162 8.47148 30.9677C8.47148 31.3146 8.48251 31.6588 8.50429 32H38.3712Z"
        fill="white"
      />
    </SvgIcon>
  );
}
