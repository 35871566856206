import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function AggregateIcon(props: SvgIconProps) {
  return (
    <svg width={281} height={281} viewBox="0 0 281 281" fill="none">
      <rect
        x="1.5"
        y="1.5"
        width={278}
        height={278}
        rx="8.5"
        fill="url(#paint0_linear_225_86896)"
        stroke="white"
        strokeWidth={3}
      />
      <path
        d="M118.844 211.695L162.813 211.695L162.813 215.164L138.109 215.164C136.328 219.305 134.18 223.242 131.664 226.977L158.781 226.977L158.781 253.789L155.125 253.789L155.125 249.664L131.219 249.664L131.219 253.977L127.562 253.977L127.562 232.555C125.062 235.633 122.297 238.555 119.266 241.32C118.641 240.039 117.937 238.852 117.156 237.758C124.437 231.477 130.047 223.945 133.984 215.164L118.844 215.164L118.844 211.695ZM155.125 230.445L131.219 230.445L131.219 246.195L155.125 246.195L155.125 230.445Z"
        fill="white"
      />
      <path
        d="M80.3636 87.5865L57.3636 112.334L52.4848 138.825L42.3788 144.053L38.8939 161.481L26 181H174.397L168.879 164.966L148.667 135.687L129.152 125.579L96.7424 89.6779H95.3485L80.3636 87.5865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.9363 79.899L134.03 117.214L157.379 128.716L179.682 160.435L188.295 181H256L242.409 161.481L236.485 129.413L224.636 117.562L210 78.524L190.833 71.5529L166.788 41.9255L139.606 36L109.288 51.6851L98.9363 79.899ZM157.379 49.2452L134.03 53.4279L140.303 61.4447L159.818 63.5361L177.939 79.2212H186.652L157.379 49.2452Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_225_86896"
          x1="140.5"
          y1={0}
          x2="140.5"
          y2={281}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003087" />
          <stop offset={1} stopColor="#1681E1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
