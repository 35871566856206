import React, { useCallback, useEffect, useState } from 'react';
import { Stack, Modal, Box, Typography } from '@mui/material';
import moment from 'moment';

import YatungPageTitle from '@Src/_basic/components/YatungPageTitle';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungLoading from '@Src/_basic/components/YatungLoading';

import EditIcon from '@Src/_basic/icons/Edit';
import StorageYardCreateTimeIcon from '@Src/_basic/icons/StorageYardCreateTime';
import StorageYardSupplierIcon from '@Src/_basic/icons/StorageYardSupplier';
import StorageYardSpecificationValueIcon from '@Src/_basic/icons/StorageYardSpecificationValue';
import CarPlateIcon from '@Src/_basic/icons/CarPlate';
import ContentMangementIcon from '@Src/_basic/icons/ContentMangement';
import SourceIcon from '@Src/_basic/icons/Source';

import { YardReceiptsApi } from '@Src/_basic/protocol/yardReceipts/YardReceiptsApi';
import { YardReciptData } from '@Src/_basic/object/YardReceiptsType';
import { useTranslation } from 'react-i18next';

const StorageYardReciptDetailItem = ({ Icon, label, value = '' }: any) => {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
      direction="row"
      sx={{ p: 1, mt: 1, mb: 1, width: '100%', fontSize: '20px' }}
    >
      {Icon && <Icon sx={{ mr: 1 }} />}
      <Typography
        sx={{ minWidth: '60px', fontSize: '18px', flex: 'none', padding: '0 8px 0 0' }}
      >{`${label} :`}</Typography>
      <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none', padding: '0 8px 0 0' }}>{value}</Typography>
    </Stack>
  );
};

function StorageYardReciptDetailContent({ id, receiptCode, onClose }: any) {
  const { t: i18T } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<YardReciptData>({
    createTime: 0,
    factoryId: 0,
    id: 0,
    lastUpdateTime: 0,
    plateNumber: '',
    receiptCode: '',
    removed: false,
    source1Id: 0,
    source1Name: '',
    source2Id: 0,
    source2Name: '',
    source3Id: 0,
    source3Name: '',
    specification1Id: 0,
    specification1Name: '',
    specification1Value: 0,
    specification2Id: 0,
    specification2Name: '',
    specification2Value: 0,
    specification3Id: 0,
    specification3Name: '',
    specification3Value: 0,
    staffId: 0,
    staffName: '',
    stationId: 0,
    storageId: 0,
    storageName: '',
    supplier1Id: 0,
    supplier1Name: '',
    supplier2Id: 0,
    supplier2Name: '',
    supplier3Id: 0,
    supplier3Name: '',
    typeId: 0,
    typeName: '',
  });
  const getReceipt = useCallback(() => {
    setLoading(true);
    YardReceiptsApi.getYardReceiptsByIdAndReceiptCode(id, receiptCode, (res: YardReciptData) => {
      setDetail(res);
      setLoading(false);
    });
  }, []);
  const [totalSpecificationValue, setTotalSpecificationValue] = useState<number>(0);
  const specificationValueCal = () => {
    const cal = detail.specification1Value + detail.specification2Value + detail.specification3Value;
    setTotalSpecificationValue(cal);
  };
  useEffect(() => {
    getReceipt();
  }, []);
  useEffect(() => {
    specificationValueCal();
  }, [loading]);
  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Box
        sx={{
          minWidth: 680,
          bgcolor: '#003087',
          color: 'white',
          boxShadow: 16,
          borderRadius: '20px',
          p: 2,
          opacity: loading ? 0.8 : 1,
        }}
      >
        <Stack spacing={1} direction="row">
          <div>
            <YatungPageTitle
              title={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.STORAGE_YARD_FEEDING')}
              sx={{ flex: '1 0 0', borderBottom: '1px solid #FFFFFF', pb: 1 }}
              textColor="white"
              iconColor="white"
            />
            <Stack spacing={2} direction="row" sx={{ flex: '1 0 0' }}>
              <Box sx={{ width: '50%' }}>
                <StorageYardReciptDetailItem
                  Icon={StorageYardCreateTimeIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.TABLE.CREATETIME')}
                  value={moment(detail?.createTime).format('YYYY/MM/DD_hh:mm')}
                />
                <StorageYardReciptDetailItem
                  Icon={CarPlateIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.TABLE.CAR_NO')}
                  value={detail?.plateNumber}
                />
                <StorageYardReciptDetailItem
                  Icon={StorageYardSpecificationValueIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.TOTAL_DOUSHU')}
                  value={totalSpecificationValue}
                />
                <Stack spacing={12} direction="row">
                  <StorageYardReciptDetailItem
                    Icon={ContentMangementIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SPECIFICATION1')}
                    value={detail?.specification1Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={SourceIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SOURCE1')}
                    value={detail?.source1Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={StorageYardSupplierIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SUPPLIER1')}
                    value={detail?.supplier1Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={StorageYardSpecificationValueIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.DOUSHU1')}
                    value={detail?.specification1Value}
                  />
                </Stack>
                <Stack spacing={12} direction="row">
                  <StorageYardReciptDetailItem
                    Icon={ContentMangementIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SPECIFICATION2')}
                    value={detail?.specification2Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={SourceIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SOURCE2')}
                    value={detail?.source2Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={StorageYardSupplierIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SUPPLIER2')}
                    value={detail?.supplier2Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={StorageYardSpecificationValueIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.DOUSHU2')}
                    value={detail?.specification2Value}
                  />
                </Stack>
                <Stack spacing={12} direction="row">
                  <StorageYardReciptDetailItem
                    Icon={ContentMangementIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SPECIFICATION3')}
                    value={detail?.specification3Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={SourceIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SOURCE3')}
                    value={detail?.source3Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={StorageYardSupplierIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SUPPLIER3')}
                    value={detail?.supplier3Name}
                  />
                  <StorageYardReciptDetailItem
                    Icon={StorageYardSpecificationValueIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.DOUSHU3')}
                    value={detail?.specification3Value}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack spacing={12} sx={{ pt: 8 }} direction={'row'}>
              <StorageYardReciptDetailItem
                Icon={StorageYardSupplierIcon}
                label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.STAFFNAME')}
                value={detail?.staffName}
              />
              <YatungButton
                text={i18T('GLOBAL.CLOSE')}
                startIcon={<EditIcon />}
                onClick={onClose}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#CB333B',
                  alignSelf: 'flex-end',
                  fontSize: '24px',
                  width: 120,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#CB333B',
                  },
                }}
              />
            </Stack>
          </div>
        </Stack>
      </Box>
      {loading && <YatungLoading overlay={true} />}
    </Stack>
  );
}

export default function StorageYardReciptDetailModal({ open = false, ...props }: any) {
  return (
    <Modal open={open}>
      <>
        <StorageYardReciptDetailContent {...props} />
      </>
    </Modal>
  );
}
