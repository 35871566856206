import YatungDialog from '@Src/_basic/components/YatungDialog';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EditAccountCreateForm from './EditAccountCreateFrom';
import { useTranslation } from 'react-i18next';
import FactoryIcon from '@Src/_basic/icons/Factory';
import GroupIcon from '@Src/_basic/icons/Group';
import UserIcon from '@Src/_basic/icons/User';
import CreateReportGroupIcon from '@Src/_basic/icons/CreateReportGroup';
import BillingIcon from '@Src/_basic/icons/Billing';
import { UserGroupData } from '@Src/_basic/object/AccountManagementType';

interface Props {
  value: number;
  isOpen: boolean;
  handleGoBackButtonClick: () => void;
  userGroupData: UserGroupData;
  setUserGroupData: React.Dispatch<Partial<UserGroupData>>;
}

export default function EditAccountCreateCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { value, isOpen, handleGoBackButtonClick, userGroupData, setUserGroupData } = props;

  const title = () => {
    switch (value) {
      case 1:
        return i18T('USERSMANAGEMENU.EDIT_ACCOUNT.FACTORY');
      case 2:
        return i18T('USERSMANAGEMENU.EDIT_ACCOUNT.GROUP_SETTINGS');
      case 3:
        return i18T('USERSMANAGEMENU.EDIT_ACCOUNT.CHARACTER_SETTING');
      case 4:
        return i18T('USERSMANAGEMENU.EDIT_ACCOUNT.REPORT_GROUP');
      case 5:
        return i18T('USERSMANAGEMENU.EDIT_ACCOUNT.TRANSFER_ORDER_GROUP');
      default:
        return i18T('USERSMANAGEMENU.EDIT_ACCOUNT.ASSIGN_GROUP');
    }
  };

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={isOpen}
        handleClose={handleGoBackButtonClick}
        icon={
          <>
            {value === 1 && (
              <FactoryIcon
                style={{
                  width: 50,
                  height: 50,
                  marginRight: 1,
                }}
              />
            )}
            {value === 2 && (
              <GroupIcon
                sx={{
                  width: 50,
                  height: 50,
                  mr: 1,
                }}
              />
            )}
            {value === 3 && (
              <UserIcon
                sx={{
                  width: 50,
                  height: 50,
                  mr: 1,
                }}
              />
            )}
            {value === 4 && (
              <CreateReportGroupIcon
                style={{
                  width: 50,
                  height: 50,
                  marginRight: 1,
                }}
              />
            )}
            {(value === 5 || value === 6) && (
              <BillingIcon
                sx={{
                  width: 50,
                  height: 50,
                  mr: 1,
                }}
              />
            )}
          </>
        }
        title={title()}
        body={
          <EditAccountCreateForm
            value={value}
            onGoBackButtonClick={handleGoBackButtonClick}
            userGroupData={userGroupData}
            setUserGroupData={setUserGroupData}
          />
        }
      />
    </Grid>
  );
}
