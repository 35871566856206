import { AreaOptions } from '@Src/_basic/object/AreaType';
import { Options } from '../components/YatungSelect';
import { AreaData } from '../object/AreaType';
import { FactoryItem } from '../object/FactoryType';

export function areaDataToAreaOptionsConverter(areaData: Array<AreaData>): Array<AreaOptions> {
  return areaData.map(({ id, name, factoryList }: AreaData) => ({
    value: id,
    text: name,
    factories: factoryList.map(({ factoryName, factoryCode, branchCode }: FactoryItem) => ({
      value: `${factoryCode}${branchCode}`,
      text: factoryName,
    })),
  }));
}

export function getFactoryOptionsByAreaId(areaOptions: Array<AreaOptions>, areaId: number): Array<Options> {
  return areaOptions.find((option: AreaOptions) => option.value === areaId)?.factories || [];
}
