import moment from 'moment';

export const timeCalculate = (time: number | Date, formula = 'add', day = 1, overToday?: boolean) => {
  let _time = new Date(time).getTime();
  const _dayTime = 1000 * 60 * 60 * 24 * day;
  if (overToday) {
    if (_time < new Date().getTime()) _time = new Date().getTime();
  }

  switch (formula) {
    case 'add':
      return new Date(_time + _dayTime);
    case 'sub':
      return new Date(_time - _dayTime);
    default:
      return new Date(_time + _dayTime);
  }
};

export const caculateFutureDate = (beginDate: number, day: number, overToday?: boolean): number => {
  const currentDate = new Date().getTime();
  let _time = beginDate;
  if (overToday && beginDate < currentDate) {
    _time = currentDate;
  }
  return moment.utc(_time).add(day, 'days').valueOf();
};
