import { useEffect, useState } from 'react';
import { Options } from '../components/YatungSelect';
import { FactoryApi } from '../protocol/factoryManagement/FactoryApi';

export default function useGetAllFactories() {
  const [factoryOption, setFactoryOption] = useState<Array<Options>>([]);

  useEffect(() => {
    FactoryApi.getAllFactories((_data) => {
      setFactoryOption(
        _data.reduce(
          (prev, { id, factoryName }) => [
            ...prev,
            {
              value: id,
              text: factoryName,
            },
          ],
          [
            {
              value: 0,
              text: '全部廠資訊',
            },
          ] as Array<Options>,
        ),
      );
    });
  }, []);

  return {
    factoryOption,
    withoutAllFactoryOption: factoryOption.filter((option) => option.value !== 0),
  } as const;
}
