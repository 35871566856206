import { AuthSysBaseResponse, Response } from '@Src/_basic/object/ApiType';
import { Api } from '../../../Api';
import { GroupProtocol } from './GroupProtocol';
import { Content, AccountGroupCreateRequest, AccountGroupUpdateRequest, RightsData, RightsRequest } from '@Src/_basic/object/AccountRightType';

export class GroupApi {
  // 取得權限群組清單
  public static postGetRightsGroups(
    request: RightsRequest,
    onSuccess?: (data: RightsData<Content>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(GroupProtocol.POST_GET_RIGHTS_GROUPS, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得權限群組
  public static postGetRightsGroupById(
    id: string,
    onSuccess?: (data: Content) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id };
    Api.post(GroupProtocol.POST_GET_RIGHTS_GROUP_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 建立權限群組
  public static postCreateRightsGroup(
    request: AccountGroupCreateRequest,
    onSuccess?: (data: Content) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(GroupProtocol.POST_CREATE_RIGHTS_GROUP, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改權限群組
  public static postUpdateRightsGroup(
    request: AccountGroupUpdateRequest,
    onSuccess?: (data: Content) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(GroupProtocol.POST_UPDATE_RIGHTS_GROUP, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 刪除權限群組
  public static postDeleteRightsGroupById(
    id: string,
    onSuccess?: (data: Content) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id };
    Api.post(GroupProtocol.POST_DELETE_RIGHTS_GROUPS_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

}