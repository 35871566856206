import NamesExpand from '@Src/_basic/components/NamesExpand';
import { MixerData } from '@Src/_basic/object/MixerTypes';
import React from 'react';

interface Props {
  row: MixerData;
}
export default function FeedTroughsColumnFormatter(props: Props) {
  const { row } = props;
  return (
    <NamesExpand
      names={[].concat(...row.mixers.map((mixer: any) => mixer.storages.map((storage: any) => storage.name)))}
    />
  );
}
