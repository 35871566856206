/* eslint-disable react/prop-types */
import { Box, Card, CardContent, Grid, Pagination, Stack } from '@mui/material';
import moment from 'moment';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import CreateIcon from '@Src/_basic/icons/CreateV2';

import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { SearchProportionResponse } from '@Src/_basic/object/ProportionType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import { TagApi } from '@Src/_basic/protocol/tag/TagApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import FormulaCard from '../../components/FormulaCard';
import ProportionListFilterPanel from '../../components/ProportionListFilterPanel';

type SelectType = {
  factoryId: number;
};

export type QueryType = SelectType & {
  tags?: Array<number>;
  blurPropName?: string;
  slump?: number;
  dsgnStr?: number;
  grainSize?: number;
  startTime?: number;
  endTime?: number;
  sort?: string;
  lastUsedValue?: number;
};

type FilterValueType = QueryType & {
  areaId: number;
};

type SearchParams = QueryType & {
  page: number;
  pageSize: number;
};

type AsyncStorageSavingType = SearchParams &
  FilterValueType & {
    selectTag: Array<Options>;
  };

export type SortOrder = 'asc' | 'desc';

export interface Sort {
  orderBy: string | number;
  order: SortOrder;
}

export const sortItems: Array<Options> = [
  { text: '最近使用時間', value: 'latestShipmentDate' },
  { text: '年出貨方數', value: 'annualQtySummary' },
  { text: '使用工地數量', value: 'annualShipmentCount' },
  { text: '實際強度均值', value: 'averageStrengthOfYear' },
  { text: '平均生產成本', value: 'averageCostOfYear' },
  { text: 'KG強度成本', value: 'strengthCostPerKg' },
];

export const timeRangeOptions = [
  { text: '一星期內', value: 0.25 },
  { text: '兩星期內', value: 0.5 },
  { text: '一個月內', value: 1 },
  { text: '一季度內', value: 3 },
  { text: '半年以內', value: 6 },
  { text: '1年以內', value: 12 },
];

export type TimeRange = null | 'inOneMonth' | 'inOneQuarter' | 'inHalfYear' | 'inOneYear' | 'overOneYear';

export default function ProportionList() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { userAreaOptionsData } = useAuth();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [filterMode, setFilterMode] = useState(0);

  const [tagItems, setTagItems] = useState<Array<Options>>([]);
  const [selectTag, setSelectTag] = useState<Array<Options>>([]);

  const [proportions, setProportions] = useState<SearchProportionResponse | undefined>();

  const [areaAndFactory, setAreaAndFactory] = useState<{ area: string; factory: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const getProportions = useCallback((searchParams: SearchParams) => {
    setLoading(true);

    const _filterSearchParams = Object.entries(searchParams).reduce((prev, [key, value]) => {
      if (key !== 'lastUsedValue' && value) {
        return {
          ...prev,
          [key]: value,
        };
      }

      return prev;
    }, {} as SearchParams);

    ProportionApi.getSearchProportion(
      {
        ..._filterSearchParams,
        removed: false,
        isCurrVer: true,
      },
      (_data) => {
        setProportions(_data);
        pageInfoDispatch({
          total: _data.maxPage,
          totalCount: _data.totalElements,
        });
      },
      (error) => {
        setIsShowAlert(true);
        setAlertText(typeof error?.message === 'string' ? error?.message : 'Error !');
      },
      () => setLoading(false),
    );

    TagApi.getAllProportionTags(
      {
        factoryId: searchParams.factoryId,
        removed: false,
      },
      (_data) =>
        setTagItems(
          _data.reduce(
            (prev, current) => [...prev, { text: current.tagName, value: current.id }],
            [] as Array<Options>,
          ),
        ),
      (error) => {
        setIsShowAlert(true);
        setAlertText(typeof error?.message === 'string' ? error?.message : 'Error !');
      },
    );
  }, []);

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 6,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue = useMemo(() => {
    const {
      areaId = 0,
      factoryId = 0,
      blurPropName = '',
      dsgnStr = 0,
      slump = 0,
      grainSize = 0,
      sort = 'latestShipmentDate',
      lastUsedValue = 0.25,
      startTime = new Date().getTime(),
      endTime = new Date().getTime(),
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      blurPropName,
      dsgnStr,
      slump,
      grainSize,
      sort,
      lastUsedValue,
      startTime,
      endTime,
    };
  }, [request, location.pathname]);

  const initQueryValue = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
      blurPropName: initFilterValue.blurPropName,
      dsgnStr: initFilterValue.dsgnStr,
      slump: initFilterValue.slump,
      grainSize: initFilterValue.grainSize,
      sort: initFilterValue.sort,
      lastUsedValue: initFilterValue.lastUsedValue,
      startTime: initFilterValue.startTime,
      endTime: initFilterValue.endTime,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const searchParams: SearchParams = useMemo(() => {
    return {
      ...query,
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
      tags: selectTag.map((tag) => tag.value),
    };
  }, [query, pageInfo.page, pageInfo.pageSize, selectTag]);

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      getProportions({ ...searchParams, page });
      pageInfoDispatch({ page });
    },
    [getProportions, searchParams],
  );

  const handleSortChange = (orderBy: string) => {
    handleChange('sort')(orderBy);
  };

  const handleTagsSelect = useCallback(
    (tag: Options) => {
      const tags =
        selectTag.findIndex((t) => t.value === tag.value) > -1
          ? selectTag.filter((t) => t.value !== tag.value)
          : [...selectTag, tag];
      setSelectTag(tags);
    },
    [selectTag],
  );

  const handlerLastUsedTime = (value: number) => {
    handleChange('lastUsedValue')(value);
    const now = moment();
    let subTime;
    switch (value) {
      case 0.25:
        subTime = now.subtract(1, 'weeks');
        break;
      case 0.5:
        subTime = now.subtract(2, 'weeks');
        break;
      default:
        subTime = now.subtract(value, 'months');
        break;
    }
    handleChange('startTime')(subTime.toDate().getTime());
    handleChange('endTime')(new Date().getTime());
  };

  const handleFilterMode = (value: number) => {
    setFilterMode(value);
    if (value === 0) {
      handlerLastUsedTime(filterValue.lastUsedValue as number);
    }
  };

  const navigateToCreateProportion = useCallback(() => {
    navigate(`create`, {
      state: {
        area: { value: filterValue.areaId, text: areaAndFactory?.area },
        factory: { value: filterValue.factoryId, text: areaAndFactory?.factory },
      },
    });
  }, [navigate, filterValue.areaId, areaAndFactory, filterValue.factoryId]);

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  useEffect(() => {
    if (!searchParams.factoryId || searchParams.factoryId !== proportions?.proportions[0]?.factoryId) {
      setProportions(undefined);
    } else {
      const _areaName = userAreaOptionsData?.find((group) => group.value === filterValue?.areaId);
      const _factoryName = _areaName?.factories.find((factory) => factory.value === searchParams?.factoryId)?.text;
      setAreaAndFactory({ area: _areaName?.text ?? '', factory: _factoryName ?? '' });
      setSaveRequest({
        page: location.pathname,
        request: { ...filterValue, ...searchParams, selectTag },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, filterValue?.areaId, proportions, selectTag]);

  useEffect(() => {
    if (request?.page === location.pathname && request?.request && request?.request?.factoryId) {
      const { selectTag } = request.request;
      setSelectTag(selectTag);
      getProportions(request.request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <YatungPage
      title={i18T('PROPORTION_MANAGEMENT.PROPORTION_LIST.TITLE')}
      body={
        <>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={3}>
            <Stack flexDirection="row" alignItems="center" gap={3}>
              <YatungCombinationSelect<SelectType, FilterValueType>
                filterValue={filterValue}
                handleChange={handleChange}
                handleFilterChange={handleFilterChange}
                selectOptions={['AreaAndFactory']}
              />
            </Stack>
            <YatungButton
              startIcon={<CreateIcon sx={{ fontSize: '36px' }} />}
              color="gray"
              onClick={navigateToCreateProportion}
              disabled={loading}
            >
              新建配比
            </YatungButton>
          </Stack>
          <Box sx={{ p: 2, border: '1px solid #6C708C', borderRadius: '8px', opacity: loading ? 0.4 : 1 }}>
            <ProportionListFilterPanel
              filterValue={filterValue}
              handleChange={handleChange}
              tagItems={tagItems}
              selectTag={selectTag}
              handlerLastUsedTime={handlerLastUsedTime}
              handleTagsSelect={handleTagsSelect}
              handleSortChange={handleSortChange}
              sortItems={sortItems}
              filterMode={filterMode}
              handleFilterMode={handleFilterMode}
              filterSearch={() => getProportions(searchParams)}
            />
            <Card sx={{ mt: 2, minHeight: '400px', minWidth: '1300px' }}>
              <CardContent>
                <Stack sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                  {!loading &&
                    !!proportions?.proportions.length &&
                    proportions.proportions.map((item) => (
                      <FormulaCard
                        key={item.id}
                        factoryName={areaAndFactory?.factory}
                        areaName={areaAndFactory?.area}
                        proportion={item}
                        type={0}
                      />
                    ))}
                </Stack>
              </CardContent>
            </Card>
          </Box>
          {!!pageInfo.totalCount && (
            <Grid container alignItems={'center'} item xs={12} sx={{ p: 1 }}>
              <Grid container justifyContent={'center'} item xs={11}>
                <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
              </Grid>

              <Grid container justifyContent={'flex-end'} item xs={1}>
                <YatungPaperLabel label={`總數：${pageInfo.totalCount}`} totalCount />
              </Grid>
            </Grid>
          )}
          {loading && <YatungLoading overlay={true} />}
          <YatungConfirmAlert isShowAlert={isShowAlert} onClose={() => setIsShowAlert(false)} alertText={alertText} />
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
