import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungInput from '@Src/_basic/components/YatungInput';
import NameIcon from '@Src/_basic/icons/Name';
import CodeIcon from '@Src/_basic/icons/Code';

import EditForm from '../../../components/EditForm';
import { MaterialCodeAndTransactionResponse } from '@Src/_basic/object/MaterialCodeType';

const transferTypeIcon = '/media/images/icon_transferType.png';

interface Props {
  open?: boolean;
  handleClose: () => void;
  handleSubmit: (data: MaterialCodeAndTransactionResponse) => void;
  allTypes: Array<MaterialCodeAndTransactionResponse>;
  selectedType: MaterialCodeAndTransactionResponse;
  title: string;
  icon: JSX.Element;
}

export default function TypeEditModal({ open, handleClose, handleSubmit, allTypes, selectedType, title, icon }: Props) {
  const { t: i18T } = useTranslation();
  const [data, setData] = useState<MaterialCodeAndTransactionResponse>();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [isCodeDuplicated, setIsCodeDuplicated] = useState<boolean>(false);

  const onSubmit = () => {
    if (data) handleSubmit(data);
  };

  const onChangeCodeHandler = (e: any) => {
    if (selectedType.code) {
      setData((prev) => ({ ...prev, code: e.target.value.replace(/[^A-Z]/g, '') }));
    } else {
      setData((prev) => ({ ...prev, transactionCode: e.target.value.replace(/[^A-Z]/g, '') }));
    }
  };

  const onChangeNameHandler = (e: any) => {
    const { value } = e.target;
    if (selectedType.name) {
      setData((prev) => ({ ...prev, name: value }));
    } else {
      setData((prev) => ({ ...prev, transactionName: value }));
    }
  };

  useEffect(() => {
    setIsCodeDuplicated(false);

    if (data?.code) {
      const filter = allTypes.filter((type) => type.code === data?.code && type.code !== selectedType?.code);
      setIsCodeDuplicated(Boolean(filter.length));
    }

    if (data?.transactionCode) {
      const filter = allTypes.filter(
        (type) =>
          type.transactionCode === data?.transactionCode && type.transactionCode !== selectedType?.transactionCode,
      );
      setIsCodeDuplicated(Boolean(filter.length));
    }

    if (data?.code && data.name) {
      setCanSubmit(true);
    } else if (data?.transactionCode && data.transactionName) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [data]);

  useEffect(() => {
    if (selectedType) {
      setData({ ...selectedType });
    }
  }, [selectedType, handleClose]);

  return (
    <>
      {data && (
        <YatungDialog
          open={open}
          handleClose={handleClose}
          icon={icon}
          title={title}
          body={
            <EditForm onSubmit={onSubmit} onCancel={handleClose} canSubmit={canSubmit && !isCodeDuplicated}>
              <Box
                sx={{
                  py: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'stretch',
                  flexDirection: 'column',
                }}
              >
                <Stack sx={{ borderBottom: '1px solid #6C708C', py: 1 }} direction="row" justifyContent="space-between">
                  <Stack direction="row" sx={{ width: 240 }} alignItems="center">
                    <CodeIcon />
                    <Typography sx={{ pl: 1, fontWeight: 900, fontSize: 20 }}>
                      {i18T('APPLICATIONSMANAGEMENT.TABLE.CODE')}
                    </Typography>
                    <Typography sx={{ pl: 1, fontWeight: 900, fontSize: 12, color: '#FF000D' }}>{`*${i18T(
                      'APPLICATIONSMANAGEMENT.TABLE.CODE_NUM',
                      { num: 1 },
                    )}`}</Typography>
                  </Stack>
                  <Stack direction="row" sx={{ width: 240 }} alignItems="center">
                    <NameIcon />
                    <Typography sx={{ pl: 1, fontWeight: 900, fontSize: 20 }}>
                      {i18T('APPLICATIONSMANAGEMENT.TABLE.NAME')}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ pt: 4 }} direction="row" justifyContent="space-between">
                  <YatungInput
                    inputProps={{
                      maxLength: 1,
                    }}
                    onChange={onChangeCodeHandler}
                    value={data.code ?? data.transactionCode}
                    error={isCodeDuplicated}
                    helperText={isCodeDuplicated && i18T('APPLICATIONSMANAGEMENT.TABLE.CODE_REPEAT')}
                  />
                  <YatungInput onChange={onChangeNameHandler} value={data.name ?? data.transactionName} />
                </Stack>
              </Box>
            </EditForm>
          }
        />
      )}
    </>
  );
}
