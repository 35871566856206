import { Box, Paper } from '@mui/material';
import React from 'react';
import { useElementSize } from 'usehooks-ts';
import YatungPageTitle from './YatungPageTitle';

interface Props {
  title: string;
  backgroundImage?: string;
  actions?: JSX.Element;
  body?: JSX.Element;
  contentBgColor?: string;
  isOrder?: boolean;
  isMonthlyReport?: boolean;
  handleClick?: () => void;
  handleMonthlyReportClick?: () => void;
}

export default function YatungPage({
  title,
  actions,
  body,
  backgroundImage,
  contentBgColor,
  isOrder,
  isMonthlyReport,
  handleClick,
  handleMonthlyReportClick,
}: Props) {
  const [titleRef, titleSize] = useElementSize();

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        height: '100%',
        backgroundColor: '#F2F2F2',
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 2,
          height: '100%',
          position: 'relative',
          backgroundColor: contentBgColor || 'rgba(255, 255, 255, 0.4)',
        }}
      >
        <YatungPageTitle
          ref={titleRef}
          title={title}
          actions={actions}
          isOrder={isOrder}
          isMonthlyReport={isMonthlyReport}
          handleMonthlyReportClick={handleMonthlyReportClick}
          handleClick={handleClick}
        />
        <Box sx={{ height: `calc(100% - ${titleSize.height}px)` }}>{body}</Box>
      </Paper>
    </Box>
  );
}
