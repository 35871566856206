import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Type(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.7,12.7h5.7c1.9,0,3.5,1.5,3.5,3.5v2.2c0,1.9-1.5,3.5-3.5,3.5h-2.2c-1.9,0-3.5-1.5-3.5-3.5V12.7z M13.4,13.4v5.1c0,1.6,1.3,2.8,2.8,2.8h2.2c1.6,0,2.8-1.3,2.8-2.8v-2.2c0-1.6-1.3-2.8-2.8-2.8H13.4z" />
      <path d="M12.7,11.1h5.7c1.9,0,3.5-1.5,3.5-3.5V5.4c0-1.9-1.5-3.5-3.5-3.5h-2.2c-1.9,0-3.5,1.5-3.5,3.5V11.1z M13.4,10.4V5.4c0-1.6,1.3-2.8,2.8-2.8h2.2c1.6,0,2.8,1.3,2.8,2.8v2.2c0,1.6-1.3,2.8-2.8,2.8H13.4z" />
      <path d="M11.1,12.7H5.4c-1.9,0-3.5,1.5-3.5,3.5v2.2c0,1.9,1.5,3.5,3.5,3.5h2.2c1.9,0,3.5-1.5,3.5-3.5V12.7z M10.4,13.4v5.1c0,1.6-1.3,2.8-2.8,2.8H5.4c-1.6,0-2.8-1.3-2.8-2.8v-2.2c0-1.6,1.3-2.8,2.8-2.8H10.4z" />
      <path d="M11.1,11.1H5.4c-1.9,0-3.5-1.5-3.5-3.5V5.4c0-1.9,1.5-3.5,3.5-3.5h2.2c1.9,0,3.5,1.5,3.5,3.5V11.1z M10.4,10.4V5.4c0-1.6-1.3-2.8-2.8-2.8H5.4c-1.6,0-2.8,1.3-2.8,2.8v2.2c0,1.6,1.3,2.8,2.8,2.8H10.4z" />
    </SvgIcon>
  );
}
