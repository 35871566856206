import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { apiSlice } from './api/apiSlice';
import { areaSlice } from './area/areaSlice';
import { authSlice } from './auth/authSlice';
import { carbonEmissionSlice } from './carbonEmission/carbonEmissionSlice';
import { dateSlice } from './date/dateSlice';
import { equipmentSlice } from './equipment/equipmentSlice';
import { exptScheduleSlice } from './exptSchedule/exptScheduleSlice';
import { factorySlice } from './factory/factorySlice';
import { feedTroughMaintenanceSlice } from './feedTroughMaintenance/feedTroughMaintenanceSlice';
import { languageSlice } from './language/languageSlice';
import { layoutSlice } from './layout/layoutSlice';
import { materialInspectionSlice } from './materialInspection/materialInspectionSlice';
import { mixerInformationSlice } from './mixerInformaiton/mixerInformationSlice';
import { monitorSlice } from './monitor/monitorSlice';
import { organizeSlice } from './organize/organizeSlice';
import { permissionSlice } from './permission/permissionSlice';
import { printProductionSlice } from './printProduction/printProductionSlice';
import { productionHistorySlice } from './productionHistory/productionHistorySlice';
import { requestSavingSlice } from './requestSaving/requestSavingSlice';
import { signatureSlice } from './signature/signatureSlice';
import { siteSlice } from './site/siteSlice';
import { testInDistrictSlice } from './testInDistrict/testInDistrictSlice';

const authPersistConfig = {
  key: 'auth',
  storage: AsyncStorage,
  whitelist: ['language', 'token', 'userApiPermission', 'userAreaOptionsData'],
};

const requestSavingPersistConfig = {
  key: 'requestSaving',
  storage: AsyncStorage,
  whitelist: ['request'],
};

export const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  api: apiSlice.reducer,
  layout: layoutSlice.reducer,
  language: languageSlice.reducer,
  area: areaSlice.reducer,
  equipment: equipmentSlice.reducer,
  permission: permissionSlice.reducer,
  factory: factorySlice.reducer,
  site: siteSlice.reducer,
  organize: organizeSlice.reducer,
  feedTroughMaintenance: feedTroughMaintenanceSlice.reducer,
  productionHistory: productionHistorySlice.reducer,
  carbonEmission: carbonEmissionSlice.reducer,
  mixerInformation: mixerInformationSlice.reducer,
  monitor: monitorSlice.reducer,
  exptSchedule: exptScheduleSlice.reducer,
  signature: signatureSlice.reducer,
  printProduction: printProductionSlice.reducer,
  dateTime: dateSlice.reducer,
  testInDistrictDate: testInDistrictSlice.reducer,
  materialInspectionDate: materialInspectionSlice.reducer,
  requestSaving: persistReducer(requestSavingPersistConfig, requestSavingSlice.reducer),
});

export function* rootSaga() {}
