import React from 'react';
import AccountManagementCreateCard from '../components/AccountManagementCreateCard';

export default function AccountManagementCreateCardScreen() {
  return (
    <>
      <AccountManagementCreateCard />
    </>
  );
}
