import React from 'react';
import MixerInformationCard from '../components/MixerInformationCard';

export default function MixerInformationScreen() {
  return (
    <>
      <MixerInformationCard />
    </>
  );
}
