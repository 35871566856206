import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DoubleTriang(props: SvgIconProps) {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M7.28033 14L13.7197 20.4393L20.1591 14C20.1957 13.9633 20.2351 13.9313 20.2767 13.9038C20.5678 13.7116 20.9634 13.7437 21.2197 14C21.2563 14.0366 21.2883 14.076 21.3157 14.1176C21.508 14.4087 21.4759 14.8043 21.2197 15.0606L14.25 22.0302C14.1093 22.1709 13.9186 22.25 13.7197 22.25C13.5207 22.25 13.33 22.1709 13.1893 22.0302L6.21967 15.0606C5.92678 14.7677 5.92678 14.2928 6.21967 14C6.34472 13.8749 6.50297 13.8032 6.66605 13.785C6.69394 13.7818 6.72197 13.7803 6.75001 13.7803C6.94195 13.7803 7.13389 13.8535 7.28033 14Z"
        fill="#6C708C"
      />
      <path
        d="M7.28033 6.21969L13.7197 12.659L20.1591 6.21969C20.1957 6.18306 20.2351 6.15102 20.2767 6.12356C20.5678 5.93135 20.9634 5.96339 21.2197 6.21969C21.2563 6.25629 21.2883 6.29574 21.3157 6.33729C21.508 6.62844 21.4759 7.02399 21.2197 7.28034L14.25 14.2499C14.1093 14.3906 13.9186 14.4697 13.7197 14.4697C13.5207 14.4697 13.33 14.3906 13.1893 14.2499L6.21967 7.28034C5.92678 6.98739 5.92678 6.51249 6.21967 6.21969C6.34472 6.09461 6.50297 6.02295 6.66605 6.00468C6.69394 6.00156 6.72197 6 6.75001 6C6.94195 6 7.13389 6.07322 7.28033 6.21969Z"
        fill="#6C708C"
      />
    </SvgIcon>
  );
}
