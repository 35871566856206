import React, { useState, SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import YatungPage from '@Src/_basic/components/YatungPage';
import YatungTabs from '@Src/_basic/components/YatungTabs';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';

import SuppliersPanel from './components/SuppliersPanel';
import SpecsPanel from './components/SpecsPanel';
import SourcesPanel from './components/SourcesPanel';
import AttributesPanel from './components/AttributesPanel';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { TypeData } from '@Src/_basic/object/TypeType';

export interface MaterialTypeOptions extends Options {
  codeNumber: string;
}

export default function MaterialNumberManage() {
  const { t: i18T } = useTranslation();
  const [tab, setTab] = useState(0);

  const [types, setTypes] = useState<Array<TypeData>>([]);
  const [typeOptions, setTypeOptions] = useState<Options[]>([]);
  const [filterType, setFilterType] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const fetchAllTypes = () => {
    TypeApi.getAllTypes((allTypes: Array<TypeData>) => {
      setTypes(allTypes);
      setTypeOptions([
        { value: -1, text: '全部類別' },
        ...allTypes.map(({ id, name }: any) => ({ value: id, text: name })),
      ]);
      setFilterType(-1);
    });
  };

  useEffect(() => {
    fetchAllTypes();
  }, []);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_NUMBER_MANAGE.TITLE')}
      body={
        <>
          <YatungSelect
            disabled={typeOptions.length === 0}
            options={typeOptions}
            value={filterType}
            onChange={(e) => setFilterType(e.target.value as number)}
          />
          <YatungTabs
            tabs={[
              i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME'),
              i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIFICATION'),
              i18T('APPLICATIONSMANAGEMENT.TABLE.MATERIAL_SOURCE'),
              i18T('APPLICATIONSMANAGEMENT.TABLE.ATTRIBUTE'),
            ]}
            selectedIndex={tab}
            onChange={handleChange}
          />
          {tab === 0 && <SuppliersPanel filterType={filterType} types={types} />}
          {tab === 1 && <SpecsPanel filterType={filterType} types={types} />}
          {tab === 2 && <SourcesPanel filterType={filterType} types={types} />}
          {tab === 3 && <AttributesPanel filterType={filterType} types={types} />}
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
