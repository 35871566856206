import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import AccountGroupManagementPage from './accountRightsManagePages/AccountGroupManagement/AccountGroupManagementPage';
import AccountManagementPage from './accountRightsManagePages/AccountManagement/AccountManagementPage';
import ApiGroupManagementPage from './accountRightsManagePages/ApiGroupManagement/ApiGroupManagementPage';
import AssignGroupManagementPage from './accountRightsManagePages/AssignGroupManagement/AssignGroupManagementPage';
import OrganizeManagementPage from './accountRightsManagePages/OrganizeManagement/OrganizeManagementPage';
import ReportGroupManagementPage from './accountRightsManagePages/ReportGroupManagement/ReportGroupManagementPage';
import RoleMaintenancePage from './accountRightsManagePages/RoleMaintenance/RoleMaintenancePage';
import TransferOrderGroupManagementPage from './accountRightsManagePages/TransferOrderGroupManagement/TransferOrderGroupManagementPage';
import VerificationCodePage from './accountRightsManagePages/VerificationCode/VerificationCodePage';
import AreaManagementPage from './factoryManagePages/AreaManagement/AreaManagementPage';
import EntityManagementPage from './factoryManagePages/EntityManagement/EntityManagementPage';
import FactoryManagementPage from './factoryManagePages/FactoryManagement/FactoryManagementPage';
import FeedTroughMaintenanceManagementPage from './factoryManagePages/FeedTroughMaintenanceManagement/FeedTroughMaintenanceManagementPage';
import MixerAndProportionInformationBindPage from './factoryManagePages/MixerAndProportionInformationBinding/MixerAndProportionInformationBindPage';
import MixerInformationPage from './factoryManagePages/MixerInformation/MixerInformationPage';
import SampleManagementPage from './factoryManagePages/SampleManagement/SampleManagementPage';
import SampleSourceManagementPage from './factoryManagePages/SampleSourceManagement/SampleSourceManagementPage';
import SiteManagementPage from './factoryManagePages/SiteManagement/SiteManagementPage';
import VehicleManagementPage from './factoryManagePages/VehicleManagement/VehicleManagementPage';
import CalendarMangementPage from './otherMaintenancePages/CalendarMangement/CalendarMangementPage';
import ClearExecution from './otherMaintenancePages/ClearExecution';
import ClearExecutionLogPage from './otherMaintenancePages/ClearExecution/components/ClearExecutionLogPage';
import EquipmentManagementPage from './otherMaintenancePages/EquipmentManage/EquipmentManagementPage';
import FeedMachineStatus from './otherMaintenancePages/FeedMachineStatus';
import FeedMachineStatusDetailScreen from './otherMaintenancePages/FeedMachineStatus/screens/FeedMachineStatusDetailScreen';
import MaterialReceipts from './otherMaintenancePages/MaterialReceipts';
import MaterialsReceiptMaleUpOrder from './otherMaintenancePages/MaterialReceipts/components/MaterialsReceiptMaleUpOrder';
import MultilingualManagementPage from './otherMaintenancePages/MultilingualManagement/MultilingualManagementPage';
import StorageYardMaterialReceipts from './otherMaintenancePages/StorageYardMaterialReceipts';
import WeightUnitManage from './otherMaintenancePages/WeightUnitManage';
import FactoryMaterialCodeManage from './rawMaterialManagePages/pages/FactoryMaterialCodeManage';
import MaterialCodeManage from './rawMaterialManagePages/pages/MaterialCodeManage';
import MaterialNumberManage from './rawMaterialManagePages/pages/MaterialNumberManage';
import MaterialPrice from './rawMaterialManagePages/pages/MaterialPrice';

const routesConfig: RouteConfig[] = [
  {
    path: '/AreaManagement/*',
    element: <AreaManagementPage />,
    permission: 'WD001',
  },
  {
    path: '/FactoryManagement/*',
    element: <FactoryManagementPage />,
    permission: 'WD002',
  },
  {
    path: '/SiteManagement/*',
    element: <SiteManagementPage />,
    permission: 'WD003',
  },
  {
    path: '/FeedTroughMaintenanceManagement/*',
    element: <FeedTroughMaintenanceManagementPage />,
    permission: 'WD004',
  },
  {
    path: '/MixerInformation/*',
    element: <MixerInformationPage />,
    permission: 'WD005',
  },
  {
    path: '/MixerAndProportionBind/*',
    element: <MixerAndProportionInformationBindPage />,
    permission: 'WD006',
  },
  {
    path: '/EntityManagement/*',
    element: <EntityManagementPage />,
    permission: 'WD007',
  },
  {
    path: '/VehicleManagement/*',
    element: <VehicleManagementPage />,
    permission: 'WD008',
  },
  {
    path: '/SampleManagement/*',
    element: <SampleManagementPage />,
    permission: 'WD009',
  },
  {
    path: '/SampleSourceManagement/*',
    element: <SampleSourceManagementPage />,
    permission: 'WD010',
  },
  {
    path: '/materialCodeMangae/*',
    element: <MaterialCodeManage />,
    permission: 'WD011',
  },
  {
    path: '/materialNumberMangae/*',
    element: <MaterialNumberManage />,
    permission: 'WD012',
  },
  {
    path: '/factoryMaterialCodeMangae/*',
    element: <FactoryMaterialCodeManage />,
    permission: 'WD013',
  },
  {
    path: '/materialPrice/*',
    element: <MaterialPrice />,
    permission: 'WD014',
  },
  {
    path: '/OrgaNizeManagement/*',
    element: <OrganizeManagementPage />,
    permission: 'WD015',
  },
  {
    path: '/AccountManagement/*',
    element: <AccountManagementPage />,
    permission: 'WD016',
  },
  {
    path: '/AccountGroupManagement/*',
    element: <AccountGroupManagementPage />,
    permission: 'WD017',
  },
  {
    path: '/RoleMaintenance/*',
    element: <RoleMaintenancePage />,
    permission: 'WD018',
  },
  {
    path: '/ApiGroupManagement/*',
    element: <ApiGroupManagementPage />,
    permission: 'WD019',
  },
  {
    path: '/ReportGroupManagement/*',
    element: <ReportGroupManagementPage />,
    permission: 'WD020',
  },
  {
    path: '/TransferOrderGroupManagement/*',
    element: <TransferOrderGroupManagementPage />,
    permission: 'WD021',
  },
  {
    path: '/AssignGroupManagement/*',
    element: <AssignGroupManagementPage />,
    permission: 'WD022',
  },
  {
    path: '/VerificationCode/*',
    element: <VerificationCodePage />,
    permission: 'WD023',
  },
  {
    path: '/WeightUnitManage/*',
    element: <WeightUnitManage />,
    permission: 'WD024',
  },
  {
    path: '/equipmentManage/*',
    element: <EquipmentManagementPage />,
    permission: 'WD025',
  },
  {
    path: '/materialReceipts/*',
    element: <MaterialReceipts />,
    permission: 'WD026',
  },
  {
    path: '/materialReceipts/materialsReceiptMaleUpOrder/*',
    element: <MaterialsReceiptMaleUpOrder />,
    permission: 'WD026',
  },
  {
    path: '/storageYardMaterialReceipts/*',
    element: <StorageYardMaterialReceipts />,
    permission: 'WD027',
  },
  {
    path: '/feedMachineStatus/*',
    element: <FeedMachineStatus />,
    permission: 'WD028',
  },
  {
    path: '/feedMachineStatus/FeedMachineStatusDetail/*',
    element: <FeedMachineStatusDetailScreen />,
    permission: 'WD028',
  },
  {
    path: '/clearExecution/*',
    element: <ClearExecution />,
    permission: 'WD029',
  },
  {
    path: '/clearExecution/ClearExecutionLogPage/*',
    element: <ClearExecutionLogPage />,
    permission: 'WD029',
  },
  {
    path: '/CalendarManagement/*',
    element: <CalendarMangementPage />,
    permission: 'WD030',
  },
  {
    path: '/MultilingualManagement/*',
    element: <MultilingualManagementPage />,
    permission: 'WD031',
  },
];

const UsersManageRouter = () => PermissionRoute(routesConfig);

export default memo(UsersManageRouter);
