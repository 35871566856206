import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React, { memo, useState } from 'react';

interface Props {
  header: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
  headerContainerStyle?: React.CSSProperties;
}

const Container = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(2, 3),
  borderRadius: theme.spacing(1),
  backgroundColor: '#ffffff', // Replace with your desired background color
}));

const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Header = styled('div')({
  flex: 1,
});

const CollapseIconButton = styled(IconButton)({
  marginLeft: '8px',
});

function SectionCollapse({ header, children, style, headerContainerStyle }: Props) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Container style={style}>
      <HeaderContainer style={headerContainerStyle}>
        <Header>{header}</Header>
        <CollapseIconButton sx={{ height: 40, width: 40 }} onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </CollapseIconButton>
      </HeaderContainer>
      {isOpen && <Box>{children}</Box>}
    </Container>
  );
}

export default memo(SectionCollapse);
