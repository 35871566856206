import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function Search(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.6,22c-0.1,0-0.2,0-0.3-0.1l-3.8-4c-0.1-0.1-0.1-0.4,0-0.5c1.5-1.5,2.3-3.5,2.4-5.6C20,9.6,19.3,7.6,18,5.9c-1.3-1.6-3.2-2.7-5.3-3.1c-2.1-0.3-4.2,0.1-6,1.2C5,5.2,3.7,6.9,3.1,8.9S2.7,13.1,3.6,15c0.9,1.9,2.4,3.4,4.4,4.2c1.9,0.8,4.1,0.9,6.1,0.3c0.2-0.1,0.4,0,0.5,0.2c0.1,0.2,0,0.4-0.2,0.5c-2.2,0.7-4.5,0.6-6.6-0.3c-2.1-0.9-3.8-2.6-4.7-4.6c-1-2.1-1.1-4.4-0.5-6.6c0.6-2.2,2.1-4.1,4-5.3c1.9-1.2,4.2-1.7,6.5-1.3c2.3,0.4,4.3,1.6,5.7,3.3c1.4,1.8,2.2,4,2.1,6.3c-0.1,2.2-0.9,4.2-2.4,5.8l3.6,3.8c0.1,0.2,0.1,0.4,0,0.5C21.8,22,21.7,22,21.6,22z" />
    </SvgIcon>
  );
}
