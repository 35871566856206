import { Grid, styled } from '@mui/material';
import React, { memo } from 'react';
import { ChildrenTypography, TitleTypography } from '../MuiStyleComponent/TestComponent';

interface Props {
  title: string;
  children: string | React.ReactNode;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  childrenStyle?: React.CSSProperties;
}

const Container = styled(Grid)(({ theme }) => ({
  flexBasis: '33%',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1, 0),
}));

const Content = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

function ColFieldInfo({ title, children, style, titleStyle, childrenStyle }: Props) {
  return (
    <Container container alignItems="center" style={style}>
      <Content item xs={true}>
        <TitleTypography variant="h6" style={titleStyle}>
          {title}
        </TitleTypography>
        {typeof children === 'string' || typeof children === 'number' ? (
          <ChildrenTypography style={childrenStyle}>{children}</ChildrenTypography>
        ) : (
          children
        )}
      </Content>
    </Container>
  );
}

export default memo(ColFieldInfo);
