import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function AddCircle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12,21c-5,0-9-4-9-9c0-5,4-9,9-9s9,4,9,9C21,17,17,21,12,21z M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8c4.4,0,8-3.6,8-8S16.5,4,12,4z" />
      <path d="M12.5,7.5C12.5,7.2,12.3,7,12,7s-0.5,0.2-0.5,0.5v4h-4C7.3,11.5,7,11.7,7,12s0.2,0.5,0.5,0.5h4v4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-4h4c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-4V7.5z" />
    </SvgIcon>
  );
}
