import YatungPage from '@Src/_basic/components/YatungPage';
import { Stack } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import { GroupManagementFormRequire, UsersData } from '@Src/_basic/object/AccountRightType';
import { TransferOrderGroupApi } from '@Src/_basic/protocol/accountRights/transferOrderGroup/TransferOrderGroupApi';
import { UsersApi } from '@Src/_basic/protocol/accountRights/users/UsersApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useLocation, useNavigate } from 'react-router';
import EditAccountField from '../../../_components/EditAccountField';
import EditAccountLabel from '../../../_components/EditAccountLabel';
import FinishCancelButton from '../../../_components/FinishCancelButton';
import { FactoriesOptions, Options } from '../../../_components/FormSelect';
import SearchTable from './SearchTable';

export const editFormSchema = Yup.object().shape({
  area: Yup.number().min(0).positive('必填'),
  factory: Yup.number().min(0).positive('必填'),
  groupName: Yup.string().required('必填'),
  groupCode: Yup.string().required('必填'),
});

export default function TransferOrderGroupManagementUpdateCard() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { row } = state;
  const { userAreaOptionsData } = useAuth();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [searchTableOpen, setSearchTableOpen] = useState(false);
  const [memberData, setMemberData] = useState<Array<UsersData>>([]);
  const [factoryId, setFactoryId] = useState<number>(row.factoryId);
  const [isOriginalUsers, setIsOriginalUsers] = useState<boolean>(false);

  const initialForm: GroupManagementFormRequire = {
    area: userAreaOptionsData.find((item: FactoriesOptions) =>
      item.factories.find((_item: Options) => _item.value === row.factoryId),
    )?.value,
    factoryId: row.factoryId,
    groupName: row.groupName,
    groupCode: row.groupCode,
  };

  const handleSubmit = (values: GroupManagementFormRequire) => {
    TransferOrderGroupApi.putUpdateTransferOrderGroup(
      {
        ...values,
        id: row.id,
        userIds: memberData.filter((item) => item.added === true).map((item) => item.id),
      },
      () => {
        setIsShowAlert(true);
        setAlertText(i18T('GLOBAL.SUCCESS', { text: i18T('GLOBAL.UPDATE') }));
      },
      (error) => {
        setIsShowAlert(true);
        setAlertText(error.message);
      },
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!isOriginalUsers) {
      const originalUsers = row.users.map((item: UsersData) => ({ id: item.id, auditor: item.auditor }));
      UsersApi.getUsersOnlyAIV(factoryId, (_data: Array<UsersData>) => {
        setMemberData(
          _data.map((item) => ({
            ...item,
            added: originalUsers.some((user: UsersData) => user.id === item.id),
            auditor: originalUsers.find((user: UsersData) => user.id === item.id)?.auditor,
          })),
        );
        setIsOriginalUsers(true);
      });
    } else {
      UsersApi.getUsersOnlyAIV(factoryId, (_data: Array<UsersData>) => {
        setMemberData(_data.map((item) => ({ ...item, added: false, auditor: false })));
      });
    }
  }, [factoryId]);

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.EDIT_TRANSFER_GROUP')}
      contentBgColor="#FFF"
      body={
        <>
          <Formik
            initialValues={initialForm}
            validationSchema={editFormSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formik: FormikProps<GroupManagementFormRequire>) => {
              useMemo(() => {
                if (isOriginalUsers && formik.values.factoryId !== factoryId) {
                  setFactoryId(formik.values.factoryId);
                }
              }, [formik.values.factoryId]);
              useMemo(() => {
                formik.values.factoryId = 0;
              }, [formik.values.area]);
              return (
                <Form>
                  <Stack display="flex" flexDirection="row">
                    <Stack width="45%" sx={{ p: 5 }}>
                      <Stack direction="row" alignItems="center" sx={{ mt: 5 }} spacing={2.7}>
                        <EditAccountLabel
                          label={`${i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.AREA')}:`}
                          isStar={true}
                        />
                        <EditAccountField target="area" options={userAreaOptionsData} />
                      </Stack>
                      <Stack direction="row" alignItems="center" sx={{ mt: 5 }} spacing={2.7}>
                        <EditAccountLabel
                          label={`${i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.FACTORY_INFO')}:`}
                          isStar={true}
                        />
                        <EditAccountField
                          target="factoryId"
                          options={
                            userAreaOptionsData.find((i: FactoriesOptions) => i.value === formik.values.area)?.factories
                          }
                        />
                      </Stack>
                      <Stack direction="row" alignItems="center" sx={{ mt: 5 }} spacing={3}>
                        <EditAccountLabel
                          label={`${i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.TRANSFER_NAME')}:`}
                          isStar={true}
                        />
                        <EditAccountField target="groupName" />
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ py: 5, borderBottom: 1, borderBottomStyle: 'dashed' }}
                        spacing={3}
                      >
                        <EditAccountLabel
                          label={`${i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.TRANSFER_CODE')}:`}
                          isStar={true}
                        />
                        <EditAccountField target="groupCode" />
                      </Stack>
                    </Stack>
                    <SearchTable
                      setSearchTableOpen={setSearchTableOpen}
                      searchTableOpen={searchTableOpen}
                      memberData={memberData}
                      setMemberData={setMemberData}
                    />
                  </Stack>
                  <Stack spacing={2} direction="row" justifyContent="flex-end" sx={{ mr: 15 }}>
                    <FinishCancelButton
                      onFinish={formik.handleSubmit}
                      onCancel={handleGoBack}
                      sx={{ py: 1.5, borderRadius: 0 }}
                    />
                  </Stack>
                </Form>
              );
            }}
          </Formik>
          {alertText === i18T('GLOBAL.SUCCESS', { text: i18T('GLOBAL.UPDATE') }) ? (
            <YatungConfirmAlert
              isShowAlert={isShowAlert}
              onClose={() => {
                setIsShowAlert(false);
                setTimeout(() => setAlertText(''), 300);
                navigate(`/UsersManagePage/TransferOrderGroupManagement`);
              }}
              alertText={alertText}
              buttonTitle={i18T('GLOBAL.BACK')}
            />
          ) : (
            <YatungConfirmAlert
              isShowAlert={isShowAlert}
              onClose={() => {
                setIsShowAlert(false);
                setTimeout(() => setAlertText(''), 300);
              }}
              alertText={alertText}
            />
          )}
        </>
      }
    />
  );
}
