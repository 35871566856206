import React from 'react';
import { Stack } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import EditIcon from '@Src/_basic/icons/Edit';
import { FactoryData } from '@Src/_basic/object/FactoryType';

export type ExtraData = {
  onGoToUpdatePage: (id: number) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: FactoryData;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;

  return (
    <Stack spacing={2} direction="row">
      <YatungButton
        text={formatExtraData.getButtonText('UPDATE')}
        variant={'contained'}
        type={'button'}
        size={'large'}
        startIcon={<EditIcon />}
        onClick={() => {
          formatExtraData.onGoToUpdatePage(row.id);
        }}
        color={'yellow'}
      />
    </Stack>
  );
}
