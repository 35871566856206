import YatungButton from '@Src/_basic/components/YatungButton';
import Cancel from '@Src/_basic/icons/CancelV2';
import Confirm from '@Src/_basic/icons/Confirm';
import EditIcon from '@Src/_basic/icons/Edit';
import { Box, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  editMode: boolean;
  onEditButtonClick?: () => void;
  onCreateButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onConfirmButtonClick?: () => void;
}

export default function ActionBar({
  editMode,
  onEditButtonClick,
  onCreateButtonClick,
  onConfirmButtonClick,
  onCancelButtonClick,
}: Props) {
  const { t: i18T } = useTranslation();

  const handleEditButtonClick = () => {
    onEditButtonClick?.();
  };

  const handleCreateButtonClick = () => {
    onCreateButtonClick?.();
  };

  const handleConfirmButtonClick = () => {
    onConfirmButtonClick?.();
  };

  const handleCancelcelButtonClick = () => {
    onCancelButtonClick?.();
  };

  return (
    <>
      {!editMode ? (
        <>
          <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}>
            <Grid container direction="row" justifyContent="flex-end">
              <YatungButton
                text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.EDIT`)}
                color="blue"
                startIcon={<EditIcon />}
                onClick={handleEditButtonClick}
              />
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}>
            <Grid container direction="row" justifyContent="flex-end">
              <YatungButton
                text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.CREATE`)}
                color="blue"
                startIcon={<EditIcon />}
                onClick={handleCreateButtonClick}
              />
            </Grid>
          </Box>
          <Box sx={{ position: 'absolute', bottom: '16px', right: '16px' }}>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <YatungButton
                  text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.CANCEL`)}
                  color="red"
                  startIcon={<Cancel />}
                  onClick={() => {
                    handleCancelcelButtonClick();
                  }}
                />
                <YatungButton
                  text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.SUBMIT`)}
                  color="green"
                  startIcon={<Confirm />}
                  onClick={() => {
                    handleConfirmButtonClick();
                  }}
                />
              </Box>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
