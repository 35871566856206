import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Transfer (props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.9,10.1l-2.1-5.9c-0.1-0.4-0.5-0.7-0.9-0.7c-0.4,0-0.8,0.2-0.9,0.6l-0.3,0.7c0,0,0,0,0,0h0C12.3,1.1,7.6,3.7,3.7,6.6C3.3,6.8,3.1,7.4,3.4,7.8c0.2,0.5,0.7,0.7,1.2,0.5c6.5-2,7.9-1.6,10.4,1.1l-0.6,1.7c-0.1,0.3-0.1,0.7,0.2,1c0.2,0.2,0.5,0.4,0.8,0.4c0.1,0,0.1,0,0.2,0l5.7-1.1c0.3-0.1,0.5-0.2,0.7-0.5C21.9,10.7,22,10.4,21.9,10.1z"/>
      <path d="M20.2,15.8c-0.3-0.4-0.8-0.6-1.2-0.4c-6.2,2.4-7.7,2.1-10.3-0.4l0.5-1.7c0.1-0.4,0-0.7-0.3-1c-0.3-0.3-0.6-0.4-1-0.3l-5.5,1.4c-0.3,0.1-0.5,0.3-0.6,0.5c-0.1,0.3-0.1,0.6,0,0.9l2.5,5.8c0.2,0.4,0.5,0.6,0.9,0.6c0.4,0,0.7-0.3,0.9-0.6l0.3-0.8c0,0,0,0,0,0c1.7,1.1,3.4,1.5,4.9,1.5c3.3,0,6.2-2.1,8.7-4.2C20.4,16.8,20.5,16.3,20.2,15.8z"/>
    </SvgIcon>
  );
}
