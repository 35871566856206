import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { AccountManagementData } from '@Src/_basic/object/AccountManagementType';
import { OrganiztionsData } from '@Src/_basic/object/AccountRightType';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import { useApi } from '@Src/redux/api/apiAction';
import { AddCircle, Cancel } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import StateColumnFormatter from './columnFormatter/StateColumnFormatter';

interface Props {
  accountData: Array<AccountManagementData>;
}

export default function AccountManagementTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { accountData } = props;
  const navigate = useNavigate();

  const [newAccountData, setNewAccountData] = useState<Array<AccountManagementData>>([]);

  useEffect(() => {
    setNewAccountData(accountData.map((item) => ({ ...item, factoryList: false, organizeList: false })));
  }, [accountData]);

  const handleGoToUpdatePage = async (id: number) => {
    navigate(`/UsersManagePage/AccountManagement/AccountManagementUpdate/${id}`, { state: id });
  };

  const columns: Array<Column> = [
    {
      text: i18T('GLOBAL.NO'),
      dataField: 'index',
      width: '5%',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.ACCOUNT'),
      dataField: 'account',
      formatter: ({ row }) => {
        return (
          //判斷是否為AD帳號
          <Stack display="flex" flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
            <span>{row.account}</span>
            {row.isAdAccount && (
              <span
                style={{
                  background: '#7C878E',
                  color: '#ffffff',
                  paddingInline: '4px',
                  marginLeft: '2px',
                  fontFamily: 'Microsoft JhengHei',
                }}
              >
                AD
              </span>
            )}
          </Stack>
        );
      },
      width: '12%',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.NAME'),
      dataField: 'accountName',
      width: '10%',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.EMAIL'),
      dataField: 'email',
      width: '20%',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.PHONE'),
      dataField: 'phone',
      width: '10%',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.FACTORY'),
      dataField: 'factory',
      width: '8%',
      formatter: ({ row }) => {
        const list = row.factories.map((item: FactoryData) => item.factoryName);
        if (list) {
          return (
            <>
              {list.length <= 2 ? (
                <Stack display="flex" flexDirection="column" alignItems="center">
                  {list.splice(0, list.length).map((item: string, index: number) => (
                    <span key={index} style={{ letterSpacing: 2 }}>
                      {item}
                    </span>
                  ))}
                </Stack>
              ) : row.factoryList ? (
                <Stack display="flex" flexDirection="column" alignItems="center">
                  {list.splice(0, list.length).map((item: string, index: number) => (
                    <span key={index} style={{ letterSpacing: 2 }}>
                      {item}
                    </span>
                  ))}
                  <Cancel
                    onClick={() =>
                      setNewAccountData((prev: Array<AccountManagementData>) =>
                        prev.map((item: AccountManagementData) =>
                          item.id === row.id ? { ...item, factoryList: false } : item,
                        ),
                      )
                    }
                    sx={{ ':hover': { cursor: 'pointer' }, fontSize: 20 }}
                  />
                </Stack>
              ) : (
                <Stack display="flex" flexDirection="column" alignItems="center">
                  {list.splice(0, 2).map((item: string, index: number) => (
                    <span key={index} style={{ letterSpacing: 2 }}>
                      {item}
                    </span>
                  ))}
                  <AddCircle
                    onClick={() =>
                      setNewAccountData((prev: Array<AccountManagementData>) =>
                        prev.map((item: AccountManagementData) =>
                          item.id === row.id ? { ...item, factoryList: true } : item,
                        ),
                      )
                    }
                    sx={{ ':hover': { cursor: 'pointer' }, fontSize: 20 }}
                  />
                </Stack>
              )}
            </>
          );
        } else return <></>;
      },
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.ORGANIZE'),
      dataField: 'organize',
      width: '8%',
      formatter: ({ row }) => {
        const list = row.organizations.map((item: OrganiztionsData) => item.organiztionName);
        if (list) {
          return (
            <>
              {list.length <= 2 ? (
                <Stack display="flex" flexDirection="column" alignItems="center">
                  {list.splice(0, list.length).map((item: string, index: number) => (
                    <span key={index} style={{ letterSpacing: 2 }}>
                      {item}
                    </span>
                  ))}
                </Stack>
              ) : row.organizeList ? (
                <Stack display="flex" flexDirection="column" alignItems="center">
                  {list.splice(0, list.length).map((item: string, index: number) => (
                    <span key={index} style={{ letterSpacing: 2 }}>
                      {item}
                    </span>
                  ))}
                  <Cancel
                    onClick={() =>
                      setNewAccountData((prev: Array<AccountManagementData>) =>
                        prev.map((item: AccountManagementData) =>
                          item.id === row.id ? { ...item, organizeList: false } : item,
                        ),
                      )
                    }
                    sx={{ ':hover': { cursor: 'pointer' }, fontSize: 20 }}
                  />
                </Stack>
              ) : (
                <Stack display="flex" flexDirection="column" alignItems="center">
                  {list.splice(0, 2).map((item: string, index: number) => (
                    <span key={index} style={{ letterSpacing: 2 }}>
                      {item}
                    </span>
                  ))}
                  <AddCircle
                    onClick={() =>
                      setNewAccountData((prev: Array<AccountManagementData>) =>
                        prev.map((item: AccountManagementData) =>
                          item.id === row.id ? { ...item, organizeList: true } : item,
                        ),
                      )
                    }
                    sx={{ ':hover': { cursor: 'pointer' }, fontSize: 20 }}
                  />
                </Stack>
              )}
            </>
          );
        } else return <></>;
      },
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.CREATEDATE'),
      dataField: 'createDt',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.UPDATEDATE'),
      dataField: 'updateDt',
    },
    {
      text: i18T('FACTORYMANAGEMENT.STATE'),
      dataField: 'state',
      width: '5%',
      formatter: StateColumnFormatter,
      formatExtraData: {
        enableText: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.ENABLE'),
        disabledText: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.DISABLED'),
      },
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onGoToUpdatePage: handleGoToUpdatePage,
        getButtonText: () => {
          return i18T('GLOBAL.UPDATE');
        },
      },
    },
  ];
  return <YatungTable loading={actionLoading} columns={columns} data={newAccountData} />;
}
