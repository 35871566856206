import { Protocol } from '../Protocol';

export class PrintProductionProtocol {
  public static readonly EXPORT_PROPS: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/export/prop`;

  public static readonly AMS_EXPT_REPORT: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/export/AMS-expt-report`;
  public static readonly CONC_EXPT_REPORT: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/export/conc-expt-report`;
  public static readonly BOM: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/export/bom`;
  public static readonly CARBON_EMISSION_INFO: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/export/carbonEmissionInfo`;
}
