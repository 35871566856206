import { Response } from '@Src/_basic/object/ApiType';
import {
  CreateMonthlyParams,
  CreateMonthlyReportViewRequire,
  MonthlyReportAggAbsorption,
  MonthlyReportCoarseAggAnalysis,
  MonthlyReportConcreteAntiStress,
  MonthlyReportExptItem,
  MonthlyReportFineAggAms,
  MonthlyReportFineAggAnalysis,
  MonthlyReportFineAggChloride,
  MonthlyReportFineAggRValue,
  MonthlyReportFlyAshLValue,
  MonthlyReportFlyAshLossOfIgnition,
  ReportViewResponse,
  ResultAndIsAbnormal,
  ViewMonthlyParams,
} from '@Src/_basic/object/MonthlyReportType';
import { Api } from '../Api';
import { MonthlyReposrtProtocol } from './MonthlyReposrtProtocol';

export class MonthlyReportApi {
  // 細粒料篩分析
  public static getFineAggAnalysis(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportFineAggAnalysis>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.FINE_AGG_ANALYSIS,
      config,
      (_data) => {
        const data = _data
          .filter((item: any) => item.verifyPassedResults)
          .map((item: any) => ({
            ...item,
            screening: {
              ...item.screening,
              isAbnormal: !(item.verifyPassedResults['9.5mm'] && item.verifyPassedResults['9.5mm']),
            },
            screening4: {
              ...item.screening4,
              isAbnormal: !(item.verifyPassedResults['4.75mm'] && item.verifyPassedResults['4.75mm']),
            },
            screening8: {
              ...item.screening8,
              isAbnormal: !(item.verifyPassedResults['2.36mm'] && item.verifyPassedResults['2.36mm']),
            },
            screening16: {
              ...item.screening16,
              isAbnormal: !(item.verifyPassedResults['1.18mm'] && item.verifyPassedResults['1.18mm']),
            },
            screening30: {
              ...item.screening30,
              isAbnormal: !(item.verifyPassedResults['0.600mm'] && item.verifyPassedResults['0.600mm']),
            },
            screening50: {
              ...item.screening50,
              isAbnormal: !(item.verifyPassedResults['0.300mm'] && item.verifyPassedResults['0.300mm']),
            },
            screening100: {
              ...item.screening100,
              isAbnormal: !(item.verifyPassedResults['0.150mm'] && item.verifyPassedResults['0.150mm']),
            },
            screening200: {
              ...item.screening200,
              isAbnormal: !(item.verifyPassedResults['0.075mm'] && item.verifyPassedResults['0.075mm']),
            },
          }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 細粒料AMS檢測
  public static getFineAggAMS(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportFineAggAms>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.FINE_AGG_AMS,
      config,
      (_data) => {
        const data = _data
          .filter((item: any) => item.verifyPassedResults)
          .map((item: any) => ({
            ...item,
            phenolphthalein: {
              ...item.phenolphthalein,
              isAbnormal: !(item.verifyPassedResults?.colorChanged && item.verifyPassedResults?.colorChanged),
            },
            ph: {
              ...item.ph,
              isAbnormal: !(item.verifyPassedResults?.afterRinsePhScale && item.verifyPassedResults?.afterRinsePhScale),
            },
            magnetic: {
              ...item.magnetic,
              isAbnormal: !(
                item.verifyPassedResults?.magAttractionRatio && item.verifyPassedResults?.magAttractionRatio
              ),
            },
          }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 細粒料比重吸水率
  public static getFineAggAbsorption(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportAggAbsorption<ResultAndIsAbnormal<number>>>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.FINE_AGG_ABSORPTION,
      config,
      (_data) => {
        const data = _data
          .filter((item: any) => item.verifyPassedResults)
          .map((item: any) => ({
            ...item,
            proportion: {
              result: item.proportion,
              isAbnormal: !(
                item.verifyPassedResults?.averageSpecificWeight && item.verifyPassedResults?.averageSpecificWeight
              ),
            },
            absorption: {
              result: item.absorption,
              isAbnormal: !(
                item.verifyPassedResults?.averageWaterAbsorption && item.verifyPassedResults?.averageWaterAbsorption
              ),
            },
          }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 細粒料氯離子檢測
  public static getFineAggChloride(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportFineAggChloride<ResultAndIsAbnormal<number>>>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.FINE_AGG_CHLORIDE,
      config,
      (_data) => {
        const data = _data
          .filter((item: any) => item.verifyPassedResults)
          .map((item: any) => ({
            ...item,
            chlorideIons: {
              result: item.chlorideIons,
              isAbnormal: !(
                item.verifyPassedResults?.chlorideIonContent && item.verifyPassedResults?.chlorideIonContent
              ),
            },
          }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 粗粒料篩分析
  public static getCoarseAggAnalysis(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportCoarseAggAnalysis>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.COARSE_AGG_ANALYSIS,
      config,
      (_data) => {
        const data = _data
          .filter((item: any) => item.verifyPassedResults.type1)
          .map((item: any) => ({
            ...item,
            screening1_5: {
              ...item.screening1_5,
              isAbnormal: !(item.verifyPassedResults.type1['37.5mm'] && item.verifyPassedResults.type2['37.5mm']),
              type1Abnormal: !(item.verifyPassedResults.type1['37.5mm'] && item.verifyPassedResults.type1['37.5mm']),
              type2Abnormal: !(item.verifyPassedResults.type2['37.5mm'] && item.verifyPassedResults.type2['37.5mm']),
            },
            screening1: {
              ...item.screening1,
              isAbnormal: !(item.verifyPassedResults.type1['25.0mm'] && item.verifyPassedResults.type2['25.0mm']),
              type1Abnormal: !(item.verifyPassedResults.type1['25.0mm'] && item.verifyPassedResults.type1['25.0mm']),
              type2Abnormal: !(item.verifyPassedResults.type2['25.0mm'] && item.verifyPassedResults.type2['25.0mm']),
            },
            screening0_5: {
              ...item.screening0_5,
              isAbnormal: !(item.verifyPassedResults.type1['12.5mm'] && item.verifyPassedResults.type2['12.5mm']),
              type1Abnormal: !(item.verifyPassedResults.type1['12.5mm'] && item.verifyPassedResults.type1['12.5mm']),
              type2Abnormal: !(item.verifyPassedResults.type2['12.5mm'] && item.verifyPassedResults.type2['12.5mm']),
            },
            screening4: {
              ...item.screening4,
              isAbnormal: !(item.verifyPassedResults.type1['4.75mm'] && item.verifyPassedResults.type2['4.75mm']),
              type1Abnormal: !(item.verifyPassedResults.type1['4.75mm'] && item.verifyPassedResults.type1['4.75mm']),
              type2Abnormal: !(item.verifyPassedResults.type2['4.75mm'] && item.verifyPassedResults.type2['4.75mm']),
            },
            screening8: {
              ...item.screening8,
              isAbnormal: !(item.verifyPassedResults.type1['2.36mm'] && item.verifyPassedResults.type2['2.36mm']),
              type1Abnormal: !(item.verifyPassedResults.type1['2.36mm'] && item.verifyPassedResults.type1['2.36mm']),
              type2Abnormal: !(item.verifyPassedResults.type2['2.36mm'] && item.verifyPassedResults.type2['2.36mm']),
            },
          }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 粗粒料比重吸水率
  public static getCoarseAggAbsorption(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportAggAbsorption<ResultAndIsAbnormal<number>>>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.COARSE_AGG_ABSORPTION,
      config,
      (_data) => {
        const data = _data
          .filter((item: any) => item.verifyPassedResults)
          .map((item: any) => ({
            ...item,
            proportion: {
              result: item.proportion,
              isAbnormal: !(
                item.verifyPassedResults?.averageSpecificWeight && item.verifyPassedResults?.averageSpecificWeight
              ),
            },
            absorption: {
              result: item.absorption,
              isAbnormal: !(
                item.verifyPassedResults?.averageWaterAbsorption && item.verifyPassedResults?.averageWaterAbsorption
              ),
            },
          }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 飛灰L值
  public static getFlyAshLValue(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportFlyAshLValue<ResultAndIsAbnormal<number>>>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.FLY_ASH_L_VALUE,
      config,
      (_data) => {
        const data = _data
          .filter((item: any) => item.verifyPassedResults)
          .map((item: any) => ({
            ...item,
            value: {
              result: item.value,
              isAbnormal: !(item.verifyPassedResults.lValue && item.verifyPassedResults.lValue),
            },
          }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 飛灰燒失量試驗
  public static getFlyAshLossOfIgnition(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportFlyAshLossOfIgnition>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.FLY_ASH_LOSS_OF_IGNITION,
      config,
      (_data) => {
        const data = _data
          .filter((item: any) => item.verifyPassedResults)
          .map((item: any) => ({
            ...item,
            weight: {
              ...item.weight,
              isAbnormal: !(item.verifyPassedResults.lossOnIgnition && item.verifyPassedResults.lossOnIgnition),
            },
          }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 混凝土抗壓試驗
  public static getConcreteAntiStress(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportConcreteAntiStress>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MonthlyReposrtProtocol.CONCRETE_ANTI_STRESS, config, onSuccess, onFailed, onFinally);
  }

  // 細粒料R值試驗
  public static getFineAggRValue(
    params: CreateMonthlyParams,
    onSuccess?: (data: Array<MonthlyReportFineAggRValue<ResultAndIsAbnormal<number>>>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      MonthlyReposrtProtocol.FINE_AGG_R_VALUE,
      config,
      (_data) => {
        const data = _data.map((item: any) => ({
          ...item,
          value: {
            result: item.value,
            isAbnormal: false,
          },
        }));
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  }

  // 創建可檢視月報資料
  public static createMonthlyReportView(
    require: CreateMonthlyReportViewRequire,
    onSuccess?: (data: Array<MonthlyReportFineAggRValue<ResultAndIsAbnormal<number>>>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(MonthlyReposrtProtocol.REPORT_VIEW, require, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得所有試驗項目
  public static getAllMonthlyExptItem(
    onSuccess?: (data: Array<MonthlyReportExptItem>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(MonthlyReposrtProtocol.MONTHLY_REPORT, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得可檢視月報資料
  public static getAllMonthlyReportView(
    params: ViewMonthlyParams,
    onSuccess?: (data: Array<ReportViewResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MonthlyReposrtProtocol.MONTHLY_REPORT_VIEW, config, onSuccess, onFailed, onFinally);
  }
}
