import { Response } from '@Src/_basic/object/ApiType';
import {
  TypeTransactionAddRequest,
  TypeTransactionData,
  TypeTransactionEditRequest,
} from '@Src/_basic/object/TypeTransactionType';
import { Api } from '../Api';
import { TypeTransactionProtocol } from './TypeTransactionProtocol';

export class TypeTransactionApi {
  // 取得所有交易類型設定
  public static getTypeTransaction(
    onSuccess?: (data: Array<TypeTransactionData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(TypeTransactionProtocol.TYPE_TRANSACTION, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改 && 新增交易類型設定
  public static putTypeTransaction(
    request: TypeTransactionEditRequest,
    onSuccess?: (data: TypeTransactionData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(TypeTransactionProtocol.TYPE_TRANSACTION_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改 && 新增交易類型設定
  public static postTypeTransaction(
    request: TypeTransactionAddRequest,
    onSuccess?: (data: TypeTransactionData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(TypeTransactionProtocol.TYPE_TRANSACTION_URL, request, undefined, onSuccess, onFailed, onFinally);
  }
}
