import { Protocol } from '../Protocol';

export class ProductionHistoryProtocol {
  public static readonly PROJECT_BY_SHIP_ID: string = `${Protocol.YATUNG_API_ROOT}/project`;
  public static readonly PAGEABLE_PROJECTS_BY_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/project/p/all`;

  public static readonly PROJECT_INFO_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/projectInfo`;
  public static readonly PROJECT_INFO_PROFILE_BY_PK: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/profile`;
  public static readonly PROJECT_INFO_BY_ORDER_ID: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/order`;

  public static readonly PROJECT_INFO_BASIC_INFO_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/basic`;

  public static readonly SIGNATURE: string = `${Protocol.YATUNG_API_ROOT}/signature`;
  public static readonly SIGNATURE_CREATE_APPLY: string = `${Protocol.YATUNG_API_ROOT}/signature/create/apply`;

  public static readonly PROJECT_INFO_PROPORTION: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/prop`;
  public static readonly PROJECT_INFO_MULTIPLE_PROPORTION: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/prop/multiple`;
  public static readonly PROJECT_INFO_REPLACE_PROPORTION: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/prop/replace`;
  public static readonly PROJECT_INFO_PROPORTION_BY_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/prop/all`;
  public static readonly PAGEABLE_PROJECT_INFO_PROPORTION_BY_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/prop/p/all`;

  public static readonly UPLOAD_REPORTS: string = `${Protocol.YATUNG_API_ROOT}/file/upload/projectInfo`;
  public static readonly DELETE_REPORTS: string = `${Protocol.YATUNG_API_ROOT}/file`;

  public static readonly CONCRETE_HEADER: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/conc/ttl`;
  public static readonly CONCRETE_EXPTS: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/conc/expts`;
  public static readonly PAGEABLE_CONCRETE_EXPTS: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/conc/p/expts`;
  public static readonly CONCRETE_FILES: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/conc/files`;

  public static readonly EXPORT_PROPS: string = `${Protocol.YATUNG_API_ROOT}/projectInfo/export/prop`;
}
