import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function PieChart(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <path
        d="M14.0002 14.5002H27.4911C27.3987 16.9952 26.616 19.4189 25.2251 21.5002L25.2251 21.5002C23.7417 23.7203 21.6332 25.4515 19.1667 26.4727C16.6996 27.494 13.985 27.7615 11.3663 27.2406C8.74745 26.7197 6.34193 25.4347 4.45416 23.5466L4.45413 23.5466C2.56595 21.6584 1.28037 19.2532 0.75945 16.6344C0.238525 14.0156 0.505747 11.3004 1.52764 8.83345C2.54955 6.36646 4.27983 4.25837 6.49983 2.77537C8.58113 1.38502 11.0052 0.601764 13.5002 0.509265V14.0002V14.5002H14.0002ZM20.4312 1.37167L20.4313 1.37169C21.8207 1.94678 23.0831 2.79026 24.1465 3.85361C25.2098 4.91693 26.0533 6.17966 26.6288 7.56894C27.1404 8.80405 27.4312 10.118 27.4895 11.4514H16.549V0.510929C17.8821 0.569237 19.196 0.860325 20.4312 1.37167Z"
        fill="#aaa"
      />
    </SvgIcon>
  );
}
