import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest } from '@Src/_basic/object/TestType';
import { HighTempCooking, PutHighTempCookingRequest } from '@Src/_basic/object/test/sandRock/HighTempCookingType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { Stack } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  testDetail: HighTempCooking;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

// 高溫蒸煮試驗
function ExportHighTempCookingTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const [edit, setEdit] = useState<boolean>(false);
  const [burst, setBurst] = useState<boolean>(testDetail?.passed);

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstHeader = useMemo(
    () => ({
      title: i18T(`TEST.AUTOCLAVE_TEST.EXPANSION_RUPTURE`),
    }),
    [i18T],
  );

  const firstInfo = useMemo(
    () => [
      {
        title: !testDetail?.passed ? '無膨脹爆裂' : '有膨脹爆裂',
        titleStyle: testDetail?.verifyPassedResults?.EXPANSION_RUPTURE
          ? TestVerifyResultStyles.normal
          : TestVerifyResultStyles.abnormal,
      },
    ],
    [testDetail?.passed, testDetail?.verifyPassedResults],
  );

  const secondHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const secondInfo = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
            titles: verifyResultText,
            titlesStyle: [TestVerifyResultStyles.normal],
          }
        : {
            titles: verifyResultText,
            titlesStyle: [
              isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
              isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            ],
          },
    ],
    [isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  const editView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <ColFieldInfo
            titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
            title={i18T('TEST.AUTOCLAVE_TEST.EXPANSION_RUPTURE')}
          >
            <YatungRadioButton
              groupName="isBurst"
              value={burst}
              onChange={(e) => {
                switch (e.target.value) {
                  case 'true':
                    setBurst(true);
                    break;
                  case 'false':
                    setBurst(false);
                    break;
                  default:
                    break;
                }
              }}
              controlData={[
                { label: '爆裂', value: true },
                { label: '無爆裂', value: false },
              ]}
              labelTextStyle={{
                fontSize: '20px',
                fontWeight: '600',
                fontFamily: 'Microsoft JhengHei',
                color: '#002F8C',
              }}
            />
          </ColFieldInfo>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstInfo} />;
      default:
        break;
    }
  }, [edit, firstHeader, firstInfo, i18T, burst]);

  const submitHandler = useCallback(() => {
    TestApi.putTest<PutHighTempCookingRequest>(
      {
        exptItemCode: testDetail?.exptItemCode,
        fields: {
          ...testDetail,
          passed: burst,
        },
      },
      (_data) => {
        afterUpdateTest(_data);
        setEdit(false);
      },
    );
  }, [burst, testDetail, afterUpdateTest]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={submitHandler} canEdit={canEdit} />

        {editView}
      </TestCardContainer>
      <TestCardContainer>
        <TestTable headerData={secondHeader} rows={secondInfo} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportHighTempCookingTable);
