import { AuthSysBaseResponse, Response } from '@Src/_basic/object/ApiType';
import { Api } from '../../../Api';
import { MenuProtocol } from './MenuProtocol';
import { ApiGroup, ApiGroupCreateRequest, ApiGroupUpdateRequest, Groups, RightsData, RightsRequest } from '@Src/_basic/object/AccountRightType';

export class MenuApi {
  // 取得API群組清單
  public static postGetRightsMenus(
    request: RightsRequest,
    onSuccess?: (data: RightsData<Groups>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(MenuProtocol.POST_GET_RIGHTS_MENUS, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得API群組
  public static postGetRightsMenuById(
    id: string,
    onSuccess?: (data: ApiGroup) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id };
    Api.post(MenuProtocol.POST_GET_RIGHTS_MENU_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增API群組
  public static postCreateRightsMenu(
    request: ApiGroupCreateRequest,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(MenuProtocol.POST_CREATE_RIGHTS_MENU, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 刪除API群組
  public static postDeleteRightsMenu(
    id: string,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id };
    Api.post(MenuProtocol.POST_DELETE_RIGHTS_MENU, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改API群組
  public static postUpdateRightsMenu(
    request: ApiGroupUpdateRequest,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(MenuProtocol.POST_UPDATE_RIGHTS_MENU, request, undefined, onSuccess, onFailed, onFinally);
  }

}