import React, { useEffect, useState } from 'react';
import { Grid, Typography, SelectChangeEvent } from '@mui/material';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import { useTranslation } from 'react-i18next';
import { Options } from '@Src/_basic/components/YatungFormSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungButton from '@Src/_basic/components/YatungButton';
import { StationsData, StationsDataById } from '@Src/_basic/object/SiteType';
import YatungDivider from '@Src/_basic/components/YatungDivider';

interface Props {
  index: number;
  factoryBranchCode: string;
  value: StationsData;
  setCreateWbStation: (e: StationsData[]) => void;
  createWbStation: StationsData[];
  updateWbStation: StationsDataById[];
  stationsOption: Options[];
}
export default function CreateWbStationForm(props: Props) {
  const { index, value, setCreateWbStation, createWbStation, factoryBranchCode, stationsOption, updateWbStation } = props;
  const { t: i18T } = useTranslation();
  const [stationTypeName, setStationTypeName] = useState<string | undefined>('');

  const getTypeId = () => {
    const typeId = stationsOption.filter((data: Options) => data.value === value.stationTypeId);
    const code = typeId.map((data: Options) => {
      return data.code;
    });
    setStationTypeName(code[0]);
  };
  useEffect(() => {
    getTypeId();
  }, []);
  const handleInput = (e: SelectChangeEvent) => {
    const updatedArray = createWbStation.map((datas: StationsDataById, i: number) => {
      if (datas.id === value.id) {
        return { ...datas, stationName: e.target.value };
      }
      return datas;
    });
    setCreateWbStation(updatedArray);
  };
  const handlePwInput = (e: SelectChangeEvent) => {
    const updatedArray = createWbStation.map((datas: StationsDataById, i: number) => {
      if (datas.id === value.id) {
        return { ...datas, password: e.target.value };
      }
      return datas;
    });
    setCreateWbStation(updatedArray);
  };
  const getRemoveData = () => {
    const filteredWbStation = createWbStation.filter((v: StationsData) => v.id !== value.id);
    setCreateWbStation(filteredWbStation);
  };
  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.SITES')} />
        </Grid>
        <Grid container item xs={10}>
          <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>
            {i18T('SITEMANAGEMENT.WBSTATION')}
          </Typography>
        </Grid>
        <Grid container item xs={1} justifyContent={'flex-start'}>
          <YatungButton
            color="red"
            text={'刪除'}
            onClick={() => {
              getRemoveData();
            }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.STATION_NAME')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungInput sx={{ width: '930px' }} onBlur={handleInput} defaultValue={value.stationName} type="text" />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.SITE_TYPE')} />
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{stationTypeName}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.SITE_NUMBER')} />
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{updateWbStation.length + index + 1}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.ACCOUNT')} />
        </Grid>
        <Grid container item xs={11}>
          <Typography
            sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}
          >{`${factoryBranchCode}_${stationTypeName}_${updateWbStation.length + index + 1}`}</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ my: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.PASSWORD')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungInput sx={{ width: '930px' }} onBlur={handlePwInput} defaultValue={value.password} type="password" />
        </Grid>
      </Grid>
      <YatungDivider />
    </>
  );
}
