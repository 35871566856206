import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungImg from '@Src/_basic/components/YatungImg';
import { useTranslation } from 'react-i18next';
import { OrganizeUpdateRequire } from '@Src/_basic/object/OrganizeType';
import { OrganizeRequire } from '@Src/_basic/protocol/organizeManagement/organizeApi';
import OrganizeManagementUpdateForm from './OrganizeManagementUpdateForm';
import { UpdateOrganizationsRequset } from '@Src/_basic/object/AccountRightType';

const editImage = '/media/images/edit.png';

interface Props {
  targetId: number;
  targetName: string;
  showUpdateCard: boolean;
  setShowUpdateCard: (e: boolean) => void;
  setAlertIsOpen: (e: boolean) => void;
  setSetAlertTitle: (e: string) => void;
}

export default function OrganizeManagementUpdateCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { targetId, targetName, showUpdateCard, setShowUpdateCard, setAlertIsOpen, setSetAlertTitle } = props;

  const handleGoBackButtonClick = () => {
    setShowUpdateCard(false);
  };
  const initialOrganize: UpdateOrganizationsRequset = {
    id: targetId,
    organizationName: targetName,
  };

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={showUpdateCard}
        handleClose={handleGoBackButtonClick}
        icon={
          <YatungImg
            img={editImage}
            sx={{
              width: 28,
              height: 28,
              zIndex: 999,
              alignSelf: 'center',
              display: 'flex',
              textAlign: 'center',
              mr: 1,
            }}
          />
        }
        title={i18T('GLOBAL.UPDATE')}
        body={
          <OrganizeManagementUpdateForm
            initialValues={initialOrganize}
            setAlertIsOpen={setAlertIsOpen}
            setSetAlertTitle={setSetAlertTitle}
            onGoBackButtonClick={handleGoBackButtonClick}
          />
        }
      />
    </Grid>
  );
}
