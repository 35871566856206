import { YatungComboBox } from '@Src/_basic/components/YatungComboBox';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import { getFirstDateAndLastDateByMonth, getMonthBetweenDates } from '@Src/_basic/helpers/DateHelpers';
import { ProportionData, RealPropData, SearchConcreteHeaderParams } from '@Src/_basic/object/ProductionHistoryType';
import { useApi } from '@Src/redux/api/apiAction';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Stack, Typography, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import StatisticsCard from '../StatisticsCard';
import InformationCard from './components/InformationCard';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

const initFilterValue = {
  startTime: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
  endTime: new Date().getTime(),
  proportionIds: [],
};

const initQueryValue = {
  startTime: initFilterValue.startTime,
  endTime: initFilterValue.endTime,
  realPropNames: [],
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'updateValue':
      return { ...state, ...action.payload };
    default:
      return { state };
  }
}

const divider = <Box sx={{ border: '1px dashed #7C878E' }} />;
export default function ConcreteQuality() {
  const { actionLoading } = useApi();
  const { t: i18T } = useTranslation();
  const { projectInfo, concreteHeader, getConcreteHeaderBySearch, clearConcreteHeader } = useProductionHistory();
  const [filterValue, filterValueDispatch] = useReducer(reducer, initFilterValue);
  const [query, queryDispatch] = useReducer(reducer, initQueryValue);
  const [renderMonths, setRenderMonths] = useState<Array<Date>>([]);

  const proportionOptions = useMemo(() => {
    return projectInfo.proportion.map((item: ProportionData) => ({ text: item.displayName, value: item.id }));
  }, [projectInfo]);

  const totalMonths = useMemo(() => {
    return getMonthBetweenDates(new Date(filterValue.startTime), new Date(filterValue.endTime));
  }, [filterValue.startTime, filterValue.endTime]);

  const searchConcreteHeaderParams: SearchConcreteHeaderParams = useMemo(() => {
    return {
      ...query,
      orderId: projectInfo.orderId,
      factoryId: projectInfo.factoryId,
      timezone: 'Asia/Taipei',
    };
  }, [query]);

  const isMoreData = useMemo(() => {
    return totalMonths.length > 0;
  }, [totalMonths]);

  const pushMonthToRenderMonths = useCallback(() => {
    const month = totalMonths.shift();
    if (month) {
      setRenderMonths((prev: Array<Date>) => [...prev, month]);
    }
  }, [totalMonths]);

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: isMoreData,
    onLoadMore: () => pushMonthToRenderMonths(),
    rootMargin: '0px 0px 400px 0px',
  });

  const handleStartDateChange = useCallback((value: any) => {
    handleFilterChange('startTime')(value);
    handleQueryChange('startTime')(value);
  }, []);

  const handleEndDateChange = useCallback((value: any) => {
    handleFilterChange('endTime')(value);
    handleQueryChange('endTime')(value);
  }, []);

  const handleProportionChange = useCallback((value: any) => {
    handleFilterChange('proportionIds')(value);

    const proportionsMap = new Map();

    projectInfo.proportion.forEach((item) => {
      proportionsMap.set(item.id, item.realProps);
    });

    const realPropNames: Array<string> = [];

    value.forEach((id: number) => {
      const realProps = proportionsMap.get(id);

      if (realProps) {
        realProps.forEach((realProp: RealPropData) => {
          realPropNames.push(realProp.proportionName);
        });
      }
    });

    handleQueryChange('realPropNames')(realPropNames);
  }, []);

  const handleFilterChange = useCallback(
    (field: string) => async (value: any) => {
      filterValueDispatch({ type: 'updateValue', payload: { [field]: value } });
    },
    [],
  );

  const handleQueryChange = useCallback(
    (field: string) => async (value: unknown) => {
      queryDispatch({ type: 'updateValue', payload: { [field]: value } });
    },
    [],
  );

  useEffect(() => {
    if (searchConcreteHeaderParams.realPropNames.length > 0) {
      getConcreteHeaderBySearch(searchConcreteHeaderParams);
    } else {
      clearConcreteHeader();
    }
  }, [searchConcreteHeaderParams]);

  useEffect(() => {
    setRenderMonths([]);
  }, [filterValue.startTime, filterValue.endTime]);

  return (
    <Box sx={{ height: '100%', width: '100%', p: '33px 36px', position: 'relative', opacity: actionLoading ? 0.5 : 1 }}>
      <Stack direction="row" spacing={2} sx={{ pt: 1, pb: 5 }} alignItems="center">
        <Title>{i18T('REPORT.PRODUCTION_HISTORY.CONCRETE_QUALITY.TOOLBAR.TITLE')}</Title>
        <YatungDateSelect
          label={i18T('REPORT.PRODUCTION_HISTORY.CONCRETE_QUALITY.TOOLBAR.START_DATE')}
          disabled={actionLoading}
          value={filterValue.startTime}
          maxDate={filterValue.endTime}
          disableFuture
          onChange={(val) => val && handleStartDateChange(new Date(val).getTime())}
        />
        <YatungDateSelect
          label={i18T('REPORT.PRODUCTION_HISTORY.CONCRETE_QUALITY.TOOLBAR.END_DATE')}
          disabled={actionLoading}
          value={filterValue.endTime}
          minDate={filterValue.startTime}
          disableFuture
          onChange={(val) => val && handleEndDateChange(new Date(val).getTime())}
        />
        <YatungComboBox
          loading={actionLoading}
          multiple
          limitTags={1}
          options={proportionOptions}
          defaultValue={filterValue.proportionIds}
          onBlur={(value: any) => {
            handleProportionChange(value);
          }}
        />
      </Stack>

      {actionLoading ? (
        <Box sx={{ mb: 10 }}>
          <YatungLoading style={{ position: 'absolute', left: '45%' }} />
        </Box>
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '30px',
            justifyContent: 'space-between',
            borderBottom: '1px solid #000000',
          }}
        >
          <StatisticsCard title="進行澆置作業天數" value={concreteHeader?.days || 0} unit="天" />
          <StatisticsCard title="監控期間澆置方數" value={concreteHeader?.sumQty || 0} unit="方" />
          <StatisticsCard title="監控期間運載車次" value={concreteHeader?.ttlTruck || 0} unit="車" />
          <StatisticsCard title="監控期間檢測車次" value={concreteHeader?.exptTtlTruck || 0} unit="車" />
          <StatisticsCard title="爐碴檢測異常天數" value={concreteHeader?.slagAbnormalDays || 0} unit="天" />
          <StatisticsCard title="氯離子檢測異常天數" value={concreteHeader?.chlorideIonAbnormalDays || 0} unit="天" />
          <StatisticsCard title="品質問題退車車次" value={concreteHeader?.returnTruck || 0} unit="車" />
        </Box>
      )}

      <Box>
        <Stack divider={divider}>
          {renderMonths.map((item: Date, index: number) => {
            const dateRange = getFirstDateAndLastDateByMonth(item);
            return (
              <InformationCard
                key={index}
                params={{
                  ...searchConcreteHeaderParams,
                  startTime:
                    dateRange.firstDate.getTime() < query.startTime ? query.startTime : dateRange.firstDate.getTime(),
                  endTime: dateRange.lastDate.getTime() > query.endTime ? query.endTime : dateRange.lastDate.getTime(),
                }}
              />
            );
          })}
        </Stack>
        <div ref={sentryRef} />
      </Box>
    </Box>
  );
}
