import React from 'react';
import VerificationCodeCard from '../components/VerificationCodeCard';

export default function VerificationCodeScreen() {
  return (
    <>
      <VerificationCodeCard />
    </>
  );
}
