import { Stack, Typography } from '@mui/material';
import YatungDynamicFormSelect from '@Src/_basic/components/YatungDynamicFormSelect';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import { Options } from '@Src/_basic/components/YatungSelect';
import { UpdateInitialWeightUnitRequest, IntlUnitConversionsArrayData } from '@Src/_basic/object/WeightUnitManageType';
import { ErrorMessage, Field } from 'formik';
import React, { useEffect, useState } from 'react';

interface Props {
  index: number;
  avaliableOptions: Array<Options>;
  values: UpdateInitialWeightUnitRequest;
}
export default function BottomMappingData(props: Props) {
  const { index, avaliableOptions, values } = props;
  const [dymanicOptions, setDymanicOptions] = useState<Array<Options>>([]);
  const filterOptions = async () => {
    const intlUnitConvert = values.intlUnitConversions.map((value: IntlUnitConversionsArrayData) => {
      return value.intlUnitId;
    });
    const intlUnitConversionFilter = avaliableOptions.filter((value: Options) => {
      return !intlUnitConvert.includes(value.value);
    });

    setDymanicOptions(intlUnitConversionFilter);
  };
  useEffect(() => {
    filterOptions();
  }, [values]);
  return (
    <Stack direction={'row'} spacing={6} alignItems={'center'} sx={{ my: 1 }}>
      <Stack direction={'column'}>
        <Field
          id="intlUnitId"
          name={`intlUnitConversions.${index}.intlUnitId`}
          type="number"
          label=""
          defaultValue={0}
          variant="standard"
          component={YatungDynamicFormSelect}
          sx={{ width: '204px' }}
          options={dymanicOptions}
          totalOptions={avaliableOptions}
          required
        />
        <ErrorMessage
          name={`intlUnitConversions.${index}.intlUnitId`}
          render={(msg) => (
            <Stack>
              <Typography sx={{ fontSize: 14, color: '#d32f2f' }}>{msg}</Typography>
            </Stack>
          )}
        />
      </Stack>
      <Stack direction={'column'}>
        <Field
          id="intlUnitValue"
          name={`intlUnitConversions.${index}.intlUnitValue`}
          type="number"
          label=""
          component={YatungFormInput}
          sx={{ width: '90%' }}
          required
        />
        <ErrorMessage
          name={`intlUnitConversions.${index}.intlUnitValue`}
          render={(msg) => (
            <Stack>
              <Typography sx={{ fontSize: 14, color: '#d32f2f' }}>{msg}</Typography>
            </Stack>
          )}
        />
      </Stack>
    </Stack>
  );
}
