import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function RockSmall(props: SvgIconProps) {
    return (
        <svg {...props} width="21" height="20" viewBox="0 0 21 20" fill="none" >
            <path d="M5.49335 1.96355C5.74981 1.96355 5.95699 1.749 5.95699 1.48422C5.95699 1.21944 5.74981 1.00488 5.49335 1.00488C5.23689 1.00488 5.02971 1.21944 5.02971 1.48422C5.02971 1.6413 5.10258 1.78063 5.2154 1.86804C5.24249 1.88917 5.27216 1.90728 5.30346 1.92177L5.32915 1.93276C5.33966 1.93686 5.3504 1.94061 5.36138 1.94387C5.38264 1.95039 5.40436 1.95534 5.42678 1.9586C5.43776 1.96029 5.44874 1.9615 5.45995 1.96234C5.47093 1.96319 5.48214 1.96355 5.49335 1.96355Z" fill="#7C878E" />
            <path d="M3.56637 11.7359C3.55212 11.7448 3.53718 11.753 3.52176 11.7604C3.50564 11.7681 3.48906 11.7749 3.47201 11.7808L3.44959 11.7878C3.44048 11.7905 3.43113 11.7928 3.42179 11.7948C3.39096 11.8015 3.35896 11.805 3.32603 11.805C3.06956 11.805 2.86238 11.5904 2.86238 11.3256C2.86238 11.0608 3.06956 10.8463 3.32603 10.8463C3.58249 10.8463 3.78967 11.0608 3.78967 11.3256C3.78967 11.3639 3.78523 11.4012 3.77706 11.437C3.76584 11.486 3.74739 11.5321 3.72287 11.574C3.70044 11.6121 3.67312 11.6468 3.64158 11.6771L3.62827 11.6894C3.62056 11.6964 3.61262 11.7032 3.60421 11.7096C3.59207 11.719 3.57945 11.7278 3.56637 11.7359Z" fill="#7C878E" />
            <path d="M8.76221 11.6561C8.67789 11.7479 8.55853 11.805 8.4261 11.805C8.22172 11.805 8.04911 11.6691 7.98698 11.4802C7.9711 11.4316 7.96245 11.3797 7.96245 11.3256C7.96245 11.0608 8.1694 10.8463 8.4261 10.8463C8.68256 10.8463 8.88974 11.0608 8.88974 11.3256C8.88974 11.3866 8.87876 11.4449 8.85867 11.4985C8.8491 11.5245 8.83718 11.5494 8.8234 11.5729C8.80589 11.603 8.78533 11.6309 8.76221 11.6561Z" fill="#7C878E" />
            <path d="M13.5262 11.805C13.7826 11.805 13.9898 11.5904 13.9898 11.3256C13.9898 11.3069 13.9889 11.2884 13.9868 11.2703C13.9842 11.2465 13.9798 11.2232 13.9739 11.2007C13.9655 11.1679 13.9538 11.1367 13.9391 11.1071C13.8623 10.9523 13.7065 10.8463 13.5262 10.8463C13.2695 10.8463 13.0625 11.0608 13.0625 11.3256C13.0625 11.5904 13.2695 11.805 13.5262 11.805Z" fill="#7C878E" />
            <path d="M19.2911 10.9204C19.2469 10.9659 19.1939 11.0024 19.1351 11.0267L19.1234 11.0313L19.0996 11.0395L19.082 11.0448C19.0678 11.0487 19.0533 11.0518 19.0386 11.0543C19.0141 11.0584 18.9891 11.0605 18.9634 11.0605C18.7069 11.0605 18.4997 10.8459 18.4997 10.5812C18.4997 10.3164 18.7069 10.1018 18.9634 10.1018C19.2201 10.1018 19.427 10.3164 19.427 10.5812C19.427 10.6301 19.42 10.6773 19.4069 10.7217C19.3843 10.7978 19.3441 10.8657 19.2911 10.9204Z" fill="#7C878E" />
            <path d="M10.759 1.93216C10.7076 1.95244 10.6516 1.96355 10.5932 1.96355C10.3367 1.96355 10.1298 1.749 10.1298 1.48422C10.1298 1.21944 10.3367 1.00488 10.5932 1.00488C10.8499 1.00488 11.0568 1.21944 11.0568 1.48422C11.0568 1.59892 11.0181 1.7042 10.9531 1.78679C10.9022 1.85126 10.8356 1.90185 10.759 1.93216Z" fill="#7C878E" />
            <path d="M15.6933 1.96355C15.8348 1.96355 15.9612 1.89835 16.046 1.79548C16.1151 1.71181 16.1569 1.60302 16.1569 1.48422C16.1569 1.21944 15.95 1.00488 15.6933 1.00488C15.4368 1.00488 15.2296 1.21944 15.2296 1.48422C15.2296 1.54108 15.2392 1.59566 15.2567 1.64625C15.2614 1.65989 15.2668 1.67329 15.2726 1.68633C15.2789 1.69985 15.2857 1.71314 15.2929 1.72606L15.3072 1.74972C15.313 1.75865 15.3188 1.76735 15.3251 1.7758L15.341 1.7962C15.426 1.89871 15.5522 1.96355 15.6933 1.96355Z" fill="#7C878E" />
            <path d="M17.5026 8.89975C17.5026 9.16453 17.2954 9.37908 17.039 9.37908C16.7825 9.37908 16.5753 9.16453 16.5753 8.89975C16.5753 8.63497 16.7825 8.42041 17.039 8.42041C17.1656 8.42041 17.2803 8.47269 17.3639 8.55745C17.3935 8.58764 17.4195 8.62193 17.4405 8.6596C17.48 8.73023 17.5026 8.81221 17.5026 8.89975Z" fill="#7C878E" />
            <path d="M3.31014 7.37639C3.32042 7.33654 3.32603 7.29465 3.32603 7.25142C3.32603 6.98664 3.11885 6.77209 2.86238 6.77209C2.60592 6.77209 2.39874 6.98664 2.39874 7.25142C2.39874 7.34668 2.4256 7.43543 2.47185 7.50992C2.52324 7.59299 2.59891 7.65855 2.68837 7.69598C2.74209 7.71844 2.80072 7.73075 2.86238 7.73075C3.03266 7.73075 3.18121 7.63622 3.26179 7.49519C3.28281 7.45837 3.29917 7.41852 3.31014 7.37639Z" fill="#7C878E" />
            <path d="M8.4261 7.25142C8.4261 7.5162 8.21892 7.73075 7.96245 7.73075C7.70576 7.73075 7.49881 7.5162 7.49881 7.25142C7.49881 6.98676 7.70576 6.77209 7.96245 6.77209C8.10423 6.77209 8.23083 6.83765 8.31585 6.941C8.38452 7.02467 8.4261 7.1331 8.4261 7.25142Z" fill="#7C878E" />
            <path d="M13.0623 7.73075C13.319 7.73075 13.5259 7.5162 13.5259 7.25142C13.5259 6.98676 13.319 6.77209 13.0623 6.77209C12.8058 6.77209 12.5986 6.98676 12.5986 7.25142C12.5986 7.5162 12.8058 7.73075 13.0623 7.73075Z" fill="#7C878E" />
            <path d="M18.8681 6.79817C18.7835 6.91251 18.6502 6.98628 18.4997 6.98628C18.2433 6.98628 18.0361 6.77173 18.0361 6.50695C18.0361 6.32789 18.1309 6.17166 18.2718 6.08943C18.339 6.05007 18.4168 6.02761 18.4997 6.02761C18.7564 6.02761 18.9634 6.24229 18.9634 6.50695C18.9634 6.61658 18.9279 6.71751 18.8681 6.79817Z" fill="#7C878E" />
            <path d="M4.94866 4.59988C5.20512 4.59988 5.4123 4.38533 5.4123 4.12055C5.4123 3.85577 5.20512 3.64121 4.94866 3.64121C4.6922 3.64121 4.48502 3.85577 4.48502 4.12055C4.48502 4.38533 4.6922 4.59988 4.94866 4.59988Z" fill="#7C878E" />
            <path d="M10.5124 4.12055C10.5124 4.38533 10.3052 4.59988 10.0487 4.59988C9.79203 4.59988 9.58509 4.38533 9.58509 4.12055C9.58509 3.85577 9.79203 3.64121 10.0487 3.64121C10.3052 3.64121 10.5124 3.85577 10.5124 4.12055Z" fill="#7C878E" />
            <path d="M15.4732 4.46308C15.5592 4.37615 15.6124 4.25481 15.6124 4.12055C15.6124 3.85577 15.4053 3.64121 15.1488 3.64121C14.8921 3.64121 14.6852 3.85577 14.6852 4.12055C14.6852 4.38533 14.8921 4.59988 15.1488 4.59988C15.2752 4.59988 15.3896 4.54772 15.4732 4.46308Z" fill="#7C878E" />
            <path d="M3.78967 16.5983C3.78967 16.8631 3.58249 17.0776 3.32603 17.0776C3.06956 17.0776 2.86238 16.8631 2.86238 16.5983C2.86238 16.4936 2.89462 16.3969 2.94951 16.3181C3.03383 16.1974 3.17093 16.119 3.32603 16.119C3.58249 16.119 3.78967 16.3335 3.78967 16.5983Z" fill="#7C878E" />
            <path d="M8.4261 17.0776C8.53938 17.0776 8.64285 17.0358 8.7232 16.9664C8.82504 16.8785 8.88974 16.7462 8.88974 16.5983C8.88974 16.3335 8.68256 16.119 8.4261 16.119C8.1694 16.119 7.96245 16.3335 7.96245 16.5983C7.96245 16.8631 8.1694 17.0776 8.4261 17.0776Z" fill="#7C878E" />
            <path d="M13.9898 16.5983C13.9898 16.8631 13.7826 17.0776 13.5262 17.0776C13.2695 17.0776 13.0625 16.8631 13.0625 16.5983C13.0625 16.3335 13.2695 16.119 13.5262 16.119C13.7826 16.119 13.9898 16.3335 13.9898 16.5983Z" fill="#7C878E" />
            <path d="M1.47146 14.4413C1.72792 14.4413 1.9351 14.2267 1.9351 13.962C1.9351 13.6972 1.72792 13.4826 1.47146 13.4826C1.21499 13.4826 1.00781 13.6972 1.00781 13.962C1.00781 14.2267 1.21499 14.4413 1.47146 14.4413Z" fill="#7C878E" />
            <path d="M7.03517 13.962C7.03517 14.2267 6.82799 14.4413 6.57152 14.4413C6.31483 14.4413 6.10788 14.2267 6.10788 13.962C6.10788 13.6972 6.31483 13.4826 6.57152 13.4826C6.76843 13.4826 6.93637 13.6092 7.00363 13.7879C7.02396 13.8418 7.03517 13.9005 7.03517 13.962Z" fill="#7C878E" />
            <path d="M11.6716 14.4413C11.9281 14.4413 12.1352 14.2267 12.1352 13.962C12.1352 13.6972 11.9281 13.4826 11.6716 13.4826C11.4149 13.4826 11.208 13.6972 11.208 13.962C11.208 14.0748 11.2456 14.1786 11.3086 14.2604C11.3934 14.3707 11.5242 14.4413 11.6716 14.4413Z" fill="#7C878E" />
            <path d="M17.5725 13.2175C17.5725 13.4823 17.3655 13.6968 17.1088 13.6968C16.8524 13.6968 16.6452 13.4823 16.6452 13.2175C16.6452 12.9527 16.8524 12.7382 17.1088 12.7382C17.3655 12.7382 17.5725 12.9527 17.5725 13.2175Z" fill="#7C878E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.00294301 6.02298L0 6.02728H0.00294301V19.0864H16.0211L20.0939 13.4256V0.000291208L4.12745 0L0 5.525L0.00294301 6.02298ZM4.39147 0.530178L0.642451 5.52386L15.7218 5.4971L19.1206 0.530178H4.39147ZM19.6097 0.707784V13.2736L15.771 18.6092H0.487062V6.02728H15.9698L19.6097 0.707784Z" fill="#7C878E" />
        </svg>
    );
}