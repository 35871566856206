import React from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material'

import YatungButton from '@Src/_basic/components/YatungButton'

interface Props {
  Icon: JSX.Element;
  title: string;
  onCancel: () => void;
  onFinish: () => void;
}

const TitleWithButton = ({Icon, title, onCancel, onFinish}:Props) => {
  const { t: i18T } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ minWidth: 700, bgcolor: '#003087', color: 'white', boxShadow: 16, borderRadius: '20px 20px 0 0'}}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
        sx={{ py: 1, px: 6, width: '60%' }}
      >
        {Icon}
        <Typography sx={{ p: 1, fontSize: 36 }}>{title}</Typography>
      </Box>
      <Stack spacing={2} alignItems="center" justifyContent="flex-start" direction="row">
        <YatungButton
          text={i18T('GLOBAL.CANCEL')}
          color={'red'}
          onClick={onCancel}
          width={100}
          sx={{ borderRadius: 20, py: 1.5 }}
        />
        <YatungButton
          text={i18T('GLOBAL.FINISH')}
          onClick={onFinish}
          color={'green'}
          width={100}
          sx={{ borderRadius: 20, py: 1.5 }}
        />
      </Stack>
    </Box>
  )
}

export default TitleWithButton