import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import { MaintPropMixerCode } from '@Src/_basic/object/MixerTypes';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MixerInformationCreateForm from './MixerInformationCreateForm';

export default function MixerInformationCreateCard() {
  const { t: i18T } = useTranslation();

  const [proportionOptions, setProportionOptions] = useState<Options[]>([]);

  useEffect(() => {
    MixerApi.getMixerOptionsByProportion(
      (data: MaintPropMixerCode[]) => {
        changeKeyName(data);
      },
      undefined,
      undefined,
    );
    const changeKeyName = (data: MaintPropMixerCode[]) => {
      const proportions = data.map((pro: MaintPropMixerCode) => ({
        value: pro.code,
        text: pro.code,
      }));
      setProportionOptions(proportions);
    };
  }, []);
  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.MIXER_INFORMATION.ROUTE_TITLE')}
      body={<MixerInformationCreateForm proportionOptions={proportionOptions} />}
      contentBgColor="#FFF"
    />
  );
}
