import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { SampleData } from '@Src/_basic/object/SampleLocationType';
import { useApi } from '@Src/redux/api/apiAction';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import NameColumnFormatter from './columnFormatter/NameColumnFormatter';
import TypeColumnFormatter from './columnFormatter/TypeColumnFormatter';

interface Props {
  sampleData: SampleData[] | undefined;
  handleUpdateAndDelete: (id: number, modal: 'UPDATE' | 'DELETE') => void;
}

function SampleSourceManagementTable({ sampleData, handleUpdateAndDelete }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const getButtonText = (status: string) => {
    switch (status) {
      case '編輯':
        return i18T('GLOBAL.UPDATE');
      case '刪除':
        return i18T('SAMPLESOURCEMANAGEMENT.DELETE');
      case '使用中':
        return i18T('GLOBAL.USING');
      case '啟用':
        return i18T('GLOBAL.OPEN');
      default:
        '';
    }
  };

  const columns: Array<Column> = [
    {
      text: i18T('SAMPLESOURCEMANAGEMENT.NO'),
      dataField: 'number',
      width: '15%',
    },
    {
      text: i18T('SAMPLESOURCEMANAGEMENT.SAMPLE'),
      dataField: 'name',
      formatter: NameColumnFormatter,
      width: '15%',
    },
    {
      text: i18T('SAMPLESOURCEMANAGEMENT.SITE_TYPE'),
      dataField: 'typeIds',
      formatter: TypeColumnFormatter,
      width: '40%',
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.ACTION'),
      dataField: '',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onEdit: (id: number) => handleUpdateAndDelete(id, 'UPDATE'),
        onStop: (id: number) => handleUpdateAndDelete(id, 'DELETE'),
        getButtonText: getButtonText,
      },
      width: '30%',
    },
  ];

  return <YatungTable columns={columns} data={sampleData} loading={actionLoading} />;
}
export default memo(SampleSourceManagementTable);
