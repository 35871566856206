import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';

export interface RowMultipleFieldInfoProps {
  style?: React.CSSProperties;
  title?: string;
  titleStyle?: React.CSSProperties;
  values?: Array<Array<string | number>>;
  valuesTextStyle?: Array<React.CSSProperties>;
  valueTextStyle?: Array<Array<React.CSSProperties>>;
}

const RowMultipleFieldInfo: React.FC<RowMultipleFieldInfoProps> = ({
  title,
  titleStyle,
  style,
  values,
  valueTextStyle,
  valuesTextStyle,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 2,
        borderBottom: `1px solid #e5e5e5`,
        ...style,
      }}
    >
      {title && <Typography sx={{ flex: 1, fontWeight: '900', ...titleStyle }}>{title}</Typography>}

      <Box
        sx={{
          flex: values && values[0] && values[0]?.length > 1 ? 2 : 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {values &&
          values.map((value, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {value.map((_value, _index) => (
                <Typography
                  key={_index}
                  sx={{
                    flex: 1,
                    ...(valuesTextStyle && valuesTextStyle[index]),
                    ...(valueTextStyle && valueTextStyle[index][_index]),
                    fontWeight: '900',
                  }}
                >
                  {_value.toString()}
                </Typography>
              ))}
            </div>
          ))}
      </Box>
    </Box>
  );
};

export default memo(RowMultipleFieldInfo);
