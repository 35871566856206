import { toAbsoluteUrl } from '@Src/_basic/helpers/AssetHelpers';
import { Stack } from '@mui/material';
import React from 'react';

const img = '/media/images/YATUNG_logo_permission.png';

export default function Header() {
  return (
    <Stack alignItems="center" sx={{ py: 2, position: 'sticky', top: 0, bgcolor: 'inherit', zIndex: 1 }}>
      <img src={toAbsoluteUrl(img)} alt="logo" style={{ width: '180px', height: '80px' }} />
    </Stack>
  );
}
