import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { VehicleData } from '@Src/_basic/object/VehicleType';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from '../columnFormatter/ActionColumnFormatter';
import FactoryColumnFormatter from '../columnFormatter/FactoryColumnFormatter';
import PlateNumberColumnFormatter from '../columnFormatter/PlateNumberColumnFormatter';
import RemovedColumnFormatter from '../columnFormatter/RemovedColumnFormatter';
import TypeColumnFormatter from '../columnFormatter/TypeColumnFormatter';

import { useApi } from '@Src/redux/api/apiAction';
import { ArrowCircleDown, ArrowCircleUp } from '@mui/icons-material';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import { Stack } from '@mui/material';

interface Props {
  sortState: boolean | null;
  onSortClick: () => void;
  vehicleData: VehicleData[];
  handleUpdateAndDelete: (id: number, model: 'UPDATE' | 'DELETE') => void;
}

function AreaManagementTable({ vehicleData, handleUpdateAndDelete, sortState, onSortClick }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const getButtonText = useCallback(
    (status: string) => {
      switch (status) {
        case '編輯':
          return i18T('GLOBAL.UPDATE');
        case '停止':
          return i18T('GLOBAL.HALT');
        case '使用中':
          return i18T('GLOBAL.USING');
        case '啟用':
          return i18T('GLOBAL.OPEN');
        default:
          return '';
      }
    },
    [i18T],
  );

  const sortArrowView = useMemo(() => {
    const style = { width: 28, height: 28, ml: 1, cursor: 'pointer' };

    switch (sortState) {
      case null:
        return <MobiledataOffIcon onClick={onSortClick} sx={{ ...style, opacity: 0.5 }} />;
      case true:
        return <ArrowCircleUp onClick={onSortClick} sx={style} />;
      case false:
        return <ArrowCircleDown onClick={onSortClick} sx={style} />;
      default:
        return null;
    }
  }, [sortState, onSortClick]);

  const getDataSort = useCallback(() => {
    return (
      <Stack flexDirection={'row'} alignItems={'center'}>
        {i18T('USERSMANAGEMENU.TABLE.PLATE_NUMBER')}
        {sortArrowView}
      </Stack>
    );
  }, [i18T, sortArrowView]);

  const columns: Array<Column> = useMemo(
    () => [
      {
        text: i18T('USERSMANAGEMENU.TABLE.NO'),
        dataField: 'ids',
        width: '10%',
      },
      {
        text: '',
        dataField: 'plateNumber',
        formatter: PlateNumberColumnFormatter,
        titleFormatter: getDataSort,
        width: '15%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.TYPE'),
        dataField: 'type',
        formatter: TypeColumnFormatter,
        width: '10%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.SITE'),
        dataField: 'factory',
        formatter: FactoryColumnFormatter,
        width: '45%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.STATE'),
        dataField: 'removed',
        formatter: RemovedColumnFormatter,
        formatExtraData: {
          getButtonText: getButtonText,
        },
        width: '10%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.ACTION'),
        dataField: '',
        formatter: ActionColumnFormatter,
        formatExtraData: {
          onEdit: (id: number) => handleUpdateAndDelete(id, 'UPDATE'),
          onStop: (id: number) => handleUpdateAndDelete(id, 'DELETE'),
          getButtonText: getButtonText,
        },
        width: '10%',
      },
    ],
    [i18T, handleUpdateAndDelete, getButtonText, getDataSort],
  );

  return <YatungTable columns={columns} data={vehicleData} loading={actionLoading} />;
}

export default memo(AreaManagementTable);
