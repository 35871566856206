import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { CalcSlagWaterDemand } from '@Src/_basic/helpers/CalcTestForm';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema, notRequiredNumberSchema } from '@Src/_basic/helpers/TestForm';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  ImportSlagWaterDemand,
  PutSlagWaterDemandRequest,
  SlagWaterDemand,
} from '@Src/_basic/object/test/cementtitiousPowder/SlagWaterDemandType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: SlagWaterDemand;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  controlWaterDemand: basicNumberSchema,
  waterDemand: notRequiredNumberSchema,
});

function ExportSlagWaterDemandTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);
  const calcTestForm = useMemo(() => new CalcSlagWaterDemand(testDetail), [testDetail]);

  const initialValues = useMemo(
    () => ({
      controlWaterDemand: testDetail?.controlWaterDemand,
      waterDemand: testDetail?.waterDemand,
    }),
    [testDetail],
  );

  const firstInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.controlWaterDemand?.toFixed(4)),
        values: [formatNumber(testDetail?.waterDemand?.toFixed(4))],
      },
    ],
    [testDetail?.controlWaterDemand, testDetail?.waterDemand],
  );

  const secondInfo = useMemo(
    () => [
      {
        title: formatNumber(calcTestForm?.waterDemandRate?.toFixed(4)),
        valuesTextStyle: TestVerifyResultStyles.normal,
      },
    ],
    [calcTestForm?.waterDemandRate],
  );

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.SLAG_WATER_DEMAND.CONTROL_WATER_DEMAND`)}(ml)`,
      values: [`${i18T(`TEST.SLAG_WATER_DEMAND.COMPARISON_WATER_DEMAND`)}(ml)`],
    }),
    [i18T],
  );

  const secondHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.SLAG_WATER_DEMAND.WATER_DEMAND_RATE`)}(%)`,
    }),
    [i18T],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportSlagWaterDemand = {
        controlWaterDemand: checkIsNaN(+values.controlWaterDemand),
        waterDemand: checkIsNaN(+values.waterDemand),
      };

      TestApi.putTest<PutSlagWaterDemandRequest>(
        {
          exptItemCode: testDetail.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const firstView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack flexDirection="row" gap={5}>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.SLAG_WATER_DEMAND.CONTROL_WATER_DEMAND`)}(ml)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.controlWaterDemand}
                isError={!!(formik.touched.controlWaterDemand && formik.errors.controlWaterDemand)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('controlWaterDemand', e.target.value)
                }
              />
            </ColFieldInfo>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.SLAG_WATER_DEMAND.COMPARISON_WATER_DEMAND`)}(ml)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.waterDemand}
                isError={!!(formik.touched.waterDemand && formik.errors.waterDemand)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('waterDemand', e.target.value)}
              />
            </ColFieldInfo>
          </Stack>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstInfo} />;
      default:
        break;
    }
  }, [edit, formik, firstHeader, firstInfo, i18T]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {firstView}
        {!edit && <TestTable headerData={secondHeader} rows={secondInfo} />}
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportSlagWaterDemandTable);
