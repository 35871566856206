import { MonthlyReportFineAggAms, MonthlyReportNameEnum } from '@Src/_basic/object/MonthlyReportType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonthlyReportSelect from '../components/MonthlyReportSelect';

const FineAggregateAMS = () => {
  const { t: i18T } = useTranslation();
  const [tableData, setTableData] = useState<Array<MonthlyReportFineAggAms>>([]);

  const selectHandler = useCallback(
    (
      factoryId: number,
      exptUnitId: number,
      startTime: number,
      endTime: number,
      specificationId?: number,
      sourceId?: number,
    ) => {
      if (!factoryId || !exptUnitId || !startTime || !endTime || !specificationId) return;

      MonthlyReportApi.getFineAggAMS(
        {
          factoryId,
          exptUnitId,
          startTime,
          endTime,
          specificationId,
          ...(sourceId && { sourceId }),
        },
        (_data) => setTableData(_data),
      );
    },
    [],
  );

  return (
    <MonthlyReportSelect
      title={i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_AMS_DETECTION.TITLE')}
      exptType={MonthlyReportNameEnum.FINE_AGGREGATE_AMS_DETECTION}
      selectHandler={selectHandler}
      tableData={tableData}
    />
  );
};

export default memo(FineAggregateAMS);
