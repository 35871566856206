import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestSavingState } from './requestSavingActions';

interface InitialState<T> {
  request: RequestSavingState<T> | undefined;
}

export const requestSavingSlice = createSlice({
  name: 'requestSaving',
  initialState: {
    request: undefined,
  } as InitialState<any>,
  reducers: {
    setRequest: <T>(state: InitialState<T>, action: PayloadAction<RequestSavingState<T> | undefined>) => {
      state.request = action.payload;
    },
  },
});
