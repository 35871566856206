import React from 'react';
import { InputAdornment, MenuItem, Stack, StandardTextFieldProps, styled, TextField, Typography } from '@mui/material';
import TriangleDown from '../icons/TriangleDown';
import { Options } from './YatungSelect';

interface IconSelectProps extends StandardTextFieldProps {
  options: Array<Options>;
  icon?: React.ReactNode;
  label?: string;
  field?: any;
}

const TriangleDownIcon = styled(TriangleDown)(() => ({
  color: '#003087',
  fill: '#003087',
}));

const SelectTextfield = styled(TextField)(({ theme }) => ({
  '& fieldset': {
    borderColor: '#6C708C',
  },
  '& .MuiInputBase-input': {
    minHeight: `0 !important`,
    fontSize: 20,
    padding: '8px 26px 8px 0px',
  },
}));

export default function YatungIconSelect({ options, icon, label, field, ...props }: IconSelectProps) {
  return (
    <Stack flexDirection="row" alignItems="center" sx={{ py: 1 }}>
      {icon}
      <SelectTextfield
        select
        sx={{ ml: 3, fontSize: 20, width: '320px' }}
        {...field}
        {...props}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography variant="caption" sx={{ fontSize: 20, color: '#6C708C', fontWeight: 900 }}>
                {label}:
              </Typography>
            </InputAdornment>
          ),
        }}
        SelectProps={{
          IconComponent: TriangleDownIcon,
        }}
      >
        <MenuItem disabled value={0}>
          請選擇 - - -
        </MenuItem>
        {options.map((option: Options, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </SelectTextfield>
    </Stack>
  );
}
