import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import YatungImg from '@Src/_basic/components/YatungImg';
import AreaManagementCreateForm from './AreaManagementCreateForm';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import { AreaData, PostCreateDataRequest } from '@Src/_basic/object/AreaType';

const createImage = '/media/images/create.png';

interface Props {
  onCreateGoBackButtonClick: () => void;
  createModalOpen: boolean;
  setAreaData: (e: Array<AreaData>) => void;
  setLoading: (e: boolean) => void;
}
export default function AreaManagementCreateCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { onCreateGoBackButtonClick, createModalOpen, setAreaData, setLoading } = props;
  const initialArea: PostCreateDataRequest = {
    name: '',
    sortingOrder: 0,
    removed: false,
  };

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={createModalOpen}
        handleClose={onCreateGoBackButtonClick}
        icon={
          <YatungImg
            img={createImage}
            sx={{
              width: 28,
              height: 28,
              zIndex: 999,
              alignSelf: 'center',
              display: 'flex',
              textAlign: 'center',
              mr: 1,
            }}
          />
        }
        title={i18T('AREAMANAGEMENT.ADD_AREA')}
        body={
          <AreaManagementCreateForm
            initialValues={initialArea}
            onCreateGoBackButtonClick={onCreateGoBackButtonClick}
            setAreaData={setAreaData}
            setLoading={setLoading}
          />
        }
      />
    </Grid>
  );
}
