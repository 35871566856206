import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import BasicMeasuresButton from '@Src/_basic/icons/BasicMeasuresButton';
import CreateUnitButton from '@Src/_basic/icons/CreateUnitButton';
import { TypeData } from '@Src/_basic/object/TypeType';
import { IntlUnitData, UnitData, WeightUnitData } from '@Src/_basic/object/WeightUnitManageType';
import { WeightUnitManageApi } from '@Src/_basic/protocol/WeightUnitManage/WeightUnitManageApi';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BasicWeightMeasuresForm from '../components/BasicWeightMeasuresForm';
import CreateUnitForm from '../components/CreateUnitForm';
import UpdateUnitForm from '../components/UpdateUnitForm';
import WeightUnitManageTable from '../components/WeightUnitManageTable';

type FilterValueType = {
  factoryId: number;
  areaId: number;
};

export default function WeightUnitManageScreens() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { setSaveRequest, request } = useRequestSaving<FilterValueType>();

  const [{ areaId, factoryId }, setQuery] = useState<FilterValueType>({ areaId: 0, factoryId: 0 });
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [isMeasuresDialog, setIsMeasuresDialog] = useState<boolean>(false);
  const [isUnitDialog, setIsUnitDialog] = useState<boolean>(false);
  const [isUpdateUnitDialog, setIsUpdateUnitDialog] = useState<boolean>(false);

  const [selectedWeightUnit, setSelectedWeightUnit] = useState<UnitData>({
    id: 0,
    name: '',
    typeId: 0,
    typeName: '',
    lastUpdateTime: 0,
    intlUnitConversions: [],
  });
  const [conversionValue, setConversionValue] = useState<Array<WeightUnitData>>([]);
  const [types, setTypes] = useState<Array<Options>>([]);
  const [avaliableOptions, setAvaliableOptions] = useState<Array<Options>>([]);

  const [weightUnit, setWeightUnit] = useState<Array<UnitData>>([]);

  const typeDataToOptions = useCallback((data: Array<TypeData>) => {
    const allTypes = data
      .filter((value: IntlUnitData) => value.id !== 9 && value.id !== 11 && value.name !== '拌合水')
      .map((value: IntlUnitData) => ({ value: value.id, text: value.name }));
    setTypes(allTypes);
  }, []);

  const getMeasures = useCallback(() => {
    WeightUnitManageApi.getUnitIntlConversion(setConversionValue);
    WeightUnitManageApi.getUnitIntlAvaliable((data: Array<IntlUnitData>) => {
      setAvaliableOptions(
        data.map((avaliable: IntlUnitData) => ({
          value: avaliable.id,
          text: avaliable.name,
        })),
      );
    });
  }, []);

  const getTypeOptions = useCallback(() => {
    TypeApi.getTypesBySearch({ factoryId }, typeDataToOptions);
  }, [factoryId, typeDataToOptions]);

  const handleUpdateButtonClick = useCallback((id: number) => {
    WeightUnitManageApi.getUnitCustomById(id, setSelectedWeightUnit, undefined, () => {
      setIsUpdateUnitDialog(true);
    });
  }, []);

  const handleUnitUpdateGoBackButtonClick = useCallback(() => setIsUpdateUnitDialog(false), []);
  const handleGoToMeasuresButtonClick = useCallback(() => setIsMeasuresDialog(true), []);
  const handleMeasuresGoBackButtonClick = useCallback(() => setIsMeasuresDialog(false), []);
  const handleGoToUnitButtonClick = useCallback(() => setIsUnitDialog(true), []);
  const handleUnitGoBackButtonClick = useCallback(() => setIsUnitDialog(false), []);

  const handleChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    setQuery((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const getAllWeightUnit = () => {
      WeightUnitManageApi.getUnitCustomBySearch({ factoryId }, (_data) =>
        setWeightUnit(_data.map((item, index) => ({ ...item, ids: index + 1 }))),
      );
    };

    if (areaId) {
      if (!factoryId) {
        setWeightUnit([]);
        setIsVisible(false);
      } else {
        getAllWeightUnit();
        setIsVisible(true);
        getTypeOptions();
        setSaveRequest({ page: location.pathname, request: { areaId, factoryId } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factoryId, areaId, location.pathname]);

  useEffect(() => {
    if (request?.page === location.pathname && request?.request) {
      Object.entries(request.request).forEach(([key, value]) => {
        setQuery((prev) => ({ ...prev, [key]: value }));
      });
    }
  }, [location.pathname, request]);

  useEffect(() => {
    getMeasures();
  }, [getMeasures]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.TITLE')}
      body={
        <>
          <Stack>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <YatungCombinationSelect<FilterValueType>
                filterValue={{ areaId, factoryId }}
                handleChange={handleChange}
                selectOptions={['AreaAndFactory']}
              />
              <Stack direction="row" spacing={2}>
                <YatungButton
                  text={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.BASIC_WEIGHTS_MEASURES')}
                  disabled={!factoryId}
                  startIcon={<BasicMeasuresButton />}
                  onClick={handleGoToMeasuresButtonClick}
                  color="yellow"
                  sx={{
                    fontSize: '20px',
                    width: 200,
                    alignSelf: 'center',
                  }}
                />
                <YatungButton
                  text={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.CREATE_UNIT')}
                  disabled={!factoryId}
                  startIcon={<CreateUnitButton />}
                  onClick={handleGoToUnitButtonClick}
                  color="green"
                  sx={{
                    fontSize: '20px',
                    width: 150,
                    alignSelf: 'center',
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <YatungFadeInOut isVisible={isVisible}>
            <WeightUnitManageTable rows={weightUnit} onEdit={(id: number) => handleUpdateButtonClick(id)} />
          </YatungFadeInOut>
          <BasicWeightMeasuresForm
            conversionValue={conversionValue}
            isMeasuresDialog={isMeasuresDialog}
            onMeasuresGoBackButtonClick={handleMeasuresGoBackButtonClick}
          />
          <CreateUnitForm
            isUnitDialog={isUnitDialog}
            onUnitGoBackButtonClick={handleUnitGoBackButtonClick}
            types={types}
            selectedFactory={factoryId}
            setWeightUnit={setWeightUnit}
            avaliableOptions={avaliableOptions}
          />
          <UpdateUnitForm
            selectedWeightUnit={selectedWeightUnit}
            isUpdateUnitDialog={isUpdateUnitDialog}
            onUnitUpdateGoBackButtonClick={handleUnitUpdateGoBackButtonClick}
            types={types}
            avaliableOptions={avaliableOptions}
            selectedFactory={factoryId}
            setWeightUnit={setWeightUnit}
          />
        </>
      }
    />
  );
}
