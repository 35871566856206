import React, { useCallback, useState } from 'react';
import Wave from 'react-wavify';
import '../assets/styles/App.css';
import { toAbsoluteUrl } from '../helpers/AssetHelpers';

const img = '/media/images/storageNoColor.png';

interface Props {
  height?: any;
}
export default function YatungFeedMachineAnimation(props: Props) {
  const { height } = props;

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);
  const getHeight = () => {
    if (Math.round(height * 100) > 100) {
      return 100;
    } else if (Math.round(height * 100) < 0) {
      return 0;
    } else {
      return Math.round(height * 100);
    }
  };
  return (
    <div className={` ${imageLoaded ? 'storageContainer' : ''}`}>
      <img
        // className="storageImg"
        className={` ${imageLoaded ? 'storageImg' : ''}`}
        src={toAbsoluteUrl(img)}
        alt="storageNoColor"
        style={{
          left: '-2px',
          top: '-2px',
          width: '97px',
          height: '99px',
        }}
        onLoad={handleImageLoad}
      />
      {imageLoaded && (
        <Wave
          className="wave"
          fill="#0099ff" // 设置波浪的颜色
          paused={false} // 控制波浪动画是否暂停
          options={{
            height: 150 - Math.round(0.9 * getHeight()), // 波浪的高度
            amplitude: 3, // 波浪的振幅
            speed: 0.3, // 波浪的速度
            points: 3, // 波浪的点数
          }}
        />
      )}
    </div>
  );
}
