import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Save(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="18" height="22" viewBox="0 0 18 22" fill="none">
      <path d="M1.5 21H16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.99999 1V16M8.99999 16L13.375 11.625M8.99999 16L4.625 11.625"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
