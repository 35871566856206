import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { LanguageApi } from '@Src/_basic/protocol/languageManagement/LanguageApi';
import {
  CreateLangData,
  CreatefileData,
  GetLangData,
  LangData,
  ReplaceLangFileData,
} from '@Src/_basic/object/MultilingualManagementType';
import { languageSlice } from './languageSlice';

export function useLanguage() {
  const { data, option } = useSelector((state: RootState) => state.language);
  const dispatch = useDispatch();

  const setOption = (option: any) => dispatch(languageSlice.actions.setOption(option));

  const uploadLanguageReports = (
    //上傳語言檔案
    platform: string,
    params: CreateLangData,
    file: FileList,
    onSuccess?: (data: Array<CreatefileData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    LanguageApi.uploadLanguageReports(
      platform,
      params,
      file,
      (data: Array<CreatefileData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const replaceUploadLanguageReports = (
    //取代語言檔案
    params: ReplaceLangFileData,
    files: FileList,
    onSuccess?: (data: Array<CreatefileData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    LanguageApi.replaceLanguageReports(
      params,
      files,
      (data: Array<CreatefileData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const getLanguageOption = (
    //儲存語言選項
    params: GetLangData,
    onSuccess?: (data: Array<LangData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    LanguageApi.getLangData(
      params,
      (data: Array<LangData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  return {
    data,
    option,
    setOption,

    uploadLanguageReports,
    replaceUploadLanguageReports,
    getLanguageOption,
  };
}
