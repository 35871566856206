import YatungSelect from '@Src/_basic/components/YatungSelect';
import AddButton from '@Src/_basic/icons/AddButton';
import DeleteCircle from '@Src/_basic/icons/DeleteCircle';
import { ScheduleData } from '@Src/_basic/object/ExptScheduleType';
import { Box, Checkbox, IconButton, Stack, Typography, styled } from '@mui/material';
import React, { useMemo } from 'react';

interface Props {
  value: any;
  activateValue: Array<any>;
  onChange?: (value: any) => void;
  periodDates: Array<ScheduleData>;
  onStartMonthSelected?: (index: number, value: number) => void;
  onStartDaySelected?: (index: number, value: number) => void;
  onEndMonthSelected?: (index: number, value: number) => void;
  onEndDaySelected?: (index: number, alue: number) => void;
  onAddButtonClick?: () => void;
  onDeleteButtonClick?: (index: number) => void;
}

const Label = styled(Typography)(({ theme }) => ({
  color: '#6C708C',
  fontSize: '20px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
}));

export default function YearlySelect(props: Props) {
  const isChecked = useMemo(() => {
    return props.activateValue.includes(props.value);
  }, [props.activateValue, props.value]);

  const getDaysInMonth = (month: number) => {
    // 因不提供2/29的選項，故選擇非閏年(2021)
    return new Date(2021, month, 0).getDate();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Checkbox
          checked={isChecked}
          onChange={() => {
            props.onChange?.(props.value);
          }}
        />
        <Label>每年</Label>
      </Box>
      <Stack alignItems="flex-start" gap="20px">
        {props.periodDates.map((periodDate: ScheduleData, index: number) => {
          return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                <Box sx={{ width: '85px' }}>
                  <YatungSelect
                    options={[...Array(12)].map((_, index) => ({ text: (index + 1).toString(), value: index }))}
                    disabled={!isChecked}
                    value={periodDate.startMonth}
                    sx={{ width: '85px' }}
                    onChange={(e) => {
                      props.onStartMonthSelected?.(index, Number(e.target.value));
                    }}
                  />
                </Box>
                <Label>月</Label>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                <Box sx={{ width: '85px' }}>
                  <YatungSelect
                    options={[...Array(getDaysInMonth(periodDate.startMonth + 1))].map((_, index) => ({
                      text: (index + 1).toString(),
                      value: index + 1,
                    }))}
                    disabled={!isChecked}
                    value={periodDate.startDay}
                    sx={{ width: '85px' }}
                    onChange={(e) => {
                      props.onStartDaySelected?.(index, Number(e.target.value));
                    }}
                  />
                </Box>
                <Label>日開立</Label>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                <Box sx={{ width: '85px' }}>
                  <YatungSelect
                    options={[...Array(12)].map((_, index) => ({ text: (index + 1).toString(), value: index }))}
                    disabled={!isChecked}
                    value={periodDate.endMonth}
                    sx={{ width: '85px' }}
                    onChange={(e) => {
                      props.onEndMonthSelected?.(index, Number(e.target.value));
                    }}
                  />
                </Box>
                <Label>月</Label>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                <Box sx={{ width: '85px' }}>
                  <YatungSelect
                    options={[...Array(getDaysInMonth(periodDate.endMonth + 1))].map((_, index) => ({
                      text: (index + 1).toString(),
                      value: index + 1,
                    }))}
                    disabled={!isChecked}
                    value={periodDate.endDay}
                    sx={{ width: '85px' }}
                    onChange={(e) => {
                      props.onEndDaySelected?.(index, Number(e.target.value));
                    }}
                  />
                </Box>
                <Label>日結束</Label>
              </Box>
              {props.periodDates.length > 1 && (
                <IconButton
                  onClick={() => {
                    props.onDeleteButtonClick?.(index);
                  }}
                >
                  <DeleteCircle />
                </IconButton>
              )}
            </Box>
          );
        })}
        <IconButton onClick={props.onAddButtonClick}>
          <AddButton />
        </IconButton>
      </Stack>
    </Box>
  );
}
