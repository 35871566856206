import React from 'react';
import { Box, Grid } from '@mui/material';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';

interface Props {
  dulpicateWarningMoalOpen: boolean;
  onDulpicateWarningModalClose: () => void;
}
export default function AreaManagementCreateDulpicateMessage(props: Props) {
  const { t: i18T } = useTranslation();
  const { dulpicateWarningMoalOpen, onDulpicateWarningModalClose } = props;

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={dulpicateWarningMoalOpen}
        handleClose={onDulpicateWarningModalClose}
        postion={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
        }}
        title={i18T('GLOBAL.WARN')}
        titleColor={{ color: '#CB333B', bgcolor: '#003087' }}
        body={
          <>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <Box component={'div'} sx={{ fontSize: 28, fontWeight: 400, fontFamily: 'Inter', color: '#7C878E' }}>
                {i18T('AREAMANAGEMENT.DULPICATE_HINT')}
              </Box>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <YatungButton
                size={'large'}
                color="blue"
                type={'button'}
                text={i18T('GLOBAL.SURE')}
                sx={{
                  fontSize: '24px',
                  px: 2,
                }}
                onClick={onDulpicateWarningModalClose}
              />
            </Grid>
          </>
        }
      />
    </Grid>
  );
}
