import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Content, ContentAndGroupsTableType, RoleMenu } from '@Src/_basic/object/AccountRightType';
import { MenuApi } from '@Src/_basic/protocol/accountRights/fetAuth/menu/MenuApi';
import { RoleApi } from '@Src/_basic/protocol/accountRights/fetAuth/role/RoleApi';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import TitleWithButton from '../../../_components/TitleWithButton';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import DeleteCircleIcon from '@Src/_basic/icons/DeleteCircle';
import EditIcon from '@Src/_basic/icons/Edit';
import SearchIcon from '@Src/_basic/icons/Search';
import SwitchIcon from '@Src/_basic/icons/Switch';
import TickIcon from '@Src/_basic/icons/Tick';

interface Props {
  open: boolean;
  closeModel: () => void;
  selectedRoleData: ContentAndGroupsTableType;
  factoryOptions: Array<Options>;
  postGetRoleList: () => void;
}

interface UpdateDetailFactoryAndId {
  id: string;
  factory: number;
}

const initUpdateDetailFactoryId: UpdateDetailFactoryAndId = {
  id: '',
  factory: 0,
};

export default function RoleMaintenanceUpdateCard({
  open,
  closeModel,
  selectedRoleData,
  factoryOptions,
  postGetRoleList,
}: Props) {
  const { t: i18T } = useTranslation();

  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [searchGroup, setSearchGroup] = useState<string>('');
  const [selectFactory, setSelectFactory] = useState<number>(0);
  const [updateDetail, setUpdateDetail] = useState<Content>();
  const [updateDetailApiGroup, setUpdateDetailApiGroup] = useState<Array<RoleMenu>>();
  const [updateDetailFactoryAndId, setUpdateDetailFactoryAndId] = useReducer(
    (prev: UpdateDetailFactoryAndId, next: Partial<UpdateDetailFactoryAndId>) => {
      return { ...prev, ...next };
    },
    initUpdateDetailFactoryId,
  );

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const [hasActionIds, setHasActionIds] = useState<Array<string>>();
  const [apiGroupList, setApiGroupList] = useState<Array<ContentAndGroupsTableType>>([]);
  const [apiGroupListData, setApiGroupListData] = useState<Array<ContentAndGroupsTableType>>([]);
  const [searchApiGroupListByInput, setSearchApiGroupListByInput] = useState<Array<ContentAndGroupsTableType>>();

  const reset = () => {
    setApiGroupListData([]);
    setSelectFactory(0);
    setCode('');
    setName('');
  };

  const cancelHandler = () => {
    closeModel();
    reset();
  };

  const finishHandler = () => {
    if (code && name && selectFactory && updateDetail) {
      const addIds: Array<string> = filterRemoveAndAddIds()[0];
      const removeIds: Array<string> = filterRemoveAndAddIds()[1];

      if (updateDetailFactoryAndId.factory === selectFactory) {
        RoleApi.postUpdateRightsRoles(
          {
            code,
            name,
            id: updateDetail.id,
            state: updateDetail.state,
            delAttrIds: [],
            attrs: [],
          },
          () => postSetRightsRoleBelongAction(addIds, removeIds),
          (error) => {
            setIsShowAlert(true);
            setAlertText(error.message);
          },
        );
      } else {
        RoleApi.postUpdateRightsRoles(
          {
            code,
            name,
            id: updateDetail.id,
            state: updateDetail.state,
            delAttrIds: [updateDetailFactoryAndId.id],
            attrs: [
              {
                code: 'factory',
                attrVal: selectFactory.toString(),
              },
            ],
          },
          () => postSetRightsRoleBelongAction(addIds, removeIds),
          (error) => {
            setIsShowAlert(true);
            setAlertText(error.message);
          },
        );
      }
    }
  };

  const filterRemoveAndAddIds = () => {
    const addIds: Array<string> = [];
    const removeIds: Array<string> = [];
    apiGroupListData
      .filter((item) => item.check)
      .forEach((item) => item.actions?.forEach((_item) => addIds.push(_item.id)));

    if (hasActionIds) {
      hasActionIds.some((item) => {
        if (!addIds.includes(item)) removeIds.push(item);
      });
    }
    return [addIds, removeIds];
  };

  const postSetRightsRoleBelongAction = (addIds: Array<string>, removeIds: Array<string>) => {
    if (updateDetail) {
      RoleApi.postSetRightsRoleBelongAction(
        {
          id: updateDetail.id,
          chkActionIds: addIds,
          unChkActionIds: removeIds,
        },
        (_data) => {
          postGetRoleList();
          closeModel();
          reset();
        },
        (error) => {
          setIsShowAlert(true);
          setAlertText(error.message);
        },
      );
    }
  };

  const getAllApiGroups = () => {
    MenuApi.postGetRightsMenus(
      {
        pageNumber: 0,
        pageSize: 99999,
        state: '1',
        showAction: true,
      },
      (_data) =>
        setApiGroupList(_data.content.filter((item) => item.actions).map((item) => ({ ...item, check: false }))),
    );
  };

  useEffect(() => {
    RoleApi.postGetRightsRoleById(selectedRoleData.id, (_data) => {
      setUpdateDetailApiGroup(_data.menus);
      setUpdateDetail(_data);
      setCode(_data.code);
      setName(_data.name);
      if (_data.attrs) {
        const factory = _data.attrs.find((item) => item.code === 'factory');
        if (factory) {
          setUpdateDetailFactoryAndId({
            factory: parseInt(factory?.attrVal),
            id: factory.id,
          });
          setSelectFactory(parseInt(factory?.attrVal));
        }
      }
    });
  }, [selectedRoleData.id]);

  useEffect(() => {
    getAllApiGroups();
  }, []);

  useEffect(() => {
    setApiGroupListData(apiGroupList);
    if (updateDetailApiGroup && apiGroupList) {
      const hasIds: Array<string> = [];
      updateDetailApiGroup.forEach((item) => {
        item.actions.forEach((_item) => hasIds.push(_item.id));
        setApiGroupListData((prev) =>
          prev.map((apiGroupListItem) =>
            apiGroupListItem.id === item.id ? { ...apiGroupListItem, check: true } : apiGroupListItem,
          ),
        );
      });
      setHasActionIds(hasIds);
    }
  }, [updateDetailApiGroup, apiGroupList]);

  useEffect(() => {
    setSearchApiGroupListByInput(apiGroupListData);
    if (searchGroup) {
      setSearchApiGroupListByInput(
        apiGroupListData.filter(
          (item) =>
            item.code.toUpperCase().includes(searchGroup.toUpperCase()) ||
            item.name.toUpperCase().includes(searchGroup.toUpperCase()),
        ),
      );
    }
  }, [searchGroup, apiGroupListData]);

  const columns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.ROLE_MAINTENANCE.API_GROUP_CODE'),
      dataField: 'code',
      width: '150px',
    },
    {
      text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.NAME_2'),
      dataField: 'name',
    },
    {
      text: undefined,
      dataField: 'id',
      formatter: ({ row }) => {
        return (
          <>
            <IconButton
              onClick={() =>
                setApiGroupListData((prev) =>
                  prev.map((item) => (item.id === row.id ? { ...item, check: false } : item)),
                )
              }
              edge="end"
              sx={{
                height: 10,
                width: 10,
              }}
            >
              <DeleteCircleIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      {updateDetail && searchApiGroupListByInput && (
        <Modal open={open}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
            <TitleWithButton
              Icon={<EditIcon />}
              title={i18T('USERSMANAGEMENU.ROLE_MAINTENANCE.EDIT_ROLE')}
              onCancel={cancelHandler}
              onFinish={finishHandler}
            />
            <Stack spacing={5} sx={{ bgcolor: 'white', minWidth: 700, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Stack sx={{ width: '60%' }}>
                  <Typography sx={{ fontSize: 28 }}>
                    {i18T('USERSMANAGEMENU.ROLE_MAINTENANCE.CODE')}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Stack sx={{ width: '50%' }}>
                    <YatungInput value={code} onChange={(e: any) => setCode(e.target.value)} />
                  </Stack>
                </Stack>
                <Stack>
                  <Typography sx={{ fontSize: 28 }}>
                    {i18T('USERSMANAGEMENU.ROLE_MAINTENANCE.NAME')}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Stack sx={{ width: '100%' }}>
                    <YatungInput value={name} onChange={(e: any) => setName(e.target.value)} />
                  </Stack>
                </Stack>
              </Box>
              {updateDetail && (
                <Box display="flex" alignItems="center" flexDirection="row">
                  <Stack spacing={1} sx={{ width: '30%', mr: 3 }}>
                    <Typography sx={{ fontSize: 28, letterSpacing: 10 }}>
                      {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.STATUS')}:
                      <span style={{ color: updateDetail.state === '1' ? '#00D03A' : '#FC0000', marginLeft: '20px' }}>
                        {updateDetail.state === '1' ? i18T('GLOBAL.OPEN') : i18T('GLOBAL.STOP')}
                      </span>
                    </Typography>
                    <Stack spacing={4} alignItems="center" justifyContent="flex-start" direction="row">
                      <YatungButton
                        text={i18T('GLOBAL.OPEN')}
                        color={'green'}
                        startIcon={<SwitchIcon />}
                        onClick={() => {
                          setUpdateDetail((prev) => {
                            if (prev?.state) {
                              return { ...prev, state: '1' };
                            }
                          });
                        }}
                      />
                      <YatungButton
                        text={i18T('GLOBAL.STOP')}
                        color={'red'}
                        startIcon={<SwitchIcon />}
                        onClick={() => {
                          setUpdateDetail((prev) => {
                            if (prev?.state) {
                              return { ...prev, state: '0' };
                            }
                          });
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack spacing={1} sx={{ width: '30%', mr: 3 }}>
                    <Stack display="flex" alignItems="center" flexDirection="row">
                      <Typography sx={{ fontSize: 28 }}>
                        {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.FACTORY')}
                      </Typography>
                      <Typography sx={{ fontSize: 28 }}>
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Stack>
                    <YatungSelect
                      options={factoryOptions}
                      value={selectFactory}
                      onChange={(e) => setSelectFactory(e.target.value as number)}
                    />
                  </Stack>
                  <Stack>
                    <Typography sx={{ fontSize: 28, letterSpacing: 15 }}>
                      {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.EDIT_TIME')}
                    </Typography>
                    <Stack>
                      <Typography sx={{ pl: 1, letterSpacing: 10 }}>{updateDetail.updateDt.split(' ')[0]}</Typography>
                      <Typography sx={{ pl: 1, letterSpacing: 10 }}>{updateDetail.updateDt.split(' ')[1]}</Typography>
                    </Stack>
                  </Stack>
                </Box>
              )}
              <Stack display="flex" flexDirection="column" spacing={2}>
                <Typography sx={{ fontSize: 28 }}>
                  {i18T('USERSMANAGEMENU.ROLE_MAINTENANCE.CREATE_API_GROUP')}
                </Typography>
                <YatungTable
                  sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'gray',
                    minHeight: 200,
                    maxHeight: 200,
                    maxWidth: 630,
                    px: 1,
                    borderRadius: 2,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: 8,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#003087',
                      borderRadius: 8,
                    },
                  }}
                  isTableMinWidth={false}
                  columns={columns}
                  data={apiGroupListData.filter((item) => item.check)}
                  headColor={false}
                />
                <YatungInput
                  value={searchGroup}
                  onChange={(e: any) => setSearchGroup(e.target.value)}
                  position="start"
                  icon={<SearchIcon sx={{ fontSize: 36, ml: 2 }} />}
                  style={{ fontSize: 28 }}
                />
                <Stack
                  sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'gray',
                    minHeight: 200,
                    maxHeight: 200,
                    borderRadius: 2,
                    maxWidth: 630,
                    px: 3,
                    py: 1,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: 8,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#003087',
                      borderRadius: 8,
                    },
                  }}
                >
                  {searchApiGroupListByInput?.map((item: any) => (
                    <Button
                      key={item.id}
                      sx={{ fontSize: 20, color: 'gray', borderBottom: 1, justifyContent: 'space-between' }}
                      disabled={item.check}
                      onClick={() => {
                        setApiGroupListData((prev) =>
                          prev.map((_data) => (_data.id === item.id ? { ..._data, check: true } : _data)),
                        );
                      }}
                    >
                      {`${item.code} ${item.name}`}
                      {item.check ? <TickIcon /> : <></>}
                    </Button>
                  ))}
                  <YatungConfirmAlert
                    isShowAlert={isShowAlert}
                    onClose={() => {
                      setIsShowAlert(false);
                      setTimeout(() => setAlertText(''), 300);
                    }}
                    alertText={alertText}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      )}
    </>
  );
}
