import YatungDialog from '@Src/_basic/components/YatungDialog';
import { Options } from '@Src/_basic/components/YatungSelect';
import EditV2 from '@Src/_basic/icons/EditV2';
import { PutVehicleData, VehicleData } from '@Src/_basic/object/VehicleType';
import { VehicleAPi } from '@Src/_basic/protocol/Vehicle/VehicleAPi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import VehicleManagementUpdateField from './components/VehicleManagementUpdateField';
import VehicleManagementUpdateSubmit from './components/VehicleManagementUpdateSubmit';

interface Props {
  modalOpen: boolean;
  selectedVehicleData: VehicleData;
  handleCloseModal: () => void;
  typeOption: Options[];
  factoryOption: Options[];
  getVehicleData: () => void;
}

const vahicleSchema = Yup.object().shape({
  plateNumber: Yup.string().required('請輸入車牌號碼'),
  type: Yup.number().typeError('請選擇類別').min(1, '請選擇至少一個類別').required('請選擇類別'),
  factory: Yup.number().typeError('請選擇廠別').min(1, '請選擇至少一個廠別').required('請選擇廠別'),
});

function VehicleManagementUpdateCard({
  modalOpen,
  selectedVehicleData,
  getVehicleData,
  handleCloseModal,
  typeOption,
  factoryOption,
}: Props) {
  const { t: i18T } = useTranslation();

  const initialValues: PutVehicleData = useMemo(
    () => ({
      id: selectedVehicleData.id,
      plateNumber: selectedVehicleData.plateNumber,
      type: selectedVehicleData.type.map((item) => item.id),
      factory: selectedVehicleData.area.reduce((acc, cur) => {
        return [...acc, ...cur.factories.map((item) => item.id)];
      }, [] as Array<number>),
    }),
    [selectedVehicleData],
  );

  const handleSubmit = useCallback(
    (request: PutVehicleData) => {
      VehicleAPi.PutVehicle(request, () => {
        handleCloseModal();
        getVehicleData();
      });
    },
    [handleCloseModal, getVehicleData],
  );

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={modalOpen}
        handleClose={handleCloseModal}
        icon={<EditV2 sx={{ width: 40, height: 40, mr: 1, alignSelf: 'center' }} />}
        title={i18T('USERSMANAGEMENU.VEHICLE_MANAGEMENT.UPDATE_VEHICLE')}
        body={
          <Formik
            initialValues={initialValues}
            validationSchema={vahicleSchema}
            onSubmit={(request: PutVehicleData) => handleSubmit(request)}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <VehicleManagementUpdateField
                    values={values}
                    setValues={setFieldValue}
                    typeOption={typeOption}
                    factoryOption={factoryOption}
                  />
                  <VehicleManagementUpdateSubmit handleCloseModal={handleCloseModal} />
                </Form>
              );
            }}
          </Formik>
        }
      />
    </Grid>
  );
}

export default memo(VehicleManagementUpdateCard);
