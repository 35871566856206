import axios, { AxiosError, AxiosResponse } from 'axios';
import { Response } from '@Src/_basic/object/ApiType';
import { SourcesProtocol } from './SourceProtocol';
import { PutMaterialNumberRequest, PostMaterialNumberRequest } from '@Src/_basic/object/MaterialNumberManageType';
import { SearchSourcesParams, SourceData } from '@Src/_basic/object/SourceType';
import { Api } from '../Api';

export class SourceApi {
  // 取得所有來源
  public static getAllSources(
    onSuccess?: (data: Array<SourceData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(SourcesProtocol.ALL_SOURCES, undefined, onSuccess, onFailed, onFinally);
  }

  public static getSourcesBySearch(
    params?: SearchSourcesParams,
    onSuccess?: (data: Array<SourceData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SourcesProtocol.ALL_SOURCES, config, onSuccess, onFailed, onFinally);
  }

  // 更新來源
  public static putSource(
    request: PutMaterialNumberRequest,
    onSuccess?: (data: SourceData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(SourcesProtocol.SOURCES_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增來源
  public static postSource(
    request: PostMaterialNumberRequest,
    onSuccess?: (data: SourceData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SourcesProtocol.SOURCES_URL, request, undefined, onSuccess, onFailed, onFinally);
  }
}
