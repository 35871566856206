import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import BackButton from '@Src/_basic/icons/BackButton';
import { MaterialReceiptsData, PageableMaterialReceiptsData } from '@Src/_basic/object/MaterialReceiptsType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { MaterialReceiptsApi } from '@Src/_basic/protocol/materialReceipt/MaterialReceiptsApi';
import { Grid, Pagination, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import FeedMachineStatusTable from '../components/FeedMachineStatusTable';

export default function FeedMachineStatusDetailScreen() {
  const { t: i18T } = useTranslation();
  const { state } = useLocation();
  const [machineStatusDetail, setMachineStatusDetail] = useState<Array<MaterialReceiptsData>>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalCount: 0,
  });

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };
  useEffect(() => {
    const params = {
      storageId: state.storageId,
      startTime: new Date().setHours(0, 0, 0, 0),
      endTime: new Date().setHours(23, 59, 59, 999),
    };
    MaterialReceiptsApi.getPageableMaterialReceiptsBySearch(
      { ...params, page: pageInfo.page, pageSize: pageInfo.pageSize },
      (data: PageableMaterialReceiptsData) => {
        setMachineStatusDetail(data.receipts);
        setPageInfo({
          ...pageInfo,
          page: pageInfo.page,
          total: data.maxPage,
          totalCount: data.elements,
        });
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [pageInfo.page]);
  const handleGoBack = () => {
    navigate('/UsersManagePage/feedMachineStatus', {
      state: { areaId: state.areaId, factoryId: state.factoryId, mixerId: state.mixerId, typeId: state.typeId },
    });
  };
  return (
    <>
      <YatungPage
        title={i18T('USERSMANAGEMENU.FEED_MACHINE_STATUS.ROUTE_TITLE')}
        contentBgColor="#ffffff"
        body={
          <>
            <Stack direction="row" justifyContent={'flex-end'} spacing={2} sx={{ my: 1 }}>
              <YatungButton
                text={i18T('GLOBAL.BACK')}
                startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
                onClick={handleGoBack}
                color="green"
                sx={{ alignSelf: 'center' }}
              />
            </Stack>
            <FeedMachineStatusTable loading={loading} machineStatusDetail={machineStatusDetail} pageInfo={pageInfo} />
            <Grid container alignItems={'center'} justifyContent={'center'} item xs={12} sx={{ p: 1 }}>
              <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
            </Grid>
          </>
        }
      />
    </>
  );
}
