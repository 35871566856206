import {
  CustomStandardV2SpecificationDetailsResponse,
  SearchCustomStandardSpecificationDetailsRequest,
} from '@Src/_basic/object/CustomStandardType';
import { Response } from '../../object/ApiType';
import { Api } from '../Api';
import { CustomStandardV2Protocol } from './CustomStandardV2Protocol';

export class CustomStandardV2Api {
  // 取得符合條件的CNS規範規格明細
  public static getCustomStandardV2SpecificationDetailsBySearch(
    params: SearchCustomStandardSpecificationDetailsRequest,
    onSuccess?: (_data: Array<CustomStandardV2SpecificationDetailsResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(
      CustomStandardV2Protocol.SEARCH_CUSTOM_STANDARD_V2_SPECIFICATION_DETAILS,
      config,
      onSuccess,
      onFailed,
      onFinally,
    );
  }
}
