import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function AccountName(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 47 42">
      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="39" viewBox="0 0 45 39" fill="none">
        <path d="M22.5069 28.5544L18.3281 34.1258L22.5069 37.6083L26.6853 34.1258L22.5069 28.5544Z" fill="#7C878E" />
        <path d="M22.5069 25.0723H18.3283L22.5069 29.2508L26.6854 25.0723H22.5069Z" fill="#7C878E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42 34.125C42 27.6413 29.0081 24.375 22.5 24.375C15.9919 24.375 3 27.6413 3 34.125V39H42V34.125ZM6.07634 30.8898C4.93752 31.9408 4.39286 33.0156 4.39286 34.125V37.6071H16.4638L13.0384 27.331C12.2842 27.565 11.5423 27.8252 10.8274 28.1116C8.88168 28.891 7.22463 29.83 6.07634 30.8898ZM27.068 37.6071L30.622 26.9454C27.6767 26.1613 24.6821 25.7679 22.5 25.7679C20.3179 25.7679 17.3233 26.1613 14.378 26.9454L17.932 37.6071H27.068ZM28.5362 37.6071H40.6071V34.125C40.6071 33.0156 40.0625 31.9408 38.9237 30.8898C37.7754 29.83 36.1183 28.891 34.1726 28.1116C33.4577 27.8252 32.7158 27.565 31.9616 27.331L28.5362 37.6071Z"
          fill="#7C878E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.25 9.75C32.25 15.1369 27.8869 19.5 22.5 19.5C17.1131 19.5 12.75 15.1369 12.75 9.75C12.75 4.36312 17.1131 0 22.5 0C27.8869 0 32.25 4.36312 32.25 9.75ZM22.5 18.1071C27.1176 18.1071 30.8571 14.3676 30.8571 9.75C30.8571 5.13238 27.1176 1.39286 22.5 1.39286C17.8824 1.39286 14.1429 5.13238 14.1429 9.75C14.1429 14.3676 17.8824 18.1071 22.5 18.1071Z"
          fill="#7C878E"
        />
      </svg>
    </SvgIcon>
  );
}
