import { Response } from '@Src/_basic/object/ApiType';
import {
  GetMaterialInspectionParams,
  MaterialInspectionArea,
  MaterialInspectionDetial,
  MaterialInspectionFactory,
} from '@Src/_basic/object/MaterialInspectionType';
import { Api } from '../Api';
import { ExptMaterialInspectionProtocol } from './ExptMaterialInspectionProtocol';

export class ExptMaterialInspectionApi {
  public static getExptMaterialInspectionByArea(
    params: GetMaterialInspectionParams,
    onSuccess?: (data: Array<MaterialInspectionArea>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ExptMaterialInspectionProtocol.GET_EXPT_MATERIAL_BY_AREA, config, onSuccess, onFailed, onFinally);
  }
  public static getExptMaterialInspectionByFactory(
    params: GetMaterialInspectionParams,
    onSuccess?: (data: Array<MaterialInspectionFactory>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ExptMaterialInspectionProtocol.GET_EXPT_MATERIAL_BY_FACTORY, config, onSuccess, onFailed, onFinally);
  }
  public static getPaginationReqMaterialInspectionView(
    params: GetMaterialInspectionParams,
    onSuccess?: (data: MaterialInspectionDetial) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(ExptMaterialInspectionProtocol.GET_PAGINATION_MATERIAL_VIEW, config, onSuccess, onFailed, onFinally);
  }
}
