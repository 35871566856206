import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../../Api';
import { UsersProtocol } from './UsersProtocol';
import {
  CreateAccountRequest,
  CreateAccountResponse,
  EditAccountRequest,
  RightsRequest,
  UsersData,
} from '@Src/_basic/object/AccountRightType';

export class UsersApi {
  // 搜尋使用者
  public static getAllUser(
    params: RightsRequest,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(UsersProtocol.GET_ALL_USER, config, onSuccess, onFailed, onFinally);
  }

  // 以ID搜尋使用者
  public static getUserById(
    id: string,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(UsersProtocol.USER_URL, config, onSuccess, onFailed, onFinally);
  }

  // 建立使用者帳號
  public static postCreateUser(
    request: CreateAccountRequest,
    onSuccess?: (data: CreateAccountResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(UsersProtocol.USER_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 編輯使用者帳號
  public static putEditUser(
    request: EditAccountRequest,
    onSuccess?: (data: CreateAccountResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(UsersProtocol.USER_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得所有AIV的使用者帳號
  public static getUsersOnlyAIV(
    factoryId: number,
    onSuccess?: (data: Array<UsersData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        factoryId: factoryId,
      },
    };
    Api.get(UsersProtocol.GET_USERS_ONLY_AIV, config, onSuccess, onFailed, onFinally);
  }
}
