import YatungButton from '@Src/_basic/components/YatungButton';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungTable from '@Src/_basic/components/YatungTable';
import { MaterialFactoryCodeData } from '@Src/_basic/object/MaterialFactoryCodeType';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack } from '@mui/material';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StatusColumnFormatter from './columnFormatter/StatusFormatter';

import EditIcon from '@Src/_basic/icons/Edit';

interface Props {
  materialCodeList: Array<MaterialFactoryCodeData>;
  // eslint-disable-next-line no-unused-vars
  handleCodeSelect: (row: MaterialFactoryCodeData) => void;
  // eslint-disable-next-line no-unused-vars
  changeRemovedHandler: (row: MaterialFactoryCodeData) => void;
}

const FactoryMaterialCodeTable = ({ materialCodeList, handleCodeSelect, changeRemovedHandler }: Props) => {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [removedItem, setRemovedItem] = useState<MaterialFactoryCodeData>();

  return (
    <>
      <YatungTable
        loading={actionLoading}
        data={materialCodeList}
        columns={[
          {
            text: '#',
            dataField: 'index',
            width: '90px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE'),
            dataField: 'typeName',
            width: '90px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME'),
            dataField: 'supplierName',
            width: '240px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.SOURCE'),
            dataField: 'sourceName',
            width: '150px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIFICATION'),
            dataField: 'specificationName',
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.ATTRIBUTE'),
            dataField: 'attributeName',
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.MATERIAL_CODE'),
            dataField: 'code',
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.STATUS'),
            dataField: 'removed',
            width: '120px',
            formatter: StatusColumnFormatter,
            formatExtraData: {
              openText: i18T('GLOBAL.OPENING'),
              closeText: i18T('GLOBAL.STOPING'),
            },
          },
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            width: '120px',
            formatter: ({ row }) => {
              return (
                <Stack direction="row" justifyContent="space-between">
                  <YatungButton
                    text={i18T('GLOBAL.UPDATE')}
                    startIcon={<EditIcon />}
                    width={90}
                    onClick={() => handleCodeSelect(row)}
                    color="yellow"
                  />
                  {row.removed ? (
                    <YatungButton
                      text={i18T('GLOBAL.OPEN')}
                      startIcon={<EditIcon />}
                      width={90}
                      onClick={() => {
                        setIsShowAlert(true);
                        setRemovedItem(row);
                      }}
                      color="green"
                    />
                  ) : (
                    <YatungButton
                      text={i18T('GLOBAL.STOP')}
                      startIcon={<EditIcon />}
                      width={90}
                      onClick={() => {
                        setIsShowAlert(true);
                        setRemovedItem(row);
                      }}
                      color="light_red"
                    />
                  )}
                </Stack>
              );
            },
          },
        ]}
      />
      {!!removedItem && (
        <YatungConfirmAlert
          isShowAlert={isShowAlert}
          onClose={() => setIsShowAlert(false)}
          onOk={() => changeRemovedHandler(removedItem)}
          alertText={`${i18T('APPLICATIONSMANAGEMENT.FACTORY_MATERIAL_CODE_MANAGE.CONFIRM_CHANGE')}?`}
        />
      )}
    </>
  );
};

export default memo(FactoryMaterialCodeTable);
