import React from 'react';
import { Stack } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import { OrganiztionsData } from '@Src/_basic/object/AccountRightType';

export type ExtraData = {
  onGoToUpdatePage: (id: number, organiztionName: string) => void;
  onGoToDeletePage: (id: number, organiztionName: string) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: OrganiztionsData;
  formatExtraData: ExtraData;
}
const editImage = '/media/images/edit.png';
const deleteImage = '/media/images/delete.png';

export default function ActionColumnFormatter(props: Props) {
  const { formatExtraData, row } = props;
  return (
    <Stack spacing={2} direction="row">
      <YatungButton
        text={formatExtraData.getButtonText('編輯')}
        variant={'contained'}
        type={'button'}
        size={'large'}
        startIcon={<YatungImg img={editImage} />}
        onClick={() => {
          formatExtraData.onGoToUpdatePage(row.id, row.organiztionName);
        }}
        color={'yellow'}
      />
      <YatungButton
        text={formatExtraData.getButtonText('刪除')}
        variant={'contained'}
        type={'button'}
        color={'red'}
        size={'large'}
        startIcon={<YatungImg img={deleteImage} />}
        onClick={() => {
          formatExtraData.onGoToDeletePage(row.id, row.organiztionName);
        }}
      />
    </Stack>
  );
}
