import YatungButton from '@Src/_basic/components/YatungButton';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungInput from '@Src/_basic/components/YatungInput';
import { MixerDetailData } from '@Src/_basic/object/MixerTypes';
import { Grid, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: MixerDetailData;
  index: number;
  inuptValue: string;
  onChangeStatus: () => void;
  onInput: (value: string) => void;
}
export default function UpdateMixerInput(props: Props) {
  const { t: i18T } = useTranslation();
  const { value, inuptValue, index, onChangeStatus, onInput } = props;

  const handleInput = (e: SelectChangeEvent) => {
    onInput?.(e.target.value);
  };
  return (
    <>
      <Grid container item xs={12} sx={{ my: 3 }}>
        <Grid container item xs={4}>
          <YatungFormLabel label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.MIXER') + `(${index + 1})：`} />
        </Grid>
        <Grid container item xs={5}>
          <YatungInput
            onBlur={handleInput}
            type="text"
            sx={{ width: '260px' }}
            defaultValue={inuptValue}
            position={'end'}
            textColor={value.removed ? '#CB333B' : '#039900'}
            text={value.removed ? i18T('GLOBAL.CLOSING') : i18T('GLOBAL.RUNNING')}
          />
        </Grid>
        <Grid container item xs={2} sx={{ ml: 1 }}>
          <YatungButton
            text={value.removed ? i18T('GLOBAL.OPENS') : i18T('GLOBAL.CLOSE')}
            color={value.removed ? 'green' : 'red'}
            onClick={onChangeStatus}
          />
        </Grid>
      </Grid>
    </>
  );
}
