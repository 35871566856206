import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import YatungTextSelect, { TextOptions } from '@Src/_basic/components/YatungTextSelect';
import { EquipmentData, FileData, UpdateEquipmentRequest } from '@Src/_basic/object/EquipmentManagementType';
import { useEquipment } from '@Src/redux/equipment/equipmentAction';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEquipmentUI } from '..';
import UploadFileCard from './UploadFileCard';

const initPeriodValueOptions = [
  {
    text: '1',
    value: 1,
  },
  {
    text: '2',
    value: 2,
  },
  {
    text: '3',
    value: 3,
  },
  {
    text: '4',
    value: 4,
  },
  {
    text: '5',
    value: 5,
  },
  {
    text: '6',
    value: 6,
  },
  {
    text: '7',
    value: 7,
  },
  {
    text: '8',
    value: 8,
  },
  {
    text: '9',
    value: 9,
  },
  {
    text: '10',
    value: 10,
  },
];

const initPeriodUnitValueOptions = [
  {
    text: '年',
    value: 'YEAR',
  },
  {
    text: '月',
    value: 'MONTH',
  },
  {
    text: '周',
    value: 'WEEK',
  },
];

const initUpdateNeedMaintenanceOptions = [
  {
    text: '需維護',
    value: true,
  },
  {
    text: '正常',
    value: false,
  },
];

interface Props {
  values: UpdateEquipmentRequest | any;
  initialEquipmentTimeValue: UpdateEquipmentRequest | any;
  userGroupsData: any;
  entityOptions: TextOptions[];
  updatePrioedNumber: number | undefined;
  setUpdatePrioedNumber: (e: number) => void;
  updateFactory: number | undefined;
  setUpdateFactory: (e: number) => void;
  updatePrioedUnit: string | undefined;
  setUpdtaePrioedUnit: (e: string) => void;
  updateKeeper: string | undefined;
  setUpdateKeeper: (e: string) => void;
  updateNeedMaintenance: boolean | undefined;
  setUpdateNeedMaintenance: (e: boolean) => void;
  onUpdateGoBackButtonClick: () => void;
  equipmentByIdData: EquipmentData | undefined;
  fileName: FileData[];
  handleChange: (event: any) => void;
  files: FileList | undefined;
  refreshUploadFileData: () => void;
}

export default function EquipmentUpdateField(props: Props) {
  const {
    values,
    initialEquipmentTimeValue,
    onUpdateGoBackButtonClick,
    updateFactory,
    setUpdateFactory,
    userGroupsData,
    entityOptions,
    updatePrioedNumber,
    setUpdatePrioedNumber,
    updatePrioedUnit,
    setUpdtaePrioedUnit,
    updateKeeper,
    setUpdateKeeper,
    updateNeedMaintenance,
    setUpdateNeedMaintenance,
    equipmentByIdData,
    fileName,
    handleChange,
    files,
    refreshUploadFileData,
  } = props;

  const { formDataDispatch } = useUpdateEquipmentUI();
  const { deleteEquipmentReport } = useEquipment();

  const list = [''];

  const saveTimeout = new Map(); //建立字典來查詢

  if (files) {
    for (let i = 0; i < files?.length; i++) {
      list.push(i ? `、${files[i]?.name}` : files[i]?.name); //紀錄選擇的檔案
    }
  }

  const handleStartDateChange = (value: any) => {
    handleFormDataChange('notificationTime')(value);
  };

  const handleFormDataChange = (field: string) => async (value: unknown) => {
    if (formDataDispatch) {
      formDataDispatch({ type: 'updateValue', payload: { [field]: value } });
    }
  };

  const handleDeleteFileButtonClick = (id: number | null) => {
    deleteEquipmentReport(id, () => {
      refreshUploadFileData();
    });
  };

  const fileSort = fileName.sort((x, y) => x.uploadFileName?.localeCompare(y.uploadFileName, 'zh-CN'));
  //讓取到的檔案資料依照中文排列

  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack flexDirection={'column'} sx={{ mt: 1 }}>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.EQUIPMENT')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <Field
            id="name"
            name="name"
            type="text"
            component={YatungFormInput}
            value={values?.name}
            sx={{ width: '535px' }}
            required
          />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.EQUIPMENT_NUMBERS')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <Field
            id="propertyCode"
            name="propertyCode"
            type="text"
            component={YatungFormInput}
            value={values?.propertyCode}
            sx={{ width: '535px' }}
            required
          />
        </Stack>
        <div style={{ display: 'flex' }}>
          <div style={{ minWidth: '260px' }}>
            <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.MAINTENANCETIME')} />
          </div>

          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.FACTORY_TYPE')} />
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.UPDATE_STATE')} />
        </div>

        <Stack flexDirection={'row'} sx={{ my: 1 }}></Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <div style={{ display: 'flex' }}>
            <YatungSelect
              options={initPeriodValueOptions}
              value={updatePrioedNumber ? updatePrioedNumber : values.periodNumber}
              placeholder={''}
              onChange={(e) => {
                setUpdatePrioedNumber(e.target.value as number);
              }}
              style={{ maxWidth: '130px' }}
              width={'130px'}
            />
            <YatungTextSelect
              options={initPeriodUnitValueOptions}
              value={updatePrioedUnit ? updatePrioedUnit : values.periodUnit}
              onChange={(e) => {
                setUpdtaePrioedUnit(e.target.value as string);
              }}
              style={{ maxWidth: '130px', marginRight: '10px', paddingTop: '2px', paddingBottom: '2px' }}
              width={'130px'}
            />
          </div>

          <div style={{ display: 'flex' }}>
            <YatungSelect
              options={userGroupsData?.factories}
              value={updateFactory ? updateFactory : values.factoryId}
              onChange={(e) => {
                setUpdateFactory(e.target.value as number);
              }}
              style={{ minWidth: '130px' }}
              width={'130px'}
            />
            <YatungSelect
              options={initUpdateNeedMaintenanceOptions}
              value={updateNeedMaintenance}
              onChange={(e) => {
                setUpdateNeedMaintenance(e.target.value as boolean);
              }}
              style={{ maxWidth: '130px', marginRight: '10px' }}
              width={'130px'}
            />
          </div>
        </Stack>
        <Stack flexDirection={'column'} justifyContent={'center'}>
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold', pl: 1 }}>
            目前狀態：{equipmentByIdData?.needMaintenance === false ? '正常' : '需維護'}
          </Typography>
        </Stack>

        <Stack flexDirection={'column'} justifyContent={'center'}>
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold', pl: 1 }}>
            下次校驗時間：{moment(equipmentByIdData?.nextMaintenanceTime).format('YYYY/MM/DD HH:mm')}
          </Typography>
        </Stack>

        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.NOTIFICATION_TIME')} />
        </Stack>
        <Grid item xs style={{ minWidth: '535px', marginRight: '20px' }}>
          <YatungDateSelect
            label=""
            fullWidth
            value={initialEquipmentTimeValue?.notificationTime}
            maxDate={equipmentByIdData?.nextMaintenanceTime}
            disablePast
            onChange={(date) => {
              date && handleStartDateChange(new Date(date).getTime());
            }}
          />
        </Grid>

        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.KEEPERNAME')} />
        </Stack>
        {values.keeper !== undefined && (
          <Stack flexDirection={'row'} sx={{ my: 1 }}>
            <YatungTextSelect
              options={entityOptions}
              value={updateKeeper ? updateKeeper : values.keeper}
              onChange={(e) => {
                setUpdateKeeper(e.target.value as string);
              }}
              style={{ maxWidth: '535px', marginRight: '10px' }}
              width={'535px'}
            />
          </Stack>
        )}

        {fileName.length === 0 ? null : ( //存在檔案時才顯示
          <div style={{ border: '1px solid gray', borderRadius: '10px', paddingTop: '10px' }}>
            <div style={{ fontWeight: 'bold' }}>已擁有的檔案：</div>
            {fileSort.map((fileUploadName, index) => {
              const times = saveTimeout.get(fileUploadName.uploadFileName); //建立一個Map字典來記錄陣列中元素使用過幾次，進而幫fileUploadName編號，由新到舊，也能夠下載檔案

              if (saveTimeout.get(fileUploadName.uploadFileName)) {
                saveTimeout.set(fileUploadName.uploadFileName, times + 1);
                return (
                  <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', textAlign: 'center', verticalAlign: 'middle', lineHeight: '40px' }}>
                      <Button href={fileUploadName.download}>{`${fileUploadName.uploadFileName}(${times + 1})`}</Button>
                    </div>
                    <Button
                      style={{ display: 'flex', textAlign: 'center', verticalAlign: 'middle', lineHeight: '20px' }} //讓使用者預覽已存在檔案，可刪除
                      onClick={() => handleDeleteFileButtonClick(fileUploadName.id)}
                    >
                      X
                    </Button>
                  </div>
                );
              } else {
                saveTimeout.set(fileUploadName.uploadFileName, 1);
                return (
                  <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      key={index}
                      style={{ display: 'flex', textAlign: 'center', verticalAlign: 'middle', lineHeight: '40px' }}
                    >
                      <Button href={fileUploadName.download}>{`${fileUploadName.uploadFileName}`}</Button>
                    </div>
                    <Button
                      style={{ display: 'flex', textAlign: 'center', verticalAlign: 'middle', lineHeight: '20px' }}
                      onClick={() => handleDeleteFileButtonClick(fileUploadName.id)}
                    >
                      X
                    </Button>
                  </div>
                );
              }
            })}
          </div>
        )}

        {files && (
          <Stack flexDirection={'row'} sx={{ my: 1, mt: 2 }}>
            <div style={{ fontWeight: 'bold' }}>新增的檔案：</div>{' '}
            {/* 讓使用者預覽點擊uploadFileCard後已選擇檔案，可多選，暫未做出刪除功能*/}
            {list}
          </Stack>
        )}
        <Stack style={{ marginTop: '5px' }}>
          <UploadFileCard handleChange={handleChange} />
        </Stack>

        <ErrorMessage
          name={'typeIds'}
          render={(msg) => (
            <Stack flexDirection={'row'} sx={{ ml: 1, mt: 1 }}>
              <Typography sx={{ fontSize: 14, color: '#d32f2f' }}>{msg}</Typography>
            </Stack>
          )}
        />

        <Stack
          spacing={3}
          sx={{ bgcolor: 'white', minWidth: 500, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
          display="flex"
          flexDirection="column"
        >
          <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <YatungButton
              text={i18T('SAMPLEMANAGEMENT.CANCEL')}
              onClick={onUpdateGoBackButtonClick}
              sx={{
                color: '#ffffff',
                bgcolor: '#CB333B',
                fontSize: '24px',
                width: 120,
                mr: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#CB333B',
                },
              }}
            />
            <YatungButton
              text={i18T('SAMPLEMANAGEMENT.COMPLETE')}
              type="submit"
              sx={{
                border: '1px solid #fff',
                color: '#ffffff',
                bgcolor: '#002F8C',
                fontSize: '24px',
                width: 120,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#002F8C',
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
