import {
  ChildrenTypography,
  TestCardContainer,
  TestVerifyResultStyles,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest, PutBaseTestRequest } from '@Src/_basic/object/TestType';
import { ConcreteStrValues } from '@Src/_basic/object/test/concrete/ConcreteApiType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: AllTest;
  allTable?: boolean;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

type EditStrRequestDetails = {
  id: number;
  compStr: number;
};

type EditStrRequest = PutBaseTestRequest & {
  id: number;
  details: EditStrRequestDetails[];
};

function ExportConcreteStrengthTable({ testDetail, allTable, canEdit, afterUpdateTest }: Props) {
  const { t: i18T } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);

  const [strDetail, setStrDetail] = useState<ConcreteStrValues>();

  const isLegalPassed = useMemo(() => {
    if (strDetail) {
      const verifyResult: Array<boolean> = strDetail?.details.map((item) =>
        isLegalStandardPassed(item.verifyPassedResults),
      );

      return verifyResult.every((item) => item);
    } else {
      return true;
    }
  }, [strDetail]);

  const isCustomPassed = useMemo(() => {
    if (strDetail) {
      const verifyResult: Array<boolean> = strDetail?.details.map((item) =>
        isCustomStandardPassed(item.verifyCustomResults),
      );

      return verifyResult.every((item) => item);
    } else {
      return true;
    }
  }, [strDetail]);

  const isCustomRejected = useMemo(() => {
    if (strDetail) {
      const verifyResult: Array<boolean> = strDetail?.details.map((item) => isRejected(item.verifyCustomResults));

      return verifyResult.some((item) => item);
    } else {
      return false;
    }
  }, [strDetail]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const initialValues: Record<string, number> = useMemo(() => {
    const _initialValues: Record<string, number> = {};

    if (allTable) {
      for (let i = 0; i <= testDetail?.values?.length - 1; i++) {
        for (let j = 0; j <= testDetail?.values[i].details.length - 1; j++) {
          _initialValues[`${testDetail?.values[i].exptId}_${testDetail?.values[i].details[j].id}`] =
            testDetail?.values[i].details[j].compStr;
        }
      }
    }

    return _initialValues;
  }, [testDetail, allTable]);

  const validationSchema = useMemo(() => {
    const validationSchemaObject: Record<string, typeof basicNumberSchema> = {};

    if (allTable) {
      for (let i = 0; i <= testDetail?.values?.length - 1; i++) {
        for (let j = 0; j <= testDetail?.values[i].details.length - 1; j++) {
          validationSchemaObject[`${testDetail?.values[i].exptId}_${testDetail?.values[i].details[j].id}`] =
            basicNumberSchema;
        }
      }
    }

    return Yup.object().shape(validationSchemaObject);
  }, [testDetail, allTable]);

  useEffect(() => {
    const findSameAgeDetails = testDetail?.values?.find((item) => item.age === testDetail.age);
    setStrDetail(findSameAgeDetails);
  }, [testDetail, allTable]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const _details: Record<string, EditStrRequestDetails[]>[] = [];
      Object.entries(values).forEach(([key, value]) => {
        const [exptId, id] = key.split('_');
        const _exist = _details.findIndex((item) => item[exptId]);
        if (_exist === -1) {
          _details.push({
            [exptId]: [{ id: Number(id), compStr: Number(value) }],
          });
        } else {
          _details[_exist][exptId].push({ id: Number(id), compStr: Number(value) });
        }
      });

      _details.forEach((item, index) => {
        const _id = Object.keys(item)[0];
        setTimeout(() => {
          TestApi.putTest<EditStrRequest>(
            {
              exptItemCode: testDetail.exptItemCode,
              fields: {
                id: Number(_id),
                completed: true,
                reportComment: testDetail?.reportComment || testDetail?.memo,
                details: item[_id],
              },
            },
            (_data) => {
              afterUpdateTest(_data);
              setEdit(false);
            },
            (err) => console.log(err),
          );
        }, index * 1000);
      });
    },
  });

  const theForm = () => {
    return (
      <>
        {allTable ? (
          testDetail?.values?.map((item) => (
            <TestCardContainer key={item.age}>
              <Stack flexDirection="row" mt={2.5} mb={2} justifyContent="center">
                <Typography
                  sx={{
                    color: '#003087',
                    bgcolor: '#dcfbfb',
                    p: 1,
                    px: 2,
                    borderRadius: '10%',
                    fontWeight: 900,
                    fontSize: 22,
                  }}
                >
                  {i18T(`TEST.CONCRETE_STR.AGE_SPECIMEN_COMP_STR`, { day: item?.age })}
                </Typography>
              </Stack>
              {item.details.map((_item, index) => (
                <Stack
                  key={index}
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  padding={'10px 10px 5px'}
                  sx={{ borderBottom: '1px solid gray' }}
                >
                  <ColFieldInfo title={`${i18T(`TEST.CONCRETE_STR.CONCRETE_STR`, { number: index + 1 })}(kgf/cm²)`}>
                    {edit ? (
                      <LabelInput
                        key={index}
                        error={
                          !!(
                            formik.touched[`${item.exptId}_${_item.id}`] && formik.errors[`${item.exptId}_${_item.id}`]
                          )
                        }
                        value={formik.values[`${item.exptId}_${_item.id}`]}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue(`${item.exptId}_${_item.id}`, e.target.value)
                        }
                      >
                        {i18T(`TEST.SPECIMEN_STR.SPECIMEN_STR`, { index: index + 1 })}
                      </LabelInput>
                    ) : (
                      <ChildrenTypography>{_item?.compStr}</ChildrenTypography>
                    )}
                  </ColFieldInfo>
                  <ColFieldInfo title={`${i18T(`TEST.CONCRETE_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                    <ChildrenTypography
                      style={{
                        ...(isLegalStandardPassed(_item.verifyPassedResults) &&
                        isCustomStandardPassed(_item.verifyCustomResults) &&
                        !isRejected(_item.verifyCustomResults)
                          ? TestVerifyResultStyles.normal
                          : TestVerifyResultStyles.abnormal),
                      }}
                    >
                      {_item?.designStrRatio?.toFixed(1)
                        ? _item?.designStrRatio?.toFixed(1)
                        : testDetail.designStr
                        ? `${(_item?.compStr / testDetail.designStr) * 100}%`
                        : '尚無資料'}
                    </ChildrenTypography>
                  </ColFieldInfo>
                </Stack>
              ))}

              <Stack
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                padding={'10px 10px 5px'}
                sx={{ borderBottom: '1px solid gray' }}
              >
                <ColFieldInfo title={`${i18T(`TEST.CONCRETE_STR.AVERAGE_STR`)}(kgf/cm²)`}>
                  <ChildrenTypography>{item?.avgCompStr?.toFixed(1)}</ChildrenTypography>
                </ColFieldInfo>
                <ColFieldInfo title={`${i18T(`TEST.CONCRETE_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                  <ChildrenTypography>
                    {item?.designStrRatio?.toFixed(1)
                      ? item?.designStrRatio?.toFixed(1)
                      : testDetail.designStr
                      ? `${(item?.avgCompStr / testDetail.designStr) * 100}%`
                      : '尚無資料'}
                  </ChildrenTypography>
                </ColFieldInfo>
              </Stack>
            </TestCardContainer>
          ))
        ) : (
          <>
            <TestCardContainer>
              <Stack flexDirection="row" mt={2.5} mb={2} justifyContent="center">
                <Typography
                  sx={{
                    color: '#003087',
                    bgcolor: '#dcfbfb',
                    p: 1,
                    px: 2,
                    borderRadius: '10%',
                    fontWeight: 900,
                    fontSize: 22,
                  }}
                >
                  {i18T(`TEST.CONCRETE_STR.AGE_SPECIMEN_COMP_STR`, { day: testDetail.age })}
                </Typography>
              </Stack>
              {strDetail &&
                strDetail.details.map((item, index) => (
                  <Stack
                    key={index}
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    padding={'10px 10px 5px'}
                    sx={{ borderBottom: '1px solid gray' }}
                  >
                    <ColFieldInfo title={`${i18T(`TEST.CONCRETE_STR.CONCRETE_STR`, { number: index + 1 })}(kgf/cm²)`}>
                      <ChildrenTypography>{item?.compStr}</ChildrenTypography>
                    </ColFieldInfo>
                    <ColFieldInfo title={`${i18T(`TEST.CONCRETE_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography
                        style={{
                          ...(isLegalStandardPassed(item.verifyPassedResults) &&
                          isCustomStandardPassed(item.verifyCustomResults) &&
                          !isRejected(item.verifyCustomResults)
                            ? TestVerifyResultStyles.normal
                            : TestVerifyResultStyles.abnormal),
                        }}
                      >
                        {item?.designStrRatio?.toFixed(1) ?? '尚無資料'}
                      </ChildrenTypography>
                    </ColFieldInfo>
                  </Stack>
                ))}

              {strDetail && (
                <Stack
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  padding={'10px 10px 5px'}
                  sx={{ borderBottom: '1px solid gray' }}
                >
                  <ColFieldInfo title={`${i18T(`TEST.CONCRETE_STR.AVERAGE_STR`)}(kgf/cm²)`}>
                    <ChildrenTypography>{strDetail?.avgCompStr?.toFixed(1)}</ChildrenTypography>
                  </ColFieldInfo>
                  <ColFieldInfo title={`${i18T(`TEST.CONCRETE_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                    <ChildrenTypography>{strDetail?.designStrRatio?.toFixed(1) ?? '尚無資料'}</ChildrenTypography>
                  </ColFieldInfo>
                </Stack>
              )}
            </TestCardContainer>
            {strDetail && (
              <TestCardContainer>
                <TestTable
                  headerData={{
                    values: [i18T(`TEST.BASE.DETECTION`)],
                  }}
                  rows={[
                    isLegalPassed && isCustomPassed && !isCustomRejected
                      ? {
                          titles: verifyResultText,
                          titlesStyle: [TestVerifyResultStyles.normal],
                        }
                      : {
                          titles: verifyResultText,
                          titlesStyle: [
                            isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                            isCustomPassed && !isCustomRejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal,
                          ],
                        },
                  ]}
                  isShowCollapse={true}
                />
              </TestCardContainer>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Stack gap={2} position="relative">
      {!canEdit && (
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          gap={3}
          position="absolute"
          right={200}
          sx={{ zIndex: 5, marginTop: 5 }}
        >
          <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} />
        </Stack>
      )}
      {theForm()}
    </Stack>
  );
}

export default memo(ExportConcreteStrengthTable);
