import { TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import FineSieveLineChart from '@Src/_basic/components/YatungTest/FineSieveLineChart';
import QrcodeDataRows from '@Src/_basic/components/YatungTest/QrcodeDataRows';
import TabCoarseSieveLineChart from '@Src/_basic/components/YatungTest/TabCoarseSieveLineChart';
import YatungText from '@Src/_basic/components/YatungText';
import { AllTest, Record, TestItemEnum } from '@Src/_basic/object/TestType';
import { Stack } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  item?: Record;
  test?: AllTest;
  first?: boolean;
}

const RecordDetailCard = ({ item, test, first }: Props) => {
  const { t: i18T } = useTranslation();

  const type = useMemo(() => {
    if (item?.exptItemCode) {
      switch (item?.exptItemCode) {
        case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS:
          return TestItemEnum.FINE_AGG_SIEVE_ANALYSIS;
        case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS:
          return TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS;
        default:
          return TestItemEnum.FINE_AGG_SIEVE_ANALYSIS;
      }
    } else {
      switch (test?.exptItemCode) {
        case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS:
          return TestItemEnum.FINE_AGG_SIEVE_ANALYSIS;
        case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS:
          return TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS;
        default:
          return TestItemEnum.FINE_AGG_SIEVE_ANALYSIS;
      }
    }
  }, [item?.exptItemCode, test]);

  const title = useMemo(() => {
    switch (type) {
      case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS:
        return 'S';
      case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS:
        return '石';
      default:
        return 'S';
    }
  }, [type]);

  const switchLineChart = useMemo(() => {
    switch (type) {
      case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS:
        return <FineSieveLineChart testFormS1={test as AllTest} />;
      case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS:
        return (
          <Stack sx={{ height: 350 }}>
            <TabCoarseSieveLineChart testFormS1={test as AllTest} />
          </Stack>
        );
      default:
        return null;
    }
  }, [type, test]);

  return (
    <>
      {test ? (
        test && (
          <TestCardContainer
            sx={{
              width: '48%',
              height: '600px',
              px: 8,
            }}
            className="yatung-paper-with-ribbon"
          >
            <Stack className="ribbon" sx={{ top: 0, left: 0 }}>
              <YatungText
                text={first ? `${title}1` : `${title}2`}
                style={{ color: '#fff', fontSize: '28px', letterSpacing: '3px', margin: '5px 0 0 10px' }}
              />
            </Stack>
            {switchLineChart}
          </TestCardContainer>
        )
      ) : (
        <TestCardContainer
          sx={{
            width: '48%',
            px: 8,
            gap: 5,
            height: '450px',
            justifyContent: 'center',
          }}
          className="yatung-paper-with-ribbon"
        >
          <Stack className="ribbon" sx={{ top: 0, left: 0 }}>
            <YatungText
              text={first ? `${title}1` : `${title}2`}
              style={{ color: '#fff', fontSize: '28px', letterSpacing: '3px', margin: '5px 0 0 10px' }}
            />
          </Stack>
          <Stack flexDirection="row">
            <Stack width={'80%'}>
              <YatungText text={i18T('REPORT.SIEVE_REPORT.BASIC.ANALYSIS_RECORD')} style={{ fontSize: '22px' }} />
              <YatungText
                text={item?.code ?? i18T('GLOABAL.NO_INFO')}
                style={{
                  fontSize: '18px',
                  color: 'gray',
                  fontWeight: 'initial',
                }}
              />
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                borderRadius: '40px',
                backgroundColor: '#D4E0FF',
                width: '50%',
                padding: 1,
                px: 6,
                height: 40,
              }}
            >
              <YatungText text={item?.exptUnitName ?? i18T('GLOBAL.NO_INFO')} style={{ fontSize: '18px' }} />
            </Stack>
          </Stack>
          {item && <QrcodeDataRows qrcodeData={item} />}
        </TestCardContainer>
      )}
    </>
  );
};

export default memo(RecordDetailCard);
