import React, { useEffect } from 'react';
import { Select, MenuItem, SelectProps, FormControl, InputLabel, styled, Grid, Stack, Typography } from '@mui/material';
import DoubleTriang from '@Src/_basic/icons/DoubleTriang';

export type Options = {
  value: number;
  text: string | undefined;
};

export interface Props extends SelectProps {
  title?: string;
  icon?: React.ReactNode;
  options: Array<Options>;
  placeholder?: string;
}

const Label = styled(Typography)(({ theme }) => ({
  color: '#6C708C',
  fontSize: '16px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
}));

const DoubleTriangIcon = styled(DoubleTriang)(() => ({
  fill: '#6C708C',
}));

export default function ExptScheduleSelect({ title, icon, options, placeholder = '請選擇', ...props }: Props) {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <Stack direction="row" alignItems="center" gap="8px">
          {icon && icon}
          <Label>{title}</Label>
        </Stack>
      </Grid>
      <Grid item xs>
        <FormControl sx={{ width: '100%' }}>
          <Select
            variant="standard"
            defaultValue={0}
            IconComponent={DoubleTriangIcon}
            {...props}
            sx={{
              '&::after': {
                borderColor: '#6C708C',
              },
              ...props.sx,
            }}
          >
            <MenuItem disabled value={0}>
              {placeholder}
            </MenuItem>
            {options.map((option: Options, index) => (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
