import { Protocol } from '../Protocol';

export class AppPermissionProtocol {
  public static readonly SEND_APPLY_EMAIL: string = `${Protocol.YATUNG_API_ROOT}/apply`;
  public static readonly APPLY_USERINFORMATION: string = `${Protocol.YATUNG_API_ROOT}/apply/all`;
  public static readonly DELETE_APPLY: string = `${Protocol.YATUNG_API_ROOT}/apply/deny`;
  public static readonly SUCCESS_APPLY: string = `${Protocol.YATUNG_API_ROOT}/apply/approve`;
  public static readonly GET_USERS_DATA: string = `${Protocol.YATUNG_AUTH}CA062`;
  public static readonly APPLY_USERINFORMATION_PAGE: string = `${Protocol.YATUNG_API_ROOT}/apply/p/all`;
}
