import React, { useEffect, useState } from 'react';
import YatungFormSelect, { Props as YatungSelectFormProps, Options } from './YatungFormSelect';

export interface Props extends YatungSelectFormProps {
  totalOptions: Options[];
}

export default function YatungDynamicFormSelect(props: Props) {
  const [selectedOption, setSelectedOption] = useState<Options>();
  const [displayOptions, setDisplayOptions] = useState<Options[]>(props.options);

  useEffect(() => {
    setSelectedOption(props.totalOptions.find((option) => option.value === props.field.value));
  }, [props.field.value]);

  useEffect(() => {
    selectedOption ? setDisplayOptions([selectedOption, ...props.options]) : setDisplayOptions(props.options);
  }, [selectedOption, props.options]);

  return <YatungFormSelect {...props} options={displayOptions} />;
}
