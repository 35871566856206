import React from 'react';
import { Grid, InputProps, Stack, SxProps, TextField, Typography, styled } from '@mui/material';

interface Props extends InputProps {
  icon: React.ReactNode;
  title: string;
  sx?: SxProps;
}

const Label = styled(Typography)(({ theme }) => ({
  color: '#6C708C',
  fontSize: '16px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
}));

export default function ExptScheduleInput({
  icon,
  title,
  value,
  onChange,
  disabled,
  placeholder,
  sx,
  ...props
}: Props) {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <Stack direction="row" alignItems="center" gap="8px">
          {icon && icon}
          <Label>{title}</Label>
        </Stack>
      </Grid>
      <Grid item xs>
        <TextField
          disabled={disabled}
          variant="standard"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          sx={{
            width: '100%',
            '&::after': {
              borderColor: '#6C708C',
            },
            ...sx,
          }}
        />
      </Grid>
    </Grid>
  );
}
