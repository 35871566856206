import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function BackButton(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="23" height="24" viewBox="0 0 23 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87987 5.09252C7.29919 4.73309 7.34775 4.10179 6.98832 3.68247C6.62889 3.26315 5.99759 3.21459 5.57827 3.57402L2.23069 6.44346C2.01317 6.6269 1.875 6.90144 1.875 7.20825C1.875 7.45966 1.96778 7.6894 2.12097 7.86511C2.13597 7.88234 2.15162 7.8991 2.16788 7.91537L5.52195 11.2695C5.91247 11.6601 6.54564 11.6601 6.93617 11.2696C7.3267 10.879 7.32671 10.2459 6.93619 9.85534L5.28915 8.20825H13.8929C16.6539 8.20825 19.0135 10.4771 19.1212 13.2368C19.2348 16.1516 16.8118 18.6666 13.8929 18.6666H5.74923C5.19695 18.6666 4.74923 19.1143 4.74923 19.6666C4.74923 20.2189 5.19695 20.6666 5.74923 20.6666H13.8929C17.9442 20.6666 21.2777 17.2107 21.1196 13.1589C20.9701 9.32562 17.728 6.20825 13.8929 6.20825H5.57822L6.87987 5.09252Z"
        fill="white"
      />
    </SvgIcon>
  );
}
