import React from 'react';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import AddForm from './AddForm';
import { CreateLangData } from '@Src/_basic/object/MultilingualManagementType';

interface Props {
  open?: boolean;
  handleClose: () => void;
  handleAddGoBackButtonClick: () => void;
  inputNameValues: string;
  setInputNameValues: (e: string) => void;
  title: string;
  icon: JSX.Element;
  platformName: string;
  refreshOptionData: () => void;
}

export default function AddLangModal({
  open,
  handleClose,
  title,
  icon,
  handleAddGoBackButtonClick,
  inputNameValues,
  setInputNameValues,
  platformName,
  refreshOptionData,
}: Props) {


  const initialCreateLangValue: CreateLangData = {
    languageName: inputNameValues,
  };

  return (
    <>
      {
        <YatungDialog
          open={open}
          handleClose={handleClose}
          icon={icon}
          title={title}
          body={
            <AddForm
              initialValues={initialCreateLangValue}
              handleAddGoBackButtonClick={handleAddGoBackButtonClick}
              inputNameValues={inputNameValues}
              setInputNameValues={setInputNameValues}
              platformName={platformName}
              refreshOptionData={refreshOptionData}
            />
          }
        />
      }
    </>
  );
}
