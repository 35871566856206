import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function RestDaySettingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="56" height="54" viewBox="0 0 56 54" fill="none">
      <path
        d="M35.337 54L34.2989 48.9656C33.6069 48.6859 32.9576 48.3928 32.3509 48.0863C31.7465 47.7775 31.1272 47.3993 30.4928 46.9518L25.4757 48.4622L22.0156 42.7565L25.9947 39.4002C25.8794 38.6171 25.8217 37.8899 25.8217 37.2186C25.8217 36.5474 25.8794 35.8202 25.9947 35.037L22.0156 31.6807L25.4757 25.9751L30.4928 27.4854C31.1272 27.0379 31.7465 26.6597 32.3509 26.351C32.9576 26.0444 33.6069 25.7513 34.2989 25.4716L35.337 20.4372H42.2571L43.2952 25.4716C43.9872 25.7513 44.6365 26.059 45.2432 26.3946C45.8476 26.7302 46.4669 27.1498 47.1013 27.6532L52.1184 25.9751L55.5785 31.8486L51.5994 35.2048C51.7147 35.8761 51.7724 36.5753 51.7724 37.3025C51.7724 38.0297 51.7147 38.7289 51.5994 39.4002L55.5785 42.7565L52.1184 48.4622L47.1013 46.9518C46.4669 47.3993 45.8476 47.7775 45.2432 48.0863C44.6365 48.3928 43.9872 48.6859 43.2952 48.9656L42.2571 54H35.337ZM38.797 43.9312C40.7001 43.9312 42.3298 43.2745 43.6861 41.961C45.0402 40.6454 45.7172 39.0646 45.7172 37.2186C45.7172 35.3727 45.0402 33.7918 43.6861 32.4762C42.3298 31.1628 40.7001 30.506 38.797 30.506C36.894 30.506 35.2655 31.1628 33.9114 32.4762C32.5551 33.7918 31.8769 35.3727 31.8769 37.2186C31.8769 39.0646 32.5551 40.6454 33.9114 41.961C35.2655 43.2745 36.894 43.9312 38.797 43.9312Z"
        fill="white"
      />
      <path
        d="M15.8914 27.1503L15.061 23.1228C14.5074 22.899 13.9879 22.6645 13.5026 22.4193C13.0191 22.1723 12.5236 21.8698 12.0161 21.5118L8.00245 22.72L5.23438 18.1555L8.41766 15.4705C8.32539 14.8439 8.27925 14.2622 8.27925 13.7252C8.27925 13.1882 8.32539 12.6064 8.41766 11.9799L5.23438 9.29489L8.00245 4.73034L12.0161 5.93861C12.5236 5.5806 13.0191 5.27809 13.5026 5.03107C13.9879 4.78584 14.5074 4.55134 15.061 4.32759L15.8914 0.300049H21.4276L22.258 4.32759C22.8116 4.55134 23.3311 4.79747 23.8164 5.06597C24.2999 5.33447 24.7954 5.6701 25.3029 6.07286L29.3166 4.73034L32.0847 9.42914L28.9014 12.1142C28.9936 12.6512 29.0398 13.2106 29.0398 13.7923C29.0398 14.3741 28.9936 14.9334 28.9014 15.4705L32.0847 18.1555L29.3166 22.72L25.3029 21.5118C24.7954 21.8698 24.2999 22.1723 23.8164 22.4193C23.3311 22.6645 22.8116 22.899 22.258 23.1228L21.4276 27.1503H15.8914ZM18.6595 19.0952C20.1819 19.0952 21.4857 18.5699 22.5708 17.5191C23.654 16.4666 24.1957 15.202 24.1957 13.7252C24.1957 12.2484 23.654 10.9838 22.5708 9.93124C21.4857 8.8805 20.1819 8.35513 18.6595 8.35513C17.1371 8.35513 15.8342 8.8805 14.751 9.93124C13.6659 10.9838 13.1234 12.2484 13.1234 13.7252C13.1234 15.202 13.6659 16.4666 14.751 17.5191C15.8342 18.5699 17.1371 19.0952 18.6595 19.0952Z"
        fill="white"
      />
      <path
        d="M8.19202 53.9999L7.5692 50.9792C7.15399 50.8114 6.76439 50.6355 6.40039 50.4516C6.03777 50.2664 5.66616 50.0395 5.28555 49.771L2.27527 50.6772L0.199219 47.2538L2.58668 45.24C2.51748 44.7701 2.48288 44.3338 2.48288 43.931C2.48288 43.5283 2.51748 43.092 2.58668 42.6221L0.199219 40.6083L2.27527 37.1849L5.28555 38.0911C5.66616 37.8226 6.03777 37.5957 6.40039 37.4104C6.76439 37.2265 7.15399 37.0507 7.5692 36.8828L8.19202 33.8622H12.3441L12.9669 36.8828C13.3821 37.0507 13.7718 37.2352 14.1358 37.4366C14.4984 37.638 14.87 37.8897 15.2506 38.1918L18.2609 37.1849L20.3369 40.709L17.9495 42.7228C18.0187 43.1255 18.0533 43.5451 18.0533 43.9814C18.0533 44.4177 18.0187 44.8372 17.9495 45.24L20.3369 47.2538L18.2609 50.6772L15.2506 49.771C14.87 50.0395 14.4984 50.2664 14.1358 50.4516C13.7718 50.6355 13.3821 50.8114 12.9669 50.9792L12.3441 53.9999H8.19202ZM10.2681 47.9586C11.4099 47.9586 12.3877 47.5645 13.2015 46.7765C14.014 45.9871 14.4202 45.0386 14.4202 43.931C14.4202 42.8235 14.014 41.875 13.2015 41.0856C12.3877 40.2975 11.4099 39.9035 10.2681 39.9035C9.12624 39.9035 8.14912 40.2975 7.33669 41.0856C6.52287 41.875 6.11597 42.8235 6.11597 43.931C6.11597 45.0386 6.52287 45.9871 7.33669 46.7765C8.14912 47.5645 9.12624 47.9586 10.2681 47.9586Z"
        fill="white"
      />
    </SvgIcon>
  );
}
