import React from 'react';
import { useTranslation } from 'react-i18next';

import YatungPage from '@Src/_basic/components/YatungPage';
import YatungTabs from '@Src/_basic/components/YatungTabs';

import MaterialTypesPanel from './components/MaterialTypesPanel';
import TransactionTypesPanel from './components/TransactionTypesPanel';
import TransferTypesPanel from './components/TransferTypesPanel';

export default function MaterialCodeManage() {
  const { t: i18T } = useTranslation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_CODE_MANAGE.TITLE')}
      body={
        <>
          <YatungTabs
            tabs={[
              i18T('APPLICATIONSMANAGEMENT.MATERIAL_CODE_MANAGE.FEEDING_TRANSFER'),
              i18T('APPLICATIONSMANAGEMENT.MATERIAL_CODE_MANAGE.TRADE_TYPE'),
              i18T('APPLICATIONSMANAGEMENT.MATERIAL_CODE_MANAGE.FEEDING_TYPE'),
            ]}
            selectedIndex={value}
            onChange={handleChange}
          />
          {value === 0 && <TransferTypesPanel />}
          {value === 1 && <TransactionTypesPanel />}
          {value === 2 && <MaterialTypesPanel />}
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
