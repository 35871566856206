import { Protocol } from '../Protocol';

export class TestProtocol {
  public static readonly TEST_DETAIL: string = `${Protocol.YATUNG_API_ROOT}/expt/detail`;
  public static readonly TEST: string = `${Protocol.YATUNG_API_ROOT}/expt`;
  public static readonly RECORD_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/expt/search`;

  public static readonly NEW_TEST_BY_FLY_ASH_ACTIVITY: string = `${Protocol.YATUNG_API_ROOT}/expt/fly-ash-activity/new`;
  public static readonly NEW_TEST_BY_SLAG_ACTIVITY: string = `${Protocol.YATUNG_API_ROOT}/expt/slag-activity/new`;
  public static readonly EXTRA_TEST: string = `${Protocol.YATUNG_API_ROOT}/expt/extra`;

  public static readonly NEW_TEST_BY_CONCRETE_STR: string = `${Protocol.YATUNG_API_ROOT}/expt/conc-comp-str`;

  public static readonly GROUP_DATA_BY_FLY_ASH_ACTIVITY: string = `${Protocol.YATUNG_API_ROOT}/expt/fly-ash-activity/group`;
  public static readonly GROUP_DATA_BY_SLAG_ACTIVITY: string = `${Protocol.YATUNG_API_ROOT}/expt/slag-activity/group`;
  public static readonly GROUP_DATA_BY_CEMENT_COMP_STR: string = `${Protocol.YATUNG_API_ROOT}/expt/cement-comp-str/group`;

  public static readonly CHEM_ADMX_PENTA_TEST: string = `${Protocol.YATUNG_API_ROOT}/expt/chem-admx-penta-test`;
  public static readonly GET_EXPT_STATUS: string = `${Protocol.YATUNG_API_ROOT}/expt/search/status`;
}
