import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, SxProps, Typography } from '@mui/material';
import React from 'react';
import { Options } from './YatungSelect';

export enum YatungChartCheckBoxVariantEnum {
  CHECK_BOX = 'checkBox',
  RADIO_BUTTON = 'radioButton',
}

interface Props {
  variant?: YatungChartCheckBoxVariantEnum;
  options: Array<Options>;
  values: Array<number>;
  labelTextStyle?: SxProps;
  sx?: SxProps;
  onChange?: (e: any) => void;
}

export default function YatungChartCheckBox({ variant, options, values, labelTextStyle, sx, onChange }: Props) {
  const isChecked = (value: number) => {
    return values.includes(value);
  };

  const handleChange = (e: any) => {
    if (onChange) {
      const value = parseInt(e.target.value);
      values.includes(value) ? onChange(values.filter((item) => item !== value)) : onChange([...values, value]);
    }
  };

  return (
    <FormControl>
      <FormGroup>
        {options.map((option: Options, index: number) => (
          <React.Fragment key={index}>
            {variant === YatungChartCheckBoxVariantEnum.RADIO_BUTTON && (
              <FormControlLabel
                value={option.value}
                control={<Radio sx={sx} onClick={handleChange} checked={isChecked(option.value)} />}
                label={<Typography sx={{ ...labelTextStyle }}>{option.text}</Typography>}
              />
            )}
            {(variant === undefined || variant === YatungChartCheckBoxVariantEnum.CHECK_BOX) && (
              <FormControlLabel
                control={
                  <Checkbox value={option.value} onChange={handleChange} checked={isChecked?.(option.value)} sx={sx} />
                }
                label={<Typography sx={{ ...labelTextStyle }}>{option.text}</Typography>}
              />
            )}
          </React.Fragment>
        ))}
      </FormGroup>
    </FormControl>
  );
}
