import { Stack } from '@mui/material';
import {
  getSpecificationExtraDataFieldNameByExperimentResultTypeId,
  getTypeAmountByExperimentItemId,
} from '@Src/_basic/helpers/CustomStandardHelpers';
import { CustomStandardData } from '@Src/_basic/object/CustomStandardType';
import React from 'react';
import BasicCard from './components/BasicCard';
import SpecificationsCard from './components/SpecificationsCard';

interface Props {
  customStandard: CustomStandardData | undefined;
  typeId: number;
}
export default function ViewCustomStandardCard(props: Props) {
  return (
    <Stack spacing={2}>
      <BasicCard customStandard={props.customStandard} typeId={props.typeId} />
      <SpecificationsCard
        variant={getSpecificationExtraDataFieldNameByExperimentResultTypeId(
          props.customStandard?.experimentResultType?.id || 1,
        )}
        experimentResultTypeId={props.customStandard?.experimentResultType?.id || 1}
        specifications={props.customStandard?.specifications || []}
        specificationGroupsSize={2}
        typeAmount={
          props.customStandard
            ? getTypeAmountByExperimentItemId(props.customStandard.experimentResultType.experimentItem.id)
            : 1
        }
      />
    </Stack>
  );
}
