import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import TestDetailDialog from '@Src/_basic/components/YatungTest/testDetail/TestDetailDialog';
import DetailIcon from '@Src/_basic/icons/Detail';
import { AnalysisList, MonthlyReportNameEnum, SourceList } from '@Src/_basic/object/MonthlyReportType';
import { TestItemEnum } from '@Src/_basic/object/TestType';
import AnalysisReportDialog from '@Src/app/module/view/page/MonthlyReport/screens/components/AnalysisReportDialog';
import { useApi } from '@Src/redux/api/apiAction';
import { Typography } from '@mui/material';
import moment from 'moment';
import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  exptType: MonthlyReportNameEnum;
  selectedFormId?: number;
  tableData?: any;
}

const MonthlyReportTable = ({ tableData, exptType, selectedFormId }: Props) => {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const [open, setOpen] = useState<boolean>(false);
  const [analysisDialogOpen, setAnalysisDialogOpen] = useState<boolean>(false);
  const [recordDetail, setRecordDetail] = useState<any>();
  const [analysisReportDetail, setAnalysisReportDetail] = useState<{
    _ratio: number;
    exptItemCode: TestItemEnum;
    form1Id: number;
    form2Id: number;
  }>({
    form1Id: 0,
    form2Id: 0,
    exptItemCode: TestItemEnum.FINE_AGG_SIEVE_ANALYSIS,
    _ratio: 0,
  });

  const openHandler = (row: any) => {
    setRecordDetail(row);
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
    setRecordDetail(undefined);
  };

  const afterColumns: Array<Column> = useMemo(() => {
    if (SourceList.includes(exptType)) {
      return [
        {
          text: i18T('REPORT.MONTHLY_REPORT.TABLE.SPECIFICATION'),
          dataField: 'specification',
        },
        {
          text: i18T('REPORT.MONTHLY_REPORT.TABLE.SOURCE'),
          dataField: 'source',
        },
      ];
    } else if (AnalysisList.includes(exptType)) {
      if (selectedFormId === 1)
        return [
          ...(exptType !== MonthlyReportNameEnum.COARSE_AGGREGATE_ANALYSIS
            ? [
                {
                  text: i18T('REPORT.MONTHLY_REPORT.TABLE.SPECIFICATION'),
                  dataField: 'specification',
                },
              ]
            : []),
          {
            text: i18T('REPORT.MONTHLY_REPORT.TABLE.SOURCE'),
            dataField: 'source',
          },
          {
            text: i18T('REPORT.MONTHLY_REPORT.TABLE.SUPPLIER'),
            dataField: 'supplier',
          },
        ];
      else return [];
    } else if (exptType === MonthlyReportNameEnum.CONCRETE_ANTI_STRESS) {
      return [
        {
          text: i18T('REPORT.MONTHLY_REPORT.TABLE.PLATE_NUMBER'),
          dataField: 'carNo',
          formatter: ({ row }) => <Typography>{row?.carNo ?? '無資料'}</Typography>,
        },
        {
          text: i18T('REPORT.MONTHLY_REPORT.TABLE.SPECIFICATION'),
          dataField: 'specification',
        },
        {
          text: i18T('REPORT.MONTHLY_REPORT.TABLE.PROPORTION_NAME'),
          dataField: 'proportionName',
        },
        {
          text: i18T('REPORT.MONTHLY_REPORT.TABLE.TEST_SAMPLE'),
          dataField: 'testSample',
        },
      ];
    } else if (exptType === MonthlyReportNameEnum.FINE_AGGREGATE_R_VALUE) {
      return [
        {
          text: i18T('REPORT.MONTHLY_REPORT.TABLE.SAMPLE_DATE'),
          dataField: 'sampleDate',
          width: '30%',
          formatter: ({ row }) => <Typography>{moment(row?.sampleDate).format('YYYY/MM/DD HH:mm')}</Typography>,
        },
        {
          text: i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_R_VALUE.R_VALUE'),
          dataField: 'value',
          width: '30%',
          formatter: ({ row }) => <Typography>{row?.value?.result?.toFixed(4)}</Typography>,
        },
        //
      ];
    } else return [];
  }, [exptType, selectedFormId, i18T]);

  return (
    <>
      <YatungTable
        loading={actionLoading}
        data={tableData}
        columns={[
          ...(exptType === MonthlyReportNameEnum.CONCRETE_ANTI_STRESS
            ? [
                {
                  text: i18T('REPORT.MONTHLY_REPORT.TABLE.TESTER_NAME'),
                  dataField: 'tester',
                  width: '20%',
                  formatter: ({ row }: any) => (
                    <Typography>{moment(row?.exptConcreteCompStr[0]?.testDate).format('YYYY/MM/DD HH:mm')}</Typography>
                  ),
                },
              ]
            : [
                {
                  text: i18T('REPORT.MONTHLY_REPORT.TABLE.DATE'),
                  dataField: 'testDate',
                  width: SourceList.includes(exptType) ? '25%' : '15%',
                  formatter: ({ row }: any) => (
                    <Typography>{moment(row.testDate).format('YYYY/MM/DD HH:mm')}</Typography>
                  ),
                },
              ]),
          ...afterColumns,
          ...(exptType === MonthlyReportNameEnum.CONCRETE_ANTI_STRESS
            ? [
                {
                  text: i18T('REPORT.MONTHLY_REPORT.TABLE.TESTER_NAME'),
                  dataField: 'tester',
                  width: '20%',
                  formatter: ({ row }: any) => <Typography>{row?.exptConcreteCompStr[0].tester}</Typography>,
                },
              ]
            : [
                {
                  text: i18T('REPORT.MONTHLY_REPORT.TABLE.TESTER_NAME'),
                  dataField: 'tester',
                  width: '20%',
                },
              ]),
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            width: '10%',
            formatter: ({ row }) => {
              return (
                <YatungButton
                  text={i18T('GLOBAL.DETAIL')}
                  startIcon={<DetailIcon />}
                  width={90}
                  onClick={
                    selectedFormId !== 2
                      ? () => openHandler(row)
                      : () => {
                          setAnalysisDialogOpen(true);
                          setAnalysisReportDetail({
                            form1Id: (row?.exptId1 as number) ?? 0,
                            form2Id: (row?.exptId2 as number) ?? 0,
                            exptItemCode: row?.exptItemCode as TestItemEnum,
                            _ratio: ((row?.ratio / 100) as number) ?? 0,
                          });
                        }
                  }
                  color="yellow"
                />
              );
            },
          },
        ]}
      />
      {recordDetail && (
        <TestDetailDialog
          exptItemCode={recordDetail.exptItemCode}
          exptId={recordDetail.exptId}
          open={open}
          closeHandler={closeHandler}
        ></TestDetailDialog>
      )}
      {analysisDialogOpen && (
        <AnalysisReportDialog
          {...analysisReportDetail}
          open={analysisDialogOpen}
          closeHandler={() => setAnalysisDialogOpen(false)}
        />
      )}
    </>
  );
};

export default memo(MonthlyReportTable);
