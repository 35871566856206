import { AllTest } from '@Src/_basic/object/TestType';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Stack } from '@mui/material';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ColFieldInfo from './ColFieldInfo';

interface Props {
  testDetail: AllTest;
}

interface TwoInOneRowProps {
  title: string[];
  content: (string | undefined | number)[];
}

function TestFormConcreteHeader({ testDetail }: Props) {
  const { t: i18T } = useTranslation();
  const [open, setOpen] = useState<boolean>(true);

  const TwoInOneRow = ({ title, content }: TwoInOneRowProps) => {
    return (
      <Stack flexDirection="row" flexWrap="wrap" margin="3px 0">
        {Array.from(title).map((_title, index) => (
          <ColFieldInfo style={{ flex: 1 }} title={_title} key={`${_title}_${index}`}>
            {content[index] ?? i18T('GLOBAL.NO_INFO')}
          </ColFieldInfo>
        ))}
      </Stack>
    );
  };

  return (
    <Stack>
      <Stack flexDirection="row" position="relative">
        <ColFieldInfo style={{ flex: 1 }} title={i18T(`TEST.QRCODE_DATA.SAMPLING_TIME`)}>
          {testDetail?.samplingTime
            ? moment(testDetail?.samplingTime).format('yyyy/MM/DD HH:mm')
            : i18T('GLOBAL.NO_INFO')}
        </ColFieldInfo>

        <ColFieldInfo style={{ flex: 1 }} title={i18T(`TEST.QRCODE_DATA.TEST_COMPLETE_TIME`)}>
          {testDetail?.completeTime
            ? moment(testDetail?.completeTime).format('yyyy/MM/DD HH:mm')
            : i18T('GLOBAL.NO_INFO')}
        </ColFieldInfo>

        <IconButton
          sx={{ height: 40, width: 40, position: 'absolute', top: 0, right: 0 }}
          onClick={() => setOpen((prev) => !prev)}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      {open ? (
        <Stack>
          <TwoInOneRow
            title={[i18T(`TEST.QRCODE_DATA.TESTERS`), i18T(`TEST.QRCODE_DATA.CAR_NO`)]}
            content={[
              testDetail?.operatorName,
              testDetail?.plateNumber ? testDetail?.plateNumber : i18T('GLOBAL.NO_INFO'),
            ]}
          />

          <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.SLUMP_TEST_EXECUTION_PERIOD`)}>
            {testDetail?.slumpExptCompleteTime ? moment(testDetail?.slumpExptCompleteTime).format('yyyy/MM/DD HH:mm') : i18T('GLOBAL.NO_INFO')}
          </ColFieldInfo>

          <TwoInOneRow
            title={[i18T(`TEST.QRCODE_DATA.SITE_NAME`), i18T(`TEST.QRCODE_DATA.SITE_WEATHER`)]}
            content={[testDetail?.civilEngrName, i18T('GLOBAL.NO_INFO')]}
          />

          <TwoInOneRow
            title={[i18T(`TEST.QRCODE_DATA.SITE_ADDRESS`), i18T(`TEST.QRCODE_DATA.TARGET`)]}
            content={[testDetail.civilEngrAddr, testDetail?.placement]}
          />

          <TwoInOneRow
            title={[i18T(`TEST.QRCODE_DATA.RATIO`), i18T(`TEST.QRCODE_DATA.DESIGN_STR`)]}
            content={[testDetail?.propName, testDetail?.designStr]}
          />

          <Stack flexDirection="row" flexWrap="wrap" margin="3px 0">
            <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.TEST_TYPE`)}>
              {testDetail?.exptUnitName ?? i18T('GLOBAL.NO_INFO')}
            </ColFieldInfo>

            <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.CONCRETE_TYPE`)}>
              {testDetail?.propName ?? i18T('GLOBAL.NO_INFO')}
            </ColFieldInfo>

            <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.MIXER_INFO`)}>
              {testDetail?.mixerName ?? i18T('GLOBAL.NO_INFO')}
            </ColFieldInfo>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
}

export default memo(TestFormConcreteHeader);
