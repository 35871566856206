import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import AreaManagementScreen from './screens/AreaManagementScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <AreaManagementScreen />,
  },
];

const AreaManagementRouter = () => PermissionRoute(routesConfig);

export default memo(AreaManagementRouter);
