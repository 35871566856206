import React from 'react';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import { UpdqateLangName } from '@Src/_basic/object/MultilingualManagementType';
import WebUpdateForm from './WebUpdateForm';

interface Props {
  open?: boolean;
  handleClose: () => void;
  handleUpdateGoBackButtonClick: () => void;
  title: string;
  icon: JSX.Element;
  langName: any;
  langData: number;
  langByIdData: any;
}

export default function EditLangModal({
  open,
  handleClose,
  title,
  icon,
  langName,
  langData,
  handleUpdateGoBackButtonClick,
  langByIdData,
}: Props) {
  const initialLangValue: UpdqateLangName = {
    id: langData,
    name: langByIdData?.name ? langByIdData?.name : langName, //顯示名稱
  };

  return (
    <>
      {
        <YatungDialog
          open={open}
          handleClose={handleClose}
          icon={icon}
          title={title}
          body={
            <WebUpdateForm
              initialValues={initialLangValue}
              onUpdateGoBackButtonClick={handleUpdateGoBackButtonClick}
              langByIdData={langByIdData}
            />
          }
        />
      }
    </>
  );
}
