import { Grid, Pagination, SelectChangeEvent, Stack } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';

import useGetAllTypes from '@Src/_basic/apiHooks/useGetAllTypes';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import ExportIcon from '@Src/_basic/icons/Export';
import SearchIcon from '@Src/_basic/icons/Search';
import { MaterialReceiptsData, PageableMaterialReceiptsData } from '@Src/_basic/object/MaterialReceiptsType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { MaterialReceiptsApi } from '@Src/_basic/protocol/materialReceipt/MaterialReceiptsApi';
import { NameApi } from '@Src/_basic/protocol/name/NameApi';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { saveAs } from 'file-saver';
import { useLocation, useNavigate } from 'react-router-dom';
import XLSX from 'xlsx-js-style';
import MaterialReceiptAreaExportModal from '../components/MaterialReceiptAreaExportModal';
import MaterialReceiptDetailModal from '../components/MaterialReceiptDetailModal';
import MaterialsReceiptUpdateModal from '../components/MaterialsReceiptUpdateModal';
import MaterialsReceiptsExcelTable from '../components/MaterialsReceiptsExcelTable';
import MaterialsReceiptsTable from '../components/MaterialsReceiptsTable';

const background = '/media/images/applicationsMange/materialReceiptsBG.png';

export type ModalType = 'DETAIL' | 'UPDATE' | 'DELETE' | 'EXPORT' | 'AREAEXPORT' | null;

type SelectQueryType = {
  startTime: number;
  endTime: number;
  factoryId: number;
};

type QueryType = SelectQueryType & {
  typeId: number;
  sourceId: number;
  specificationId: number;
  plateNumber: string;
};

type FilterValueType = QueryType & {
  areaId: number;
};

type AsyncStorageSavingType = FilterValueType & {
  page: number;
};

export default function MaterialReceiptsScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { actionLoading } = useApi();
  const { userAreaOptionsData } = useAuth();
  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();
  const { typeOption } = useGetAllTypes([9, 10, 11]);

  const effectRef = useRef<boolean>(false);
  const exportIdRef = useRef(null);

  const [receipts, setRecipts] = useState<Array<MaterialReceiptsData>>([]);
  const [totalReceipts, setTotalReceipts] = useState<Array<MaterialReceiptsData>>([]);
  const [selectedRecipt, setSelectedRecipt] = useState<MaterialReceiptsData | undefined>(undefined);

  const [exporting, setExporting] = useState(false);
  const [sources, setSources] = useState<Options[]>([]);
  const [suppliers, setSuppliers] = useState<Options[]>([]);
  const [specification, setSpecification] = useState<Options[]>([]);
  const [creators, setCreators] = useState<Options[]>([]);

  const [selectedAreaName, setSelectedAreaName] = useState<string>('');
  const [factoriesName, setFactoriesName] = useState<string>('');
  const [typesName, setTypesName] = useState<string>('');
  const [specificationName, setSpecificationName] = useState<string>('全部');

  const [isVisable, setIsVisable] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<ModalType>(null);

  const initFilterValue = useMemo(() => {
    const defaultStart = new Date().getTime();
    const defaultEnd = new Date().getTime();

    const {
      areaId = 0,
      factoryId = 0,
      startTime = defaultStart,
      endTime = defaultEnd,
      typeId = 0,
      sourceId = 0,
      specificationId = 0,
      plateNumber = '',
    } = location.pathname === request?.page && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      startTime,
      endTime,
      typeId,
      sourceId,
      specificationId,
      plateNumber,
    };
  }, [request, location.pathname]);

  const iniQuery = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
      startTime: initFilterValue.startTime,
      endTime: initFilterValue.endTime,
      typeId: initFilterValue.typeId,
      sourceId: initFilterValue.sourceId,
      specificationId: initFilterValue.specificationId,
      plateNumber: initFilterValue.plateNumber,
    }),
    [initFilterValue],
  );

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 10,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, iniQuery);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
    getAllReceipts(page);
  };

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const handleSpecificationChange = (e: SelectChangeEvent<unknown>) => {
    const specificationId = e.target.value as number;
    setSpecificationName(specification.find((i) => i.value === specificationId)?.text || '');
    handleChange('specificationId')(specificationId);
  };

  const handleModalOpen = useCallback((row: MaterialReceiptsData, modal: ModalType) => {
    setSelectedRecipt(row);
    setModalOpen(modal);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(null);
    setSelectedRecipt(undefined);
  }, []);

  const handleGoTOMakeUpOrder = useCallback(() => {
    navigate('MaterialsReceiptMaleUpOrder');
  }, [navigate]);

  const reset = (withType?: boolean) => {
    setRecipts([]);
    pageInfoDispatch({
      total: 0,
      page: 0,
      totalCount: 0,
    });
    handleChange('specificationId')(0);
    setSpecificationName('全部');
    handleChange('sourceId')(0);
    if (withType) handleChange('typeId')(0);
  };

  const handleTypeChange = (typeId: number) => {
    reset();

    setTypesName(typeOption.find((i) => i.value === typeId)?.text || '');
    handleChange('typeId')(typeId);

    const _params = {
      factoryId: filterValue.factoryId,
      typeId,
    };

    MaterialReceiptsApi.getSSSArray({ ..._params, removed: false }, (data) => {
      const sourcesOptions = data.sources.map((source) => ({
        value: source.id,
        text: source.sourceName,
      }));
      setSources(sourcesOptions);
    });
    SpecificationApi.getSpecificationsBySearch(_params, (data) => {
      const specificationsOptions = data.map(({ id, name }) => ({ value: id, text: name }));
      setSpecification(specificationsOptions);
    });
  };

  const getAllReceipts = (page: number) => {
    const { sourceId, plateNumber, specificationId, ...params } = query;

    MaterialReceiptsApi.getPageableMaterialReceiptsBySearch(
      {
        ...params,
        ...(sourceId && { sourceId }),
        ...(plateNumber && { plateNumber }),
        ...(specificationId && { specificationId }),
        page,
        pageSize: pageInfo.pageSize,
      },
      (data: PageableMaterialReceiptsData) => {
        setRecipts(data.receipts);
        pageInfoDispatch({ page, total: data.maxPage, totalCount: data.elements });
        setSaveRequest({ page: location.pathname, request: { areaId: filterValue.areaId, ...query, page } });
        setIsVisable(true);
      },
    );
  };

  const handleDeleteReceipt = () => {
    if (!selectedRecipt) return;
    MaterialReceiptsApi.deleteReceipt(selectedRecipt.id, () => {
      getAllReceipts(pageInfo.page);
      handleCloseModal();
    });
  };

  const getAllCreators = () => {
    NameApi.getAllNames((data) => {
      setCreators(data.map(({ id, name }) => ({ value: id, text: name })));
    });
  };

  const exportFile = async () => {
    setModalOpen(null);
    const fileName = '原料進廠';
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.sheet_add_dom(ws, exportIdRef.current, { dateNF: 'YYYY/MM/DD HH:mm' });
    XLSX.utils.book_append_sheet(wb, ws, '原料進廠');

    const range = XLSX.utils.decode_range(ws['!fullref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellref = XLSX.utils.encode_cell({ c: C, r: R });
        if (!ws[cellref]) continue;
        const cell = ws[cellref];
        const specificationCalTotal = [...new Set(totalReceipts.map((item) => item.specification + '合計：'))];
        const specificationExportValue = [...new Set(totalReceipts.map((item) => item.specification + '送貨合計：'))];
        const specificationCalDiff = [...new Set(totalReceipts.map((item) => item.specification + '合計差異：'))];

        const specificationCalArray = specificationCalTotal.concat(specificationExportValue, specificationCalDiff);

        const specificationTotalCalArray = ['總計入料：', '總計差異量：'];
        const tableHeaderArray = [
          '資料筆數',
          '建單時間',
          '結單時間',
          '車號',
          '供應商',
          '來源',
          '規格',
          '總重(kg)',
          '空重(kg)',
          '淨重(kg)',
          '送貨單淨重(kg)',
          '差異量(kg)',
          '入料料庫',
          '送貨狀態',
        ];
        const dataHeaderArray = ['原料進廠統計表'];
        const dataDetailArray = ['資料期間：', '區域：', '廠別：', '物料類別：', '物料規格：'];
        const plateNumberArray = totalReceipts.map((i: any) => {
          return i.plateNumber;
        });

        const supplierArray = totalReceipts.map((i: any) => {
          return i.supplier;
        });
        const sourcerArray = totalReceipts.map((i: any) => {
          return i.source;
        });
        const specificationArray = totalReceipts.map((i: any) => {
          return i.specification;
        });
        const grossWeightArray = totalReceipts.map((i: any) => {
          return i.grossWeight;
        });
        const emptyWeightArray = totalReceipts.map((i: any) => {
          return i.emptyWeight;
        });
        const exportValueArray = totalReceipts.map((i: any) => {
          return i.exportValue;
        });
        const diffArray = totalReceipts.map((i: any) => {
          return i.diff;
        });
        const storageArray = totalReceipts.map((i: any) => {
          return i.storage;
        });
        const netWeightArray = totalReceipts.map((i: any) => {
          return i.grossWeight - i.emptyWeight;
        });
        const statusArray = totalReceipts.map((i: any) => {
          return i.status;
        });
        const indexArray = totalReceipts.map((i: any, index: number) => {
          return index + 1;
        });
        if (specificationCalArray.includes(cell.v)) {
          cell.s = {
            font: {
              name: '新細明體',
              sz: 12,
              bold: true,
            },
            alignment: {
              horizontal: 'center',
            },
          };
        } else if (specificationTotalCalArray.includes(cell.v)) {
          cell.s = {
            font: {
              name: '新細明體',
              sz: 12,
              bold: true,
            },
            alignment: {
              horizontal: 'left',
            },
          };
        } else if (tableHeaderArray.includes(cell.v)) {
          cell.s = {
            fill: {
              patternType: 'solid',
              fgColor: { rgb: 'D3D3D3	' },
            },
            font: {
              name: '新細明體',
              sz: 12,
              bold: true,
            },
            alignment: {
              horizontal: 'center',
            },
            border: {
              top: { style: 'thin', color: { rgb: '#ff0000' } },
              bottom: { style: 'thin', color: { rgb: '#ff0000' } },
              left: { style: 'thin', color: { rgb: '#ff0000' } },
              right: { style: 'thin', color: { rgb: '#ff0000' } },
            },
          };
        } else if (dataHeaderArray.includes(cell.v)) {
          cell.s = {
            font: {
              name: '新細明體',
              sz: 18,
              bold: true,
            },
            alignment: {
              horizontal: 'center',
            },
          };
        } else if (dataDetailArray.includes(cell.v)) {
          cell.s = {
            font: {
              name: '新細明體',
              sz: 12,
              bold: true,
            },
            alignment: {
              horizontal: 'center',
            },
          };
        } else if (
          indexArray.includes(cell.v) ||
          cell.z === 'YYYY/MM/DD HH:mm' ||
          supplierArray.includes(cell.v) ||
          plateNumberArray.includes(String(cell.v)) ||
          sourcerArray.includes(cell.v) ||
          specificationArray.includes(cell.v) ||
          grossWeightArray.includes(cell.v) ||
          emptyWeightArray.includes(cell.v) ||
          exportValueArray.includes(cell.v) ||
          storageArray.includes(cell.v) ||
          netWeightArray.includes(cell.v) ||
          diffArray.includes(cell.v) ||
          statusArray.includes(cell.v) ||
          cell.v === 0 ||
          cell.v === 'Invalid date'
        ) {
          cell.s = {
            font: {
              name: '新細明體',
              sz: 12,
            },
            alignment: {
              horizontal: 'center',
            },
            border: {
              top: { style: 'thin', color: { rgb: '#ff0000' } },
              bottom: { style: 'thin', color: { rgb: '#ff0000' } },
              left: { style: 'thin', color: { rgb: '#ff0000' } },
              right: { style: 'thin', color: { rgb: '#ff0000' } },
            },
          };
        } else {
          cell.s = {
            font: {
              name: '新細明體',
              sz: 12,
            },
            alignment: {
              horizontal: 'center',
            },
          };
        }
      }
    }
    const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([eb], { type: fileType });
    saveAs(blob, fileName + fileExtension);
  };

  const getAllPageReceipts = () => {
    setExporting(true);
    const { sourceId, plateNumber, specificationId, ...params } = query;
    MaterialReceiptsApi.getCancelableMaterialReceiptsBySearch(
      {
        ...params,
        ...(sourceId && { sourceId }),
        ...(plateNumber && { plateNumber }),
        ...(specificationId && { specificationId }),
      },
      (data) => {
        setTotalReceipts(data.receipts);
        setModalOpen('EXPORT');
        setExporting(false);
      },
    );
  };

  const exportAllAreaReceipt = () => {
    setModalOpen('AREAEXPORT');
  };

  useEffect(() => {
    reset(true);
    if (!filterValue.factoryId) return;

    SupplierApi.getSuppliersBySearch({ factoryId: filterValue.factoryId }, (data) => {
      const suppliersOptions = data.map((supplier) => ({ value: supplier.id, text: supplier.name }));
      setSuppliers(suppliersOptions);
    });

    const _area = userAreaOptionsData.find((i) => i.value === filterValue.areaId);
    if (_area && _area.text) {
      setSelectedAreaName(_area.text);
      const _factory = _area.factories.find((i) => i.value === filterValue.factoryId);
      if (_factory && _factory.text) setFactoriesName(_factory.text);
    }
  }, [filterValue.factoryId]);

  useEffect(() => {
    setIsVisable(false);
  }, [query]);

  useEffect(() => {
    effectRef.current = true;
    getAllCreators();
    handleTypeChange(filterValue.typeId);
    getAllReceipts(pageInfo.page);
  }, [effectRef]);

  return (
    <YatungPage
      isOrder
      handleClick={handleGoTOMakeUpOrder}
      title={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.TITLE')}
      backgroundImage={background}
      actions={
        <Stack display={'flex'} direction={'row'}>
          <YatungButton
            text="匯出全區入料資訊"
            disabled={actionLoading}
            startIcon={<ExportIcon />}
            onClick={exportAllAreaReceipt}
            sx={{
              color: '#ffffff',
              bgcolor: '#697EA3',
              alignSelf: 'center',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#697EA3',
              },
              marginRight: '920px',
            }}
          />
          <YatungButton
            text="匯出"
            disabled={!receipts.length || actionLoading}
            startIcon={<ExportIcon />}
            onClick={getAllPageReceipts}
            sx={{
              color: '#ffffff',
              bgcolor: '#697EA3',
              alignSelf: 'center',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#697EA3',
              },
            }}
          />
        </Stack>
      }
      body={
        <>
          <Stack direction="row">
            <Stack direction="row" spacing={1} alignItems="center">
              <YatungCombinationSelect<SelectQueryType, FilterValueType>
                filterValue={filterValue}
                handleChange={handleChange}
                handleFilterChange={handleFilterChange}
                selectOptions={['Date', 'AreaAndFactory']}
                isNotMaxDate={true}
              />
              <YatungSelect
                disabled={typeOption.length === 0 || !filterValue.factoryId || actionLoading}
                helperText={i18T('GLOBAL.TYPE')}
                options={typeOption}
                value={filterValue.typeId}
                onChange={(e) => handleTypeChange(e.target.value as number)}
              />
              <YatungSelect
                disabled={sources.length === 0 || !filterValue.factoryId || actionLoading}
                helperText={i18T('APPLICATIONSMANAGEMENT.TABLE.SOURCE')}
                options={[{ value: 0, text: '全部' }, ...sources]}
                value={filterValue.sourceId}
                onChange={(e) => handleChange('sourceId')(e.target.value as number)}
              />
              <YatungSelect
                disabled={specification.length === 0 || !filterValue.factoryId || actionLoading}
                helperText={i18T('GLOBAL.SPECIFICATION')}
                options={[{ value: 0, text: '全部' }, ...specification]}
                value={filterValue.specificationId}
                onChange={handleSpecificationChange}
              />
              <YatungInput
                onChange={(e: any) => handleChange('plateNumber')(e.target.value)}
                value={filterValue.plateNumber}
                disabled={actionLoading}
              />
              <YatungButton
                disabled={!filterValue.typeId || !filterValue.factoryId || actionLoading}
                text={i18T('GLOBAL.SEARCh_BUTTON')}
                startIcon={<SearchIcon />}
                onClick={() => getAllReceipts(pageInfo.page)}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#003087',
                  fontSize: '20px',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#003087',
                  },
                }}
              />
            </Stack>
          </Stack>
          <MaterialsReceiptsExcelTable
            exporting={exporting}
            exportIdRef={exportIdRef}
            query={query}
            totalReceipts={totalReceipts}
            selectedAreaName={selectedAreaName}
            factoriesName={factoriesName}
            typesName={typesName}
            specificationName={specificationName}
          />
          {
            <YatungFadeInOut isVisible={isVisable} y={5}>
              <>
                <MaterialsReceiptsTable receiptData={receipts} handleModalOpen={handleModalOpen} />
                <Grid container alignItems={'center'} item xs={12}>
                  <Grid container justifyContent={'center'} item xs={11}>
                    <Pagination
                      count={pageInfo.total}
                      page={pageInfo.page}
                      onChange={handlePageChange}
                      color="primary"
                      disabled={actionLoading}
                    />
                  </Grid>
                  <Grid container justifyContent={'flex-end'} item xs={1}>
                    <YatungPaperLabel label={`總數：${pageInfo.totalCount}`} totalCount />
                  </Grid>
                </Grid>
              </>
            </YatungFadeInOut>
          }

          {!!selectedRecipt && modalOpen === 'DETAIL' && (
            <MaterialReceiptDetailModal
              id={selectedRecipt.id}
              open={modalOpen === 'DETAIL'}
              handleCloseModal={handleCloseModal}
            />
          )}
          {!!selectedRecipt && modalOpen === 'UPDATE' && (
            <MaterialsReceiptUpdateModal
              id={selectedRecipt.id}
              handleCloseModal={handleCloseModal}
              open={modalOpen === 'UPDATE'}
              sources={sources}
              suppliers={suppliers}
              creators={creators}
              getAllReceipts={() => getAllReceipts(pageInfo.page)}
            />
          )}
          {modalOpen === 'AREAEXPORT' && (
            <MaterialReceiptAreaExportModal open={modalOpen === 'AREAEXPORT'} handleCloseModal={handleCloseModal} />
          )}
          {modalOpen === 'DELETE' && (
            <YatungConfirmAlert
              isShowAlert={modalOpen === 'DELETE'}
              alertText={`刪除此物件(${selectedRecipt?.id})？`}
              onOk={handleDeleteReceipt}
              onClose={handleCloseModal}
            />
          )}
          {modalOpen === 'EXPORT' && (
            <YatungConfirmAlert
              isShowAlert={modalOpen === 'EXPORT'}
              alertText={`已完成匯出，請至下載區查看`}
              onClose={exportFile}
              buttonTitle="確認"
            />
          )}
        </>
      }
    />
  );
}
