import YatungLoading from '@Src/_basic/components/YatungLoading';
import { Box, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CementDetail from './typeDetailModal/CementDetail';
import ConcreteDetail from './typeDetailModal/ConcrteteDetail';
import FlyAshAndSlagDetail from './typeDetailModal/FlyAshAndSlagDetail';

import InfoTitle from '@Src/_basic/icons/InfoTitle';
import Set from '@Src/_basic/icons/Set';
import { useApi } from '@Src/redux/api/apiAction';
import ChemDetail from './typeDetailModal/ChemDetail';

const TestManageDetailIconItem = ({ Icon, label }: any) => {
  return (
    <Stack spacing={1} alignItems="center" justifyContent="flex-start" direction="row" sx={{ p: 2, width: '50%' }}>
      {Icon && <Icon sx={{ fontSize: '32px' }} />}
      <Typography sx={{ minWidth: '60px', fontSize: '40px', flex: 'none', padding: '0 8px 0 0' }}>{label}</Typography>
    </Stack>
  );
};

function TestManageDetailContent(props: any) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const [testDetailType, setTestDetailType] = useState<string>('');

  useEffect(() => {
    setTestDetailType(props.selectedTestDetail.specimenType.typeCode);
  }, [props.selectedTestDetail.specimenType.typeCode]);

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Box
        sx={{
          minWidth: 1000,
          display: 'flex',
          bgcolor: '#003087',
          color: 'white',
          boxShadow: 16,
          borderRadius: '20px',
          opacity: actionLoading ? 0.8 : 1,
          overflow: 'hidden',
          flexDirection: 'column',
        }}
      >
        <Stack spacing={1} direction="row" width="100%">
          <TestManageDetailIconItem Icon={InfoTitle} label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_DETAIL')} />
          <TestManageDetailIconItem
            Icon={Set}
            label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_NOTIFICATION_SET')}
          />
        </Stack>
        {testDetailType === 'CONCRETE' ? (
          <ConcreteDetail {...props} />
        ) : testDetailType === 'CEMENT' ? (
          <CementDetail {...props} />
        ) : testDetailType === 'CONTROL_FLY_ASH' || testDetailType === 'CONTROL_SLAG' ? (
          <FlyAshAndSlagDetail {...props} />
        ) : testDetailType === 'STANDARD_CHEM_ADMX' ? (
          <ChemDetail {...props} />
        ) : null}
      </Box>
      {actionLoading && <YatungLoading overlay={true} />}
    </Stack>
  );
}

export default function TestManageDetailModal({ open = false, ...props }: any) {
  return (
    <Modal open={open}>
      <>
        <TestManageDetailContent {...props} />
      </>
    </Modal>
  );
}
