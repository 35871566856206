import { Box, Dialog, Stack, Typography } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { insertLineBreaks } from '../helpers/LineBreaks';
import YatungButton from './YatungButton';

interface Props {
  isShowAlert: boolean;
  onClose: () => void;
  alertText?: string;
  confirmTitle?: string;
  onOk?: () => void;
  type?: 'ALERT' | 'CONFIRM';
  buttonTitle?: string;
  showWarningIcon?: boolean;
  alertBtnColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'red' | 'blue';
  containerStyle?: React.CSSProperties;
  icon?: JSX.Element;
  fontStyle?: React.CSSProperties;
  headerText?: string;
  isKeyManagement?: boolean;
  noteText?: string;
}

function YatungConfirmAlert({
  isShowAlert,
  onClose,
  alertText,
  onOk,
  type = 'CONFIRM',
  buttonTitle,
  confirmTitle,
  showWarningIcon = false,
  alertBtnColor = 'red',
  containerStyle,
  icon,
  fontStyle,
  headerText,
  isKeyManagement = false,
  noteText,
}: Props) {
  const { t: i18T } = useTranslation();

  const cancelHandler = () => {
    onClose();
  };

  const finishHandler = () => {
    onClose();
    onOk?.();
  };

  return (
    <Dialog
      open={isShowAlert}
      onClose={onClose}
      PaperProps={{ style: { borderRadius: 20, backgroundColor: '#003087', ...containerStyle } }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <Stack display="flex" flexDirection="row" sx={{ minWidth: 400, bgcolor: '#003087', color: '#ffff' }}>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ py: 1, px: 6, width: '100%' }}>
            {showWarningIcon && icon}
            <Typography sx={{ p: 1, fontSize: 28, ...fontStyle }}>{headerText ?? i18T('GLOBAL.WARN')}</Typography>
            {showWarningIcon && icon}
          </Box>
        </Stack>
        <Stack display="flex" alignItems="center" sx={{ bgcolor: '#ffff', minWidth: 400, p: 4 }}>
          {isKeyManagement ? (
            <Box sx={{ px: 5, py: 1 }}>
              <Typography
                sx={{ fontSize: 22, textAlign: 'flex-start', fontWeight: 400 }}
                dangerouslySetInnerHTML={{ __html: insertLineBreaks(noteText, 20) }}
              />
            </Box>
          ) : (
            <Typography
              sx={{ p: 1, pb: 5, fontSize: 22, textAlign: 'center', fontWeight: 900, whiteSpace: 'pre-line' }}
            >
              {alertText ?? `${i18T('GLOBAL.CONFIRM_DELETE')}?`}
            </Typography>
          )}
          {onOk ? (
            <Stack display="flex" flexDirection="row" justifyContent="space-between" sx={{ width: '80%' }}>
              <YatungButton width={100} color="light_red" onClick={cancelHandler}>
                {i18T(`GLOBAL.CANCEL`)}
              </YatungButton>
              <YatungButton width={100} color="blue" onClick={finishHandler}>
                {confirmTitle ? confirmTitle : i18T(`GLOBAL.FINISH`)}
              </YatungButton>
            </Stack>
          ) : (
            <Stack display="flex" flexDirection="row" justifyContent="center" sx={{ width: '100%' }}>
              <YatungButton width={100} color={alertBtnColor} onClick={cancelHandler}>
                {buttonTitle ? buttonTitle : i18T(`GLOBAL.CLOSE`)}
              </YatungButton>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default memo(YatungConfirmAlert);
