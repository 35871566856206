import { Response } from '@Src/_basic/object/ApiType';
import { ProportionOption } from '@Src/_basic/object/MixerAndProportionInformationBindingType';
import { Api } from '../Api';
import { proportionMaintenanceMixerProtocol } from './proportionMaintenanceMixerProtocol';

export class proportionMaintenanceMixerApi {
  // 取得所有配比拌合機代號
  public static getProportionMaintenanceMixer(
    onSuccess?: (data: Array<any>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(
      proportionMaintenanceMixerProtocol.GET_PROPORTION_MAINTENANCE_MIXER_ALL,
      undefined,
      onSuccess,
      onFailed,
      onFinally,
    );
  }
  //取得所有配比選項
  public static getProportionOptions(
    onSuccess?: (data: Array<ProportionOption>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(proportionMaintenanceMixerProtocol.GET_PROPORTION_OPTIONS, undefined, onSuccess, onFailed, onFinally);
  }
}
