import { useAuth } from '@Redux/auth/authActions';
import useI18n from '@Src/_basic/i18n/i18n';
import ErrorBoundary from '@Src/app/ErrorBoundary';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import BasicRouter from './BasicRouter';
import AuthRouter from './module/auth/AuthRouter';

function App() {
  const { token } = useAuth();
  const { setSaveRequest } = useRequestSaving();
  const { isInitialized } = useI18n();

  useEffect(() => {
    if (!token) setSaveRequest(undefined);
  }, [token]);

  return (
    <BrowserRouter>
      <ErrorBoundary>{isInitialized ? !token ? <AuthRouter /> : <BasicRouter /> : <></>} </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
