import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import { Options } from '@Src/_basic/components/YatungSelect';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { concreteTestFormSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  ConcreteSlump,
  ImportConcreteSlump,
  PutConcreteSlumpRequest,
} from '@Src/_basic/object/test/concrete/ConcreteSlumpType';
import { MaterialStatusApi } from '@Src/_basic/protocol/materialStatus/MaterialStatusApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: ConcreteSlump;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  plateNumber: Yup.string().min(4).required(),
  slumpDemand: concreteTestFormSchema,
  visObsSlump: concreteTestFormSchema,
  actualSlump: concreteTestFormSchema,
  beforeTubeSlump: concreteTestFormSchema,
  afterTubeSlump: concreteTestFormSchema,
  airContent: concreteTestFormSchema,
  chlorideIonContent: concreteTestFormSchema,
  chlorideIonContent2: concreteTestFormSchema,
  chlorideIonContent3: concreteTestFormSchema,
  concreteTemp: concreteTestFormSchema,
  specimenNum: concreteTestFormSchema,
});

function ExportConcreteSlumpTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);
  const [selectStatus, setSelectStatus] = useState<number>(testDetail?.materialStatusId);
  const [materialStatus, setMaterialStatus] = useState<Array<Options>>();

  const initialValues = useMemo(
    () => ({
      plateNumber: testDetail?.plateNumber,
      slumpDemand: testDetail?.slumpDemand,
      visObsSlump: testDetail?.visObsSlump,
      actualSlump: testDetail?.actualSlump,
      beforeTubeSlump: testDetail?.beforeTubeSlump,
      afterTubeSlump: testDetail?.afterTubeSlump,
      airContent: testDetail?.airContent,
      chlorideIonContent: testDetail?.chlorideIonContent,
      chlorideIonContent2: testDetail?.chlorideIonContent2,
      chlorideIonContent3: testDetail?.chlorideIonContent3,
      concreteTemp: testDetail?.concreteTemp,
      specimenNum: testDetail?.specimenNum,
    }),
    [testDetail],
  );

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE.SLUMP_FLOW_DEMAND`)}(cm)`,
      values: [`${i18T(`TEST.CONCRETE.VIS_OBS_SLUMP_FLOW`)}(cm)`, `${i18T(`TEST.CONCRETE.SLUMP_TEST`)}(Sec)`],
    }),
    [i18T],
  );

  const firstInfo = useMemo(
    () => [
      {
        title: testDetail?.slumpDemand?.toString(),
        values: [testDetail?.visObsSlump?.toString(), testDetail?.actualSlump?.toString()],
      },
    ],
    [testDetail],
  );

  const secondHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE.BEFORE_TUBE_SLUMP`)}(cm)`,
      values: [`${i18T(`TEST.CONCRETE.AFTER_TUBE_SLUMP`)}(cm)`, `${i18T(`TEST.CONCRETE_FLOW.MATERIAL_STATUS`)}`],
    }),
    [i18T],
  );

  const secondInfo = useMemo(
    () => [
      {
        title: testDetail?.beforeTubeSlump?.toString(),
        values: [testDetail?.beforeTubeSlump?.toString(), testDetail?.materialStatus as string],
      },
    ],
    [testDetail],
  );

  const thirdHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE.AIR_CONTENT`)}(%)`,
      values: [`${i18T(`TEST.CONCRETE.TEMPERATURE`)}(°C)`, `${i18T(`TEST.CONCRETE.CHLORIDE_CONTENT_AVG`)}(kg/m³)`],
    }),
    [i18T],
  );

  const thirdInfo = useMemo(
    () => [
      {
        title: testDetail?.airContent?.toString(),
        values: [testDetail?.concreteTemp?.toString(), testDetail?.chlorideIonContentAvg?.toString() ?? ''],
        titleStyle: {
          ...(testDetail?.verifyPassedResults?.airContent && testDetail?.verifyCustomResults?.airContent?.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal),
        },
      },
    ],
    [testDetail],
  );

  const fourthHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE.SPECIMEN_COUNT`)}(${i18T(`TEST.CONCRETE.PCS`)})`,
    }),
    [i18T],
  );

  const fourthInfo = useMemo(
    () => [
      {
        title: testDetail?.specimenNum?.toString(),
      },
    ],
    [testDetail],
  );

  const sixthHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE.CHLORIDE_CONTENT`)}1(kg/m³)`,
      values: [`${i18T(`TEST.CONCRETE.CHLORIDE_CONTENT`)}2(kg/m³)`, `${i18T(`TEST.CONCRETE.CHLORIDE_CONTENT`)}3(kg/m³)`],
    }),
    [i18T],
  );

  const sixthInfo = useMemo(
    () => [
      {
        title: testDetail?.chlorideIonContent?.toString() ?? '',
        values: [testDetail?.chlorideIonContent2?.toString() ?? '', testDetail?.chlorideIonContent3?.toString() ?? ''],
      },
    ],
    [testDetail],
  );

  const fifthHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const fifthInfo = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
          titles: verifyResultText,
          titlesStyle: [TestVerifyResultStyles.normal],
        }
        : {
          titles: verifyResultText,
          titlesStyle: [
            isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
          ],
        },
    ],
    [isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  useEffect(() => {
    MaterialStatusApi.getAllMaterialStatus((_data) =>
      setMaterialStatus(_data.map((item) => ({ text: item.name, value: item.id }))),
    );
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportConcreteSlump = {
        plateNumber: values.plateNumber,
        slumpDemand: checkIsNaN(+values.slumpDemand),
        visObsSlump: checkIsNaN(+values.visObsSlump),
        actualSlump: checkIsNaN(+values.actualSlump),
        beforeTubeSlump: checkIsNaN(+values.beforeTubeSlump),
        afterTubeSlump: checkIsNaN(+values.afterTubeSlump),
        airContent: checkIsNaN(+values.airContent),
        chlorideIonContent: checkIsNaN(+values.chlorideIonContent),
        chlorideIonContent2: checkIsNaN(+values.chlorideIonContent2),
        chlorideIonContent3: checkIsNaN(+values.chlorideIonContent3),
        concreteTemp: checkIsNaN(+values.concreteTemp),
        specimenNum: values.specimenNum,
        materialStatusId: selectStatus,
      };

      TestApi.putTest<PutConcreteSlumpRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  const editView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack gap={2}>
            <Stack flexDirection="row">
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.SLUMP_FLOW_DEMAND`)}(cm)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.slumpDemand}
                  isError={!!(formik.touched.slumpDemand && formik.errors.slumpDemand)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('slumpDemand', e.target.value)}
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.VIS_OBS_SLUMP_FLOW`)}(cm)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.visObsSlump}
                  isError={!!(formik.touched.visObsSlump && formik.errors.visObsSlump)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('visObsSlump', e.target.value)}
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.SLUMP_TEST`)}(Sec)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.actualSlump}
                  isError={!!(formik.touched.actualSlump && formik.errors.actualSlump)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('actualSlump', e.target.value)}
                />
              </ColFieldInfo>
            </Stack>
            <Stack flexDirection="row">
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.BEFORE_TUBE_SLUMP`)}(cm)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.beforeTubeSlump}
                  isError={!!(formik.touched.beforeTubeSlump && formik.errors.beforeTubeSlump)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('beforeTubeSlump', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.AFTER_TUBE_SLUMP`)}(cm)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.afterTubeSlump}
                  isError={!!(formik.touched.afterTubeSlump && formik.errors.afterTubeSlump)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('afterTubeSlump', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 22,
                }}
                title={`${i18T(`TEST.CONCRETE_FLOW.MATERIAL_STATUS`)}`}
              >
                <YatungRadioButton
                  groupName="status"
                  value={selectStatus}
                  onChange={(e) => {
                    switch (e.target.value) {
                      case '1':
                        setSelectStatus(1);
                        break;
                      case '2':
                        setSelectStatus(2);
                        break;
                      case '3':
                        setSelectStatus(3);
                        break;
                      case '4':
                        setSelectStatus(4);
                        break;
                      case '5':
                        setSelectStatus(5);
                        break;
                      default:
                        break;
                    }
                  }}
                  controlData={materialStatus?.map((item) => ({ label: item.text as string, value: item.value })) ?? []}
                  labelTextStyle={{
                    fontSize: '20px',
                    fontWeight: '600',
                    fontFamily: 'Microsoft JhengHei',
                    color: '#002F8C',
                  }}
                />
              </ColFieldInfo>
            </Stack>
            <Stack flexDirection="row">
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.AIR_CONTENT`)}(%)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.airContent}
                  isError={!!(formik.touched.airContent && formik.errors.airContent)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('airContent', e.target.value)}
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.CHLORIDE_CONTENT`)}1(kg/m³)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.chlorideIonContent}
                  isError={!!(formik.touched.chlorideIonContent && formik.errors.chlorideIonContent)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('chlorideIonContent', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.CHLORIDE_CONTENT`)}2(kg/m³)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.chlorideIonContent2}
                  isError={!!(formik.touched.chlorideIonContent2 && formik.errors.chlorideIonContent2)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('chlorideIonContent2', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.CHLORIDE_CONTENT`)}3(kg/m³)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.chlorideIonContent3}
                  isError={!!(formik.touched.chlorideIonContent3 && formik.errors.chlorideIonContent3)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('chlorideIonContent3', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE.TEMPERATURE`)}(°C)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.concreteTemp}
                  isError={!!(formik.touched.concreteTemp && formik.errors.concreteTemp)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('concreteTemp', e.target.value)}
                />
              </ColFieldInfo>
            </Stack>
          </Stack>
        );
      case false:
        return (
          <>
            <TestTable headerData={firstHeader} rows={firstInfo} />
            <TestTable headerData={secondHeader} rows={secondInfo} />
            <TestTable headerData={sixthHeader} rows={sixthInfo} />
            <TestTable headerData={thirdHeader} rows={thirdInfo} />
            <TestTable headerData={fourthHeader} rows={fourthInfo} />
          </>
        );
    }
  }, [
    edit,
    firstHeader,
    firstInfo,
    secondHeader,
    secondInfo,
    thirdHeader,
    thirdInfo,
    fourthHeader,
    fourthInfo,
    selectStatus,
    i18T,
    formik,
    materialStatus,
    sixthHeader,
    sixthInfo,
  ]);

  return (
    <Stack gap={3}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {editView}
      </TestCardContainer>
      <TestCardContainer>
        <TestTable headerData={fifthHeader} rows={fifthInfo} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportConcreteSlumpTable);
