import { Stack, SxProps, Typography as MuiTypography, styled } from '@mui/material';
import React from 'react';

interface Props {
  label: string;
  isStar?: boolean;
  width?: string | number;
  sx?: SxProps;
  isTableLabel?: boolean;
}

export default function EditAccountLabel(props: Props) {
  const { label, isStar, isTableLabel } = props;
  const Typography = styled(MuiTypography)(
    (e) =>
      isTableLabel && {
        padding: '8px 12px',
        borderRadius: 8,
        lineHeight: '1.4375rem',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#003087',
      },
  );

  return !isTableLabel ? (
    <Stack direction={'row'}>
      <Typography color="red" sx={{ mt: 2 }}>
        {isStar ? '*' : '\xa0'}
      </Typography>
      <Typography sx={{ width: '130px', fontSize: 20, fontWeight: 900, mt: 1, ml: 1, mr: 5 }}>{label}</Typography>
    </Stack>
  ) : (
    <Stack>
      <Typography
        sx={{
          letterSpacing: label?.length == 6 ? 1 : 8,
          width: '150px',
          textAlign: 'center',
          fontSize: 20,
          fontWeight: 900,
          ...props.sx,
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
}
