import { Grid, Pagination, SelectChangeEvent, Stack } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';

import useGetAllTypes from '@Src/_basic/apiHooks/useGetAllTypes';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import AddCircleIcon from '@Src/_basic/icons/AddCircle';
import { Pageable } from '@Src/_basic/object/ApiType';
import { CustomStandardData, SearchPageableCustomStandardsParams } from '@Src/_basic/object/CustomStandardType';
import { ExperimentResultTypeData } from '@Src/_basic/object/ExperimentResultTypeType';
import { SearchExperimentResultTypesParams } from '@Src/_basic/object/ExperimentResultTypeTypeType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { TestItemEnum } from '@Src/_basic/object/TestType';
import { ExperimentItemApi } from '@Src/_basic/protocol/experimentItem/ExperimentItemApi';
import { ExperimentResultTypeApi } from '@Src/_basic/protocol/experimentResultType/ExperimentResultTypeApi';
import { StandardV2Api } from '@Src/_basic/protocol/standardV2/custom/CustomStandardV2Api';
import { useApi } from '@Src/redux/api/apiAction';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomStandardsTable from '../components/CustomStandardTable';

const background = '/media/images/applicationsMange/customStandardBG.png';

type SelectType = {
  factoryId: number;
  typeId: number;
};

type QueryType = SelectType & {
  experimentResultTypeId: number;
  disabled: boolean | number;
};

type FilterValueType = QueryType & {
  areaId: number;
  exptItemCode: number | TestItemEnum;
  experimentResultTypeId: number;
};

type SearchParams = QueryType & {
  page: number;
};

type AsyncStorageSavingType = SearchParams & FilterValueType;

const initDisabledOptions: Options[] = [
  { value: false, text: '生效' },
  { value: true, text: '關閉' },
];

export default function CustomStandardsScreen() {
  const { t: i18T } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { actionLoading } = useApi();
  const { typeOption } = useGetAllTypes([9]);
  const { setSaveRequest, request } = useRequestSaving<AsyncStorageSavingType>();

  const effectRef = useRef<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [customStandards, setCustomStandards] = useState<Array<CustomStandardData>>([]);
  const [activatedCustomStandardIds, setActivatedCustomStandardIds] = useState<Array<number>>([]);
  const [experimentItemOptions, setExperimentItemOptions] = useState<Options[]>([]);
  const [experimentResultTypeOptions, setExperimentResultTypeOptions] = useState<Options[]>([]);

  const queryObject = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);
  const comparison = useMemo(
    () => request?.page === location.pathname && Number(queryObject.typeId) === request.request.typeId,
    [request, location, queryObject],
  );

  const initFilterValue = useMemo(() => {
    const {
      exptItemCode = 0,
      areaId = 0,
      factoryId = 0,
      experimentResultTypeId = 0,
      disabled = 0,
    } = comparison && request?.request ? request.request : {};

    const typeId = comparison && request?.request ? request.request.typeId : Number(queryObject.typeId);

    return {
      typeId,
      exptItemCode,
      areaId,
      factoryId,
      experimentResultTypeId,
      disabled,
    };
  }, [comparison, request?.request, queryObject.typeId]);

  const initQueryValue = useMemo(
    () => ({
      typeId: initFilterValue.typeId,
      factoryId: initFilterValue.factoryId,
      disabled: initFilterValue.disabled,
      experimentResultTypeId: initFilterValue.experimentResultTypeId,
    }),
    [initFilterValue],
  );

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const getExperimentItemOptions = useCallback((typeId: number) => {
    ExperimentItemApi.getAllExperimentItems({ typeId }, (_data) =>
      setExperimentItemOptions(_data.map(({ id, itemName }) => ({ value: id, text: itemName }))),
    );
  }, []);

  const getExperimentResultTypeOptions = (experimentItemId: number) => {
    const searchParams: SearchExperimentResultTypesParams = { experimentItemId };
    ExperimentResultTypeApi.getExperimentResultTypesBySearch(searchParams, (_data) => {
      if (_data.length > 0) {
        setExperimentResultTypeOptions(
          _data.map(({ id, name }: ExperimentResultTypeData) => ({ value: id, text: name })),
        );
      } else {
        handleChange('experimentResultTypeId')(0);
        setExperimentResultTypeOptions([]);
      }
    });
  };

  const onGetLegalStandardsSuccess = useCallback((data: Pageable<CustomStandardData>) => {
    setCustomStandards(data.content);
    pageInfoDispatch({ total: data.totalPages as number, totalCount: data.totalElements as number });
    setIsVisible(true);
  }, []);

  const getLegalStandards = useCallback(() => {
    const { disabled, typeId, ...params } = query;
    const searchParams: SearchPageableCustomStandardsParams = {
      ...params,
      ...(typeof disabled !== 'number' && { disabled: disabled }),
      page: pageInfo.page - 1,
      size: pageInfo.pageSize,
      sort: 'id,desc',
    };
    setSaveRequest({ page: location.pathname, request: { ...filterValue, ...query, page: pageInfo.page - 1 } });
    StandardV2Api.getCancelablePageableCustomStandardsBySearch(searchParams, onGetLegalStandardsSuccess);
  }, [query, onGetLegalStandardsSuccess, pageInfo, setSaveRequest, filterValue, location.pathname]);

  const getActivedLegalStandardIds = useCallback(() => {
    const { disabled, typeId, ...params } = query;
    const searchParams = {
      ...params,
      ...(typeof disabled !== 'number' && { disabled: disabled }),
      effectiveUnixTime: new Date().getTime(),
    };
    StandardV2Api.getActivatedCustomStandardIds(searchParams, setActivatedCustomStandardIds);
  }, [query]);

  const isActivatedCustomStandard = useCallback(
    (id: number) => activatedCustomStandardIds.includes(id),
    [activatedCustomStandardIds],
  );

  const handleCreateButtonClick = useCallback(() => {
    const stringFilterValue: Record<string, string> = {};

    Object.keys(filterValue).forEach((key) => {
      stringFilterValue[key] = filterValue[key as keyof FilterValueType].toString();
    });

    navigate(`/qualityManagement/customStandards/create?${new URLSearchParams(stringFilterValue)}`);
  }, [navigate, filterValue]);

  const handleDetailButtonClick = useCallback(
    (row: any) => {
      navigate(`/qualityManagement/customStandards/view?customStandardId=${row.id}&typeId=${filterValue.typeId}`);
    },
    [navigate, filterValue.typeId],
  );

  const onToggleEnableStatusSuccess = useCallback(() => {
    getLegalStandards();
    getActivedLegalStandardIds();
  }, [getLegalStandards, getActivedLegalStandardIds]);

  const handleToggleEnableStatus = useCallback(
    (row: any) => {
      StandardV2Api.toggleCustomStandardEnableStatusById(row.id, onToggleEnableStatusSuccess);
    },
    [onToggleEnableStatusSuccess],
  );

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
  };

  const handleTypeChange = (typeId: number) => {
    handleChange('typeId')(typeId);
    handleFilterChange('exptItemCode')(0);
    handleChange('experimentResultTypeId')(0);
    getExperimentItemOptions(typeId);
  };

  const handleExptItemCode = (e: SelectChangeEvent<unknown>) => {
    const exptItemCode = e.target.value as number;
    handleFilterChange('exptItemCode')(exptItemCode);
    handleChange('experimentResultTypeId')(0);
    getExperimentResultTypeOptions(exptItemCode);
  };

  useEffect(() => {
    handleTypeChange(filterValue.typeId);
  }, [filterValue.typeId]);

  useEffect(() => {
    setIsVisible(false);
    if (!query.factoryId || !query.experimentResultTypeId) return;
    getActivedLegalStandardIds();
    getLegalStandards();
  }, [query, pageInfo.page]);

  useEffect(() => {
    if (!effectRef.current && comparison && request?.request) {
      effectRef.current = true;
      new Promise<void>((resolve) => {
        handleTypeChange(request.request.typeId);
        getExperimentResultTypeOptions(request.request.exptItemCode as number);
        resolve();
      }).then(() => {
        handleFilterChange('exptItemCode')(request.request.exptItemCode);
        setTimeout(() => handleChange('experimentResultTypeId')(request.request.experimentResultTypeId), 0);
      });
    }
  }, [effectRef]);

  return (
    <YatungPage
      title={i18T('QUALITY_MANAGE.SIDE_MENU.STANDARDIZED_PENALTY_MANAGEMENT.CUSTOM_STANDARD')}
      backgroundImage={background}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
              <YatungCombinationSelect<SelectType, FilterValueType>
                filterValue={filterValue}
                handleChange={handleChange}
                handleFilterChange={handleFilterChange}
                selectOptions={['AreaAndFactory']}
              />
              <YatungSelect
                disabled={typeOption.length === 0 || actionLoading}
                options={typeOption.map((item) => (item.value === 7 ? { value: item.value, text: '拌合水' } : item))}
                value={filterValue.typeId}
                onChange={(e) => handleTypeChange(e.target.value as number)}
              />
              <YatungSelect
                disabled={experimentItemOptions.length === 0 || actionLoading}
                options={experimentItemOptions}
                value={filterValue.exptItemCode}
                onChange={handleExptItemCode}
              />
              <YatungSelect
                disabled={experimentResultTypeOptions.length === 0 || actionLoading}
                options={experimentResultTypeOptions}
                value={filterValue.experimentResultTypeId}
                onChange={(e) => handleChange('experimentResultTypeId')(e.target.value as number)}
              />
              <YatungSelect
                disabled={initDisabledOptions.length === 0 || actionLoading}
                options={[{ value: 0, text: '全部' }, ...initDisabledOptions]}
                value={filterValue.disabled}
                onChange={(e) => handleChange('disabled')(e.target.value as number | boolean)}
              />
            </Stack>
            <YatungButton
              text={i18T('QUALITY_MANAGE.STANDARD.TABLE_ACTION.CREATE')}
              startIcon={<AddCircleIcon />}
              color="green"
              onClick={handleCreateButtonClick}
            />
          </Stack>
          {
            <YatungFadeInOut isVisible={isVisible}>
              <>
                <CustomStandardsTable
                  rows={customStandards}
                  onViewDetail={handleDetailButtonClick}
                  isActivatedCustomStandard={isActivatedCustomStandard}
                  onToggleEnableStatus={handleToggleEnableStatus}
                />
                <Grid container alignItems={'center'} item xs={12} sx={{ p: 1 }}>
                  <Grid container justifyContent={'center'} item xs={11}>
                    <Pagination
                      count={pageInfo.total}
                      page={pageInfo.page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Grid>
                  <Grid container justifyContent={'flex-end'} item xs={1}>
                    <YatungPaperLabel label={`總數：${pageInfo.totalCount}`} totalCount />
                  </Grid>
                </Grid>
              </>
            </YatungFadeInOut>
          }
        </>
      }
    />
  );
}
