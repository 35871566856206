import {
  TestCardContainer,
  TestDetailContainer,
  TestVerifyResultStyles,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { basicNumberSchema, notRequiredNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { AllTest, ExperimentResultType } from '@Src/_basic/object/TestType';
import { Ams, ImportAmsType, PutAmsRequest } from '@Src/_basic/object/test/sandRock/AmsType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: Ams;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  beforeRinsePhScale: basicNumberSchema,
  afterRinsePhScale: notRequiredNumberSchema,
  beforeMagAttractionWeight: basicNumberSchema,
  afterMagAttractionWeight: basicNumberSchema,
});

function ExportAmsTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const initialValues = useMemo(
    () => ({
      beforeRinsePhScale: testDetail?.beforeRinsePhScale,
      afterRinsePhScale: testDetail?.afterRinsePhScale,
      beforeMagAttractionWeight: testDetail?.beforeMagAttractionWeight,
      afterMagAttractionWeight: testDetail?.afterMagAttractionWeight,
    }),
    [testDetail],
  );

  const [legalStandard, setLegalStandard] = useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [changeColor, setChangeColor] = useState<boolean>(testDetail?.colorChanged);
  const [edit, setEdit] = useState<boolean>(false);

  const getAfterRinsePhScaleMaxValue = () => {
    return legalStandard?.get('afterRinsePhScale')?.maxValue || 0;
  };

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.AMS_ANALYSIS,
        effectiveUnixTime: testDetail?.completeTime || Date.now(),
        type: 1,
      },
      (_data) => {
        const legalStandardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          legalStandardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandard(legalStandardMap);
      },
      () => undefined,
    );
  }, [testDetail]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportAmsType = {
        colorChanged: changeColor,
        beforeRinsePhScale: checkIsNaN(+values.beforeRinsePhScale),
        afterRinsePhScale: checkIsNaN(+values.afterRinsePhScale),
        beforeMagAttractionWeight: checkIsNaN(+values.beforeMagAttractionWeight),
        afterMagAttractionWeight: checkIsNaN(+values.afterMagAttractionWeight),
      };
      TestApi.putTest<PutAmsRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const colorChanged = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <YatungRadioButton
            groupName="isColorChanged"
            value={changeColor}
            onChange={(e) => {
              switch (e.target.value) {
                case 'true':
                  setChangeColor(true);
                  break;
                case 'false':
                  setChangeColor(false);
                  break;
                default:
                  break;
              }
            }}
            controlData={[
              { label: '變色', value: true },
              { label: '無變色', value: false },
            ]}
            labelTextStyle={{
              fontSize: '20px',
              fontWeight: '600',
              fontFamily: 'Microsoft JhengHei',
              color: '#002F8C',
            }}
          />
        );
      case false:
        return testDetail?.colorChanged ? i18T('TEST.AMS.V_COLOR_CHANGED') : i18T('TEST.AMS.V_COLOR_UNCHANGED');
      default:
        break;
    }
  }, [edit, testDetail?.colorChanged, changeColor, i18T]);

  const beforeRinsePhScale = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.beforeRinsePhScale}
            isError={!!(formik.touched.beforeRinsePhScale && formik.errors.beforeRinsePhScale)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('beforeRinsePhScale', e.target.value)}
          />
        );
      case false:
        return testDetail?.beforeRinsePhScale;
      default:
        break;
    }
  }, [edit, formik, testDetail?.beforeRinsePhScale]);

  const afterRinsePhScale = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.afterRinsePhScale}
            isError={!!(formik.touched.afterRinsePhScale && formik.errors.afterRinsePhScale)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('afterRinsePhScale', e.target.value)}
          />
        );
      case false:
        return testDetail?.afterRinsePhScale;
      default:
        break;
    }
  }, [edit, formik, testDetail?.afterRinsePhScale]);

  const beforeMagAttractionWeight = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.beforeMagAttractionWeight}
            isError={!!(formik.touched.beforeMagAttractionWeight && formik.errors.beforeMagAttractionWeight)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formik.setFieldValue('beforeMagAttractionWeight', e.target.value)
            }
          />
        );
      case false:
        return testDetail?.beforeMagAttractionWeight;
      default:
        break;
    }
  }, [edit, formik, testDetail?.beforeMagAttractionWeight]);

  const afterMagAttractionWeight = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.afterMagAttractionWeight}
            isError={!!(formik.touched.afterMagAttractionWeight && formik.errors.afterMagAttractionWeight)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formik.setFieldValue('afterMagAttractionWeight', e.target.value)
            }
          />
        );
      case false:
        return testDetail?.afterMagAttractionWeight;
      default:
        break;
    }
  }, [edit, formik, testDetail?.afterMagAttractionWeight]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <TestDetailContainer>
          <ColFieldInfo
            titleStyle={TestVerifyResultStyles.resultTitle}
            title={i18T('TEST.AMS.PHENOLPHTHALEIN_PAPER_CHANGE_COLOR')}
            childrenStyle={
              testDetail?.verifyPassedResults?.colorChanged && testDetail?.verifyCustomResults?.colorChanged?.passed
                ? TestVerifyResultStyles.normal
                : TestVerifyResultStyles.abnormal
            }
          >
            {colorChanged}
          </ColFieldInfo>
          <Stack flexDirection="row" gap={2}>
            <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
          </Stack>
        </TestDetailContainer>

        <TestDetailContainer>
          <ColFieldInfo
            titleStyle={TestVerifyResultStyles.resultTitle}
            title={i18T('TEST.AMS.BEFORE_RINSE_DRY_PH')}
            childrenStyle={
              testDetail?.beforeRinsePhScale <= getAfterRinsePhScaleMaxValue()
                ? TestVerifyResultStyles.normal
                : TestVerifyResultStyles.abnormal
            }
          >
            {beforeRinsePhScale}
          </ColFieldInfo>
          {testDetail?.beforeRinsePhScale > getAfterRinsePhScaleMaxValue() && (
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={i18T('TEST.AMS.AFTER_RINSE_DRY_PH')}
              childrenStyle={
                testDetail?.verifyPassedResults?.afterRinsePhScale &&
                testDetail?.verifyCustomResults?.afterRinsePhScale?.passed
                  ? TestVerifyResultStyles.normal
                  : TestVerifyResultStyles.abnormal
              }
            >
              {afterRinsePhScale}
            </ColFieldInfo>
          )}
        </TestDetailContainer>

        <TestDetailContainer>
          <ColFieldInfo
            titleStyle={TestVerifyResultStyles.resultTitle}
            title={`${i18T('TEST.AMS.BEFORE_MAGNETIC_ATTRACTION')}(g)`}
          >
            {beforeMagAttractionWeight}
          </ColFieldInfo>
          <ColFieldInfo
            titleStyle={TestVerifyResultStyles.resultTitle}
            title={`${i18T('TEST.AMS.AFTER_MAGNETIC_ATTRACTION')}(g)`}
          >
            {afterMagAttractionWeight}
          </ColFieldInfo>
        </TestDetailContainer>
        <TestDetailContainer>
          <ColFieldInfo
            titleStyle={TestVerifyResultStyles.resultTitle}
            title={`${i18T('TEST.AMS.MAG_ATTRACTION_RATIO')}(%)`}
            childrenStyle={
              testDetail?.verifyPassedResults?.magAttractionRatio &&
              testDetail?.verifyCustomResults?.magAttractionRatio?.passed
                ? TestVerifyResultStyles.normal
                : TestVerifyResultStyles.abnormal
            }
          >
            {`${(
              ((testDetail?.beforeMagAttractionWeight - testDetail?.afterMagAttractionWeight) /
                testDetail?.beforeMagAttractionWeight) *
              100
            ).toFixed(2)}${' '}(%)`}
          </ColFieldInfo>
        </TestDetailContainer>
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={{
            title: i18T(`TEST.BASE.DETECTION`),
          }}
          rows={[
            isLegalPassed && isCustomPassed && !isCustomRejected
              ? {
                  titles: verifyResultText,
                  titlesStyle: [TestVerifyResultStyles.normal],
                }
              : {
                  titles: verifyResultText,
                  titlesStyle: [
                    isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                    isCustomPassed && !isCustomRejected
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal,
                  ],
                },
          ]}
          isShowCollapse={true}
        />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportAmsTable);
