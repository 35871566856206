import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import CementitiousBiScreen from './screens/CementitiousBiScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <CementitiousBiScreen />,
  },
];

const CementitiousRouter = () => PermissionRoute(routesConfig);

export default memo(CementitiousRouter);
