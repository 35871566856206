import AdapterDateFns from '@date-io/date-fns';
import { Typography, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import zhLocale from 'date-fns/locale/zh-TW'; // 引入中文語言包
import React from 'react';
import CalendarIcon from '../icons/Calendar';
import YatungInput from './YatungInput';
interface Props {
  icon?: any;
  value: Date | null;
  onChange: (value: Date | null) => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxDate?: any;
  minDate?: any;
  disabled?: boolean;
  label?: string;
  fullWidth?: boolean;
  error?: string;
}

const Label = styled(Typography)(({ theme }) => ({
  color: '#6C708C',
  fontSize: '16px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
}));

export default function YatungDateSelect({ icon = CalendarIcon, value, onChange, fullWidth, error, ...props }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhLocale}>
      <DatePicker
        inputFormat="yyyy/MM/dd"
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <YatungInput
            {...params}
            error={error}
            helperText={error}
            sx={{ ...props, width: fullWidth ? '100%' : 180 }}
          />
        )}
        components={{
          OpenPickerIcon: icon,
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}
