import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function SandBig(props: SvgIconProps) {
    return (
        <svg {...props} width="84" height="26" viewBox="0 0 84 26" fill="none" >
            <path d="M21.4337 11.1124C21.4337 12.0565 20.6683 12.822 19.7242 12.822C18.7801 12.822 18.0146 12.0565 18.0146 11.1124C18.0146 10.8545 18.0714 10.6099 18.174 10.3912C18.1891 10.3586 18.2049 10.3269 18.2224 10.296C18.2442 10.256 18.2667 10.2167 18.2917 10.1792C18.3243 10.1291 18.3602 10.0798 18.3986 10.0331C18.4261 9.99968 18.4545 9.96713 18.4846 9.93541C18.7959 9.60735 19.2367 9.40283 19.7242 9.40283C20.6683 9.40283 21.4337 10.1683 21.4337 11.1124Z" fill="#7C878E" />
            <path d="M13.7407 18.8055C14.6848 18.8055 15.4502 18.04 15.4502 17.0959C15.4502 16.1518 14.6848 15.3864 13.7407 15.3864C12.7965 15.3864 12.0311 16.1518 12.0311 17.0959C12.0311 18.04 12.7965 18.8055 13.7407 18.8055Z" fill="#7C878E" />
            <path d="M8.61192 24.789C9.55603 24.789 10.3215 24.0236 10.3215 23.0794C10.3215 22.1353 9.55603 21.3699 8.61192 21.3699C7.66781 21.3699 6.90234 22.1353 6.90234 23.0794C6.90234 24.0236 7.66781 24.789 8.61192 24.789Z" fill="#7C878E" />
            <path d="M26.5625 17.0959C26.5625 17.6669 26.2828 18.1719 25.8529 18.4825C25.5716 18.6853 25.226 18.8055 24.8529 18.8055C23.9088 18.8055 23.1433 18.04 23.1433 17.0959C23.1433 16.8505 23.1951 16.6168 23.2886 16.4056C23.4029 16.1476 23.5782 15.9231 23.7969 15.7511C24.0874 15.5224 24.4539 15.3864 24.8529 15.3864C25.1492 15.3864 25.4289 15.4615 25.6718 15.5942C25.8755 15.7061 26.0541 15.858 26.1969 16.0391C26.297 16.1668 26.3797 16.3088 26.4406 16.4615C26.5191 16.6577 26.5625 16.8714 26.5625 17.0959Z" fill="#7C878E" />
            <path d="M19.7242 24.789C20.6683 24.789 21.4337 24.0236 21.4337 23.0794C21.4337 22.6512 21.276 22.2597 21.0155 21.96C20.7025 21.5986 20.24 21.3699 19.7242 21.3699C19.2125 21.3699 18.7534 21.5944 18.4403 21.9509C18.1749 22.2522 18.0146 22.647 18.0146 23.0794C18.0146 24.0236 18.7801 24.789 19.7242 24.789Z" fill="#7C878E" />
            <path d="M35.9652 18.8055C36.9093 18.8055 37.6747 18.04 37.6747 17.0959C37.6747 16.5425 37.4118 16.0508 37.0044 15.7386C36.7164 15.5174 36.3558 15.3864 35.9652 15.3864C35.021 15.3864 34.2556 16.1518 34.2556 17.0959C34.2556 18.04 35.021 18.8055 35.9652 18.8055Z" fill="#7C878E" />
            <path d="M32.546 23.0794C32.546 23.4818 32.4066 23.8516 32.1737 24.1438C31.8607 24.5369 31.3782 24.789 30.8364 24.789C29.8923 24.789 29.1268 24.0236 29.1268 23.0794C29.1268 22.1353 29.8923 21.3699 30.8364 21.3699C31.7805 21.3699 32.546 22.1353 32.546 23.0794Z" fill="#7C878E" />
            <path d="M30.8364 12.822C31.7805 12.822 32.546 12.0565 32.546 11.1124C32.546 10.7276 32.4183 10.372 32.2037 10.0857C32.132 9.9905 32.051 9.90285 31.9617 9.82522C31.9266 9.79517 31.8907 9.76595 31.854 9.7384C31.8106 9.70668 31.7663 9.67663 31.7204 9.64908C31.4625 9.49299 31.1603 9.40283 30.8364 9.40283C30.4491 9.40283 30.091 9.53222 29.8038 9.74926L29.7112 9.82438C29.6811 9.85026 29.6527 9.87697 29.6252 9.90535L29.5734 9.95961C29.5092 10.0297 29.4507 10.1057 29.399 10.1858C29.227 10.453 29.1268 10.771 29.1268 11.1124C29.1268 12.0565 29.8923 12.822 30.8364 12.822Z" fill="#7C878E" />
            <path d="M48.787 17.0959C48.787 18.04 48.0215 18.8055 47.0774 18.8055C46.7151 18.8055 46.3795 18.6928 46.1032 18.5008C45.6583 18.192 45.3678 17.6778 45.3678 17.0959C45.3678 16.6861 45.5122 16.3104 45.7526 16.0158C46.0657 15.6318 46.5432 15.3864 47.0774 15.3864C47.4697 15.3864 47.8312 15.5182 48.12 15.7403C48.5257 16.0533 48.787 16.5442 48.787 17.0959Z" fill="#7C878E" />
            <path d="M42.4729 24.7072C42.6023 24.6655 42.725 24.6087 42.8394 24.5386C43.144 24.3524 43.3861 24.0736 43.5255 23.7414C43.5806 23.6087 43.6207 23.4676 43.6416 23.3207C43.6491 23.2664 43.6541 23.2113 43.6566 23.1554L43.6582 23.0794C43.6582 22.5385 43.407 22.056 43.0146 21.743C42.7225 21.5093 42.3519 21.3699 41.9487 21.3699C41.8151 21.3699 41.6849 21.3849 41.5605 21.4141C41.4461 21.4408 41.3368 21.4784 41.2324 21.526C41.1774 21.5518 41.1239 21.5802 41.0722 21.6111C40.9862 21.6629 40.9044 21.7221 40.8293 21.7872C40.4678 22.1003 40.2391 22.5636 40.2391 23.0794C40.2391 24.0236 41.0046 24.789 41.9487 24.789C42.1315 24.789 42.3076 24.7598 42.4729 24.7072Z" fill="#7C878E" />
            <path d="M59.8992 17.0959C59.8992 18.04 59.1338 18.8055 58.1896 18.8055C57.2455 18.8055 56.4801 18.04 56.4801 17.0959C56.4801 16.1518 57.2455 15.3864 58.1896 15.3864C59.1338 15.3864 59.8992 16.1518 59.8992 17.0959Z" fill="#7C878E" />
            <path d="M53.0609 24.789C54.005 24.789 54.7705 24.0236 54.7705 23.0794C54.7705 22.5377 54.5184 22.0552 54.1261 21.7422C53.9716 21.6186 53.7947 21.5218 53.6035 21.4575C53.4332 21.4008 53.2504 21.3699 53.0609 21.3699C52.1168 21.3699 51.3513 22.1353 51.3513 23.0794C51.3513 23.3107 51.3973 23.5319 51.4807 23.7331C51.5483 23.895 51.6402 24.0444 51.7512 24.1772C51.8539 24.2999 51.9741 24.4084 52.1076 24.4985C52.3798 24.6822 52.7078 24.789 53.0609 24.789Z" fill="#7C878E" />
            <path d="M54.7705 11.1124C54.7705 12.0565 54.005 12.822 53.0609 12.822C52.5091 12.822 52.0183 12.5607 51.7053 12.155C51.6627 12.0991 51.6235 12.0407 51.5876 11.9797C51.5317 11.8854 51.4849 11.7852 51.4482 11.68C51.3856 11.5022 51.3513 11.3111 51.3513 11.1124C51.3513 10.1683 52.1168 9.40283 53.0609 9.40283C54.005 9.40283 54.7705 10.1683 54.7705 11.1124Z" fill="#7C878E" />
            <path d="M69.3019 18.8055C70.246 18.8055 71.0115 18.04 71.0115 17.0959C71.0115 16.1518 70.246 15.3864 69.3019 15.3864C68.3578 15.3864 67.5923 16.1518 67.5923 17.0959C67.5923 18.04 68.3578 18.8055 69.3019 18.8055Z" fill="#7C878E" />
            <path d="M65.8827 23.0794C65.8827 24.0236 65.1173 24.789 64.1732 24.789C63.6206 24.789 63.1289 24.5269 62.8167 24.1196C62.5955 23.8316 62.4636 23.4709 62.4636 23.0794C62.4636 22.1353 63.2291 21.3699 64.1732 21.3699C65.1173 21.3699 65.8827 22.1353 65.8827 23.0794Z" fill="#7C878E" />
            <path d="M64.1732 12.822C65.1173 12.822 65.8827 12.0565 65.8827 11.1124C65.8827 10.1683 65.1173 9.40283 64.1732 9.40283C63.2291 9.40283 62.4636 10.1683 62.4636 11.1124C62.4636 12.0565 63.2291 12.822 64.1732 12.822Z" fill="#7C878E" />
            <path d="M1.23153 25.0807L22.5562 2.13076C23.1255 1.55311 23.7849 1.28264 24.4252 1.28264C25.0654 1.28264 25.7249 1.55311 26.2942 2.13076L47.6188 25.0807L48.2449 24.498L42.324 18.1263L56.7502 2.12825C57.3187 1.55227 57.9781 1.28264 58.6167 1.28264C59.257 1.28264 59.9164 1.55311 60.4857 2.13076L81.8104 25.0807L82.4364 24.498L61.1076 1.54392L61.1001 1.53558C60.3872 0.809339 59.5124 0.427856 58.6167 0.427856C57.7219 0.427856 56.847 0.809339 56.1342 1.53558L56.1275 1.54225L41.7397 17.4977L26.9161 1.54392L26.9077 1.53558C26.1957 0.809339 25.32 0.427856 24.4252 0.427856C23.5303 0.427856 22.6547 0.809339 21.9426 1.53558L0.605469 24.498L1.23153 25.0807Z" fill="#7C878E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M24.1347 0.870189C23.4684 0.941448 22.8146 1.2598 22.2556 1.82469C22.8137 1.25945 23.4683 0.941384 24.1347 0.870189ZM21.6358 1.2359C22.4194 0.436603 23.4019 0 24.4235 0C25.4445 0 26.4263 0.436049 27.2098 1.23437C27.2103 1.23488 27.2108 1.23539 27.2113 1.2359L27.2166 1.24125L27.2222 1.24679L41.7333 16.8643L55.8158 1.24738L55.8236 1.23958L55.8275 1.23572C55.828 1.23523 55.8284 1.23473 55.8289 1.23424C56.613 0.436168 57.5939 0 58.615 0C59.6376 0 60.6191 0.436663 61.4034 1.23572L61.4099 1.24232L61.4161 1.2492L61.419 1.25254L61.4236 1.25755L83.0386 24.5195L81.7868 25.6845L60.1754 2.42594C59.6785 1.92382 59.1272 1.70958 58.615 1.70958C58.105 1.70958 57.5545 1.92261 57.0593 2.42139L42.9017 18.1215L48.847 24.5195L47.5952 25.6845L25.9838 2.42597C25.487 1.92383 24.9357 1.70958 24.4235 1.70958C23.9113 1.70958 23.36 1.92383 22.8632 2.42597L1.2518 25.6845L0 24.5195L21.6358 1.2359ZM60.7871 1.82805C60.1871 1.21964 59.4769 0.896719 58.7592 0.858613C59.4774 0.896841 60.1879 1.22096 60.7885 1.83029L60.7929 1.83476L81.8306 24.4759L60.7882 1.82941L60.7871 1.82805Z" fill="#7C878E" />
        </svg>
    );
}