import { RecycleSpecificGravity } from '../object/test/cementtitiousLiquid/RecycleSpecificGravityType';
import { FlyAshLossOnIgnition } from '../object/test/cementtitiousPowder/FlyAshLossOnIgnitionType';
import { FlyAshWaterDemand } from '../object/test/cementtitiousPowder/FlyAshWaterDemandType';
import { SlagWaterDemand } from '../object/test/cementtitiousPowder/SlagWaterDemandType';
import { CoarseAbrasive } from '../object/test/sandRock/CoarseAbrasiveType';
import { CoarseAggOblateness } from '../object/test/sandRock/CoarseAggOblatenessType';
import { CoarseAggSgWabs } from '../object/test/sandRock/CoarseAggSgWabsType';
import { CoarseMoistureContent } from '../object/test/sandRock/CoarseMoistureContentType';
import { CoarseSieve } from '../object/test/sandRock/CoarseSieveType';
import { FineAggSgWabs } from '../object/test/sandRock/FineAggSgWabsType';
import { FineSieve } from '../object/test/sandRock/FineSieveType';
import { MoistureContent } from '../object/test/sandRock/MoistureContentType';
import { Rmbt } from '../object/test/sandRock/RmbtType';
import { calcPercent, checkIsNaN } from './CalcHelper';

export class CalcFineSieve {
  sieveAllPercentage: number;
  FM: number;
  waterWeight: number;
  poreDiameterPercentage1: number;
  poreDiameterPercentage2: number;
  poreDiameterPercentage3: number;
  poreDiameterPercentage4: number;
  poreDiameterPercentage5: number;
  poreDiameterPercentage6: number;
  poreDiameterPercentage7: number;
  poreDiameterPercentage8: number;
  underPanPercentage: number;

  poreDiameterRemandPercentage1: number;
  poreDiameterRemandPercentage2: number;
  poreDiameterRemandPercentage3: number;
  poreDiameterRemandPercentage4: number;
  poreDiameterRemandPercentage5: number;
  poreDiameterRemandPercentage6: number;
  poreDiameterRemandPercentage7: number;
  poreDiameterRemandPercentage8: number;
  underPanRemandPercentage: number;

  lossPercentage: number;
  dirtContent: number;
  waterContent: number;
  surfaceWaterContent: number;

  constructor(_formData: FineSieve) {
    this.waterWeight = _formData.wetWeight - _formData.beforeRinseDryWeight;

    this.poreDiameterPercentage1 = calcPercent(_formData.poreDiameter1, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage2 = calcPercent(_formData.poreDiameter2, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage3 = calcPercent(_formData.poreDiameter3, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage4 = calcPercent(_formData.poreDiameter4, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage5 = calcPercent(_formData.poreDiameter5, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage6 = calcPercent(_formData.poreDiameter6, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage7 = calcPercent(_formData.poreDiameter7, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage8 = calcPercent(_formData.poreDiameter8, _formData.beforeRinseDryWeight);
    this.underPanPercentage = calcPercent(_formData.underPan, _formData.beforeRinseDryWeight);

    this.sieveAllPercentage =
      this.poreDiameterPercentage1 +
      this.poreDiameterPercentage2 +
      this.poreDiameterPercentage3 +
      this.poreDiameterPercentage4 +
      this.poreDiameterPercentage5 +
      this.poreDiameterPercentage6 +
      this.poreDiameterPercentage7;

    this.FM = this.sieveAllPercentage / 100;

    this.poreDiameterRemandPercentage1 = 100 - this.poreDiameterPercentage1;
    this.poreDiameterRemandPercentage2 = 100 - this.poreDiameterPercentage2;
    this.poreDiameterRemandPercentage3 = 100 - this.poreDiameterPercentage3;
    this.poreDiameterRemandPercentage4 = 100 - this.poreDiameterPercentage4;
    this.poreDiameterRemandPercentage5 = 100 - this.poreDiameterPercentage5;
    this.poreDiameterRemandPercentage6 = 100 - this.poreDiameterPercentage6;
    this.poreDiameterRemandPercentage7 = 100 - this.poreDiameterPercentage7;
    this.poreDiameterRemandPercentage8 = 100 - this.poreDiameterPercentage8;
    this.underPanRemandPercentage = 100 - this.underPanPercentage;

    this.lossPercentage = calcPercent(
      _formData.afterRinseDryWeight - _formData.underPan,
      _formData.afterRinseDryWeight,
    );
    this.dirtContent = calcPercent(
      _formData.beforeRinseDryWeight - _formData.afterRinseDryWeight,
      _formData.beforeRinseDryWeight,
    );
    this.waterContent = calcPercent(this.waterWeight, _formData.beforeRinseDryWeight);

    this.surfaceWaterContent = (this.waterContent - _formData.waterAbsorption) / (1 + _formData.waterAbsorption / 100);
  }
}
export class CalcComboSieveAnalysis {
  poreDiameterRemandPercentage1: number;
  poreDiameterRemandPercentage2: number;
  poreDiameterRemandPercentage3: number;
  poreDiameterRemandPercentage4: number;
  poreDiameterRemandPercentage5: number;
  poreDiameterRemandPercentage6: number;
  poreDiameterRemandPercentage7: number;
  poreDiameterRemandPercentage8: number;
  FM: number;
  lossPercentage: number;
  dirtContent: number;
  waterContent: number;
  surfaceWaterContent: number;

  constructor(
    _calcTestFormS1: CalcFineSieve | CalcCoarseSieve,
    _calcTestFormS2: CalcFineSieve | CalcCoarseSieve,
    _ratio: number,
  ) {
    this.poreDiameterRemandPercentage1 =
      _calcTestFormS1.poreDiameterRemandPercentage1 * _ratio +
      _calcTestFormS2.poreDiameterRemandPercentage1 * (1 - _ratio);
    this.poreDiameterRemandPercentage2 =
      _calcTestFormS1.poreDiameterRemandPercentage2 * _ratio +
      _calcTestFormS2.poreDiameterRemandPercentage2 * (1 - _ratio);
    this.poreDiameterRemandPercentage3 =
      _calcTestFormS1.poreDiameterRemandPercentage3 * _ratio +
      _calcTestFormS2.poreDiameterRemandPercentage3 * (1 - _ratio);
    this.poreDiameterRemandPercentage4 =
      _calcTestFormS1.poreDiameterRemandPercentage4 * _ratio +
      _calcTestFormS2.poreDiameterRemandPercentage4 * (1 - _ratio);
    this.poreDiameterRemandPercentage5 =
      _calcTestFormS1.poreDiameterRemandPercentage5 * _ratio +
      _calcTestFormS2.poreDiameterRemandPercentage5 * (1 - _ratio);
    this.poreDiameterRemandPercentage6 =
      _calcTestFormS1.poreDiameterRemandPercentage6 * _ratio +
      _calcTestFormS2.poreDiameterRemandPercentage6 * (1 - _ratio);
    this.poreDiameterRemandPercentage7 =
      _calcTestFormS1.poreDiameterRemandPercentage7 * _ratio +
      _calcTestFormS2.poreDiameterRemandPercentage7 * (1 - _ratio);
    this.poreDiameterRemandPercentage8 =
      _calcTestFormS1.poreDiameterRemandPercentage8 * _ratio +
      _calcTestFormS2.poreDiameterRemandPercentage8 * (1 - _ratio);

    this.FM = _calcTestFormS1.FM * _ratio + _calcTestFormS2.FM * (1 - _ratio);
    this.lossPercentage = _calcTestFormS1.lossPercentage * _ratio + _calcTestFormS2.lossPercentage * (1 - _ratio);
    this.dirtContent = _calcTestFormS1.dirtContent * _ratio + _calcTestFormS2.dirtContent * (1 - _ratio);
    this.waterContent = _calcTestFormS1.waterContent * _ratio + _calcTestFormS2.waterContent * (1 - _ratio);
    this.surfaceWaterContent =
      _calcTestFormS1.surfaceWaterContent * _ratio + _calcTestFormS2.surfaceWaterContent * (1 - _ratio);
  }
}

export class CalcRecycleSpecificGravity {
  solidContent: number;

  constructor(_formData: RecycleSpecificGravity) {
    this.solidContent = (_formData.specificWeight - 1.005) / 0.6524;
  }
}

export class CalcMoistureContent {
  moistureContent: number;

  constructor(_formData: MoistureContent) {
    this.moistureContent = calcPercent(_formData.wetWeight - _formData.dryWeight, _formData.dryWeight);
  }
}

export class CalcRmbt {
  siltWeight: number;
  rValue: number;

  constructor(_formData: Rmbt) {
    this.siltWeight = calcPercent(
      _formData.beforeRinseDryWeight - _formData.afterRinseDryWeight,
      _formData.beforeRinseDryWeight,
    );
    this.rValue = _formData.drops / this.siltWeight;
  }
}

export class CalcFineAggSgWabs {
  firstOvenDrySpecificGravity: number; // 烘乾虛比重
  secondOvenDrySpecificGravity: number;
  thirdOvenDrySpecificGravity: number;
  fourthOvenDrySpecificGravity: number;

  firstSpecificGravity: number; // 比重
  secondSpecificGravity: number;
  thirdSpecificGravity: number;
  fourthSpecificGravity: number;

  firstTrueSpecificGravity: number; // 實比重
  secondTrueSpecificGravity: number;
  thirdTrueSpecificGravity: number;
  fourthTrueSpecificGravity: number;

  firstWaterAbsorption: number; // 吸水率
  secondWaterAbsorption: number;
  thirdWaterAbsorption: number;
  fourthWaterAbsorption: number;

  firstSampleSpecificGravity: number; // 試料虛體積
  secondSampleSpecificGravity: number;
  thirdSampleSpecificGravity: number;
  fourthSampleSpecificGravity: number;

  allSpecificGravitySurfaceDryWeight: number;
  allWaterAbsorptionSurfaceDryWeight: number;
  allOvenDryWeight: number;
  allPycnometerAndWaterWeight: number;
  allSDWPycnometerWaterWeight: number;

  allOvenDrySpecificGravity: number;
  allSpecificGravity: number;
  allTrueSpecificGravity: number;
  allWaterAbsorption: number;

  constructor(_formData: FineAggSgWabs) {
    this.firstSampleSpecificGravity =
      _formData.firstSpecificGravitySurfaceDryWeight +
      _formData.firstPycnometerAndWaterWeight -
      _formData.firstSDWPycnometerWaterWeight;
    this.secondSampleSpecificGravity =
      _formData.secondSpecificGravitySurfaceDryWeight ??
      0 + (_formData.secondPycnometerAndWaterWeight ?? 0) - (_formData.secondSDWPycnometerWaterWeight ?? 0);
    this.thirdSampleSpecificGravity =
      _formData.thirdSpecificGravitySurfaceDryWeight ??
      0 + (_formData.thirdPycnometerAndWaterWeight ?? 0) - (_formData.thirdSDWPycnometerWaterWeight ?? 0);
    this.fourthSampleSpecificGravity =
      _formData.fourthSpecificGravitySurfaceDryWeight ??
      0 + (_formData.fourthPycnometerAndWaterWeight ?? 0) - (_formData.fourthSDWPycnometerWaterWeight ?? 0);

    this.firstOvenDrySpecificGravity = _formData.firstOvenDryWeight / this.firstSampleSpecificGravity;
    this.secondOvenDrySpecificGravity = _formData.secondOvenDryWeight ?? 0 / this.secondSampleSpecificGravity;
    this.thirdOvenDrySpecificGravity = _formData.thirdOvenDryWeight ?? 0 / this.thirdSampleSpecificGravity;
    this.fourthOvenDrySpecificGravity = _formData.fourthOvenDryWeight ?? 0 / this.fourthSampleSpecificGravity;

    this.firstSpecificGravity = _formData.firstSpecificGravitySurfaceDryWeight / this.firstSampleSpecificGravity;
    this.secondSpecificGravity =
      _formData.secondSpecificGravitySurfaceDryWeight ?? 0 / this.secondSampleSpecificGravity;
    this.thirdSpecificGravity = _formData.thirdSpecificGravitySurfaceDryWeight ?? 0 / this.thirdSampleSpecificGravity;
    this.fourthSpecificGravity =
      _formData.fourthSpecificGravitySurfaceDryWeight ?? 0 / this.fourthSampleSpecificGravity;

    this.firstTrueSpecificGravity =
      _formData.firstOvenDryWeight /
      (_formData.firstOvenDryWeight +
        _formData.firstPycnometerAndWaterWeight -
        _formData.firstSDWPycnometerWaterWeight);
    this.secondTrueSpecificGravity =
      _formData.secondOvenDryWeight ??
      0 /
        (_formData.secondOvenDryWeight ??
          0 + (_formData.secondPycnometerAndWaterWeight ?? 0) - (_formData.secondSDWPycnometerWaterWeight ?? 0));
    this.thirdTrueSpecificGravity =
      _formData.thirdOvenDryWeight ??
      0 /
        (_formData.thirdOvenDryWeight ??
          0 + (_formData.thirdPycnometerAndWaterWeight ?? 0) - (_formData.thirdSDWPycnometerWaterWeight ?? 0));
    this.fourthTrueSpecificGravity =
      _formData.fourthOvenDryWeight ??
      0 /
        (_formData.fourthOvenDryWeight ??
          0 + (_formData.fourthPycnometerAndWaterWeight ?? 0) - (_formData.fourthSDWPycnometerWaterWeight ?? 0));

    this.firstWaterAbsorption = calcPercent(
      _formData.firstWaterAbsorptionSurfaceDryWeight - _formData.firstOvenDryWeight,
      _formData.firstOvenDryWeight,
    );
    this.secondWaterAbsorption = calcPercent(
      _formData.secondWaterAbsorptionSurfaceDryWeight ?? 0 - (_formData.secondOvenDryWeight ?? 0),
      _formData.secondOvenDryWeight ?? 0,
    );
    this.thirdWaterAbsorption = calcPercent(
      _formData.thirdWaterAbsorptionSurfaceDryWeight ?? 0 - (_formData.thirdOvenDryWeight ?? 0),
      _formData.thirdOvenDryWeight ?? 0,
    );
    this.fourthWaterAbsorption = calcPercent(
      _formData.fourthWaterAbsorptionSurfaceDryWeight ?? 0 - (_formData.fourthOvenDryWeight ?? 0),
      _formData.fourthOvenDryWeight ?? 0,
    );

    this.allSpecificGravitySurfaceDryWeight =
      _formData.firstSpecificGravitySurfaceDryWeight +
      (_formData.secondSpecificGravitySurfaceDryWeight ?? 0) +
      (_formData.thirdSpecificGravitySurfaceDryWeight ?? 0) +
      (_formData.fourthSpecificGravitySurfaceDryWeight ?? 0);
    this.allWaterAbsorptionSurfaceDryWeight =
      _formData.firstWaterAbsorptionSurfaceDryWeight +
      (_formData.secondWaterAbsorptionSurfaceDryWeight ?? 0) +
      (_formData.thirdWaterAbsorptionSurfaceDryWeight ?? 0) +
      (_formData.fourthWaterAbsorptionSurfaceDryWeight ?? 0);
    this.allOvenDryWeight =
      _formData.firstOvenDryWeight +
      (_formData.secondOvenDryWeight ?? 0) +
      (_formData.thirdOvenDryWeight ?? 0) +
      (_formData.fourthOvenDryWeight ?? 0);
    this.allPycnometerAndWaterWeight =
      _formData.firstPycnometerAndWaterWeight +
      (_formData.secondPycnometerAndWaterWeight ?? 0) +
      (_formData.thirdPycnometerAndWaterWeight ?? 0) +
      (_formData.fourthPycnometerAndWaterWeight ?? 0);
    this.allSDWPycnometerWaterWeight =
      _formData.firstSDWPycnometerWaterWeight +
      (_formData.secondSDWPycnometerWaterWeight ?? 0) +
      (_formData.thirdSDWPycnometerWaterWeight ?? 0) +
      (_formData.fourthSDWPycnometerWaterWeight ?? 0);

    this.allSpecificGravity =
      checkIsNaN(this.firstSpecificGravity) +
      checkIsNaN(this.secondSpecificGravity) +
      checkIsNaN(this.thirdSpecificGravity) +
      checkIsNaN(this.fourthSpecificGravity);
    this.allOvenDrySpecificGravity =
      checkIsNaN(this.firstOvenDrySpecificGravity) +
      checkIsNaN(this.secondOvenDrySpecificGravity) +
      checkIsNaN(this.thirdOvenDrySpecificGravity) +
      checkIsNaN(this.fourthOvenDrySpecificGravity);
    this.allTrueSpecificGravity =
      checkIsNaN(this.firstTrueSpecificGravity) +
      checkIsNaN(this.secondTrueSpecificGravity) +
      checkIsNaN(this.thirdTrueSpecificGravity) +
      checkIsNaN(this.fourthTrueSpecificGravity);
    this.allWaterAbsorption =
      this.firstWaterAbsorption + this.secondWaterAbsorption + this.thirdWaterAbsorption + this.fourthWaterAbsorption;
  }
}

export class CalcFlyAshLossOnIgnition {
  netWeightInitialWeight: number;
  netWeightFinalWeight: number;
  lossWeight: number;
  lossOnIgnition: number;

  constructor(_testForm: FlyAshLossOnIgnition) {
    this.netWeightInitialWeight = _testForm.initialWeight - _testForm.crucibleWeight;
    this.netWeightFinalWeight = _testForm.finalWeight - _testForm.crucibleWeight;
    this.lossWeight = this.netWeightInitialWeight - this.netWeightFinalWeight;
    this.lossOnIgnition = calcPercent(this.lossWeight, this.netWeightInitialWeight);
  }
}

export class CalcCoarseAggSgWabs {
  firstOvenDrySpecificGravity: number;
  secondOvenDrySpecificGravity: number;
  thirdOvenDrySpecificGravity: number;
  fourthOvenDrySpecificGravity: number;

  firstSaturatedSurfaceDryProportion: number;
  secondSaturatedSurfaceDryProportion: number;
  thirdSaturatedSurfaceDryProportion: number;
  fourthSaturatedSurfaceDryProportion: number;

  firstSpecificGravity: number;
  secondSpecificGravity: number;
  thirdSpecificGravity: number;
  fourthSpecificGravity: number;

  firstWaterAbsorption: number;
  secondWaterAbsorption: number;
  thirdWaterAbsorption: number;
  fourthWaterAbsorption: number;

  allUnderwaterWeight: number;
  allSaturatedSurfaceDryWeight: number;
  allOvenDryWeight: number;
  allOvenDrySpecificGravity: number;
  allSaturatedSurfaceDryProportion: number;
  allSpecificGravity: number;
  allWaterAbsorption: number;

  constructor(_testForm: CoarseAggSgWabs) {
    this.firstOvenDrySpecificGravity =
      _testForm.firstOvenDryWeight / (_testForm.firstSaturatedSurfaceDryWeight - _testForm.firstUnderwaterWeight);
    this.secondOvenDrySpecificGravity =
      _testForm.secondOvenDryWeight ??
      0 / (_testForm.secondSaturatedSurfaceDryWeight ?? 0 - (_testForm.secondUnderwaterWeight ?? 0));
    this.thirdOvenDrySpecificGravity =
      _testForm.thirdOvenDryWeight ??
      0 / (_testForm.thirdSaturatedSurfaceDryWeight ?? 0 - (_testForm.thirdUnderwaterWeight ?? 0));
    this.fourthOvenDrySpecificGravity =
      _testForm.fourthOvenDryWeight ??
      0 / (_testForm.fourthSaturatedSurfaceDryWeight ?? 0 - (_testForm.fourthUnderwaterWeight ?? 0));

    this.firstSaturatedSurfaceDryProportion =
      _testForm.firstSaturatedSurfaceDryWeight /
      (_testForm.firstSaturatedSurfaceDryWeight - _testForm.firstUnderwaterWeight);
    this.secondSaturatedSurfaceDryProportion =
      _testForm.secondSaturatedSurfaceDryWeight ??
      0 / (_testForm.secondSaturatedSurfaceDryWeight ?? 0 - (_testForm.secondUnderwaterWeight ?? 0));
    this.thirdSaturatedSurfaceDryProportion =
      _testForm.thirdSaturatedSurfaceDryWeight ??
      0 / (_testForm.thirdSaturatedSurfaceDryWeight ?? 0 - (_testForm.thirdUnderwaterWeight ?? 0));
    this.fourthSaturatedSurfaceDryProportion =
      _testForm.fourthSaturatedSurfaceDryWeight ??
      0 / (_testForm.fourthSaturatedSurfaceDryWeight ?? 0 - (_testForm.fourthUnderwaterWeight ?? 0));

    this.firstSpecificGravity =
      _testForm.firstOvenDryWeight / (_testForm.firstSaturatedSurfaceDryWeight - _testForm.firstUnderwaterWeight) -
      (_testForm.firstSaturatedSurfaceDryWeight - _testForm.firstOvenDryWeight);
    this.secondSpecificGravity =
      _testForm.secondOvenDryWeight ??
      0 / (_testForm.secondSaturatedSurfaceDryWeight ?? 0 - (_testForm.secondUnderwaterWeight ?? 0)) -
        (_testForm.secondSaturatedSurfaceDryWeight ?? 0 - (_testForm.secondOvenDryWeight ?? 0));
    this.thirdSpecificGravity =
      _testForm.thirdOvenDryWeight ??
      0 / (_testForm.thirdSaturatedSurfaceDryWeight ?? 0 - (_testForm.thirdUnderwaterWeight ?? 0)) -
        (_testForm.thirdSaturatedSurfaceDryWeight ?? 0 - (_testForm.thirdOvenDryWeight ?? 0));
    this.fourthSpecificGravity =
      _testForm.fourthOvenDryWeight ??
      0 / (_testForm.fourthSaturatedSurfaceDryWeight ?? 0 - (_testForm.fourthUnderwaterWeight ?? 0)) -
        (_testForm.fourthSaturatedSurfaceDryWeight ?? 0 - (_testForm.fourthOvenDryWeight ?? 0));

    this.firstWaterAbsorption = calcPercent(
      _testForm.firstSaturatedSurfaceDryWeight - _testForm.firstOvenDryWeight,
      _testForm.firstOvenDryWeight,
    );
    this.secondWaterAbsorption = calcPercent(
      _testForm.secondSaturatedSurfaceDryWeight ?? 0 - (_testForm.secondOvenDryWeight ?? 0),
      _testForm.secondOvenDryWeight ?? 0,
    );
    this.thirdWaterAbsorption = calcPercent(
      _testForm.thirdSaturatedSurfaceDryWeight ?? 0 - (_testForm.thirdOvenDryWeight ?? 0),
      _testForm.thirdOvenDryWeight ?? 0,
    );
    this.fourthWaterAbsorption = calcPercent(
      _testForm.fourthSaturatedSurfaceDryWeight ?? 0 - (_testForm.fourthOvenDryWeight ?? 0),
      _testForm.fourthOvenDryWeight ?? 0,
    );

    this.allUnderwaterWeight =
      _testForm.firstUnderwaterWeight +
      (_testForm.secondUnderwaterWeight ?? 0) +
      (_testForm.thirdUnderwaterWeight ?? 0) +
      (_testForm.fourthUnderwaterWeight ?? 0);

    this.allSaturatedSurfaceDryWeight =
      _testForm.firstSaturatedSurfaceDryWeight +
      (_testForm.secondSaturatedSurfaceDryWeight ?? 0) +
      (_testForm.thirdSaturatedSurfaceDryWeight ?? 0) +
      (_testForm.fourthSaturatedSurfaceDryWeight ?? 0);
    this.allOvenDryWeight =
      _testForm.firstOvenDryWeight +
      (_testForm.secondOvenDryWeight ?? 0) +
      (_testForm.thirdOvenDryWeight ?? 0) +
      (_testForm.fourthOvenDryWeight ?? 0);

    this.allOvenDrySpecificGravity =
      checkIsNaN(this.firstOvenDrySpecificGravity) +
      checkIsNaN(this.secondOvenDrySpecificGravity) +
      checkIsNaN(this.thirdOvenDrySpecificGravity) +
      checkIsNaN(this.fourthOvenDrySpecificGravity);

    this.allSaturatedSurfaceDryProportion =
      checkIsNaN(this.firstSaturatedSurfaceDryProportion) +
      checkIsNaN(this.secondSaturatedSurfaceDryProportion) +
      checkIsNaN(this.thirdSaturatedSurfaceDryProportion) +
      checkIsNaN(this.fourthSaturatedSurfaceDryProportion);

    this.allSpecificGravity =
      checkIsNaN(this.firstSpecificGravity) +
      checkIsNaN(this.secondSpecificGravity) +
      checkIsNaN(this.thirdSpecificGravity) +
      checkIsNaN(this.fourthSpecificGravity);

    this.allWaterAbsorption =
      checkIsNaN(this.firstWaterAbsorption) +
      checkIsNaN(this.secondWaterAbsorption) +
      checkIsNaN(this.thirdWaterAbsorption) +
      checkIsNaN(this.fourthWaterAbsorption);
  }
}

export class CalcSlagWaterDemand {
  waterDemandRate: number;

  constructor(_testForm: SlagWaterDemand) {
    this.waterDemandRate = calcPercent(_testForm.waterDemand, _testForm.controlWaterDemand);
  }
}

export class CalcFlyAshWaterDemand {
  waterDemandRate: number;

  constructor(_testForm: FlyAshWaterDemand) {
    this.waterDemandRate = calcPercent(_testForm.waterDemand, _testForm.controlWaterDemand);
  }
}

export class CalcCoarseAggOblateness {
  totalParticleCount: number;
  totalSampleGrading: number;
  sampleGrading1: number;
  sampleGrading2: number;
  sampleGrading3: number;
  sampleGrading4: number;
  sampleGrading5: number;
  sampleGrading6: number;

  totalWeightBefore: number;
  totalWeightAfter: number;

  percentage1: number;
  percentage2: number;
  percentage3: number;
  percentage4: number;
  percentage5: number;
  percentage6: number;
  totalPercentage: number;

  finalPercentage1: number;
  finalPercentage2: number;
  finalPercentage3: number;
  finalPercentage4: number;
  finalPercentage5: number;
  finalPercentage6: number;
  totalFinalPercentage: number;

  constructor(_testForm: CoarseAggOblateness) {
    this.totalParticleCount =
      _testForm.gradation1 +
      _testForm.gradation2 +
      _testForm.gradation3 +
      _testForm.gradation4 +
      _testForm.gradation5 +
      _testForm.gradation6;
    this.sampleGrading1 = calcPercent(_testForm.gradation1, this.totalParticleCount);
    this.sampleGrading2 = calcPercent(_testForm.gradation2, this.totalParticleCount);
    this.sampleGrading3 = calcPercent(_testForm.gradation3, this.totalParticleCount);
    this.sampleGrading4 = calcPercent(_testForm.gradation4, this.totalParticleCount);
    this.sampleGrading5 = calcPercent(_testForm.gradation5, this.totalParticleCount);
    this.sampleGrading6 = calcPercent(_testForm.gradation6, this.totalParticleCount);
    this.totalSampleGrading =
      this.sampleGrading1 +
      this.sampleGrading2 +
      this.sampleGrading3 +
      this.sampleGrading4 +
      this.sampleGrading5 +
      this.sampleGrading6;
    this.totalWeightBefore =
      _testForm.weightBeforeTest1 +
      _testForm.weightBeforeTest2 +
      _testForm.weightBeforeTest3 +
      _testForm.weightBeforeTest4 +
      _testForm.weightBeforeTest5 +
      _testForm.weightBeforeTest6;
    this.totalWeightAfter =
      _testForm.weightAfterTest1 +
      _testForm.weightAfterTest2 +
      _testForm.weightAfterTest3 +
      _testForm.weightAfterTest4 +
      _testForm.weightAfterTest5 +
      _testForm.weightAfterTest6;
    this.percentage1 = calcPercent(_testForm.weightAfterTest1, _testForm.weightBeforeTest1);
    this.percentage2 = calcPercent(_testForm.weightAfterTest2, _testForm.weightBeforeTest2);
    this.percentage3 = calcPercent(_testForm.weightAfterTest3, _testForm.weightBeforeTest3);
    this.percentage4 = calcPercent(_testForm.weightAfterTest4, _testForm.weightBeforeTest4);
    this.percentage5 = calcPercent(_testForm.weightAfterTest5, _testForm.weightBeforeTest5);
    this.percentage6 = calcPercent(_testForm.weightAfterTest6, _testForm.weightBeforeTest6);
    this.totalPercentage =
      this.percentage1 + this.percentage2 + this.percentage3 + this.percentage4 + this.percentage5 + this.percentage6;
    this.finalPercentage1 = calcPercent(_testForm.weightAfterTest1, this.totalWeightBefore);
    this.finalPercentage2 = calcPercent(_testForm.weightAfterTest2, this.totalWeightBefore);
    this.finalPercentage3 = calcPercent(_testForm.weightAfterTest3, this.totalWeightBefore);
    this.finalPercentage4 = calcPercent(_testForm.weightAfterTest4, this.totalWeightBefore);
    this.finalPercentage5 = calcPercent(_testForm.weightAfterTest5, this.totalWeightBefore);
    this.finalPercentage6 = calcPercent(_testForm.weightAfterTest6, this.totalWeightBefore);
    this.totalFinalPercentage =
      this.finalPercentage1 +
      this.finalPercentage2 +
      this.finalPercentage3 +
      this.finalPercentage4 +
      this.finalPercentage5 +
      this.finalPercentage6;
  }
}

export class CalcCoarseAbrasive {
  totalWeightInGram: number;
  wearOutRate: number;

  constructor(_testForm: CoarseAbrasive) {
    this.totalWeightInGram =
      _testForm.weightInGram1 +
      _testForm.weightInGram2 +
      _testForm.weightInGram3 +
      _testForm.weightInGram4 +
      _testForm.weightInGram5 +
      _testForm.weightInGram6;
    this.wearOutRate = calcPercent(this.totalWeightInGram - _testForm.afterTestRetainedWeight, this.totalWeightInGram);
  }
}

export class CalcCoarseMoistureContent {
  moistureContent: number;

  constructor(_formData: CoarseMoistureContent) {
    this.moistureContent = calcPercent(_formData.wetWeight - _formData.dryWeight, _formData.dryWeight);
  }
}

export class CalcCoarseSieve {
  sieveAllPercentage: number;
  FM: number;
  waterWeight: number;
  poreDiameterPercentage1: number;
  poreDiameterPercentage2: number;
  poreDiameterPercentage3: number;
  poreDiameterPercentage4: number;
  poreDiameterPercentage5: number;
  poreDiameterPercentage6: number;
  poreDiameterPercentage7: number;
  poreDiameterPercentage8: number;
  underPanPercentage: number;

  poreDiameterRemandPercentage1: number;
  poreDiameterRemandPercentage2: number;
  poreDiameterRemandPercentage3: number;
  poreDiameterRemandPercentage4: number;
  poreDiameterRemandPercentage5: number;
  poreDiameterRemandPercentage6: number;
  poreDiameterRemandPercentage7: number;
  poreDiameterRemandPercentage8: number;
  underPanRemandPercentage: number;

  lossPercentage: number;
  dirtContent: number;
  waterContent: number;
  surfaceWaterContent: number;

  constructor(_formData: CoarseSieve) {
    this.waterWeight = _formData.wetWeight - _formData.beforeRinseDryWeight;

    this.poreDiameterPercentage1 = calcPercent(_formData.poreDiameter1, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage2 = calcPercent(_formData.poreDiameter2, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage3 = calcPercent(_formData.poreDiameter3, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage4 = calcPercent(_formData.poreDiameter4, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage5 = calcPercent(_formData.poreDiameter5, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage6 = calcPercent(_formData.poreDiameter6, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage7 = calcPercent(_formData.poreDiameter7, _formData.beforeRinseDryWeight);
    this.poreDiameterPercentage8 = calcPercent(_formData.poreDiameter8, _formData.beforeRinseDryWeight);
    this.underPanPercentage = calcPercent(_formData.underPan, _formData.beforeRinseDryWeight);

    this.sieveAllPercentage =
      this.poreDiameterPercentage1 +
      this.poreDiameterPercentage2 +
      this.poreDiameterPercentage3 +
      this.poreDiameterPercentage4 +
      this.poreDiameterPercentage5 +
      this.poreDiameterPercentage6 +
      this.poreDiameterPercentage7;

    this.FM = this.sieveAllPercentage / 100;

    this.poreDiameterRemandPercentage1 = 100 - this.poreDiameterPercentage1;
    this.poreDiameterRemandPercentage2 = 100 - this.poreDiameterPercentage2;
    this.poreDiameterRemandPercentage3 = 100 - this.poreDiameterPercentage3;
    this.poreDiameterRemandPercentage4 = 100 - this.poreDiameterPercentage4;
    this.poreDiameterRemandPercentage5 = 100 - this.poreDiameterPercentage5;
    this.poreDiameterRemandPercentage6 = 100 - this.poreDiameterPercentage6;
    this.poreDiameterRemandPercentage7 = 100 - this.poreDiameterPercentage7;
    this.poreDiameterRemandPercentage8 = 100 - this.poreDiameterPercentage8;
    this.underPanRemandPercentage = 100 - this.underPanPercentage;

    this.lossPercentage = calcPercent(
      _formData.afterRinseDryWeight - _formData.underPan,
      _formData.afterRinseDryWeight,
    );
    this.dirtContent = calcPercent(
      _formData.beforeRinseDryWeight - _formData.afterRinseDryWeight,
      _formData.beforeRinseDryWeight,
    );
    this.waterContent = calcPercent(this.waterWeight, _formData.beforeRinseDryWeight);

    this.surfaceWaterContent = (this.waterContent - _formData.waterAbsorption) / (1 + _formData.waterAbsorption / 100);
  }
}
