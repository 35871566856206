import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Analytics(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4,3.5h15.5c1.7,0,3,1.3,3,3V14c0,1.5-1.1,2.8-2.6,3v1c2-0.2,3.6-1.9,3.6-4V6.5c0-2.2-1.8-4-4-4H4c-2.2,0-4,1.8-4,4V14c0,2,1.5,3.6,3.4,3.9v-1C2,16.7,1,15.5,1,14V6.5C1,4.8,2.3,3.5,4,3.5z"
      />
      <path d="M17.5,7.4c-0.5,0-0.9,0.4-0.9,0.9V20c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V8.3C18.4,7.8,18,7.4,17.5,7.4z" />
      <path d="M9.7,10.8c-0.5,0-0.9,0.4-0.9,0.9V20c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-8.3C10.6,11.2,10.2,10.8,9.7,10.8z" />
      <path d="M5.8,14.7c-0.5,0-0.9,0.4-0.9,0.9V20c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-4.4C6.7,15.1,6.3,14.7,5.8,14.7z" />
      <path d="M12.7,12.7c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9V20c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9V12.7z" />
    </SvgIcon>
  );
}
