import { Protocol } from '../../Protocol';

export class CustomStandardV2Protocol {
  public static readonly CUSTOM_STANDARD_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/standardV2/custom`;
  public static readonly CUSTOM_STANDARDS_BY_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/standardV2/custom/search`;
  public static readonly PAGEABLE_CUSTOM_STANDARDS_BY_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/standardV2/custom/search/pageable`;
  public static readonly TOGGLE_CUSTOM_STANDARD_ENABLE_STATUS: string = `${Protocol.YATUNG_API_ROOT}/standardV2/custom/enableStatus`;
  public static readonly CREATE_CUSTOM_STANDARD: string = `${Protocol.YATUNG_API_ROOT}/standardV2/custom`;
  public static readonly GET_ACTIVATED_CUSTOM_STANDARD_IDS: string = `${Protocol.YATUNG_API_ROOT}/standardV2/custom/activated/ids`;
}
