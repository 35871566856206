import { IconButton, Stack } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import AddCircleFillIcon from '@Src/_basic/icons/AddCircleFill';
import DeleteCircleIcon from '@Src/_basic/icons/DeleteCircle';
import { GroupData } from '@Src/_basic/object/AccountManagementType';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onCreate: () => void;
  onDelete: (row: GroupData) => void;
  codeTitle: string;
  data: Array<GroupData>;
  isDisabled: boolean;
}

export default function EditAccountMiniTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { codeTitle, data, onCreate, onDelete, isDisabled } = props;

  const columns: Array<Column> = [
    {
      text: codeTitle,
      dataField: 'code',
    },
    {
      text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.NAME_2'),
      dataField: 'name',
    },
    {
      text: undefined,
      dataField: 'id',
      formatter: ({ row }) => {
        return (
          <>
            <IconButton
              onClick={() => {
                onDelete(row);
              }}
              edge="end"
              sx={{
                height: 10,
                width: 10,
              }}
            >
              <DeleteCircleIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Stack direction={'row'} sx={{ minWidth: '450px', height: 150 }}>
      <YatungButton
        disabled={isDisabled}
        text={i18T('GLOBAL.CREATE')}
        variant="contained"
        size="large"
        onClick={onCreate}
        color={'blue'}
        endIcon={<AddCircleFillIcon sx={{ mr: 1, width: 25, height: 35 }} />}
        width={5}
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          lineHeight: 2,
          flexDirection: 'column',
          alignItems: 'flex-start',
          whiteSpace: '',
        }}
      />
      <YatungTable
        sx={{
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          borderWidth: '1px 1px 1px 0',
          borderStyle: 'solid',
          borderColor: 'gray',
          px: 2,
          minHeight: 150,

          pr: 2,
          '&::-webkit-scrollbar': {
            width: 8,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#003087',
            borderRadius: 8,
          },
        }}
        isDashedBottom={true}
        isTableMinWidth={false}
        tableCellPadding={3}
        columns={columns}
        data={data}
        headColor={false}
      />
    </Stack>
  );
}
