import { Stack } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import YatungButton from '../YatungButton';

interface Props {
  edit: boolean;
  setEdit: (edit: boolean) => void;
  onSubmit: () => void;
  canEdit?: boolean;
}

const EditButtons = ({ edit, setEdit, onSubmit, canEdit }: Props) => {
  const { t: i18T } = useTranslation();

  const ShowButtons = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <>
            <YatungButton
              color="green"
              sx={{ height: '35%', mt: 1 }}
              width={80}
              text={`${i18T('GLOBAL.SUBMIT')}`}
              onClick={onSubmit}
            />
            <YatungButton
              color="red"
              sx={{ height: '35%', mt: 1 }}
              width={80}
              text={`${i18T('GLOBAL.CANCEL')}`}
              onClick={() => setEdit(false)}
            />
          </>
        );
      case false:
        return (
          <YatungButton
            color="yellow"
            sx={{ height: '40%', mt: 1 }}
            width={80}
            text={`${i18T('GLOBAL.UPDATE')}`}
            onClick={() => setEdit(true)}
          />
        );
      default:
        break;
    }
  }, [edit, i18T, onSubmit, setEdit]);
  return (
    <Stack flexDirection="row" justifyContent="flex-end" gap={3} position="absolute" right={50}>
      {canEdit && ShowButtons}
    </Stack>
  );
};

export default memo(EditButtons);
