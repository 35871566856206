import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

const background = '/media/images/home_bg.jpg';

interface Props {
  children: React.ReactNode;
}

function ErrorBoundary({ children }: Props) {
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const errorHandler = (error: ErrorEvent | Event) => {
      console.error('Error caught by ErrorBoundary:', error);
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    return (
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          height: '100%',
          backgroundColor: '#F2F2F2',
        }}
      >
        <Stack justifyContent="center" alignItems="center" height="100vh">
          <YatungConfirmAlert
            alertText="發生點小錯誤..."
            isShowAlert={true}
            onClose={() => {
              setHasError(false);
              navigate('/');
            }}
          />
        </Stack>
      </Box>
    );
  }

  return <>{children}</>;
}

export default ErrorBoundary;
