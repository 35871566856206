import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import { Options } from '@Src/_basic/components/YatungSelect';
import { MaterialCodeAndTransactionResponse } from '@Src/_basic/object/MaterialCodeType';
import { StationOption, StationsData, StationsDataById, mappingCodeBranch } from '@Src/_basic/object/SiteType';
import { SearchTypeParamsByRemoved, TypeData } from '@Src/_basic/object/TypeType';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import { StationApi } from '@Src/_basic/protocol/site/StationApi';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { TypeTransactionApi } from '@Src/_basic/protocol/typeTransaction/TypeTransactionApi';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreateFeedInletForm from './components/CreateFeedInletForm';
import CreateGuardhouseForm from './components/CreateGuardhouseForm';
import CreateQualityControlForm from './components/CreateQualityControlForm';
import CreateWbStationForm from './components/CreateWbStationForm';
import SiteManagementErrorMessage from './components/SiteManagementErrorMessage';
import SiteManagementUpdateSubmit from './components/SiteManagementUpdateSubmit';
import UpdateFeedInletForm from './components/UpdateFeedInletForm';
import UpdateGuardhouseForm from './components/UpdateGuardhouseForm';
import UpdateQualityControlForm from './components/UpdateQualityControlForm';
import UpdateWbStationForm from './components/UpdateWbStationForm';

export default function SiteManagementUpdateForm() {
  const { t: i18T } = useTranslation();
  const history = useNavigate();
  const { id } = useParams();
  const {
    state: { areaName, factoryName, removed },
  } = useLocation();

  const handleGoBack = () => {
    history(-1);
  };
  const [siteUpdateData, setSiteUpdateData] = useState<StationsDataById[]>([]);
  const getStationAccountByFactoryId = () => {
    setLoading(true);
    StationApi.getStationAccountByFactoryId(
      { factoryId: Number(id), pageSize: 99999, pageNumber: 1 },
      (data: StationsDataById[]) => {
        creagteRelatedValue(data);
      },
      (error) => {
        getErrorCode(error);
        setWarningMoalOpen(true);
      },
      () => {
        setLoading(false);
      },
    );
  };
  const creagteRelatedValue = (data: StationsDataById[]) => {
    const passwordValue = data.map((value: StationsDataById) => ({
      ...value,
      password: '',
      passwordEdit: false,
      edit: true,
      inputType: 'password',
    }));
    setSiteUpdateData(passwordValue);
  };
  const [typeTransaction, setTypeTransaction] = useState<Array<Options>>([]);
  const getTypeTransaction = () => {
    setLoading(true);
    TypeTransactionApi.getTypeTransaction(
      (_data) => {
        const typeTransaction = _data.map((transaction: MaterialCodeAndTransactionResponse) => ({
          value: transaction.id,
          text: transaction.transactionName,
        }));
        setTypeTransaction(typeTransaction);
      },
      () => {
        setLoading(false);
      },
    );
  };
  useEffect(() => {
    getStationAccountByFactoryId();
    getTypeTransaction();
  }, []);
  const [loading, setLoading] = useState(false);
  const [updateWbStation, setUpdateWbStation] = useState<StationsDataById[]>([]);
  const [updateGuardhouse, setUpdateGuardhouse] = useState<StationsDataById[]>([]);
  const [updateFeedInlet, setUpdateFeedInlet] = useState<StationsDataById[]>([]);
  const [updateQualityControl, setUpdateQualityControl] = useState<StationsDataById[]>([]);

  const [factoryBranchCode, setFactoryBranchCode] = useState<string>('');
  const [stationsOption, setStationsOption] = useState<Options[]>([]);

  const [createWbStation, setCreateWbStation] = useState<StationsData[]>([]);
  const [createGuardhouse, setCreateGuardhouse] = useState<StationsData[]>([]);
  const [createFeedInlet, setCreateFeedInlet] = useState<StationsData[]>([]);
  const [createQualityControl, setCreateQualityControl] = useState<StationsData[]>([]);

  const [warningMoalOpen, setWarningMoalOpen] = useState<boolean>(false);

  const [types, setTypes] = useState<Options[]>([]);
  const handleWarningModalClose = () => {
    setWarningMoalOpen(false);
  };

  const getUpdateWbStation = () => {
    const wbStationFilter = siteUpdateData.filter((value: StationsDataById) => value.stationType === 'W');

    setUpdateWbStation(wbStationFilter);
  };
  const getUpdateGuardhouse = () => {
    const guardhouseFilter = siteUpdateData.filter((value: StationsDataById) => value.stationType === 'G');
    setUpdateGuardhouse(guardhouseFilter);
  };
  const getUpdateFeedInlet = () => {
    const feedInletFilter = siteUpdateData.filter((value: StationsDataById) => value.stationType === 'I');
    setUpdateFeedInlet(feedInletFilter);
  };
  const getUpdateQualityControl = () => {
    const qualityControlFilter = siteUpdateData.filter((value: StationsDataById) => value.stationType === 'Q');
    setUpdateQualityControl(qualityControlFilter);
  };

  const [updateWbStationTypeId, setUpdateWbStationTypeId] = useState<StationsDataById[]>([]);
  const [updateGuardhouseTypeId, setUpdateGuardhouseTypeId] = useState<StationsDataById[]>([]);
  const [updateFeedInletTypeId, setUpdateFeedInletTypeId] = useState<StationsDataById[]>([]);
  const [updateQualityControlTypeId, setUpdateQualityControlTypeId] = useState<StationsDataById[]>([]);

  const getWbStationTypeId = () => {
    const updatedData = updateWbStation.map(({ types, stationTypeSettings, ...rest }: StationsDataById) => ({
      ...rest,
      types: types.map(({ id }: TypeData) => id),
      stationTypeSettings: stationTypeSettings.map(({ type, ...rest }: any) => ({ //轉換資料型態，把stationTypeSettings裡面的type.id轉成typeId，其餘刪掉
        ...rest,
        typeId: type.id,
      })),
    }));
    setUpdateWbStationTypeId(updatedData);
  };

  const getGuardhouseTypeId = () => {
    const updatedData = updateGuardhouse.map(({ types, stationTypeSettings, ...rest }: StationsDataById) => ({
      ...rest,
      types: types.map(({ id }: TypeData) => id),
      stationTypeSettings: stationTypeSettings.map(({ type, ...rest }: any) => ({
        ...rest,
        typeId: type.id,
      })),
    }));
    setUpdateGuardhouseTypeId(updatedData);
  };

  const getFeedInletTypeId = () => {
    const updatedData = updateFeedInlet.map(({ types, ...rest }: StationsDataById) => ({
      ...rest,
      types: types.map(({ id }: TypeData) => id),
    }));
    setUpdateFeedInletTypeId(updatedData);
  };

  const getQualityControlTypeId = () => {
    const updatedData = updateQualityControl.map(({ types, ...rest }: StationsDataById) => ({
      ...rest,
      types: types.map(({ id }: TypeData) => id),
    }));
    setUpdateQualityControlTypeId(updatedData);
  };

  useEffect(() => {
    getWbStationTypeId();
  }, [updateWbStation]);
  useEffect(() => {
    getGuardhouseTypeId();
  }, [updateGuardhouse]);
  useEffect(() => {
    getFeedInletTypeId();
  }, [updateFeedInlet]);
  useEffect(() => {
    getQualityControlTypeId();
  }, [updateQualityControl]);

  const getFactoryBranchCode = () => {
    setLoading(true);
    FactoryApi.GetFactoryById(
      Number(id),
      (data: mappingCodeBranch) => {
        setFactoryBranchCode(data.factoryCode + data.branchCode);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };

  const getAllStaionsData = () => {
    StationApi.GetStaionTypeOption((allStaions: StationOption[]) => {
      const stationOptions = allStaions.map((allStaion: StationOption) => ({
        value: allStaion.id,
        text: allStaion.typeName,
        code: allStaion.typeCode,
      }));
      setStationsOption(stationOptions);
    });
  };
  useEffect(() => {
    getAllStaionsData();
  }, []);

  const handleWbCreatePage = () => {
    const objectData = {
      id: Math.random(),
      factoryId: Number(id),
      stationTypeId: 1,
      stationCode: 0,
      stationName: '',
      account: '',
      accountName: '',
      password: '',
    };
    const newDatas = Object.assign([], createWbStation);
    newDatas.push(objectData);
    setCreateWbStation(newDatas);
  };

  const handleGuardhouseCreatePage = () => {
    const objectData = {
      id: Math.random(),
      factoryId: Number(id),
      stationTypeId: 2,
      stationCode: 0,
      stationName: '',
      account: '',
      accountName: '',
      password: '',
    };
    const newDatas = Object.assign([], createGuardhouse);

    newDatas.push(objectData);

    setCreateGuardhouse(newDatas);
  };

  const handleFeedInletCreatePage = () => {
    const objectData = {
      id: Math.random(),
      factoryId: Number(id),
      stationTypeId: 3,
      stationCode: 0,
      stationName: '',
      account: '',
      accountName: '',
      password: '',
    };
    const newDatas = Object.assign([], createFeedInlet);

    newDatas.push(objectData);

    setCreateFeedInlet(newDatas);
  };

  const handleQualityControlCreatePage = () => {
    const objectData = {
      id: Math.random(),
      factoryId: Number(id),
      stationTypeId: 4,
      stationCode: 0,
      stationName: '',
      account: '',
      accountName: '',
      password: '',
    };
    const newDatas = Object.assign([], createQualityControl);

    newDatas.push(objectData);

    setCreateQualityControl(newDatas);
  };

  const resetCreateWbIndex = () => {
    for (let i = 0; i < createWbStation.length; i++) {
      createWbStation[i].stationCode = updateWbStation.length + i + 1;
      createWbStation[i].account = `${factoryBranchCode}_W_${updateWbStation.length + i + 1}`;
      createWbStation[i].accountName = `${factoryBranchCode}_W_${updateWbStation.length + i + 1}`;
    }
  };

  const resetCreateGuardhouseIndex = () => {
    for (let i = 0; i < createGuardhouse.length; i++) {
      createGuardhouse[i].stationCode = updateGuardhouse.length + i + 1;
      createGuardhouse[i].account = `${factoryBranchCode}_G_${updateGuardhouse.length + i + 1}`;
      createGuardhouse[i].accountName = `${factoryBranchCode}_G_${updateGuardhouse.length + i + 1}`;
    }
  };

  const resetCreateFeedInletIndex = () => {
    for (let i = 0; i < createFeedInlet.length; i++) {
      createFeedInlet[i].stationCode = updateFeedInlet.length + i + 1;
      createFeedInlet[i].account = `${factoryBranchCode}_I_${updateFeedInlet.length + i + 1}`;
      createFeedInlet[i].accountName = `${factoryBranchCode}_I_${updateFeedInlet.length + i + 1}`;
    }
  };

  const resetCreateQualityControlIndex = () => {
    for (let i = 0; i < createQualityControl.length; i++) {
      createQualityControl[i].stationCode = updateQualityControl.length + i + 1;
      createQualityControl[i].account = `${factoryBranchCode}_Q_${updateQualityControl.length + i + 1}`;
      createQualityControl[i].accountName = `${factoryBranchCode}_Q_${updateQualityControl.length + i + 1}`;
    }
  };
  const [errorCode, setErrorCode] = useState<string>('');

  const getErrorCode = (error: any) => {
    if (error.code) {
      setErrorCode(error.code);
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    const concatCreateData = createWbStation.concat(createGuardhouse, createFeedInlet, createQualityControl);
    const concatUpdateData = updateWbStationTypeId.concat(
      updateGuardhouseTypeId,
      updateFeedInletTypeId,
      updateQualityControlTypeId,
    );

    const concatTotalData = concatCreateData.concat(concatUpdateData);

    console.log('總和concatTotalData', concatTotalData);

    StationApi.PutStation(
      concatTotalData,
      (updateData: StationsDataById[]) => {
        history(-1);
      },
      (error) => {
        getErrorCode(error);
        setWarningMoalOpen(true);
      },
      () => {
        setLoading(false);
      },
    );
  };
  const getAllTypes = () => {
    const searchParams: SearchTypeParamsByRemoved = { removed: false };
    TypeApi.getTypesBySearchRelatedRemoved(searchParams, typesDataToOptions);
  };

  const typesDataToOptions = (data: Array<TypeData>) => {
    const allTypesRemoveSome = data.filter(
      (value: TypeData) => value.id !== 9 && value.id !== 11 && value.id !== 7 && value.id !== 8,
    );
    const typesOptions = allTypesRemoveSome.map((type: TypeData) => ({ value: type.id, text: type.name }));
    setTypes(typesOptions);
  };

  useEffect(() => {
    resetCreateWbIndex();
  }, [createWbStation]);

  useEffect(() => {
    resetCreateGuardhouseIndex();
  }, [createGuardhouse]);

  useEffect(() => {
    resetCreateFeedInletIndex();
  }, [createFeedInlet]);

  useEffect(() => {
    resetCreateQualityControlIndex();
  }, [createQualityControl]);

  useEffect(() => {
    getAllTypes();
    getFactoryBranchCode();
  }, []);
  useEffect(() => {
    getUpdateWbStation();
    getUpdateGuardhouse();
    getUpdateFeedInlet();
    getUpdateQualityControl();
  }, [siteUpdateData]);
  return (
    <>
      <SiteManagementErrorMessage
        onWarningModalClose={handleWarningModalClose}
        warningMoalOpen={warningMoalOpen}
        errorCode={errorCode}
      />
      <Stack direction="row" sx={{ mt: 3 }}>
        <Stack direction="row">
          <YatungFormLabel label={i18T('SITEMANAGEMENT.AREA')} />
        </Stack>
        <Stack direction="row">
          <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{areaName}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ my: 3 }} alignItems={'center'}>
        <Stack direction="row">
          <YatungFormLabel label={i18T('SITEMANAGEMENT.FACTORY')} />
        </Stack>
        <Stack direction="row">
          <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{factoryName}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ my: 3 }} alignItems={'center'}>
        <Stack direction="row">
          <YatungFormLabel label={`${i18T('GLOBAL.STATE')}：`} />
        </Stack>
        <Stack direction="row">
          <Typography sx={{ fontSize: '24px', fontWeight: 400, color: removed ? '#CB333B' : '#3E9B2F' }}>
            {removed ? i18T('GLOBAL.CLOSING') : i18T('GLOBAL.RUNNING')}
          </Typography>
        </Stack>
      </Stack>
      <YatungDivider />
      <Stack flexDirection="row" sx={{ mt: 3 }}>
        <YatungButton
          disabled={errorCode === '99999' || removed === true}
          text={i18T('SITEMANAGEMENT.CREATE_WBSTATION')}
          onClick={handleWbCreatePage}
          color="green"
          sx={{ mr: 2 }}
        />
        <YatungButton
          disabled={errorCode === '99999' || removed === true}
          text={i18T('SITEMANAGEMENT.CREATE_GUARDHOUSE')}
          onClick={handleGuardhouseCreatePage}
          color="green"
          sx={{ mr: 2 }}
        />
        <YatungButton
          disabled={errorCode === '99999' || removed === true}
          text={i18T('SITEMANAGEMENT.CREATE_FEEDINLET')}
          onClick={handleFeedInletCreatePage}
          color="green"
          sx={{ mr: 2 }}
        />
        <YatungButton
          disabled={errorCode === '99999' || removed === true}
          text={i18T('SITEMANAGEMENT.CREATE_QUALITYCONTROL')}
          onClick={handleQualityControlCreatePage}
          color="green"
          sx={{ mr: 2 }}
        />
      </Stack>
      <Stack>
        {updateWbStation.concat(createWbStation).length > 0 && (
          <Card
            sx={{
              pointerEvents: !removed ? 'all' : 'none',
              opacity: !removed ? 1 : 0.4,
              transition: 'opacity 1.5s',
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              mt: 2,
            }}
          >
            <CardContent>
              {updateWbStationTypeId.map((data: StationsData, index: number) => {
                return (
                  <UpdateWbStationForm
                    key={data.id}
                    data={data}
                    types={types}
                    factoryBranchCode={factoryBranchCode}
                    setUpdateWbStationTypeId={setUpdateWbStationTypeId}
                    updateWbStationTypeId={updateWbStationTypeId}
                    index={index}
                    typeTransaction={typeTransaction}
                  />
                );
              })}
              {createWbStation.map((value: StationsData, index: number) => {
                return (
                  <CreateWbStationForm
                    key={value.id}
                    value={value}
                    factoryBranchCode={factoryBranchCode}
                    updateWbStation={updateWbStation}
                    setCreateWbStation={setCreateWbStation}
                    createWbStation={createWbStation}
                    index={index}
                    stationsOption={stationsOption}
                  />
                );
              })}
            </CardContent>
          </Card>
        )}

        {updateGuardhouse.concat(createGuardhouse).length > 0 && (
          <Card
            sx={{
              pointerEvents: !removed ? 'all' : 'none',
              opacity: !removed ? 1 : 0.4,
              transition: 'opacity 1.5s',
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              mt: 2,
            }}
          >
            <CardContent>
              {updateGuardhouseTypeId.map((data: StationsData, index: number) => {
                return (
                  <UpdateGuardhouseForm
                    key={data.id}
                    data={data}
                    types={types}
                    factoryBranchCode={factoryBranchCode}
                    setUpdateGuardhouseTypeId={setUpdateGuardhouseTypeId}
                    updateGuardhouseTypeId={updateGuardhouseTypeId}
                    stationsOption={stationsOption}
                    index={index}
                    typeTransaction={typeTransaction}
                  />
                );
              })}
              {createGuardhouse.map((value: StationsData, index: number) => {
                return (
                  <CreateGuardhouseForm
                    key={value.id}
                    value={value}
                    factoryBranchCode={factoryBranchCode}
                    updateGuardhouse={updateGuardhouse}
                    setCreateGuardhouse={setCreateGuardhouse}
                    createGuardhouse={createGuardhouse}
                    stationsOption={stationsOption}
                    index={index}
                  />
                );
              })}
            </CardContent>
          </Card>
        )}
        {updateFeedInlet.concat(createFeedInlet).length > 0 && (
          <Card
            sx={{
              pointerEvents: !removed ? 'all' : 'none',
              opacity: !removed ? 1 : 0.4,
              transition: 'opacity 1.5s',
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              mt: 2,
            }}
          >
            <CardContent>
              {updateFeedInletTypeId.map((data: StationsData, index: number) => {
                return (
                  <UpdateFeedInletForm
                    key={data.id}
                    data={data}
                    factoryBranchCode={factoryBranchCode}
                    setUpdateFeedInletTypeId={setUpdateFeedInletTypeId}
                    updateFeedInletTypeId={updateFeedInletTypeId}
                    stationsOption={stationsOption}
                    index={index}
                  />
                );
              })}
              {createFeedInlet.map((value: StationsData, index: number) => {
                return (
                  <CreateFeedInletForm
                    key={value.id}
                    value={value}
                    factoryBranchCode={factoryBranchCode}
                    updateFeedInlet={updateFeedInlet}
                    setCreateFeedInlet={setCreateFeedInlet}
                    createFeedInlet={createFeedInlet}
                    stationsOption={stationsOption}
                    index={index}
                  />
                );
              })}
            </CardContent>
          </Card>
        )}
        {updateQualityControl.concat(createQualityControl).length > 0 && (
          <Card
            sx={{
              pointerEvents: !removed ? 'all' : 'none',
              opacity: !removed ? 1 : 0.4,
              transition: 'opacity 1.5s',
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              mt: 2,
            }}
          >
            <CardContent>
              {updateQualityControlTypeId.map((data: StationsData, index: number) => {
                return (
                  <UpdateQualityControlForm
                    key={data.id}
                    data={data}
                    factoryBranchCode={factoryBranchCode}
                    setUpdateQualityControlTypeId={setUpdateQualityControlTypeId}
                    updateQualityControlTypeId={updateQualityControlTypeId}
                    index={index}
                  />
                );
              })}
              {createQualityControl.map((value: StationsData, index: number) => {
                return (
                  <CreateQualityControlForm
                    key={value.id}
                    value={value}
                    factoryBranchCode={factoryBranchCode}
                    updateQualityControl={updateQualityControl}
                    setCreateQualityControl={setCreateQualityControl}
                    createQualityControl={createQualityControl}
                    stationsOption={stationsOption}
                    index={index}
                  />
                );
              })}
            </CardContent>
          </Card>
        )}
      </Stack>
      <Stack justifyContent="flex-end" flexDirection="row" sx={{ pr: 4, mt: 5 }}>
        <SiteManagementUpdateSubmit
          onSubmit={handleSubmit}
          onGoBack={handleGoBack}
          errorCode={errorCode}
          removed={removed}
        />
      </Stack>
    </>
  );
}
