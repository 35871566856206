import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initialState {
  date: any;
}

export const materialInspectionSlice = createSlice({
  name: 'materialInspectionSlice',
  initialState: {
    date: new Date().getTime(),
  } as initialState,
  reducers: {
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
  },
});
