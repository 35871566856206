import React from 'react';
import AccountManagementCard from '../components/AccountManagementCard';

export default function AccountManagementCardScreen() {
  return (
    <>
      <AccountManagementCard />
    </>
  );
}
