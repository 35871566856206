import React from 'react';
import TransferOrderGroupManagementUpdateCard from '../components/TransferOrderGroupManagementUpdateCard';

export default function TransferOrderGroupManagementUpdateCardScreen() {
  return (
    <>
      <TransferOrderGroupManagementUpdateCard />
    </>
  );
}
