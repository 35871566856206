import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initialState {
  targetId: number;
  updateMixerData: any;
  mixsersData: any;
  checked: boolean;
  createMixer: any;
  createStorageData: any;
}
export const mixerInformationSlice = createSlice({
  name: 'mixerInformationSlice',
  initialState: {
    targetId: 0,
    updateMixerData: {
      areaName: '',
      factoryId: 0,
      factoryName: '',
      waterAbsorption: 0.0,
      mixers: [],
      lastUpdateTime: 0,
      isYardMixerActived: false,
      yardMixer: 0,
    },
    mixsersData: {
      factoryId: 0,
      id: 0,
      maintPropMixerCode: 0,
      mixerName: '',
      removed: false,
      storages: [],
    },
    createMixer: [],
    createStorageData: [],
    checked: false,
  } as initialState,
  reducers: {
    setTargetId: (state, action: PayloadAction<number>) => {
      state.targetId = action.payload;
    },
    setUpdateMixerData: (state, action: PayloadAction<any>) => {
      state.updateMixerData = action.payload;
    },
    setMixersData: (state, action: PayloadAction<any>) => {
      state.mixsersData = action.payload;
    },
    setChecked: (state, action: PayloadAction<boolean>) => {
      state.checked = action.payload;
      console.log('switch menu to', state.checked);
    },
    setCreateMixer: (state, action: PayloadAction<any>) => {
      state.createMixer = action.payload;
    },
    setCreateStorageData: (state, action: PayloadAction<any>) => {
      state.createStorageData = action.payload;
    },
  },
});
