import YatungWindow from '@Src/_basic/components/YatungWindow';
import { numberComma } from '@Src/_basic/helpers/NumberHelpers';
import PieChartIcon from '@Src/_basic/icons/PieChart';
import { ProportionData } from '@Src/_basic/object/ProductionHistoryType';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { useElementSize } from 'usehooks-ts';
import TextLine from '../../../../TextLine';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

export default function ViewProportion() {
  const { projectInfo } = useProductionHistory();
  const [titleRef, titleSize] = useElementSize();
  return (
    <Box sx={{ height: '100%' }}>
      <Title ref={titleRef}>使用配比</Title>
      <Box sx={{ pl: '50px', mt: '16px', height: '100%' }}>
        <Box sx={{ height: `calc(100% - ${titleSize.height}px - 16px)` }}>
          <YatungWindow
            className="scroll-bar"
            data={projectInfo.proportion}
            renderItem={(item: ProportionData) => {
              return (
                <Grid container direction="row" alignItems="center" sx={{ py: '4px' }}>
                  <Grid item xs={5}>
                    <TextLine icon={<PieChartIcon stroke="#002F8C" />} content={item.displayName} />
                  </Grid>
                  <Grid item xs={7}>
                    <TextLine title="設計強度：" content={`${numberComma(item.designedStrength)} PSI`} />
                  </Grid>
                </Grid>
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
