import {
  YardReciptData,
  UpdateFormDataRequest,
  PageableYardReciptData,
  SearchPageableYardReceiptsParams,
  SearchYardReceiptsParams,
} from '@Src/_basic/object/YardReceiptsType';
import axios from 'axios';
import { YardReceiptsProtocol } from './YardReceiptsProtocol';
import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../Api';

export class YardReceiptsApi {
  public static getYardReceipts(
    onSuccess?: (data: Array<YardReciptData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(YardReceiptsProtocol.ALL_YARD_RECEIPTS, undefined, onSuccess, onFailed, onFinally);
  }

  public static getYardReceiptsByIdAndReceiptCode(
    id: string,
    receiptCode: string,
    onSuccess?: (data: YardReciptData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
        receiptCode,
      },
    };
    Api.get(YardReceiptsProtocol.YARD_RECEIPT, config, onSuccess, onFailed, onFinally);
  }

  public static getCancelableYardReceiptsBySearch(
    params: SearchYardReceiptsParams,
    onSuccess?: (data: Array<YardReciptData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(YardReceiptsProtocol.YARD_RECEIPTS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getPageableYardReceiptsBySearch(
    params: SearchPageableYardReceiptsParams,
    onSuccess?: (data: PageableYardReciptData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(YardReceiptsProtocol.YARD_RECEIPTS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }
  public static getYardReceiptsTtl(
    params: SearchYardReceiptsParams,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(YardReceiptsProtocol.GET_YARD_RECEIPT_TTL, config, onSuccess, onFailed, onFinally);
  }
  public static updateYardReceipt(
    request: UpdateFormDataRequest,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(YardReceiptsProtocol.YARD_RECEIPT, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static deleteYardReceipt(
    id: string,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(YardReceiptsProtocol.YARD_RECEIPT, config, onSuccess, onFailed, onFinally);
  }
}
