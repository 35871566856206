import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import SampleSourceScreen from './screens/SampleSourceScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <SampleSourceScreen />,
  },
];

const SampleSourceManagementRouter = () => PermissionRoute(routesConfig);

export default memo(SampleSourceManagementRouter);
