import { Response } from '@Src/_basic/object/ApiType';
import { colorCodeOption, getEventsResponse, postParams, putParams } from '@Src/_basic/object/CalendarType';
import { Api } from '../Api';
import { CalendarProtocol } from './CalendarProtocol';

export class CalendarApi {
  //  取得行事曆
  public static getAllCalendar(
    onSuccess?: (data: Array<getEventsResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(CalendarProtocol.GET_CALENDAR_ALL, undefined, onSuccess, onFailed, onFinally);
  }
  //  根據Id取得行事曆內容
  public static getCalendarById(
    id: any,
    onSuccess?: (data: getEventsResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(CalendarProtocol.GET_CALENDAR_BY_ID, config, onSuccess, onFailed, onFinally);
  }
  //  新增行事曆
  public static postCalendar(
    request: postParams,
    onSuccess?: (data: Array<getEventsResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(CalendarProtocol.GET_CALENDAR_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }
  //  修改行事曆
  public static putCalendar(
    request: putParams,
    onSuccess?: (data: Array<getEventsResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(CalendarProtocol.GET_CALENDAR_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }
  //  刪除行事曆
  public static deleteCalendarById(
    id: number,
    onSuccess?: (data: Array<getEventsResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(CalendarProtocol.GET_CALENDAR_BY_ID, config, onSuccess, onFailed, onFinally);
  }
  //取得行事曆選項顏色
  public static getCalendarColorCode(
    onSuccess?: (data: Array<colorCodeOption>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(CalendarProtocol.GET_CALENDAR_COLOR_CODE, undefined, onSuccess, onFailed, onFinally);
  }
  //取得行事曆節日
  public static getCalendarFestival(
    onSuccess?: (data: Array<getEventsResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(CalendarProtocol.GET_CALENDAR_FESTIVAL, undefined, onSuccess, onFailed, onFinally);
  }
}
