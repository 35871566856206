import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LandMark(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 24">
        <path d="M11.7049 5.71834L11.7055 5.71893C12.1767 6.17205 12.4234 6.73568 12.4234 7.38364C12.4234 8.03163 12.1767 8.59511 11.7052 9.04772C11.2339 9.50016 10.6565 9.72979 10.0003 9.72979C9.34412 9.72979 8.76683 9.50015 8.29606 9.04746L11.7049 5.71834ZM11.7049 5.71834C11.2336 5.26662 10.6562 5.03749 10.0003 5.03749M11.7049 5.71834L10.0003 5.03749M10.0003 5.03749C9.34437 5.03749 8.76716 5.26664 8.29633 5.71864M10.0003 5.03749L8.29633 5.71864M8.29633 5.71864C7.82442 6.17167 7.57721 6.73539 7.57721 7.38364M8.29633 5.71864L7.57721 7.38364M7.57721 7.38364C7.57721 8.03163 7.82424 8.595 8.29603 9.04742L7.57721 7.38364ZM4.62827 12.2991L4.62816 12.2989C3.3883 10.5404 2.80798 8.96737 2.80798 7.56825C2.80798 5.38503 3.53326 3.68985 4.95959 2.42726L4.95966 2.42719C6.41587 1.13762 8.08914 0.499023 10.0003 0.499023C11.9114 0.499023 13.5847 1.13762 15.0409 2.42719L15.041 2.42726C16.4673 3.68985 17.1926 5.38503 17.1926 7.56825C17.1926 8.96745 16.6125 10.5405 15.3733 12.299C14.1863 13.9831 12.401 15.8214 10.0003 17.813C7.5996 15.8214 5.81467 13.9831 4.62827 12.2991Z" stroke="gray" fill="none"/>
        <path d="M4.61538 15.5646C1.83976 16.2889 0 17.5567 0 18.9997C0 21.2506 4.47715 23.0754 10 23.0754C15.5228 23.0754 20 21.2506 20 18.9997C20 17.4272 17.8152 16.0628 14.6154 15.3831V16.0827C15.4079 16.26 16.1259 16.4806 16.7487 16.7344C18.5015 17.4488 19.2308 18.2988 19.2308 18.9997C19.2308 19.7005 18.5015 20.5505 16.7487 21.2649C15.0613 21.9526 12.676 22.3961 10 22.3961C7.32399 22.3961 4.93873 21.9526 3.25128 21.2649C1.49849 20.5505 0.769231 19.7005 0.769231 18.9997C0.769231 18.2988 1.49849 17.4488 3.25128 16.7344C3.66587 16.5655 4.12259 16.4112 4.61538 16.2747V15.5646Z" stroke="gray"/>
        <path d="M4.61538 15.5646C1.83976 16.2889 0 17.5567 0 18.9997C0 21.2506 4.47715 23.0754 10 23.0754C15.5228 23.0754 20 21.2506 20 18.9997C20 17.4272 17.8152 16.0628 14.6154 15.3831V16.0827C15.4079 16.26 16.1259 16.4806 16.7487 16.7344C18.5015 17.4488 19.2308 18.2988 19.2308 18.9997C19.2308 19.7005 18.5015 20.5505 16.7487 21.2649C15.0613 21.9526 12.676 22.3961 10 22.3961C7.32399 22.3961 4.93873 21.9526 3.25128 21.2649C1.49849 20.5505 0.769231 19.7005 0.769231 18.9997C0.769231 18.2988 1.49849 17.4488 3.25128 16.7344C3.66587 16.5655 4.12259 16.4112 4.61538 16.2747V15.5646Z" stroke="gray"/>
    </SvgIcon>
  );
}
