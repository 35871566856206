import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import SymbolColumnFormatter from '../FactoryManagementTable/columnFormatter/SymbolColumnFormatter';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import RemovedColumnFormatter from './columnFormatter/RemovedColumnFormatter';

interface Props {
  factoryData: FactoryData[];
  getAllFactory: () => void;
}
export default function FactoryManagementTable({ factoryData, getAllFactory }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();

  const { actionLoading } = useApi();
  const { userAreaOptionsData } = useAuth();

  const handleGoToUpdatePage = useCallback(
    (id: number) => {
      navigate(`/UsersManagePage/FactoryManagement/FactoryManagementUpdate/${id}`, { state: userAreaOptionsData });
    },
    [navigate, userAreaOptionsData],
  );

  const handleChangeStatus = useCallback(
    (id: number) => {
      FactoryApi.DeleteFactoryById(id, getAllFactory);
    },
    [getAllFactory],
  );

  const getButtonText = (status: string) => {
    switch (status) {
      case '開啟':
        return i18T('FACTORYMANAGEMENT.ACTION_OPEN');
      case '關閉':
        return i18T('FACTORYMANAGEMENT.ACTION_CLOSE');
      case '編輯':
        return i18T('FACTORYMANAGEMENT.UPDATE');
      case '運轉中':
        return i18T('GLOBAL.RUNNING');
      case '關閉中':
        return i18T('GLOBAL.CLOSING');
      default:
        return '';
    }
  };

  const columns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.TABLE.NO'),
      dataField: 'ids',
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.AREA'),
      dataField: 'areaName',
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.FACTORY'),
      dataField: 'factoryName',
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.ADDRESS'),
      dataField: 'factoryAddress',
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.PHONE'),
      dataField: 'factoryPhone',
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.NUMBERING'),
      dataField: 'factoryCode',
      formatter: SymbolColumnFormatter,
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.STATE'),
      dataField: 'removed',
      formatter: RemovedColumnFormatter,
      formatExtraData: {
        getButtonText: getButtonText,
      },
    },
    {
      text: i18T('USERSMANAGEMENU.TABLE.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onGoToUpdatePage: handleGoToUpdatePage,
        onStop: (id: number) => handleChangeStatus(id),
        getButtonText: getButtonText,
      },
    },
  ];
  return <YatungTable columns={columns} data={factoryData} loading={actionLoading} />;
}
