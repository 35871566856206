import { DropdownItem } from '@Src/_basic/components/YatungSelect';
import YatungWindow from '@Src/_basic/components/YatungWindow';
import { UserApiPermissionHelpers } from '@Src/_basic/helpers/UserApiPermissionHelpers';
import { GroupBasicInfo } from '@Src/_basic/object/AuthType';
import { TestItemData } from '@Src/_basic/object/TestItemType';
import { TestItemApi } from '@Src/_basic/protocol/testItem/TestItemApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { Box, Grid, Typography, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Member from '../../../icons/Member';
import Triangle from '../../../icons/Triangle';
import ExptScheduleCard from '../../ExptScheduleCard';
import ExptScheduleCheckBoxWithSelect, { Option } from '../../ExptScheduleCheckBoxWithSelect';

interface Props {
  formik: any;
}

const Title = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '24px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '0.28em',
}));

export default function SelectExptCard({ formik }: Props) {
  // TODO using i18n

  const { userGroupsData, userApiPermission } = useAuth();
  const [checkedValues, setCheckedValues] = useState<Array<any>>([]);
  const [exptItems, setExptItems] = useState<Array<TestItemData>>([]);

  const operatorGroup: Array<Option> = useMemo(() => {
    return (
      userGroupsData?.supervisorAssignGroups.map((item: GroupBasicInfo<DropdownItem<string>>) => ({
        text: item.groupName,
        value: item.id,
      })) || []
    );
  }, [userGroupsData]);

  const getOperatorListOptions = useCallback(
    (groupId: number) => {
      const group = userGroupsData?.supervisorAssignGroups.find(
        (item: GroupBasicInfo<DropdownItem<string>>) => item.id == groupId,
      );

      return (
        group?.users.map((item: DropdownItem<string>) => ({
          text: item.text ?? '',
          value: item.value,
        })) || []
      );
    },
    [userGroupsData],
  );

  const getSelectedGroup = useCallback(
    (exptItemId: number) => {
      return formik.values.exptItems.find((item: any) => item.exptItemId === exptItemId)?.groupId;
    },
    [formik.values.exptItems],
  );

  const getSelectedOperator = useCallback(
    (exptItemId: number) => {
      return formik.values.exptItems.find((item: any) => item.exptItemId === exptItemId)?.operator;
    },
    [formik.values.exptItems],
  );

  const getSelectError = useCallback(
    (exptItemId: number) => {
      const operator = getSelectedOperator(exptItemId);

      if (operator === undefined) {
        return formik.errors.exptItems ? true : false;
      }
    },
    [formik.errors.exptItems, getSelectedOperator],
  );

  const handleCheckBoxChange = useCallback(
    (value: any) => {
      if (checkedValues.includes(value)) {
        formik.setFieldValue(
          'exptItems',
          formik.values.exptItems.filter((item: any) => item.exptItemId !== value),
        );
        setCheckedValues((current) => current.filter((item) => item !== value));
      } else {
        formik.setFieldValue('exptItems', [
          ...formik.values.exptItems,
          {
            exptItemId: value,
            groupId: undefined,
            operator: undefined,
          },
        ]);
        setCheckedValues((current) => [...current, value]);
      }
    },
    [checkedValues, formik.values.exptItems],
  );

  const handleGroupChange = useCallback(
    (key: any, value: any) => {
      const exptItems = [...formik.values.exptItems];
      exptItems.find((item: any) => item.exptItemId === key).groupId = value;

      formik.setFieldValue('exptItems', exptItems);
    },
    [formik.values.exptItems],
  );

  const handleOperatorChange = useCallback(
    (key: any, value: any) => {
      const exptItems = [...formik.values.exptItems];
      exptItems.find((item: any) => item.exptItemId === key).operator = value;

      formik.setFieldValue('exptItems', exptItems);
    },
    [formik.values.exptItems],
  );

  const getExptItems = useCallback(() => {
    if (formik.values.exptTypeId > 0) {
      if (formik.values.isRequirement === 'true') {
        TestItemApi.getDemandOrderItemsBySearch(
          {
            exptTypeId: formik.values.exptTypeId,
            specificationId: formik.values.specificationId,
          },
          (data: Array<TestItemData>) => setExptItems(data),
        );
      } else {
        TestItemApi.getTestOrderItemsBySearch(
          {
            exptTypeId: formik.values.exptTypeId,
            specificationId: formik.values.specificationId,
          },
          (data: Array<TestItemData>) => setExptItems(data),
        );
      }
    }
  }, [formik.values.isRequirement, formik.values.exptTypeId, formik.values.specificationId]);

  const getExptItemsByTest = useCallback(() => {
    if (formik.values.exptTypeId > 0) {
      if (formik.values.isRequirement === 'false') {
        TestItemApi.getDemandOrderItemsBySearch(
          {
            exptTypeId: formik.values.exptTypeId,
            specificationId: formik.values.specificationId,
          },
          (data: Array<TestItemData>) => setExptItems(data),
        );
      } else {
        TestItemApi.getTestOrderItemsBySearch(
          {
            exptTypeId: formik.values.exptTypeId,
            specificationId: formik.values.specificationId,
          },
          (data: Array<TestItemData>) => setExptItems(data),
        );
      }
    }
  }, [formik.values.isRequirement, formik.values.exptTypeId, formik.values.specificationId]);

  const clearExptItem = useCallback(() => {
    const exptItemIds = exptItems.map((item) => item.id);
    const newExptItems = formik.values.exptItems.filter((item: any) => exptItemIds.includes(item.exptItemId));

    formik.setFieldValue('exptItems', newExptItems);
  }, [exptItems]);

  const header = useMemo(() => {
    return (
      <Grid container direction="row" alignItems="center" sx={{ height: '100%', px: '24px' }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }} gap="12px">
            <Triangle />
            <Title>試驗名稱</Title>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }} gap="12px">
            <Member />
            <Title>指派人員</Title>
          </Box>
        </Grid>
      </Grid>
    );
  }, []);

  useEffect(() => {
    if (UserApiPermissionHelpers('WD063', userApiPermission)) {
      getExptItems();
    } else {
      getExptItemsByTest();
    }
  }, [getExptItems]);

  useEffect(() => {
    clearExptItem();
  }, [exptItems]);

  return (
    <ExptScheduleCard
      header={header}
      body={
        <Box sx={{ height: '100%', padding: '24px 24px' }}>
          <YatungWindow
            className="scroll-bar"
            data={exptItems}
            renderItem={(item: TestItemData) => {
              return (
                <ExptScheduleCheckBoxWithSelect
                  text={item.itemName}
                  value={item.id}
                  checkedValues={checkedValues}
                  onChange={handleCheckBoxChange}
                  options={
                    UserApiPermissionHelpers('WD063', userApiPermission)
                      ? formik.values.isRequirement === 'true'
                        ? operatorGroup
                        : undefined
                      : formik.values.isRequirement === 'false'
                      ? operatorGroup
                      : undefined
                  }
                  selectedValue={getSelectedGroup(item.id)}
                  onSelected={(value: any) => {
                    handleGroupChange(item.id, value);
                  }}
                  options2={getOperatorListOptions(getSelectedGroup(item.id))}
                  selectedValue2={getSelectedOperator(item.id)}
                  onSelected2={(value: any) => {
                    handleOperatorChange(item.id, value);
                  }}
                  error={getSelectError(item.id)}
                />
              );
            }}
          />
        </Box>
      }
    />
  );
}
