import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import MaterialInspection from './pages/MaterialInspection';
import MonitorInformationRouter from './pages/MonitorInformation/MonitorInformationRouter';
import TestInDistrict from './pages/TestInDistrict';

const routesConfig: RouteConfig[] = [
  {
    path: '/testInDistrict/*',
    element: <TestInDistrict />,
    permission: 'WD039',
  },
  {
    path: '/materialInspection/*',
    element: <MaterialInspection />,
    permission: 'WD040',
  },
  {
    path: '/deliveryInformation/*',
    element: <MonitorInformationRouter />,
    permission: 'WD041',
  },
];

const RealTimeMonitoring = () => PermissionRoute(routesConfig);

export default memo(RealTimeMonitoring);
