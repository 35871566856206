import React, { memo } from 'react';

import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import ProportionPage from './pages';
import ProportionTagTable from './pages/ProportionTagTable';

const routesConfig: RouteConfig[] = [
  {
    path: '/proportionList/*',
    element: <ProportionPage />,
    permission: 'WD036',
  },
  {
    path: '/proportionTagTable',
    element: <ProportionTagTable />,
    permission: 'WD037',
  },
];

const ProportionManage = () => PermissionRoute(routesConfig);

export default memo(ProportionManage);
