import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import { GetClearExecution } from '@Src/_basic/object/ClearExecutionType';

interface Props {
  row: GetClearExecution;
}
export default function ClearTimeColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{moment(row.createTime).format('YYYY/MM/DD HH:MM')}</Typography>;
}
