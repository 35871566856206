import YatungModal from '@Src/_basic/components/YatungModal';
import { CreateProportionFormType, CreateProportionRealPropsRequest } from '@Src/_basic/object/ProductionHistoryType';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import CreateItemForm from './components/CreateItemForm';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

interface Props {
  onSubmitButtonClick: (value: CreateProportionFormType) => void;
  onCancelcelButtonClick: () => void;
}

export default function CreateItemCard({ onSubmitButtonClick, onCancelcelButtonClick }: Props) {
  const formik = useFormik({
    initialValues: {
      displayName: '',
      designedStrength: 0,
      carbonEmissionFactor: 0,
      carbonEmissionLifeCycleFactor: 0,
      realProps: [],
    },
    onSubmit: onSubmitButtonClick,
    enableReinitialize: true,
  });

  const handleDisplayNameChange = (value: string) => {
    formik.setFieldValue('displayName', value);
  };

  const handleDesignedStrengthChange = (value: number) => {
    formik.setFieldValue('designedStrength', value);
  };

  const handleCarbonEmissionChange = (value: number) => {
    formik.setFieldValue('carbonEmissionFactor', value);
  };

  const handleCarbonEmissionLifeCycleChange = (value: number) => {
    formik.setFieldValue('carbonEmissionLifeCycleFactor', value);
  };

  const handleRealPropsChange = (value: Array<CreateProportionRealPropsRequest>) => {
    formik.setFieldValue('realProps', value);
  };

  const handleSubmitButtonClick = () => {
    formik.submitForm();
  };

  const handleCancelButtonClick = () => {
    formik.resetForm();
    onCancelcelButtonClick?.();
  };

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Box sx={{ opacity: 0.2 }}>
        <Title>使用配比</Title>
      </Box>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '100%',
          width: '100%',
        }}
      >
        <YatungModal
          title={'新增配比'}
          body={
            <CreateItemForm
              displayName={formik.values.displayName}
              designedStrength={formik.values.designedStrength}
              carbonEmissionFactor={formik.values.carbonEmissionFactor}
              carbonEmissionLifeCycleFactor={formik.values.carbonEmissionLifeCycleFactor}
              realProps={formik.values.realProps}
              onDisplayNameChange={handleDisplayNameChange}
              onDesignedStrengthChange={handleDesignedStrengthChange}
              onCarbonEmissionChange={handleCarbonEmissionChange}
              onCarbonEmissionLifeCycleChange={handleCarbonEmissionLifeCycleChange}
              onRealPropsChange={handleRealPropsChange}
              onConfirmButtonClick={handleSubmitButtonClick}
              onCancelcelButtonClick={handleCancelButtonClick}
            />
          }
          sx={{ mt: '0px', overflow: 'auto' }}
        />
      </Stack>
    </Box>
  );
}
