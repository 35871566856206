import { Protocol } from '../Protocol';

export class FeedTroughMaintenanceProtocol {
  public static readonly FEEDTHROUGHMAINTENANCE_MANAGEMENT_DATA: string = `api/feedTroughMaintenanceManagement`;
  public static readonly FEEDTHROUGHMAINTENANCE_MANAGEMENT_CREATE_DATA: string = `api/feedTroughMaintenanceManagement/create`;

  public static readonly FEEDTHROUGHMAINTENANCE_MANAGEMENT_UPDATE_DATA: string = `api/feedTroughMaintenanceManagement/update`;
  public static readonly FEEDTHROUGHMAINTENANCE_MANAGEMENT_SEARCH_DATA: string = `api/feedTroughMaintenanceManagement/search`;

  public static readonly GET_STORAGE_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/storage/all`;
  public static readonly GET_STORAGE_PAGINATION_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/storage/p/all`;
  public static readonly GET_STORAGE_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/storage`;
}
