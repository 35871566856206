import theme from '@Basic/theme';
import '@Styles/index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import axios from 'axios';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './app/App';
import * as _redux from './redux';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

_redux.setupAxios(axios, store);
_redux.mockAxios(axios);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement); //要使用Lazy Loading否會報錯，使用Suspense來顯示Loading畫面
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <CssBaseline />
          <App />
        </Suspense>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
