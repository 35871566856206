import { BomHierarchyData } from '@Src/_basic/object/MaterialFactoryCodeType';
import { PageableSignature, SignatureData } from '@Src/_basic/object/SignatureType';
import signatureTableMock from '@Src/_basic/protocol/signature/_mocks_/SignatureTableMock';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialState {
  signature: SignatureData;
  signatures: Array<SignatureData>;
  pageableSignatures: PageableSignature | undefined;
  bomHierarchy: Array<BomHierarchyData>;
}

export const signatureSlice = createSlice({
  name: 'signatureSlice',
  initialState: {
    signature: signatureTableMock[0],
    signatures: [],
    pageableSignatures: undefined,
    bomHierarchy: [],
  } as initialState,
  reducers: {
    setSignature: (state, action: PayloadAction<SignatureData>) => {
      state.signature = action.payload;
    },
    setSignatures: (state, action: PayloadAction<Array<SignatureData>>) => {
      state.signatures = action.payload;
    },
    setPageableSignature: (state, action: PayloadAction<PageableSignature>) => {
      state.pageableSignatures = action.payload;
    },
    clearSignature: (state) => {
      state.signature = signatureTableMock[0];
    },
    setBomHierarchy: (state, action: PayloadAction<Array<BomHierarchyData>>) => {
      state.bomHierarchy = action.payload;
    },
  },
});
