import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CarPlate(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M22.2,5.8c0.7,0,1.3,0.6,1.3,1.4v9.7c0,0.8-0.6,1.4-1.3,1.4H1.8c-0.7,0-1.3-0.6-1.3-1.4V7.1c0-0.8,0.6-1.4,1.3-1.4H22.2 M22.2,5.2H1.8C0.8,5.2,0,6.1,0,7.1v9.7c0,1,0.8,1.9,1.8,1.9h20.4c1,0,1.8-0.8,1.8-1.9V7.1C24,6.1,23.2,5.2,22.2,5.2L22.2,5.2z" />
      <path d="M21.3,6.6c0.7,0,1.3,0.6,1.3,1.4v7.9c0,0.8-0.6,1.4-1.3,1.4H2.7c-0.7,0-1.3-0.6-1.3-1.4V8.1c0-0.8,0.6-1.4,1.3-1.4H21.3 M21.3,6.1H2.7c-1,0-1.8,0.9-1.8,1.9v7.9c0,1.1,0.8,1.9,1.8,1.9h18.6c1,0,1.8-0.9,1.8-1.9V8.1C23.1,7,22.3,6.1,21.3,6.1L21.3,6.1z" />
      <path d="M3.1,9.3C3.6,9.3,4,8.9,4,8.4c0-0.5-0.4-0.9-0.9-0.9S2.2,7.9,2.2,8.4C2.2,8.9,2.6,9.3,3.1,9.3z M3.1,9.7c0.7,0,1.3-0.6,1.3-1.3C4.4,7.7,3.9,7,3.1,7S1.8,7.7,1.8,8.4C1.8,9.1,2.4,9.7,3.1,9.7z" />
      <path d="M4.4,9.7c0.1,0,0.1,0,0.2,0h1.6c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H4.6c0,0-0.1,0-0.1,0c0.2,0.2,0.3,0.5,0.3,0.8C4.8,9.2,4.7,9.5,4.4,9.7z" />
      <path d="M20.9,9.3c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C21.8,8.9,21.4,9.3,20.9,9.3zM20.9,9.7c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.4,1.3-1.4c0.7,0,1.3,0.6,1.3,1.4C22.2,9.1,21.6,9.7,20.9,9.7z" />
      <path d="M19.6,9.7c-0.1,0-0.1,0-0.2,0h-1.6c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h1.6c0,0,0.1,0,0.1,0c-0.2,0.2-0.3,0.5-0.3,0.8C19.2,9.2,19.4,9.5,19.6,9.7z" />
      <path d="M3.1,16.5c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9C2.2,16.1,2.6,16.5,3.1,16.5z M3.1,17c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4s-1.3,0.6-1.3,1.4C1.8,16.3,2.4,17,3.1,17z" />
      <path d="M4.4,16c0.1,0,0.1,0,0.2,0h1.6c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H4.6c0,0-0.1,0-0.1,0c0.2,0.2,0.3,0.5,0.3,0.8C4.8,15.5,4.7,15.8,4.4,16z" />
      <path d="M20.9,16.5c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C21.8,16.1,21.4,16.5,20.9,16.5zM20.9,17c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4c0.7,0,1.3,0.6,1.3,1.4C22.2,16.3,21.6,17,20.9,17z" />
      <path d="M19.6,16c-0.1,0-0.1,0-0.2,0h-1.6c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h1.6c0,0,0.1,0,0.1,0c-0.2,0.2-0.3,0.5-0.3,0.8C19.2,15.5,19.4,15.8,19.6,16z" />
    </SvgIcon>
  );
}
