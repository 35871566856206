import React from 'react';

export default function Triangle() {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_921_10230)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.7376 4.85036C28.483 3.58361 27.5514 2 26.0624 2H-1L0.168739 3.7265C0.392661 4.05751 0.770588 4.25654 1.17592 4.25654H22.6951C23.7826 4.25654 24.4628 5.38573 23.9186 6.33655L14.8134 22.249C14.2692 23.1998 12.9087 23.1998 12.3644 22.249L4.40247 8.41332H9.2456C9.71045 8.41332 10.1337 8.15189 10.335 7.74109L11.1702 6.03801H0.16496L11.9232 26.0499C12.6677 27.3167 14.53 27.3167 15.2745 26.0499L27.7376 4.85036ZM12.1819 25.8979C12.8104 26.9674 14.3873 26.9674 15.0158 25.8979L27.479 4.69832C28.1018 3.63989 27.3291 2.3 26.0624 2.3H-0.434644L0.41717 3.55833C0.584405 3.80554 0.868583 3.95654 1.17592 3.95654H22.6951C24.005 3.95654 24.8416 5.32787 24.179 6.48555C24.179 6.48556 24.179 6.48553 24.179 6.48555L15.0738 22.398C14.4144 23.55 12.7638 23.5501 12.1043 22.3983C12.1042 22.3982 12.1043 22.3984 12.1043 22.3983L3.8837 8.11332H9.2456C9.59814 8.11332 9.91566 7.91512 10.0656 7.60911L10.6889 6.33801H0.689181L12.1819 25.8979Z"
          fill="white"
        />
        <path
          d="M24.179 6.48555C24.8416 5.32787 24.005 3.95654 22.6951 3.95654H1.17592C0.868583 3.95654 0.584405 3.80554 0.41717 3.55833L-0.434644 2.3H26.0624C27.3291 2.3 28.1018 3.63989 27.479 4.69832L15.0158 25.8979C14.3873 26.9674 12.8104 26.9674 12.1819 25.8979L0.689181 6.33801H10.6889L10.0656 7.60911C9.91566 7.91512 9.59814 8.11332 9.2456 8.11332H3.8837L12.1043 22.3983M24.179 6.48555C24.179 6.48553 24.179 6.48556 24.179 6.48555ZM24.179 6.48555L15.0738 22.398C14.4144 23.55 12.7638 23.5501 12.1043 22.3983M12.1043 22.3983C12.1043 22.3984 12.1042 22.3982 12.1043 22.3983ZM27.7376 4.85036C28.483 3.58361 27.5514 2 26.0624 2H-1L0.168739 3.7265C0.392661 4.05751 0.770588 4.25654 1.17592 4.25654H22.6951C23.7826 4.25654 24.4628 5.38573 23.9186 6.33655L14.8134 22.249C14.2692 23.1998 12.9087 23.1998 12.3644 22.249L4.40247 8.41332H9.2456C9.71045 8.41332 10.1337 8.15189 10.335 7.74109L11.1702 6.03801H0.16496L11.9232 26.0499C12.6677 27.3167 14.53 27.3167 15.2745 26.0499L27.7376 4.85036Z"
          stroke="white"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_921_10230">
          <rect width={28} height={28} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
