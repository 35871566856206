import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function NewStaff(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <path
        d="M20.8933 6.70782C20.8933 10.1357 18.1107 12.9157 14.6768 12.9157C11.2429 12.9157 8.46027 10.1357 8.46027 6.70782C8.46027 3.27996 11.2429 0.5 14.6768 0.5C18.1107 0.5 20.8933 3.27996 20.8933 6.70782ZM23.1421 27.9406C23.1421 28.7953 23.3552 29.6009 23.7311 30.3063H0.508321C0.502786 30.1425 0.5 29.9779 0.5 29.8126C0.5 21.8568 6.95795 15.4063 14.9255 15.4063C20.3983 15.4063 25.1598 18.4497 27.6047 22.9357C25.0933 23.2209 23.1421 25.3529 23.1421 27.9406Z"
        stroke="white"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6574 32C30.7676 32 32.4783 30.2893 32.4783 28.1791C32.4783 26.0688 30.7676 24.3582 28.6574 24.3582C26.5471 24.3582 24.8364 26.0688 24.8364 28.1791C24.8364 30.2893 26.5471 32 28.6574 32ZM28.5777 24.8359C28.7536 24.8359 28.8961 24.9785 28.8961 25.1543V27.7812H31.523C31.6989 27.7812 31.8414 27.9238 31.8414 28.0996C31.8414 28.2755 31.6989 28.418 31.523 28.418H28.8961V31.0449C28.8961 31.2208 28.7535 31.3633 28.5777 31.3633C28.4018 31.3633 28.2593 31.2208 28.2593 31.0449V28.418H25.6324C25.4565 28.418 25.314 28.2755 25.314 28.0996C25.314 27.9238 25.4565 27.7812 25.6324 27.7812H28.2593V25.1543C28.2593 24.9785 28.4018 24.8359 28.5777 24.8359Z"
        fill="white"
      />
    </SvgIcon>
  );
}
