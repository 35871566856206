import { CalcCoarseSieve, CalcComboSieveAnalysis } from '@Src/_basic/helpers/CalcTestForm';
import {
  getPassedResultByKey,
  getRejectedResultByKey,
  isCustomStandardPassed,
  isLegalStandardPassed,
  isRejected,
} from '@Src/_basic/helpers/VerifyResultHelper';
import { CustomStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/CustomStandardType';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { ExperimentResultType } from '@Src/_basic/object/TestType';
import { CoarseSieveLegalTypeEnum } from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import { FineSieve } from '@Src/_basic/object/test/sandRock/FineSieveType';
import { CustomStandardV2Api } from '@Src/_basic/protocol/customStandardV2/CustomStandardV2Api';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { Stack, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TestCardContainer, TestVerifyResultStyles } from '../MuiStyleComponent/TestComponent';
import SectionCollapse from './SectionCollapse';
import TestTable from './TestTable';

interface Props {
  testForm: FineSieve[];
  ratio: number;
  type: CoarseSieveLegalTypeEnum;
}

const CoarseSieveReportTable = ({ testForm, ratio, type = 0 }: Props) => {
  const { t: i18T } = useTranslation();
  const [legalStandardType1, setLegalStandardType1] =
    useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [legalStandardType2, setLegalStandardType2] =
    useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();

  const [customStandardType1, setCustomStandardType1] =
    useState<Map<string, CustomStandardV2SpecificationDetailsResponse>>();
  const [customStandardType2, setCustomStandardType2] =
    useState<Map<string, CustomStandardV2SpecificationDetailsResponse>>();

  const calcTestFormS1 = useMemo(() => new CalcCoarseSieve(testForm[0]), [testForm]);
  const calcTestFormS2 = useMemo(() => new CalcCoarseSieve(testForm[1]), [testForm]);
  const calcTestFormMix = useMemo(
    () => new CalcComboSieveAnalysis(calcTestFormS1, calcTestFormS2, ratio),
    [calcTestFormS1, calcTestFormS2, ratio],
  );

  const mixValueMappingTable = useMemo(() => {
    const mixValueMappingTableTemp = new Map<string, number>();
    mixValueMappingTableTemp.set('37.5mm', calcTestFormMix.poreDiameterRemandPercentage1);
    mixValueMappingTableTemp.set('25.0mm', calcTestFormMix.poreDiameterRemandPercentage2);
    mixValueMappingTableTemp.set('19.0mm', calcTestFormMix.poreDiameterRemandPercentage3);
    mixValueMappingTableTemp.set('12.5mm', calcTestFormMix.poreDiameterRemandPercentage4);
    mixValueMappingTableTemp.set('9.5mm', calcTestFormMix.poreDiameterRemandPercentage5);
    mixValueMappingTableTemp.set('4.75mm', calcTestFormMix.poreDiameterRemandPercentage6);
    mixValueMappingTableTemp.set('2.36mm', calcTestFormMix.poreDiameterRemandPercentage7);
    mixValueMappingTableTemp.set('0.075mm', calcTestFormMix.poreDiameterRemandPercentage8);
    mixValueMappingTableTemp.set('lossPercentage', calcTestFormMix.lossPercentage);
    mixValueMappingTableTemp.set('dirtContentRatio', calcTestFormMix.dirtContent);

    return mixValueMappingTableTemp;
  }, [calcTestFormMix]);

  const verifyResult = useMemo(() => {
    const resultMap = new Map<string, boolean>();
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE2:
        mixValueMappingTable.forEach((value, key) => {
          resultMap.set(key, getPassedResultByKey(legalStandardType2, key, value));
        });
        break;
      default:
        mixValueMappingTable.forEach((value, key) => {
          resultMap.set(key, getPassedResultByKey(legalStandardType1, key, value));
        });
        break;
    }

    return resultMap;
  }, [legalStandardType1, legalStandardType2, mixValueMappingTable, type]);

  const verifyCustomResult = useMemo(() => {
    const resultMap = new Map<string, boolean>();
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE2:
        mixValueMappingTable.forEach((value, key) => {
          resultMap.set(key, getPassedResultByKey(customStandardType2, key, value));
        });
        break;
      default:
        mixValueMappingTable.forEach((value, key) => {
          resultMap.set(key, getPassedResultByKey(customStandardType1, key, value));
        });
        break;
    }

    return resultMap;
  }, [customStandardType1, customStandardType2, mixValueMappingTable, type]);

  const verifyCustomRejectResult = useMemo(() => {
    const resultMap = new Map<string, boolean>();
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE2:
        mixValueMappingTable.forEach((value, key) => {
          resultMap.set(key, getRejectedResultByKey(customStandardType2, key, value));
        });
        break;
      default:
        mixValueMappingTable.forEach((value, key) => {
          resultMap.set(key, getRejectedResultByKey(customStandardType1, key, value));
        });
        break;
    }

    return resultMap;
  }, [customStandardType1, customStandardType2, mixValueMappingTable, type]);

  const isRecordLegalPassed = useMemo(() => {
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE2:
        return (
          isLegalStandardPassed(testForm[0].verifyPassedResults.type2) &&
          isLegalStandardPassed(testForm[1].verifyPassedResults.type2)
        );
      default:
        return (
          isLegalStandardPassed(testForm[0].verifyPassedResults.type1) &&
          isLegalStandardPassed(testForm[1].verifyPassedResults.type1)
        );
    }
  }, [testForm]);

  const isRecordCustomPassed = useMemo(() => {
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE2:
        return (
          isCustomStandardPassed(testForm[0].verifyCustomResults.type2) &&
          isCustomStandardPassed(testForm[1].verifyCustomResults.type2)
        );
      default:
        return (
          isCustomStandardPassed(testForm[0].verifyCustomResults.type1) &&
          isCustomStandardPassed(testForm[1].verifyCustomResults.type1)
        );
    }
  }, [testForm]);

  const isRecordCustomRejected = useMemo(() => {
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE2:
        return isRejected(testForm[0].verifyCustomResults.type2) || isRejected(testForm[1].verifyCustomResults.type2);
      default:
        return isRejected(testForm[0].verifyCustomResults.type1) || isRejected(testForm[1].verifyCustomResults.type1);
    }
  }, [testForm, type]);

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(verifyResult);
  }, [verifyResult]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(verifyCustomResult);
  }, [verifyCustomResult]);

  const isCustomRejected = useMemo(() => {
    return isRejected(verifyCustomRejectResult);
  }, [verifyCustomRejectResult]);

  const recordVerifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isRecordLegalPassed && isRecordCustomPassed) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isRecordLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isRecordCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isRecordCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [isRecordLegalPassed, isRecordCustomPassed, isRecordCustomRejected]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstHeader = useMemo(
    () => ({
      title: i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`),
      values: [
        `${i18T(`TEST.SIEVE_ANALYSIS.ACCUMULATION_SIEVE_WEIGHT`)}(g)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.ACCUMULATION_PERCENTAGE`)}(%)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.PASS_SIEVE_PERCENTAGE`)}(%)`,
      ],
    }),
    [i18T],
  );
  const firstData = useMemo(
    () => [
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.37500um`),

        values: [
          [
            testForm[0].poreDiameter1.toFixed(1),
            testForm[0].poreDiameter1Percentage.toFixed(1),
            testForm[0].sievingPoreDiameter1Percentage.toFixed(1),
          ],
          [
            testForm[1].poreDiameter1.toFixed(1),
            testForm[1].poreDiameter1Percentage.toFixed(1),
            testForm[1].sievingPoreDiameter1Percentage.toFixed(1),
          ],
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? [
                testForm[0].verifyPassedResults?.type1['37.5mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type1['37.5mm'] ? styles.textB : styles.abnormal,
              ]
            : [
                testForm[0].verifyPassedResults?.type2['37.5mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type2['37.5mm'] ? styles.textB : styles.abnormal,
              ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.25000um`),

        values: [
          [
            testForm[0].poreDiameter2.toFixed(1),
            testForm[0].poreDiameter2Percentage.toFixed(1),
            testForm[0].sievingPoreDiameter2Percentage.toFixed(1),
          ],
          [
            testForm[1].poreDiameter2.toFixed(1),
            testForm[1].poreDiameter2Percentage.toFixed(1),
            testForm[1].sievingPoreDiameter2Percentage.toFixed(1),
          ],
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? [
                testForm[0].verifyPassedResults?.type1['25.0mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type1['25.0mm'] ? styles.textB : styles.abnormal,
              ]
            : [
                testForm[0].verifyPassedResults?.type2['25.0mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type2['25.0mm'] ? styles.textB : styles.abnormal,
              ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.19000um`),

        values: [
          [
            testForm[0].poreDiameter3.toFixed(1),
            testForm[0].poreDiameter3Percentage.toFixed(1),
            testForm[0].sievingPoreDiameter3Percentage.toFixed(1),
          ],
          [
            testForm[1].poreDiameter3.toFixed(1),
            testForm[1].poreDiameter3Percentage.toFixed(1),
            testForm[1].sievingPoreDiameter3Percentage.toFixed(1),
          ],
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? [
                testForm[0].verifyPassedResults?.type1['19.0mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type1['19.0mm'] ? styles.textB : styles.abnormal,
              ]
            : [
                testForm[0].verifyPassedResults?.type2['19.0mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type2['19.0mm'] ? styles.textB : styles.abnormal,
              ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.12500um`),

        values: [
          [
            testForm[0].poreDiameter4.toFixed(1),
            testForm[0].poreDiameter4Percentage.toFixed(1),
            testForm[0].sievingPoreDiameter4Percentage.toFixed(1),
          ],
          [
            testForm[1].poreDiameter4.toFixed(1),
            testForm[1].poreDiameter4Percentage.toFixed(1),
            testForm[1].sievingPoreDiameter4Percentage.toFixed(1),
          ],
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? [
                testForm[0].verifyPassedResults?.type1['12.5mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type1['12.5mm'] ? styles.textB : styles.abnormal,
              ]
            : [
                testForm[0].verifyPassedResults?.type2['12.5mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type2['12.5mm'] ? styles.textB : styles.abnormal,
              ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.9500um`),

        values: [
          [
            testForm[0].poreDiameter5.toFixed(1),
            testForm[0].poreDiameter5Percentage.toFixed(1),
            testForm[0].sievingPoreDiameter5Percentage.toFixed(1),
          ],
          [
            testForm[1].poreDiameter5.toFixed(1),
            testForm[1].poreDiameter5Percentage.toFixed(1),
            testForm[1].sievingPoreDiameter5Percentage.toFixed(1),
          ],
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? [
                testForm[0].verifyPassedResults?.type1['9.5mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type1['9.5mm'] ? styles.textB : styles.abnormal,
              ]
            : [
                testForm[0].verifyPassedResults?.type2['9.5mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type2['9.5mm'] ? styles.textB : styles.abnormal,
              ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.4750um`),

        values: [
          [
            testForm[0].poreDiameter6.toFixed(1),
            testForm[0].poreDiameter6Percentage.toFixed(1),
            testForm[0].sievingPoreDiameter6Percentage.toFixed(1),
          ],
          [
            testForm[1].poreDiameter6.toFixed(1),
            testForm[1].poreDiameter6Percentage.toFixed(1),
            testForm[1].sievingPoreDiameter6Percentage.toFixed(1),
          ],
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? [
                testForm[0].verifyPassedResults?.type1['4.75mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type1['4.75mm'] ? styles.textB : styles.abnormal,
              ]
            : [
                testForm[0].verifyPassedResults?.type2['4.75mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type2['4.75mm'] ? styles.textB : styles.abnormal,
              ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.2360um`),

        values: [
          [
            testForm[0].poreDiameter7.toFixed(1),
            testForm[0].poreDiameter7Percentage.toFixed(1),
            testForm[0].sievingPoreDiameter7Percentage.toFixed(1),
          ],
          [
            testForm[1].poreDiameter7.toFixed(1),
            testForm[1].poreDiameter7Percentage.toFixed(1),
            testForm[1].sievingPoreDiameter7Percentage.toFixed(1),
          ],
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? [
                testForm[0].verifyPassedResults?.type1['2.36mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type1['2.36mm'] ? styles.textB : styles.abnormal,
              ]
            : [
                testForm[0].verifyPassedResults?.type2['2.36mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type2['2.36mm'] ? styles.textB : styles.abnormal,
              ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.75um`),

        values: [
          [
            testForm[0].poreDiameter8.toFixed(1),
            testForm[0].poreDiameter8Percentage.toFixed(1),
            testForm[0].sievingPoreDiameter8Percentage.toFixed(1),
          ],
          [
            testForm[1].poreDiameter8.toFixed(1),
            testForm[1].poreDiameter8Percentage.toFixed(1),
            testForm[1].sievingPoreDiameter8Percentage.toFixed(1),
          ],
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? [
                testForm[0].verifyPassedResults?.type1['0.075mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type1['0.075mm'] ? styles.textB : styles.abnormal,
              ]
            : [
                testForm[0].verifyPassedResults?.type2['0.075mm'] ? styles.textA : styles.abnormal,
                testForm[1].verifyPassedResults?.type2['0.075mm'] ? styles.textB : styles.abnormal,
              ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.UNDER_PAN`),

        values: [
          [
            testForm[0].underPan.toFixed(1),
            (100 - testForm[0].sievingUnderPanPercentage).toFixed(1),
            testForm[0].sievingUnderPanPercentage.toFixed(1),
          ],
          [
            testForm[1].underPan.toFixed(1),
            (100 - testForm[1].sievingUnderPanPercentage).toFixed(1),
            testForm[1].sievingUnderPanPercentage.toFixed(1),
          ],
        ],
        valuesTextStyle: [styles.textA, styles.textB],
      },
    ],
    [i18T, testForm, type],
  );

  const secondHeader = useMemo(
    () => ({
      values: [
        `${i18T(`TEST.SIEVE_ANALYSIS.RATE_OF_WEIGHT_LOSS`)}(%)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.DIRT_CONTENT`)}(%)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.WET_WEIGHT`)}(g)`,
      ],
    }),
    [i18T],
  );
  const secondData = useMemo(
    () => ({
      infoS1: [
        testForm[0].lossPercentage.toFixed(1),
        testForm[0].dirtContentRatio.toFixed(1),
        testForm[0].wetWeight.toFixed(1),
      ],

      infoS2: [
        testForm[1].lossPercentage.toFixed(1),
        testForm[1].dirtContentRatio.toFixed(1),
        testForm[1].wetWeight.toFixed(1),
      ],
    }),
    [testForm],
  );

  const thirdHeader = useMemo(
    () => [
      `${i18T(`TEST.SIEVE_ANALYSIS.BEFORE_RINSE_DRY_WEIGHT`)}(g)`,
      `${i18T(`TEST.SIEVE_ANALYSIS.AFTER_RINSE_DRY_WEIGHT`)}(g)`,
      `${i18T(`TEST.SIEVE_ANALYSIS.WATER_WEIGHT`)}(g)`,
    ],
    [i18T],
  );
  const thirdData = useMemo(
    () => ({
      infoS1: [
        testForm[0].beforeRinseDryWeight.toFixed(1),
        testForm[0].afterRinseDryWeight.toFixed(1),
        testForm[0].waterWeight.toFixed(1),
      ],

      infoS2: [
        testForm[1].beforeRinseDryWeight.toFixed(1),
        testForm[1].afterRinseDryWeight.toFixed(1),
        testForm[1].waterWeight.toFixed(1),
      ],
    }),
    [testForm],
  );

  const fourthHeader = useMemo(
    () => [
      `${i18T(`TEST.SIEVE_ANALYSIS.WATER_CONTENT`)}(%)`,
      `${i18T(`TEST.SIEVE_ANALYSIS.SURFACE_WATER_CONTENT`)}(%)`,
    ],
    [i18T],
  );
  const fourthData = useMemo(
    () => ({
      infoS1: [testForm[0].waterContent.toFixed(1), testForm[0].surfaceWaterContent.toFixed(1)],

      infoS2: [testForm[1].waterContent.toFixed(1), testForm[1].surfaceWaterContent.toFixed(1)],
    }),
    [testForm],
  );

  const fifthHeader = useMemo(
    () => ({
      values: [i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`), `${i18T(`TEST.SIEVE_ANALYSIS.MIX_PERCENTAGE`)}(%)`],
    }),
    [i18T],
  );
  const fifthData = useMemo(
    () => [
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.37500um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage1.toFixed(1)],
        valueTextStyle: [verifyResult?.get('37.5mm') ? styles.normal : styles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.25000um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage2.toFixed(1)],
        valueTextStyle: [verifyResult?.get('25.0mm') ? styles.normal : styles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.19000um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage3.toFixed(1)],
        valueTextStyle: [verifyResult?.get('19.0mm') ? styles.normal : styles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.12500um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage4.toFixed(1)],
        valueTextStyle: [verifyResult?.get('12.5mm') ? styles.normal : styles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.9500um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage5.toFixed(1)],
        valueTextStyle: [verifyResult?.get('9.5mm') ? styles.normal : styles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.4750um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage6.toFixed(1)],
        valueTextStyle: [verifyResult?.get('4.75mm') ? styles.normal : styles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.2360um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage7.toFixed(1)],
        valueTextStyle: [verifyResult?.get('2.36mm') ? styles.normal : styles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.75um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage8.toFixed(1)],
        valueTextStyle: [verifyResult?.get('0.075mm') ? styles.normal : styles.abnormal],
      },
    ],
    [i18T, calcTestFormMix, verifyResult],
  );

  const sixthHeader = useMemo(
    () => ({
      values: [i18T(`TEST.SIEVE_ANALYSIS.COARSE.MIX_LOSS_RATIO`), i18T(`TEST.SIEVE_ANALYSIS.COARSE.MIX_DIRT_CONTENT`)],
    }),
    [i18T],
  );
  const sixthData = useMemo(
    () => [
      {
        values: [calcTestFormMix.lossPercentage.toFixed(3), calcTestFormMix.dirtContent.toFixed(1)],
        valueTextStyle: [
          verifyResult?.get('lossPercentage') ? styles.normal : styles.abnormal,
          verifyResult?.get('dirtContentRatio') ? styles.normal : styles.abnormal,
        ],
      },
    ],
    [calcTestFormMix, verifyResult],
  );

  const seventhHeader = useMemo(
    () => ({
      values: [i18T(`TEST.SIEVE_ANALYSIS.MIX_SURFACE_WATER_CONTENT`), i18T(`TEST.SIEVE_ANALYSIS.MIX_WATER_CONTENT`)],
    }),
    [i18T],
  );
  const seventhData = useMemo(
    () => [
      {
        values: [calcTestFormMix.surfaceWaterContent.toFixed(2), calcTestFormMix.waterContent.toFixed(2)],
      },
    ],
    [calcTestFormMix],
  );

  const showTableHeader = useCallback(
    () => (
      <Stack sx={styles.header}>
        <Typography style={{ ...styles.textA, fontSize: '24px', fontWeight: 'bold' }}>
          {i18T(`TEST.SIEVE_ANALYSIS.S1_COARSE`)}: {(ratio * 100).toFixed(0)}%
        </Typography>
        <Typography style={{ ...styles.textB, fontSize: '24px', fontWeight: 'bold' }}>
          {i18T(`TEST.SIEVE_ANALYSIS.S2_COARSE`)}: {((1 - ratio) * 100).toFixed(0)}%
        </Typography>
      </Stack>
    ),
    [i18T, ratio],
  );

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_MIX_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandardType1(standardMap);
      },
      () => undefined,
    );
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_MIX_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 2,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandardType2(standardMap);
      },
      () => undefined,
    );

    CustomStandardV2Api.getCustomStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_MIX_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: CustomStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setCustomStandardType1(standardMap);
      },
      () => undefined,
    );
    CustomStandardV2Api.getCustomStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_MIX_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 2,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: CustomStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setCustomStandardType2(standardMap);
      },
      () => undefined,
    );
  }, []);

  return (
    <>
      <TestCardContainer>
        <SectionCollapse header={showTableHeader()}>
          <TestTable headerData={firstHeader} rowsMultiple={firstData} />
        </SectionCollapse>
      </TestCardContainer>
      <TestCardContainer>
        <SectionCollapse header={showTableHeader()}>
          <TestTable
            headerData={secondHeader}
            rowsMultiple={[
              {
                values: [secondData.infoS1, secondData.infoS2],
                valuesTextStyle: [styles.textA, styles.textB],
                valueTextStyle:
                  type === CoarseSieveLegalTypeEnum.TYPE1
                    ? [
                        [
                          !testForm[0].verifyPassedResults?.type1.lossPercentage ? styles.abnormal : {},
                          !testForm[0].verifyPassedResults?.type1.dirtContentRatio ? styles.abnormal : {},
                        ],
                        [
                          !testForm[1].verifyPassedResults?.type1.lossPercentage ? styles.abnormal : {},
                          !testForm[1].verifyPassedResults?.type1.dirtContentRatio ? styles.abnormal : {},
                        ],
                      ]
                    : [
                        [
                          !testForm[0].verifyPassedResults?.type2.lossPercentage ? styles.abnormal : {},
                          !testForm[0].verifyPassedResults?.type2.dirtContentRatio ? styles.abnormal : {},
                        ],
                        [
                          !testForm[1].verifyPassedResults?.type2.lossPercentage ? styles.abnormal : {},
                          !testForm[1].verifyPassedResults?.type2.dirtContentRatio ? styles.abnormal : {},
                        ],
                      ],
              },
              { values: [thirdHeader], valuesTextStyle: [styles.textA] },
              {
                values: [thirdData.infoS1, thirdData.infoS2],
                valuesTextStyle: [styles.textA, styles.textB],
              },
              { values: [fourthHeader], valuesTextStyle: [styles.textA] },
              {
                values: [fourthData.infoS1, fourthData.infoS2],
                valuesTextStyle: [styles.textA, styles.textB],
              },
            ]}
          />
        </SectionCollapse>
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={{
            values: [`${i18T(`TEST.BASE.DETECTION`)}(${i18T(`TASK_MANAGE.VIEW.RECORD_ORDER`)})`],
          }}
          rows={[
            isRecordLegalPassed && isRecordCustomPassed
              ? {
                  titles: recordVerifyResultText,
                  titlesStyle: [styles.normal],
                }
              : {
                  titles: recordVerifyResultText,
                  titlesStyle: [
                    isRecordLegalPassed ? styles.normal : styles.abnormal,
                    isRecordCustomPassed ? styles.normal : styles.abnormal,
                  ],
                },
          ]}
          isShowCollapse={true}
        />
      </TestCardContainer>
      <TestCardContainer>
        <TestTable headerData={fifthHeader} rows={fifthData} isShowCollapse={true} />
      </TestCardContainer>
      <TestCardContainer>
        <TestTable headerData={sixthHeader} rows={sixthData} isShowCollapse={true} />
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={{
            values: [`${i18T(`TEST.BASE.DETECTION`)}(${i18T(`TASK_MANAGE.VIEW.REPORT`)})`],
          }}
          rows={[
            isLegalPassed && isCustomPassed && !isCustomRejected
              ? {
                  titles: verifyResultText,
                  titlesStyle: [TestVerifyResultStyles.normal],
                }
              : {
                  titles: verifyResultText,
                  titlesStyle: [
                    isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                    isCustomPassed && !isCustomRejected
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal,
                  ],
                },
          ]}
          isShowCollapse={true}
        />
      </TestCardContainer>
    </>
  );
};

export default memo(CoarseSieveReportTable);

const styles = {
  textA: {
    color: 'rgba(90, 96, 174, 1)',
  },
  textB: {
    color: 'rgba(129, 178, 154, 1)',
  },
  abnormal: {
    color: '#FF0002',
    fontWeight: '900',
  },
  normal: {
    color: '#70C030',
    fontWeight: '900',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    py: 2,
  },
};
