import React from 'react';
import { EntityData } from '@Src/_basic/object/EntityType';


interface Props {
  row: EntityData;
}

export default function FactoryColumnFormatter(props: Props) {
  const { row } = props;

  return <span>{row.station?.factory_name}</span>;

}
