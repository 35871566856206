import YatungButton from '@Src/_basic/components/YatungButton';
import { KeyManagementData } from '@Src/_basic/object/KeyManagementType';
import { Stack } from '@mui/material';
import React from 'react';

export type ExtraData = {
  onLogout: (id: number) => void;
  onGoToNoteModal: (id: number, description: string) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: KeyManagementData;
  formatExtraData: ExtraData;
}

export default function AreaColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <Stack spacing={2} direction="row">
      <YatungButton
        disabled={row.isCancle || row.isExpired}
        text={formatExtraData.getButtonText('註銷')}
        variant={'contained'}
        color="red"
        type={'button'}
        size={'large'}
        onClick={() => {
          formatExtraData.onLogout(row.id);
        }}
      />
      <YatungButton
        text={formatExtraData.getButtonText('備註')}
        variant={'contained'}
        color="blue"
        type={'button'}
        size={'large'}
        onClick={() => {
          formatExtraData.onGoToNoteModal(row.id, row.description);
        }}
      />
    </Stack>
  );
}
