import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungInput from '@Src/_basic/components/YatungInput';
import EyeCloseIcon from '@Src/_basic/icons/EyeClose';
import { StationsDataById } from '@Src/_basic/object/SiteType';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  factoryBranchCode: string;
  data: StationsDataById;
  setUpdateQualityControlTypeId: (e: StationsDataById[]) => void;
  updateQualityControlTypeId: StationsDataById[];
}
export default function UpdateQualityControlForm(props: Props) {
  const { data, setUpdateQualityControlTypeId, updateQualityControlTypeId, factoryBranchCode } = props;

  const { t: i18T } = useTranslation();

  const handleInput = (e: SelectChangeEvent) => {
    const updatedArray = updateQualityControlTypeId.map((datas: StationsDataById, i: number) => {
      if (datas.id === data.id) {
        return { ...datas, stationName: e.target.value };
      }
      return datas;
    });
    setUpdateQualityControlTypeId(updatedArray);
  };
  const handlePwInput = (e: SelectChangeEvent) => {
    const updatedArray = updateQualityControlTypeId.map((datas: StationsDataById, i: number) => {
      if (datas.id === data.id) {
        return { ...datas, password: e.target.value };
      }
      return datas;
    });
    setUpdateQualityControlTypeId(updatedArray);
  };
  const handlePasswordEdit = () => {
    if (data.passwordEdit) {
      const updatedArray = updateQualityControlTypeId.map((datas: StationsDataById, i: number) => {
        if (datas.id === data.id) {
          return { ...datas, passwordEdit: false };
        }
        return datas;
      });

      setUpdateQualityControlTypeId(updatedArray);
    } else {
      const updatedArray = updateQualityControlTypeId.map((datas: StationsDataById, i: number) => {
        if (datas.id === data.id) {
          return { ...datas, passwordEdit: true };
        }
        return datas;
      });

      setUpdateQualityControlTypeId(updatedArray);
    }
  };

  const handleInputType = () => {
    if (data.inputType === 'password') {
      const updatedArray = updateQualityControlTypeId.map((datas: StationsDataById, i: number) => {
        if (datas.id === data.id) {
          return { ...datas, inputType: 'text' };
        }
        return datas;
      });
      setUpdateQualityControlTypeId(updatedArray);
    } else {
      const updatedArray = updateQualityControlTypeId.map((datas: StationsDataById, i: number) => {
        if (datas.id === data.id) {
          return { ...datas, inputType: 'password' };
        }
        return datas;
      });
      setUpdateQualityControlTypeId(updatedArray);
    }
  };
  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.SITES')} />
        </Grid>
        <Grid container item xs={11}>
          <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>
            {i18T('SITEMANAGEMENT.QUALITYCONTROL')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.STATION_NAME')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungInput sx={{ width: '930px' }} defaultValue={data.stationName} onBlur={handleInput} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.SITE_TYPE')} />
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{data.stationType}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.SITE_NUMBER')} />
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{data.stationCode}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.ACCOUNT')} />
        </Grid>
        <Grid container item xs={11}>
          <Typography
            sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}
          >{`${factoryBranchCode}_${data.stationType}_${data.stationCode}`}</Typography>
        </Grid>
      </Grid>
      {data.edit ? (
        <Grid container alignItems={'center'} item xs={12} sx={{ my: 2 }}>
          <Grid container item xs={1}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.PASSWORD')} />
          </Grid>
          {data.passwordEdit ? (
            <Grid container item xs={11}>
              <YatungInput
                isIconButton
                type={data.inputType ? data.inputType : 'password'}
                handleInputType={handleInputType}
                position="end"
                icon={<EyeCloseIcon />}
                defaultValue={data.password}
                onBlur={handlePwInput}
              />
              <YatungButton width={110} sx={{ ml: 1 }} color="blue" text="取消編輯" onClick={handlePasswordEdit} />
            </Grid>
          ) : (
            <YatungButton color="blue" text="編輯密碼" onClick={handlePasswordEdit} />
          )}
        </Grid>
      ) : (
        <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.PASSWORD')} />
          <YatungInput
            isIconButton
            type={data.inputType ? data.inputType : 'password'}
            handleInputType={handleInputType}
            position="end"
            icon={<EyeCloseIcon />}
            defaultValue={data.password}
            onBlur={handlePwInput}
          />
        </Grid>
      )}
      <YatungDivider />
    </>
  );
}
