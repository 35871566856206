import { Protocol } from '../Protocol';

export class MaterialReceiptsProtocol {
  public static readonly SEARCH_MATERIAL_RECEIPTS: string = `${Protocol.YATUNG_API_ROOT}/receipt/material/search`;
  public static readonly GET_ALL_MATERIAL_RECEIPTS_EXCEL: string = `${Protocol.YATUNG_API_ROOT}/export/excel/materialReceipt/all`;
  public static readonly RECEIPT_MATERIAL: string = `${Protocol.YATUNG_API_ROOT}/receipt/material`;
  public static readonly RECEIPT_MATERIAL_OPTS: string = `${Protocol.YATUNG_API_ROOT}/receipt/material/opts`;
  public static readonly RECEIPT_MATERIAL_QRCODE: string = `${Protocol.YATUNG_API_ROOT}/receipt/material/qrcode`;
  public static readonly GET_RECEIPT_MATERIAL_OPTIONS: string = `${Protocol.YATUNG_API_ROOT}/receipt/material/option/all`;
  public static readonly POST_RECEIPT_MATERIAL: string = `${Protocol.YATUNG_API_ROOT}/receipt/material/additional`;
}
