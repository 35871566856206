import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { MaterialInspectionDetialByData } from '@Src/_basic/object/MaterialInspectionType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import CompletedColumnFormatter from './columnFormatter/CompletedColumnFormatter';
import CreateTimeColumnFormatter from './columnFormatter/CreateTimeColumnFormatter';
import ExptItemCodeColumnFormatter from './columnFormatter/ExptItemCodeColumnFormatter';
import OperatorNameColumnFormatter from './columnFormatter/OperatorNameColumnFormatter';

interface Props {
  loading: boolean;
  materialInspectionDetailData: Array<MaterialInspectionDetialByData>;
}
export default function TestInDistrictByDetailTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { loading, materialInspectionDetailData } = props;

  const getButtonText = (status: string) => {
    switch (status) {
      case '詳細':
        return i18T('GLOBAL.DETAIL');
      case '已完成':
        return i18T('GLOBAL.COMPLETE');
      case '異常':
        return i18T('GLOBAL.ABNORMAL');
    }
  };
  const getTestName = (item: string) => {
    return i18T(`TEST.TEST_ITEM.${item}`);
  };
  const columns: Array<Column> = [
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.TEST_NAME'),
      dataField: 'exptItemCode',
      formatter: ExptItemCodeColumnFormatter,
      formatExtraData: {
        getTestName: getTestName,
      },
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.CREATER_NAME'),
      dataField: 'operatorName',
      formatter: OperatorNameColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.CREATE_TIME'),
      dataField: 'createTime',
      formatter: CreateTimeColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.STATUS'),
      dataField: 'completed',
      formatter: CompletedColumnFormatter,
      formatExtraData: {
        getButtonText: getButtonText,
      },
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        // onGoToUpdatePage: handleGoToUpdatePage,
        getButtonText: getButtonText,
      },
    },
  ];
  return <YatungTable columns={columns} data={materialInspectionDetailData} loading={loading} />;
}
