import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema, notRequiredNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  FlyAshWaterDemand,
  ImportFlyAshWaterDemand,
  PutFlyAshWaterDemandRequest,
} from '@Src/_basic/object/test/cementtitiousPowder/FlyAshWaterDemandType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: FlyAshWaterDemand;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  controlWaterDemand: basicNumberSchema,
  waterDemand: notRequiredNumberSchema,
});

function ExportFlyAshWaterDemandTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      controlWaterDemand: testDetail.controlWaterDemand,
      waterDemand: testDetail.waterDemand,
    }),
    [testDetail],
  );

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.controlWaterDemand?.toFixed(4)),
        values: [formatNumber(testDetail?.waterDemand?.toFixed(4))],
      },
    ],
    [testDetail?.controlWaterDemand, testDetail?.waterDemand],
  );

  const secondInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.waterDemandRatio?.toFixed(4)),
        titleStyle: {
          ...(testDetail?.verifyPassedResults?.waterDemandRatio &&
          testDetail?.verifyCustomResults?.waterDemandRatio?.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal),
        },
      },
    ],
    [
      testDetail?.waterDemandRatio,
      testDetail?.verifyPassedResults?.waterDemandRatio,
      testDetail?.verifyCustomResults?.waterDemandRatio?.passed,
    ],
  );

  const thirdInfo = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
            titles: verifyResultText,
            titlesStyle: [TestVerifyResultStyles.normal],
          }
        : {
            titles: verifyResultText,
            titlesStyle: [
              isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
              isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            ],
          },
    ],
    [isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.FLY_ASH_WATER_DEMAND.CONTROL_WATER_DEMAND`)}(ml)`,
      values: [`${i18T(`TEST.FLY_ASH_WATER_DEMAND.COMPARISON_WATER_DEMAND`)}(ml)`],
    }),
    [i18T],
  );

  const secondHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.FLY_ASH_WATER_DEMAND.WATER_DEMAND_RATE`)}(%)`,
    }),
    [i18T],
  );

  const thirdHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportFlyAshWaterDemand = {
        controlWaterDemand: checkIsNaN(+values.controlWaterDemand),
        waterDemand: checkIsNaN(+values.waterDemand),
      };

      TestApi.putTest<PutFlyAshWaterDemandRequest>(
        {
          exptItemCode: testDetail.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const firstView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack flexDirection="row" gap={5}>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.FLY_ASH_WATER_DEMAND.CONTROL_WATER_DEMAND`)}(ml)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.controlWaterDemand}
                isError={!!(formik.touched.controlWaterDemand && formik.errors.controlWaterDemand)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('controlWaterDemand', e.target.value)
                }
              />
            </ColFieldInfo>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.FLY_ASH_WATER_DEMAND.COMPARISON_WATER_DEMAND`)}(ml)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.waterDemand}
                isError={!!(formik.touched.waterDemand && formik.errors.waterDemand)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('waterDemand', e.target.value)}
              />
            </ColFieldInfo>
          </Stack>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstInfo} />;
      default:
        break;
    }
  }, [edit, formik, firstHeader, firstInfo, i18T]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {firstView}
        {!edit && <TestTable headerData={secondHeader} rows={secondInfo} />}
      </TestCardContainer>
      <TestCardContainer>
        <TestTable headerData={thirdHeader} rows={thirdInfo} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportFlyAshWaterDemandTable);
