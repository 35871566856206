export function getSpecificationExtraDataFieldNameByExperimentResultTypeId(ExperimentResultTypeId: number) {
  const specificationIds: Array<number> = [
    //爐石
    13,
    //混凝土
    17, 18, 19,
  ];
  const sourceIds: Array<number> = [];

  if (specificationIds.includes(ExperimentResultTypeId)) {
    return 'specification';
  } else if (sourceIds.includes(ExperimentResultTypeId)) {
    return 'source';
  } else {
    return 'default';
  }
}

export function getTypeAmountByExperimentItemId(experimentItemId: number) {
  switch (experimentItemId) {
    case 3:
      return 2;
    default:
      return 1;
  }
}
