import { UserApiPermissionHelpers } from '@Src/_basic/helpers/UserApiPermissionHelpers';
import { SampleSourceData } from '@Src/_basic/object/SampleSourceType';
import { SourceData } from '@Src/_basic/object/SourceType';
import { SpecificationData } from '@Src/_basic/object/SpecificationType';
import { ChemType } from '@Src/_basic/object/SpecimenType';
import { StorageData } from '@Src/_basic/object/StorageType';
import { SupplierData } from '@Src/_basic/object/SupplierType';
import { TestTypeData } from '@Src/_basic/object/TestTypeType';
import { SampleSourceApi } from '@Src/_basic/protocol/sampleSource/SampleSourceApi';
import { SourceApi } from '@Src/_basic/protocol/source/SourceApi';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { StorageApi } from '@Src/_basic/protocol/storage/StorageApi';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { TestTypeApi } from '@Src/_basic/protocol/testType/TestTypeApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { Box, Stack, Typography, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Clock from '../../../icons/Clock';
import Clock2 from '../../../icons/Clock2';
import Expt from '../../../icons/Expt';
import Location from '../../../icons/Location';
import Paper from '../../../icons/Paper';
import Source from '../../../icons/Source';
import Storage from '../../../icons/Storage';
import ExptScheduleCard from '../../ExptScheduleCard';
import ExptScheduleDateTimePicker from '../../ExptScheduleDateTimePicker';
import ExptScheduleInput from '../../ExptScheduleInput';
import ExptScheduleSelect, { Options } from '../../ExptScheduleSelect';

interface Props {
  formik: any;
}

const Title = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '24px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '1.35em',
}));

export default function RequirementCard({ formik }: Props) {
  // TODO using i18n
  const { userApiPermission } = useAuth();

  const [testType, setTestType] = useState<Array<TestTypeData>>();
  const [testTypeOptions, setTestTypeOptions] = useState<Array<Options>>([]);
  const [sampleSourceOptions, setSampleSourceOptions] = useState<Array<Options>>([]);
  const [gTypeSpecificationOptions, setGTypeSpecificationOptions] = useState<Array<Options>>([]);
  const [fluidifySpecificationOptions, setFluidifySpecificationOptions] = useState<Array<Options>>([]);
  const [supplierOptions, setSupplierOptions] = useState<Array<Options>>([]);
  const [specificationOptions, setSpecificationOptions] = useState<Array<Options>>([]);
  const [sourceOptions, setSourceOptions] = useState<Array<Options>>([]);
  const [storageOptions, setStorageOptions] = useState<Array<Options>>([]);

  const isDisabledDateRangePicker: boolean = useMemo(() => {
    return Boolean(formik.values.period.periodType);
  }, [formik.values.period.periodType]);

  const getTestTypeOptions = useCallback(() => {
    TestTypeApi.getAllTestType((data: Array<TestTypeData>) => {
      setTestType(data);
      setTestTypeOptions(data.map((item) => ({ text: item.typeName, value: item.id })));
    });
  }, []);

  const getSampleSourceOptions = useCallback(() => {
    SampleSourceApi.getSampleSourceBySearch({ factoryId: formik.values.factoryId }, (data: Array<SampleSourceData>) => {
      setSampleSourceOptions(
        data.filter((item) => item.isAnalysis).map((item) => ({ text: item.name, value: item.id })),
      );
    });
  }, [formik.values.factoryId]);

  const getStorageOptions = useCallback(() => {
    StorageApi.getStoragesBySearch({ factoryId: formik.values.factoryId }, (data: Array<StorageData>) => {
      setStorageOptions(data.map((item) => ({ text: `${item?.group} ${item?.name}`, value: item.id })));
    });
  }, [formik.values.factoryId]);

  const getSupplierOptions = useCallback(() => {
    SupplierApi.getSuppliersBySearch({ factoryId: formik.values.factoryId }, (data: Array<SupplierData>) => {
      setSupplierOptions(data.map((item) => ({ text: item.name, value: item.id })));
    });
  }, [formik.values.factoryId]);

  const getGTypeSpecificationOptions = useCallback(() => {
    SpecificationApi.getChemTypeSpecificationBySearch(
      { factoryId: formik.values.factoryId, attrTypeCode: ChemType.G_TYPE },
      (data: Array<SpecificationData>) => {
        setGTypeSpecificationOptions(data.map((item) => ({ text: item.name, value: item.id })));
      },
    );
  }, [formik.values.factoryId]);

  const getFluidifySpecificationOptions = useCallback(() => {
    SpecificationApi.getChemTypeSpecificationBySearch(
      { factoryId: formik.values.factoryId, attrTypeCode: ChemType.FLUIDIFIER },
      (data: Array<SpecificationData>) => {
        setFluidifySpecificationOptions(data.map((item) => ({ text: item.name, value: item.id })));
      },
    );
  }, [formik.values.factoryId]);

  const getSpecificationOptions = useCallback(() => {
    if (testType && testType.length) {
      const materialIds = testType
        .find((value) => value.id === formik.values.exptTypeId)
        ?.types.reduce((prev, current) => {
          prev.push(current.id);
          return prev;
        }, [] as Array<number>);

      SpecificationApi.getSpecificationsBySearch(
        { factoryId: formik.values.factoryId, typeId: materialIds },
        (data: Array<SpecificationData>) => {
          setSpecificationOptions(data.map((item) => ({ text: item.name, value: item.id })));
        },
      );
    }
  }, [testType, formik.values.factoryId, formik.values.exptTypeId]);

  const getSourceOptions = useCallback(() => {
    SourceApi.getSourcesBySearch(
      { factoryId: formik.values.factoryId, typeId: formik.values.exptTypeId },
      (data: Array<SourceData>) => {
        setSourceOptions(data.map((item) => ({ text: item.sourceName, value: item.id })));
      },
    );
  }, [formik.values.factoryId, formik.values.exptTypeId]);

  const header = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', direction: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }} gap="12px">
          <Paper />
          <Title>
            {UserApiPermissionHelpers('WD063', userApiPermission)
              ? formik.values.isRequirement === 'true'
                ? '需求單'
                : '試驗單'
              : formik.values.isRequirement === 'false'
              ? '需求單'
              : '試驗單'}
          </Title>
        </Box>
      </Box>
    );
  }, [formik.values.isRequirement]);

  const renderDateRangePicker = useMemo(() => {
    if (isDisabledDateRangePicker) {
      return (
        <>
          <ExptScheduleInput title="開始時間：" icon={<Clock />} placeholder={'請至排程設定'} disabled />
          <ExptScheduleInput title="結束時間：" icon={<Clock2 />} placeholder={'請至排程設定'} disabled />
        </>
      );
    } else {
      return (
        <>
          <ExptScheduleDateTimePicker
            disabled={isDisabledDateRangePicker}
            title="開始時間："
            icon={<Clock />}
            maxDate={formik?.values?.endTime}
            value={formik?.values?.startTime}
            onChange={(value) => {
              formik?.setFieldValue('startTime', value);
            }}
          />
          <ExptScheduleDateTimePicker
            disabled={isDisabledDateRangePicker}
            title="結束時間："
            icon={<Clock2 />}
            minDate={formik?.values?.startTime}
            value={formik?.values?.endTime}
            onChange={(value) => {
              formik.setFieldValue('endTime', value);
            }}
          />
        </>
      );
    }
  }, [isDisabledDateRangePicker, formik.values.startTime, formik.values.endTime]);

  const renderExptTypeSelect = useMemo(() => {
    return (
      <ExptScheduleSelect
        title="試驗類型："
        icon={<Expt />}
        options={testTypeOptions}
        value={formik.values.exptTypeId}
        onChange={(e) => {
          formik.setFieldValue('exptTypeId', e.target.value);
        }}
      />
    );
  }, [testTypeOptions, formik.values.exptTypeId]);

  const renderSpecificationSelect = useMemo(() => {
    if (formik.values.exptTypeId > 0) {
      return (
        <ExptScheduleSelect
          title="規格："
          icon={<Expt />}
          options={specificationOptions}
          value={formik.values.specificationId}
          onChange={(e) => {
            formik.setFieldValue('specificationId', e.target.value);
          }}
        />
      );
    }
  }, [formik.values.exptTypeId, specificationOptions, formik.values.specificationId]);

  const renderSourceSelect = useMemo(() => {
    if (formik.values.specificationId > 0) {
      return (
        <ExptScheduleSelect
          title="來源："
          icon={<Source />}
          options={sourceOptions}
          value={formik.values.sourceId}
          onChange={(e) => {
            formik.setFieldValue('sourceId', e.target.value);
          }}
        />
      );
    }
  }, [formik.values.specificationId, sourceOptions, formik.values.sourceId]);

  const renderSampleSelect = useMemo(() => {
    if (formik.values.specificationId) {
      return (
        <ExptScheduleSelect
          title="取樣位置："
          icon={<Location />}
          options={sampleSourceOptions}
          value={formik.values.sampleSourceId}
          onChange={(e) => {
            formik.setFieldValue('sampleSourceId', e.target.value);
          }}
        />
      );
    }
  }, [formik.values.specificationId, sampleSourceOptions, formik.values.sampleSourceId]);

  const renderStorageSelect = useMemo(() => {
    if (formik.values.specificationId) {
      return (
        <ExptScheduleSelect
          title="料庫："
          icon={<Storage />}
          options={storageOptions}
          value={formik.values.storageId}
          onChange={(e) => {
            formik.setFieldValue('storageId', e.target.value);
          }}
        />
      );
    }
  }, [formik.values.specificationId, storageOptions, formik.values.storageId]);

  useEffect(() => {
    getTestTypeOptions();
  }, [getTestTypeOptions]);

  useEffect(() => {
    getSampleSourceOptions();
    getStorageOptions();
    getSupplierOptions();
    getGTypeSpecificationOptions();
    getFluidifySpecificationOptions();
  }, [
    getSampleSourceOptions,
    getStorageOptions,
    getSupplierOptions,
    getGTypeSpecificationOptions,
    getFluidifySpecificationOptions,
  ]);

  useEffect(() => {
    getSourceOptions();
    getSpecificationOptions();
  }, [getSourceOptions, getSpecificationOptions]);

  return (
    <ExptScheduleCard
      header={header}
      body={
        <Box sx={{ padding: '24px 41px' }}>
          <Stack gap="20px">
            {UserApiPermissionHelpers('WD063', userApiPermission)
              ? formik.values.isRequirement === 'true' && renderDateRangePicker
              : formik.values.isRequirement === 'false' && renderDateRangePicker}
            {renderExptTypeSelect}
            {renderSpecificationSelect}
            {renderSourceSelect}
            {renderSampleSelect}
            {renderStorageSelect}
          </Stack>
        </Box>
      }
    />
  );
}
