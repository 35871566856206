import YatungButton from '@Src/_basic/components/YatungButton';
import { Options, YatungComboBox } from '@Src/_basic/components/YatungComboBox';
import YatungInput from '@Src/_basic/components/YatungInput';
import { ProductionHistoryContext } from '@Src/_basic/contexts/ProductionHistory';
import Cancel from '@Src/_basic/icons/CancelV2';
import Confirm from '@Src/_basic/icons/Confirm';
import { CreateProportionRealPropsRequest } from '@Src/_basic/object/ProductionHistoryType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Typography, styled } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useElementSize } from 'usehooks-ts';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '16px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '19px',
  letterSpacing: '0.255em',
}));

interface Props {
  displayName: string;
  designedStrength: number;
  carbonEmissionFactor: number;
  carbonEmissionLifeCycleFactor: number;
  realProps: Array<CreateProportionRealPropsRequest>;
  onDisplayNameChange: (value: string) => void;
  onDesignedStrengthChange: (value: number) => void;
  onCarbonEmissionChange: (value: number) => void;
  onCarbonEmissionLifeCycleChange: (value: number) => void;
  onRealPropsChange: (value: Array<CreateProportionRealPropsRequest>) => void;
  onConfirmButtonClick: () => void;
  onCancelcelButtonClick: () => void;
}

export default function CreateItemForm({
  displayName,
  designedStrength,
  carbonEmissionFactor,
  carbonEmissionLifeCycleFactor,
  realProps,
  onDisplayNameChange,
  onDesignedStrengthChange,
  onCarbonEmissionChange,
  onCarbonEmissionLifeCycleChange,
  onRealPropsChange,
  onConfirmButtonClick,
  onCancelcelButtonClick,
}: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { projectInfo } = useProductionHistory();
  const context = useContext(ProductionHistoryContext);

  const [proportionOptions, setProportionOptions] = useState<Array<Options>>([]);
  const [inputRef, inputSize] = useElementSize();

  const realPropsValues: Array<string> = useMemo(() => {
    return realProps.map((item) => item.proportionName);
  }, [realProps]);

  const handleRealPropsChange = (value: Array<any>) => {
    onRealPropsChange(value.map((item: any) => ({ proportionName: item })));
  };

  const handleConfirmButtonClick = () => {
    onConfirmButtonClick?.();
  };

  const handleCancelcelButtonClick = () => {
    onCancelcelButtonClick?.();
  };

  useEffect(() => {
    ProportionApi.getProportionNamesBySearch(
      {
        factoryId: projectInfo.factoryId,
      },
      (data: Array<string>) => setProportionOptions(data.map((item: string) => ({ text: item, value: item }))),
    );
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>{i18T('REPORT.PRODUCTION_HISTORY.INFORMATION.PROPORTION.SHIP_PROPRTION')}：</Title>
          <YatungComboBox
            width={`${inputSize.width}px`}
            loading={actionLoading}
            multiple
            limitTags={1}
            options={proportionOptions}
            defaultValue={realPropsValues}
            onBlur={(value: any) => {
              handleRealPropsChange(value);
            }}
            {...(context?.selectedProportions && { removeHadSelectedValues: context?.selectedProportions })}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>{i18T('REPORT.PRODUCTION_HISTORY.INFORMATION.PROPORTION.DISPLAY_NAME')}：</Title>
          <YatungInput
            ref={inputRef}
            value={displayName}
            onChange={(e: any) => {
              onDisplayNameChange(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>{i18T('REPORT.PRODUCTION_HISTORY.INFORMATION.PROPORTION.STRENGTH')}：</Title>
          <YatungInput
            type="number"
            value={designedStrength}
            onChange={(e: any) => {
              onDesignedStrengthChange(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>{i18T('REPORT.PRODUCTION_HISTORY.INFORMATION.PROPORTION.CARBON_EMISSION_COEFFCIENT')}：</Title>
          <YatungInput
            type="number"
            value={carbonEmissionFactor}
            onChange={(e: any) => {
              onCarbonEmissionChange(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>
            {i18T('REPORT.PRODUCTION_HISTORY.INFORMATION.PROPORTION.CARBON_EMISSION_COEFFCIENT_LIFE_CYCLE')}：
          </Title>
          <YatungInput
            type="number"
            value={carbonEmissionLifeCycleFactor}
            onChange={(e: any) => {
              onCarbonEmissionLifeCycleChange(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}
        >
          <YatungButton
            text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.CANCEL`)}
            color="red"
            startIcon={<Cancel />}
            onClick={() => {
              handleCancelcelButtonClick();
            }}
          />
          <YatungButton
            text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.SUBMIT`)}
            color="green"
            startIcon={<Confirm />}
            onClick={() => {
              handleConfirmButtonClick();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
