import React from 'react';
import { Typography } from '@mui/material';
import { YardReciptData } from '@Src/_basic/object/YardReceiptsType';

interface Props {
  row: YardReciptData;
}
export default function SupplierNameColumnFormatter(props: Props) {
  const { row } = props;
  return (
    <>
      {row.supplier1Name ? (
        <Typography variant="inherit">{row.supplier1Name}</Typography>
      ) : (
        <Typography variant="inherit">&nbsp;</Typography>
      )}
      {row.supplier2Name ? (
        <Typography variant="inherit">{row.supplier2Name}</Typography>
      ) : (
        <Typography variant="inherit">&nbsp;</Typography>
      )}
      {row.supplier3Name ? (
        <Typography variant="inherit">{row.supplier3Name}</Typography>
      ) : (
        <Typography variant="inherit">&nbsp;</Typography>
      )}
    </>
  );
}
