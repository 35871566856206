import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CancelIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.26731 20.88C4.92707 21.2203 4.37542 21.2203 4.03518 20.88C3.69494 20.5398 3.69494 19.9881 4.03518 19.6479L11.4281 12.255L4.03539 4.86232C3.69515 4.52207 3.69515 3.97042 4.03539 3.63019C4.37564 3.28994 4.92728 3.28994 5.26752 3.63019L12.6602 11.0229L20.0529 3.63019C20.3931 3.28994 20.9448 3.28994 21.285 3.63019C21.6253 3.97042 21.6253 4.52207 21.285 4.86232L13.8923 12.255L21.2852 19.6479C21.6255 19.9881 21.6255 20.5398 21.2852 20.88C20.945 21.2203 20.3933 21.2203 20.0531 20.88L12.6602 13.4871L5.26731 20.88Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.904999 4.33333C0.904999 2.21624 2.62124 0.5 4.73833 0.5H20.0717C22.1888 0.5 23.905 2.21624 23.905 4.33333V19.6667C23.905 21.7838 22.1888 23.5 20.0717 23.5H4.73833C2.62124 23.5 0.904999 21.7838 0.904999 19.6667V4.33333ZM4.73833 1.45833H20.0717C21.6595 1.45833 22.9467 2.74551 22.9467 4.33333V19.6667C22.9467 21.2545 21.6595 22.5417 20.0717 22.5417H4.73833C3.15051 22.5417 1.86333 21.2545 1.86333 19.6667V4.33333C1.86333 2.74551 3.15051 1.45833 4.73833 1.45833Z"
        fill="white"
      />
    </SvgIcon>
  );
}
