import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import BackButton from '@Src/_basic/icons/BackButton';
import { MaterialInspectionDetial, MaterialInspectionDetialByData } from '@Src/_basic/object/MaterialInspectionType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { ExptMaterialInspectionApi } from '@Src/_basic/protocol/exptMaterialInspection/ExptMaterialInspectionApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useMaterialInspection } from '@Src/redux/materialInspection/materialInspectionActions';
import { Grid, Pagination, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import MaterialInspectionByDetailTable from '../components/MaterialInspectionByDetailTable';

export default function MaterialInspectionByDetailScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    state: { name },
  } = useLocation();
  const { date } = useMaterialInspection();
  // const [loading, setLoading] = useState<boolean>(false);
  const { actionLoading } = useApi();
  const [materialInspectionDetailData, setMaterialInspectionDetailData] = useState<
    Array<MaterialInspectionDetialByData>
  >([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalCount: 0,
  });

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    ExptMaterialInspectionApi.getPaginationReqMaterialInspectionView(
      { time: date, factoryId: id, page: pageInfo.page },
      (data: MaterialInspectionDetial) => {
        setMaterialInspectionDetailData(data.data);
        setPageInfo({
          ...pageInfo,
          page: pageInfo.page,
          total: data.maxPage,
        });
      },
      undefined,
      undefined,
    );
  }, [date, id, pageInfo.page]);
  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_INSPECTION_PASS_MONITORING.TITLE') + `(${name})`}
      body={
        <>
          <Stack direction="row" spacing={2} sx={{ py: 1 }} justifyContent="flex-end">
            <YatungButton
              disabled={actionLoading}
              text={i18T('GLOBAL.BACK_PAGE')}
              color="green"
              onClick={handleGoBack}
              startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
              sx={{ alignSelf: 'center' }}
            />
          </Stack>
          <MaterialInspectionByDetailTable
            materialInspectionDetailData={materialInspectionDetailData}
            loading={actionLoading}
          />
          <Grid container justifyContent={'center'} item xs={12} sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid>
        </>
      }
      contentBgColor="#fff"
    />
  );
}
