import { Box, Card, IconButton, Stack, Typography, styled } from '@mui/material';

// 紀錄表 Card
export const TestCardContainer = styled(Card)(({ theme }) => ({
  width: '80%',
  padding: theme.spacing(2, 20),
  display: 'flex',
  alignSelf: 'center',
  flexDirection: 'column',
  borderRadius: '30px',
  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2);',
  position: 'relative',
}));

export const TestChemAdmxContainer = styled(Card)(({ theme }) => ({
  width: '90%',
  padding: theme.spacing(2, 5),
  display: 'flex',
  alignSelf: 'center',
  flexDirection: 'column',
  borderRadius: '30px',
  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2);',
  position: 'relative',
}));

export const TestDetailContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: '1px solid #9A99A1',
  marginBottom: '16px',
});

export const TableHeader = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 0',
  borderBottom: '1px solid #ccc',
});

export const TableHeaderTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: '900',
  color: '#003087',
  flex: 1,
});

export const TableHeaderSub = styled(Stack)({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
});

export const TableHeaderSubTitle = styled(Stack)({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
});

export const TableHeaderLast = styled(Typography)({
  color: 'rgba(90, 96, 174, 1)',
  fontWeight: '900',
});

export const CollapseIconButton = styled(IconButton)({
  marginLeft: 8,
});

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '900',
  color: '#003087',
  flex: 1,
}));

export const ChildrenTypography = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '900',
  flex: 1,
}));

export const TestVerifyResultStyles = {
  resultTitle: {
    marginBottom: 18,
    fontSize: 20,
  },
  textA: {
    color: 'rgba(90, 96, 174, 1)',
  },
  textB: {
    color: 'rgba(129, 178, 154, 1)',
  },
  textBlue: {
    color: '#003087',
  },
  abnormal: {
    color: '#FF0002',
  },
  normal: {
    color: '#70C030',
  },
};
