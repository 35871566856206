import { Protocol } from "../../../Protocol";

export class ApiProtocol {

  public static readonly POST_GET_RIGHTS_APIS: string = `${Protocol.YATUNG_AUTH}CA015`; // 取得權限API清單
  public static readonly POST_GET_RIGHTS_API_BY_ID: string = `${Protocol.YATUNG_AUTH}CA015`; // 取得權限API

  public static readonly POST_CREATE_RIGHTS_API: string = `${Protocol.YATUNG_AUTH}CA013`; // 新增權限API
  public static readonly POST_UPDATE_RIGHTS_API: string = `${Protocol.YATUNG_AUTH}CA014`; // 修改權限API
  public static readonly POST_DELETE_RIGHTS_API: string = `${Protocol.YATUNG_AUTH}CA016`; // 刪除權限API
  
}