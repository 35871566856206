import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import EquipmentScreen from './screens/EquipmentScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <EquipmentScreen />,
  },
];

const EquipmentManagementRouter = () => PermissionRoute(routesConfig);

export default memo(EquipmentManagementRouter);
