import YatungButton from '@Src/_basic/components/YatungButton';
import AccountName from '@Src/_basic/icons/AccountName';
import Car from '@Src/_basic/icons/Car';
import Clock from '@Src/_basic/icons/Clock';
import DeliveryInformationConstructionSiteName from '@Src/_basic/icons/DeliveryInformationConstructionSiteName';
import DeliveryInformationDesignStrength from '@Src/_basic/icons/DeliveryInformationDesignStrength';
import DeliveryInformationMaterialStatus from '@Src/_basic/icons/DeliveryInformationMaterialStatus';
import DeliveryInformationSlump from '@Src/_basic/icons/DeliveryInformationSlump';
import MonitorlastExperimentOperator from '@Src/_basic/icons/MonitorlastExperimentOperator/MonitorlastExperimentOperator';
import PlateNumber from '@Src/_basic/icons/PlateNumber';
import SampleLocationGray from '@Src/_basic/icons/SampleLocationGray';
import Video from '@Src/_basic/icons/Video';
import ViewQR from '@Src/_basic/icons/ViewQR';
import { MonitorOrderData } from '@Src/_basic/object/MonitorType';
import { Box, Card, CardContent, Stack } from '@mui/material';
import moment from 'moment';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: MonitorOrderData;
  index: number;
  handleVideoButtonClick: (id: number) => void;
  handleImageButtonClick: (id: number) => void;
  count: number;
  setImageUrl: (imageUrl: string[]) => void;
}

function ShipmentBlock({ data, index, handleVideoButtonClick, handleImageButtonClick, count, setImageUrl }: Props) {
  const isShowSlump: boolean = useMemo(
    () =>
      Boolean(
        data.beforeShippingSlump ||
          data.visualSlump ||
          data.actualSlump ||
          data.frontTubeSlump ||
          data.endTubeSlump ||
          data.materialStatus ||
          data.lastExperimentOperator,
      ),
    [
      data.beforeShippingSlump,
      data.visualSlump,
      data.actualSlump,
      data.frontTubeSlump,
      data.endTubeSlump,
      data.materialStatus,
      data.lastExperimentOperator,
    ],
  );

  const isShowSlump2: boolean = useMemo(
    () =>
      Boolean(
        data.beforeShippingSlump2 ||
          data.visualSlump2 ||
          data.actualSlump2 ||
          data.frontTubeSlump2 ||
          data.endTubeSlump2 ||
          data.materialStatus2 ||
          data.lastExperimentOperator2,
      ),
    [
      data.beforeShippingSlump2,
      data.visualSlump2,
      data.actualSlump2,
      data.frontTubeSlump2,
      data.endTubeSlump2,
      data.materialStatus2,
      data.lastExperimentOperator2,
    ],
  );

  const isShowSlumpFlow: boolean = useMemo(
    () =>
      Boolean(
        data.beforeShippingSlumpFlow ||
          data.visualSlumpFlow ||
          data.actualSlumpFlow ||
          data.frontTubeSlumpFlow ||
          data.endTubeSlumpFlow ||
          data.materialStatusFlow ||
          data.lastExperimentOperatorFlow,
      ),
    [
      data.beforeShippingSlumpFlow,
      data.visualSlumpFlow,
      data.actualSlumpFlow,
      data.frontTubeSlumpFlow,
      data.endTubeSlumpFlow,
      data.materialStatusFlow,
      data.lastExperimentOperatorFlow,
    ],
  );

  const isShowSlumpFlow2: boolean = useMemo(
    () =>
      Boolean(
        data.beforeShippingSlumpFlow2 ||
          data.visualSlumpFlow2 ||
          data.actualSlumpFlow2 ||
          data.frontTubeSlumpFlow2 ||
          data.endTubeSlumpFlow2 ||
          data.materialStatusFlow2 ||
          data.lastExperimentOperatorFlow2,
      ),
    [
      data.beforeShippingSlumpFlow2,
      data.visualSlumpFlow2,
      data.actualSlumpFlow2,
      data.frontTubeSlumpFlow2,
      data.endTubeSlumpFlow2,
      data.materialStatusFlow2,
      data.lastExperimentOperatorFlow2,
    ],
  );

  const isHasExperiment: boolean = useMemo(() => {
    return isShowSlump || isShowSlumpFlow || isShowSlump2 || isShowSlumpFlow2;
  }, [isShowSlump, isShowSlumpFlow, isShowSlump2, isShowSlumpFlow2]);

  const isFailed: boolean = useMemo(() => {
    return data.status === false || data.statusFlow === false || data.status2 === false || data.statusFlow2 === false; //檢查是否有錯誤或退車
  }, [data.status, data.statusFlow, data.status2, data.statusFlow2]);

  const { t: i18T } = useTranslation();

  return (
    <>
      <>
        <Box key={data.shipmentId} marginTop={'20px'} width={'100%'}>
          <Card
            id={`${data.shipmentId}`} //新增id來讓警示可以跳轉到該車
            sx={{
              height: '100%',
              width: '1500px',
              borderRadius: '13px',
              border: '15px solid #FFFFFF',
              boxShadow: '4px 4px 20px 0px #00000033',
              backgroundImage: isHasExperiment
                ? !isFailed
                  ? 'linear-gradient(to right, #FFFFFF, #83D2EB)'
                  : 'linear-gradient(to right ,#FFFFFF, #f5abbe)'
                : undefined,
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {data.imageUrl.length ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                      <YatungButton
                        text={i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.VEIW_IMAGE')}
                        color="green"
                        onClick={() => {
                          handleImageButtonClick(data.shipmentId);
                          setImageUrl(data.imageUrl);
                        }}
                        startIcon={<ViewQR sx={{ width: '25px', height: '25px' }} />}
                        sx={{
                          alignSelf: 'center',
                          marginRight: '10px',
                        }}
                        style={{ marginBottom: '10px' }}
                      />
                    </div>
                    <Stack
                      style={{
                        width: '1470px',
                        border: '12px soild black',
                        background: 'black',
                        height: '1px',
                        marginBottom: '10px',
                      }}
                    ></Stack>
                  </div>
                ) : null}
                {data.imageUrl2.length ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                      <YatungButton
                        text={i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.VEIW_IMAGE')}
                        color="green"
                        onClick={() => {
                          handleImageButtonClick(data.shipmentId);
                          setImageUrl(data.imageUrl2);
                        }}
                        startIcon={<ViewQR sx={{ width: '25px', height: '25px' }} />}
                        sx={{
                          alignSelf: 'center',
                          marginRight: '10px',
                        }}
                        style={{ marginBottom: '10px' }}
                      />
                    </div>
                    <Stack
                      style={{
                        width: '1470px',
                        border: '12px soild black',
                        background: 'black',
                        height: '1px',
                        marginBottom: '10px',
                      }}
                    ></Stack>
                  </div>
                ) : null}
                {data.imageUrlFlow.length ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '135px' }}>
                      <YatungButton
                        text={i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.VEIW_VEDIO')}
                        color="green"
                        onClick={() => {
                          handleImageButtonClick(data.shipmentId);
                          setImageUrl(data.imageUrlFlow);
                        }}
                        startIcon={<Video sx={{ width: '25px', height: '25px' }} />}
                        sx={{ alignSelf: 'center', marginRight: '10px', marginBottom: '10px' }}
                      />
                    </div>
                    <Stack
                      style={{
                        width: '1470px',
                        border: '12px soild black',
                        background: 'black',
                        height: '1px',
                        marginBottom: '10px',
                      }}
                    ></Stack>
                  </div>
                ) : null}
                {data.imageUrlFlow2.length ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '135px' }}>
                      <YatungButton
                        text={i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.VEIW_VEDIO')}
                        color="green"
                        onClick={() => {
                          handleImageButtonClick(data.shipmentId);
                          setImageUrl(data.imageUrlFlow2);
                        }}
                        startIcon={<Video sx={{ width: '25px', height: '25px' }} />}
                        sx={{ alignSelf: 'center', marginRight: '10px', marginBottom: '10px' }}
                      />
                    </div>
                    <Stack
                      style={{
                        width: '1470px',
                        border: '12px soild black',
                        background: 'black',
                        height: '1px',
                        marginBottom: '10px',
                      }}
                    ></Stack>
                  </div>
                ) : null}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    width: '1000px',
                    height: '130px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationConstructionSiteName
                        sx={{ marginTop: '10px', width: '50px', height: '30px' }}
                      />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ORDER_NAME')}：{data.orderName}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationDesignStrength sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.STRENGTH')}：
                      {data.strength
                        ? data.strength + ' KgF/CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Stack>
                </Box>

                <Stack
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'flex-end'}
                  sx={{ width: '400px', height: '100px', fontSize: '30px', textAlign: 'center' }}
                >
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                  >
                    <Car sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                    車次
                  </Stack>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    style={{ letterSpacing: '10px', display: 'flex', marginLeft: '20px' }}
                  >
                    <Stack>{'<'}</Stack>
                    <Stack style={{ letterSpacing: '10px' }}>{index + 1}</Stack>
                    <Stack>{'/'}</Stack>
                    <Stack style={{ letterSpacing: '10px' }}>{count}</Stack>
                    <Stack>{'>'}</Stack>
                  </Stack>
                </Stack>
              </Box>

              <Box
                sx={{
                  width: '1500px',
                  height: '70px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '60px',
                }}
              >
                <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', height: '100%', fontSize: '30px' }}>
                  <AccountName sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                  {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.CUSTOMER_NAME')}：{data.customerName}
                </Stack>
                <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', height: '100%', fontSize: '30px' }}>
                  <DeliveryInformationDesignStrength sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                  {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.SLUMP_DEMAND')}：{data.slumpDemand} CM
                </Stack>
                <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', height: '100%', fontSize: '30px' }}>
                  <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                  {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.PROPORTION_NAME')}：{data.proportionName}
                </Stack>
              </Box>

              <Box
                sx={{
                  width: '1500px',
                  height: '70px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '60px',
                }}
              >
                <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', height: '100%', fontSize: '30px' }}>
                  <SampleLocationGray sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                  {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.POURING_PLACE')}：
                  {data.pouringPlace ? data.pouringPlace : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                </Stack>
                <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', height: '100%', fontSize: '30px' }}>
                  <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                  {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.SHIPMENT_DATE')}：
                  {moment(data.shipmentDate).format('YYYY-MM-DD HH:mm')}
                </Stack>
                <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', height: '100%', fontSize: '30px' }}>
                  <PlateNumber sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                  <Stack style={{ letterSpacing: '35px', marginLeft: '10px' }}>
                    {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.CAR_ID')}
                  </Stack>
                  ：{data.carId}
                </Stack>
              </Box>

              <Box
                sx={{
                  width: '1500px',
                  height: '70px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '60px',
                }}
              >
                <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', height: '100%', fontSize: '30px' }}>
                  <SampleLocationGray sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                  {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.MIXER_NUMBER')}：
                  {data.mixerName ? data.mixerName : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                  {/* 拌合機資訊 */}
                </Stack>
              </Box>

              {isShowSlump ? (
                <>
                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.BEFORESHIPPING_SLUMP')}：
                      {data.beforeShippingSlump
                        ? data.beforeShippingSlump + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationDesignStrength sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.EXPTUNIT_NAME')}：
                      {data.exptUnitName
                        ? data.exptUnitName
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.VISUAL_SLUMP')}：
                      {data.visualSlump
                        ? data.visualSlump + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ACTUAL_SLUMP')}：
                      {data.actualSlump ? data.actualSlump : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.FRONTTUBES_SLUMP')}：
                      {data.frontTubeSlump
                        ? data.frontTubeSlump
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      <Stack>{i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ENDTUBES_SLUMP')}</Stack>：
                      {data.endTubeSlump
                        ? data.endTubeSlump
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationMaterialStatus sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.MATERIAL_STATUS')}：
                      <Stack
                        color={
                          data?.materialStatus === '優' ||
                          data?.materialStatus === '良' ||
                          data?.materialStatus === '可'
                            ? '#00D03A'
                            : data?.materialStatus === '差' || data?.materialStatus === '劣'
                            ? '#CB333B'
                            : 'gray'
                        }
                      >
                        {data.materialStatus
                          ? data.materialStatus
                          : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                      </Stack>
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <MonitorlastExperimentOperator sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.LASTEXPERIMENT_OPERATOR')}：
                      {data.lastExperimentOperator
                        ? data.lastExperimentOperator
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.REASON')}：
                      {data.reason ? data.reason : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.REMARK')}：
                      {data.remark ? data.remark : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>
                </>
              ) : (
                <></>
              )}

              {isShowSlump2 ? (
                <>
                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.BEFORESHIPPING_SLUMP')}：
                      {data.beforeShippingSlump2
                        ? data.beforeShippingSlump2 + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationDesignStrength sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.EXPTUNIT_NAME')}：
                      {data.exptUnitName2
                        ? data.exptUnitName2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.VISUAL_SLUMP')}：
                      {data.visualSlump2
                        ? data.visualSlump2 + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ACTUAL_SLUMP')}：
                      {data.actualSlump2
                        ? data.actualSlump2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.FRONTTUBES_SLUMP')}：
                      {data.frontTubeSlump2
                        ? data.frontTubeSlump2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      <Stack>{i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ENDTUBES_SLUMP')}</Stack>：
                      {data.endTubeSlump2
                        ? data.endTubeSlump2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationMaterialStatus sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.MATERIAL_STATUS')}：
                      <Stack
                        color={
                          data?.materialStatus2 === '優' ||
                          data?.materialStatus2 === '良' ||
                          data?.materialStatus2 === '可'
                            ? '#00D03A'
                            : data?.materialStatus2 === '差' || data?.materialStatus2 === '劣'
                            ? '#CB333B'
                            : 'gray'
                        }
                      >
                        {data.materialStatus2
                          ? data.materialStatus2
                          : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                      </Stack>
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <MonitorlastExperimentOperator sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.LASTEXPERIMENT_OPERATOR')}：
                      {data.lastExperimentOperator2
                        ? data.lastExperimentOperator2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.REASON')}：
                      {data.reason2 ? data.reason2 : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.REMARK')}：
                      {data.remark ? data.remark : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>
                </>
              ) : (
                <></>
              )}

              {isShowSlumpFlow ? (
                <>
                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.BEFORESHIPPING_SLUMP_FLOW_DEMAND')}：
                      {data.beforeShippingSlumpFlow
                        ? data.beforeShippingSlump + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationDesignStrength sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.EXPTUNIT_NAME')}：
                      {data.exptFlowUnitName
                        ? data.exptFlowUnitName
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.VISUAL_SLUMP_FLOW_DEMAND')}：
                      {data.visualSlumpFlow
                        ? data.visualSlumpFlow + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ACTUAL_SLUMP_FLOW_DEMAND')}：
                      {data.actualSlumpFlow
                        ? data.actualSlumpFlow
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.FRONTTUBES_SLUMP_FLOW_DEMAND')}：
                      {data.frontTubeSlumpFlow
                        ? data.frontTubeSlumpFlow
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      <Stack>{i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ENDTUBES_SLUMP_FLOW_DEMAND')}</Stack>：
                      {data.endTubeSlumpFlow
                        ? data.endTubeSlumpFlow
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.SLUMP_FLOW_DEMAND')}：
                      {data.slumpFlowDemand
                        ? data.slumpFlowDemand + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationMaterialStatus sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.MATERIAL_STATUS_FLOW')}：
                      <Stack
                        color={
                          data?.materialStatusFlow === '優' ||
                          data?.materialStatusFlow === '良' ||
                          data?.materialStatusFlow === '可'
                            ? '#00D03A'
                            : data?.materialStatusFlow === '差' || data?.materialStatusFlow === '劣'
                            ? '#CB333B'
                            : 'gray'
                        }
                      >
                        {data.materialStatusFlow
                          ? data.materialStatusFlow
                          : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                      </Stack>
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <MonitorlastExperimentOperator sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.LASTEXPERIMENT_OPERATOR_FLOW')}：
                      {data.lastExperimentOperatorFlow
                        ? data.lastExperimentOperatorFlow
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.REASON')}：
                      {data.reason2 ? data.reason2 : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.REMARK')}：
                      {data.remark ? data.remark : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    ></Stack>
                  </Box>
                </>
              ) : (
                <></>
              )}

              {isShowSlumpFlow2 ? (
                <>
                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.BEFORESHIPPING_SLUMP_FLOW_DEMAND')}：
                      {data.beforeShippingSlumpFlow2
                        ? data.beforeShippingSlump2 + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationDesignStrength sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.EXPTUNIT_NAME')}：
                      {data.exptFlowUnitName2
                        ? data.exptFlowUnitName2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.VISUAL_SLUMP_FLOW_DEMAND')}：
                      {data.visualSlumpFlow2
                        ? data.visualSlumpFlow2 + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ACTUAL_SLUMP_FLOW_DEMAND')}：
                      {data.actualSlumpFlow2
                        ? data.actualSlumpFlow2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.FRONTTUBES_SLUMP_FLOW_DEMAND')}：
                      {data.frontTubeSlumpFlow2
                        ? data.frontTubeSlumpFlow2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      <Stack>{i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ENDTUBES_SLUMP_FLOW_DEMAND')}</Stack>：
                      {data.endTubeSlumpFlow2
                        ? data.endTubeSlumpFlow2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationSlump sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.SLUMP_FLOW_DEMAND')}：
                      {data.slumpFlowDemand2
                        ? data.slumpFlowDemand2 + ' CM'
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <DeliveryInformationMaterialStatus sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.MATERIAL_STATUS_FLOW')}：
                      <Stack
                        color={
                          data?.materialStatusFlow2 === '優' ||
                          data?.materialStatusFlow2 === '良' ||
                          data?.materialStatusFlow2 === '可'
                            ? '#00D03A'
                            : data?.materialStatusFlow2 === '差' || data?.materialStatusFlow2 === '劣'
                            ? '#CB333B'
                            : 'gray'
                        }
                      >
                        {data.materialStatusFlow2
                          ? data.materialStatusFlow2
                          : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                      </Stack>
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <MonitorlastExperimentOperator sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.LASTEXPERIMENT_OPERATOR_FLOW')}：
                      {data.lastExperimentOperatorFlow2
                        ? data.lastExperimentOperatorFlow2
                        : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      width: '1500px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '60px',
                    }}
                  >
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.REASON')}：
                      {data.reason2 ? data.reason2 : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    >
                      <Clock sx={{ marginTop: '10px', width: '50px', height: '30px' }} />
                      {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.REMARK')}：
                      {data.remark ? data.remark : i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NO_DATA')}
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{ width: '100%', height: '100%', fontSize: '30px' }}
                    ></Stack>
                  </Box>
                </>
              ) : (
                <></>
              )}

              <Stack alignItems={'center'}></Stack>
            </CardContent>
          </Card>
        </Box>
      </>
    </>
  );
}

export default memo(ShipmentBlock);
