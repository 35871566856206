import { Pageable } from '@Src/_basic/object/ApiType';
import { EquipmentData, } from '@Src/_basic/object/EquipmentManagementType'
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialState {
  data: EquipmentData | undefined;
}

export const equipmentSlice = createSlice({
  name: 'equipmentSlice',
  initialState: {
    data: undefined,
  } as initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<EquipmentData>) => {
      state.data = action.payload;
    },
  },
});