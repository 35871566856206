import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PotionSmallIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} width="23" height="24" viewBox="0 0 23 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2949 14.5714C13.4657 14.5714 14.4147 13.612 14.4147 12.4286C14.4147 11.9462 14.2571 11.501 13.9909 11.1429H14.8358L19.2939 19.7216C19.8861 20.8612 19.1458 22.2857 17.9615 22.2857H4.93256C3.7482 22.2857 3.00794 20.8612 3.60012 19.7216L8.05823 11.1429H10.5989C10.3328 11.501 10.1751 11.9462 10.1751 12.4286C10.1751 13.612 11.1242 14.5714 12.2949 14.5714ZM8.0553 18.8571C8.75774 18.8571 9.32719 18.2815 9.32719 17.5714C9.32719 16.8613 8.75774 16.2857 8.0553 16.2857C7.35286 16.2857 6.78341 16.8613 6.78341 17.5714C6.78341 18.2815 7.35286 18.8571 8.0553 18.8571ZM11.871 21.4286C12.3393 21.4286 12.7189 21.0448 12.7189 20.5714C12.7189 20.098 12.3393 19.7143 11.871 19.7143C11.4027 19.7143 11.023 20.098 11.023 20.5714C11.023 21.0448 11.4027 21.4286 11.871 21.4286Z" fill="#7C878E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.8002 24C22.4911 24 23.5479 22.125 22.7025 20.625L16.3244 9.30929C16.1302 8.96477 16.1334 8.55029 16.1365 8.14916C16.1369 8.09917 16.1372 8.04939 16.1372 8.00001V1.5C16.1372 0.671395 15.4816 0 14.6729 0H8.32715C7.51844 0 6.86276 0.671395 6.86276 1.5V8.00001C6.86276 8.04939 6.86314 8.09917 6.86352 8.14914C6.86662 8.55027 6.86981 8.96477 6.67563 9.30929L0.297535 20.625C-0.547875 22.125 0.508947 24 2.19977 24H20.8002ZM7.71068 1.5C7.71068 1.13416 7.99718 0.857143 8.32715 0.857143H14.6729C15.0028 0.857143 15.2893 1.13416 15.2893 1.5V8.00001C15.2893 8.03566 15.289 8.07568 15.2886 8.11908C15.2871 8.2974 15.2851 8.5323 15.3043 8.75194C15.3295 9.04125 15.3953 9.39245 15.5876 9.73363L21.9657 21.0493C22.4986 21.9949 21.8212 23.1429 20.8002 23.1429H2.19977C1.17879 23.1429 0.501354 21.9949 1.03428 21.0493L7.41236 9.73363C7.60466 9.39245 7.67046 9.04125 7.69572 8.75194C7.7149 8.5323 7.71291 8.2974 7.71139 8.11908C7.71102 8.07584 7.71068 8.03553 7.71068 8.00001V1.5Z" fill="#7C878E" />
        </SvgIcon>
    );
} 