import { WarehouseAndStateData, DynmaicObject } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { feedTroughMaintenanceSlice } from './feedTroughMaintenanceSlice';

export function useFeedTroughMaintenance() {
  const {
    feedTroughMaintenanceData,
    areaValue,
    factoryValue,
    feedTroughMaintenanceCreateDatas,
    feedTroughMaintenanceMappingDatas,
    storageYardeData,
    storageYardCreateDatas,
    storageYardMappingDatas,
  } = useSelector((state: RootState) => state.feedTroughMaintenance);
  const dispatch = useDispatch();
  const setAreaValue = (_areaValue: number) => dispatch(feedTroughMaintenanceSlice.actions.setAreaValue(_areaValue));
  const setFactoryValue = (_factoryValue: number) =>
    dispatch(feedTroughMaintenanceSlice.actions.setFactoryValue(_factoryValue));

  const setFeedTroughMaintenanceData = (_feedTroughMaintenanceData: WarehouseAndStateData[]) =>
    dispatch(feedTroughMaintenanceSlice.actions.setFeedTroughMaintenanceData(_feedTroughMaintenanceData));

  const setFeedTroughMaintenanceCreateDatas = (_feedTroughMaintenanceCreateData: DynmaicObject[]) =>
    dispatch(feedTroughMaintenanceSlice.actions.setFeedTroughMaintenanceCreateDatas(_feedTroughMaintenanceCreateData));

  const setFeedTroughMaintenanceMappingDatas = (_feedTroughMaintenanceMappingDatas: WarehouseAndStateData[]) =>
    dispatch(
      feedTroughMaintenanceSlice.actions.setFeedTroughMaintenanceMappingDatas(_feedTroughMaintenanceMappingDatas),
    );

  const setStorageYardeData = (_strorageYardData: WarehouseAndStateData[]) =>
    dispatch(feedTroughMaintenanceSlice.actions.setStorageYardeData(_strorageYardData));
  const setStorageYardCreateDatas = (_storageYardCreateDatas: DynmaicObject[]) =>
    dispatch(feedTroughMaintenanceSlice.actions.setStorageYardCreateDatas(_storageYardCreateDatas));
  const setStorageYardMappingDatas = (_storageYardMappingDatas: WarehouseAndStateData[]) =>
    dispatch(feedTroughMaintenanceSlice.actions.setStorageYardMappingDatas(_storageYardMappingDatas));
  return {
    areaValue,
    setAreaValue,
    factoryValue,
    setFactoryValue,
    feedTroughMaintenanceData,
    setFeedTroughMaintenanceData,
    feedTroughMaintenanceCreateDatas,
    setFeedTroughMaintenanceCreateDatas,
    feedTroughMaintenanceMappingDatas,
    setFeedTroughMaintenanceMappingDatas,
    storageYardeData,
    setStorageYardeData,
    storageYardCreateDatas,
    setStorageYardCreateDatas,
    storageYardMappingDatas,
    setStorageYardMappingDatas,
  };
}
