import { Response } from '@Src/_basic/object/ApiType';
import {
  CreateExptScheduleRequest,
  ExptScheduleData,
  PageableExptScheduleData,
  SearchPageableExptSchedulesParams,
  UpdateExptScheduleRequest,
} from '@Src/_basic/object/ExptScheduleType';
import { ExptScheduleApi } from '@Src/_basic/protocol/exptSchedule/ExptScheduleApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { exptScheduleSlice } from './exptScheduleSlice';

export function useExptSchedule() {
  const { exptSchedule, exptSchedules, pageableExptSchedules } = useSelector((state: RootState) => state.exptSchedule);
  const dispatch = useDispatch();

  const getExptScheduleById = (id: number) => {
    ExptScheduleApi.getExptScheduleById(id, setExptSchedule);
  };

  const getPageableExptSchedulesBySearch = (params: SearchPageableExptSchedulesParams) => {
    ExptScheduleApi.getPageableExptSchedulesBySearch(params, setPageableExptSchedules);
  };

  const getCancelablePageableExptSchedulesBySearch = (params: SearchPageableExptSchedulesParams) => {
    ExptScheduleApi.getCancelablePageableExptSchedulesBySearch(params, setPageableExptSchedules);
  };

  const createExptSchedule = (
    request: CreateExptScheduleRequest,
    onSuccess?: (data: ExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) => {
    ExptScheduleApi.createExptSchedule(
      request,
      (data: ExptScheduleData) => {
        setExptSchedule(data);
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const updateExptSchedule = (
    request: UpdateExptScheduleRequest,
    onSuccess?: (data: ExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) => {
    ExptScheduleApi.updateExptSchedule(
      request,
      (data: ExptScheduleData) => {
        setExptSchedule;
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const deleteExptSchedule = (
    id: number,
    onSuccess?: (data: ExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) => {
    ExptScheduleApi.deleteExptSchedule(id, onSuccess, onFailed, onFinally);
  };

  const setExptSchedule = (data: ExptScheduleData) => dispatch(exptScheduleSlice.actions.setExptSchedule(data));

  const setPageableExptSchedules = (data: PageableExptScheduleData) =>
    dispatch(exptScheduleSlice.actions.setPageableExptSchedules(data));

  const cleanPageableExptSchedules = () => dispatch(exptScheduleSlice.actions.setPageableExptSchedules(undefined));

  return {
    exptSchedule,
    exptSchedules,
    pageableExptSchedules,
    getExptScheduleById,
    getPageableExptSchedulesBySearch,
    getCancelablePageableExptSchedulesBySearch,
    createExptSchedule,
    updateExptSchedule,
    deleteExptSchedule,
    cleanPageableExptSchedules,
  };
}
