import {
  CreateSignatureFormType,
  CreateSignatureItemRequest,
  SignatureItemData,
  SignatureItemDetailData,
} from '@Src/_basic/object/SignatureType';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { useSignature } from '@Src/redux/signature/signatureActions';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';

import SubmitButtons from '../../SignatureFormLayout';
import CementitiousCard from './SignatrueItemForm/CementitiousCard';
import ChemicalCard from './SignatrueItemForm/ChemicalCard';
import SandAndRockCard from './SignatrueItemForm/SandAndRockCard';

export default function CreateSignatureFrom() {
  const { projectInfo, createSignature } = useProductionHistory();
  const { signature, clearSignature } = useSignature();

  const formData = useMemo(() => {
    return {
      cementingMaterial: signature?.cementingMaterial?.map((item: SignatureItemData) => ({
        ...item,
        details: item.details?.map((detail: SignatureItemDetailData) => ({ ...detail })),
      })),
      chemicalAdmixture: signature?.chemicalAdmixture?.map((item: SignatureItemData) => ({
        ...item,
        details: item.details?.map((detail: SignatureItemDetailData) => ({ ...detail })),
      })),
      sandStone: signature?.sandStone?.map((item: SignatureItemData) => ({
        ...item,
        details: item.details?.map((detail: SignatureItemDetailData) => ({ ...detail })),
      })),
    };
  }, [signature]);

  const formik = useFormik({
    initialValues: formData,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const handleSubmit = useCallback(
    (isTemplete: boolean) => {
      function filterWithOutZero(values: Array<SignatureItemData>) {
        return values
          .map((item) => ({
            ...item,
            details: item?.details?.filter((detail) => detail.specificationId !== 0 && detail.BOMId !== 0),
          }))
          .filter((item) => item.typeId !== 0 && item.details?.length !== 0);
      }

      const filteredCementingMaterial = filterWithOutZero(formik.values.cementingMaterial);
      const filteredChemicalAdmixture = filterWithOutZero(formik.values.chemicalAdmixture);
      const filteredSandStone = filterWithOutZero(formik.values.sandStone);

      createSignature(
        {
          projectInfoId: projectInfo.projectInfoId,
          factoryId: projectInfo.factoryId,
          items: [...filteredCementingMaterial, ...filteredChemicalAdmixture, ...filteredSandStone],
        },
        isTemplete,
      );
    },
    [formik, projectInfo, createSignature],
  );

  const setFieldValueHandler = useCallback(
    (field: keyof CreateSignatureFormType, value: Array<CreateSignatureItemRequest>) => {
      formik.setFieldValue(field, value);
    },
    [formik],
  );

  const resetForm = useCallback(() => {
    clearSignature();
    formik.resetForm();
  }, [clearSignature, formik]);

  useEffect(() => {
    return () => {
      clearSignature();
    };
  }, []);

  return (
    <SubmitButtons cancelHandler={resetForm} confirmHandler={handleSubmit}>
      <>
        <CementitiousCard
          data={formik.values.cementingMaterial}
          onChange={(value) => setFieldValueHandler('cementingMaterial', value)}
        />
        <ChemicalCard
          data={formik.values.chemicalAdmixture}
          onChange={(value) => setFieldValueHandler('chemicalAdmixture', value)}
        />
        <SandAndRockCard
          data={formik.values.sandStone}
          onChange={(value) => setFieldValueHandler('sandStone', value)}
        />
      </>
    </SubmitButtons>
  );
}
