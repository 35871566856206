import { KeyManagementData } from '@Src/_basic/object/KeyManagementType';
import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  row: KeyManagementData;
}
export default function CodeColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.code}</Typography>;
}
