import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import EditIcon from '@Src/_basic/icons/Edit';
import NameIcon from '@Src/_basic/icons/Name';
import { UpdateEntityRequest } from '@Src/_basic/object/EntityType';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UploadFileCard from '../../../components/UploadFileCard';

interface Props {
  values: UpdateEntityRequest | any;
  setValues: (name: string, number: any) => void;
  handleAddGoBackButtonClick: () => void;
  inputNameValues: string;
  setInputNameValues: (e: string) => void;
  file: FileList | undefined;
  handleChange: (e: any) => void;
  defaultName: string;
}
export default function AddLangField(props: Props) {
  const { values, handleAddGoBackButtonClick, inputNameValues, setInputNameValues, file, handleChange, defaultName } =
    props;

  const list = [];

  if (file) {
    for (let i = 0; i < file?.length; i++) {
      list.push(i ? `、${file[i]?.name}` : file[i]?.name);
    }
  }

  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack flexDirection={'column'} sx={{ mt: 1 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <NameIcon />
            <Typography sx={{ pl: 1, fontSize: 20 }}>
              {i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.LANGUAGE_NAME')}：
            </Typography>
          </Stack>
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungInput
            id="name"
            name="name"
            value={inputNameValues}
            sx={{ width: '100%' }}
            onChange={(e: any) => setInputNameValues(e.target.value)}
            onKeyPress={(e: any) => {
              if (new RegExp(/[@=+!#$%^&*-]/).test(e.key)) { //擋住特殊字元
                e.preventDefault();
              }
            }}
          />
        </Stack>
        <Stack marginTop={'20px'} justifyContent={'space-between'} flexDirection={'row'}>
          <Typography sx={{ pl: 1, fontSize: 20 }}>
            {i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.UPLOAD_ORIGIN_LANG_FILE')}：
          </Typography>
          <Button
            href={defaultName}
            startIcon={<EditIcon />}
            style={{
              padding: '8px 12px',
              borderRadius: 8,
              lineHeight: '1.4375rem',
              fontSize: '20px',
              color: '#fff',
              background: '#00D03A',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
              marginBottom: '10px',
            }}
          >
            {i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.DOWNLOAD_BLANK_LANG_FILE')}
          </Button>
        </Stack>
        <UploadFileCard handleChange={handleChange} list={list} />

        <Stack
          spacing={3}
          sx={{ bgcolor: 'white', minWidth: 500, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
          display="flex"
          flexDirection="column"
        >
          <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <YatungButton
              text={i18T('ENTITYMANAGEMENT.CANCEL')}
              onClick={handleAddGoBackButtonClick}
              sx={{
                color: '#ffffff',
                bgcolor: '#CB333B',
                fontSize: '24px',
                width: 120,
                mr: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#CB333B',
                },
              }}
            />
            <YatungButton
              text={i18T('ENTITYMANAGEMENT.COMPLETE')}
              type="submit"
              sx={{
                border: '1px solid #fff',
                color: '#ffffff',
                bgcolor: '#002F8C',
                fontSize: '24px',
                width: 120,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#002F8C',
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
