import React from 'react';
import { toAbsoluteUrl } from '@Src/_basic/helpers/AssetHelpers';
import { AppPermissionImg } from '@Src/_basic/object/AppPermissionType';
import { Box, SxProps } from '@mui/material';

interface Props {
  img: string;
  sx?: SxProps;
}
export default function YatungImg(props: Props) {
  const { img, sx } = props;
  return (
    <>
      <Box
        component="img"
        src={img}
        sx={
          sx || {
            width: '20px',
            height: '20px',
            zIndex: 999,
            alignSelf: 'center',
            display: 'flex',
            textAlign: 'center',
          }
        }
      />
    </>
  );
}
