import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface Props {
  names: Array<string>;
  displayAmount?: number;
}

const DISPLAY_AMOUNT = 5;

export default function NamesExpand(props: Props) {
  const [displayAmount, setDisplayAmount] = useState(DISPLAY_AMOUNT);
  const [isExpand, setIsExpand] = useState(false);
  const [display, setDisplay] = useState<Array<string>>([]);

  const isExpandable = () => {
    return props.names.length > displayAmount;
  };

  const handleClick = () => {
    setIsExpand((current) => {
      return !current;
    });
  };

  useEffect(() => {
    if (props.displayAmount !== undefined) {
      setDisplayAmount(props.displayAmount);
    }
  }, [props.displayAmount]);

  useEffect(() => {
    if (isExpand) {
      setDisplay(props.names);
    } else {
      setDisplay(props.names.slice(0, displayAmount));
    }
  }, [props.names, isExpand]);

  return (
    <>
      <span>
        {display.map((name: any, index: number) => {
          return index ? `、${name}` : name;
        })}
      </span>
      {isExpandable() && (
        <>
          {!isExpand && <span>...</span>}
          <IconButton size="small" component="label" onClick={handleClick}>
            {isExpand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
          </IconButton>
        </>
      )}
    </>
  );
}
