import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Calendar(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M19.7,21.1H4c-1.3,0-2.1-0.9-2.1-1.8v-10h20v10C21.9,20.1,21,21.1,19.7,21.1z M2.9,10.3v9C2.9,19.6,3.3,20,4,20h15.7c0.7,0,1.1-0.4,1.1-0.7v-9H2.9z M21.9,8.1h-20V6.3c0-0.9,0.8-1.8,2.1-1.8h2.1V3.3c0-0.6,0.5-1.2,1.4-1.2S9,2.8,9,3.3v1.2h5.7V3.3c0-0.6,0.5-1.2,1.4-1.2c0.9,0,1.4,0.6,1.4,1.2v1.2h2.1c1.3,0,2.1,0.9,2.1,1.8V8.1z M2.9,7h17.8V6.3c0-0.3-0.4-0.7-1.1-0.7h-3.2V3.3c0,0-0.1-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.1l0,2.2H7.9V3.3c0,0-0.1-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.1l0,2.2H4C3.3,5.6,2.9,6,2.9,6.3V7z" />
    </SvgIcon>
  );
}
