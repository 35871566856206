import React, { CSSProperties } from 'react';
import { TextField, Typography } from '@mui/material';

interface Props {
  onChange: (event: any) => void;
  value: any;
  style?: CSSProperties;
  text?: string;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
}

export default function YatungTextInput({onChange, value, style, text, size="medium", disabled = false}: Props) {

  return (
    <>
      <TextField variant="outlined" sx={{ width: '20%', ...style }} onChange={onChange} value={value} size={size} disabled={disabled}></TextField>
      <Typography>{text}</Typography>
    </> 
  )
}
