import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    text: {
      primary: '#6C708C',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Inter, sans-serif',
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: 40,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 32,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 24,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    overline: {
      textTransform: 'none',
    },
  },
});
