import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function FinishIcon(props: SvgIconProps) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-0.5" y="0.5" width={22} height={22} rx="3.5" transform="matrix(-1 0 0 1 22.2148 0.5)" stroke="white" />
      <path
        d="M3.21484 11.3833L6.376 18.4096H10.2396L20.2148 4.50049H16.9132L9.11567 14.9682H7.78096L6.02476 11.3833H3.21484Z"
        fill="white"
      />
    </svg>
  );
}
