import { MonitorCustomerData } from '@Src/_basic/object/MonitorType';
import { Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

interface Props {
  row: MonitorCustomerData;
}
export default function AddressColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.address ? row.address : '無'}</Typography>;
}
