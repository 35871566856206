import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';

interface Props {
  onSubmit: () => void;
  onGoBack: () => void;
}
export default function FactoryManagementCreateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onSubmit, onGoBack } = props;
  return (
    <>
      <YatungButton

        color="error"
        text={i18T('GLOBAL.CANCEL')}

        variant="contained"
        size="large"
        onClick={onGoBack}
        sx={{
          color: '#ffffff',
          fontSize: '36px',
          p: 2,
          mr: 5,
        }}
      />
      <YatungButton
        text={i18T('GLOBAL.FINISH')}
        variant="contained"
        color="blue"
        size="large"
        onClick={onSubmit}
        sx={{
          color: '#ffffff',
          fontSize: '36px',
          p: 2,
        }}
      />
    </>
  );
}
