import { TestItemEnum } from '@Src/_basic/object/TestType';
import Header from '@Src/app/module/layout/left/Header';
import { usePrintProduction } from '@Src/redux/printProduction/printProductionActions';
import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import moment from 'moment';
import React, { Ref, forwardRef, useCallback, useEffect } from 'react';

const background = '/media/images/printProduction.png';

const CoverSubTitle = styled(Typography)(() => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#fff',
}));

const CoverTitle = styled(Typography)(() => ({
  color: '#fff',
  marginLeft: '20px',
  borderBottom: '1px solid #fff',
  fontSize: '1.5rem',
  fontWeight: 800,
}));

const Title = styled(Typography)(() => ({
  fontSize: '1.5rem',
  fontWeight: 800,
  color: '#fff',
  textAlign: 'center',
}));

const SubTitle = styled(Typography)(() => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#000',
  padding: '0 1rem',
}));

const TableTitleCell = styled(TableCell)(() => ({
  fontSize: '1rem',
  fontWeight: 700,
  color: '#000',
  textAlign: 'center',
  padding: 5,
  border: '1px solid #000',
  borderCollapse: 'collapse',
}));

const TableBodyCell = styled(TableCell)(() => ({
  fontSize: '0.9rem',
  fontWeight: 500,
  color: '#000',
  padding: 1,
  border: '1px solid #000',
  borderCollapse: 'collapse',
}));

interface Props {
  ref: Ref<HTMLDivElement>;
  title: string;
  time: string;
}

const materialTraceabilityColumn = [
  { title: '原料', field: 'type' },
  { title: '規格', field: 'specification' },
  { title: '來源', field: 'bomName' },
];

const carbonDataColumn = [
  { title: '配比', field: 'proportion' },
  { title: '澆置位置', field: 'position' },
  { title: '澆置方數', field: 'count', props: { style: { textAlign: 'center' } } },
  { title: '產生碳排(kg)', field: 'total', props: { style: { textAlign: 'center' } } },
];

const concreteProps = { style: { textAlign: 'center', width: '8%', padding: '1px' } };

const concreteDataColumn = [
  { title: '受測車號', field: 'plateNumber', props: concreteProps },
  {
    title: '檢驗時間',
    field: 'samplingTtime',
    props: concreteProps,
    formatter: ({ row }: any) => {
      return (
        <Stack flexDirection="row" justifyContent="center">
          {moment(row.samplingTtime).format('HH:mm')}
        </Stack>
      );
    },
  },
  { title: '坍(流)度cm', field: 'actualSlump', props: concreteProps },
  { title: '氯離子含量', field: 'chlorideIonContent', props: concreteProps },
  { title: '7天(抗壓)', field: 'compStr7Days', props: concreteProps },
  { title: '28天(抗壓)', field: 'compStr28Days', props: concreteProps },
  {
    title: '備註',
    field: 'exptType',
    props: concreteProps,
    formatter: ({ row }: any) => {
      return (
        <>
          {row.exptType === TestItemEnum.CONCRETE_SLUMP_FLOW_TEST ? (
            <Stack flexDirection="column" alignItems="flex-start" sx={{ textAlign: 'center', paddingX: 1 }}>
              {row.fillingHeight ? (
                <>
                  <span>V型試驗 :</span>
                  <span style={{ marginLeft: 'auto' }}>流出時間 {row.flowTime}sec</span>
                </>
              ) : (
                <span> 無資料</span>
              )}
              {row.flowTime ? (
                <>
                  <span>U型試驗 :</span>
                  <span style={{ marginLeft: 'auto' }}>填充高度 {row.fillingHeight}cm</span>
                </>
              ) : (
                <span> 無資料</span>
              )}
            </Stack>
          ) : (
            <Typography sx={{ textAlign: 'center' }}> 無資料</Typography>
          )}
        </>
      );
    },
  },
  {
    title: '照片',
    field: 'previewUrl',
    formatter: ({ row }: any) => {
      return (
        <Stack flexDirection="row" justifyContent="center" sx={{ padding: '2px' }} gap={2}>
          {row.previewType === 'IMAGE' ? (
            <img src={row.previewUrl} style={{ objectFit: 'contain' }} width="100px" height="100px" />
          ) : (
            <Stack width="100px" height="100px" justifyContent="center" alignItems="center">
              <Typography>無資料</Typography>
            </Stack>
          )}
          <img src={row.chlContentPicUrl} style={{ objectFit: 'contain' }} width="100px" height="100px" />
        </Stack>
      );
    },
  },
];

const ProductionHeader = () => {
  return (
    <Stack
      sx={{
        backgroundColor: '#000E85',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 6,
        py: 1,
        mt: 4,
        borderRadius: '2rem 2rem 0 0 ',
      }}
    >
      <Typography sx={{ fontSize: 48, color: 'white', fontWeight: 700 }}>混凝土產品履歷</Typography>
      <Header />
    </Stack>
  );
};

const PrintProductionTable = ({ data, column }: any) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {column?.map((_column: any, index: number) => {
              if (typeof _column.title === 'object') {
                return (
                  <TableTitleCell
                    key={index}
                    {..._column.props}
                    colSpan={_column.title.subTitle.length}
                    sx={{ borderBottom: '1px solid #000' }}
                  >
                    {_column.title.title}
                  </TableTitleCell>
                );
              } else return <TableTitleCell key={index}>{_column.title}</TableTitleCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0
            ? data?.map((_data: any, index: number) => (
              <TableRow key={index}>
                {column?.map((_column: any, index: number) => {
                  if (_data[_column.field] === null) {
                    return <TableBodyCell key={index}></TableBodyCell>;
                  } else if (_data[_column.field] === undefined) {
                    return null;
                  } else if (_column.formatter) {
                    return <TableBodyCell key={index}>{_column.formatter({ row: _data })}</TableBodyCell>;
                  } else if (typeof _data[_column.field] === 'object') {
                    return (
                      <TableBodyCell key={index} {..._data[_column.field].props}>
                        {_data[_column.field].title}
                      </TableBodyCell>
                    );
                  } else
                    return (
                      <TableBodyCell key={index} {..._column.props}>
                        {_data[_column.field]}
                      </TableBodyCell>
                    );
                })}
              </TableRow>
            ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MyPrintComponent = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const {
    proportionPosition,
    amsAbnormalLegalData,
    setAmsAbnormalLegalData,
    concreteExptReportData,
    setConcreteExptReportData,
    bomData,
    setBomData,
    carbonData,
    setCarbonData,
  } = usePrintProduction();

  const PrintProductionHeader = ({ title, extraTitle }: { title: string; extraTitle?: string }) => {
    return (
      <TableHead>
        <TableRow sx={{ bgcolor: '#808080' }}>
          <Title>{title}</Title>
        </TableRow>
        <TableRow
          sx={{
            bgcolor: '#D9D9D9',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {extraTitle ? (
            <Stack>
              <SubTitle>案名 : {props.title}</SubTitle>
              <SubTitle sx={{ ml: 7 }}>{extraTitle}</SubTitle>
            </Stack>
          ) : (
            <SubTitle>案名 : {props.title}</SubTitle>
          )}

          <SubTitle>日期 : {props.time}</SubTitle>
        </TableRow>
      </TableHead>
    );
  };

  const carbonDataData = useCallback(() => {
    if (!carbonData || !proportionPosition) return [];

    const carbonDataSlice = carbonData.map((item) => {
      return {
        proportion: item.proportionName,
        position: proportionPosition[item.proportionName],
        count: item.qty,
        total: item.carbonEmission,
      };
    });

    const carbonTotalSum = carbonDataSlice?.reduce((sum, item) => sum + item.total, 0);

    return [
      ...carbonDataSlice,
      {
        proportion: {
          title: '合計產生碳排放量',
          props: { colSpan: 3, style: { backgroundColor: '#305496', color: '#fff', textAlign: 'center' } },
        },
        total: carbonTotalSum || '無資料',
      },
    ];
  }, [proportionPosition, carbonData]);

  const MaterialTraceability = () => {
    return (
      <TableContainer>
        <Table>
          <PrintProductionHeader title="混凝土原料溯源資料" />
          <TableBody>
            <PrintProductionTable column={materialTraceabilityColumn} data={bomData} />
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const CarbonData = () => {
    return (
      <TableContainer>
        <Table>
          <PrintProductionHeader title="混凝土供應產生碳排放數據資料" />
          <TableBody>
            <PrintProductionTable column={carbonDataColumn} data={carbonDataData()} />
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const TestReport = () => {
    return (
      <TableContainer>
        <Table>
          <PrintProductionHeader title="建築物結構用混凝土細粒料中電弧爐煉鋼爐碴(石)檢測報告書" />

          <TableBody>
            <TableRow sx={{ display: 'flex', flexDirection: 'column' }}>
              <TableBodyCell>
                建造執造號碼<span style={{ marginLeft: '30px' }}>:</span>
              </TableBodyCell>
              <TableBodyCell>
                雜項執照號碼<span style={{ marginLeft: '30px' }}>:</span>
              </TableBodyCell>
              <TableBodyCell
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <span>
                  混凝土澆置時間<span style={{ marginLeft: '15px' }}>:</span>
                </span>
                <span>年</span>
                <span>月</span>
                <span>日</span>
                <span>時</span>
              </TableBodyCell>
              <TableBodyCell>
                混凝土澆置位置<span style={{ marginLeft: '15px' }}>:</span>
              </TableBodyCell>
              <TableBodyCell
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <span style={{ letterSpacing: '10px' }}>
                  檢測時間
                  <span style={{ marginLeft: '12px' }}>:</span>
                </span>
                <span>年</span>
                <span>月</span>
                <span>日</span>
                <span>時</span>
              </TableBodyCell>
              <TableBodyCell sx={{ display: 'flex', flexDirection: 'row' }}>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', width: '30%' }}>
                  <span>檢測方法</span>
                  <span>(含內政部認可方號)</span>
                </Stack>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', width: '70%' }}>
                  <span>AMS砂石爐渣快篩檢測法</span>
                  <span>(內授營建管字第10908188681號)</span>
                </Stack>
              </TableBodyCell>

              {amsAbnormalLegalData && Object.keys(amsAbnormalLegalData).length > 0 ? (
                amsAbnormalLegalData.abnormalLegal ? null : (
                  <TableBodyCell sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Stack
                      sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '30%' }}
                    >
                      <span>* </span>
                      <span style={{ letterSpacing: '10px', paddingLeft: '20px' }}>檢測結果:</span>
                    </Stack>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs={11} style={{ borderBottom: '1px solid #000', width: '100%' }}>
                        <span>酚酞指示劑噴滴結果，顯示( □ 有 / ■ 無) 變色，判定為 ( ■ 合格 / □ 不合格 ) : </span>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={11} style={{ borderBottom: '1px solid #000', width: '100%' }}>
                        <span>{`磁鐵吸引結果( □ 有 / ■ 無) 磁吸，有磁吸者退料處理，無磁吸者進行pH檢測， pH值 : ${amsAbnormalLegalData.beforeRinsePhScale} `}</span>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={11} style={{ borderBottom: '1px solid #000', width: '100%' }}>
                        <span>{`若pH - 9~10之間辦理異常管理，水洗後pH值: ${amsAbnormalLegalData.afterRinsePhScale ? amsAbnormalLegalData.afterRinsePhScale : ''
                          } ，判定為 ( ■ 合格/ □ 不合格 ) `}</span>
                      </Grid>

                      <Grid item xs={1} />
                      <Grid item xs={11} style={{ borderBottom: '1px solid #000', width: '100%' }}>
                        <span>
                          若鹼質試驗 ( A ) 與磁吸試驗 ( M ) 皆合格成立時，免做熱壓膨脹試驗 ( S ) ，故本檢測結果為合格 。
                        </span>
                      </Grid>
                    </Grid>
                    <Stack>
                      <span style={{ letterSpacing: '2.5px' }}>
                        {`* 茲證明依建築物結構用混凝土細粒料中電弧爐煉鋼爐碴(石)檢測實施要點取樣檢測之混凝土`}
                      </span>
                      <span style={{ marginLeft: '15px', letterSpacing: '2.5px' }}>
                        {`係使用於上述工地，其檢測結果如上表無誤，並負擔保責任`}
                      </span>
                    </Stack>
                  </TableBodyCell>
                )
              ) : null}

              <TableBodyCell sx={{ display: 'flex', flexDirection: 'column', py: 5, pr: 10, pl: 2 }}>
                <Grid container spacing={10}>
                  <Grid item xs={4}>
                    <span>* 檢測人員 ( 簽 章 ) : </span>
                  </Grid>
                  <Grid item xs={8} style={{ borderBottom: '1px solid #000' }} />
                  <Grid item xs={4}>
                    <span>訓練結業證書字號 : </span>
                  </Grid>
                  <Grid item xs={8} style={{ borderBottom: '1px solid #000' }} />
                  <Grid item xs={4}>
                    <span>混凝土供應者 : </span>
                  </Grid>
                  <Grid item xs={8} style={{ borderBottom: '1px solid #000' }} />
                </Grid>
              </TableBodyCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const ConcreteData = ({ position, proportion, displayName }: { position: string; proportion: string, displayName: string }) => {
    if (!concreteExptReportData) return null;
    console.log(proportion, JSON.stringify(concreteExptReportData));
    return (
      <TableContainer>
        <Table>
          <PrintProductionHeader
            title="混凝土檢驗數據報告"
            extraTitle={`澆置位置 : ${position} / 配比編號 : ${displayName}`}
          />
          <TableBody>
            <PrintProductionTable column={concreteDataColumn} data={concreteExptReportData[proportion]} />
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  useEffect(() => {
    return () => {
      setAmsAbnormalLegalData(undefined);
      setConcreteExptReportData({});
      setBomData(undefined);
      setCarbonData([]);
    };
  }, []);

  return (
    <Stack ref={ref}>
      <Stack
        height="100vh"
        flexDirection="row"
        justifyContent="center"
        alignItems="flex-end"
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          objectFit: 'contain',
          backgroundSize: 'contain',
        }}
      >
        <Stack flexDirection="row" gap={5} mb={25} alignItems="cneter">
          <Stack flexDirection="column" justifyContent="center" gap={5}>
            <CoverSubTitle>工程案名稱:</CoverSubTitle>
            <CoverSubTitle>預拌混凝土供應商:</CoverSubTitle>
          </Stack>
          <Stack flexDirection="column" justifyContent="flex-start" gap={5}>
            <CoverTitle>{props.title}</CoverTitle>
            <CoverTitle>亞東預拌混凝土股份有限公司</CoverTitle>
          </Stack>
        </Stack>
      </Stack>

      {[
        <>
          <ProductionHeader />
          <MaterialTraceability />
          <div style={{ height: '5px' }}></div>
          <CarbonData />
        </>,
        <>
          <TestReport />
        </>,
      ].map((item, index) => (
        <Stack key={index} px={5} height={'100vh'} justifyContent="center">
          {item}
        </Stack>
      ))}

      {proportionPosition &&
        Object.entries(proportionPosition).map(([key, value]) => {
          return (
            <Stack key={key} px={5} height={'100vh'} justifyContent="center">
              <ConcreteData position={value.position} proportion={value.formulaName} displayName={value.displayName} />
            </Stack>
          );
        })}
    </Stack>
  );
});

MyPrintComponent.displayName = 'MyPrintComponent';

export default MyPrintComponent;
