import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import YatungFormSelect from '@Src/_basic/components/YatungFormSelect';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { Options } from '@Src/_basic/components/YatungSelect';
import { UpdateInitialStorageYard } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { SearchSpecificationsParams, SpecificationData } from '@Src/_basic/object/SpecificationType';
import { SearchTypeParams, TypeData } from '@Src/_basic/object/TypeType';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  values: UpdateInitialStorageYard;
  selectedFactory: number;
}
export default function StorageYardUpdateBody(props: Props) {
  const { t: i18T } = useTranslation();
  const { values, selectedFactory } = props;
  const [types, setTypes] = useState<Options[]>([]);
  const [specification, setSpecification] = useState<Options[]>([]);
  const getTypeRelatedOptions = (factoryId: number, typeId: number) => {
    const searchParams: SearchTypeParams = { factoryId };
    const searchSpecificationsParams: SearchSpecificationsParams = { factoryId, typeId };
    TypeApi.getTypesBySearch(searchParams, getTypesOptions);
    SpecificationApi.getSpecificationsBySearch(searchSpecificationsParams, SpecificationsToOptions);
  };

  const getTypesOptions = (data: Array<TypeData>) => {
    // const allTypesRemoveSome = data.filter(
    //   (value: TypeData) => value.id !== 9 && value.id !== 11 && value.name !== '拌合水',
    // );
    const typesOptions = data.map((type: TypeData) => ({ value: type.id, text: type.name }));
    setTypes(typesOptions);
  };
  const SpecificationsToOptions = (data: Array<SpecificationData>) => {
    const specificationsOptions = data.map((spec: SpecificationData) => ({ value: spec.id, text: spec.name }));
    setSpecification(specificationsOptions);
  };
  // const resetFactoryRelatedSetting = () => {
  //   setValues('specificationId', 0);
  //   setSpecification([]);
  // };
  useEffect(() => {
    // resetFactoryRelatedSetting();
    if (selectedFactory > 0) {
      getTypeRelatedOptions(selectedFactory, values.typeId);
    }
  }, [selectedFactory, values.typeId]);
  const textStyle = {
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#7C878E',
    my: 1,
    ml: 3,
  };
  const symbolSmall = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#7C878E',
    ml: 3,
  };
  const symbolBig = {
    fontSize: 36,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#7C878E',
    ml: 3,
  };
  return (
    <>
      <Grid container justifyContent={'flex-start'} item xs={12}>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.TYPE_SETTING')} sx={textStyle} />
        </Grid>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.FEEDTROUGH_NAME')} sx={textStyle} />
        </Grid>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.DEFINITION_NAME')} sx={textStyle} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={12} sx={{ ml: 2 }}>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <Field
            id="typeId"
            name="typeId"
            type="number"
            label=""
            placeholder={i18T('GLOBAL.NOT_SETTING')}
            component={YatungFormSelect}
            sx={{ width: '90%' }}
            options={types}
            required
          />
        </Grid>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={values.code} sx={textStyle} />
        </Grid>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <Field
            id="name"
            name="name"
            type="text"
            label=""
            placeholder={i18T('GLOBAL.NOT_SETTING')}
            component={YatungFormInput}
            sx={{ width: '90%' }}
            required
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 1 }}>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.ADD_SPEFICATIONS')} sx={textStyle} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12} sx={{ ml: 2 }}>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <Field
            id="specificationId"
            name="specificationId"
            type="number"
            label=""
            component={YatungFormSelect}
            sx={{ width: '90%' }}
            disabled={values.typeId === 0}
            options={specification}
            required
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 1 }}>
        <Grid container justifyContent={'flex-start'} item xs={6}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.STORAGE_YARD_WEIGHT')} sx={textStyle} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12} sx={{ ml: 2 }}>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container justifyContent={'center'} item xs={10}>
            <Field
              id="capacity"
              name="capacity"
              type="number"
              label=""
              placeholder={i18T('GLOBAL.NOT_SETTING')}
              component={YatungFormInput}
              sx={{ width: '100%' }}
              required
            />{' '}
          </Grid>
          <Grid container justifyContent={'center'} item xs={2}>
            <YatungPaperLabel sx={symbolSmall} label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.KG')} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
