import React from 'react';
import { AppPermissionRequest } from '@Src/_basic/object/AppPermissionType';
import { Typography } from '@mui/material';
import moment from 'moment';

interface Props {
  row: AppPermissionRequest;
}
export default function ApplicationTimeColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{moment(row.applicationTime).format('YYYY-MM-DD HH:mm:ss')}</Typography>;
}
