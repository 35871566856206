import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import YatungDymanicTabs from '@Src/_basic/components/YatungDymanicTabs';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import LeftArrow from '@Src/_basic/icons/LeftArrow';
import RightArrow from '@Src/_basic/icons/RightArrow';
import { AreaData, AreaOptions } from '@Src/_basic/object/AreaType';
import { FactoryData, FactoryItem } from '@Src/_basic/object/FactoryType';
import { CreateMixerData, MixerData, MixerNameTab } from '@Src/_basic/object/MixerTypes';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import { Box, Grid, Paper, SelectChangeEvent, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateMixerDetailsSettings from './components/CreateMixerDetailsSettings';
import CreateMixerInput from './components/CreateMixerInput';

interface Props {
  proportionOptions: Options[];
}
export default function MixerInformationCreateForm(props: Props) {
  const { t: i18T } = useTranslation();
  const { proportionOptions } = props;
  const history = useNavigate();
  const handleGoBack = () => {
    history(-1);
  };
  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState<AreaOptions[]>([]);
  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [factories, setFactories] = useState<Options[]>([]);
  const [selectedFactory, setSelectedFactory] = useState<number>(0);
  const [waterDemand, setWaterDemand] = useState<number>(0);
  const [createMixer, setCreateMixer] = useState<Array<CreateMixerData>>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [mixerName, setMixerName] = useState<Array<MixerNameTab>>([]);
  const [storageType, setStorageType] = useState<string>('notIs_storage');

  const handleWaterDemandInput = (e: SelectChangeEvent) => {
    setWaterDemand(parseFloat(e.target.value));
  };
  const handleChange = (event: any, newValue: number) => {
    setTabValue(newValue);
  };
  const handleSubmit = () => {
    for (let i = 0; i < createMixer.length; i++) {
      delete createMixer[i].id;
    }
    const data = {
      areaId: selectedArea,
      factoryId: selectedFactory,
      waterAbsorption: waterDemand,
      mixers: createMixer,
      isYardMixerActive: storageType === 'is_storage' ? true : false,
    };
    MixerApi.putMixerData(
      data,
      (data: MixerData[]) => {
        history(-1);
      },
      undefined,
      undefined,
    );
  };
  const getAllAreasData = () => {
    AreaApi.getAllAreas((allAreas: Array<AreaData>) => {
      const areaOptions: AreaOptions[] = allAreas.map((allArea: AreaData) => ({
        value: allArea.id,
        text: allArea.name,
        factories: allArea.factoryList.map((factory: FactoryItem) => ({
          value: factory.factoryId,
          text: factory.factoryName,
        })),
      }));
      setAreas(areaOptions);
    });
  };
  const handleAreaSelectChange = (area: number) => {
    handleReset();
    FactoryApi.getFactoryWithoutMixer(area, (allFactory: FactoryData[]) => {
      const factoryOptions = allFactory.map((factory: FactoryData) => ({
        value: factory.id,
        text: factory.factoryName,
      }));
      setFactories(factoryOptions);
    });
    setSelectedArea(area);
  };

  const handleReset = () => {
    setLoading(false);
    setSelectedFactory(0);
    setCreateMixer([]);
  };
  const handleResetFactory = () => {
    setCreateMixer([]);
  };

  const handleMixerCreateElement = () => {
    const objectData = {
      id: Math.random(),
      mixerName: '',
      storages: [],
    };
    const newDatas = Object.assign([], createMixer);
    newDatas.push(objectData);
    setCreateMixer(newDatas);
  };
  const getMixerValue = () => {
    const mixerValue = createMixer.map((value: CreateMixerData, index: number) => {
      const data = {
        id: index,
        name: value.mixerName === '' ? '未命名' : value.mixerName,
      };
      return data;
    });
    setMixerName(mixerValue);
  };
  useEffect(() => {
    getAllAreasData();
  }, []);
  useEffect(() => {
    handleReset();
  }, [selectedArea]);
  useEffect(() => {
    handleResetFactory();
  }, [selectedFactory]);
  useEffect(() => {
    getMixerValue();
  }, [createMixer]);

  const [startIndex, setStartIndex] = useState(0);
  const batchSize = 5;
  const handleNext = () => {
    if (startIndex < mixerName.length - 1) {
      setStartIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex((prev) => prev - 1);
    }
  };
  const handleInputChange = (key: number, _value: string) => {
    const updatedArray = createMixer.map((data: CreateMixerData) => {
      if (data.id === key) {
        return { ...data, mixerName: _value };
      }
      return data;
    });
    setCreateMixer(updatedArray);
  };
  const handleDeleteInput = (key: number) => {
    const deleteCreateMixerById = createMixer.filter((data: CreateMixerData) => data.id !== key);
    setCreateMixer(deleteCreateMixerById);
    if (startIndex !== 0) {
      setStartIndex(startIndex - 1);
    }
    if (tabValue > 0) {
      setTabValue(tabValue - 1);
    }
  };
  const handleStorageType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStorageType(e.target.value);
  };
  return (
    <>
      <Grid container item xs={12}>
        <Grid container item xs={5}>
          <Grid container item xs={12} sx={{ mt: 3 }}>
            <Grid container item xs={3}>
              <YatungFormLabel label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.AREA')} />
            </Grid>
            <Grid container item xs={9}>
              <YatungSelect
                disabled={loading}
                options={areas}
                value={selectedArea}
                onChange={(e) => handleAreaSelectChange(e.target.value as number)}
                sx={{ width: '260px' }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ my: 3 }}>
            <Grid container item xs={3}>
              <YatungFormLabel label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.FACTORY')} />
            </Grid>
            <Grid container item xs={9}>
              <YatungSelect
                disabled={selectedArea === 0 || loading}
                options={factories}
                value={selectedFactory}
                onChange={(e: SelectChangeEvent<unknown>) => setSelectedFactory(e.target.value as number)}
                sx={{ width: '260px' }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ my: 3 }}>
            <Grid container item xs={3}>
              <YatungFormLabel label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.WATER_DEMAND_RATE')} />
            </Grid>
            <Grid container item xs={9}>
              <YatungInput onBlur={handleWaterDemandInput} sx={{ width: '260px' }} />
            </Grid>
          </Grid>
          <Grid container item xs={5} sx={{ my: 3, ml: 1 }}>
            <YatungRadioButton
              label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.STORAGE_YARD')}
              onChange={handleStorageType}
              value={storageType}
              groupName={'order-group'}
              controlData={[
                {
                  label: i18T('GLOBAL.YES'),
                  value: 'is_storage',
                },
                {
                  label: i18T('GLOBAL.NOT'),
                  value: 'notIs_storage',
                },
              ]}
            />
          </Grid>
          <YatungDivider />
          <Grid container item xs={12} sx={{ my: 3 }}>
            <YatungButton
              disabled={selectedFactory === 0}
              text={i18T('USERSMANAGEMENU.MIXER_INFORMATION.CREATE_MIXER')}
              onClick={handleMixerCreateElement}
              color="green"
              sx={{ mr: 2 }}
            />
          </Grid>
          <Grid container item xs={12} sx={{ my: 3 }}>
            {createMixer.map((data: CreateMixerData, index: number) => {
              return (
                <CreateMixerInput
                  key={data.id}
                  index={index}
                  inuptValue={data.mixerName ? data.mixerName : ''}
                  onInput={(_value: string) => {
                    handleInputChange(data.id, _value);
                  }}
                  onDeleteInput={() => {
                    handleDeleteInput(data.id);
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
        {createMixer.length > 0 && (
          <Grid container item xs={7} justifyContent={'flex-start'}>
            <Box
              sx={{
                backgroundSize: 'cover',
                height: '100%',
                backgroundColor: '#F2F2F2',
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  height: '100%',
                  position: 'relative',
                }}
              >
                <Grid container item xs={12} justifyContent={'flex-end'}>
                  {startIndex !== 0 && createMixer.length > 5 && (
                    <YatungButton
                      onClick={handlePrev}
                      color="blue"
                      text={<LeftArrow sx={{ width: '32px', height: '32px' }} />}
                    />
                  )}
                  <YatungDymanicTabs
                    tabs={mixerName.slice(startIndex, startIndex + batchSize)}
                    selectedIndex={tabValue}
                    onChange={handleChange}
                    fontSize={20}
                  />
                  {!(startIndex + batchSize >= createMixer.length) && createMixer.length > 5 && (
                    <YatungButton
                      onClick={handleNext}
                      color="blue"
                      text={<RightArrow sx={{ width: '32px', height: '32px' }} />}
                    />
                  )}
                </Grid>
                {createMixer.map((value: CreateMixerData, index: number) => {
                  return (
                    <Grid container item xs={12} justifyContent={'flex-end'} key={value.id}>
                      <>
                        {mixerName.length > 0 && index === tabValue && (
                          <CreateMixerDetailsSettings
                            value={value}
                            index={index}
                            createMixer={createMixer}
                            setCreateMixer={setCreateMixer}
                            selectedFactory={selectedFactory}
                            proportionOptions={proportionOptions}
                          />
                        )}
                      </>
                    </Grid>
                  );
                })}
              </Paper>
            </Box>
          </Grid>
        )}
      </Grid>
      <Stack justifyContent="flex-end" flexDirection="row" sx={{ pr: 4, mt: 5 }}>
        <YatungButton
          text={i18T('GLOBAL.CANCEL')}
          color="error"
          variant="contained"
          size="large"
          onClick={handleGoBack}
          sx={{
            color: '#ffffff',
            fontSize: '36px',
            p: 2,
            mr: 5,
          }}
        />
        <YatungButton
          disabled={selectedArea === 0 || selectedFactory === 0 || !waterDemand}
          text={i18T('GLOBAL.FINISH')}
          color="blue"
          variant="contained"
          size="large"
          onClick={handleSubmit}
          sx={{
            color: '#ffffff',
            fontSize: '36px',
            p: 2,
          }}
        />
      </Stack>
    </>
  );
}
