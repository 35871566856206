import { Response } from '@Src/_basic/object/ApiType';
import {
  CreateEntityRequest,
  DeleteEntityRequest,
  EntityData,
  SearchEntityParams,
  UpdateEntityRequest,
} from '@Src/_basic/object/EntityType';
import { GetAllName, SearchPaginationUserRequest } from '@Src/_basic/object/EquipmentManagementType';
import axios from 'axios';
import { Api } from '../Api';
import { EntityProtocol } from './EntityProtocol';

export class EntityApi {
  public static getEntityData(
    params?: SearchEntityParams,
    onSuccess?: (data: EntityData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(EntityProtocol.GET_PAGINATION_ALL_NAME_ENTITIES, config, onSuccess, onFailed, onFinally);
  }

  public static GetKeeper(
    params?: GetAllName,
    onSuccess?: (data: SearchPaginationUserRequest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    if (params?.factoryId === 0) {
      const paramsTemp = { ...params, factory_id: null };
      const config = {
        headers: {
          Cancelable: true,
        },
        params: paramsTemp,
      };
      Api.get(EntityProtocol.GET_USER, config, onSuccess, onFailed, onFinally);
    } else {
      const config = {
        headers: {
          Cancelable: true,
        },
        params,
      };
      Api.get(EntityProtocol.GET_USER, config, onSuccess, onFailed, onFinally);
    }
  }

  public static CreateEntity(
    request: CreateEntityRequest,
    onSuccess?: (data: CreateEntityRequest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(EntityProtocol.CREATE_ENTITY, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static UpdateEntity(
    request: UpdateEntityRequest,
    onSuccess?: (data: UpdateEntityRequest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(EntityProtocol.UPDATE_ENTITY, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static DeleteEntity(
    id: number,
    onSuccess?: (data: DeleteEntityRequest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(EntityProtocol.DELETE_ENTITY, config, onSuccess, onFailed, onFinally);
  }

  public static GetEntityById(id: number) {
    const params = {
      params: {
        id: id,
      },
    };
    return axios.get(EntityProtocol.GET_ENTITY_BY_ID, params).then((res) => {
      return res.data;
    });
  }
}
