import { Grid, Stack } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import FolderTree from 'react-folder-tree';
import 'react-folder-tree/dist/style.css';
import { useTranslation } from 'react-i18next';
import TestDetailDialog from './YatungTest/testDetail/TestDetailDialog';

export default function YatungFolderTree({ smapleData }: any) {
  const [sampleByIdData, setSampleByIdData] = React.useState<any>(undefined);
  const [open, setOpen] = useState(false);

  const onTreeStateChange = (state: any, event: any) => console.log(state, event);

  const { t: i18T } = useTranslation();

  const openHandler = useCallback((row: any) => {
    console.log('row', row.nodeData.name);
    if (row.nodeData.name === '樣品歷程') {
      setOpen(false);
    } else {
      //點擊樣品歷程的歷史紀錄
      setSampleByIdData(row);
      setOpen(true);
    }
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setSampleByIdData(undefined);
  }, []);

  const tableData = useMemo(() => {
    return smapleData?.map((item: any, index: number) => {
      //轉換成樹狀圖的格式，並將英文試驗exptItemCode轉成中文
      //children為下一層的樣品歷程
      const enhancedItem = {
        ...item,
        name: i18T(`TEST.TEST_ITEM.${item.exptItemCode}`) + `_${moment(item.createTime).format('YYYY/MM/DD HH:mm')}`,
      };

      // Check if 'nextExpt' property exists and is an array
      if (item.nextExpt && Array.isArray(item.nextExpt)) {
        // Map over 'nextExpt' array and enhance each item
        enhancedItem.children = item.nextExpt.map((nextItem: any) => ({
          ...nextItem,
          name:
            i18T(`TEST.TEST_ITEM.${nextItem.exptItemCode}`) +
            `_${moment(nextItem.createTime).format('YYYY/MM/DD HH:mm')}`,
        }));
      }

      return enhancedItem;
    });
  }, [smapleData]);

  const jsonFile = {
    //符合react-folder-tree的格式
    children: tableData,
    name: i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.TITLE'),
  };

  return (
    <div
      style={{ border: '1px solid gray', borderRadius: '10px', marginTop: '10px', marginLeft: '10px', width: '50%' }}
    >
      <Grid>
        <Stack
          sx={{
            color: '#ffffff',
            bgcolor: '#003087',
            fontSize: '20px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            padding: '5px',
            width: ' 100%',
            '&.MuiButtonBase-root:hover': {
              bgcolor: '#003087',
            },
          }}
        >
          {i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.HISTORY_RECORD')}
        </Stack>
      </Grid>
      <FolderTree
        data={jsonFile}
        onChange={onTreeStateChange}
        showCheckbox={false}
        onNameClick={(e: any) => openHandler(e)} //點擊樣品歷程的歷史紀錄
        readOnly //唯讀
      />
      {open && (
        <TestDetailDialog
          exptItemCode={sampleByIdData?.nodeData?.exptItemCode}
          exptId={sampleByIdData?.nodeData?.exptId}
          open={open}
          closeHandler={closeHandler}
          allTable={true}
        />
      )}
    </div>
  );
}
