import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import ExptScheduleSelect, { Options } from '../../ExptScheduleSelect';
import ExptScheduleCard from '../../ExptScheduleCard';
import Expt from '../../../icons/Expt';
import Location from '../../../icons/Location';
import Storage from '../../../icons/Storage';
import Source from '../../../icons/Source';
import Paper from '../../../icons/Paper';
import { TestTypeApi } from '@Src/_basic/protocol/testType/TestTypeApi';
import { TestTypeData } from '@Src/_basic/object/TestTypeType';
import { SampleSourceApi } from '@Src/_basic/protocol/sampleSource/SampleSourceApi';
import { SampleSourceData } from '@Src/_basic/object/SampleSourceType';
import { StorageApi } from '@Src/_basic/protocol/storage/StorageApi';
import { StorageData } from '@Src/_basic/object/StorageType';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { SupplierData } from '@Src/_basic/object/SupplierType';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { SpecificationData } from '@Src/_basic/object/SpecificationType';
import { ChemType } from '@Src/_basic/object/SpecimenType';
import { SourceApi } from '@Src/_basic/protocol/source/SourceApi';
import { SourceData } from '@Src/_basic/object/SourceType';
import { PeriodTypeEnum } from '@Src/_basic/object/ExptScheduleType';

interface Props {
  factoryId: number;
  isRequirement: string;
  exptTypeId: number;
  specificationId: number | undefined;
  sourceId: number | undefined;
  sampleSourceId: number | undefined;
  storageId: number | undefined;
  setFieldValue: (field: string, value: any) => void;
}

const Title = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '24px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '1.35em',
}));

export default memo(function RequirementCard(props: Props) {
  // TODO using i18n

  const [testType, setTestType] = useState<Array<TestTypeData>>();
  const [testTypeOptions, setTestTypeOptions] = useState<Array<Options>>([]);
  const [sampleSourceOptions, setSampleSourceOptions] = useState<Array<Options>>([]);
  const [gTypeSpecificationOptions, setGTypeSpecificationOptions] = useState<Array<Options>>([]);
  const [fluidifySpecificationOptions, setFluidifySpecificationOptions] = useState<Array<Options>>([]);
  const [supplierOptions, setSupplierOptions] = useState<Array<Options>>([]);
  const [specificationOptions, setSpecificationOptions] = useState<Array<Options>>([]);
  const [sourceOptions, setSourceOptions] = useState<Array<Options>>([]);
  const [storageOptions, setStorageOptions] = useState<Array<Options>>([]);

  const getTestTypeOptions = useCallback(() => {
    TestTypeApi.getAllTestType((data: Array<TestTypeData>) => {
      setTestType(data);
      setTestTypeOptions(data.map((item) => ({ text: item.typeName, value: item.id })));
    });
  }, []);

  const getSampleSourceOptions = useCallback(() => {
    SampleSourceApi.getSampleSourceBySearch({ factoryId: props.factoryId }, (data: Array<SampleSourceData>) => {
      setSampleSourceOptions(
        data.filter((item) => item.isAnalysis).map((item) => ({ text: item.name, value: item.id })),
      );
    });
  }, [props.factoryId]);

  const getStorageOptions = useCallback(() => {
    StorageApi.getStoragesBySearch({ factoryId: props.factoryId }, (data: Array<StorageData>) => {
      setStorageOptions(data.map((item) => ({ text: `${item?.group} ${item?.name}`, value: item.id })));
    });
  }, [props.factoryId]);

  const getSupplierOptions = useCallback(() => {
    SupplierApi.getSuppliersBySearch({ factoryId: props.factoryId }, (data: Array<SupplierData>) => {
      setSupplierOptions(data.map((item) => ({ text: item.name, value: item.id })));
    });
  }, [props.factoryId]);

  const getGTypeSpecificationOptions = useCallback(() => {
    SpecificationApi.getChemTypeSpecificationBySearch(
      { factoryId: props.factoryId, attrTypeCode: ChemType.G_TYPE },
      (data: Array<SpecificationData>) => {
        setGTypeSpecificationOptions(data.map((item) => ({ text: item.name, value: item.id })));
      },
    );
  }, [props.factoryId]);

  const getFluidifySpecificationOptions = useCallback(() => {
    SpecificationApi.getChemTypeSpecificationBySearch(
      { factoryId: props.factoryId, attrTypeCode: ChemType.FLUIDIFIER },
      (data: Array<SpecificationData>) => {
        setFluidifySpecificationOptions(data.map((item) => ({ text: item.name, value: item.id })));
      },
    );
  }, [props.factoryId]);

  const getSpecificationOptions = useCallback(() => {
    if (testType && testType.length) {
      const materialIds = testType
        .find((value) => value.id === props.exptTypeId)
        ?.types.reduce((prev, current) => {
          prev.push(current.id);
          return prev;
        }, [] as Array<number>);

      SpecificationApi.getSpecificationsBySearch(
        { factoryId: props.factoryId, typeId: materialIds },
        (data: Array<SpecificationData>) => {
          setSpecificationOptions(data.map((item) => ({ text: item.name, value: item.id })));
        },
      );
    }
  }, [testType, props.factoryId, props.exptTypeId]);

  const getSourceOptions = useCallback(() => {
    SourceApi.getSourcesBySearch(
      { factoryId: props.factoryId, typeId: props.exptTypeId },
      (data: Array<SourceData>) => {
        setSourceOptions(data.map((item) => ({ text: item.sourceName, value: item.id })));
      },
    );
  }, [props.factoryId, props.exptTypeId]);

  const header = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', direction: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }} gap="12px">
          <Paper />
          <Title>需求單</Title>
        </Box>
      </Box>
    );
  }, []);

  const renderExptTypeSelect = useMemo(() => {
    return (
      <ExptScheduleSelect
        title="試驗類型："
        icon={<Expt />}
        options={testTypeOptions}
        value={props.exptTypeId}
        onChange={(e) => {
          props.setFieldValue('exptTypeId', e.target.value);
        }}
      />
    );
  }, [testTypeOptions, props.exptTypeId]);

  const renderSpecificationSelect = useMemo(() => {
    if (props.exptTypeId > 0) {
      return (
        <ExptScheduleSelect
          title="規格："
          icon={<Expt />}
          options={specificationOptions}
          value={props.specificationId}
          onChange={(e) => {
            props.setFieldValue('specificationId', e.target.value);
          }}
        />
      );
    }
  }, [props.exptTypeId, specificationOptions, props.specificationId]);

  const renderSourceSelect = useMemo(() => {
    if (props.specificationId && props.specificationId > 0) {
      return (
        <ExptScheduleSelect
          title="來源："
          icon={<Source />}
          options={sourceOptions}
          value={props.sourceId}
          onChange={(e) => {
            props.setFieldValue('sourceId', e.target.value);
          }}
        />
      );
    }
  }, [props.specificationId, sourceOptions, props.sourceId]);

  const renderSampleSelect = useMemo(() => {
    if (props.specificationId) {
      return (
        <ExptScheduleSelect
          title="取樣位置："
          icon={<Location />}
          options={sampleSourceOptions}
          value={props.sampleSourceId}
          onChange={(e) => {
            props.setFieldValue('sampleSourceId', e.target.value);
          }}
        />
      );
    }
  }, [props.specificationId, sampleSourceOptions, props.sampleSourceId]);

  const renderStorageSelect = useMemo(() => {
    if (props.specificationId) {
      return (
        <ExptScheduleSelect
          title="料庫："
          icon={<Storage />}
          options={storageOptions}
          value={props.storageId}
          onChange={(e) => {
            props.setFieldValue('storageId', e.target.value);
          }}
        />
      );
    }
  }, [props.specificationId, storageOptions, props.storageId]);

  useEffect(() => {
    getTestTypeOptions();
  }, [getTestTypeOptions]);

  useEffect(() => {
    getSampleSourceOptions();
    getStorageOptions();
    getSupplierOptions();
    getGTypeSpecificationOptions();
    getFluidifySpecificationOptions();
  }, [getSampleSourceOptions, getStorageOptions, getSupplierOptions, getGTypeSpecificationOptions, getFluidifySpecificationOptions]);

  useEffect(() => {
    getSourceOptions();
    getSpecificationOptions();
  }, [getSourceOptions, getSpecificationOptions]);

  return (
    <ExptScheduleCard
      header={header}
      body={
        <Box sx={{ padding: '24px 41px' }}>
          <Stack gap="20px">
            {renderExptTypeSelect}
            {renderSpecificationSelect}
            {renderSourceSelect}
            {renderSampleSelect}
            {renderStorageSelect}
          </Stack>
        </Box>
      }
    />
  );
});
