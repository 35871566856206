import { Box, Card, CardContent, Grid, Pagination, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import CreateIcon from '@Src/_basic/icons/CreateV2';

import FormulaCard from '../../components/FormulaCard';
import RowContent from '../../components/RowContent';
import SortPanel from '../../components/SortPanel';
import TagsSelectPanel from '../../components/TagsSelectPanel';
import { sortItems } from '../ProportionList';

import YatungLoading from '@Src/_basic/components/YatungLoading';
import CancelIcon from '@Src/_basic/icons/Cancel';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import {
  GetSearchProportionRequest,
  ProportionResponse,
  SearchProportionResponse,
} from '@Src/_basic/object/ProportionType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import { TagApi } from '@Src/_basic/protocol/tag/TagApi';
import { useApi } from '@Src/redux/api/apiAction';
import nowImg from './now.png';

const initPageInfo = {
  page: 1,
  pageSize: 6,
  total: 0,
  totalCount: 0,
};

export default function Proportion() {
  const navigate = useNavigate();
  const { actionLoading } = useApi();

  const [currentVersion, setCurrentVersion] = useState<ProportionResponse>();
  const [selectTag, setSelectTag] = useState<Array<Options>>([]); //選擇標籤
  const [sort, setSort] = useState<string>('annualShipmentCount');

  const [pageInfo, setPageInfo] = useState<PageInfo>(initPageInfo);

  const [proportions, setProportions] = useState<SearchProportionResponse>();

  const [filterSearchRequest, setFilterSearchRequest] = useState<GetSearchProportionRequest>();

  const [tagItems, setTagItems] = useState<Array<Options>>([]);

  const { t: i18T } = useTranslation();
  const { propName, factoryId } = useParams();

  const handlerSearchProportion = useCallback((request: GetSearchProportionRequest) => {
    ProportionApi.getSearchProportion(request, (_data) => {
      setProportions(_data);
      setPageInfo((prev) => ({
        ...prev,
        total: _data.maxPage,
      }));
      setFilterSearchRequest(request);
    });
  }, []);

  const handlerSearchCurProportion = useCallback(() => {
    if (!factoryId) return;

    ProportionApi.getSearchProportion(
      {
        page: 1,
        pageSize: 6,
        propName: propName,
        isCurrVer: true,
        factoryId: parseInt(factoryId),
        removed: false,
        sort,
      },
      (_data) => {
        const currentVersion = _data.proportions[0];
        const tags = currentVersion.tags?.reduce(
          (prev, curr) => [...prev, { text: curr.tagName, value: curr.id }],
          [] as Array<Options>,
        );
        setSelectTag(tags);
        setCurrentVersion(currentVersion);
      },
    );
  }, [factoryId, propName, sort]);

  const handlerSaveTags = useCallback(() => {
    if (!filterSearchRequest || !proportions) return;

    ProportionApi.putEditProportionTags(
      {
        id: proportions.proportions[0].id,
        tagIds: selectTag.map((item) => item.value),
      },
      () => {
        handlerSearchProportion(filterSearchRequest);
        handlerSearchCurProportion();
      },
    );
  }, [proportions, selectTag, filterSearchRequest, handlerSearchProportion, handlerSearchCurProportion]);

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      if (!filterSearchRequest) return;

      ProportionApi.getSearchProportion(
        {
          ...filterSearchRequest,
          page: page,
        },
        (_data) => {
          setProportions(_data);
          setFilterSearchRequest({
            ...filterSearchRequest,
            page: page,
          });
          setPageInfo((prev) => ({
            ...prev,
            page,
          }));
        },
      );
    },
    [filterSearchRequest],
  );

  const handlerChangeVersion = useCallback(
    (item: ProportionResponse) => {
      if (!filterSearchRequest) return;

      ProportionApi.putProportionCurrVer(
        {
          id: item.id,
        },
        () => {
          handlerSearchProportion(filterSearchRequest);
          handlerSearchCurProportion();
        },
      );
    },
    [filterSearchRequest, handlerSearchProportion, handlerSearchCurProportion],
  );

  useEffect(() => {
    if (!propName || !factoryId) return;

    const request = {
      page: 1,
      pageSize: 6,
      propName: propName,
      factoryId: parseInt(factoryId),
      removed: false,
      sort,
    };

    TagApi.getAllProportionTags(
      {
        factoryId: parseInt(factoryId),
        removed: false,
      },
      (_data) =>
        setTagItems(
          _data.reduce(
            (prev, current) => [...prev, { text: current.tagName, value: current.id }],
            [] as Array<Options>,
          ),
        ),
    );

    handlerSearchProportion(request);
    handlerSearchCurProportion();
  }, [propName, factoryId]);

  const handleTagsSelect = useCallback(
    (tag: Options) => {
      if (!selectTag) {
        setSelectTag([tag]);
      } else {
        setSelectTag((prev) => {
          const repeat = prev.findIndex((item) => item.value === tag.value);
          if (repeat !== -1) {
            return prev.filter((item) => item.value !== tag.value);
          } else {
            return [...prev, tag];
          }
        });
      }
    },
    [selectTag, setSelectTag],
  );

  const handleSortChange = (orderBy: string) => {
    setSort(orderBy);
    handlerSearchProportion({ ...filterSearchRequest, sort: orderBy } as GetSearchProportionRequest);
    handlerSearchCurProportion();
  };

  const navigateToCreateProportion = () => {
    navigate(`/proportionManage/proportionList/create`);
  };

  const navigateBack = () => {
    navigate('/proportionManage/proportionList');
  };

  const {
    annualShipmentCount,
    latestShipmentDate,
    annualQtySummary,
    annualRejectSummary,
    annualRejectRate,
    highestStrengthOfYear,
    lowestStrengthOfYear,
    averageStrengthOfYear,
    highestCostOfYear,
    lowestCostOfYear,
    averageCostOfYear,
    strengthCostPerKg,
  } = currentVersion?.annualShipmentAndCostStatistics ?? {};

  return (
    <YatungPage
      title={i18T('PROPORTION_MANAGEMENT.PROPORTION.TITLE')}
      body={
        <>
          <Stack flexDirection="row" justifyContent="flex-end" sx={{ my: 2, gap: 1 }}>
            <YatungButton startIcon={<CancelIcon />} color="red" onClick={navigateBack} disabled={actionLoading}>
              取消
            </YatungButton>
            <YatungButton
              startIcon={<CreateIcon />}
              color="gray"
              onClick={navigateToCreateProportion}
              disabled={actionLoading}
            >
              新建配比
            </YatungButton>
          </Stack>
          <Box
            sx={{
              p: 2,
              border: '1px solid #6C708C',
              borderRadius: '8px',
              minWidth: '1380px',
              height: '100%',
              opacity: actionLoading ? 0.4 : 1,
            }}
          >
            <Card sx={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)', position: 'relative' }}>
              <CardContent>
                <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between">
                  {currentVersion && (
                    <Card sx={{ width: '450px', mr: 2, boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
                      <CardContent>
                        <RowContent
                          labels={['配比名稱', '配比版本', '']}
                          values={[
                            currentVersion?.propName ?? '無資料',
                            `${currentVersion?.version}/${proportions?.totalElements}`,
                            '',
                          ]}
                          showBorder={false}
                        />
                        <RowContent
                          labels={['設計強度', '坍(流)度', '粒徑']}
                          values={[
                            currentVersion?.dsgnStr ?? '無資料',
                            currentVersion?.slump ?? '無資料',
                            currentVersion?.grainSize ?? '無資料',
                          ]}
                          showBorder={false}
                        />
                        <RowContent
                          labels={['最近出貨日期', '年出貨工地數', '年出貨方數']}
                          values={[
                            latestShipmentDate ?? '無資料',
                            annualShipmentCount ?? '無資料',
                            annualQtySummary ?? '無資料',
                          ]}
                          showBorder={false}
                        />
                        <RowContent
                          labels={['年最高強度', '年最低強度', '年平均強度(kgf/cm²)']}
                          values={[
                            highestCostOfYear ?? '無資料',
                            lowestCostOfYear ?? '無資料',
                            averageStrengthOfYear ?? '無資料',
                          ]}
                          showBorder={false}
                        />
                        <RowContent
                          labels={['年最高成本', '年最低成本', '年平均成本']}
                          values={[
                            highestStrengthOfYear ?? '無資料',
                            lowestStrengthOfYear ?? '無資料',
                            averageCostOfYear ?? '無資料',
                          ]}
                          showBorder={false}
                        />
                        <RowContent
                          labels={['kg強度成本', '年退轉方數', '年退轉率(%)']}
                          values={[
                            strengthCostPerKg ?? '無資料',
                            annualRejectSummary ?? '無資料',
                            annualRejectRate ?? '無資料',
                          ]}
                          showBorder={false}
                        />
                      </CardContent>
                    </Card>
                  )}
                  <Box sx={{ width: '443px', maxHeight: '155px' }}>
                    <TagsSelectPanel
                      selectedTags={selectTag ?? []}
                      handleTagsSelect={handleTagsSelect}
                      tagItems={tagItems}
                    />
                  </Box>
                  <Stack sx={{ width: '443px', maxHeight: '155px', ml: 2 }}>
                    <SortPanel selectSort={sort} sortItems={sortItems} handleSortChange={handleSortChange} />
                  </Stack>
                </Stack>
              </CardContent>
              <YatungButton
                sx={{ position: 'absolute', right: 30, bottom: 30 }}
                width={100}
                color="blue"
                text="儲存"
                onClick={handlerSaveTags}
              />
            </Card>
            {!actionLoading && proportions && (
              <Card sx={{ mt: 2, minHeight: '400px', minWidth: '1300px' }}>
                <CardContent>
                  <Stack flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                    {proportions.proportions.map((item: ProportionResponse) => (
                      <Stack
                        key={item.id}
                        sx={{
                          position: 'relative',
                          padding: 4,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <FormulaCard isVersionList={true} proportion={item} type={0} />
                        {item.isCurrVer && (
                          <img
                            src={nowImg}
                            style={{
                              width: '124px',
                              height: '84px',
                              rotate: '25deg',
                              position: 'absolute',
                              right: -20,
                              top: -2,
                              opacity: 0.8,
                            }}
                          />
                        )}
                        {!item.isCurrVer && (
                          <YatungButton
                            width={160}
                            color="blue"
                            text="設為最新版本"
                            onClick={() => handlerChangeVersion(item)}
                          />
                        )}
                      </Stack>
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            )}
          </Box>
          <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid>
          {actionLoading && <YatungLoading overlay={true} />}
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
