import { AfterGetUserGroupsData, UserData } from '@Basic/object/AuthType';
import { AreaOptions } from '@Src/_basic/object/AreaType';
import { SelectEquipmentFactory } from '@Src/_basic/object/EquipmentManagementType';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
  token: string | undefined;
  userData: UserData | undefined;
  userGroupsData: AfterGetUserGroupsData | undefined;
  language: string;
  userAreaOptionsData: AreaOptions[];
  userApiPermission: Array<string>;
  equipmentFactory: SelectEquipmentFactory[];
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    token: undefined,
    userData: undefined,
    userGroupsData: undefined,
    language: 'zh',
    userAreaOptionsData: [],
    userApiPermission: [],
    equipmentFactory: [],
  } as InitialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
      console.log('set token', action.payload);
    },
    setUserData: (state, action: PayloadAction<UserData | undefined>) => {
      state.userData = action.payload;
    },
    setUserGroupsData: (state, action: PayloadAction<AfterGetUserGroupsData | undefined>) => {
      state.userGroupsData = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setUserAreaOptionsData: (state, action: PayloadAction<AreaOptions[]>) => {
      state.userAreaOptionsData = action.payload;
    },
    setUserApiPermission: (state, action: PayloadAction<Array<string>>) => {
      state.userApiPermission = action.payload;
    },
    setEquipmentFactory: (state, action: PayloadAction<SelectEquipmentFactory[]>) => {
      state.equipmentFactory = action.payload;
    },
  },
});
