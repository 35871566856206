import { CardMedia } from '@mui/material';
import React from 'react';

export default function FactoryDeviationBi() {
  return (
    <CardMedia
      component="iframe"
      src="https://app.powerbi.com/reportEmbed?reportId=da84a482-b00b-4f04-81ff-2d0cc72c8cac&autoAuth=true&ctid=ab35a555-3574-4bb8-9c15-c26a9300d0ff"
      sx={{ width: '80vw', height: '80vh' }}
    ></CardMedia>
  );
}
