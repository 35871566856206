import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import { TestInDistrictArea } from '@Src/_basic/object/TestInDistrictType';
import { ExptTestInDistrictApi } from '@Src/_basic/protocol/exptTestInDistrict/ExptTestInDistrictApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useTestDistrict } from '@Src/redux/testInDistrict/testInDistrictActions';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TestInDistrictByAreaCard from '../components/TestInDistrictByAreaCard';

export default function TestInDistrictByAreaScreen() {
  const { t: i18T } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { setDate, date } = useTestDistrict();
  const { userGroupsData } = useAuth();
  const [testInDistrictAreaData, setTestInDistrictAreaData] = useState<Array<TestInDistrictArea>>([]);
  const handleDateChange = (value: unknown) => {
    setDate(value);
  };
  useEffect(() => {
    setLoading(true);
    ExptTestInDistrictApi.getExptTestInDistrictByArea(
      { currTime: date, factoryIds: userGroupsData?.factories.map((item) => item.value) },
      (data: Array<TestInDistrictArea>) => {
        setTestInDistrictAreaData(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [date]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.TITLE')}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="flex-start" sx={{ py: 1 }}>
            <YatungDateSelect
              disabled={loading}
              value={date}
              disableFuture={true}
              onChange={(val) => val && handleDateChange(new Date(val).getTime())}
            />
          </Stack>

          <TestInDistrictByAreaCard testInDistrictAreaData={testInDistrictAreaData} loading={loading} />
        </>
      }
      contentBgColor="#fff"
    />
  );
}
