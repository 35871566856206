import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';
import Switch from '@Src/_basic/icons/Switch';

interface Props {
  onStop: () => void;
  onStart: () => void;
}
export default function FactoryManagementCreateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onStop, onStart } = props;
  return (
    <>
      <YatungButton
        text={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.ENABLE')}
        color={'green'}
        size="large"
        startIcon={<Switch />}
        onClick={onStart}
        sx={{ fontSize: '16px' }}
      />
      <YatungButton
        text={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.DISABLED')}
        size="large"
        startIcon={<Switch />}
        onClick={onStop}
        color={'red'}
        sx={{ fontSize: '16px' }}
      />
    </>
  );
}
