import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import VehicleManagementScreen from './screens/VehicleManagementScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <VehicleManagementScreen />,
  },
];

const VehicleManagementRouter = () => PermissionRoute(routesConfig);

export default memo(VehicleManagementRouter);
