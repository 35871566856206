import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppPermissionRequest } from '@Src/_basic/object/AppPermissionType';

interface initialState {
  permissionData: AppPermissionRequest | any;
  originPermissionData: AppPermissionRequest | any;
  activePermissionData: AppPermissionRequest | any;
}

export const permissionSlice = createSlice({
  name: 'permissionSlice',
  initialState: {
    permissionData: [],
    originPermissionData: [],
    activePermissionData: [],
  } as initialState,
  reducers: {
    setPermissionData: (state, action: PayloadAction<AppPermissionRequest | any>) => {
      state.permissionData = action.payload;
    },
    setOriginPermissionData: (state, action: PayloadAction<AppPermissionRequest | any>) => {
      state.originPermissionData = action.payload;
    },
    setActivePermissionData: (state, action: PayloadAction<AppPermissionRequest | any>) => {
      state.activePermissionData = action.payload;
    },
  },
});
