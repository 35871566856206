import {
  GetMonitorParams,
  MonitorAreaData,
  MonitorCustomerData,
  MonitorFactoryData,
  MonitorOrderData,
} from '@Src/_basic/object/MonitorType';
import { MonitorApi } from '@Src/_basic/protocol/monitor/MonitorApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { monitorSlice } from './monitorSlice';

export function useMonitor() {
  const { areaData, orderData, date } = useSelector((state: RootState) => state.monitor);
  const dispatch = useDispatch();

  const setMonitorAreaData = (_areaData: MonitorAreaData) => dispatch(monitorSlice.actions.setArea(_areaData));

  const setDate = (_date: any) => dispatch(monitorSlice.actions.setDate(_date));

  const getMonitorArea = (
    params: GetMonitorParams,
    onSuccess?: (data: Array<MonitorAreaData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    MonitorApi.getMonitorArea(
      params,
      (data: Array<MonitorAreaData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const getMonitorFactory = (
    id: number,
    params: GetMonitorParams,
    onSuccess?: (data: Array<MonitorFactoryData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    MonitorApi.getMonitorFactory(
      id,
      params,
      (data: Array<MonitorFactoryData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const getMonitorCustomer = (
    id: number,
    params: GetMonitorParams,
    onSuccess?: (data: Array<MonitorCustomerData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    MonitorApi.getMonitorCustomer(
      id,
      params,
      (data: Array<MonitorCustomerData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const getMonitorOrder = (
    factoryId: number,
    customerId: string,
    orderId: string,
    date: string,
    onSuccess?: (data: Array<MonitorOrderData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    MonitorApi.getMonitorOrder(
      factoryId,
      customerId,
      orderId,
      { date },
      (data: Array<MonitorOrderData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  return {
    areaData,
    orderData,
    date,

    setDate,
    getMonitorArea,
    getMonitorFactory,
    getMonitorCustomer,
    getMonitorOrder,

    setMonitorAreaData,
  };
}
