import { MaterialInspectionDetialByData } from '@Src/_basic/object/MaterialInspectionType';
import { Typography } from '@mui/material';
import React from 'react';

export type ExtraData = {
  getTestName: (item: string) => string;
};

interface Props {
  row: MaterialInspectionDetialByData;
  formatExtraData: ExtraData;
}
export default function ExptItemCodeColumnFormatter(props: Props) {
  const { formatExtraData, row } = props;
  return <Typography variant="inherit">{formatExtraData.getTestName(row.exptItemCode)}</Typography>;
}
