export const ChangeValueHelper = (formData: any) => {
  for (const key in formData) {
    formData.source1Id = formData.source1Id === 0 ? null : formData.source1Id;
    formData.source2Id = formData.source2Id === 0 ? null : formData.source2Id;
    formData.source3Id = formData.source3Id === 0 ? null : formData.source3Id;
    formData.specification1Id = formData.specification1Id === 0 ? null : formData.specification1Id;
    formData.specification2Id = formData.specification2Id === 0 ? null : formData.specification2Id;
    formData.specification3Id = formData.specification3Id === 0 ? null : formData.specification3Id;
    formData.supplier1Id = formData.supplier1Id === 0 ? null : formData.supplier1Id;
    formData.supplier2Id = formData.supplier2Id === 0 ? null : formData.supplier2Id;
    formData.supplier3Id = formData.supplier3Id === 0 ? null : formData.supplier3Id;
  }
};
