import { Response } from '@Src/_basic/object/ApiType';
import {
  CreateLangData,
  CreatefileData,
  DeleteLangData,
  GetLangData,
  GetMultilingualFileData,
  LangData,
  MultilingualFileData,
  ReplaceLangFileData,
  UpdqateLangName,
} from '@Src/_basic/object/MultilingualManagementType';
import { Api } from '../Api';
import { LanguageProtocol } from './LanguageProtocol';

export class LanguageApi {
  // 取得語系
  public static getLangData(
    params: GetLangData,
    onSuccess?: (data: Array<LangData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(LanguageProtocol.GET_LANG, config, onSuccess, onFailed, onFinally);
  }

  //刪除語系
  public static deleteLangData(
    params: DeleteLangData,
    onSuccess?: (data: Array<LangData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.delete(LanguageProtocol.DELETE_LANG, config, onSuccess, onFailed, onFinally);
  }

  //更新語系名稱
  public static updateLangName(
    request: UpdqateLangName,
    onSuccess?: (data: Array<LangData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(LanguageProtocol.UPDATE_LANG, request, undefined, onSuccess, onFailed, onFinally);
  }

  //新增語系
  public static uploadLanguageReports(
    platform: string,
    params: CreateLangData,
    file: FileList,
    onSuccess?: (data: Array<CreatefileData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    };

    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append('file', file[i]); //要求'file'，而非'files'
    }

    Api.post(LanguageProtocol.POST_LANG_FILE + `/${platform}`, formData, config, onSuccess, onFailed, onFinally);
  }

    //新增預設語系檔
    public static uploadDefaultLanguageReports(
      platform: string,
      params: CreateLangData,
      file: FileList,
      onSuccess?: (data: Array<CreatefileData>) => void,
      onFailed?: (res: Response<null>) => void,
      onFinally?: () => void,
    ) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
      };
  
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append('file', file[i]); //要求'file'，而非'files'
      }
  
      Api.post(LanguageProtocol.POST_LANG_FILE + `/${platform}`+ '/default', formData, config, onSuccess, onFailed, onFinally);
    }

    //更新語系檔
    public static replaceLanguageReports(
      params: ReplaceLangFileData,
      file: FileList,
      onSuccess?: (data: Array<CreatefileData>) => void,
      onFailed?: (res: Response<null>) => void,
      onFinally?: () => void,
    ) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
      };
  
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append('file', file[i]); //要求'file'，而非'files'
      }
  
      Api.put(LanguageProtocol.PUT_REPLACE_FILE , formData, config, onSuccess, onFailed, onFinally);
    }

  //取得指定平台預設語系檔案
  public static getLangPlatformDefaultFileData(
    platform: string,
    onSuccess?: (data: MultilingualFileData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {};
    Api.get(LanguageProtocol.POST_LANG_FILE + `/${platform}` + '/default', config, onSuccess, onFailed, onFinally);
  }

  public static getMultilingualPlatformAllFileData(
    //取得特定平台所有的語系檔案
    platformName: string,
    onSuccess?: (data: Array<MultilingualFileData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {};
    Api.get(LanguageProtocol.GET_FILE_CONTENT + `/${platformName}`, config, onSuccess, onFailed, onFinally);
  }

  public static getMultilingualPlatformFileData(
    //取得特定平台的語系檔案
    platformName: string,
    lang: string,
    onSuccess?: (data: Array<MultilingualFileData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {};
    Api.get(
      LanguageProtocol.GET_FILE_CONTENT + `/${platformName}` + `/${lang}`,
      config,
      onSuccess,
      onFailed,
      onFinally,
    );
  }

  public static getAllMultilingualFileData(
    //取得所有的語系檔案
    params: GetMultilingualFileData,
    onSuccess?: (data: Array<MultilingualFileData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(LanguageProtocol.GET_ALL_FILE, config, onSuccess, onFailed, onFinally);
  }
}
