import React from 'react';
import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { useElementSize } from 'usehooks-ts';
import YatungTestPageTitle from './YatungTestPageTitle';

interface Props {
  title?: string;
  backgroundImage?: string;
  actions?: JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
  contentBgColor?: string;
  isOrder?: boolean;
  handleClick?: () => void;
  sx?: any;
}

export default function YatungHistoryFilePage({
  title,
  actions,
  body,
  sx,
  contentBgColor,
  isOrder,
  handleClick,
}: Props) {
  const [titleRef, titleSize] = useElementSize();

  return (

    <TableContainer
      sx={{
        backgroundColor: 'transparent',
        position: 'relative',
        ...sx,
      }}
    >
      <Table>
        <TableBody>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              height: '100%',
              position: 'relative',
              backgroundColor: contentBgColor || 'rgba(255, 255, 255, 0.4)',
            }}
          >
            <YatungTestPageTitle
              ref={titleRef}
              title={title}
              actions={actions}
              isOrder={isOrder}
              handleClick={handleClick}
            />
            <Box sx={{ height: `calc(100% - ${titleSize.height}px)` }}>{body}</Box>
          </Paper>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
