import YatungDialog from '@Src/_basic/components/YatungDialog';
import PersonAdd from '@Src/_basic/icons/PersonAdd';
import { CreateEntityRequest } from '@Src/_basic/object/EntityType';
import { EntityApi } from '@Src/_basic/protocol/entity/EntityApi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FactoryAndStationName, QueryType } from '../../screens/EntityScreen';
import EntityCreateField from './components/EntityCreateField';

interface Props {
  query: QueryType;
  modalOpen: boolean;
  modalClose: () => void;
  getSearchEntityData: () => void;
  factoryAndStationOptions: FactoryAndStationName;
}

const createEntitySchema = Yup.object().shape({
  name: Yup.string().required('請輸入人員名稱'),
});

export default function EntityCreateCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { query, modalOpen, modalClose, getSearchEntityData, factoryAndStationOptions } = props;

  const initialValues: CreateEntityRequest = useMemo(
    () => ({
      name: '',
      factory_id: query.factoryId,
      stationId: query.stationId,
    }),
    [query],
  );

  const handleSubmit = useCallback(
    (request: CreateEntityRequest) => {
      EntityApi.CreateEntity(request, () => {
        getSearchEntityData();
        modalClose();
      });
    },
    [getSearchEntityData, modalClose],
  );

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={modalOpen}
        handleClose={modalClose}
        icon={<PersonAdd sx={{ width: 40, height: 40, mr: 1, alignSelf: 'center' }} />}
        title={i18T('ENTITYMANAGEMENT.CREATE_ENTITY_BUTTON')}
        body={
          <Formik
            initialValues={initialValues}
            validationSchema={createEntitySchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <EntityCreateField
                    values={values}
                    handleClose={modalClose}
                    setFieldValue={setFieldValue}
                    factoryAndStationOptions={factoryAndStationOptions}
                  />
                </Form>
              );
            }}
          </Formik>
        }
      />
    </Grid>
  );
}
