import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LangWarning(props: SvgIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
      <path
        d="M13.8397 2.05986C14.7998 0.396874 17.2002 0.396876 18.1603 2.05987L31.6621 25.4457C32.6222 27.1087 31.4221 29.1874 29.5018 29.1874H2.4982C0.577949 29.1874 -0.622208 27.1086 0.337919 25.4457L13.8397 2.05986Z"
        fill="#CB333B"
      />
      <path
        d="M14.813 8.19627C14.3317 8.19627 13.9414 8.59321 13.9414 9.08278V20.164C13.9414 20.6536 14.3317 21.0505 14.813 21.0505H16.7741C17.2555 21.0505 17.6457 20.6536 17.6457 20.164V9.08278C17.6457 8.59321 17.2555 8.19627 16.7741 8.19627H14.813Z"
        fill="white"
      />
      <path
        d="M14.813 21.7154C14.3317 21.7154 13.9414 22.1124 13.9414 22.6019V24.5966C13.9414 25.0861 14.3317 25.4831 14.813 25.4831H16.7741C17.2555 25.4831 17.6457 25.0861 17.6457 24.5966V22.6019C17.6457 22.3093 17.5061 22.0496 17.2912 21.8881C17.1465 21.7795 16.9678 21.7154 16.7741 21.7154H14.813Z"
        fill="white"
      />
    </svg>
  );
}
