import { Box, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import YatungButton from '@Src/_basic/components/YatungButton';
import CancelIcon from '@Src/_basic/icons/Cancel';
import EditIcon from '@Src/_basic/icons/Edit';

import { CalcUpdateProportion } from '@Src/_basic/helpers/Proportion';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber, getInteger } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import CalculatorIcon from '@Src/_basic/icons/Calculator';
import SaveIcon from '@Src/_basic/icons/Save';
import TransferIcon from '@Src/_basic/icons/Transfer';
import {
  ImportUpdateProportion,
  PostProportionRequest,
  ProportionMaintenanceResponse,
  ProportionResponse,
} from '@Src/_basic/object/ProportionType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LabelInput from './LabelInput';
import LabelInputV2 from './LabelInputV2';
import RowContent from './RowContent';
import ValueCheckWrapper from './ValueCheckWrapper';

const validationSchema = Yup.object().shape({
  propName: Yup.string().required(),
  quantityMixingWater: basicNumberSchema,
  waterColloidRatio: basicNumberSchema,
  stone1Pct: basicNumberSchema,
  stone2Pct: basicNumberSchema,
  stone3Pct: basicNumberSchema,
  stone4Pct: basicNumberSchema,
  sand1Pct: basicNumberSchema,
  sand2Pct: basicNumberSchema,
  sand3Pct: basicNumberSchema,
  sand4Pct: basicNumberSchema,
  stone1Kg: basicNumberSchema,
  stone2Kg: basicNumberSchema,
  stone3Kg: basicNumberSchema,
  stone4Kg: basicNumberSchema,
  sand1Kg: basicNumberSchema,
  sand2Kg: basicNumberSchema,
  sand3Kg: basicNumberSchema,
  sand4Kg: basicNumberSchema,
  chemAdmx1UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx2UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx3UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx4UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx5UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx6UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  selectedMixer: Yup.string().required(),
  cementWt: basicNumberSchema,
  slagWt: basicNumberSchema,
  flyAshWt: basicNumberSchema,
  microsilicaWt: basicNumberSchema,
  sandAndStoneTtlWt: basicNumberSchema,
  recycledWater: basicNumberSchema,
  sandPct: basicNumberSchema,
  normalPortlandCementRatio: basicNumberSchema,
});

const rowBoxStyle = { backgroundColor: '#697EA3', borderRadius: '4px', m: 1, p: 1, width: '300px' };
const rowLabelsx = {
  fontWeight: 400,
  fontSize: 16,
  color: 'white',
  textAlign: 'center',
  lineHeight: 1,
  p: 0.5,
  letterSpacing: 4,
};
const rowValuesx = { fontWeight: 700, fontSize: 32, color: 'white', textAlign: 'center', lineHeight: 1, p: 0.5 };

interface Props {
  selectedFactoryId: number;
  proportion: ProportionResponse;
  initialValues: ImportUpdateProportion;
  proportionMaintenance: ProportionMaintenanceResponse;
}

function EditProportionForm({ proportion, initialValues, proportionMaintenance, selectedFactoryId }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { dsgnStr, coarseAggCompVolumeRatio } = proportion;

  const [alertText, setAlertText] = useState<string>('');
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [postRequest, setPostRequest] = useState<PostProportionRequest>();
  const [changePercentAndKg, setChangePercentAndKg] = useState<boolean>(true);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      postProportion(values);
    },
  });

  const postNewProportion = useCallback(
    (postRequest: PostProportionRequest) => {
      if (!postRequest) return;
      ProportionApi.postProportion(postRequest, () =>
        navigate(`/proportionManage/proportionList/version/${postRequest.propName}/${postRequest.factoryId}`),
      );

      setIsShowAlert(false);
    },
    [postRequest, navigate],
  );

  useEffect(
    () => Object.entries(initialValues).forEach(([key, value]) => formik.setFieldValue(`${key}`, `${value}`)),
    [initialValues],
  );

  const totalStonePercentage = useMemo(() => {
    const _totalStone =
      Number(formik.values.stone1Pct) +
      Number(formik.values.stone2Pct) +
      Number(formik.values.stone3Pct) +
      Number(formik.values.stone4Pct);
    return Number(_totalStone?.toFixed(1)) >= 100.5 || Number(_totalStone?.toFixed(1)) < 99.5;
  }, [formik.values.stone1Pct, formik.values.stone2Pct, formik.values.stone3Pct, formik.values.stone4Pct]);

  const totalSandPercentage = useMemo(() => {
    const _totalSand =
      Number(formik.values.sand1Pct) +
      Number(formik.values.sand2Pct) +
      Number(formik.values.sand3Pct) +
      Number(formik.values.sand4Pct);
    return Number(_totalSand?.toFixed(1)) >= 100.5 || Number(_totalSand?.toFixed(1)) < 99.5;
  }, [formik.values.sand1Pct, formik.values.sand2Pct, formik.values.sand3Pct, formik.values.sand4Pct]);

  const {
    safeStr,
    cementitiousWt,
    recycledWaterWt,
    tapWaterWt,

    cementPct,
    slagPct,
    flyAshPct,
    microsilicaPct,

    coarseAggWt,
    stone1Wt,
    stone2Wt,
    stone3Wt,
    stone4Wt,

    calcStone1Pct,
    calcStone2Pct,
    calcStone3Pct,
    calcStone4Pct,

    fineAggWt,
    sand1Wt,
    sand2Wt,
    sand3Wt,
    sand4Wt,

    calcSand1Pct,
    calcSand2Pct,
    calcSand3Pct,
    calcSand4Pct,

    chemAdmx1Wt,
    chemAdmx2Wt,
    chemAdmx3Wt,
    chemAdmx4Wt,
    chemAdmx5Wt,
    chemAdmx6Wt,
    expectedStr,
    proportionUnitWt,
    truthWaterColloidRatio,
    arrivedDesignStrPct,
    proportionUnitVolume,
    proportionUnitCost,
    carbonEmissionMaterial,
    carbonEmissionLifeCycle,
  } = new CalcUpdateProportion({ ...formik.values }, proportion, proportionMaintenance);

  useEffect(() => {
    if (!changePercentAndKg) return;
    Object.entries({
      stone1Wt,
      stone2Wt,
      stone3Wt,
      stone4Wt,
      sand1Wt,
      sand2Wt,
      sand3Wt,
      sand4Wt,
    }).forEach(([key, value]) => {
      const _text = key.replace('Wt', 'Kg');
      formik.setFieldValue(`${_text}`, `${formatNumber(value?.toFixed(4))}`);
    });
  }, [stone1Wt, stone2Wt, stone3Wt, stone4Wt, sand1Wt, sand2Wt, sand3Wt, sand4Wt, changePercentAndKg]);

  useEffect(() => {
    if (changePercentAndKg) return;
    Object.entries({
      calcStone1Pct,
      calcStone2Pct,
      calcStone3Pct,
      calcStone4Pct,
      calcSand1Pct,
      calcSand2Pct,
      calcSand3Pct,
      calcSand4Pct,
    }).forEach(([key, value]) => {
      const _text = key.replace('calcS', 's');
      formik.setFieldValue(`${_text}`, `${formatNumber(value?.toFixed(4))}`);
    });
  }, [
    calcStone1Pct,
    calcStone2Pct,
    calcStone3Pct,
    calcStone4Pct,
    calcSand1Pct,
    calcSand2Pct,
    calcSand3Pct,
    calcSand4Pct,
    changePercentAndKg,
  ]);

  const postProportion = useCallback(
    (values: ImportUpdateProportion) => {
      const require: PostProportionRequest = {
        propName: values.propName,
        slump: checkIsNaN(+values.slump),
        grainSize: checkIsNaN(+values.grainSize),
        quantityMixingWater: checkIsNaN(+values.quantityMixingWater),
        waterColloidRatio: checkIsNaN(+values.waterColloidRatio),

        airContent: proportion.airContent,

        stone1Pct: checkIsNaN(+values.stone1Pct),
        stone2Pct: checkIsNaN(+values.stone2Pct),
        stone3Pct: checkIsNaN(+values.stone3Pct),
        stone4Pct: checkIsNaN(+values.stone4Pct),
        sand1Pct: checkIsNaN(+values.sand1Pct),
        sand2Pct: checkIsNaN(+values.sand2Pct),
        sand3Pct: checkIsNaN(+values.sand3Pct),
        sand4Pct: checkIsNaN(+values.sand4Pct),
        chemAdmx1UsagePct:
          proportionMaintenance.chemAdmx1Name && values.chemAdmx1UsagePct ? checkIsNaN(+values.chemAdmx1UsagePct) : 0,
        chemAdmx2UsagePct:
          proportionMaintenance.chemAdmx2Name && values.chemAdmx2UsagePct ? checkIsNaN(+values.chemAdmx2UsagePct) : 0,
        chemAdmx3UsagePct:
          proportionMaintenance.chemAdmx3Name && values.chemAdmx3UsagePct ? checkIsNaN(+values.chemAdmx3UsagePct) : 0,
        chemAdmx4UsagePct:
          proportionMaintenance.chemAdmx4Name && values.chemAdmx4UsagePct ? checkIsNaN(+values.chemAdmx4UsagePct) : 0,
        chemAdmx5UsagePct:
          proportionMaintenance.chemAdmx5Name && values.chemAdmx5UsagePct ? checkIsNaN(+values.chemAdmx5UsagePct) : 0,
        chemAdmx6UsagePct:
          proportionMaintenance.chemAdmx6Name && values.chemAdmx6UsagePct ? checkIsNaN(+values.chemAdmx6UsagePct) : 0,

        recycledWater: checkIsNaN(+values.recycledWater),

        selectedMixer: proportion.selectedMixer,

        factoryId: selectedFactoryId,

        cementWt: checkIsNaN(+values.cementWt),
        slagWt: checkIsNaN(+values.slagWt),
        flyAshWt: checkIsNaN(+values.flyAshWt),
        microsilicaWt: checkIsNaN(+values.microsilicaWt),
        normalPortlandCementRatio: checkIsNaN(+values.normalPortlandCementRatio),

        sandTtlWt: fineAggWt,
        stoneTtlWt: coarseAggWt,
        maintPropId: proportionMaintenance.id,
        dsgnStr,
        coarseAggCompVolumeRatio,
      };

      ProportionApi.getIsExistProportion(require, (_data) => {
        const exitProportion = Object.values(_data);
        setPostRequest(require);
        if (!exitProportion[0]) postNewProportion(require);
        else {
          setIsShowAlert(true);
          if (!exitProportion[1])
            setAlertText(`${i18T('PROPORTION.SYSTEM_CHECK')}: ${i18T('PROPORTION.PROPORTION_NAME_EXIST_NEW_VERSION')}`);
          else
            setAlertText(
              `${i18T('PROPORTION.SYSTEM_CHECK')}: ${i18T('PROPORTION.PROPORTION_NAME_EXIST_CONFIRM')}: ${i18T(
                'PROPORTION.PROPORTION_NAME_EXIST_PREVIOUS_VERSION',
              )}`,
            );
        }
      });
    },
    [
      i18T,
      coarseAggCompVolumeRatio,
      coarseAggWt,
      dsgnStr,
      fineAggWt,
      proportion,
      proportionMaintenance,
      selectedFactoryId,
      postNewProportion,
    ],
  );

  const navigateBack = () => {
    navigate('/proportionManage/proportionList');
  };

  const navigateToProportionParameterTable = () => {
    navigate(`/proportionManage/proportionList/proportionParameterTable`, {
      state: {
        editProportion: {
          proportionId: proportion.id,
          versionId: proportion.version,
        },
        proportionMaintenanceId: proportionMaintenance.id,
        proportion: {
          ...proportion,
          propName: formik.values.propName,
          slump: checkIsNaN(+formik.values.slump),
          grainSize: checkIsNaN(+formik.values.grainSize),
          quantityMixingWater: checkIsNaN(+formik.values.quantityMixingWater),
          waterColloidRatio: checkIsNaN(+formik.values.waterColloidRatio),

          airContent: proportion.airContent,

          stone1Pct: checkIsNaN(+formik.values.stone1Pct),
          stone2Pct: checkIsNaN(+formik.values.stone2Pct),
          stone3Pct: checkIsNaN(+formik.values.stone3Pct),
          stone4Pct: checkIsNaN(+formik.values.stone4Pct),
          sand1Pct: checkIsNaN(+formik.values.sand1Pct),
          sand2Pct: checkIsNaN(+formik.values.sand2Pct),
          sand3Pct: checkIsNaN(+formik.values.sand3Pct),
          sand4Pct: checkIsNaN(+formik.values.sand4Pct),
          chemAdmx1UsagePct: formik.values.chemAdmx1UsagePct ? checkIsNaN(+formik.values.chemAdmx1UsagePct) : 0,
          chemAdmx2UsagePct: formik.values.chemAdmx2UsagePct ? checkIsNaN(+formik.values.chemAdmx2UsagePct) : 0,
          chemAdmx3UsagePct: formik.values.chemAdmx3UsagePct ? checkIsNaN(+formik.values.chemAdmx3UsagePct) : 0,
          chemAdmx4UsagePct: formik.values.chemAdmx4UsagePct ? checkIsNaN(+formik.values.chemAdmx4UsagePct) : 0,
          chemAdmx5UsagePct: formik.values.chemAdmx5UsagePct ? checkIsNaN(+formik.values.chemAdmx5UsagePct) : 0,
          chemAdmx6UsagePct: formik.values.chemAdmx6UsagePct ? checkIsNaN(+formik.values.chemAdmx6UsagePct) : 0,

          recycledWater: checkIsNaN(+formik.values.recycledWater),

          selectedMixer: proportion.selectedMixer,

          factoryId: selectedFactoryId,

          cementWt: checkIsNaN(+formik.values.cementWt),
          slagWt: checkIsNaN(+formik.values.slagWt),
          flyAshWt: checkIsNaN(+formik.values.flyAshWt),
          microsilicaWt: checkIsNaN(+formik.values.microsilicaWt),
          normalPortlandCementRatio: checkIsNaN(+formik.values.normalPortlandCementRatio),

          sandTtlWt: fineAggWt,
          stoneTtlWt: coarseAggWt,
          maintPropId: proportionMaintenance.id,
          dsgnStr,
          coarseAggCompVolumeRatio,

          sandAndStoneTtlWt: formik.values.sandAndStoneTtlWt,
          sandPct: formik.values.sandPct,
        },
        mode: 'edit',
      },
    });
  };

  const navigateToSafeWaterCalculation = () => {
    navigate(`safeWaterCalculation`, {
      state: {
        proportion: {
          ...proportion,
          propName: formik.values.propName,
          slump: checkIsNaN(+formik.values.slump),
          grainSize: checkIsNaN(+formik.values.grainSize),
          quantityMixingWater: checkIsNaN(+formik.values.quantityMixingWater),
          waterColloidRatio: checkIsNaN(+formik.values.waterColloidRatio),

          stone1Pct: checkIsNaN(+formik.values.stone1Pct),
          stone2Pct: checkIsNaN(+formik.values.stone2Pct),
          stone3Pct: checkIsNaN(+formik.values.stone3Pct),
          stone4Pct: checkIsNaN(+formik.values.stone4Pct),
          sand1Pct: checkIsNaN(+formik.values.sand1Pct),
          sand2Pct: checkIsNaN(+formik.values.sand2Pct),
          sand3Pct: checkIsNaN(+formik.values.sand3Pct),
          sand4Pct: checkIsNaN(+formik.values.sand4Pct),
          chemAdmx1UsagePct: formik.values.chemAdmx1UsagePct ? checkIsNaN(+formik.values.chemAdmx1UsagePct) : 0,
          chemAdmx2UsagePct: formik.values.chemAdmx2UsagePct ? checkIsNaN(+formik.values.chemAdmx2UsagePct) : 0,
          chemAdmx3UsagePct: formik.values.chemAdmx3UsagePct ? checkIsNaN(+formik.values.chemAdmx3UsagePct) : 0,
          chemAdmx4UsagePct: formik.values.chemAdmx4UsagePct ? checkIsNaN(+formik.values.chemAdmx4UsagePct) : 0,
          chemAdmx5UsagePct: formik.values.chemAdmx5UsagePct ? checkIsNaN(+formik.values.chemAdmx5UsagePct) : 0,
          chemAdmx6UsagePct: formik.values.chemAdmx6UsagePct ? checkIsNaN(+formik.values.chemAdmx6UsagePct) : 0,

          recycledWater: checkIsNaN(+formik.values.recycledWater),

          cementWt: checkIsNaN(+formik.values.cementWt),
          slagWt: checkIsNaN(+formik.values.slagWt),
          flyAshWt: checkIsNaN(+formik.values.flyAshWt),
          microsilicaWt: checkIsNaN(+formik.values.microsilicaWt),
          normalPortlandCementRatio: checkIsNaN(+formik.values.normalPortlandCementRatio),
          sandAndStoneTtlWt: checkIsNaN(+formik.values.sandAndStoneTtlWt),
          sandPct: checkIsNaN(+formik.values.sandPct),
        },
        calcData: {
          stone1Wt,
          stone2Wt,
          stone3Wt,
          stone4Wt,
          sand1Wt,
          sand2Wt,
          sand3Wt,
          sand4Wt,
          cementitiousWt,
          quantityMixingWater: formik.values.quantityMixingWater,
          chemAdmx1Wt: proportionMaintenance.chemAdmx1Id ? chemAdmx1Wt : null,
          chemAdmx2Wt: proportionMaintenance.chemAdmx2Id ? chemAdmx2Wt : null,
          chemAdmx3Wt: proportionMaintenance.chemAdmx3Id ? chemAdmx3Wt : null,
          chemAdmx4Wt: proportionMaintenance.chemAdmx4Id ? chemAdmx4Wt : null,
          chemAdmx5Wt: proportionMaintenance.chemAdmx5Id ? chemAdmx5Wt : null,
          chemAdmx6Wt: proportionMaintenance.chemAdmx6Id ? chemAdmx6Wt : null,
        },
        afterProportionMaintenance: proportionMaintenance,
      },
    });
  };

  const showFirstSection = useCallback(
    () => (
      <>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box sx={{ ...rowBoxStyle, width: '48%' }}>
            <RowContent
              labels={[`${i18T('PROPORTION.RECYCLED_WATER_WEIGHT')}(KG)`]}
              values={[getInteger(recycledWaterWt?.toFixed(2))]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </Box>
          <Box sx={{ ...rowBoxStyle, width: '48%' }}>
            <RowContent
              labels={[`${i18T('PROPORTION.TAP_WATER_WEIGHT')}(KG)`]}
              values={[getInteger(tapWaterWt?.toFixed(2))]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </Box>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box sx={{ ...rowBoxStyle, width: '48%' }}>
            <RowContent
              labels={[`${i18T('PROPORTION.SAFE_STR')}(%)`]}
              values={[safeStr?.toFixed(2)]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </Box>
          <Box sx={{ ...rowBoxStyle, width: '48%' }}>
            <RowContent
              labels={[`${i18T('PROPORTION.CEMENTITIOUS_TOTAL')}(kg)`]}
              values={[getInteger(cementitiousWt?.toFixed(2))]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </Box>
        </Stack>
      </>
    ),
    [i18T, safeStr, cementitiousWt, recycledWaterWt, tapWaterWt],
  );

  const showSecondKgSection = useCallback(
    () => (
      <Stack>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.COARSE_AGG_WEIGHT')}(kg)`]}
            values={[getInteger(coarseAggWt?.toFixed(2))]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            borderColor={'#fff'}
          />
          <RowContent
            labels={[
              `${i18T('PROPORTION.STONE1')}(kg)`,
              `${i18T('PROPORTION.STONE2')}(kg)`,
              `${i18T('PROPORTION.STONE3')}(kg)`,
              `${i18T('PROPORTION.STONE4')}(kg)`,
            ]}
            values={[
              getInteger(stone1Wt?.toFixed(2)),
              getInteger(stone2Wt?.toFixed(2)),
              getInteger(stone3Wt?.toFixed(2)),
              getInteger(stone4Wt?.toFixed(2)),
            ]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.FINE_AGG_WEIGHT')}(kg)`]}
            values={[getInteger(fineAggWt?.toFixed(2))]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            borderColor={'#fff'}
          />
          <RowContent
            labels={[
              `${i18T('PROPORTION.SAND1')}(kg)`,
              `${i18T('PROPORTION.SAND2')}(kg)`,
              `${i18T('PROPORTION.SAND3')}(kg)`,
              `${i18T('PROPORTION.SAND4')}(kg)`,
            ]}
            values={[
              getInteger(sand1Wt?.toFixed(2)),
              getInteger(sand2Wt?.toFixed(2)),
              getInteger(sand3Wt?.toFixed(2)),
              getInteger(sand4Wt?.toFixed(2)),
            ]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
      </Stack>
    ),
    [i18T, coarseAggWt, fineAggWt, stone1Wt, stone2Wt, stone3Wt, stone4Wt, sand1Wt, sand2Wt, sand3Wt, sand4Wt],
  );

  const showSecondPercentSection = useCallback(
    () => (
      <Stack>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.COARSE_AGG_WEIGHT')}(kg)`]}
            values={[getInteger(coarseAggWt?.toFixed(2))]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            borderColor={'#fff'}
          />
          <RowContent
            labels={[
              `${i18T('PROPORTION.STONE1')}(%)`,
              `${i18T('PROPORTION.STONE2')}(%)`,
              `${i18T('PROPORTION.STONE3')}(%)`,
              `${i18T('PROPORTION.STONE4')}(%)`,
            ]}
            values={[
              formatNumber(calcStone1Pct?.toFixed(4)),
              formatNumber(calcStone2Pct?.toFixed(4)),
              formatNumber(calcStone3Pct?.toFixed(4)),
              formatNumber(calcStone4Pct?.toFixed(4)),
            ]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.FINE_AGG_WEIGHT')}(kg)`]}
            values={[getInteger(fineAggWt?.toFixed(2))]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            borderColor={'#fff'}
          />
          <RowContent
            labels={[
              `${i18T('PROPORTION.SAND1')}(%)`,
              `${i18T('PROPORTION.SAND2')}(%)`,
              `${i18T('PROPORTION.SAND3')}(%)`,
              `${i18T('PROPORTION.SAND4')}(%)`,
            ]}
            values={[
              formatNumber(calcSand1Pct?.toFixed(4)),
              formatNumber(calcSand2Pct?.toFixed(4)),
              formatNumber(calcSand3Pct?.toFixed(4)),
              formatNumber(calcSand4Pct?.toFixed(4)),
            ]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
      </Stack>
    ),
    [
      i18T,
      coarseAggWt,
      fineAggWt,
      calcStone1Pct,
      calcStone2Pct,
      calcStone3Pct,
      calcStone4Pct,
      calcSand1Pct,
      calcSand2Pct,
      calcSand3Pct,
      calcSand4Pct,
    ],
  );

  const showThirdSection = useCallback(
    () => (
      <Stack>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <ValueCheckWrapper
            customNoramlStyle={{ backgroundColor: '#697EA3', width: 'auto' }}
            values={[cementPct, slagPct, flyAshPct, microsilicaPct]}
          >
            <RowContent
              labels={[
                `${i18T('PROPORTION.CEMENT')}(%)`,
                `${i18T('PROPORTION.SLAG')}(%)`,
                `${i18T('PROPORTION.FLY_ASH')}(%)`,
                `${i18T('PROPORTION.MICROSILICA')}(%)`,
              ]}
              values={[cementPct?.toFixed(2), slagPct?.toFixed(2), flyAshPct?.toFixed(2), microsilicaPct?.toFixed(2)]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </ValueCheckWrapper>
        </Box>
      </Stack>
    ),
    [i18T, cementPct, slagPct, flyAshPct, microsilicaPct],
  );

  const showFourthSection = useCallback(
    () => (
      <>
        <Stack>
          <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
            <RowContent
              labels={[
                `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', {
                  number: 1,
                })}(kg)`,
                proportionMaintenance.chemAdmx2Name
                  ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', {
                      number: 2,
                    })}(kg)`
                  : '',
                proportionMaintenance.chemAdmx3Name
                  ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', {
                      number: 3,
                    })}(kg)`
                  : '',
              ]}
              values={[
                getInteger(chemAdmx1Wt.toFixed(2)),
                proportionMaintenance.chemAdmx2Name ? getInteger(chemAdmx2Wt?.toFixed(2)) : '',
                proportionMaintenance.chemAdmx3Name ? getInteger(chemAdmx3Wt?.toFixed(2)) : '',
              ]}
              justifyContent="flex-start"
              columnWidth={[33, 33, 33]}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
              borderColor={'#fff'}
            />
          </Box>
        </Stack>

        {proportionMaintenance.chemAdmx4Name && (
          <Stack>
            <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
              <RowContent
                labels={[
                  `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', {
                    number: 4,
                  })}(kg)`,
                  proportionMaintenance.chemAdmx5Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', {
                        number: 5,
                      })}(kg)`
                    : '',
                  proportionMaintenance.chemAdmx6Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', {
                        number: 6,
                      })}(kg)`
                    : '',
                ]}
                values={[
                  getInteger(chemAdmx4Wt?.toFixed(2)),
                  proportionMaintenance.chemAdmx5Name ? getInteger(chemAdmx5Wt?.toFixed(2)) : '',
                  proportionMaintenance.chemAdmx6Name ? getInteger(chemAdmx6Wt?.toFixed(2)) : '',
                ]}
                justifyContent="flex-start"
                columnWidth={[33, 33, 33]}
                labelsx={rowLabelsx}
                valuesx={rowValuesx}
                borderColor={'#fff'}
              />
            </Box>
          </Stack>
        )}
      </>
    ),
    [i18T, chemAdmx1Wt, chemAdmx2Wt, chemAdmx3Wt, proportionMaintenance, chemAdmx4Wt, chemAdmx5Wt, chemAdmx6Wt],
  );

  const showResultSection = useCallback(
    () => (
      <Stack flexDirection="row" justifyContent="space-between">
        <Box sx={{ ...rowBoxStyle, width: '33%', backgroundColor: '#012E7F' }}>
          <RowContent
            labels={[i18T('PROPORTION.EXPECTED_STR')]}
            values={[{ text: expectedStr?.toFixed(1), unit: 'Kgf/cm²' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
          <RowContent
            labels={[i18T('PROPORTION.ARRIVE_DESIGN_STR')]}
            values={[{ text: arrivedDesignStrPct?.toFixed(1), unit: '%' }]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: '33%', backgroundColor: '#012E7F' }}>
          <RowContent
            labels={[i18T('PROPORTION.PROPORTION_UNIT_WEIGHT')]}
            values={[{ text: proportionUnitWt?.toFixed(0), unit: 'kg' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
          <RowContent
            labels={[i18T('PROPORTION.PROPORTION_UNIT_VOLUME')]}
            values={[{ text: proportionUnitVolume?.toFixed(3), unit: 'M³' }]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: '33%', backgroundColor: '#012E7F' }}>
          <RowContent
            labels={[i18T('PROPORTION.TRUTH_WATER_COLLOID_RATIO')]}
            values={[{ text: truthWaterColloidRatio?.toFixed(2), unit: 'W/B' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
          <RowContent
            labels={[i18T('PROPORTION.PROPORTION_UNIT_COST')]}
            values={[{ text: proportionUnitCost?.toFixed(1), unit: '元' }]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
      </Stack>
    ),
    [
      i18T,
      expectedStr,
      arrivedDesignStrPct,
      proportionUnitWt,
      proportionUnitVolume,
      truthWaterColloidRatio,
      proportionUnitCost,
    ],
  );

  const showCarbonEmissionSection = useCallback(
    () => (
      <Stack flexDirection="row" justifyContent="space-between">
        <Box sx={{ ...rowBoxStyle, width: '49%', backgroundColor: '#00D03A' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.CARBON_EMISSION')}(${i18T('PROPORTION.MATERIAL')})`]}
            values={[{ text: carbonEmissionMaterial?.toFixed(2), unit: 'kg' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            showBorder={false}
            borderColor={'#fff'}
            labelBorder={true}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: '49%', backgroundColor: '#00D03A' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.CARBON_EMISSION')}(${i18T('PROPORTION.FULL_LIFE_CYCLE')})`]}
            values={[{ text: carbonEmissionLifeCycle?.toFixed(2), unit: 'kg' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            showBorder={false}
            borderColor={'#fff'}
            labelBorder={true}
          />
        </Box>
      </Stack>
    ),
    [i18T, carbonEmissionMaterial, carbonEmissionLifeCycle],
  );

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid #6C708C',
        borderRadius: '8px',
        minWidth: '1280px',
        position: 'relative',
        pr: '48px',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          right: '8px',
          top: '96px',
        }}
      >
        <IconButton
          onClick={navigateToProportionParameterTable}
          sx={{ color: 'white', backgroundColor: '#EBD82E', '&:hover': { backgroundColor: '#EBD82E' } }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={navigateToSafeWaterCalculation}
          sx={{ color: 'white', backgroundColor: '#B2D765', '&:hover': { backgroundColor: '#B2D765' }, mt: 2 }}
        >
          <CalculatorIcon />
        </IconButton>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ pb: 2, borderBottom: '1px solid #697EA3' }}
        justifyContent="space-between"
      >
        <Stack flexDirection="row" alignItems="center">
          <LabelInput
            label={i18T('PROPORTION.PROP_NAME')}
            value={formik.values.propName}
            valueWidth="180px"
            isError={!!(formik.touched.propName && formik.errors.propName)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('propName', e.target.value)}
          />
          <LabelInput
            label={`${i18T('PROPORTION.DESIGN_STR')}(kgf/cm²)`}
            value={dsgnStr}
            editable={false}
            valueWidth="70px"
          />
          <Stack flexDirection="row" alignItems="center">
            <Typography sx={{ fontSize: '20px', fontWeight: 900, color: '#002F8C', mr: 1 }}>{`${i18T(
              'PROPORTION.MIXER',
            )} :`}</Typography>
            <Typography sx={{ fontSize: '20px', fontWeight: 900, color: '#002F8C', mr: 1 }}>
              {proportion.selectedMixer === '1'
                ? i18T('PROPORTION.MIXER_NUMBER', { number: 1 })
                : i18T('PROPORTION.MIXER_NUMBER', { number: 2 })}
            </Typography>
          </Stack>
        </Stack>
        <LabelInput
          label={`${i18T('PROPORTION.SLUMP')}(cm)`}
          value={formik.values.slump}
          isError={!!(formik.touched.slump && formik.errors.slump)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('slump', e.target.value)}
        />
        <LabelInput
          label={`${i18T('PROPORTION.GRAIN_SIZE')}(mm)`}
          value={formik.values.grainSize}
          isError={!!(formik.touched.grainSize && formik.errors.grainSize)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('grainSize', e.target.value)}
        />
      </Stack>
      {initialValues && (
        <Stack flexDirection="row">
          <Box sx={{ width: '50%', p: 1 }}>
            <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
              <CardContent>
                <Stack flexDirection="row" justifyContent="space-between">
                  <LabelInputV2
                    label={`${i18T('PROPORTION.QUANTITY_MIXER_WATER')}(kg)`}
                    value={formik.values.quantityMixingWater}
                    letterSpacing={8}
                    rootWidth="48%"
                    sx={{ width: '100%' }}
                    isError={!!(formik.touched.quantityMixingWater && formik.errors.quantityMixingWater)}
                    onChange={(text: string) => formik.setFieldValue('quantityMixingWater', text)}
                  />
                  <LabelInputV2
                    label={`${i18T('PROPORTION.WATER_COLLOID_RATIO')}(W/B)`}
                    value={formik.values.waterColloidRatio}
                    letterSpacing={8}
                    rootWidth="48%"
                    sx={{ width: '100%' }}
                    isError={!!(formik.touched.waterColloidRatio && formik.errors.waterColloidRatio)}
                    onChange={(text: string) => formik.setFieldValue('waterColloidRatio', text)}
                  />
                </Stack>
                <Stack flexDirection="row" justifyContent="space-between">
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.RECYCLED_WATER_USE_RATIO')}(%)`}
                    value={formik.values.recycledWater}
                    letterSpacing={8}
                    rootWidth="48%"
                    sx={{ width: '100%' }}
                    isError={!!(formik.touched.recycledWater && formik.errors.recycledWater)}
                    onChange={(text: string) => formik.setFieldValue('recycledWater', text)}
                  />
                  <LabelInputV2
                    label={`${i18T('PROPORTION.TAP_WATER_USE_RATIO')}(%)`}
                    value={100 - +formik.values.recycledWater}
                    editable={false}
                    letterSpacing={8}
                    rootWidth="48%"
                    sx={{ width: '100%' }}
                  />
                </Stack>
                {showFirstSection()}
              </CardContent>
            </Card>
            <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
              <CardContent>
                <Stack flexDirection="row" justifyContent="space-between">
                  <LabelInputV2
                    label={`${i18T('PROPORTION.SAND_AND_STONE_WEIGHT')}(kg)`}
                    value={formik.values.sandAndStoneTtlWt}
                    letterSpacing={12}
                    rootWidth="48%"
                    sx={{ width: '100%' }}
                    isError={!!(formik.touched.sandAndStoneTtlWt && formik.errors.sandAndStoneTtlWt)}
                    onChange={(text: string) => formik.setFieldValue('sandAndStoneTtlWt', text)}
                  />
                  <LabelInputV2
                    label={`${i18T('PROPORTION.SAND_RATIO')}(%)`}
                    letterSpacing={12}
                    value={formik.values.sandPct}
                    rootWidth="48%"
                    sx={{ width: '100%' }}
                    isError={!!(formik.touched.sandPct && formik.errors.sandPct)}
                    onChange={(text: string) => formik.setFieldValue('sandPct', text)}
                  />
                  <Stack sx={{ my: 1 }} flexDirection="row" alignItems="center">
                    <IconButton
                      sx={{ color: '#002F8C', height: '70%', ':hover': { backgroundColor: '#eee' } }}
                      onClick={() => setChangePercentAndKg((prev) => !prev)}
                    >
                      <TransferIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                {changePercentAndKg ? (
                  <>
                    <ValueCheckWrapper
                      values={[
                        Number(formik.values.stone1Pct),
                        Number(formik.values.stone2Pct),
                        Number(formik.values.stone3Pct),
                        Number(formik.values.stone4Pct),
                      ]}
                    >
                      <Stack flexDirection="row" justifyContent="space-between">
                        <LabelInputV2
                          label={`${i18T('PROPORTION.STONE1')}(%)`}
                          value={formik.values.stone1Pct}
                          {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.stone1Pct && formik.errors.stone1Pct)}
                          onChange={(text: string) => formik.setFieldValue('stone1Pct', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.STONE2')}(%)`}
                          value={formik.values.stone2Pct}
                          {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.stone2Pct && formik.errors.stone2Pct)}
                          onChange={(text: string) => formik.setFieldValue('stone2Pct', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.STONE3')}(%)`}
                          value={formik.values.stone3Pct}
                          {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.stone3Pct && formik.errors.stone3Pct)}
                          onChange={(text: string) => formik.setFieldValue('stone3Pct', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.STONE4')}(%)`}
                          value={formik.values.stone4Pct}
                          {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.stone4Pct && formik.errors.stone4Pct)}
                          onChange={(text: string) => formik.setFieldValue('stone4Pct', text)}
                        />
                      </Stack>
                    </ValueCheckWrapper>
                    <ValueCheckWrapper
                      values={[
                        Number(formik.values.sand1Pct),
                        Number(formik.values.sand2Pct),
                        Number(formik.values.sand3Pct),
                        Number(formik.values.sand4Pct),
                      ]}
                    >
                      <Stack flexDirection="row" justifyContent="space-between">
                        <LabelInputV2
                          label={`${i18T('PROPORTION.SAND1')}(%)`}
                          value={formik.values.sand1Pct}
                          {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.sand1Pct && formik.errors.sand1Pct)}
                          onChange={(text: string) => formik.setFieldValue('sand1Pct', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.SAND2')}(%)`}
                          value={formik.values.sand2Pct}
                          {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.sand2Pct && formik.errors.sand2Pct)}
                          onChange={(text: string) => formik.setFieldValue('sand2Pct', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.SAND3')}(%)`}
                          value={formik.values.sand3Pct}
                          {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.sand3Pct && formik.errors.sand3Pct)}
                          onChange={(text: string) => formik.setFieldValue('sand3Pct', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.SAND4')}(%)`}
                          value={formik.values.sand4Pct}
                          {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.sand4Pct && formik.errors.sand4Pct)}
                          onChange={(text: string) => formik.setFieldValue('sand4Pct', text)}
                        />
                      </Stack>
                    </ValueCheckWrapper>
                    {showSecondKgSection()}
                  </>
                ) : (
                  <>
                    <ValueCheckWrapper
                      values={[
                        Number(calcStone1Pct),
                        Number(calcStone2Pct),
                        Number(calcStone3Pct),
                        Number(calcStone4Pct),
                      ]}
                    >
                      <Stack flexDirection="row" justifyContent="space-between">
                        <LabelInputV2
                          label={`${i18T('PROPORTION.STONE1')}(kg)`}
                          value={formik.values.stone1Kg}
                          {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.stone1Kg && formik.errors.stone1Kg)}
                          onChange={(text: string) => formik.setFieldValue('stone1Kg', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.STONE2')}(kg)`}
                          value={formik.values.stone2Kg}
                          {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.stone2Kg && formik.errors.stone2Kg)}
                          onChange={(text: string) => formik.setFieldValue('stone2Kg', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.STONE3')}(kg)`}
                          value={formik.values.stone3Kg}
                          {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.stone3Kg && formik.errors.stone3Kg)}
                          onChange={(text: string) => formik.setFieldValue('stone3Kg', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.STONE4')}(kg)`}
                          value={formik.values.stone4Kg}
                          {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.stone4Kg && formik.errors.stone4Kg)}
                          onChange={(text: string) => formik.setFieldValue('stone4Kg', text)}
                        />
                      </Stack>
                    </ValueCheckWrapper>
                    <ValueCheckWrapper
                      values={[Number(calcSand1Pct), Number(calcSand2Pct), Number(calcSand3Pct), Number(calcSand4Pct)]}
                    >
                      <Stack flexDirection="row" justifyContent="space-between">
                        <LabelInputV2
                          label={`${i18T('PROPORTION.SAND1')}(kg)`}
                          value={formik.values.sand1Kg}
                          {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.sand1Kg && formik.errors.sand1Kg)}
                          onChange={(text: string) => formik.setFieldValue('sand1Kg', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.SAND2')}(kg)`}
                          value={formik.values.sand2Kg}
                          {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.sand2Kg && formik.errors.sand2Kg)}
                          onChange={(text: string) => formik.setFieldValue('sand2Kg', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.SAND3')}(kg)`}
                          value={formik.values.sand3Kg}
                          {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.sand3Kg && formik.errors.sand3Kg)}
                          onChange={(text: string) => formik.setFieldValue('sand3Kg', text)}
                        />
                        <LabelInputV2
                          label={`${i18T('PROPORTION.SAND4')}(kg)`}
                          value={formik.values.sand4Kg}
                          {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                          isError={!!(formik.touched.sand4Kg && formik.errors.sand4Kg)}
                          onChange={(text: string) => formik.setFieldValue('sand4Kg', text)}
                        />
                      </Stack>
                    </ValueCheckWrapper>
                    {showSecondPercentSection()}
                  </>
                )}
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '50%', p: 1 }}>
            <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
              <CardContent>
                <Stack flexDirection="row" justifyContent="space-between">
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.CEMENT')}(kg)`}
                    value={formik.values.cementWt}
                    isError={!!(formik.touched.cementWt && formik.errors.cementWt)}
                    onChange={(text: string) => formik.setFieldValue('cementWt', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.SLAG')}(kg)`}
                    value={formik.values.slagWt}
                    isError={!!(formik.touched.slagWt && formik.errors.slagWt)}
                    onChange={(text: string) => formik.setFieldValue('slagWt', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.FLY_ASH')}(kg)`}
                    value={formik.values.flyAshWt}
                    isError={!!(formik.touched.flyAshWt && formik.errors.flyAshWt)}
                    onChange={(text: string) => formik.setFieldValue('flyAshWt', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.MICROSILICA')}(kg)`}
                    value={formik.values.microsilicaWt}
                    isError={!!(formik.touched.microsilicaWt && formik.errors.microsilicaWt)}
                    onChange={(text: string) => formik.setFieldValue('microsilicaWt', text)}
                  />
                </Stack>
                {showThirdSection()}
              </CardContent>
            </Card>
            <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
              <CardContent>
                <Stack flexDirection="row" justifyContent="space-between">
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.NORMAL_PORTLAND_CEMENT_RATIO')}(%)`}
                    value={formik.values.normalPortlandCementRatio}
                    letterSpacing={12}
                    rootWidth="48%"
                    sx={{ width: '100%' }}
                    isError={!!(formik.touched.normalPortlandCementRatio && formik.errors.normalPortlandCementRatio)}
                    onChange={(text: string) => formik.setFieldValue('normalPortlandCementRatio', text)}
                  />
                  <LabelInputV2
                    label={`${i18T('PROPORTION.SECOND_PORTLAND_CEMENT_RATIO')}(%)`}
                    value={100 - formik.values.normalPortlandCementRatio}
                    letterSpacing={12}
                    rootWidth="48%"
                    sx={{ width: '100%' }}
                    editable={false}
                  />
                </Stack>
                <Stack flexDirection="row" justifyContent="space-between">
                  <Box sx={{ ...rowBoxStyle, width: '48%' }}>
                    <RowContent
                      labels={[`${i18T('PROPORTION.NORMAL_PORTLAND_CEMENT_WEIGHT')}(kg)`]}
                      values={[
                        getInteger(
                          (formik.values.cementWt * (formik.values.normalPortlandCementRatio / 100))?.toFixed(2),
                        ),
                      ]}
                      showBorder={false}
                      labelsx={rowLabelsx}
                      valuesx={rowValuesx}
                    />
                  </Box>
                  <Box sx={{ ...rowBoxStyle, width: '48%' }}>
                    <RowContent
                      labels={[`${i18T('PROPORTION.SECOND_PORTLAND_CEMENT_WEIGHT')}(kg)`]}
                      values={[
                        getInteger(
                          (formik.values.cementWt * (1 - formik.values.normalPortlandCementRatio / 100))?.toFixed(2),
                        ),
                      ]}
                      showBorder={false}
                      labelsx={rowLabelsx}
                      valuesx={rowValuesx}
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
            <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
              <CardContent>
                <Stack flexDirection="row">
                  <LabelInputV2
                    label={
                      <>
                        {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 1 })}(%)`}
                        <br />
                        {`${proportionMaintenance.chemAdmx1Name ?? ''}`}
                      </>
                    }
                    letterSpacing={2}
                    value={formik.values.chemAdmx1UsagePct}
                    rootWidth="32%"
                    sx={{ width: '100%' }}
                    isError={!!(formik.touched.chemAdmx1UsagePct && formik.errors.chemAdmx1UsagePct)}
                    onChange={(text: string) => formik.setFieldValue('chemAdmx1UsagePct', text)}
                  />
                  {proportionMaintenance.chemAdmx2Name && (
                    <LabelInputV2
                      label={
                        <>
                          {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 2 })}(%)`}
                          <br />
                          {`${proportionMaintenance.chemAdmx2Name ?? ''}`}
                        </>
                      }
                      letterSpacing={2}
                      value={formik.values.chemAdmx2UsagePct}
                      rootWidth="32%"
                      sx={{ width: '100%' }}
                      isError={!!(formik.touched.chemAdmx2UsagePct && formik.errors.chemAdmx2UsagePct)}
                      onChange={(text: string) => formik.setFieldValue('chemAdmx2UsagePct', text)}
                    />
                  )}
                  {proportionMaintenance.chemAdmx3Name && (
                    <LabelInputV2
                      label={
                        <>
                          {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 3 })}(%)`}
                          <br />
                          {`${proportionMaintenance.chemAdmx3Name ?? ''}`}
                        </>
                      }
                      letterSpacing={2}
                      value={formik.values.chemAdmx3UsagePct}
                      rootWidth="32%"
                      sx={{ width: '100%' }}
                      isError={!!(formik.touched.chemAdmx3UsagePct && formik.errors.chemAdmx3UsagePct)}
                      onChange={(text: string) => formik.setFieldValue('chemAdmx3UsagePct', text)}
                    />
                  )}
                </Stack>
                {proportionMaintenance.chemAdmx4Name && (
                  <Stack flexDirection="row">
                    <LabelInputV2
                      label={
                        <>
                          {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 4 })}(%)`}
                          <br />
                          {`${proportionMaintenance.chemAdmx4Name ?? ''}`}
                        </>
                      }
                      letterSpacing={2}
                      value={formik.values.chemAdmx4UsagePct}
                      rootWidth="32%"
                      sx={{ width: '100%' }}
                      isError={!!(formik.touched.chemAdmx4UsagePct && formik.errors.chemAdmx4UsagePct)}
                      onChange={(text: string) => formik.setFieldValue('chemAdmx4UsagePct', text)}
                    />

                    {proportionMaintenance.chemAdmx5Name && (
                      <LabelInputV2
                        label={
                          <>
                            {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 5 })}(%)`}
                            <br />
                            {`${proportionMaintenance.chemAdmx5Name ?? ''}`}
                          </>
                        }
                        letterSpacing={2}
                        value={formik.values.chemAdmx5UsagePct}
                        rootWidth="32%"
                        sx={{ width: '100%' }}
                        isError={!!(formik.touched.chemAdmx5UsagePct && formik.errors.chemAdmx5UsagePct)}
                        onChange={(text: string) => formik.setFieldValue('chemAdmx5UsagePct', text)}
                      />
                    )}
                    {proportionMaintenance.chemAdmx6Name && (
                      <LabelInputV2
                        label={
                          <>
                            {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 6 })}(%)`}
                            <br />
                            {`${proportionMaintenance.chemAdmx6Name ?? ''}`}
                          </>
                        }
                        letterSpacing={2}
                        value={formik.values.chemAdmx6UsagePct}
                        rootWidth="32%"
                        sx={{ width: '100%' }}
                        isError={!!(formik.touched.chemAdmx6UsagePct && formik.errors.chemAdmx6UsagePct)}
                        onChange={(text: string) => formik.setFieldValue('chemAdmx6UsagePct', text)}
                      />
                    )}
                  </Stack>
                )}
                {showFourthSection()}
                {showResultSection()}
                {showCarbonEmissionSection()}
              </CardContent>
            </Card>
            <Stack flexDirection="row" justifyContent="flex-end" sx={{ my: 2 }}>
              <YatungButton sx={{ mr: 1 }} color="red" onClick={navigateBack} startIcon={<CancelIcon />}>
                {i18T('GLOBAL.CANCEL')}
              </YatungButton>
              <YatungButton color="blue" onClick={formik.handleSubmit} startIcon={<SaveIcon />}>
                {i18T('GLOBAL.SAVE')}
              </YatungButton>
            </Stack>
          </Box>
        </Stack>
      )}
      <YatungConfirmAlert
        isShowAlert={isShowAlert}
        alertText={alertText}
        onOk={() => postNewProportion(postRequest as PostProportionRequest)}
        onClose={() => setIsShowAlert(false)}
      />
    </Box>
  );
}

export default memo(EditProportionForm);
