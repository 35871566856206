import { MaterialReceiptsMerge } from '@Src/_basic/object/MaterialReceiptsType';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface Props {
  exportIdRef: any;
  query: any;
  selectedAreaName: string | undefined;
  factoriesName: string | undefined;
  specificationName: string | undefined;
  typesName: string | undefined;
  totalReceipts: Array<any>;
  exporting: boolean;
}
export default function MaterialsReceiptsExcelTable(props: Props) {
  const {
    exportIdRef,
    query,
    selectedAreaName,
    factoriesName,
    typesName,
    totalReceipts,
    exporting,
    specificationName,
  } = props;

  const totalReceiptsName = [
    { name: '資料筆數' },
    { name: '建單時間' },
    { name: '結單時間' },
    { name: '車號' },
    { name: '供應商' },
    { name: '來源' },
    { name: '規格' },
    { name: '總重(kg)' },
    { name: '空重(kg)' },
    { name: '淨重(kg)' },
    { name: '送貨單淨重(kg)' },
    { name: '差異量(kg)' },
    { name: '入料料庫' },
    { name: '送貨狀態' },
  ];
  const [receiptsMergedData, setReceiptsMergedData] = useState<Array<MaterialReceiptsMerge>>([]);
  const [receiptsNetWeight, setReceiptsNetWeight] = useState<number>(0);
  const [receiptsDiff, setReceiptsDiff] = useState<number>(0);

  useEffect(() => {
    const mergedData = totalReceipts.reduce((result: any, item: any) => {
      const existingItem = result.find((element: any) => element.specification === item.specification);
      if (existingItem) {
        existingItem.grossWeight += item.grossWeight;
        existingItem.emptyWeight += item.emptyWeight;
        existingItem.exportValue += item.exportValue;
        existingItem.diff += item.diff;
      } else {
        result.push({
          specification: item.specification,
          grossWeight: item.grossWeight,
          emptyWeight: item.emptyWeight,
          exportValue: item.exportValue,
          diff: item.diff,
        });
      }
      return result;
    }, []);
    setReceiptsMergedData(mergedData);
    const totalNetWeight = mergedData.reduce((prev: any, next: any) => {
      return prev + (next.grossWeight - next.emptyWeight);
    }, 0);
    setReceiptsNetWeight(totalNetWeight);
    const totalDiff = mergedData.reduce((prev: any, next: any) => {
      return prev + next.diff;
    }, 0);
    setReceiptsDiff(totalDiff);
  }, [exporting]);

  return (
    <table id="table" ref={exportIdRef} style={{ display: 'none' }}>
      <tbody>
        <tr>
          <td colSpan={11} style={{ textAlign: 'center', fontSize: '32px' }}>
            原料進廠統計表
          </td>
        </tr>
        <tr>
          <td>資料期間：</td>
          <td>
            {moment(query.startTime).format('YYYY/MM/DD ')}~{moment(query.endTime).format('YYYY/MM/DD ')}
          </td>
          <td>區域：</td>
          <td>{selectedAreaName}</td>
          <td>廠別：</td>
          <td>{factoriesName}</td>
          <td>物料類別：</td>
          <td>{typesName}</td>
          <td>物料規格：</td>
          <td>{specificationName}</td>
        </tr>
        <tr></tr>
        {receiptsMergedData.map((value: any, index: number) => {
          return (
            <tr key={index}>
              <td>{value.specification + '合計：'}</td>
              <td>{`${value.grossWeight - value.emptyWeight}kg`}</td>
              <td>{value.specification + '送貨合計：'}</td>
              <td>{`${value.exportValue}kg`}</td>
              <td>{value.specification + '合計差異：'}</td>
              <td>{`${value.diff}kg`}</td>
            </tr>
          );
        })}
        <tr>
          <td>總計入料：</td>
          <td>{`${receiptsNetWeight}kg`}</td>
          <td>總計差異量：</td>
          <td>{`${receiptsDiff}kg`}</td>
        </tr>
        <tr></tr>
        <tr>
          {totalReceiptsName.map((value: any, index) => {
            return <td key={index}>{value.name}</td>;
          })}
        </tr>
        {totalReceipts.map((value: any, index: number) => {
          return (
            <tr key={value.id}>
              <td>{index + 1}</td>
              <td>{moment(value.createTime).format('YYYY/MM/DD HH:mm')}</td>
              <td>{moment(value.receiptCompleteTime).format('YYYY/MM/DD HH:mm')}</td>
              <td>{value.plateNumber}</td>
              <td>{value.supplier}</td>
              <td>{value.source}</td>
              <td>{value.specification}</td>
              <td>{value.grossWeight ? value.grossWeight : 0}</td>
              <td>{value.emptyWeight ? value.emptyWeight : 0}</td>
              <td>{value.grossWeight - value.emptyWeight}</td>
              <td>{value.exportValue ? value.exportValue : 0}</td>
              <td>{value.diff ? value.diff : 0}</td>
              <td>{value.storage}</td>
              <td>{value.status}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
