import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import { MixerData } from '@Src/_basic/object/MixerTypes';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import MixerInformationTable from './MixerInformationTable';
import MixerInformationtSearchBar from './MixerInformationtSearchBar';

export interface AreaOptions extends Options {
  factories: Options[];
}

type FilterValueType = {
  areaId: number;
};

export default function MixerInformationCard() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<FilterValueType>();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [areaId, setAreaId] = useState<number>(0);
  const [mixerData, setMixerData] = useState<MixerData[]>([]);

  const getAllMixerBySelectArea = useCallback(
    (id: number) => {
      setIsVisible(false);
      setAreaId(id);
      MixerApi.getAllMixerByAreaId(id, (_data) => {
        setSaveRequest({
          page: location.pathname,
          request: {
            areaId: id,
          },
        });
        setTimeout(() => {
          setMixerData(_data.map((item, index) => ({ ...item, ids: index + 1 })));
          setIsVisible(true);
        }, 500);
      });
    },
    [setSaveRequest, location.pathname],
  );

  useEffect(() => {
    if (request?.page === location.pathname && request?.request) {
      const { areaId } = request.request;
      getAllMixerBySelectArea(areaId);
    }
  }, []);

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.MIXER_INFORMATION.ROUTE_TITLE')}
      body={
        <>
          <MixerInformationtSearchBar getAllMixerBySelectArea={getAllMixerBySelectArea} areaId={areaId} />

          <YatungFadeInOut isVisible={isVisible} y={100}>
            <MixerInformationTable mixerData={mixerData} />
          </YatungFadeInOut>
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
