import YatungButton from '@Src/_basic/components/YatungButton';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import YatungTextSelect, { TextOptions } from '@Src/_basic/components/YatungTextSelect';
import { Stack, Typography } from '@mui/material';
import { ErrorMessage } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UploadFileCard from './UploadFileCard';

const initPeriodValueOptions = [
  {
    text: '1',
    value: 1,
  },
  {
    text: '2',
    value: 2,
  },
  {
    text: '3',
    value: 3,
  },
  {
    text: '4',
    value: 4,
  },
  {
    text: '5',
    value: 5,
  },
  {
    text: '6',
    value: 6,
  },
  {
    text: '7',
    value: 7,
  },
  {
    text: '8',
    value: 8,
  },
  {
    text: '9',
    value: 9,
  },
  {
    text: '10',
    value: 10,
  },
];

const initPeriodUnitValueOptions = [
  {
    text: '年',
    value: 'YEAR',
  },
  {
    text: '月',
    value: 'MONTH',
  },
  {
    text: '周',
    value: 'WEEK',
  },
];
interface Props {
  userGroupsData: any;
  entityOptions: TextOptions[];
  createFactory: number | null;
  testCreateKeeper: string | null;
  createPrioedNumber: number | null;
  createPrioedUnit: string | null;
  inputNameValues: string;
  inputPeriodValues: string;
  setInputPeriodValues: (e: string) => void;
  setInputNameValues: (e: string) => void;
  setCreateFactory: (e: number) => void;
  setTestCreateKeeper: (e: string) => void;
  setCreatePrioedNumber: (e: number) => void;
  setCreatePrioedUnit: (e: string) => void;
  onCreateGoBackButtonClick: () => void;
  handleChange: (event: any) => void;
  files: FileList | undefined;
}

export default function EquipmentCreateField(props: Props) {
  const {
    createFactory,
    testCreateKeeper,
    createPrioedNumber,
    createPrioedUnit,
    inputNameValues,
    inputPeriodValues,
    setInputPeriodValues,
    setInputNameValues,
    setCreateFactory,
    setCreatePrioedNumber,
    setTestCreateKeeper,
    setCreatePrioedUnit,
    userGroupsData,
    entityOptions,
    onCreateGoBackButtonClick,
    handleChange,
    files,
  } = props;

  const list = [];

  if (files) {
    for (let i = 0; i < files?.length; i++) {
      list.push(i ? `、${files[i]?.name}` : files[i]?.name);
    }
  }

  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack flexDirection={'column'} sx={{ mt: 1 }}>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.EQUIPMENT')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungInput
            id="name"
            name="name"
            value={inputNameValues}
            sx={{ width: '100%' }}
            onChange={(e: any) => setInputNameValues(e.target.value)}
          />
        </Stack>

        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.EQUIPMENT_NUMBERS')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungInput
            id="propertyCode"
            name="propertyCode"
            value={inputPeriodValues}
            sx={{ width: '100%' }}
            onChange={(e: any) => setInputPeriodValues(e.target.value)}
          />
        </Stack>

        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.MAINTENANCETIME')} />
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.FACTORY_TYPE')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <div style={{ display: 'flex' }}>
            <YatungSelect
              options={initPeriodValueOptions}
              value={createPrioedNumber}
              placeholder={''}
              onChange={(e) => {
                setCreatePrioedNumber(e.target.value as number);
              }}
              style={{ maxWidth: '135px' }}
              width={'135px'}
            />
            <YatungTextSelect
              options={initPeriodUnitValueOptions}
              value={createPrioedUnit}
              placeholder={''}
              onChange={(e) => {
                setCreatePrioedUnit(e.target.value as string);
              }}
              style={{ maxWidth: '135px', paddingBottom: '5px', marginRight: '10px' }}
              width={'135px'}
            />
          </div>

          <YatungSelect
            options={userGroupsData?.factories}
            value={createFactory}
            onChange={(e) => {
              setCreateFactory(e.target.value as number);
            }}
            style={{ minWidth: '270px' }}
            width={'270px'}
          />
        </Stack>

        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.KEEPERNAME')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungTextSelect
            options={entityOptions ? [...entityOptions] : []}
            value={testCreateKeeper}
            onChange={(e) => {
              setTestCreateKeeper(e.target.value as string);
            }}
            style={{ maxWidth: '550px', marginRight: '10px' }}
            width={'550px'}
          />
        </Stack>

        <div className="output" style={{ maxWidth: '550px', marginRight: '10px' }}>
          新增的檔案：{list}
        </div>

        <UploadFileCard handleChange={handleChange} />

        <ErrorMessage
          name={'typeIds'}
          render={(msg) => (
            <Stack flexDirection={'row'} sx={{ ml: 1, mt: 1 }}>
              <Typography sx={{ fontSize: 14, color: '#d32f2f' }}>{msg}</Typography>
            </Stack>
          )}
        />

        <Stack
          spacing={3}
          sx={{ bgcolor: 'white', minWidth: 500, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
          display="flex"
          flexDirection="column"
        >
          <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <YatungButton
              text={i18T('SAMPLEMANAGEMENT.CANCEL')}
              onClick={onCreateGoBackButtonClick}
              sx={{
                color: '#ffffff',
                bgcolor: '#CB333B',
                fontSize: '24px',
                width: 120,
                mr: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#CB333B',
                },
              }}
            />
            <YatungButton
              text={i18T('SAMPLEMANAGEMENT.COMPLETE')}
              type="submit"
              sx={{
                border: '1px solid #fff',
                color: '#ffffff',
                bgcolor: '#002F8C',
                fontSize: '24px',
                width: 120,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#002F8C',
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
