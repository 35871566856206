import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import EntityScreen from './screens/EntityScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <EntityScreen />,
  },
];

const EntityManagementRouter = () => PermissionRoute(routesConfig);

export default memo(EntityManagementRouter);
