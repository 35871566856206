import React from 'react';
import LoginForm from '../components/LoginForm';
import { toAbsoluteUrl } from '@Src/_basic/helpers/AssetHelpers';
import { Grid, Box } from '@mui/material';
import AuthFooter from '../components/AuthFooter';

const bgImgSrc = '/media/images/polygon.png';
const logoSrc = '/media/images/YATUNG_logo_login.png';

export default function AuthScreen() {
  return (
    <Box
      sx={{
        backgroundImage: `url("${bgImgSrc}")`,
        backgroundSize: 'cover',
        height: '100vh',
        width: '100vw',
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <Grid container justifyContent={'center'} alignItems={'center'} item xs={12}>
        <img src={toAbsoluteUrl(logoSrc)} className="App-logo" alt="logo" />
        <br />
      </Grid>
      <Grid container justifyContent={'center'} alignItems={'start'} item xs={12}>
        <LoginForm />
      </Grid>
      <Grid container justifyContent={'center'} alignItems={'end'} item xs={12}>
        <AuthFooter />
      </Grid>
    </Box>
  );
}
