import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function RightArrow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="85" height="85" viewBox="0 0 85 85" fill="none">
      <rect width="85" height="85" rx="9" fill="#003087" />

      <path
        d="M41.3898 20.6021L45.9919 16L71.9922 42.0003L45.9919 68.0006L41.3898 63.3985L62.7881 42.0003L41.3898 20.6021Z"
        fill="white"
      />
      <path
        d="M12.7917 20.6021L17.3938 16L43.394 42.0003L17.3938 68.0006L12.7917 63.3985L34.1899 42.0003L12.7917 20.6021Z"
        fill="white"
      />
    </SvgIcon>
  );
}
