import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { GroupData, UserGroupData } from '@Src/_basic/object/AccountManagementType';
import {
  AssignGroupsData,
  Content,
  ReportGroupsData,
  RightsData,
  TransferOrderGroupsData,
  UsersData,
} from '@Src/_basic/object/AccountRightType';
import { AreaData } from '@Src/_basic/object/AreaType';
import { FactoryItem } from '@Src/_basic/object/FactoryType';
import { AssignGroupApi } from '@Src/_basic/protocol/accountRights/assignGroup/AssignGroupApi';
import { GroupApi } from '@Src/_basic/protocol/accountRights/fetAuth/group/GroupApi';
import { RoleApi } from '@Src/_basic/protocol/accountRights/fetAuth/role/RoleApi';
import { ReportGroupApi } from '@Src/_basic/protocol/accountRights/reportGroup/ReportGroupApi';
import { TransferOrderGroupApi } from '@Src/_basic/protocol/accountRights/transferOrderGroup/TransferOrderGroupApi';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import { Checkbox, SelectChangeEvent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubmitButton from './components/SubmitButton';

interface AreasOptions extends Options {
  factoryList: Array<FactoryList>;
}

interface FactoryList extends Options {
  factoryCode: string;
  branchCode: string;
}

interface GroupOptions extends Options {
  users: Array<UsersData>;
  code: string;
  name: string;
}

interface Data {
  id: string | number;
  code?: string;
  name: string;
  jobtitle: string;
  factory?: number;
}

interface Props {
  value: number;
  onGoBackButtonClick: () => void;
  userGroupData: UserGroupData;
  setUserGroupData: React.Dispatch<Partial<UserGroupData>>;
}

export default function EditAccountCreateForm(props: Props) {
  const { t: i18T } = useTranslation();
  const { value, onGoBackButtonClick, userGroupData, setUserGroupData } = props;
  const [data, setData] = useState<Array<Data>>([]);
  const [areaOptions, setAreaOptions] = useState<Array<AreasOptions>>([]);
  const [factoriesOptions, setFactoriesOptions] = useState<Array<Options>>([]);
  const [factoryList, setFactoryList] = useState<Array<FactoryList>>([]);
  const [factoryId, setFactoryId] = useState<number>(0);
  const [groupOptions, setGroupOptions] = useState<Array<GroupOptions>>([]);
  const [groupId, setGroupId] = useState<number>(0);
  const [groupIds, setGroupIds] = useState<Array<number>>([]);
  const [columns, setColumns] = useState<Array<Column>>([]);
  const [selectData, setSelectData] = useState<Array<GroupData>>([]);

  const getAllAreas = () => {
    AreaApi.getAllAreas((_data: Array<AreaData>) => {
      const areasOptions: Array<AreasOptions> = _data.map((area: AreaData) => ({
        value: area.id,
        text: area.name,
        factoryList: area.factoryList.map((factory: FactoryItem) => ({
          value: factory.factoryId,
          text: factory.factoryName,
          factoryCode: factory.factoryCode,
          branchCode: factory.branchCode,
        })),
      }));
      setAreaOptions(areasOptions);
    });
  };

  const handleFilterFactory = (areaValue: number) => {
    selectData.splice(0);
    setFactoryList(areaOptions.find((area: AreasOptions) => area.value === areaValue)?.factoryList ?? []);
  };

  const handleCheckBoxSelectData = (row: Data, event: React.ChangeEvent<HTMLInputElement>) => {
    const index = selectData.findIndex((element) => element.id.toString() === row.id.toString());
    if (event.target.checked) {
      const _data = { id: row.id.toString(), code: row.code, name: row.name, factory: row.factory };
      if (index === -1) selectData.push(_data);
    } else {
      if (index !== -1) selectData.splice(index, 1);
    }
  };

  useEffect(() => {
    if (value === 1) {
      getAllAreas();
    } else {
      setFactoriesOptions(
        userGroupData.factoryIds.map((item: GroupData) => ({ value: Number(item.id), text: item.name })),
      );
    }

    if (value === 1 || value === 3) {
      setColumns([
        {
          text: undefined,
          dataField: '',
          formatter: ({ row }) => {
            return (
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxSelectData(row, event)}
              />
            );
          },
        },
        {
          text: i18T('GLOBAL.NO'),
          dataField: 'id',
        },
        {
          text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.FACTORY_NAME'),
          dataField: 'name',
          formatter: ({ row }) => {
            return (
              <>
                <Typography>{row.code}</Typography>
                <Typography>{row.name}</Typography>
              </>
            );
          },
        },
      ]);
    } else if (value === 2) {
      setColumns([
        {
          text: undefined,
          dataField: '',
          width: '40%',
          formatter: ({ row }) => {
            return (
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxSelectData(row, event)}
              />
            );
          },
        },
        {
          text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.GROUP_CODE'),
          dataField: 'name',
          formatter: ({ row }) => {
            return (
              <>
                <Typography>{row.name}</Typography>
              </>
            );
          },
        },
      ]);
    } else {
      setColumns([
        {
          text: undefined,
          dataField: 'none',
          width: '20%',
        },
        {
          text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.NAME_2'),
          dataField: 'name',
        },
        {
          text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.JOB_TITLE'),
          dataField: 'jobtitle',
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (value === 1) {
      setData(
        factoryList
          .map((factory: FactoryList) => ({
            id: factory.value,
            code: factory.factoryCode + factory.branchCode,
            name: factory.text ?? '',
            jobtitle: '',
          }))
          .filter((item) => {
            return userGroupData.factoryIds.find((_data: GroupData) => _data.id.toString() === item.id.toString())
              ? false
              : true;
          }),
      );
    }
  }, [factoryList]);

  useEffect(() => {
    const rightsRequest = {
      pageNumber: 0,
      pageSize: 99999,
      state: '1',
      attrs: [
        {
          code: 'factory',
          attrVal: factoryId.toString(),
        },
      ],
    };
    if (value === 2) {
      rightsRequest.attrs = [
        ...rightsRequest.attrs,
        {
          code: 'type',
          attrVal: 'user',
        },
      ];
      GroupApi.postGetRightsGroups(
        rightsRequest,
        (_data: RightsData<Content>) => setRightsData(_data, userGroupData.groupSettings, factoryId),
        (error) => console.log(error),
      );
    }
    if (value === 3) {
      RoleApi.postGetRightsRoles(
        rightsRequest,
        (_data: RightsData<Content>) => setRightsData(_data, userGroupData.characterSetting, factoryId),
        (error) => console.log(error),
      );
    }
    if (value === 4) {
      ReportGroupApi.getReportGroupsBySearch(
        {
          factoryId,
          keyword: undefined,
          removed: false,
        },
        (_data: Array<ReportGroupsData>) => setGroupData(_data),
        (error) => console.log(error),
      );
    }
    if (value === 5) {
      TransferOrderGroupApi.getTransferOrderGroupsBySearch(
        {
          factoryId,
          keyword: undefined,
          removed: false,
        },
        (_data: Array<TransferOrderGroupsData>) => setGroupData(_data),
        (error) => console.log(error),
      );
    }
    if (value === 6) {
      AssignGroupApi.getAssignGroupsBySearch(
        {
          factoryId,
          keyword: undefined,
          removed: false,
        },
        (_data: Array<AssignGroupsData>) => setGroupData(_data),
        (error) => console.log(error),
      );
    }
  }, [factoryId]);

  const setRightsData = (_data: RightsData<Content>, settingsData: Array<GroupData>, factory: number) => {
    setData(
      _data.content
        .map((content: Content) => ({
          id: content.id,
          code: content.code,
          name: content.name,
          factory: factory,
          jobtitle: '',
        }))
        .filter((item) => {
          return settingsData.find((groupData: GroupData) => groupData.id.toString() === item.id.toString())
            ? false
            : true;
        }),
    );
  };

  const setGroupData = (_data: Array<ReportGroupsData>) => {
    if (value === 4) {
      setGroupOptions(
        _data
          .map((groupData: ReportGroupsData) => ({
            value: groupData.id,
            text: groupData.groupCode + ' ' + groupData.groupName,
            code: groupData.groupCode,
            name: groupData.groupName,
            users: groupData.users,
          }))
          .filter((item) => {
            return userGroupData.supervisorReportGroups.find(
              (_data: GroupData) => _data.id.toString() === item.value.toString(),
            )
              ? false
              : true;
          }),
      );
    }
    if (value === 5) {
      setGroupOptions(
        _data
          .map((groupData: ReportGroupsData) => ({
            value: groupData.id,
            text: groupData.groupCode + ' ' + groupData.groupName,
            code: groupData.groupCode,
            name: groupData.groupName,
            users: groupData.users,
          }))
          .filter((item) => {
            return userGroupData.supervisorTransformOrderGroups.find(
              (_data: GroupData) => _data.id.toString() === item.value.toString(),
            )
              ? false
              : true;
          }),
      );
    }
    if (value === 6) {
      setGroupOptions(
        _data
          .map((groupData: ReportGroupsData) => ({
            value: groupData.id,
            text: groupData.groupCode + ' ' + groupData.groupName,
            code: groupData.groupCode,
            name: groupData.groupName,
            users: groupData.users,
          }))
          .filter((item) => {
            return userGroupData.supervisorAssignGroups.find(
              (_data: GroupData) => _data.id.toString() === item.value.toString(),
            )
              ? false
              : true;
          }),
      );
    }
  };

  useEffect(() => {
    setGroupIds(groupOptions.map((item: GroupOptions) => item.value));
  }, [groupOptions]);

  const onSubmit = () => {
    if (value === 1) {
      setUserGroupData({ factoryIds: [...userGroupData.factoryIds, ...selectData] });
    }
    if (value === 2) {
      setUserGroupData({ groupSettings: [...userGroupData.groupSettings, ...selectData] });
    }
    if (value === 3) {
      setUserGroupData({ characterSetting: [...userGroupData.characterSetting, ...selectData] });
    }
    if (groupId !== 0) {
      const group = groupOptions[groupIds.indexOf(groupId)];
      const data = [
        {
          id: group.value,
          code: group.code,
          name: group.name,
        },
      ];
      if (value === 4) {
        setUserGroupData({ supervisorReportGroups: [...userGroupData.supervisorReportGroups, ...data] });
      }
      if (value === 5) {
        setUserGroupData({
          supervisorTransformOrderGroups: [...userGroupData.supervisorTransformOrderGroups, ...data],
        });
      }
      if (value === 6) {
        setUserGroupData({ supervisorAssignGroups: [...userGroupData.supervisorAssignGroups, ...data] });
      }
    }
    onGoBackButtonClick();
  };

  return (
    <>
      <YatungSelect
        sx={{ my: 1, width: 550 }}
        options={value === 1 ? areaOptions : factoriesOptions}
        onChange={(e: SelectChangeEvent<unknown>) => {
          if (value === 1) {
            handleFilterFactory(e.target.value as number);
          } else {
            setFactoryId(e.target.value as number);
            setData([]);
            setGroupId(0);
          }
        }}
      />
      {value === 1 ? (
        <Typography sx={{ fontSize: 20, letterSpacing: 3, fontWeight: 900 }}>
          {i18T('USERSMANAGEMENU.EDIT_ACCOUNT.CHOOSE_FACTORY')}
        </Typography>
      ) : (
        <></>
      )}
      {value === 4 || value === 5 || value === 6 ? (
        <>
          <Typography sx={{ fontSize: 20, letterSpacing: 3, fontWeight: 900 }}>
            {i18T('USERSMANAGEMENU.EDIT_ACCOUNT.SELECT_REPORT_GROUP')}
          </Typography>
          <YatungSelect
            sx={{ my: 1, width: 550 }}
            options={groupOptions}
            value={groupId}
            onChange={(e: SelectChangeEvent<unknown>) => {
              const groupId = e.target.value as number;
              setGroupId(groupId);
              if (groupIds.indexOf(groupId) === -1) {
                setData([]);
              } else {
                setData(
                  groupOptions[groupIds.indexOf(groupId)].users.map((_data: UsersData) => ({
                    id: _data.id,
                    name: _data.accountName,
                    jobtitle: _data.jobTitle,
                  })),
                );
              }
            }}
          />
          <Typography sx={{ fontSize: 20, letterSpacing: 3, fontWeight: 900 }}>
            {i18T('USERSMANAGEMENU.EDIT_ACCOUNT.MEMBER_LIST')}
          </Typography>
        </>
      ) : (
        <></>
      )}

      <YatungTable
        columns={columns}
        data={data}
        headColor={false}
        isTableMinWidth={false}
        tableCellPadding={5}
        sx={{
          mt: 1,
          borderRadius: 4,
          border: '1px solid gray',
          '&::-webkit-scrollbar': {
            width: 8,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#003087',
            borderRadius: 8,
          },
          '&::-webkit-scrollbar-button': {
            height: 10,
          },
        }}
      />
      <SubmitButton onSubmit={onSubmit} onGoBack={onGoBackButtonClick} />
    </>
  );
}
