import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { NewAppPermissionData } from '@Src/_basic/object/AppPermissionType';
import { usePermission } from '@Src/redux/permission/permissionActions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountColumnFormatter from './columnFormatter/AccountColumnFormatter';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import ApplicationTimeColumnFormatter from './columnFormatter/ApplicationTimeColumnFormatter';
import EmailAddressColumnFormatter from './columnFormatter/EmailAddressColumnFormatter';
import IdColumnFormatter from './columnFormatter/IdColumnFormatter';
import NameColumnFormatter from './columnFormatter/NameColumnFormatter';

interface Props {
  handleSuccessButtonClick: (id: number) => void;
  handleDeleteButtonClick: (id: number) => void;
  loading: boolean;
  page: number;
}
export default function VerificationCodeTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { handleSuccessButtonClick, handleDeleteButtonClick, page, loading } = props;
  const { activePermissionData } = usePermission();
  const [newActivePermissionData, setNewActivePermissionData] = useState<NewAppPermissionData[]>([]);

  const getButtonText = (status: string) => {
    switch (status) {
      case 'success':
        return i18T('VERIFICATIONCODE.SUCCESS_BUTTON');
      case 'fail':
        return i18T('VERIFICATIONCODE.ERROR_BUTTON');
      default:
        return '';
    }
  };
  const getNewAtivePermissionData = () => {
    const storageYardReciptsTemp = activePermissionData?.map((data: any, index: number) => {
      return { ...data, ids: index + 1 + (page - 1) * 10 };
    });
    setNewActivePermissionData(storageYardReciptsTemp);
  };
  useEffect(() => {
    getNewAtivePermissionData();
  }, [activePermissionData]);
  const columns: Array<Column> = [
    {
      text: i18T('VERIFICATIONCODE.NO'),
      dataField: 'ids',
      formatter: IdColumnFormatter,
    },
    {
      text: i18T('VERIFICATIONCODE.EMAIL'),
      dataField: 'emailAddress',
      formatter: EmailAddressColumnFormatter,
    },
    {
      text: i18T('VERIFICATIONCODE.ACCOUNT'),
      dataField: 'account',
      formatter: AccountColumnFormatter,
    },
    {
      text: i18T('VERIFICATIONCODE.NAME'),
      dataField: 'name',
      formatter: NameColumnFormatter,
    },
    {
      text: i18T('VERIFICATIONCODE.APPLY_TIME'),
      dataField: 'applicationTime',
      formatter: ApplicationTimeColumnFormatter,
    },
    {
      text: i18T('VERIFICATIONCODE.FACTORY'),
      dataField: '汐止廠',
    },
    {
      text: i18T('VERIFICATIONCODE.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onSuccessButtonClick: handleSuccessButtonClick,
        onDeleteButtonClick: handleDeleteButtonClick,
        getButtonText: getButtonText,
      },
    },
  ];
  return <YatungTable columns={columns} data={newActivePermissionData} loading={loading} />;
}
