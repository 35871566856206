import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function WaterSmall(props: SvgIconProps) {
  return (
    <svg {...props} width="19" height="23" viewBox="0 0 19 23" fill="none">
      <path
        d="M9.5 22.3175C7.24566 22.3175 5.08365 21.4191 3.48959 19.82C1.89553 18.2208 1 16.052 1 13.7905C1 10.0578 3.87938 7.07228 6.41131 4.33618L9.5 1L12.5887 4.33618C15.1206 7.07334 18 10.0589 18 13.7905C18 16.052 17.1045 18.2208 15.5104 19.82C13.9163 21.4191 11.7543 22.3175 9.5 22.3175V22.3175Z"
        stroke="#7C878E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70032 13.7952C3.93608 13.7952 4.1272 13.9863 4.1272 14.222C4.1272 15.6833 4.70767 17.0847 5.74093 18.1179C6.77419 19.1512 8.17558 19.7317 9.63683 19.7317C9.87258 19.7317 10.0637 19.9228 10.0637 20.1586C10.0637 20.3943 9.87258 20.5854 9.63683 20.5854C7.94915 20.5854 6.3306 19.915 5.13723 18.7216C3.94386 17.5283 3.27344 15.9097 3.27344 14.222C3.27344 13.9863 3.46456 13.7952 3.70032 13.7952Z"
        fill="#7C878E"
        stroke="#7C878E"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00967 11.7935C4.00967 11.9645 3.87109 12.1031 3.70015 12.1031C3.5292 12.1031 3.39062 11.9645 3.39062 11.7935C3.39062 11.6226 3.5292 11.484 3.70015 11.484C3.87109 11.484 4.00967 11.6226 4.00967 11.7935Z"
        fill="#7C878E"
        stroke="#7C878E"
      />
    </svg>
  );
}
