import { AreaData, PostCreateDataRequest } from '@Src/_basic/object/AreaType';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AreaManagementCreateField from './components/AreaManagementCreateField';
import AreaManagementCreateSubmit from './components/AreaManagementCreateSubmit';

interface Props {
  initialValues: PostCreateDataRequest;
  onCreateGoBackButtonClick: () => void;
  setAreaData: (e: Array<AreaData>) => void;
  setLoading: (e: boolean) => void;
}

const areaSchema = Yup.object().shape({
  name: Yup.string().required('請輸入區域名稱'),
  sortingOrder: Yup.number().typeError('請輸入數字').min(1, '請輸入大於1的數字').required('請輸入區域排序'),
});
export default function AreaManagementCreateForm(props: Props) {
  const { initialValues, onCreateGoBackButtonClick, setAreaData, setLoading } = props;
  const [dulpicateWarningMoalOpen, setDulpicateWarningMoalOpen] = useState<boolean>(false);
  const handleSubmit = async (require: PostCreateDataRequest) => {
    setLoading(true);
    AreaApi.postAllAreaById(
      require,
      (datas: AreaData) => {
        AreaApi.getAllAreas((data: Array<AreaData>) => {
          setAreaData(data);
        });
        onCreateGoBackButtonClick();
      },
      () => {
        setDulpicateWarningMoalOpen(true);
      },
      () => {
        setLoading(false);
      },
    );
  };
  const handleDulpicateWarningModalClose = () => {
    setDulpicateWarningMoalOpen(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={areaSchema}
      onSubmit={(values) => handleSubmit(values)}
      enableReinitialize
    >
      {(formik) => {
        return (
          <Form>
            <Grid container justifyContent={'center'} sx={{ my: 2 }} item xs={12}>
              <AreaManagementCreateField
                dulpicateWarningMoalOpen={dulpicateWarningMoalOpen}
                onDulpicateWarningModalClose={handleDulpicateWarningModalClose}
              />
            </Grid>
            <Grid container justifyContent={'center'} sx={{ my: 2 }} item xs={12}>
              <AreaManagementCreateSubmit
                onCreateGoBackButtonClick={onCreateGoBackButtonClick}
                onSubmit={formik.handleSubmit}
              />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
