import { Box, Paper, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';
import YatungButton from '../YatungButton';
import YatungLoading from '../YatungLoading';
import Tab, { DisplayProps as TabProps } from './components/Tab';

export interface Props {
  tabs: Array<TabProps>;
  screens: Array<JSX.Element>;
  loading: boolean;
  handleViewSelectProportion: () => void;
}

export default function YatungPaperWithTabs(props: Props) {
  const navigate = useNavigate();
  const { t: i18T } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [tabsRef, tabsSize] = useElementSize();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      <Stack sx={{ position: 'absolute', right: 5, top: -tabsSize.height, flexDirection: 'row', gap: 2 }}>
        <YatungButton onClick={goBack} color="red" width={100} text={i18T('GLOBAL.BACK')} />
        <YatungButton onClick={props.handleViewSelectProportion} color="blue" width={140} text={'選擇匯出日期'} />
      </Stack>

      <Box ref={tabsRef} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: '1px' }}>
        {props.tabs.map((tab: TabProps, index: number) => {
          return (
            <Tab
              key={index}
              {...tab}
              index={index}
              activedIndex={activeIndex}
              onClick={setActiveIndex}
              lastIndex={props.tabs.length - 1}
            />
          );
        })}
      </Box>
      <Box sx={{ height: `calc(100% - ${tabsSize.height}px)`, opacity: !props.loading ? 0.15 : 1 }}>
        <Paper sx={{ height: '100%', borderTopRightRadius: '0px' }}>{props.screens[activeIndex]}</Paper>
      </Box>
      {!props.loading && <YatungLoading overlay />}
    </Box>
  );
}
