import { useEffect, useState } from 'react';
import { Options } from '../components/YatungSelect';
import { TypeApi } from '../protocol/type/TypeApi';

export default function useGetAllTypes(removedIds?: Array<number>) {
  const [typeOption, setTypeOption] = useState<Array<Options>>([]);

  useEffect(() => {
    TypeApi.getAllTypes((_data) => {
      if (removedIds) {
        setTypeOption(
          _data.filter(({ id }) => !removedIds.includes(id)).map(({ id, name }) => ({ value: id, text: name })),
        );
      } else {
        setTypeOption(_data.map(({ id, name }) => ({ value: id, text: name })));
      }
    });
  }, []);

  return {
    typeOption,
  } as const;
}
