import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function CreateReportGroup(props: SvgIconProps) {
  return (
    <svg {...props} width="38" height="32" viewBox="0 0 38 32" fill="none">
      <path
        d="M24.5174 4.9985C24.5174 7.48235 22.5011 9.49701 20.0125 9.49701C17.5239 9.49701 15.5075 7.48235 15.5075 4.9985C15.5075 2.51467 17.5239 0.5 20.0125 0.5C22.5011 0.5 24.5174 2.51467 24.5174 4.9985ZM26.1932 20.8206C26.1932 21.4046 26.3224 21.9589 26.5537 22.4561H9.57836C9.57658 22.3762 9.57568 22.296 9.57568 22.2156C9.57568 16.3577 14.3308 11.6078 20.1978 11.6078C24.1615 11.6078 27.6186 13.7757 29.4442 16.9904C27.6004 17.2914 26.1932 18.8917 26.1932 20.8206Z"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4304 23.8457C32.0029 23.8457 33.2777 22.5709 33.2777 20.9984C33.2777 19.4259 32.0029 18.1511 30.4304 18.1511C28.8579 18.1511 27.5831 19.4259 27.5831 20.9984C27.5831 22.5709 28.8579 23.8457 30.4304 23.8457ZM30.371 18.5071C30.5021 18.5071 30.6083 18.6134 30.6083 18.7444V20.7019H32.5658C32.6968 20.7019 32.8031 20.8081 32.8031 20.9392C32.8031 21.0702 32.6968 21.1765 32.5658 21.1765H30.6083V23.1339C30.6083 23.265 30.5021 23.3712 30.371 23.3712C30.24 23.3712 30.1338 23.265 30.1338 23.1339V21.1765H28.1763C28.0452 21.1765 27.939 21.0702 27.939 20.9392C27.939 20.8081 28.0452 20.7019 28.1763 20.7019H30.1338V18.7444C30.1338 18.6134 30.24 18.5071 30.371 18.5071Z"
        fill="white"
      />
      <path
        d="M37.5172 20.9655H34.2069V26.6129H6.89655V23.8062L0 27.9031L6.89655 32V29.1933H37.5172V20.9655Z"
        fill="white"
      />
    </svg>
  );
}
