import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import MultilingualManagementScreen from './screens/MultilingualManagementScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <MultilingualManagementScreen />,
  },
];

const MultilingualManagementRouter = () => PermissionRoute(routesConfig);

export default memo(MultilingualManagementRouter);
