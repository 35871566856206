import { InputAdornment, MenuItem, SelectProps, styled, SxProps, TextField } from '@mui/material';
import React from 'react';
import TriangleDown from '../icons/TriangleDown';

export type Options = {
  value: number;
  text: string | undefined;
  code?: string | undefined;
  state?: boolean;
  disabled?: boolean;
};
export interface Props extends SelectProps {
  type?: string;
  label?: string;
  field?: any;
  form: { setFieldValue: any; touched: boolean | any; errors: boolean | any };
  variant?: `${'filled' | 'outlined' | 'standard'}`;
  disabled?: boolean;
  options: Array<Options>;
  width?: string;
  sx?: SxProps;
  isFormik: boolean;
}
const SelectInput = styled(TextField)(({ theme }) => ({
  borderRadius: 8,
  background: 'white',
  '& fieldset': {
    borderColor: '#6C708C',
  },
  '& .MuiInputBase-input': {
    minHeight: `0 !important`,
    fontSize: 20,
    padding: '8px 26px 8px 12px',
  },
}));
const TriangleDownIcon = styled(TriangleDown)(() => ({
  color: '#003087',
  fill: '#003087',
}));
export default function YatungFormSelect({
  field,
  label,
  variant,
  type,
  width,
  form: { touched, errors },
  options,
  sx,
  ...props
}: Props) {
  return (
    <>
      <SelectInput
        select
        variant={variant}
        margin="normal"
        label=""
        type={type}
        error={touched[field.name] && !!errors[field.name]}
        helperText={touched[field.name] && errors[field.name]}
        sx={sx || { m: 1, minWidth: width ? 0 : 180 }}
        SelectProps={{
          IconComponent: () => null, // removes the select icon
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{<TriangleDownIcon />}</InputAdornment>,
        }}
        {...field}
        {...props}
      >
        <MenuItem disabled value={0}>
          請選擇 - - -
        </MenuItem>
        {options.map((option: Options, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </SelectInput>
    </>
  );
}
