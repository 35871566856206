import AdapterDateFns from '@date-io/date-fns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import zhLocale from 'date-fns/locale/zh-TW'; // 引入中文語言包
import React from 'react';
import CalendarIcon from '../icons/Calendar';
import YatungInput from './YatungInput';

interface Props {
  onChange: (value: Date | null) => void;
  value: Date | null;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxDateTime?: any;
  minDate?: any;
  disabled?: boolean;
  label?: string;
  icon?: any;
  fullWidth?: boolean;
  error?: string;
}
export default function YatungDateTimePicker({
  onChange,
  value,
  icon = CalendarIcon,
  fullWidth,
  error,
  ...props
}: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhLocale}>
      <DateTimePicker
        views={['year', 'month', 'day', 'hours', 'minutes']}
        inputFormat={`yyyy/MM/dd HH:mm`}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <YatungInput
            {...params}
            error={error}
            helperText={error}
            sx={{ ...props, width: fullWidth ? '100%' : 230 }}
          />
        )}
        components={{
          OpenPickerIcon: icon,
        }}
        ampm={false}
        {...props}
      />
    </LocalizationProvider>
  );
}
