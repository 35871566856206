import React, { useCallback, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import LogoutScreen from './module/auth/screens/LogoutScreen';
import HomePage from './pages/HomePage';

import Layout from './module/layout';
import PowerbiManagePage from './module/powerbiManage/PowerbiManagePage';
import UsersManagePage from './module/usersManage/UsersManagePage';
import View from './module/view';

import { DropdownItem } from '@Src/_basic/components/YatungSelect';
import { AreaData, AreaOptions } from '@Src/_basic/object/AreaType';
import { AreaItem, GetUserGroupsData, GetUserGroupsParams } from '@Src/_basic/object/AuthType';
import { FactoryItem } from '@Src/_basic/object/FactoryType';
import { AuthApi } from '@Src/_basic/protocol/auth/AuthApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useIdleTimer } from 'react-idle-timer';
import ProportionManage from './module/proportionManage';
import QualityManagement from './module/qualityManagement';
import RealTimeMonitoring from './module/realTimeMonitoring';
import Report from './module/report';

export interface RouteConfig {
  path: string;
  permission?: string;
  element: JSX.Element;
}

export default function BasicRouter() {
  const { logout, setUserData, setUserGroupsData, setUserAreaOptionsData, setUserApiPermission } = useAuth();
  const navigate = useNavigate();

  const handleOnIdle = useCallback(() => {
    logout();
    navigate('/');
  }, [logout, navigate]);

  const getAreasOptions = useCallback(
    (areas: AreaData[]) => {
      const areaOptions: AreaOptions[] = areas.map((allArea: AreaData) => ({
        value: allArea.id,
        text: allArea.name,
        factories: allArea.factoryList.map((factory: FactoryItem) => ({
          value: factory.factoryId,
          text: factory.factoryName,
          facCode: `${factory.factoryCode + factory.branchCode}`,
        })),
      }));
      setUserAreaOptionsData(areaOptions);
    },
    [setUserAreaOptionsData],
  );

  const getUserGroupsDataSuccess = useCallback(
    (getUserGroupsDataResp: GetUserGroupsData) => {
      if (getUserGroupsDataResp) {
        const factoryArray = getUserGroupsDataResp.areas.reduce((acc: Array<DropdownItem<number>>, area: AreaItem) => {
          area.factoryList.forEach((factory: FactoryItem) => {
            if (!acc.some((f) => f.value === factory.factoryId)) {
              acc.push({ text: factory.factoryName, value: factory.factoryId });
            }
          });
          return acc;
        }, [] as Array<DropdownItem<number>>);
        const afterData = {
          ...getUserGroupsDataResp,
          areas: getUserGroupsDataResp.areas,
          factories: factoryArray,
          supervisorReportGroups: getUserGroupsDataResp.supervisorReportGroups.map((item) => ({
            ...item,
            users: item.users.reduce(
              (prev, curr) => [...prev, { text: curr.accountName, value: curr.account }],
              [] as Array<DropdownItem<string>>,
            ),
          })),
          supervisorTransformGroups: getUserGroupsDataResp.supervisorTransformGroups.map((item) => ({
            ...item,
            users: item.users.reduce(
              (prev, curr) => [...prev, { text: curr.accountName, value: curr.account }],
              [] as Array<DropdownItem<string>>,
            ),
          })),
          supervisorAssignGroups: getUserGroupsDataResp.supervisorAssignGroups.map((item) => ({
            ...item,
            users: item.users.reduce(
              (prev, curr) => [...prev, { text: curr.accountName, value: curr.account }],
              [] as Array<DropdownItem<string>>,
            ),
          })),
        };
        getAreasOptions(afterData.areas);
        setUserGroupsData(afterData);
      }
    },
    [getAreasOptions, setUserGroupsData],
  );

  const getUserGroupsData = useCallback(
    (params: GetUserGroupsParams) => {
      AuthApi.getUserGroupsData(params, getUserGroupsDataSuccess, (err) =>
        console.log('ERROR: getUserGroupsData', err),
      );
    },
    [getUserGroupsDataSuccess],
  );

  const getUserApiPermission = useCallback(() => {
    AuthApi.getUserApiPermission((data: Array<string>) => {
      setUserApiPermission(data);
    });
  }, [setUserApiPermission]);

  const getUserDataSuccess = useCallback(
    (data: any) => {
      console.log('getUserDataSuccess', data);
      setUserData(data);
      if (data.id) {
        getUserGroupsData({ id: data.id });
        getUserApiPermission();
      }
    },
    [getUserApiPermission, getUserGroupsData, setUserData],
  );

  const getUserData = useCallback(() => {
    AuthApi.getUserData(getUserDataSuccess, logout);
  }, [getUserDataSuccess]);

  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    return () => {
      idleTimer?.pause();
      idleTimer?.reset();
    };
  }, [idleTimer, handleOnIdle]);

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/UsersManagePage/*" element={<UsersManagePage />} />
        <Route path="/PowerbiManagePage/*" element={<PowerbiManagePage />} />
        <Route path="/proportionManage/*" element={<ProportionManage />} />
        <Route path="/logout/*" element={<LogoutScreen />} />
        <Route path="/qualityManagement/*" element={<QualityManagement />} />
        <Route path="/report/*" element={<Report />} />
        <Route path="/view/*" element={<View />} />
        <Route path="/realTimeMonitoring/*" element={<RealTimeMonitoring />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
}
