import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import BackButton from '@Src/_basic/icons/BackButton';
import SearchIcon from '@Src/_basic/icons/Search';
import { MonitorCustomerData } from '@Src/_basic/object/MonitorType';
import { MonitorApi } from '@Src/_basic/protocol/monitor/MonitorApi';
import { useMonitor } from '@Src/redux/monitor/monitorAction';
import { SelectChangeEvent, Stack } from '@mui/material';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import MonitorByDetailTable from '../components/MonitorByDetailTable';

export default function MonitorByDetailSreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<string>('');
  const [monitorCustomer, setMonitorCustomer] = useState<MonitorCustomerData[] | undefined>(undefined);
  const [factoryId, setFactoryId] = useState<number>(0);

  const { date } = useMonitor();
  const handleGoBack = () => {
    navigate(-1);
  };
  const getMonitorCustomer = (id: number) => {
    MonitorApi.getMonitorCustomer(id, { date: moment(date).format('YYYY-MM-DD') }, onGetMonitorSuccess);
  };

  const onGetMonitorSuccess = (data: MonitorCustomerData[]) => {
    setMonitorCustomer(data);
  };

  const searchMonitorCustomer = () => {
    MonitorApi.searchMonitorCustomer(
      factoryId,
      { date: moment(date).format('YYYY-MM-DD'), customerName: inputValues },
      onSearchMonitorSuccess,
    );
  };

  const onSearchMonitorSuccess = (data: MonitorCustomerData[]) => {
    setMonitorCustomer(data);
  };

  const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (location.search) {
      if (queryObject.factoryId) {
        getMonitorCustomer(parseInt(queryObject.factoryId.toString()));
        setFactoryId(parseInt(queryObject.factoryId.toString()));
      }
    }
  }, [date]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.PAGE_TITLE') + `(${queryObject.factory})`}
      body={
        <>
          <Stack direction="row" spacing={2} sx={{ py: 1 }} justifyContent="flex-end">
            <YatungInput value={inputValues} onChange={(e: SelectChangeEvent) => setInputValues(e.target.value)} />
            <YatungButton
              text={i18T('GLOBAL.SEARCh_BUTTON')}
              startIcon={<SearchIcon />}
              color="blue"
              onClick={searchMonitorCustomer}
            />
            <YatungButton
              text={i18T('GLOBAL.BACK_PAGE')}
              color="green"
              onClick={handleGoBack}
              startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
              sx={{ alignSelf: 'center' }}
            />
          </Stack>
          <MonitorByDetailTable loading={loading} customerData={monitorCustomer} />
        </>
      }
      contentBgColor="#fff"
    />
  );
}
