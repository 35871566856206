import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDatePicker from '@Src/_basic/components/YatungDatePicker';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import EditIcon from '@Src/_basic/icons/Edit';
import { AreaItem } from '@Src/_basic/object/AuthType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import { MonthlyReposrtProtocol } from '@Src/_basic/protocol/monthlyReport/MonthlyReposrtProtocol';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { Box, Dialog, Grid, SelectChangeEvent, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

function ExcelReportDialog({ handleCloseModal }: any) {
  const { t: i18T } = useTranslation();

  const { userGroupsData } = useAuth();
  const { actionLoading } = useApi();

  const [factoryOptions, setFactoryOptions] = useState<Array<Options>>([]); // 工廠選項
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date()); // selected開始時間
  const [exptItemOptions, setExptItemOptions] = useState<Array<Options>>([]); // 試驗類別選項
  const [selectedExptItem, setSelectedExptItem] = useState<number>(0); // selected試驗類別
  const [selectedFactory, setSelectedFactory] = useState<number>(0); // selected廠Id
  const [selectedArea, setSelectedArea] = useState<number>(0); // selected區Id
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);

  const handleConfirmButtonClick = async () => {
    const url = `${MonthlyReposrtProtocol.GET_MONTHLY_REPORT_EXCEL}?time=${Number(
      selectedMonth,
    )}&areaId=${selectedArea}&factoryIds=${selectedFactory}&monthlyReportExperimentId=${selectedExptItem}`;

    try {
      const response = await fetch(url, {
        mode: 'no-cors',
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.text();
      //如果第一個字元是{，代表是錯誤訊息，否則就是檔案
      if (data[0] === '{') {
        setErrorMessageOpen(true);
      } else {
        window.location.href = url;
      }
    } catch (error) {
      console.error('error:', error);
    }
  };

  // 區選項
  const areaOptions = useMemo(() => {
    if (!userGroupsData || !userGroupsData.areas) return [];

    return userGroupsData.areas.reduce(
      (prev, curr) => [...prev, { text: curr.name, value: curr }],
      [] as Array<Options>,
    );
  }, [userGroupsData]);

  // 選擇區
  const selectAreaHandler = useCallback((e: SelectChangeEvent<unknown>) => {
    const area = e.target.value as AreaItem;
    setSelectedArea(area.id);
    if (!area?.factoryList) setFactoryOptions([]);
    else {
      const afterFactoryOption = area?.factoryList?.reduce(
        (prev, curr) => [...prev, { text: curr.factoryName, value: curr.factoryId }],
        [] as Array<Options>,
      );
      setFactoryOptions(afterFactoryOption);
      setSelectedFactory(0);
      //將selectedExptItem的值清空
      setSelectedExptItem(0);
    }
  }, []);
  const selectedFactoryHandler = useCallback((e: SelectChangeEvent<unknown>) => {
    setSelectedFactory(e.target.value as number);
    setSelectedExptItem(0);
  }, []);
  //  試驗類別
  useEffect(() => {
    MonthlyReportApi.getAllMonthlyExptItem((_data) => {
      console.log(_data);
      const afterData = _data.filter((item) => item.id !== 9);
      setExptItemOptions(afterData.map((item) => ({ text: item.itemName, value: item.id })));
    });
  }, []);
  const errorMessageClose = () => {
    setErrorMessageOpen(false);
  };
  return (
    <>
      <YatungDialog
        open={errorMessageOpen}
        handleClose={errorMessageClose}
        postion={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
        }}
        title={i18T('GLOBAL.WARN')}
        titleColor={{ color: '#CB333B', bgcolor: '#003087' }}
        body={
          <>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <Box component={'div'} sx={{ fontSize: 28, fontWeight: 400, fontFamily: 'Inter', color: '#7C878E' }}>
                {i18T('GLOBAL.EXPORT_DENIED')}
              </Box>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <YatungButton
                size={'large'}
                color="blue"
                type={'button'}
                text={i18T('GLOBAL.SURE')}
                sx={{
                  fontSize: '24px',
                  px: 2,
                }}
                onClick={errorMessageClose}
              />
            </Grid>
          </>
        }
      />
      <Box
        sx={{
          bgcolor: '#003087',
          color: 'white',
          boxShadow: 16,
          borderRadius: '20px',
          p: 2,
          opacity: 1,
        }}
      >
        <Stack>{i18T('VIEW.MONTHLY_REPORT.EXPORT')}</Stack>
        <Stack direction="column" spacing={2} alignItems="flex-start" my={3}>
          <Stack direction="row" spacing={2} alignItems="center">
            <YatungDatePicker
              disabled={actionLoading}
              mode="Month"
              value={selectedMonth}
              disableFuture={true}
              onChange={(val: Date | null) => {
                if (val) setSelectedMonth(new Date(val));
              }}
            />
            <YatungSelect
              disabled={actionLoading}
              options={areaOptions as Array<Options>}
              onChange={selectAreaHandler}
            />
            <YatungSelect
              disabled={factoryOptions.length === 0 || actionLoading}
              options={factoryOptions}
              onChange={selectedFactoryHandler}
              value={selectedFactory}
            />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <YatungSelect
              disabled={actionLoading || selectedFactory === 0}
              options={exptItemOptions}
              onChange={(e) => {
                setSelectedExptItem(e.target.value as number);
              }}
              value={selectedExptItem}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent={'center'}>
          <YatungButton
            text={i18T('GLOBAL.CANCEL')}
            startIcon={<EditIcon />}
            onClick={handleCloseModal}
            sx={{
              color: '#ffffff',
              bgcolor: '#CB333B',
              fontSize: '24px',
              width: 120,
              mr: 1,
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#CB333B',
              },
            }}
          />
          <YatungButton
            text={i18T('GLOBAL.EXPORT')}
            startIcon={<EditIcon />}
            disabled={!selectedMonth || !selectedFactory || !selectedExptItem}
            onClick={handleConfirmButtonClick}
            sx={{
              border: '1px solid #fff',
              color: '#ffffff',
              bgcolor: '#002F8C',
              fontSize: '24px',
              width: 120,
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#002F8C',
              },
            }}
          />
        </Stack>
      </Box>
    </>
  );
}

export default function ExcelReportModal({ open = false, ...props }: any) {
  return (
    <Dialog
      open={open}
      onClose={props.handleCloseModal}
      PaperProps={{ style: { borderRadius: 20, backgroundColor: '#003087' } }}
    >
      <ExcelReportDialog {...props} />
    </Dialog>
  );
}
