import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ListTitle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
        <path d="M1.7093 3.37037C2.65332 3.37037 3.4186 2.61588 3.4186 1.68519C3.4186 0.754489 2.65332 0 1.7093 0C0.765287 0 0 0.754489 0 1.68519C0 2.61588 0.765287 3.37037 1.7093 3.37037Z" />
        <path d="M6.10465 0.481481C5.43034 0.481481 4.88372 1.02039 4.88372 1.68519C4.88372 2.34998 5.43034 2.88889 6.10465 2.88889H19.7791C20.4534 2.88889 21 2.34998 21 1.68519C21 1.02039 20.4534 0.481481 19.7791 0.481481H6.10465Z" />
        <path d="M3.4186 6.5C3.4186 7.4307 2.65332 8.18519 1.7093 8.18519C0.765287 8.18519 0 7.4307 0 6.5C0 5.5693 0.765287 4.81482 1.7093 4.81482C2.65332 4.81482 3.4186 5.5693 3.4186 6.5Z" />
        <path d="M6.10465 5.2963C5.43034 5.2963 4.88372 5.8352 4.88372 6.5C4.88372 7.1648 5.43034 7.7037 6.10465 7.7037H19.7791C20.4534 7.7037 21 7.1648 21 6.5C21 5.8352 20.4534 5.2963 19.7791 5.2963H6.10465Z" />
        <path d="M3.4186 11.3148C3.4186 12.2455 2.65332 13 1.7093 13C0.765287 13 0 12.2455 0 11.3148C0 10.3841 0.765287 9.62963 1.7093 9.62963C2.65332 9.62963 3.4186 10.3841 3.4186 11.3148Z" />
        <path d="M6.10465 10.1111C5.43034 10.1111 4.88372 10.65 4.88372 11.3148C4.88372 11.9796 5.43034 12.5185 6.10465 12.5185H19.7791C20.4534 12.5185 21 11.9796 21 11.3148C21 10.65 20.4534 10.1111 19.7791 10.1111H6.10465Z" />
    </SvgIcon>
  );
}