import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import FactoryManagementSearchBar from './FactoryManagementSearchBar';
import FactoryManagementTable from './FactoryManagementTable';

const background = '/media/images/background.png';

export type FilterValueType = {
  areaIds: number;
  searchStr: string;
  removed: number;
};

export default function FactoryManagementCard() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<FilterValueType>();

  const [factoryData, setFactoryData] = useState<FactoryData[]>([]);
  const [isVisible, setIsVisible] = useState(false);

  const initFilterValue = useMemo(() => {
    const {
      searchStr = '',
      areaIds = 0,
      removed = 0,
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      searchStr,
      areaIds,
      removed,
    };
  }, [request, location.pathname]);

  const [{ searchStr, areaIds, removed }, queryDispatch] = useReducer<
    Reducer<FilterValueType, Partial<FilterValueType>>
  >(updateReducer, initFilterValue);

  const getAllFactory = useCallback(() => {
    FactoryApi.getFactoryBySearch(
      {
        ...(searchStr && { searchStr }),
        ...(areaIds && { areaIds }),
        ...(removed && { removed: removed === 1 ? false : true }),
      },
      (_data) => {
        setIsVisible(true);
        setFactoryData(_data.map((item, index) => ({ ...item, ids: index + 1 })));
        setSaveRequest({
          page: location.pathname,
          request: { areaIds, removed, searchStr },
        });
      },
    );
  }, [removed, areaIds, searchStr, setSaveRequest, location.pathname]);

  const handleChange = useCallback((field: keyof FilterValueType, value: FilterValueType[keyof FilterValueType]) => {
    queryDispatch({ [field]: value });
  }, []);

  useEffect(() => {
    setIsVisible(false);
  }, [areaIds, removed, searchStr]);

  useEffect(() => {
    getAllFactory();
  }, []);

  return (
    <YatungPage
      title={i18T('FACTORYMANAGEMENT.PAGE_TITLE')}
      backgroundImage={background}
      body={
        <>
          <FactoryManagementSearchBar
            areaIds={areaIds}
            searchStr={searchStr}
            removed={removed}
            handleChange={handleChange}
            getAllFactory={getAllFactory}
          />
          <YatungFadeInOut isVisible={isVisible}>
            <FactoryManagementTable factoryData={factoryData} getAllFactory={getAllFactory} />
          </YatungFadeInOut>
        </>
      }
    />
  );
}
