export function getMonthBetweenDates(start: Date, end: Date) {
  const result = [];
  let startTemp = new Date(start.getTime());
  while (startTemp.getTime() <= end.getTime()) {
    result.push(startTemp);
    startTemp = new Date(startTemp.getFullYear(), startTemp.getMonth() + 1, 1);
  }
  return result;
}

export function getFirstDateAndLastDateByMonth(date: Date) {
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { firstDate, lastDate };
}
