export interface ExptScheduleData {
  id: number;
  title: string;
  areaId: number;
  areaName: string;
  factoryId: number;
  factoryName: string;
  isRequirement: boolean;
  exptTypeId: number;
  specificationId: number;
  sourceId: number;
  sampleSourceId: number;
  storageId: number;
  memo: string;
  schedulePeriods: Array<string>;
  period: CreateSchedulePeriodRequest;
  scheduleItems: Array<ScheduleItemData>;
}

export interface ScheduleItemData {
  exptItemId: number;
  exptItemName: string;
  operator: string;
  operatorName: string;
  groupId: number;
}

export interface PageableExptScheduleData {
  maxPage: number;
  totalElements: number;
  data: Array<ExptScheduleData>;
}

export interface SearchExptSchedulesParams {
  factoryId: number;
}

export interface SearchPageableExptSchedulesParams extends SearchExptSchedulesParams {
  page: number;
}

export interface CreateExptScheduleRequest {
  areaId: number;
  factoryId: number;
  creater: string;
  title: string;
  isRequirement: boolean;
  isTest: boolean;
  exptTypeId: number;
  memo?: string;
  sampleSourceId?: number;
  sourceId?: number;
  specificationId?: number;
  storageId?: number;
  sampleId?: string;
  exptItems?: Array<CreateScheduleItemRequest>;
  period?: CreateSchedulePeriodRequest;
}

export interface UpdateExptScheduleRequest {
  id: number;
  areaId?: number;
  title?: string;
  isRequirement?: boolean;
  exptTypeId?: number;
  length?: number;
  memo?: string;
  sampleSourceId?: number;
  sourceId?: number;
  specificationId?: number;
  storageId?: number;
  sampleId?: string;
  exptItems?: Array<CreateScheduleItemRequest>;
  period?: CreateSchedulePeriodRequest;
}

export interface CreateExptScheduleFormType {
  areaId: number;
  factoryId: number;
  creater: string;
  title: string;
  isRequirement: string;
  isTest: string;
  exptTypeId: number;
  memo?: string;
  sampleSourceId?: number;
  sourceId?: number;
  specificationId?: number;
  storageId?: number;
  sampleId?: string;
  exptItems?: Array<CreateScheduleItemRequest>;
  period?: CreateSchedulePeriodRequest;
  startTime?: Date | any;
  endTime?: Date | any;
}

export interface UpdateExptScheduleFormType {
  id: number;
  areaId: number;
  factoryId: number;
  title: string;
  isRequirement: string;
  exptTypeId: number;
  memo?: string;
  sampleSourceId?: number;
  sourceId?: number;
  specificationId?: number;
  storageId?: number;
  sampleId?: string;
  exptItems?: Array<CreateScheduleItemRequest>;
  period?: CreateSchedulePeriodRequest;
}

export interface CreateScheduleItemRequest {
  exptItemId: number;
  groupId: number;
  operator: string;
}

export interface CreateSchedulePeriodRequest {
  periodType: PeriodTypeEnum | string | undefined;
  periodWeekDays: Array<ScheduleWeekDay> | null;
  periodDates: Array<ScheduleData> | null;
}

export interface ScheduleWeekDay {
  weekDay: number;
  length: number;
}

export interface ScheduleData {
  startMonth: number;
  startDay: number;
  endMonth: number;
  endDay: number;
}

export interface UpdateExptScheduleRequest {
  id: number;
  title?: string;
  isRequirement?: boolean;
  exptTypeId?: number;
  memo?: string;
  sampleSourceId?: number;
  sourceId?: number;
  specificationId?: number;
  storageId?: number;
  exptItems?: Array<CreateScheduleItemRequest>;
  period?: CreateSchedulePeriodRequest;
}

export enum PeriodTypeEnum {
  WEEK = 'WEEK',
  DATE = 'DATE',
}

export enum PeriodDayEnum {
  SUNDAY = 1,
  MONDAY = 2,
  TUESDAY = 3,
  WEDNESDAY = 4,
  THURSDAY = 5,
  FRIDAY = 6,
  SATURDAY = 7,
}
