import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Billing(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="26" height="28" viewBox="0 0 26 28" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6 19.6H15.4V22.4H5.6V19.6ZM5.6 14H19.6V16.8H5.6V14ZM5.6 8.4H19.6V11.2H5.6V8.4ZM22.4 2.8H16.548C15.96 1.176 14.42 0 12.6 0C10.78 0 9.24 1.176 8.652 2.8H2.8C2.604 2.8 2.422 2.814 2.24 2.856C1.694 2.968 1.204 3.248 0.826 3.626C0.574 3.878 0.364 4.186 0.224 4.522C0.084 4.844 0 5.208 0 5.6V25.2C0 25.578 0.084 25.956 0.224 26.292C0.364 26.628 0.574 26.922 0.826 27.188C1.204 27.566 1.694 27.846 2.24 27.958C2.422 27.986 2.604 28 2.8 28H22.4C23.94 28 25.2 26.74 25.2 25.2V5.6C25.2 4.06 23.94 2.8 22.4 2.8ZM12.6 2.45C13.174 2.45 13.65 2.926 13.65 3.5C13.65 4.074 13.174 4.55 12.6 4.55C12.026 4.55 11.55 4.074 11.55 3.5C11.55 2.926 12.026 2.45 12.6 2.45ZM22.4 25.2H2.8V5.6H22.4V25.2Z"
        fill="white"
      />
    </SvgIcon>
  );
}
