import YatungAlert from '@Src/_basic/components/YatungAlert';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungImg from '@Src/_basic/components/YatungImg';
import { Options } from '@Src/_basic/components/YatungSelect';
import {
  IntlUnitConversionsArrayData,
  UnitData,
  UpdateInitialWeightUnitRequest,
} from '@Src/_basic/object/WeightUnitManageType';
import { WeightUnitManageApi } from '@Src/_basic/protocol/WeightUnitManage/WeightUnitManageApi';
import { Stack } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import UpdateUnitField from './UpdateUnitField';

const editImage = '/media/images/edit.png';

interface Props {
  selectedFactory: number;
  selectedWeightUnit: UnitData;
  isUpdateUnitDialog: boolean;
  types: Array<Options>;
  avaliableOptions: Array<Options>;
  onUnitUpdateGoBackButtonClick: () => void;
  setWeightUnit: (data: Array<UnitData>) => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('請輸入單位名稱'),
  typeId: Yup.number().positive('請選擇對應單位'),
  intlUnitConversions: Yup.array().of(
    Yup.object().shape({
      intlUnitId: Yup.number().positive('請選擇名稱'),
      intlUnitValue: Yup.number().min(1, '請輸入大於1的值').required('請輸入值'),
    }),
  ),
});
export default function UpdateUnitForm(props: Props) {
  const { t: i18T } = useTranslation();
  const {
    isUpdateUnitDialog,
    onUnitUpdateGoBackButtonClick,
    types,
    selectedWeightUnit,
    avaliableOptions,
    setWeightUnit,
    selectedFactory,
  } = props;
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const initialValues: UpdateInitialWeightUnitRequest = {
    id: selectedWeightUnit.id || 0,
    name: selectedWeightUnit.name || '',
    typeId: selectedWeightUnit.typeId || 0,
    intlUnitConversions: selectedWeightUnit.intlUnitConversions || [],
  };
  const handleUnitGoBackButtonClick = () => {
    onUnitUpdateGoBackButtonClick();
  };
  const handleSaveSubmit = async (require: UpdateInitialWeightUnitRequest) => {
    const removeintlUnitName: Array<IntlUnitConversionsArrayData> = require.intlUnitConversions.map((value) => {
      delete value.intlUnitName;
      return value;
    });
    const newRequire: UpdateInitialWeightUnitRequest = { ...require, intlUnitConversions: removeintlUnitName };

    WeightUnitManageApi.updateUnitCostom(
      newRequire,
      (data: UnitData) => {
        onUnitUpdateGoBackButtonClick();
        WeightUnitManageApi.getUnitCustomBySearch({ factoryId: selectedFactory }, setWeightUnit);
      },
      () => {
        setAlertOpen(true);
      },
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(require: UpdateInitialWeightUnitRequest) => handleSaveSubmit(require)}
        enableReinitialize
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form>
            <FieldArray name="intlUnitConversions">
              {(arrayHelpers) => {
                return (
                  <YatungDialog
                    isMeasures
                    open={isUpdateUnitDialog}
                    handleClose={onUnitUpdateGoBackButtonClick}
                    icon={
                      <YatungImg
                        img={editImage}
                        sx={{
                          width: 28,
                          height: 28,
                          zIndex: 999,
                          alignSelf: 'center',
                          display: 'flex',
                          textAlign: 'center',
                          mr: 1,
                        }}
                      />
                    }
                    title={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.UPDATE_UNIT')}
                    action={
                      <Stack direction="row" spacing={2}>
                        <YatungButton
                          text={i18T('GLOBAL.CANCEL')}
                          onClick={handleUnitGoBackButtonClick}
                          color="red"
                          sx={{
                            fontSize: '20px',
                            width: 75,
                            alignSelf: 'center',
                          }}
                        />
                        <YatungButton
                          text={i18T('GLOBAL.FINISH')}
                          color="green"
                          onClick={handleSubmit}
                          sx={{
                            fontSize: '20px',
                            width: 75,
                            alignSelf: 'center',
                          }}
                        />
                      </Stack>
                    }
                    body={
                      <Stack>
                        <UpdateUnitField
                          types={types}
                          arrayHelpers={arrayHelpers}
                          values={values}
                          setFieldValue={setFieldValue}
                          isUpdateUnitDialog={isUpdateUnitDialog}
                          avaliableOptions={avaliableOptions}
                        />
                      </Stack>
                    }
                  />
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
      <YatungAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        title={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.ALERT_MESSAGE')}
        type="error"
      />
    </>
  );
}
