import React from 'react';
import { VehicleData, VehicleOptions } from '@Src/_basic/object/VehicleType';
import NamesExpand from '@Src/_basic/components/NamesExpand';

interface Props {
  row: VehicleData;
}

export default function TypeColumnFormatter(props: Props) {
  const { row } = props;
  return <NamesExpand names={row.type.map((factory: VehicleOptions) => factory.name)} />;
}
