import { Options } from '@Src/_basic/components/YatungDynamicComboBox';
import YatungDynamicSelect from '@Src/_basic/components/YatungDynamicSelect';
import { CreateSignatureItemDetailRequest, CreateSignatureItemRequest } from '@Src/_basic/object/SignatureType';
import { useSignature } from '@Src/redux/signature/signatureActions';
import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SignatrueItemDetailsForm from './SignatrueItemDetailsForm';

interface Props {
  data: CreateSignatureItemRequest;
  typeOptions: Array<Options>;
  dynamicTypeOptions: Array<Options>;
  onTypeChange?: (value: number) => void;
  onDetailsChange?: (value: Array<CreateSignatureItemDetailRequest>) => void;
  onAddDetailsButtonClick?: () => void;
  onDeleteDetailButtonClick?: (detailOrder: number) => void;
}

export default function SignatrueItemForm({
  data,
  typeOptions,
  dynamicTypeOptions,
  onTypeChange,
  onDetailsChange,
  onAddDetailsButtonClick,
  onDeleteDetailButtonClick,
}: Props) {
  const { bomHierarchy } = useSignature();
  const [specificationOptions, setSpecificationOptions] = useState<Array<Options>>([]);

  const dynamicSpecificationOptions = useMemo(() => {
    const specificationIds: Array<number> = data.details?.map(
      (item: CreateSignatureItemDetailRequest) => item.specificationId,
    );

    return specificationOptions.filter((item: Options) => !specificationIds?.includes(Number(item.value)));
  }, [specificationOptions, data.details]);

  const handleSpecificationChange = (specificationId: number, detailOrder: number) => {
    const details = [...data.details];
    const item = details.find((item: CreateSignatureItemDetailRequest) => item.order === detailOrder);
    item!.specificationId = specificationId;

    onDetailsChange?.(details);
  };

  const handleDetailValueChange = (value: string, detailOrder: number) => {
    const details = [...data.details];
    const item = details.find((item: CreateSignatureItemDetailRequest) => item.order === detailOrder);
    item!.detailValue = value;

    onDetailsChange?.(details);
  };

  const handleDetailBomChange = (value: number, detailOrder: number) => {
    const details = [...data.details];
    const item = details.find((item: CreateSignatureItemDetailRequest) => item.order === detailOrder);
    item!.BOMId = value;

    onDetailsChange?.(details);
  };

  const handleTypeChange = (typeId: number) => {
    onTypeChange?.(typeId);
  };

  const getSpecificationOptions = useCallback(() => {
    if (!data.typeId) return;

    const _specificationList = bomHierarchy.find((bom) => bom.typeId === data.typeId)?.specificationList;
    if (_specificationList) {
      setSpecificationOptions(
        _specificationList.map((specification) => ({
          text: specification.specificationName,
          value: specification.specificationId,
        })),
      );
    }
  }, [data.typeId, bomHierarchy]);

  useEffect(() => {
    getSpecificationOptions();
  }, [getSpecificationOptions]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <YatungDynamicSelect
        totalOptions={typeOptions}
        options={dynamicTypeOptions}
        value={data.typeId}
        width="100%"
        onChange={(e: any) => {
          handleTypeChange(e.target.value);
        }}
      />
      <SignatrueItemDetailsForm
        data={data.details}
        specificationOptions={specificationOptions}
        dynamicSpecificationOptions={dynamicSpecificationOptions}
        onSpecificationChange={handleSpecificationChange}
        onBomChange={handleDetailBomChange}
        onDetailValueChange={handleDetailValueChange}
        onAddDetailsButtonClick={onAddDetailsButtonClick}
        onDeleteDetailButtonClick={onDeleteDetailButtonClick}
      />
    </Box>
  );
}
