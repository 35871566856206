import { ChildrenTypography, TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { MonthlyReportAggAbsorption, ResultAndIsAbnormal } from '@Src/_basic/object/MonthlyReportType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { SetStateAction, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportBlock from './components/ReportBlock';
import RowContent from './components/RowContent';
import ToggleBlockButton from './components/ToggleBlockButton';

type FilterType = 'proportion' | 'absorption' | null;

interface SummeryProps {
  proportion: {
    max: number;
    min: number;
    average: number;
  };
  absorption: {
    max: number;
    min: number;
    average: number;
  };
}

const FineAggregateAbsorptionReport = ({
  report,
}: {
  report: Array<MonthlyReportAggAbsorption<ResultAndIsAbnormal<number>>>;
}) => {
  const { t: i18T } = useTranslation();
  const [abnormalSummary, setAbnormalSummery] = useState({
    pieces: 0,
    days: 0,
    dateRecord: {},
  });
  const [summary, setSummery] = useState<SummeryProps>({
    proportion: { max: 0, min: 0, average: 0 },
    absorption: { max: 0, min: 0, average: 0 },
  });
  const [selectedFilter, setSelectedFilter] = useState<SetStateAction<FilterType>>(null);

  const [reports, setReports] = useState<Array<MonthlyReportAggAbsorption<ResultAndIsAbnormal<number>>>>([]);
  const [filteredReport, setFilteredReport] = useState<Array<MonthlyReportAggAbsorption<ResultAndIsAbnormal<number>>>>(
    [],
  );
  const [realDays, setRealDays] = useState<number>(0);

  const handleFilterChange = (filter: FilterType) => () => {
    const newFilter = selectedFilter === filter ? null : filter;
    const filteredReport = reports.filter((i: any) =>
      newFilter
        ? i[newFilter].result === summary[newFilter].max || i[newFilter].result === summary[newFilter].min
        : true,
    );
    setSelectedFilter(newFilter);
    setFilteredReport(filteredReport);
  };

  useEffect(() => {
    const days = new Set();

    const proportion = { max: Number.MIN_VALUE, min: report.length > 0 ? Number.MAX_VALUE : 0, average: 0, sum: 0 };
    const absorption = { max: Number.MIN_VALUE, min: report.length > 0 ? Number.MAX_VALUE : 0, average: 0, sum: 0 };

    report.forEach(
      (d: MonthlyReportAggAbsorption<ResultAndIsAbnormal<number>>) => {
        days.add(moment(d.testDate).format('MM-dd'));
        proportion.max = Math.max(proportion.max, d.proportion.result);
        proportion.min = Math.min(proportion.min, d.proportion.result);
        proportion.sum += d.proportion.result;

        absorption.max = Math.max(absorption.max, d.absorption.result);
        absorption.min = Math.min(absorption.min, d.absorption.result);
        absorption.sum += d.absorption.result;
      },
      [report],
    );

    setRealDays(days.size);
    setReports(report);
    setFilteredReport(report);
    setSummery({
      proportion: {
        max: proportion.max,
        min: proportion.min,
        average: checkIsNaN(proportion.sum / report.length),
      },
      absorption: {
        max: absorption.max,
        min: absorption.min,
        average: checkIsNaN(absorption.sum / report.length),
      },
    });
  }, [report]);

  return (
    <>
      <TestCardContainer sx={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <ToggleBlockButton
          top={
            <>
              <ChildrenTypography>{`${realDays}${i18T(`MONTHLY_REPORT.BASE.DAY`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ABSORPTION.SPECIFIC`)}${i18T(
                `MONTHLY_REPORT.BASE.AVERAGE`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography>{summary?.proportion?.average?.toFixed(2)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          top={
            <>
              <ChildrenTypography>{`${realDays}${i18T(`MONTHLY_REPORT.BASE.DAY`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ABSORPTION.SPECIFIC`)}${i18T(
                `MONTHLY_REPORT.BASE.SPACING`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack mt={3}>
              <ChildrenTypography>{(summary.proportion.max - summary.proportion.min)?.toFixed(2)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'proportion'}
          onPress={handleFilterChange('proportion')}
          top={
            <>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ABSORPTION.SPECIFIC`)}${i18T(
                `MONTHLY_REPORT.BASE.MAX`,
              )}`}</ChildrenTypography>
              <ChildrenTypography>{summary?.proportion?.min?.toFixed(2)}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1} flexDirection="row">
              <ChildrenTypography style={abnormalSummary.days ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.days}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAY`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          top={
            <>
              <ChildrenTypography>{`${realDays}${i18T(`MONTHLY_REPORT.BASE.DAY`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ABSORPTION.WATER_ABSORPTION`)}${i18T(
                `MONTHLY_REPORT.BASE.AVERAGE`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1} flexDirection="row">
              <ChildrenTypography>{summary?.absorption?.average?.toFixed(2)}</ChildrenTypography>
              <ChildrenTypography>%</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          top={
            <>
              <ChildrenTypography>{`${realDays}${i18T(`MONTHLY_REPORT.BASE.DAY`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ABSORPTION.WATER_ABSORPTION`)}${i18T(
                `MONTHLY_REPORT.BASE.SPACING`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1} flexDirection="row">
              <ChildrenTypography>{(summary.absorption.max - summary.absorption.min)?.toFixed(2)}</ChildrenTypography>
              <ChildrenTypography>%</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'absorption'}
          onPress={handleFilterChange('absorption')}
          top={
            <>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ABSORPTION.WATER_ABSORPTION`)}${i18T(
                `MONTHLY_REPORT.BASE.SPACING`,
              )}`}</ChildrenTypography>
              <Stack flexDirection="row">
                <ChildrenTypography>{summary?.absorption?.max?.toFixed(2)}</ChildrenTypography>
                <ChildrenTypography>%</ChildrenTypography>
              </Stack>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ABSORPTION.WATER_ABSORPTION`)}${i18T(
                `MONTHLY_REPORT.BASE.SPACING`,
              )}`}</ChildrenTypography>
              <Stack flexDirection="row">
                <ChildrenTypography>{summary?.absorption?.min?.toFixed(2)}</ChildrenTypography>
                <ChildrenTypography>%</ChildrenTypography>
              </Stack>
            </Stack>
          }
        />
      </TestCardContainer>
      {filteredReport &&
        filteredReport?.map((item: MonthlyReportAggAbsorption<ResultAndIsAbnormal<number>>) => (
          <ReportBlock
            key={item.exptId}
            tester={item.tester}
            exptId={item.exptId}
            exptItemCode={item.exptItemCode}
            verifyPassedResults={item.verifyPassedResults}
            verifyCustomResults={item.verifyCustomResults}
          >
            <RowContent
              widths={[32, 32, 35]}
              labels={[
                i18T(`MONTHLY_REPORT.BASE.TEST_DATE`),
                i18T(`MONTHLY_REPORT.ABSORPTION.WATER_ABSORPTION`),
                `${i18T(`MONTHLY_REPORT.ABSORPTION.SPECIFIC`)}(%)`,
              ]}
              values={[
                moment(item.testDate).format('YYYY/MM/DD'),
                [item?.absorption?.result?.toFixed(2) ?? '0', item?.absorption?.isAbnormal],
                [item?.proportion?.result?.toFixed(2) ?? '0', item?.proportion?.isAbnormal],
              ]}
            />
          </ReportBlock>
        ))}
    </>
  );
};

export default memo(FineAggregateAbsorptionReport);
