import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';
import PreviousIcon from '@Src/_basic/icons/Previous';
import Save from '@Src/_basic/icons/Save';

interface Props {
  onSubmit: () => void;
  onGoBack: () => void;
}
export default function FactoryManagementCreateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onSubmit, onGoBack } = props;
  return (
    <>
      <YatungButton
        text={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.PREVIOUS')}
        color={'red'}
        variant="contained"
        size="large"
        onClick={onGoBack}
        startIcon={<PreviousIcon />}
        width={120}
      />
      <YatungButton
        text={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.SAVE')}
        variant="contained"
        size="large"
        onClick={onSubmit}
        startIcon={<Save />}
        color={'blue'}
        width={120}
      />
    </>
  );
}
