import YatungPaper from '@Src/_basic/components/YatungPaper';
import {
  CreateInitialStorageYard,
  WarehouseAndStateData,
  WarehouseData,
} from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { FeedTroughMaintenanceApi } from '@Src/_basic/protocol/feedTroughMaintenanceManagement/FeedTroughMaintenanceApi';
import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import StorageYardCreateActions from './components/StorageYardCreateActions';
import StorageYardCreateBody from './components/StorageYardCreateBody';

interface Props {
  index: number;
  selectedFactory: number;
  data: WarehouseAndStateData;
}
export default function StorageYardCreatePaper(props: Props) {
  const { t: i18T } = useTranslation();
  const { index, selectedFactory } = props;
  const feedTroughMaintenanceSchema = Yup.object().shape({
    typeId: Yup.number().positive(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.TYPE')),
    name: Yup.string().required(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NAME')),
    specificationId: Yup.number().positive(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.SPEFICATION')),
    capacity: Yup.number().min(1, i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NUMBER')),
  });
  const { storageYardeData, setStorageYardeData, setStorageYardCreateDatas, storageYardCreateDatas } =
    useFeedTroughMaintenance();
  const initialFeedTroughMaintenance: CreateInitialStorageYard = {
    typeId: 0,
    name: '',
    specificationId: 0,
    code: '',
    capacity: 0,
    isYard: true,
  };
  const handleSaveSubmit = async (request: CreateInitialStorageYard) => {
    FeedTroughMaintenanceApi.postStorage(
      { ...request, factoryId: selectedFactory },
      (datas: WarehouseData[]) => {
        FeedTroughMaintenanceApi.getStorageBySearch(
          { factoryId: selectedFactory, isYard: true },
          (values: WarehouseAndStateData[]) => {
            mappingData(values);
          },
          undefined,
        );
      },
      undefined,
    );
  };
  const mappingData = (values: WarehouseAndStateData[]) => {
    const mappedData = values.map((value: WarehouseAndStateData) => ({
      ...value,
      state: false,
    }));
    const dataMap: any = {};

    storageYardeData.forEach((element: WarehouseData) => {
      dataMap[element.id] = {
        ...element,
      };
    });

    const mappedDataTemp: Array<any> = [];
    mappedData.forEach((element: WarehouseData) => {
      mappedDataTemp.push({
        ...element,
        ...dataMap[element.id],
      });
    });
    setStorageYardeData(mappedDataTemp);
    const newDatas = Object.assign([], storageYardCreateDatas);
    newDatas.splice(index, 1);
    setStorageYardCreateDatas(newDatas);
  };
  return (
    <Box>
      <Formik
        initialValues={initialFeedTroughMaintenance}
        validationSchema={feedTroughMaintenanceSchema}
        onSubmit={(require) => handleSaveSubmit(require)}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Grid container justifyContent={'center'} item xs={12}>
                <YatungPaper
                  body={
                    <StorageYardCreateBody
                      values={values}
                      setValues={setFieldValue}
                      selectedFactory={selectedFactory}
                    />
                  }
                  actions={<StorageYardCreateActions index={index} />}
                  sx={{ borderRadius: 5, width: 610, height: 550, boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
