import { Protocol } from "../Protocol";

export class StationProtocol {
  public static readonly ALL_STATION: string = `${Protocol.YATUNG_API_ROOT}/station/all`;
  public static readonly GET_STATION_PAGINATION: string = `${Protocol.YATUNG_API_ROOT}/station/pageable`;
  public static readonly GET_STATION_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/station`;
  public static readonly GET_STATION_OPTION: string = `${Protocol.YATUNG_API_ROOT}/station/option`;
  public static readonly CREATE_STATION: string = `${Protocol.YATUNG_API_ROOT}/station`;
  public static readonly UPDATE_STATION: string = `${Protocol.YATUNG_API_ROOT}/station`;
  public static readonly DELETE_STATION: string = `${Protocol.YATUNG_API_ROOT}/station`;
  public static readonly GET_STATION_FACTORY: string = `${Protocol.YATUNG_API_ROOT}/station/factory`;
  public static readonly GET_STATION_ACCOUNT_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/station/account`;



  public static readonly SITE_MANAGEMENT_DATA: string = `api/siteManagement`;
  public static readonly SITE_MANAGEMENT_GET_ID: string = 'api/siteManagement/getId';
  public static readonly SITE_MANAGEMENT_CREATE_DATA: string = `api/siteManagement/create`;
  public static readonly SITE_MANAGEMENT_UPDATE_DATA: string = `api/siteManagement/update`;
  public static readonly SITE_MANAGEMENT_SEARCH_DATA: string = `api/siteManagement/search`;
}
