import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { MonitorCustomerData } from '@Src/_basic/object/MonitorType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import AccountNameColumnFormatter from './columnFormatter/AccountNameColumnFormatter';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import AddressColumnFormatter from './columnFormatter/AddressColumnFormatter';
import DateColumnFormatter from './columnFormatter/DateColumnFormatter';
import OrderNameColumnFormatter from './columnFormatter/OrderNameColumnFormatter';
import OutCarColumnFormatter from './columnFormatter/OutCarColumnFormatter';
import TotalCarColumnFormatter from './columnFormatter/TotalCarColumnFormatter';
import WarningColumnFormatter from './columnFormatter/WarningColumnFormatter';

interface Props {
  loading?: boolean;
  customerData?: MonitorCustomerData[] | undefined;
}
export default function MonitorByDetailTable(props: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { loading, customerData } = props;

  const getButtonText = (status: string) => {
    switch (status) {
      case '詳細':
        return i18T('GLOBAL.DETAIL');
    }
  };
  const handleGoToUpdatePage = (
    id: number,
    customerId: number,
    orderId: number,
    orderCount: number,
    factoryName: string,
  ) => {
    navigate(
      `/realTimeMonitoring/deliveryInformation/deliveryInformationDetailCard?factoryId=${id}&customerId=${customerId}&orderId=${orderId}&count=${orderCount}&factory=${factoryName}`,
    );
  };
  const columns: Array<Column> = [
    {
      text: i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ACCOUNT_NAME'),
      dataField: 'accountName',
      formatter: AccountNameColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ORDER_NAME'),
      dataField: 'orderName',
      formatter: OrderNameColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.TOTAL_CAR'),
      dataField: 'totalCar',
      formatter: TotalCarColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.OUT_CAR'),
      dataField: 'outCar',
      formatter: OutCarColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.ADDRESS'),
      dataField: 'address',
      formatter: AddressColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.DATE'),
      dataField: 'date',
      formatter: DateColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.WARNING'),
      dataField: 'warning',
      formatter: WarningColumnFormatter,
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onGoToUpdatePage: handleGoToUpdatePage,
        getButtonText: getButtonText,
      },
    },
  ];
  return <YatungTable columns={columns} data={customerData} loading={loading} />;
}
