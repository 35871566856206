import { AreaData } from '@Src/_basic/object/AreaType';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import React, { useEffect, useState } from 'react';
import AreaManagementCard from '../components/AreaManagementCard';
import AreaManagementCreateCard from '../components/AreaManagementCreateCard';
import AreaManagementUpdateCard from '../components/AreaManagementUpdateCard';

export default function AreaManagementScreen() {
  const [loading, setLoading] = useState<boolean>(false);
  const [areaData, setAreaData] = useState<AreaData[]>([]);
  const [areaUpdateData, setAreaUpdateData] = useState<AreaData>({
    id: 0,
    name: '',
    removed: false,
    createTime: new Date(),
    sortingOrder: 0,
    factoryList: [],
  });
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const getAllAreaData = () => {
    setLoading(true);
    AreaApi.getAllAreas(
      (data: Array<AreaData>) => {
        setAreaData(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };

  const handleOpenUpdateModal = (id: number) => {
    AreaApi.getAllAreaById(
      id,
      (datas: AreaData) => {
        setAreaUpdateData(datas);
        setUpdateModalOpen(true);
      },
      undefined,
      () => {},
    );
  };
  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleUpdateGoBackButtonClick = () => {
    setUpdateModalOpen(false);
  };
  const handleCreateGoBackButtonClick = () => {
    setCreateModalOpen(false);
  };
  useEffect(() => {
    getAllAreaData();
  }, []);
  return (
    <>
      <AreaManagementCard
        loading={loading}
        areaData={areaData}
        onOpenUpdateModal={(id: number) => handleOpenUpdateModal(id)}
        onOpenCreateModal={handleOpenCreateModal}
      />
      <AreaManagementCreateCard
        onCreateGoBackButtonClick={handleCreateGoBackButtonClick}
        createModalOpen={createModalOpen}
        setAreaData={setAreaData}
        setLoading={setLoading}
      />
      <AreaManagementUpdateCard
        setLoading={setLoading}
        areaUpdateData={areaUpdateData}
        onUpdateGoBackButtonClick={handleUpdateGoBackButtonClick}
        updateModalOpen={updateModalOpen}
        setAreaData={setAreaData}
      />
    </>
  );
}
