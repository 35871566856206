export default [
  {
    id: 1,
    area: '亞興',
    factoryList: [
      {
        factoryId: 7,
        factoryName: '新竹廠',
        stations: [
          {
            id: 1034,
            stationCode: '1',
            stationName: 'qwe',
            stationType: 'Q',
            account: "Y0_Q_1",
            password:"B123456",
            typess: [
              {
                id: 6,
                name: '藥劑',
                code: '6',
                removed: false,
              },
            ],
          },
          {
            id: 1086,
            stationCode: '2',
            stationName: '品管室',
            stationType: 'Q',
            account: "Y0_Q_2",
            password:"Y123",
            typess: [
              {
                id: 5,
                name: '飛灰',
                code: '3',
                removed: false,
              },
            ],
          },
          {
            id: 1035,
            stationCode: '1',
            stationName: 'fbi',
            stationType: 'G',
            account: "Y0_G_1",
            password:"A123456",
            typess: [
              {
                id: 5,
                name: '飛灰',
                code: '3',
                removed: false,
              },
            ],
          },
        ],
      },
      {
        factoryId: 8,
        factoryName: '竹北廠',
        stations: [
          {
            id: 1085,
            stationCode: '1',
            stationName: 'abc',
            stationType: 'W',
            typess: [
              {
                id: 6,
                name: '藥劑',
                code: '6',
                removed: false,
              },
            ],
          },
          {
            id: 1086,
            stationCode: '1',
            stationName: 'def',
            stationType: 'G',
            typess: [
              {
                id: 6,
                name: '藥劑',
                code: '6',
                removed: false,
              },
              {
                id: 5,
                name: '飛灰',
                code: '3',
                removed: false,
              },
            ],
          },
        ],
      },
    ],
  },
];
