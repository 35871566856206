import NamesExpand from '@Src/_basic/components/NamesExpand';
import { RequireItemList, TestInDistrictDetialByData } from '@Src/_basic/object/TestInDistrictType';
import React from 'react';

interface Props {
  row: TestInDistrictDetialByData;
}
export default function OperatorNameColumnFormatter(props: Props) {
  const { row } = props;
  const getOperatorName = () => {
    const operatorName = row.requireItemList.map((value: RequireItemList) => value.operatorName);
    const uniqueOperatorName = Array.from(new Set(operatorName));

    return uniqueOperatorName;
  };
  return <NamesExpand names={getOperatorName()} />;
}
