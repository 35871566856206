import YatungFadeMenu from '@Src/_basic/components/YatungFadeMenu';
import { useAuth } from '@Src/redux/auth/authActions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ExptManagementFadeMenu() {
  const { t: i18T } = useTranslation();
  const { userApiPermission } = useAuth();
  const [newRouteSideMenu, setNewRouteSideMenu] = React.useState<Array<any>>([]);

  const routeSideMenu = [
    {
      title: i18T('QUALITY_MANAGE.SIDE_MENU.EXPT_MANAGEMENT.EXPT_SCHEDULE.CREATE'),
      toUrl: '/qualityManagement/exptSchedule/create',
      code: 'WD032',
    },
    {
      title: i18T('QUALITY_MANAGE.SIDE_MENU.EXPT_MANAGEMENT.EXPT_SCHEDULE.LIST'),
      toUrl: '/qualityManagement/exptSchedule/list',
      code: 'WD033',
    },
  ];

  useEffect(() => {
    const routeSideMenuTemp: Array<any> = [];
    routeSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeSideMenuTemp.push(item);
      }
    });
    setNewRouteSideMenu(routeSideMenuTemp);
  }, [userApiPermission]);
  return (
    <>
      {newRouteSideMenu.length > 0 && (
        <YatungFadeMenu text={i18T('QUALITY_MANAGE.SIDE_MENU.EXPT_MANAGEMENT.TITLE')} option={newRouteSideMenu} />
      )}
    </>
  );
}
