import React from 'react';
import NamesExpand from '@Src/_basic/components/NamesExpand';

interface Props {
  row: any;
}

export default function FactoriesNameFormatter(props: Props) {
  return (
    <>
      <NamesExpand names={props.row.factories.map((factory: any) => factory.factoryName)} />
    </>
  );
}
