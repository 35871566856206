import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';

interface Props {
  onUpdateGoBackButtonClick: () => void;
}
export default function AreaManagementUpdateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onUpdateGoBackButtonClick } = props;
  return (
    <>
      <YatungButton
        text={i18T('GLOBAL.CANCEL')}
        variant="contained"
        color="red"
        size={'large'}
        type={'button'}
        sx={{
          color: '#ffffff',
          fontSize: '20px',
          mr: 2,
          px: 2,
          py: 'auto',
        }}
        onClick={onUpdateGoBackButtonClick}
      />
      <YatungButton
        text={i18T('GLOBAL.FINISH')}
        variant={'contained'}
        color="blue"
        size={'large'}
        type={'submit'}
        sx={{
          fontSize: '20px',
          px: 2,
          py: 'auto',
        }}
      />
    </>
  );
}
