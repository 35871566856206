import YatungPaperWithRibbon from '@Src/_basic/components/YatungPaperWithRibbon';
import { RibbonVariantEnum } from '@Src/_basic/object/ProductionHistoryType';
import { Grid } from '@mui/material';
import React, { useMemo } from 'react';

import { Options } from '@Src/_basic/components/YatungDynamicComboBox';
import { CreateSignatureItemDetailRequest, CreateSignatureItemRequest } from '@Src/_basic/object/SignatureType';
import AddSignatureItemButton from './AddSignatureItemButton';
import SignatrueItemForm from './SignatrueItemForm';

const typeOptions: Array<Options> = [{ text: '藥劑', value: 6 }];

interface Props {
  data: Array<CreateSignatureItemRequest>;
  onChange: (value: Array<CreateSignatureItemRequest>) => void;
}

export default function ChemicalCard(props: Props) {
  const isRenderAddSignatureItemButton = useMemo(() => {
    return props.data.length < typeOptions.length;
  }, [props.data.length, typeOptions.length]);
  const dynamicTypeOptions = useMemo(() => {
    const typeIds: Array<number> = props.data.map((item: CreateSignatureItemRequest) => item.typeId);

    return typeOptions.filter((option: Options) => !typeIds.includes(Number(option.value)));
  }, [props.data, typeOptions]);

  const handleTypeChange = (typeId: number, order: number) => {
    const data = [...props.data];
    data.find((item: CreateSignatureItemRequest) => item.order === order)!.typeId = typeId;

    props.onChange(data);
  };

  const handleDetailsChange = (value: Array<CreateSignatureItemDetailRequest>, itemOrder: number) => {
    const data = [...props.data];
    const item = data.find((item: CreateSignatureItemRequest) => item.order === itemOrder);
    item!.details = value;

    props.onChange(data);
  };

  const handleAddSignatureItemButtonClick = () => {
    props.onChange([
      ...props.data,
      {
        typeId: 0,
        order: props.data?.length + 1,
        details: [],
      },
    ]);
  };

  const handleAddSignatureItemDetailsButtonClick = (itemOrder: number) => {
    const data = [...props.data];
    const item = data.find((item: CreateSignatureItemRequest) => item.order === itemOrder);

    if (item?.details) {
      item.details?.push({ specificationId: 0, order: item!.details.length + 1, detailValue: '', BOMId: 0 });
    } else {
      item!.details = [{ specificationId: 0, order: 1, detailValue: '', BOMId: 0 }];
    }

    props.onChange(data);
  };

  const handleDeleteSignatureItemButtonClick = (itemOrder: number, detailOrder: number) => {
    const data = [...props.data];
    const item = data.find((item: CreateSignatureItemRequest) => item.order === itemOrder);
    item!.details = item!.details.filter((detail: CreateSignatureItemDetailRequest) => detail.order !== detailOrder);

    props.onChange(data);
  };

  return (
    <YatungPaperWithRibbon variant={RibbonVariantEnum.CHEMICAL} divider>
      <Grid container direction="row" alignItems="center" sx={{ gap: '8px' }}>
        {props.data.map((item: CreateSignatureItemRequest, index: number) => (
          <Grid key={index} item xs={4}>
            <SignatrueItemForm
              data={item}
              typeOptions={typeOptions}
              dynamicTypeOptions={dynamicTypeOptions}
              onTypeChange={(typeId: number) => {
                handleTypeChange(typeId, item.order);
              }}
              onDetailsChange={(value: Array<CreateSignatureItemDetailRequest>) => {
                handleDetailsChange(value, item.order);
              }}
              onAddDetailsButtonClick={() => {
                handleAddSignatureItemDetailsButtonClick(item.order);
              }}
              onDeleteDetailButtonClick={(detailOrder: number) => {
                handleDeleteSignatureItemButtonClick(item.order, detailOrder);
              }}
            />
          </Grid>
        ))}
        {isRenderAddSignatureItemButton && (
          <Grid item xs={4}>
            <AddSignatureItemButton onClick={handleAddSignatureItemButtonClick} />
          </Grid>
        )}
      </Grid>
    </YatungPaperWithRibbon>
  );
}
