import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungFormSelect from '@Src/_basic/components/YatungFormSelect';
import { Options } from '@Src/_basic/components/YatungSelect';
import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import {
  IntlUnitConversionsArrayPostData,
  UpdateInitialWeightUnitRequest,
} from '@Src/_basic/object/WeightUnitManageType';
import { Stack } from '@mui/material';
import { Field } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BottomMappingData from './components/BottomMappingData';

interface Props {
  types: Array<Options>;
  avaliableOptions: Array<Options>;
  values: UpdateInitialWeightUnitRequest;
  arrayHelpers: any;
  setFieldValue: any;
  isUpdateUnitDialog: boolean;
}
export default function UpdateUnitField(props: Props) {
  const { t: i18T } = useTranslation();
  const { types, arrayHelpers, values, setFieldValue, isUpdateUnitDialog, avaliableOptions } = props;

  const handlePushData = () => {
    arrayHelpers.push({
      intlUnitId: 0,
      intlUnitValue: 0,
    });
  };
  const refresh = () => {
    const name = values.name;
    const typeId = values.typeId;
    const intlUnitConversions = values.intlUnitConversions;
    const intlUnitConversionsFilter: Array<IntlUnitConversionsArrayPostData> = intlUnitConversions.filter(
      (value: IntlUnitConversionsArrayPostData) => value.intlUnitId !== 0,
    );
    setFieldValue('name', name);
    setFieldValue('typeId', typeId);
    setFieldValue('intlUnitConversions', intlUnitConversionsFilter);
  };
  useEffect(() => {
    if (isUpdateUnitDialog === false) {
      refresh();
    }
  }, [isUpdateUnitDialog]);
  return (
    <>
      <Stack direction={'row'} sx={{ my: 2 }}>
        <Stack direction={'row'} justifyContent={'flex-start'}>
          <YatungFormLabel
            label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.UNIT_NAME')}
            sx={{ fontSize: 20, fontWeight: 900, alignItems: 'center' }}
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-start'} sx={{ ml: 2 }}>
          <Field
            id="name"
            name="name"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ width: '80%' }}
            required
          />
        </Stack>
      </Stack>
      <Stack direction={'row'} sx={{ my: 2 }}>
        <Stack direction={'row'} justifyContent={'flex-start'}>
          <YatungFormLabel
            label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.RELATED_UNIT')}
            sx={{ fontSize: 20, fontWeight: 900, alignItems: 'center' }}
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-start'} sx={{ ml: 1 }}>
          <Field
            id="typeId"
            name="typeId"
            type="number"
            label=""
            component={YatungFormSelect}
            options={types}
            required
          />
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
        <YatungButton
          color="green"
          text={i18T('GLOBAL.CREATE')}
          startIcon={<AddCircleFill />}
          disabled={values.intlUnitConversions.length > 4}
          onClick={handlePushData}
          sx={{
            fontSize: '20px',
            width: 90,
            alignSelf: 'center',
          }}
        />
      </Stack>
      <Stack direction={'column'} sx={{ border: '1px solid #6C708C', mt: 1, borderRadius: '8px', px: 4, pt: 1, pb: 5 }}>
        <Stack direction={'row'} spacing={2} sx={{ my: 2 }}>
          <YatungFormLabel
            label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.NAME')}
            sx={{ color: '#003087', fontSize: 20, fontWeight: 900 }}
          />
          <YatungFormLabel
            label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.VALUE')}
            sx={{ color: '#003087', fontSize: 20, fontWeight: 900 }}
          />
        </Stack>
        <YatungDivider borderStyle />
        {values.intlUnitConversions.length > 0 &&
          values.intlUnitConversions.map((intlUnitConversion: any, index: number) => (
            <Stack key={index}>
              <BottomMappingData index={index} avaliableOptions={avaliableOptions} values={values} />
            </Stack>
          ))}
      </Stack>
    </>
  );
}
