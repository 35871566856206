import { CalcComboSieveAnalysis, CalcFineSieve } from '@Src/_basic/helpers/CalcTestForm';
import {
  getPassedResultByKey,
  getRejectedResultByKey,
  isCustomStandardPassed,
  isLegalStandardPassed,
  isRejected,
} from '@Src/_basic/helpers/VerifyResultHelper';
import { CustomStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/CustomStandardType';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { ExperimentResultType } from '@Src/_basic/object/TestType';
import { FineSieve } from '@Src/_basic/object/test/sandRock/FineSieveType';
import { CustomStandardV2Api } from '@Src/_basic/protocol/customStandardV2/CustomStandardV2Api';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { Stack, Typography } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TestCardContainer, TestVerifyResultStyles } from '../MuiStyleComponent/TestComponent';
import SectionCollapse from './SectionCollapse';
import TestTable from './TestTable';

interface Props {
  testForm: FineSieve[];
  ratio: number;
}

const FineSieveReportTable = ({ testForm, ratio }: Props) => {
  const { t: i18T } = useTranslation();
  const [legalStandard, setLegalStandard] = useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [customStandard, setCustomStandard] = useState<Map<string, CustomStandardV2SpecificationDetailsResponse>>();
  const calcTestFormS1 = useMemo(() => new CalcFineSieve(testForm[0]), [testForm]);
  const calcTestFormS2 = useMemo(() => new CalcFineSieve(testForm[1]), [testForm]);
  const calcTestFormMix = useMemo(
    () => new CalcComboSieveAnalysis(calcTestFormS1, calcTestFormS2, ratio),
    [calcTestFormS1, calcTestFormS2, ratio],
  );

  const mixValueMappingTable = useMemo(() => {
    const mixValueMappingTableTemp = new Map<string, number>();

    mixValueMappingTableTemp.set('9.5mm', calcTestFormMix.poreDiameterRemandPercentage1);
    mixValueMappingTableTemp.set('4.75mm', calcTestFormMix.poreDiameterRemandPercentage2);
    mixValueMappingTableTemp.set('2.36mm', calcTestFormMix.poreDiameterRemandPercentage3);
    mixValueMappingTableTemp.set('1.18mm', calcTestFormMix.poreDiameterRemandPercentage4);
    mixValueMappingTableTemp.set('0.600mm', calcTestFormMix.poreDiameterRemandPercentage5);
    mixValueMappingTableTemp.set('0.300mm', calcTestFormMix.poreDiameterRemandPercentage6);
    mixValueMappingTableTemp.set('0.150mm', calcTestFormMix.poreDiameterRemandPercentage7);
    mixValueMappingTableTemp.set('0.075mm', calcTestFormMix.poreDiameterRemandPercentage8);
    mixValueMappingTableTemp.set('lossPercentage', calcTestFormMix.lossPercentage);
    mixValueMappingTableTemp.set('dirtContentRatio', calcTestFormMix.dirtContent);
    mixValueMappingTableTemp.set('fm', calcTestFormMix.FM);

    return mixValueMappingTableTemp;
  }, [calcTestFormMix]);

  const verifyResult = useMemo(() => {
    const resultMap = new Map<string, boolean>();

    mixValueMappingTable.forEach((value, key) => {
      resultMap.set(key, getPassedResultByKey(legalStandard, key, value));
    });

    return resultMap;
  }, [legalStandard, mixValueMappingTable]);

  const verifyCustomResult = useMemo(() => {
    const resultMap = new Map<string, boolean>();

    mixValueMappingTable.forEach((value, key) => {
      resultMap.set(key, getPassedResultByKey(customStandard, key, value));
    });

    return resultMap;
  }, [customStandard, mixValueMappingTable]);

  const verifyCustomRejectResult = useMemo(() => {
    const resultMap = new Map<string, boolean>();

    mixValueMappingTable.forEach((value, key) => {
      resultMap.set(key, getRejectedResultByKey(customStandard, key, value));
    });

    return resultMap;
  }, [customStandard, mixValueMappingTable]);

  const isRecordLegalPassed = useMemo(() => {
    return (
      isLegalStandardPassed(testForm[0].verifyPassedResults) && isLegalStandardPassed(testForm[1].verifyPassedResults)
    );
  }, [testForm]);

  const isRecordCustomPassed = useMemo(() => {
    return (
      isCustomStandardPassed(testForm[0].verifyCustomResults) && isCustomStandardPassed(testForm[1].verifyCustomResults)
    );
  }, [testForm]);

  const isRecordCustomRejected = useMemo(() => {
    return isRejected(testForm[0].verifyCustomResults) || isRejected(testForm[1].verifyCustomResults);
  }, [testForm]);

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(verifyResult);
  }, [verifyResult]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(verifyCustomResult);
  }, [verifyCustomResult]);

  const isCustomRejected = useMemo(() => {
    return isRejected(verifyCustomRejectResult);
  }, [verifyCustomRejectResult]);

  const recordVerifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isRecordLegalPassed && isRecordCustomPassed) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isRecordLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isRecordCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isRecordCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [isRecordLegalPassed, isRecordCustomPassed, isRecordCustomRejected]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstHeader = useMemo(
    () => ({
      title: i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`),
      values: [
        `${i18T(`TEST.SIEVE_ANALYSIS.ACCUMULATION_SIEVE_WEIGHT`)}(g)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.ACCUMULATION_PERCENTAGE`)}(%)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.PASS_SIEVE_PERCENTAGE`)}(%)`,
      ],
    }),
    [i18T],
  );

  const firstData = useMemo(
    () => [
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.9500um`),
        values: [
          [
            testForm[0]?.poreDiameter1?.toFixed(1),
            testForm[0]?.poreDiameter1Percentage?.toFixed(1),
            testForm[0]?.sievingPoreDiameter1Percentage?.toFixed(1),
          ],
          [
            testForm[1]?.poreDiameter1?.toFixed(1),
            testForm[1]?.poreDiameter1Percentage?.toFixed(1),
            testForm[1]?.sievingPoreDiameter1Percentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [
          testForm[0].verifyPassedResults?.['9.5mm'] ? TestVerifyResultStyles.textA : TestVerifyResultStyles.abnormal,
          testForm[1]?.verifyPassedResults?.['9.5mm'] ? TestVerifyResultStyles.textB : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.4750um`),
        values: [
          [
            testForm[0]?.poreDiameter2?.toFixed(1),
            testForm[0]?.poreDiameter2Percentage?.toFixed(1),
            testForm[0]?.sievingPoreDiameter2Percentage?.toFixed(1),
          ],
          [
            testForm[1]?.poreDiameter2?.toFixed(1),
            testForm[1]?.poreDiameter2Percentage?.toFixed(1),
            testForm[1]?.sievingPoreDiameter2Percentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [
          testForm[0].verifyPassedResults?.['4.75mm'] && testForm[0].verifyCustomResults?.['4.75mm'].passed
            ? TestVerifyResultStyles.textA
            : TestVerifyResultStyles.abnormal,
          testForm[1]?.verifyPassedResults?.['4.75mm'] && testForm[1]?.verifyCustomResults?.['4.75mm'].passed
            ? TestVerifyResultStyles.textB
            : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.2360um`),
        values: [
          [
            testForm[0]?.poreDiameter3?.toFixed(1),
            testForm[0]?.poreDiameter3Percentage?.toFixed(1),
            testForm[0]?.sievingPoreDiameter3Percentage?.toFixed(1),
          ],
          [
            testForm[1]?.poreDiameter3?.toFixed(1),
            testForm[1]?.poreDiameter3Percentage?.toFixed(1),
            testForm[1]?.sievingPoreDiameter3Percentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [
          testForm[0].verifyPassedResults?.['2.36mm'] && testForm[1]?.verifyCustomResults?.['2.36mm'].passed
            ? TestVerifyResultStyles.textA
            : TestVerifyResultStyles.abnormal,
          testForm[1]?.verifyPassedResults?.['2.36mm'] && testForm[1]?.verifyCustomResults?.['2.36mm'].passed
            ? TestVerifyResultStyles.textB
            : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.1180um`),
        values: [
          [
            testForm[0]?.poreDiameter4?.toFixed(1),
            testForm[0]?.poreDiameter4Percentage?.toFixed(1),
            testForm[0]?.sievingPoreDiameter4Percentage?.toFixed(1),
          ],
          [
            testForm[1]?.poreDiameter4?.toFixed(1),
            testForm[1]?.poreDiameter4Percentage?.toFixed(1),
            testForm[1]?.sievingPoreDiameter4Percentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [
          testForm[0].verifyPassedResults?.['1.18mm'] && testForm[0].verifyCustomResults?.['1.18mm'].passed
            ? TestVerifyResultStyles.textA
            : TestVerifyResultStyles.abnormal,
          testForm[1]?.verifyPassedResults?.['1.18mm'] && testForm[1]?.verifyCustomResults?.['1.18mm'].passed
            ? TestVerifyResultStyles.textB
            : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.600um`),
        values: [
          [
            testForm[0]?.poreDiameter5?.toFixed(1),
            testForm[0]?.poreDiameter5Percentage?.toFixed(1),
            testForm[0]?.sievingPoreDiameter5Percentage?.toFixed(1),
          ],
          [
            testForm[1]?.poreDiameter5?.toFixed(1),
            testForm[1]?.poreDiameter5Percentage?.toFixed(1),
            testForm[1]?.sievingPoreDiameter5Percentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [
          testForm[0].verifyPassedResults?.['0.600mm'] && testForm[0].verifyCustomResults?.['0.600mm'].passed
            ? TestVerifyResultStyles.textA
            : TestVerifyResultStyles.abnormal,
          testForm[1]?.verifyPassedResults?.['0.600mm'] && testForm[1]?.verifyCustomResults?.['0.600mm'].passed
            ? TestVerifyResultStyles.textB
            : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.300um`),
        values: [
          [
            testForm[0]?.poreDiameter6?.toFixed(1),
            testForm[0]?.poreDiameter6Percentage?.toFixed(1),
            testForm[0]?.sievingPoreDiameter6Percentage?.toFixed(1),
          ],
          [
            testForm[1]?.poreDiameter6?.toFixed(1),
            testForm[1]?.poreDiameter6Percentage?.toFixed(1),
            testForm[1]?.sievingPoreDiameter6Percentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [TestVerifyResultStyles.textA, TestVerifyResultStyles.textB],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.150um`),
        values: [
          [
            testForm[0]?.poreDiameter7?.toFixed(1),
            testForm[0]?.poreDiameter7Percentage?.toFixed(1),
            testForm[0]?.sievingPoreDiameter7Percentage?.toFixed(1),
          ],
          [
            testForm[1]?.poreDiameter7?.toFixed(1),
            testForm[1]?.poreDiameter7Percentage?.toFixed(1),
            testForm[1]?.sievingPoreDiameter7Percentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [
          testForm[0].verifyPassedResults?.['0.150mm'] && testForm[0].verifyCustomResults?.['0.150mm'].passed
            ? TestVerifyResultStyles.textA
            : TestVerifyResultStyles.abnormal,
          testForm[1]?.verifyPassedResults?.['0.150mm'] && testForm[1]?.verifyCustomResults?.['0.150mm'].passed
            ? TestVerifyResultStyles.textB
            : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.75um`),
        values: [
          [
            testForm[0]?.poreDiameter8?.toFixed(1),
            testForm[0]?.poreDiameter8Percentage?.toFixed(1),
            testForm[0]?.sievingPoreDiameter8Percentage?.toFixed(1),
          ],
          [
            testForm[1]?.poreDiameter8?.toFixed(1),
            testForm[1]?.poreDiameter8Percentage?.toFixed(1),
            testForm[1]?.sievingPoreDiameter8Percentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [
          testForm[0].verifyPassedResults?.['0.075mm'] && testForm[0].verifyCustomResults?.['0.075mm'].passed
            ? TestVerifyResultStyles.textA
            : TestVerifyResultStyles.abnormal,
          testForm[1]?.verifyPassedResults?.['0.075mm'] && testForm[1]?.verifyCustomResults?.['0.075mm'].passed
            ? TestVerifyResultStyles.textB
            : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.UNDER_PAN`),
        values: [
          [
            testForm[0]?.underPan?.toFixed(1),
            (100 - testForm[0]?.sievingUnderPanPercentage)?.toFixed(1),
            testForm[0]?.sievingUnderPanPercentage?.toFixed(1),
          ],
          [
            testForm[1]?.underPan?.toFixed(1),
            (100 - testForm[1]?.sievingUnderPanPercentage)?.toFixed(1),
            testForm[1]?.sievingUnderPanPercentage?.toFixed(1),
          ],
        ],
        valuesTextStyle: [TestVerifyResultStyles.textA, TestVerifyResultStyles.textB],
      },
    ],
    [i18T, testForm],
  );

  const secondHeader = useMemo(
    () => ({
      values: [
        i18T(`TEST.SIEVE_ANALYSIS.RATE_OF_WEIGHT_LOSS`),
        i18T(`TEST.SIEVE_ANALYSIS.DIRT_CONTENT`),
        i18T(`TEST.SIEVE_ANALYSIS.FM`),
      ],
    }),
    [i18T],
  );

  const secondData = useMemo(
    () => ({
      infoS1: [
        testForm[0]?.lossPercentage?.toFixed(1),
        testForm[0]?.dirtContentRatio?.toFixed(1),
        testForm[0]?.fm?.toFixed(2),
      ],

      infoS2: [
        testForm[1]?.lossPercentage?.toFixed(1),
        testForm[1]?.dirtContentRatio?.toFixed(1),
        testForm[1]?.fm?.toFixed(2),
      ],
    }),
    [testForm],
  );

  const thirdHeader = useMemo(
    () => [
      `${i18T(`TEST.SIEVE_ANALYSIS.WET_WEIGHT`)}(g)`,
      `${i18T(`TEST.SIEVE_ANALYSIS.BEFORE_RINSE_DRY_WEIGHT`)}(g)`,
      `${i18T(`TEST.SIEVE_ANALYSIS.AFTER_RINSE_DRY_WEIGHT`)}(g)`,
    ],
    [i18T],
  );

  const thirdData = useMemo(
    () => ({
      infoS1: [
        testForm[0]?.wetWeight?.toFixed(1),
        testForm[0]?.beforeRinseDryWeight?.toFixed(1),
        testForm[0]?.afterRinseDryWeight?.toFixed(1),
      ],

      infoS2: [
        testForm[1]?.wetWeight?.toFixed(1),
        testForm[1]?.beforeRinseDryWeight?.toFixed(1),
        testForm[1]?.afterRinseDryWeight?.toFixed(1),
      ],
    }),
    [testForm],
  );

  const fourthHeader = useMemo(
    () => [
      `${i18T(`TEST.SIEVE_ANALYSIS.WATER_WEIGHT`)}(g)`,
      `${i18T(`TEST.SIEVE_ANALYSIS.WATER_CONTENT`)}(%)`,
      `${i18T(`TEST.SIEVE_ANALYSIS.SURFACE_WATER_CONTENT`)}(%)`,
    ],
    [i18T],
  );

  const fourthData = useMemo(
    () => ({
      infoS1: [
        testForm[0]?.waterWeight?.toFixed(1),
        testForm[0]?.waterContent?.toFixed(1),
        testForm[0]?.surfaceWaterContent?.toFixed(1),
      ],

      infoS2: [
        testForm[1]?.waterWeight?.toFixed(1),
        testForm[1]?.waterContent?.toFixed(1),
        testForm[1]?.surfaceWaterContent?.toFixed(1),
      ],
    }),
    [testForm],
  );

  const fifthData = useMemo(
    () => [
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.9500um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage1.toFixed(1)],
        valueTextStyle: [verifyResult?.get('9.5mm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.4750um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage2.toFixed(1)],
        valueTextStyle: [verifyResult?.get('4.75mm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.2360um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage3.toFixed(1)],
        valueTextStyle: [verifyResult?.get('2.36mm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.1180um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage4.toFixed(1)],
        valueTextStyle: [verifyResult?.get('1.18mm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.600um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage5.toFixed(1)],
        valueTextStyle: [
          verifyResult?.get('0.600mm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.300um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage6.toFixed(1)],
        valueTextStyle: [
          verifyResult?.get('0.300mm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.150um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage7.toFixed(1)],
        valueTextStyle: [
          verifyResult?.get('0.150mm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
        ],
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.75um`),
        values: [calcTestFormMix.poreDiameterRemandPercentage8.toFixed(1)],
        valueTextStyle: [
          verifyResult?.get('0.075mm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
        ],
      },
    ],
    [i18T, calcTestFormMix, verifyResult],
  );

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.FINE_AGG_MIX_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandard(standardMap);
      },
      (err) => console.log(err),
    );
    CustomStandardV2Api.getCustomStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.FINE_AGG_MIX_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: CustomStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setCustomStandard(standardMap);
      },
      (err) => console.log(err),
    );
  }, []);

  return (
    <>
      <TestCardContainer>
        <SectionCollapse
          header={
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flex: 1,
                py: 2,
              }}
            >
              <Typography style={{ ...TestVerifyResultStyles.textA, fontSize: '24px', fontWeight: 'bold' }}>
                {i18T(`TEST.SIEVE_ANALYSIS.S1_FINE`)}: {(ratio * 100).toFixed(0)}%
              </Typography>
              <Typography style={{ ...TestVerifyResultStyles.textB, fontSize: '24px', fontWeight: 'bold' }}>
                {i18T(`TEST.SIEVE_ANALYSIS.S2_FINE`)}: {((1 - ratio) * 100).toFixed(0)}%
              </Typography>
            </Stack>
          }
        >
          <TestTable headerData={firstHeader} rowsMultiple={firstData} />
        </SectionCollapse>
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={secondHeader}
          rowsMultiple={[
            {
              values: [secondData.infoS1, secondData.infoS2],
              valuesTextStyle: [TestVerifyResultStyles.textA, TestVerifyResultStyles.textB],
              valueTextStyle: [
                [
                  !testForm[0]?.verifyPassedResults?.lossPercentage ||
                  !testForm[0]?.verifyCustomResults?.lossPercentage.passed
                    ? TestVerifyResultStyles.abnormal
                    : {},
                  !testForm[0]?.verifyPassedResults?.dirtContentRatio ||
                  !testForm[0]?.verifyCustomResults?.dirtContentRatio.passed
                    ? TestVerifyResultStyles.abnormal
                    : {},
                  !testForm[0]?.verifyPassedResults?.fm || !testForm[0]?.verifyCustomResults?.fm.passed
                    ? TestVerifyResultStyles.abnormal
                    : {},
                ],
                [
                  !testForm[1]?.verifyPassedResults?.lossPercentage ||
                  !testForm[1]?.verifyCustomResults?.lossPercentage.passed
                    ? TestVerifyResultStyles.abnormal
                    : {},
                  !testForm[1]?.verifyPassedResults?.dirtContentRatio ||
                  !testForm[1]?.verifyCustomResults?.dirtContentRatio.passed
                    ? TestVerifyResultStyles.abnormal
                    : {},
                  !testForm[1]?.verifyPassedResults?.fm || !testForm[1]?.verifyCustomResults?.fm.passed
                    ? TestVerifyResultStyles.abnormal
                    : {},
                ],
              ],
            },
            { values: [thirdHeader], valuesTextStyle: [{ fontSize: '20px', color: '#003087' }] },
            {
              values: [thirdData.infoS1, thirdData.infoS2],
              valuesTextStyle: [TestVerifyResultStyles.textA, TestVerifyResultStyles.textB],
            },
            { values: [fourthHeader], valuesTextStyle: [{ fontSize: '20px', color: '#003087' }] },
            {
              values: [fourthData.infoS1, fourthData.infoS2],
              valuesTextStyle: [TestVerifyResultStyles.textA, TestVerifyResultStyles.textB],
            },
          ]}
          isShowCollapse={true}
        />
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={{
            values: [`${i18T(`TEST.BASE.DETECTION`)}(${i18T(`TASK_MANAGE.VIEW.RECORD_ORDER`)})`],
          }}
          rows={[
            isRecordLegalPassed && isRecordCustomPassed
              ? {
                  titles: recordVerifyResultText,
                  titlesStyle: [TestVerifyResultStyles.normal],
                }
              : {
                  titles: recordVerifyResultText,
                  titlesStyle: [
                    isRecordLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                    isRecordCustomPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                  ],
                },
          ]}
          isShowCollapse={true}
        />
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={{
            values: [i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`), `${i18T(`TEST.SIEVE_ANALYSIS.MIX_PERCENTAGE`)}(%)`],
          }}
          rows={fifthData}
          isShowCollapse={true}
        />
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={{
            values: [
              i18T(`TEST.SIEVE_ANALYSIS.FINE.MIX_LOSS_RATIO`),
              i18T(`TEST.SIEVE_ANALYSIS.FINE.MIX_DIRT_CONTENT`),
              i18T(`TEST.SIEVE_ANALYSIS.FINE.MIX_FM`),
            ],
          }}
          headerTextStyle={{ flex: 1 }}
          rows={[
            {
              values: [
                calcTestFormMix.lossPercentage.toFixed(2),
                calcTestFormMix.dirtContent.toFixed(2),
                calcTestFormMix.FM.toFixed(2),
              ],
              valueTextStyle: [
                verifyResult?.get('lossPercentage') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                verifyResult?.get('dirtContentRatio') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                verifyResult?.get('fm') ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
              ],
            },
          ]}
          isShowCollapse={true}
        />
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={{
            values: [`${i18T(`TEST.BASE.DETECTION`)}(${i18T(`TASK_MANAGE.VIEW.REPORT`)})`],
          }}
          rows={[
            isLegalPassed && isCustomPassed && !isCustomRejected
              ? {
                  titles: verifyResultText,
                  titlesStyle: [TestVerifyResultStyles.normal],
                }
              : {
                  titles: verifyResultText,
                  titlesStyle: [
                    isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                    isCustomPassed && !isCustomRejected
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal,
                  ],
                },
          ]}
          isShowCollapse={true}
        />
      </TestCardContainer>
    </>
  );
};

export default memo(FineSieveReportTable);
