import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { useApi } from '@Src/redux/api/apiAction';
import { useExptSchedule } from '@Src/redux/exptSchedule/exptScheduleActions';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Grid, Pagination } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ExptScheduleTable from '../components/ExptScheduleTable';

type QueryType = {
  factoryId: number;
};

type FilterValueType = QueryType & {
  areaId: number;
};

type SearchParams = QueryType & {
  page: number;
  pageSize: number;
};

type AsyncStorageSavingType = SearchParams & FilterValueType;

export default function ExptScheduleScreen() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { pageableExptSchedules, getCancelablePageableExptSchedulesBySearch, cleanPageableExptSchedules } =
    useExptSchedule();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue = useMemo(() => {
    const { areaId = 0, factoryId = 0 } =
      request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
    };
  }, [request, location.pathname]);

  const initQueryValue = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const searchParams: SearchParams = useMemo(() => {
    return {
      ...query,
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
    };
  }, [query, pageInfo.page, pageInfo.pageSize]);

  const reset = useCallback(() => {
    cleanPageableExptSchedules();
    pageInfoDispatch({
      page: 0,
      total: 0,
      totalCount: 0,
    });
  }, [cleanPageableExptSchedules]);

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
  };

  useEffect(() => {
    if (pageableExptSchedules) {
      pageInfoDispatch({
        total: pageableExptSchedules.maxPage,
        totalCount: pageableExptSchedules.totalElements || 0,
      });
    }
  }, [pageableExptSchedules]);

  useEffect(() => {
    if (!searchParams.factoryId) {
      reset();
    } else {
      setSaveRequest({ page: location.pathname, request: { areaId: filterValue.areaId, ...searchParams } });
      getCancelablePageableExptSchedulesBySearch(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <YatungPage
      title={i18T(`QUALITY_MANAGE.SIDE_MENU.EXPT_MANAGEMENT.EXPT_SCHEDULE.LIST`)}
      body={
        <>
          <YatungCombinationSelect<QueryType, FilterValueType>
            filterValue={filterValue}
            handleChange={handleChange}
            handleFilterChange={handleFilterChange}
            selectOptions={['AreaAndFactory']}
          />
          <ExptScheduleTable
            loading={actionLoading}
            rows={pageableExptSchedules?.data || []}
            searchFn={() => getCancelablePageableExptSchedulesBySearch(searchParams)}
          />
          {!!pageInfo.totalCount && (
            <Grid container alignItems={'center'} item xs={12} sx={{ p: 1 }}>
              <Grid container justifyContent={'center'} item xs={11}>
                <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
              </Grid>

              <Grid container justifyContent={'flex-end'} item xs={1}>
                <YatungPaperLabel label={`總數：${pageInfo.totalCount}`} totalCount />
              </Grid>
            </Grid>
          )}
        </>
      }
    />
  );
}
