import React, { useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';
import Clear from '@Src/_basic/icons/Clear';
import { useDate } from '@Src/redux/date/dateActions';
import { ClearExecutionApi } from '@Src/_basic/protocol/clearExecution/ClearExecutionApi';
import { WarehouseData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';

interface Props {
  clearExecutionDataById: WarehouseData | undefined;
  modalOpen: boolean;
  onModalClose: () => void;
}
export default function ClearExecutionModal(props: Props) {
  const { t: i18T } = useTranslation();
  const { clearExecutionDataById, modalOpen, onModalClose } = props;

  const [notes, setNotes] = useState('');

  const { dateTime } = useDate();

  const bodyStyle = {
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#697EA3',
    px: 5,
    py: 1,
  };
  const inputStyle = {
    border: '1px solid #FFFFFF',
    borderRadius: '8px',
  };
  const handleModalColse = () => {
    setNotes('');
    onModalClose();
  };
  const handleSubmit = () => {
    ClearExecutionApi.postClearExecution(
      { storageId: clearExecutionDataById?.id, memo: notes },
      (data: WarehouseData) => {
        handleModalColse();
      },
      undefined,
      undefined,
    );
  };
  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={modalOpen}
        handleClose={handleModalColse}
        icon={<Clear sx={{ mr: 1 }} fontSize="large" />}
        postion={{
          '& .MuiDialog-container': {
            alignItems: 'center',
          },
        }}
        title={i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.CLEAR')}
        titleColor={{ color: '#FFFFFF', bgcolor: '#003087' }}
        body={
          <>
            <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 1 }}>
              <Typography sx={bodyStyle}>{clearExecutionDataById?.name}</Typography>
            </Grid>
            <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 1 }}>
              <Typography sx={bodyStyle}>
                {i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.TIMENOW')}
                {`${dateTime.getFullYear()} / ${dateTime.getMonth() + 1} /
                ${dateTime.getDate()}`}
                &nbsp; &nbsp;
                {`${dateTime.getHours()}：${dateTime.getMinutes()}`}
              </Typography>
            </Grid>
            <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 1 }}>
              <Typography sx={bodyStyle}>{i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.NOTES')}</Typography>
            </Grid>
            <Box sx={{ px: 5, py: 1 }}>
              <TextField
                multiline={true}
                rows={6}
                fullWidth
                sx={inputStyle}
                inputProps={{
                  style: {
                    color: '#6C708C',
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                  },
                }}
                defaultValue={notes}
                onBlur={(e) => {
                  setNotes(e.target.value);
                }}
              />
            </Box>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <YatungButton
                size={'large'}
                color="error"
                type={'button'}
                text={i18T('GLOBAL.CANCEL')}
                sx={{
                  fontSize: '24px',
                  px: 5,
                }}
                onClick={handleModalColse}
              />
              <YatungButton
                size={'large'}
                color="blue"
                type={'button'}
                text={i18T('GLOBAL.FINISH')}
                sx={{
                  fontSize: '24px',
                  px: 5,
                  ml: 2,
                }}
                onClick={handleSubmit}
              />
            </Grid>
          </>
        }
      />
    </Grid>
  );
}
