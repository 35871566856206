import MockAdapter from 'axios-mock-adapter/types';
import { MixerProtocol } from '../MixerProtocol';
import mixerTableMock from './mixerTableMock';

export default function mixerMockDetails(mock: MockAdapter) {
  mock.onGet(MixerProtocol.ALL_MIXER_MOCK).reply(200, {
    code:'00000',
    data: mixerTableMock,
  });
}
