import styled from '@emotion/styled';
import { Box, Checkbox, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import ExptScheduleSelect from './ExptScheduleSelect';

export type Option = {
  text: string;
  value: any;
};

interface Props {
  text: string;
  value: any;
  checkedValues?: Array<any>;
  onChange?: (value: any) => void;
  options?: Array<Option>;
  selectedValue?: any;
  onSelected?: (value: any) => void;
  options2?: Array<Option>;
  selectedValue2?: any;
  onSelected2?: (value: any) => void;
  error?: boolean;
}

const CheckboxText = styled(Typography)({
  color: '#6C708C',
  fontSize: '16px',
  fontWeight: '700',
  fontFamily: 'Microsoft JhengHei',
});

export default function ExptScheduleCheckBoxWithSelect(props: Props) {
  const isChecked = useMemo(() => {
    return props.checkedValues?.includes(props.value);
  }, [props.checkedValues, props.value]);

  const handleChange = () => {
    props.onChange?.(props.value);
  };

  const handleSelect = (e: any) => {
    props.onSelected?.(e.target.value);
  };

  const handleSelect2 = (e: any) => {
    props.onSelected2?.(e.target.value);
  };

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            checked={isChecked}
            onChange={() => {
              handleChange();
            }}
          />
          <CheckboxText>{props.text}</CheckboxText>
        </Box>
      </Grid>
      {isChecked && props.options && props.options2 && (
        <Grid item xs={6} gap="8px">
          <Grid container direction="row">
            <Grid item xs={6}>
              <ExptScheduleSelect options={props.options} value={props.selectedValue} onChange={handleSelect} />
            </Grid>
            <Grid item xs={6}>
              <ExptScheduleSelect
                options={props.options2}
                value={props.selectedValue2}
                onChange={handleSelect2}
                error={props.error}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
