import { Response } from '@Src/_basic/object/ApiType';
import {
  MaterialReceiptQRCodeDataResponse,
  MaterialReceiptsData,
  PageableMaterialReceiptsData,
  SSSData,
  SearchMaterialReceiptsParams,
  SearchPageableMaterialReceiptsParams,
  UpdateMaterialReceiptRequest,
} from '@Src/_basic/object/MaterialReceiptsType';
import { Api } from '../Api';
import { MaterialReceiptsProtocol } from './MaterialReceiptsProtocol';

export class MaterialReceiptsApi {
  public static getAllMaterialReceipts(
    onSuccess?: (data: PageableMaterialReceiptsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(MaterialReceiptsProtocol.SEARCH_MATERIAL_RECEIPTS, undefined, onSuccess, onFailed, onFinally);
  }
  public static getCancelableMaterialReceiptsBySearch(
    params: SearchMaterialReceiptsParams,
    onSuccess?: (data: PageableMaterialReceiptsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(MaterialReceiptsProtocol.SEARCH_MATERIAL_RECEIPTS, config, onSuccess, onFailed, onFinally);
  }

  public static getPageableMaterialReceiptsBySearch(
    params: SearchPageableMaterialReceiptsParams,
    onSuccess?: (data: PageableMaterialReceiptsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MaterialReceiptsProtocol.SEARCH_MATERIAL_RECEIPTS, config, onSuccess, onFailed, onFinally);
  }

  public static getAllAreaMaterialReceiptsBySearch(
    //一次匯出21場入料單
    monthTime: any,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        monthTime,
      },
    };
    Api.get(MaterialReceiptsProtocol.GET_ALL_MATERIAL_RECEIPTS_EXCEL, config, onSuccess, onFailed, onFinally);
  }

  public static getReceipt(
    receiptCode: string,
    onSuccess?: (data: MaterialReceiptsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        receiptCode,
      },
    };
    Api.get(MaterialReceiptsProtocol.RECEIPT_MATERIAL, config, onSuccess, onFailed, onFinally);
  }
  public static getSSSArray(
    params: any,
    onSuccess?: (data: SSSData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MaterialReceiptsProtocol.RECEIPT_MATERIAL_OPTS, config, onSuccess, onFailed, onFinally);
  }
  public static getReceiptQRCode(
    id: string,
    onSuccess?: (data: MaterialReceiptQRCodeDataResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };

    Api.get(MaterialReceiptsProtocol.RECEIPT_MATERIAL_QRCODE, config, onSuccess, onFailed, onFinally);
  }
  public static updateReceipt(
    request: UpdateMaterialReceiptRequest,
    onSuccess?: (data: MaterialReceiptsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(MaterialReceiptsProtocol.RECEIPT_MATERIAL, request, undefined, onSuccess, onFailed, onFinally);
  }
  public static deleteReceipt(
    id: string,
    onSuccess?: (data: MaterialReceiptsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(MaterialReceiptsProtocol.RECEIPT_MATERIAL, config, onSuccess, onFailed, onFinally);
  }
  //取得所有入料單狀態選項
  public static getReceiptMaterialOption(
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(MaterialReceiptsProtocol.GET_RECEIPT_MATERIAL_OPTIONS, undefined, onSuccess, onFailed, onFinally);
  }
  //入料單補單
  public static postReceiptMaterial(
    request: any,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(MaterialReceiptsProtocol.POST_RECEIPT_MATERIAL, request, undefined, onSuccess, onFailed, onFinally);
  }
}
