import YatungAlert from '@Src/_basic/components/YatungAlert';
import { CreateOrganizationsRequset } from '@Src/_basic/object/AccountRightType';
import { OrganizationApi } from '@Src/_basic/protocol/accountRights/organization/OrganizationApi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import OrganizeManagementCreateField from './components/OrganizeManagementCreateField';
import OrganizeManagementCreateSubmit from './components/OrganizeManagementCreateSubmit';

interface Props {
  onGoBackButtonClick: () => void;
  setAlertIsOpen: (e: boolean) => void;
  setSetAlertTitle: (e: string) => void;
}
const organizeSchema = Yup.object().shape({
  organizationName: Yup.string().required('請輸入組織名稱'),
});
export default function OrganizeManagementCreateForm(props: Props) {
  const { t: i18T } = useTranslation();
  const { onGoBackButtonClick, setAlertIsOpen, setSetAlertTitle } = props;
  const initialValues: CreateOrganizationsRequset = { organizationName: '' };
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = (request: CreateOrganizationsRequset) => {
    OrganizationApi.postCreateOrganization(
      request,
      () => {
        setAlertIsOpen(true);
        setSetAlertTitle(i18T('GLOBAL.CREATE_SUCCESS'));
        onGoBackButtonClick();
      },
      () => {
        setIsOpen(true);
      },
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={organizeSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <Grid container>
                <OrganizeManagementCreateField />
              </Grid>
              <Grid container justifyContent={'space-evenly'} sx={{ my: 2 }} item xs={12}>
                <OrganizeManagementCreateSubmit
                  onGoBackButtonClick={onGoBackButtonClick}
                  onSubmit={formik.handleSubmit}
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <YatungAlert
        title={i18T('USERSMANAGEMENU.ORGANIZATION_MANAGEMENT.ALERT_ERROR')}
        open={isOpen}
        setOpen={setIsOpen}
        type={'error'}
      />
    </>
  );
}
