import { YatungComboBox } from '@Src/_basic/components/YatungComboBox';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { entityToRequestConverter } from '@Src/_basic/helpers/StandardHelpers';
import { ExperimentResultTypeData } from '@Src/_basic/object/ExperimentResultTypeType';
import { SearchExperimentResultTypesParams } from '@Src/_basic/object/ExperimentResultTypeTypeType';
import { LegalStandardData, SearchLegalStandardsParams } from '@Src/_basic/object/LegalStandardType';
import { ExperimentResultTypeApi } from '@Src/_basic/protocol/experimentResultType/ExperimentResultTypeApi';
import { StandardV2Api } from '@Src/_basic/protocol/standardV2/legal/LegalStandardV2Api';
import { useAuth } from '@Src/redux/auth/authActions';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useCreateLegalStandardUI } from '..';

const rawMaterialMappingTable: any = {
  '1': '細粒料',
  '2': '粗粒料',
  '3': '水泥',
  '4': '爐石',
  '5': '飛灰',
  '6': '藥劑',
  '7': '拌合水',
  '8': '混凝土',
  '11': '回收水',
};

interface Props {
  typeId: number;
  experimentItemId: number;
}

export default function BasicFrom(props: Props) {
  const { userGroupsData } = useAuth();
  const [experimentResultTypeOptions, setExperimentResultTypeOptions] = useState<Options[]>([]);
  const [legalStandardOptions, setLegalStandardOptions] = useState<Options[]>([]);

  const { loading, setLoading, formData, formDataDispatch, clearFormData } = useCreateLegalStandardUI();

  const getExperimentResultTypeOptions = (experimentItemId: number) => {
    setLoading && setLoading(true);
    const searchParams: SearchExperimentResultTypesParams = { experimentItemId };
    ExperimentResultTypeApi.getExperimentResultTypesBySearch(
      searchParams,
      onGetExperimentResultTypeOptionsSuccess,
      undefined,
      () => {
        setLoading && setLoading(false);
      },
    );
  };

  const onGetExperimentResultTypeOptionsSuccess = (data: Array<ExperimentResultTypeData>) => {
    setExperimentResultTypeOptions(data.map(({ id, name }: ExperimentResultTypeData) => ({ value: id, text: name })));
  };

  const getLegalStandardOptions = () => {
    if (formData) {
      const searchParams: SearchLegalStandardsParams = {
        experimentResultTypeId: formData.experimentResultTypeId,
        sort: 'id,desc',
      };
      StandardV2Api.getLegalStandardsBySearch(searchParams, onGetLegalStandardOptionsSuccess);
    }
  };

  const onGetLegalStandardOptionsSuccess = useCallback((data: Array<LegalStandardData>) => {
    setLegalStandardOptions(data.map(({ id, name }: any) => ({ value: id, text: name })));
  }, []);

  const handleExperimentResultTypeChange = (value: any) => {
    handleFormDataChange('experimentResultTypeId')(value);
    handleFormDataChange('specifications')([]);
  };

  const handleFactoriesChange = (value: any) => {
    handleFormDataChange('factoryIds')(value);
  };

  const handleStartDateChange = (value: any) => {
    handleFormDataChange('startDate')(value);
  };

  const handleEndDateChange = (value: any) => {
    handleFormDataChange('endDate')(value);
  };

  const handleFormDataChange = (field: string) => async (value: unknown) => {
    if (formDataDispatch) {
      formDataDispatch({ type: 'updateValue', payload: { [field]: value } });
    }
  };

  const handleLegalStandardChange = async (value: any) => {
    if (value === 0 && clearFormData) {
      clearFormData();
    } else {
      StandardV2Api.getLegalStandardById(value, updateFormData);
    }
  };
  const updateFormData = (legalStandard: any) => {
    if (formDataDispatch) {
      formDataDispatch({ type: 'updateValue', payload: entityToRequestConverter(legalStandard) });
    }
  };

  useEffect(() => {
    if (formData && experimentResultTypeOptions.length) {
      if (
        !experimentResultTypeOptions.find((item: Options) => item.value === formData.experimentResultTypeId) ||
        formData.experimentResultTypeId === 0
      ) {
        handleExperimentResultTypeChange(experimentResultTypeOptions[0].value);
      }
    }
  }, [formData?.experimentResultTypeId, experimentResultTypeOptions]);

  useEffect(() => {
    if (props.experimentItemId) {
      getExperimentResultTypeOptions(props.experimentItemId);
    }
  }, [props.experimentItemId]);

  useEffect(() => {
    if (formData?.experimentResultTypeId) {
      getLegalStandardOptions();
    }
  }, [formData?.experimentResultTypeId]);

  return (
    <Paper key={formData?.id} elevation={2} sx={{ padding: '16px 32px', borderRadius: '5px' }}>
      <Stack spacing={2}>
        <Typography variant="h2" color="#003087">
          基本設定
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">規範名稱：</Typography>
            </Grid>
            <Grid item xs>
              <YatungInput
                fullWidth
                defaultValue={formData?.name}
                onBlur={(e: any) => handleFormDataChange('name')(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="flex-end">
            <Grid item xs={3}>
              <Typography variant="body1">使用過往規範資料：</Typography>
            </Grid>
            <Grid item>
              <YatungSelect
                disabled={legalStandardOptions.length === 0 || loading}
                options={[{ text: '未設定', value: 0 }, ...legalStandardOptions]}
                value={formData?.id}
                onChange={(e: any) => {
                  handleLegalStandardChange(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 對應紀錄表/報告：</Typography>
            </Grid>
            <Grid item xs>
              <YatungSelect
                disabled={experimentResultTypeOptions.length === 0 || loading}
                value={formData?.experimentResultTypeId}
                options={experimentResultTypeOptions}
                fullWidth
                onChange={(e: any) => handleExperimentResultTypeChange(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center"></Grid>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 對應廠區：</Typography>
            </Grid>
            <Grid item xs>
              <YatungComboBox
                options={userGroupsData?.factories}
                fullWidth
                multiple
                limitTags={10}
                defaultValue={formData?.factoryIds}
                onBlur={(value: any) => {
                  handleFactoriesChange(value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 對應原料：</Typography>
            </Grid>
            <Grid item>
              <Typography>{rawMaterialMappingTable[props.typeId.toString()]}</Typography>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 有效日期_開始：</Typography>
            </Grid>
            <Grid item xs>
              <YatungDateSelect
                label=""
                fullWidth
                value={formData?.startDate}
                maxDate={formData?.endDate}
                disablePast
                onChange={(date) => {
                  date && handleStartDateChange(new Date(date).getTime());
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 有效日期_結束：</Typography>
            </Grid>
            <Grid item xs>
              <YatungDateSelect
                label=""
                fullWidth
                value={formData?.endDate}
                minDate={formData?.startDate}
                disablePast
                onChange={(date) => {
                  date && handleEndDateChange(new Date(date).getTime());
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Paper>
  );
}
