import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectProps, styled } from '@mui/material';
import React from 'react';
import TriangleDown from '../icons/TriangleDown';

export type Options = {
  value: any;
  text: string | undefined;
  disabled?: boolean;
  facCode?: string;
  code?: string;
};

export type SampleOption = {
  id?: string;
  value?: any;
  text?: string | undefined;
  disabled?: boolean;
  facCode?: string;
  sampleId?: string;
};

export interface DropdownItem<T> {
  value: T;
  text: string | undefined;
}

export interface AccordionModelMenuItem<T, U> {
  text: string;
  value: T;
  extendValue?: Array<DropdownItem<U>>;
}

export interface Props extends SelectProps {
  options: Array<Options>;
  helperText?: string;
  placeholder?: string;
  width?: React.CSSProperties['width'];
  isError?: boolean;
}

const SelectInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: 8,
  background: 'white',
  '& fieldset': {
    borderColor: '#6C708C',
  },
  '& .MuiInputBase-input': {
    minHeight: `0 !important`,
    fontSize: 20,
    padding: '12px 26px 8px 12px',
  },
}));

const TriangleDownIcon = styled(TriangleDown)(() => ({
  color: '#003087',
  fill: '#003087',
}));

export default function YatungSelect({
  options,
  helperText,
  width,
  placeholder = '請選擇 - - -',
  isError,
  ...props
}: Props) {
  return (
    <FormControl sx={{ width: width ? width : 180 }}>
      {helperText && <InputLabel>{helperText}</InputLabel>}
      <Select
        defaultValue={0}
        input={<SelectInput label={helperText} />}
        IconComponent={TriangleDownIcon}
        {...props}
        sx={{
          ...props.sx,
          ...(width ? { width } : {}),
          border: isError ? '1px red solid' : {},
        }}
      >
        <MenuItem disabled value={0}>
          {placeholder}
        </MenuItem>
        {options?.map((option: Options, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
