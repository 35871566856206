import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initialState {
  actionLoading: boolean;
}

export const apiSlice = createSlice({
  name: 'api',
  initialState: {
    actionLoading: false,
  } as initialState,
  reducers: {
    setActionLoading: (state, action: PayloadAction<boolean>) => {
      state.actionLoading = action.payload;
    },
  },
});

export const { setActionLoading } = apiSlice.actions;
