import React, { Reducer, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import YatungPage from '@Src/_basic/components/YatungPage';
import { PageInfo } from '@Src/_basic/object/PageInfoType';

import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { Record, TestItemEnum } from '@Src/_basic/object/TestType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { useLocation } from 'react-router-dom';
import RecordStatusListTable from './components/RecordStatusListTable';

type QueryType = {
  factoryId: number;
  exptUnitId?: number;
  testType: number;
  exptItemCode: number | TestItemEnum;
  specificationId?: number;
  startTime: number;
  endTime: number;
  createType?: number;
  operator?: string;
  creator?: string;
};

type FilterValueType = QueryType & {
  areaId: number;
};

type SearchParams = QueryType & {
  page: number;
};

type AsyncStorageSavingType = SearchParams & FilterValueType;

function RecordStatusListScreen() {
  const { t: i18T } = useTranslation();
  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [data, setData] = useState<Array<Record>>([]);

  console.log("DATA", data)

  const getExptStatus = useCallback((searchParams: SearchParams) => {
    const { createType, operator, creator, factoryId, exptUnitId, exptItemCode, specificationId, startTime, endTime, testType, page } = searchParams;
    TestApi.getExptStatus(
      {
        createType: createType,
        operator: operator,
        creator: creator,
        startBetween: startTime,
        endBetween: endTime,
        factoryId,
        exptTypeId: testType,
        ...(exptUnitId && { exptUnitId }),
        ...(specificationId && { specificationId }),
        ...(exptItemCode && { exptItemCode: exptItemCode as TestItemEnum }),
        timeZone: 'Asia/Taipei',
        completed: true,
        pageNum: page,
      },
      (_data) => {
        setData(_data.data);
        pageInfoDispatch({ total: _data.maxPage });
      },
    );
  }, [])

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue = useMemo(() => {
    const defaultStart = new Date().getTime() - 1000 * 60 * 60 * 24;
    const defaultEnd = new Date().getTime();

    const {
      areaId = 0,
      factoryId = 0,
      exptUnitId = 0,
      testType = 0,
      exptItemCode = 0,
      specificationId = 0,
      createType = 0,
      operator = undefined,
      creator = undefined,
      startTime = defaultStart,
      endTime = defaultEnd,
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      exptUnitId,
      testType,
      specificationId,
      exptItemCode,
      createType,
      startTime,
      endTime,
      operator,
      creator
    };
  }, [request, location.pathname]);

  const initQueryValue = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
      exptUnitId: initFilterValue.exptUnitId,
      testType: initFilterValue.testType,
      specificationId: initFilterValue.specificationId,
      exptItemCode: initFilterValue.exptItemCode,
      createType: initFilterValue.createType,
      operator: initFilterValue.operator,
      creator: initFilterValue.creator,
      startTime: initFilterValue.startTime,
      endTime: initFilterValue.endTime,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const searchParams: SearchParams = useMemo(() => {
    return {
      ...query,
      page: pageInfo.page,
    };
  }, [query, pageInfo.page]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
  };

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const reset = useCallback(() => {
    setData([]);
    pageInfoDispatch({
      total: 0,
      page: 0,
      totalCount: 0,
    });
  }, []);

  useEffect(() => {
    if (!searchParams.testType || !searchParams.factoryId) {
      reset();
    } else {
      setSaveRequest({ page: location.pathname, request: { areaId: filterValue.areaId, ...searchParams } });
      // getReportSearch(searchParams);
      getExptStatus(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <YatungPage
      title={i18T('VIEW.RECORD_STATUS_LIST.TITLE')}
      contentBgColor="#FFF"
      body={
        <>
          <YatungCombinationSelect<QueryType, FilterValueType>
            filterValue={filterValue}
            handleChange={handleChange}
            handleFilterChange={handleFilterChange}
            selectOptions={['CreateType', 'Operator', "Creator", 'Date', 'AreaAndFactory', 'ExptUnit', 'ExptType', 'Specification', 'ExptTestItemType']}
          />
          <RecordStatusListTable data={data} testType={filterValue?.testType} />
          <Stack justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Stack>
        </>
      }
    />
  );
}

export default memo(RecordStatusListScreen);
