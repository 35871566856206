import React from 'react';
import FactoryManagementCreateCard from '../components/FactoryManagementCreateCard';

export default function FactoryManagementCreateCardScreen() {
  return (
    <>
      <FactoryManagementCreateCard />
    </>
  );
}
