import YatungButton from '@Src/_basic/components/YatungButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onSubmit: () => void;
  onGoBack: () => void;
  errorCode: string;
  removed: boolean;
}
export default function SiteManagementUpdateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onSubmit, onGoBack, errorCode, removed } = props;

  return (
    <>
      <YatungButton
        text={i18T('SITEMANAGEMENT.CANCEL')}
        color="error"
        variant="contained"
        size="large"
        onClick={onGoBack}
        sx={{
          color: '#ffffff',
          fontSize: '36px',
          p: 2,
          mr: 5,
        }}
      />
      <YatungButton
        disabled={errorCode === '99999' || removed === true}
        text={i18T('SITEMANAGEMENT.COMPLETE')}
        color="blue"
        variant="contained"
        size="large"
        onClick={onSubmit}
        sx={{
          color: '#ffffff',
          fontSize: '36px',
          p: 2,
        }}
      />
    </>
  );
}
