import { Response } from '@Src/_basic/object/ApiType';
import {
  PostVehicleData,
  PutVehicleData,
  SearchPaginationParams,
  VehiclePaginationData,
} from '@Src/_basic/object/VehicleType';
import { Api } from '../Api';
import { VehicleProtocol } from './VehicleProtocol';

export class VehicleAPi {
  public static getPaginationVehicle(
    params: SearchPaginationParams,
    onSuccess?: (data: VehiclePaginationData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(VehicleProtocol.PAGINATION_VEHICLE, config, onSuccess, onFailed, onFinally);
  }
  public static PostVehicle(
    request: PostVehicleData,
    onSuccess?: (data: VehiclePaginationData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(VehicleProtocol.GET_VEHICLE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }
  public static DeleteVehicle(
    id: number,
    onSuccess?: (data: VehiclePaginationData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(VehicleProtocol.GET_VEHICLE_BY_ID, config, onSuccess, onFailed, onFinally);
  }
  public static PutVehicle(
    request: PutVehicleData,
    onSuccess?: (data: VehiclePaginationData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(VehicleProtocol.GET_VEHICLE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }
}
