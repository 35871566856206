import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LangDownload(props: SvgIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 32 32" fill="none">
      <path
        d="M14.3333 15.7013V5H17.1654V15.7013L20.4667 12.4L20.5479 12.4841L20.632 12.4L22.4987 14.3333L15.832 21L15.7494 20.9173L15.6667 21L9 14.3333L10.8667 12.4L10.9508 12.4841L11.032 12.4L14.3333 15.7013Z"
        fill="white"
      />
      <path
        d="M5.95064 25.5493C6.4733 26.072 7.1013 26.3333 7.83464 26.3333H24C24.7325 26.3342 25.36 26.0733 25.8827 25.5506C26.4053 25.028 26.6667 24.4 26.6667 23.6666V19.6666H23.8346V23.6666H8V19.6666H5.16797V23.6666C5.16708 24.3991 5.42797 25.0266 5.95064 25.5493Z"
        fill="white"
      />
    </svg>
  );
}
