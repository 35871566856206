import useGetAllTypes from '@Src/_basic/apiHooks/useGetAllTypes';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFeedMachineAnimation from '@Src/_basic/components/YatungFeedMachineAnimation';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import FlyAshSmallIcon from '@Src/_basic/icons/FlyAshSmallIcon';
import FurnaceStoneSmallIcon from '@Src/_basic/icons/FurnaceStoneSmallIcon';
import More from '@Src/_basic/icons/More';
import MudSmallIcon from '@Src/_basic/icons/MudSmallIcon';
import PersonIcon from '@Src/_basic/icons/PersonIcon';
import PotionSmallIcon from '@Src/_basic/icons/PotionSmallIcon';
import RockSmall from '@Src/_basic/icons/RockSmall';
import SandSmall from '@Src/_basic/icons/SandSmall';
import SourceIcon from '@Src/_basic/icons/Source';
import Specification from '@Src/_basic/icons/Specification';
import WaterSmall from '@Src/_basic/icons/WaterSmall';
import { AreaOptions } from '@Src/_basic/object/AreaType';
import { MaterialMixerTop3, MaterialMixerTop3Receipts } from '@Src/_basic/object/FeedMachineStatusType';
import { MixerOptions } from '@Src/_basic/object/MixerTypes';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { FeedMachineStatusApi } from '@Src/_basic/protocol/feedMachineStatus/FeedMachineStatusApi';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { AddCircle } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

export default function FeedMachineStatusScreen() {
  const titleStyle = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 900,
    color: '#FFFFFF',
    ml: 1,
  };
  const bodyStyle = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#697EA3',
    my: 1,
    ml: 1,
  };
  const { t: i18T } = useTranslation();
  const { userAreaOptionsData } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { actionLoading } = useApi();

  const { typeOption } = useGetAllTypes();

  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [factories, setFactories] = useState<Options[]>([]);
  const [selectedFactory, setSelectedFactory] = useState<number>(0);
  const [selectedMixer, setSelectedMixer] = useState<number>(0);
  const [mixers, setMixers] = useState<Options[]>([]);
  const [selectedType, setSelectedType] = useState<number>(0);
  const [materialMixer, setMaterialMixer] = useState<Array<MaterialMixerTop3>>([]);
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  useEffect(() => {
    if (state) {
      setSelectedArea(state?.areaId);
      const selected: any = userAreaOptionsData.find((i: AreaOptions) => i.value === state?.areaId);
      setFactories(selected?.factories);
      setSelectedFactory(state?.factoryId);
      setSelectedMixer(state?.mixerId);
      setSelectedType(state?.typeId);
    }
  }, [state, userAreaOptionsData]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 1,
    pageSize: 12,
    total: 0,
    totalCount: 0,
  });

  const getMixerOptions = (data: Array<MixerOptions>) => {
    const mixerOptions = data.map((type: MixerOptions) => ({ value: type.id, text: type.mixerName }));
    setMixers(mixerOptions);
  };

  const handleAreaSelectChange = useCallback(
    (area: number) => {
      const selected = userAreaOptionsData.find((i: AreaOptions) => i.value === area);
      if (selected) {
        setFactories(selected.factories);
        setSelectedArea(area);
      }

      setPageInfo({
        ...pageInfo,
        page: 1,
      });
    },
    [userAreaOptionsData],
  );
  const handleReset = () => {
    setSelectedFactory(0);
    setSelectedMixer(0);
    setSelectedType(0);
  };
  useEffect(() => {
    handleReset();
  }, [selectedArea]);
  useEffect(() => {
    MixerApi.getMixerOptionsByFactoryId(
      {
        factoryId: selectedFactory,
      },
      getMixerOptions,
    );
  }, [selectedFactory]);
  const handleFactorySelectChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSelectedFactory(e.target.value as number);

      setSelectedMixer(0);
      setSelectedType(0);
      setPageInfo({
        ...pageInfo,
        page: 1,
      });
    },
    [pageInfo],
  );
  const handleTypeSelectChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSelectedType(e.target.value as number);
      setPageInfo({
        ...pageInfo,
        page: 1,
      });
    },
    [pageInfo],
  );
  const handleMixerSelectChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSelectedMixer(e.target.value as number);
      setSelectedType(0);
      setPageInfo({
        ...pageInfo,
        page: 1,
      });
    },
    [pageInfo],
  );

  useEffect(() => {
    FeedMachineStatusApi.getMaterialMixer(
      {
        mixerId: selectedMixer,
        ...(selectedType && { typeId: selectedType }),
        timeZone: 'Asia/Taipei',
        time: new Date().setHours(0, 0, 0, 0),
      },
      setMaterialMixer,
    );
  }, [selectedFactory, selectedType, selectedMixer]);

  const handleGoNextPage = (storageId: number) => {
    navigate('FeedMachineStatusDetail', {
      state: {
        areaId: selectedArea,
        factoryId: selectedFactory,
        mixerId: selectedMixer,
        typeId: selectedType,
        storageId: storageId,
      },
    });
  };
  const GetImageChange = ({ typeId }: any) => {
    switch (typeId) {
      case 1:
        return <SandSmall />;
      case 2:
        return <RockSmall />;
      case 3:
        return <MudSmallIcon />;
      case 4:
        return <FurnaceStoneSmallIcon />;
      case 5:
        return <FlyAshSmallIcon />;
      case 6:
        return <PotionSmallIcon />;
      case 7:
        return <WaterSmall />;
      default:
        return <SandSmall />;
    }
  };
  const shortenString = (storage: string, maxLength: number) => {
    if (storage.length <= maxLength) {
      return storage;
    } else {
      return storage.slice(0, maxLength) + '...';
    }
  };

  const getSpaceCard = () => {
    const spaceCard: Array<any> = [];
    if (materialMixer.length % 3 === 2) {
      spaceCard.push(
        <Card
          key={0}
          sx={{
            width: '488px',
            height: '360px',
            borderRadius: '13px',
            background: 'transparent',
            boxShadow: 'none',
            my: 1,
          }}
        />,
      );
    }
    return spaceCard;
  };

  const getToneValue = (total: number) => {
    if (total < 0) {
      return '0.000T';
    } else {
      return `${(total / 1000).toFixed(3)}T`;
    }
  };

  const handleClickAdd = (storageId: number) => {
    const selected = materialMixer.find((i: MaterialMixerTop3) => i.storageId === storageId);
    setAlertText(selected?.storage || '');
    setIsShowAlert(true);
  };
  return (
    <>
      <YatungPage
        title={i18T('USERSMANAGEMENU.FEED_MACHINE_STATUS.ROUTE_TITLE')}
        contentBgColor="#ffffff"
        body={
          <>
            <Stack direction="row" spacing={2} justifyContent="space-between" my={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <YatungSelect
                  helperText={i18T('GLOBAL.AREA')}
                  disabled={actionLoading}
                  options={userAreaOptionsData}
                  value={selectedArea}
                  onChange={(e) => handleAreaSelectChange(e.target.value as number)}
                />
                <YatungSelect
                  helperText={i18T('GLOBAL.FACTORY')}
                  disabled={selectedArea === 0 || actionLoading}
                  options={factories}
                  value={selectedFactory}
                  onChange={handleFactorySelectChange}
                />
                <YatungSelect
                  helperText={'拌合機'}
                  disabled={selectedFactory === 0 || actionLoading}
                  options={mixers}
                  value={selectedMixer}
                  onChange={handleMixerSelectChange}
                />
                <YatungSelect
                  helperText={i18T('GLOBAL.TYPE')}
                  disabled={selectedMixer === 0 || actionLoading}
                  options={[{ value: 0, text: '全部' }, ...typeOption]}
                  value={selectedType}
                  onChange={handleTypeSelectChange}
                />
              </Stack>
            </Stack>
            <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
              {materialMixer.map((data: MaterialMixerTop3, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Card sx={{ width: '488px', height: '360px', borderRadius: '13px', background: '#FFFFFF', my: 1 }}>
                      <CardContent>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                          <Stack direction={'column'} justifyContent={'center'} sx={{ position: 'relative' }}>
                            <Stack direction={'row'} justifyContent={'center'}>
                              <YatungFeedMachineAnimation
                                height={data.storageTotal / (data.storageVolume * data.storageSpecificWeight * 1000)}
                              />
                            </Stack>

                            <Stack direction={'row'} justifyContent={'center'}>
                              <Typography sx={{ color: 'black', mt: '5px' }}>
                                {getToneValue(data.storageTotal)}
                              </Typography>
                            </Stack>

                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ mt: 1 }}>
                              <Box
                                sx={{
                                  width: '94px',
                                  height: '36px',
                                  borderRadius: '200px',
                                  background: '#CB333B',
                                }}
                              >
                                <Typography variant="inherit" sx={{ textAlign: 'center', color: '#fff', mt: '5px' }}>
                                  {shortenString(data.storage, 5)}
                                </Typography>
                              </Box>
                              {data.storage.length > 5 && (
                                <AddCircle
                                  onClick={() => handleClickAdd(data.storageId)}
                                  sx={{ ':hover': { cursor: 'pointer' }, fontSize: 28 }}
                                />
                              )}
                            </Stack>
                          </Stack>
                          <Card sx={{ width: '315px', height: '288px', borderRadius: '10px', background: '#FFFFFF' }}>
                            <CardHeader
                              sx={{ color: '#ffffff', background: '#003087' }}
                              title={
                                <>
                                  <Grid container item xs={12} justifyContent={'center'} sx={{ mt: 1 }}>
                                    <Grid container item xs={6} alignItems={'center'}>
                                      <Specification />
                                      <Typography sx={titleStyle}>
                                        {i18T('USERSMANAGEMENU.FEED_MACHINE_STATUS.SPECIFICATION')}
                                      </Typography>
                                    </Grid>
                                    <Grid container item xs={6} alignItems={'center'}>
                                      <SourceIcon />
                                      <Typography sx={titleStyle}>
                                        {i18T('USERSMANAGEMENU.FEED_MACHINE_STATUS.SOURCE')}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </>
                              }
                            ></CardHeader>
                            <CardContent>
                              {data.receipts.map((receipt: MaterialMixerTop3Receipts, index: number) => {
                                return (
                                  <React.Fragment key={index}>
                                    <Grid container item xs={12} justifyContent={'center'}>
                                      <Grid container item xs={6} alignItems={'center'}>
                                        <GetImageChange typeId={data.typeId} />
                                        <Typography sx={bodyStyle}>{receipt.specification}</Typography>
                                      </Grid>
                                      <Grid container item xs={6} alignItems={'center'}>
                                        <PersonIcon />
                                        <Typography sx={bodyStyle}>{receipt.source}</Typography>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                );
                              })}
                              {data.receipts.length > 0 && (
                                <Grid container item xs={12} justifyContent={'center'} alignSelf={'flex-end'}>
                                  <YatungButton
                                    color="blue"
                                    text={i18T('GLOBAL.MORE')}
                                    onClick={() => handleGoNextPage(data.storageId)}
                                    startIcon={<More />}
                                  />
                                </Grid>
                              )}
                            </CardContent>
                          </Card>
                        </Stack>
                      </CardContent>
                    </Card>
                  </React.Fragment>
                );
              })}
              {getSpaceCard()}
            </Stack>
            <YatungConfirmAlert
              headerText={i18T('GLOBAL.STORAGE_DEFINITION_NAME')}
              isShowAlert={isShowAlert}
              onClose={() => setIsShowAlert(false)}
              alertText={alertText}
            />
          </>
        }
      />
    </>
  );
}
