import YatungTable, { FormatterProps } from '@Src/_basic/components/YatungTable';
import { CarbonEmissionList } from '@Src/_basic/object/CarbonEmissionType';
import { useApi } from '@Src/redux/api/apiAction';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  rows: Array<CarbonEmissionList>;
}

export default function CarbonEmissionTable({ rows }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const columns = [
    {
      text: '日期',
      dataField: 'date',
    },
    {
      text: '配比編號',
      dataField: 'displayName',
    },
    {
      text: '澆置方數(M³)',
      dataField: 'qty',
    },
    {
      text: '運載車次',
      dataField: 'ship',
    },
    {
      text: '混凝土生產碳排放量(kg)',
      dataField: 'carbonEmission',
      formatter: ({ row }: FormatterProps) => {
        return <Typography>{row?.carbonEmission?.toFixed(2)}</Typography>;
      },
    },
    {
      text: '生命週期碳排放量(kg)',
      dataField: 'carbonEmissionLifeCycle',
      formatter: ({ row }: FormatterProps) => {
        return <Typography>{row?.carbonEmissionLifeCycle?.toFixed(2)}</Typography>;
      },
    },
  ];

  return <YatungTable loading={actionLoading} data={rows} columns={columns} />;
}
