import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';
import PreviousIcon from '@Src/_basic/icons/Previous';
import Save from '@Src/_basic/icons/Save';
import { SxProps } from '@mui/material';

interface Props {
  onFinish: () => void;
  onCancel: () => void;
  finishIcon?: JSX.Element;
  cancelIcon?: JSX.Element;
  sx?: SxProps
}
export default function FinishCancelButton(props: Props) {
  const { t: i18T } = useTranslation();
  const { onFinish, onCancel, finishIcon, cancelIcon, sx } = props;
  return (
    <>
      <YatungButton
        color='red'
        text={i18T('GLOBAL.CANCEL')}
        onClick={onCancel}
        startIcon={cancelIcon ? cancelIcon : undefined}
        sx={sx}
        width={120}
      />
      <YatungButton
        color='blue'
        text={i18T('GLOBAL.FINISH')}
        onClick={onFinish}
        startIcon={finishIcon ? finishIcon : undefined}
        sx={sx}
        width={120}
      />
    </>
  );
}
