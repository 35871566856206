import YatungFadeMenu from '@Src/_basic/components/YatungFadeMenu';
import { useAuth } from '@Src/redux/auth/authActions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function SieveAnalysisReport() {
  const { t: i18T } = useTranslation();
  const { userApiPermission } = useAuth();
  const [newRouteSideMenu, setNewRouteSideMenu] = React.useState<Array<any>>([]);

  const routeSideMenu = [
    {
      title: i18T('VIEW.SIEVE_REPORT.TITLE'),
      toUrl: '/view/sieve-report',
      code: 'WD055',
    },
  ];
  useEffect(() => {
    const routeSideMenuTemp: Array<any> = [];
    routeSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeSideMenuTemp.push(item);
      }
    });
    setNewRouteSideMenu(routeSideMenuTemp);
  }, [userApiPermission]);
  return (
    <>
      {newRouteSideMenu.length > 0 && (
        <YatungFadeMenu text={i18T('VIEW.SIDE_MENU.SIEVE_REPORT')} option={newRouteSideMenu} />
      )}
    </>
  );
}
