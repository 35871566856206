import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { Grid, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import YatungImg from '../../../../../../_basic/components/YatungImg';

const searchSrc = '/media/images/search.png';

interface Props {
  searchValue: any;
  searched: string | undefined;
  SearchChange: () => void;
  RefreshChange: () => void;
  factoryOptions: Array<Options>;
}
export default function SearchBar(props: Props) {
  const { t: i18T } = useTranslation();
  const { searchValue, searched, factoryOptions, SearchChange, RefreshChange } = props;
  const [factory, setFactory] = useState<number>(0);
  const handleSelection = (event: SelectChangeEvent<unknown>) => {
    setFactory(event.target.value as number);
  };
  return (
    <Grid container justifyContent={'flex-start'} alignItems={'center'}>
      <YatungInput onChange={searchValue} value={searched} />
      <YatungButton
        text={i18T('VERIFICATIONCODE.SEARCh_BUTTON')}
        variant={'contained'}
        type={'button'}
        size={'large'}
        onClick={SearchChange}
        startIcon={<YatungImg img={searchSrc} />}
        sx={{
          color: '#ffffff',
          bgcolor: '#003087',
          fontSize: '20px',
          mx: 3,
          '&.MuiButtonBase-root:hover': {
            bgcolor: '#003087',
          },
        }}
      />
      <YatungSelect
        options={[{ value: 0, text: '全部廠資訊' }, ...factoryOptions]}
        value={factory}
        onChange={handleSelection}
      />
      {/* <Grid justifyContent={'flex-start'} alignItems={'center'} sx={{ mx: 3 }}>
        <Fab size="medium" onClick={RefreshChange}>
          <RefreshIcon sx={{ fontSize: '30px' }} />
        </Fab>
      </Grid> */}
    </Grid>
  );
}
