import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { requestSavingSlice } from './requestSavingSlice';

export interface RequestSavingState<T> {
  page: string;
  request: T;
}

export function useRequestSaving<T>() {
  const { request } = useSelector((state: RootState) => state.requestSaving);

  const dispatch = useDispatch();

  const setSaveRequest = (args: RequestSavingState<T> | undefined) => {
    dispatch(requestSavingSlice.actions.setRequest(args));
  };

  return {
    request,
    setSaveRequest,
  } as {
    request: RequestSavingState<T> | undefined;
    setSaveRequest: (args: RequestSavingState<T> | undefined) => void;
  };
}
