import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import MonthlyReportScreen from './screens';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <MonthlyReportScreen />,
  },
];

const MonthlyReport = () => PermissionRoute(routesConfig);

export default memo(MonthlyReport);
