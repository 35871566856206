import React from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface Props {
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
    selectedIndex: number;
    tabs: any;
    fontSize?: number;
}

interface StyledTabProps {
    label: string;
    value: number;
}

const YatungTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ theme }) => ({
    marginRight: '1px',
    padding: '8px 24px',
    backgroundColor: '#C1C1C1',
    color: 'white',
    fontWeight: 900,
    '&:hover': {
        opacity: 1,
    },
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#002F8C',
    },
}));

export default function YatungDymanicTabs({ tabs = [], fontSize, onChange, selectedIndex }: Props) {
    console.log('tabs62', tabs);
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
            }}
        >
            <Tabs
                value={selectedIndex}
                onChange={onChange}
                sx={{
                    '& .MuiTabs-indicator': {
                        opacity: 0,
                    },
                }}
            >
                {tabs.map((tab: any, i: number) => (
                    <YatungTab
                        value={tab.id}
                        label={tab.name}
                        key={tab.id}
                        sx={{ borderRadius: i === 0 ? '10px 0 0 0' : 0, fontSize: { fontSize } }}
                    />
                ))}
            </Tabs>
        </Box>
    );
}
