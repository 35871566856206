import React, { Reducer, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Pagination from '@mui/material/Pagination';

import YatungPage from '@Src/_basic/components/YatungPage';

import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { SearchReportData } from '@Src/_basic/object/ReportType';
import { TestItemEnum } from '@Src/_basic/object/TestType';
import { ReportApi } from '@Src/_basic/protocol/report/ReportApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SieveReportTable from './components/SieveReportTable';

type QueryType = {
  factoryId: number;
  exptUnitId?: number;
  exptItemCode: number | TestItemEnum;
  startTime: number;
  endTime: number;
};

type FilterValueType = QueryType & {
  areaId: number;
};

type SearchParams = QueryType & {
  page: number;
  pageSize: number;
};

type AsyncStorageSavingType = SearchParams & FilterValueType;

function SieveReportScreen() {
  const location = useLocation();
  const { t: i18T } = useTranslation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue = useMemo(() => {
    const defaultStart = new Date().getTime() - 1000 * 60 * 60 * 24;
    const defaultEnd = new Date().getTime();

    const {
      areaId = 0,
      factoryId = 0,
      exptUnitId = 0,
      exptItemCode = 0,
      startTime = defaultStart,
      endTime = defaultEnd,
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      exptUnitId,
      exptItemCode,
      startTime,
      endTime,
    };
  }, [request, location.pathname]);

  const initQueryValue = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
      exptUnitId: initFilterValue.exptUnitId,
      exptItemCode: initFilterValue.exptItemCode,
      startTime: initFilterValue.startTime,
      endTime: initFilterValue.endTime,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const [data, setData] = useState<Array<SearchReportData>>([]);

  const searchParams: SearchParams = useMemo(() => {
    return {
      ...query,
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
    };
  }, [query, pageInfo.page, pageInfo.pageSize]);

  const handlePageChange = useCallback((e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
  }, []);

  const reset = useCallback(() => {
    setData([]);
    pageInfoDispatch({
      total: 0,
      page: 0,
      totalCount: 0,
    });
  }, []);

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const getReportSearch = useCallback((searchParams: SearchParams) => {
    const { exptUnitId, exptItemCode, ...rest } = searchParams;
    ReportApi.getSearchReport(
      {
        ...(exptUnitId ? searchParams : rest),
        timezone: 'Asia/Taipei',
        exptItemCode: exptItemCode as TestItemEnum,
      },
      (_data) => {
        setData(_data.data);
        pageInfoDispatch({
          total: _data.maxPage,
          totalCount: _data.totalElements,
        });
      },
      (error) => {
        setIsShowAlert(true);
        setAlertText(typeof error?.message === 'string' ? error?.message : 'Error !');
      },
    );
  }, []);

  useEffect(() => {
    if (!searchParams.exptItemCode || !searchParams.factoryId) {
      reset();
    } else {
      setSaveRequest({ page: location.pathname, request: { ...filterValue, ...searchParams } });
      getReportSearch(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <YatungPage
      title={i18T('VIEW.SIEVE_REPORT.TITLE')}
      contentBgColor="#FFF"
      body={
        <>
          <YatungCombinationSelect<QueryType, FilterValueType>
            filterValue={filterValue}
            handleChange={handleChange}
            handleFilterChange={handleFilterChange}
            selectOptions={['Date', 'AreaAndFactory', 'ExptUnit', 'ExptTestItemType']}
            exptTypeSelection={[
              { text: '細粒料篩分析', value: TestItemEnum.FINE_AGG_SIEVE_ANALYSIS },
              { text: '粗粒料篩分析', value: TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS },
            ]}
          />
          <SieveReportTable data={data} exptCode={filterValue.exptItemCode as TestItemEnum} />
          {!!pageInfo.totalCount && (
            <Grid container alignItems={'center'} item xs={12} sx={{ p: 1 }}>
              <Grid container justifyContent={'center'} item xs={11}>
                <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
              </Grid>

              <Grid container justifyContent={'flex-end'} item xs={1}>
                <YatungPaperLabel label={`總數：${pageInfo.totalCount}`} totalCount />
              </Grid>
            </Grid>
          )}
          <YatungConfirmAlert isShowAlert={isShowAlert} onClose={() => setIsShowAlert(false)} alertText={alertText} />
        </>
      }
    />
  );
}

export default memo(SieveReportScreen);
