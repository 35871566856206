import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function FurnaceStoneSmallIcon(props: SvgIconProps) {
  return (
    <svg {...props} width="35" height="20" viewBox="0 0 35 20" fill="none">
      <mask id="path-1-inside-1_1544_39474" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8686 5.3891C17.2173 5.3891 17.5 5.02821 17.5 4.58304C17.5 4.13786 17.2173 3.77698 16.8686 3.77698C16.5198 3.77698 16.2371 4.13786 16.2371 4.58304C16.2371 5.02821 16.5198 5.3891 16.8686 5.3891ZM16.8686 4.98607C17.0429 4.98607 17.1843 4.80562 17.1843 4.58304C17.1843 4.36045 17.0429 4.18001 16.8686 4.18001C16.6942 4.18001 16.5528 4.36045 16.5528 4.58304C16.5528 4.80562 16.6942 4.98607 16.8686 4.98607Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3417 13.4497C21.5623 13.4497 22.5519 12.1866 22.5519 10.6285C22.5519 9.07038 21.5623 7.80728 20.3417 7.80728C19.1211 7.80728 18.1315 9.07038 18.1315 10.6285C18.1315 12.1866 19.1211 13.4497 20.3417 13.4497ZM20.3417 13.0467C21.388 13.0467 22.2361 11.964 22.2361 10.6285C22.2361 9.29297 21.388 8.21031 20.3417 8.21031C19.2954 8.21031 18.4473 9.29297 18.4473 10.6285C18.4473 11.964 19.2954 13.0467 20.3417 13.0467Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5528 18.2861C17.4247 18.2861 18.1315 17.3839 18.1315 16.2709C18.1315 15.158 17.4247 14.2558 16.5528 14.2558C15.6809 14.2558 14.9741 15.158 14.9741 16.2709C14.9741 17.3839 15.6809 18.2861 16.5528 18.2861ZM16.5528 17.883C17.2503 17.883 17.8158 17.1613 17.8158 16.2709C17.8158 15.3806 17.2503 14.6588 16.5528 14.6588C15.8553 14.6588 15.2899 15.3806 15.2899 16.2709C15.2899 17.1613 15.8553 17.883 16.5528 17.883Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7112 9.82243C13.7112 10.4902 13.1457 11.0315 12.4482 11.0315C11.7507 11.0315 11.1853 10.4902 11.1853 9.82243C11.1853 9.15467 11.7507 8.61334 12.4482 8.61334C13.1457 8.61334 13.7112 9.15467 13.7112 9.82243ZM13.3954 9.82243C13.3954 10.001 13.3206 10.1934 13.1518 10.355C12.983 10.5166 12.7353 10.6285 12.4482 10.6285C12.1612 10.6285 11.9135 10.5166 11.7446 10.355C11.5759 10.1934 11.501 10.001 11.501 9.82243C11.501 9.64385 11.5759 9.45144 11.7446 9.2899C11.9135 9.12826 12.1612 9.01637 12.4482 9.01637C12.7353 9.01637 12.983 9.12826 13.1518 9.2899C13.3206 9.45144 13.3954 9.64385 13.3954 9.82243Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.4492 15.8679C6.97234 15.8679 7.39642 15.3266 7.39642 14.6588C7.39642 13.991 6.97234 13.4497 6.4492 13.4497C5.92607 13.4497 5.50199 13.991 5.50199 14.6588C5.50199 15.3266 5.92607 15.8679 6.4492 15.8679ZM6.4492 15.4649C6.79796 15.4649 7.08068 15.104 7.08068 14.6588C7.08068 14.2136 6.79796 13.8527 6.4492 13.8527C6.10045 13.8527 5.81773 14.2136 5.81773 14.6588C5.81773 15.104 6.10045 15.4649 6.4492 15.4649Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6037 15.0618C27.6037 15.507 27.321 15.8679 26.9722 15.8679C26.6234 15.8679 26.3407 15.507 26.3407 15.0618C26.3407 14.6167 26.6234 14.2558 26.9722 14.2558C27.321 14.2558 27.6037 14.6167 27.6037 15.0618ZM27.2879 15.0618C27.2879 15.2844 27.1466 15.4649 26.9722 15.4649C26.7978 15.4649 26.6565 15.2844 26.6565 15.0618C26.6565 14.8392 26.7978 14.6588 26.9722 14.6588C27.1466 14.6588 27.2879 14.8392 27.2879 15.0618Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3502 17.48H35L28.0067 10.7156C28.1529 10.4464 28.2351 10.1432 28.2351 9.82243C28.2351 8.70949 27.2456 7.80728 26.0249 7.80728C25.7195 7.80728 25.4286 7.86376 25.164 7.96589L17.5 0.552734L11.1246 6.71949C10.6077 6.3894 9.97462 6.19516 9.2908 6.19516C7.54702 6.19516 6.13342 7.45826 6.13342 9.01637C6.13342 9.58222 6.31985 10.1092 6.64084 10.5508C6.71198 10.6487 6.78973 10.7424 6.87353 10.8314L0 17.48H4.1125C4.57333 17.9695 5.30703 18.2861 6.13342 18.2861C6.9598 18.2861 7.6935 17.9695 8.15433 17.48H13.1116C13.3305 18.8472 14.6464 19.8982 16.237 19.8982C17.8277 19.8982 19.1436 18.8472 19.3625 17.48H24.0682C24.3958 17.9619 25.008 18.2861 25.7092 18.2861C26.4104 18.2861 27.0226 17.9619 27.3502 17.48ZM27.7472 10.4859L27.5748 10.8035L34.0606 17.077H27.2061L27.1117 17.2159C26.8594 17.587 26.3452 17.883 25.7092 17.883C25.0732 17.883 24.559 17.587 24.3067 17.2159L24.2123 17.077H19.1047L19.0531 17.3993C18.9659 17.9442 18.6525 18.465 18.1494 18.858C17.6484 19.2493 16.9799 19.4952 16.237 19.4952C15.4942 19.4952 14.8256 19.2493 14.3247 18.858C13.8216 18.465 13.5082 17.9442 13.421 17.3993L13.3694 17.077H8.04015L7.95238 17.1702C7.56063 17.5863 6.90389 17.883 6.13342 17.883C5.36294 17.883 4.7062 17.5863 4.31446 17.1702L4.22669 17.077H0.939449L7.38105 10.8462L7.07549 10.5216C6.667 10.0877 6.44915 9.56 6.44915 9.01637C6.44915 8.40493 6.72611 7.81016 7.23926 7.35165C7.7514 6.89403 8.47575 6.59819 9.2908 6.59819C9.9289 6.59819 10.5131 6.77969 10.9833 7.07994L11.1589 7.19202L17.5 1.05841L25.1014 8.41108L25.2554 8.35167C25.4897 8.26122 25.7498 8.21031 26.0249 8.21031C26.577 8.21031 27.0637 8.41496 27.4042 8.72546C27.7454 9.03652 27.9194 9.4299 27.9194 9.82243C27.9194 10.0519 27.8612 10.2762 27.7472 10.4859Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8686 5.3891C17.2173 5.3891 17.5 5.02821 17.5 4.58304C17.5 4.13786 17.2173 3.77698 16.8686 3.77698C16.5198 3.77698 16.2371 4.13786 16.2371 4.58304C16.2371 5.02821 16.5198 5.3891 16.8686 5.3891ZM16.8686 4.98607C17.0429 4.98607 17.1843 4.80562 17.1843 4.58304C17.1843 4.36045 17.0429 4.18001 16.8686 4.18001C16.6942 4.18001 16.5528 4.36045 16.5528 4.58304C16.5528 4.80562 16.6942 4.98607 16.8686 4.98607Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3417 13.4497C21.5623 13.4497 22.5519 12.1866 22.5519 10.6285C22.5519 9.07038 21.5623 7.80728 20.3417 7.80728C19.1211 7.80728 18.1315 9.07038 18.1315 10.6285C18.1315 12.1866 19.1211 13.4497 20.3417 13.4497ZM20.3417 13.0467C21.388 13.0467 22.2361 11.964 22.2361 10.6285C22.2361 9.29297 21.388 8.21031 20.3417 8.21031C19.2954 8.21031 18.4473 9.29297 18.4473 10.6285C18.4473 11.964 19.2954 13.0467 20.3417 13.0467Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5528 18.2861C17.4247 18.2861 18.1315 17.3839 18.1315 16.2709C18.1315 15.158 17.4247 14.2558 16.5528 14.2558C15.6809 14.2558 14.9741 15.158 14.9741 16.2709C14.9741 17.3839 15.6809 18.2861 16.5528 18.2861ZM16.5528 17.883C17.2503 17.883 17.8158 17.1613 17.8158 16.2709C17.8158 15.3806 17.2503 14.6588 16.5528 14.6588C15.8553 14.6588 15.2899 15.3806 15.2899 16.2709C15.2899 17.1613 15.8553 17.883 16.5528 17.883Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7112 9.82243C13.7112 10.4902 13.1457 11.0315 12.4482 11.0315C11.7507 11.0315 11.1853 10.4902 11.1853 9.82243C11.1853 9.15467 11.7507 8.61334 12.4482 8.61334C13.1457 8.61334 13.7112 9.15467 13.7112 9.82243ZM13.3954 9.82243C13.3954 10.001 13.3206 10.1934 13.1518 10.355C12.983 10.5166 12.7353 10.6285 12.4482 10.6285C12.1612 10.6285 11.9135 10.5166 11.7446 10.355C11.5759 10.1934 11.501 10.001 11.501 9.82243C11.501 9.64385 11.5759 9.45144 11.7446 9.2899C11.9135 9.12826 12.1612 9.01637 12.4482 9.01637C12.7353 9.01637 12.983 9.12826 13.1518 9.2899C13.3206 9.45144 13.3954 9.64385 13.3954 9.82243Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4492 15.8679C6.97234 15.8679 7.39642 15.3266 7.39642 14.6588C7.39642 13.991 6.97234 13.4497 6.4492 13.4497C5.92607 13.4497 5.50199 13.991 5.50199 14.6588C5.50199 15.3266 5.92607 15.8679 6.4492 15.8679ZM6.4492 15.4649C6.79796 15.4649 7.08068 15.104 7.08068 14.6588C7.08068 14.2136 6.79796 13.8527 6.4492 13.8527C6.10045 13.8527 5.81773 14.2136 5.81773 14.6588C5.81773 15.104 6.10045 15.4649 6.4492 15.4649Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6037 15.0618C27.6037 15.507 27.321 15.8679 26.9722 15.8679C26.6234 15.8679 26.3407 15.507 26.3407 15.0618C26.3407 14.6167 26.6234 14.2558 26.9722 14.2558C27.321 14.2558 27.6037 14.6167 27.6037 15.0618ZM27.2879 15.0618C27.2879 15.2844 27.1466 15.4649 26.9722 15.4649C26.7978 15.4649 26.6565 15.2844 26.6565 15.0618C26.6565 14.8392 26.7978 14.6588 26.9722 14.6588C27.1466 14.6588 27.2879 14.8392 27.2879 15.0618Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3502 17.48H35L28.0067 10.7156C28.1529 10.4464 28.2351 10.1432 28.2351 9.82243C28.2351 8.70949 27.2456 7.80728 26.0249 7.80728C25.7195 7.80728 25.4286 7.86376 25.164 7.96589L17.5 0.552734L11.1246 6.71949C10.6077 6.3894 9.97462 6.19516 9.2908 6.19516C7.54702 6.19516 6.13342 7.45826 6.13342 9.01637C6.13342 9.58222 6.31985 10.1092 6.64084 10.5508C6.71198 10.6487 6.78973 10.7424 6.87353 10.8314L0 17.48H4.1125C4.57333 17.9695 5.30703 18.2861 6.13342 18.2861C6.9598 18.2861 7.6935 17.9695 8.15433 17.48H13.1116C13.3305 18.8472 14.6464 19.8982 16.237 19.8982C17.8277 19.8982 19.1436 18.8472 19.3625 17.48H24.0682C24.3958 17.9619 25.008 18.2861 25.7092 18.2861C26.4104 18.2861 27.0226 17.9619 27.3502 17.48ZM27.7472 10.4859L27.5748 10.8035L34.0606 17.077H27.2061L27.1117 17.2159C26.8594 17.587 26.3452 17.883 25.7092 17.883C25.0732 17.883 24.559 17.587 24.3067 17.2159L24.2123 17.077H19.1047L19.0531 17.3993C18.9659 17.9442 18.6525 18.465 18.1494 18.858C17.6484 19.2493 16.9799 19.4952 16.237 19.4952C15.4942 19.4952 14.8256 19.2493 14.3247 18.858C13.8216 18.465 13.5082 17.9442 13.421 17.3993L13.3694 17.077H8.04015L7.95238 17.1702C7.56063 17.5863 6.90389 17.883 6.13342 17.883C5.36294 17.883 4.7062 17.5863 4.31446 17.1702L4.22669 17.077H0.939449L7.38105 10.8462L7.07549 10.5216C6.667 10.0877 6.44915 9.56 6.44915 9.01637C6.44915 8.40493 6.72611 7.81016 7.23926 7.35165C7.7514 6.89403 8.47575 6.59819 9.2908 6.59819C9.9289 6.59819 10.5131 6.77969 10.9833 7.07994L11.1589 7.19202L17.5 1.05841L25.1014 8.41108L25.2554 8.35167C25.4897 8.26122 25.7498 8.21031 26.0249 8.21031C26.577 8.21031 27.0637 8.41496 27.4042 8.72546C27.7454 9.03652 27.9194 9.4299 27.9194 9.82243C27.9194 10.0519 27.8612 10.2762 27.7472 10.4859Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8686 5.3891C17.2173 5.3891 17.5 5.02821 17.5 4.58304C17.5 4.13786 17.2173 3.77698 16.8686 3.77698C16.5198 3.77698 16.2371 4.13786 16.2371 4.58304C16.2371 5.02821 16.5198 5.3891 16.8686 5.3891ZM16.8686 4.98607C17.0429 4.98607 17.1843 4.80562 17.1843 4.58304C17.1843 4.36045 17.0429 4.18001 16.8686 4.18001C16.6942 4.18001 16.5528 4.36045 16.5528 4.58304C16.5528 4.80562 16.6942 4.98607 16.8686 4.98607Z"
        stroke="#7C878E"
        strokeWidth="3"
        mask="url(#path-1-inside-1_1544_39474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3417 13.4497C21.5623 13.4497 22.5519 12.1866 22.5519 10.6285C22.5519 9.07038 21.5623 7.80728 20.3417 7.80728C19.1211 7.80728 18.1315 9.07038 18.1315 10.6285C18.1315 12.1866 19.1211 13.4497 20.3417 13.4497ZM20.3417 13.0467C21.388 13.0467 22.2361 11.964 22.2361 10.6285C22.2361 9.29297 21.388 8.21031 20.3417 8.21031C19.2954 8.21031 18.4473 9.29297 18.4473 10.6285C18.4473 11.964 19.2954 13.0467 20.3417 13.0467Z"
        stroke="#7C878E"
        strokeWidth="3"
        mask="url(#path-1-inside-1_1544_39474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5528 18.2861C17.4247 18.2861 18.1315 17.3839 18.1315 16.2709C18.1315 15.158 17.4247 14.2558 16.5528 14.2558C15.6809 14.2558 14.9741 15.158 14.9741 16.2709C14.9741 17.3839 15.6809 18.2861 16.5528 18.2861ZM16.5528 17.883C17.2503 17.883 17.8158 17.1613 17.8158 16.2709C17.8158 15.3806 17.2503 14.6588 16.5528 14.6588C15.8553 14.6588 15.2899 15.3806 15.2899 16.2709C15.2899 17.1613 15.8553 17.883 16.5528 17.883Z"
        stroke="#7C878E"
        strokeWidth="3"
        mask="url(#path-1-inside-1_1544_39474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7112 9.82243C13.7112 10.4902 13.1457 11.0315 12.4482 11.0315C11.7507 11.0315 11.1853 10.4902 11.1853 9.82243C11.1853 9.15467 11.7507 8.61334 12.4482 8.61334C13.1457 8.61334 13.7112 9.15467 13.7112 9.82243ZM13.3954 9.82243C13.3954 10.001 13.3206 10.1934 13.1518 10.355C12.983 10.5166 12.7353 10.6285 12.4482 10.6285C12.1612 10.6285 11.9135 10.5166 11.7446 10.355C11.5759 10.1934 11.501 10.001 11.501 9.82243C11.501 9.64385 11.5759 9.45144 11.7446 9.2899C11.9135 9.12826 12.1612 9.01637 12.4482 9.01637C12.7353 9.01637 12.983 9.12826 13.1518 9.2899C13.3206 9.45144 13.3954 9.64385 13.3954 9.82243Z"
        stroke="#7C878E"
        strokeWidth="3"
        mask="url(#path-1-inside-1_1544_39474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4492 15.8679C6.97234 15.8679 7.39642 15.3266 7.39642 14.6588C7.39642 13.991 6.97234 13.4497 6.4492 13.4497C5.92607 13.4497 5.50199 13.991 5.50199 14.6588C5.50199 15.3266 5.92607 15.8679 6.4492 15.8679ZM6.4492 15.4649C6.79796 15.4649 7.08068 15.104 7.08068 14.6588C7.08068 14.2136 6.79796 13.8527 6.4492 13.8527C6.10045 13.8527 5.81773 14.2136 5.81773 14.6588C5.81773 15.104 6.10045 15.4649 6.4492 15.4649Z"
        stroke="#7C878E"
        strokeWidth="3"
        mask="url(#path-1-inside-1_1544_39474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6037 15.0618C27.6037 15.507 27.321 15.8679 26.9722 15.8679C26.6234 15.8679 26.3407 15.507 26.3407 15.0618C26.3407 14.6167 26.6234 14.2558 26.9722 14.2558C27.321 14.2558 27.6037 14.6167 27.6037 15.0618ZM27.2879 15.0618C27.2879 15.2844 27.1466 15.4649 26.9722 15.4649C26.7978 15.4649 26.6565 15.2844 26.6565 15.0618C26.6565 14.8392 26.7978 14.6588 26.9722 14.6588C27.1466 14.6588 27.2879 14.8392 27.2879 15.0618Z"
        stroke="#7C878E"
        strokeWidth="3"
        mask="url(#path-1-inside-1_1544_39474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3502 17.48H35L28.0067 10.7156C28.1529 10.4464 28.2351 10.1432 28.2351 9.82243C28.2351 8.70949 27.2456 7.80728 26.0249 7.80728C25.7195 7.80728 25.4286 7.86376 25.164 7.96589L17.5 0.552734L11.1246 6.71949C10.6077 6.3894 9.97462 6.19516 9.2908 6.19516C7.54702 6.19516 6.13342 7.45826 6.13342 9.01637C6.13342 9.58222 6.31985 10.1092 6.64084 10.5508C6.71198 10.6487 6.78973 10.7424 6.87353 10.8314L0 17.48H4.1125C4.57333 17.9695 5.30703 18.2861 6.13342 18.2861C6.9598 18.2861 7.6935 17.9695 8.15433 17.48H13.1116C13.3305 18.8472 14.6464 19.8982 16.237 19.8982C17.8277 19.8982 19.1436 18.8472 19.3625 17.48H24.0682C24.3958 17.9619 25.008 18.2861 25.7092 18.2861C26.4104 18.2861 27.0226 17.9619 27.3502 17.48ZM27.7472 10.4859L27.5748 10.8035L34.0606 17.077H27.2061L27.1117 17.2159C26.8594 17.587 26.3452 17.883 25.7092 17.883C25.0732 17.883 24.559 17.587 24.3067 17.2159L24.2123 17.077H19.1047L19.0531 17.3993C18.9659 17.9442 18.6525 18.465 18.1494 18.858C17.6484 19.2493 16.9799 19.4952 16.237 19.4952C15.4942 19.4952 14.8256 19.2493 14.3247 18.858C13.8216 18.465 13.5082 17.9442 13.421 17.3993L13.3694 17.077H8.04015L7.95238 17.1702C7.56063 17.5863 6.90389 17.883 6.13342 17.883C5.36294 17.883 4.7062 17.5863 4.31446 17.1702L4.22669 17.077H0.939449L7.38105 10.8462L7.07549 10.5216C6.667 10.0877 6.44915 9.56 6.44915 9.01637C6.44915 8.40493 6.72611 7.81016 7.23926 7.35165C7.7514 6.89403 8.47575 6.59819 9.2908 6.59819C9.9289 6.59819 10.5131 6.77969 10.9833 7.07994L11.1589 7.19202L17.5 1.05841L25.1014 8.41108L25.2554 8.35167C25.4897 8.26122 25.7498 8.21031 26.0249 8.21031C26.577 8.21031 27.0637 8.41496 27.4042 8.72546C27.7454 9.03652 27.9194 9.4299 27.9194 9.82243C27.9194 10.0519 27.8612 10.2762 27.7472 10.4859Z"
        stroke="#7C878E"
        strokeWidth="3"
        mask="url(#path-1-inside-1_1544_39474)"
      />
    </svg>
  );
}
