import YatungButton from '@Src/_basic/components/YatungButton';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungInput from '@Src/_basic/components/YatungInput';
import { Grid, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  onInput: (value: string) => void;
  inuptValue: string;
  onDeleteInput: () => void;
}
export default function CreateMixerInput(props: Props) {
  const { t: i18T } = useTranslation();
  const { index, onInput, inuptValue, onDeleteInput } = props;

  const handleInput = (e: SelectChangeEvent) => {
    onInput?.(e.target.value);
  };
  return (
    <>
      <Grid container item xs={12} sx={{ my: 3 }}>
        <Grid container item xs={4}>
          <YatungFormLabel label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.MIXER') + `(${index + 1})：`} />
        </Grid>
        <Grid container item xs={5}>
          <YatungInput onBlur={handleInput} type="text" sx={{ width: '260px' }} defaultValue={inuptValue} />
        </Grid>
        <Grid container item xs={2} sx={{ ml: 1 }}>
          <YatungButton text={i18T('GLOBAL.DELETE')} color="red" onClick={onDeleteInput} />
        </Grid>
      </Grid>
    </>
  );
}
