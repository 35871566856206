import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import { Box, Stack, Typography, styled } from '@mui/material';
import React, { useRef } from 'react';

const Button = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#003087',
  userSelect: 'none',
  gap: '8px',
});

const ButtonLabel = styled(Typography)({
  color: '#697EA3',
  fontFamily: 'Microsoft JhengHei',
  fontWeight: 400,
  size: '20px',
});

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: '#9A99A1',
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '0.255em',
}));

interface Props {
  handleChange: (event: any) => void;
  list: string[];
}

export default function UploadFileCard(props: Props) {
  const { handleChange, list } = props;
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFileButtonClick = () => {
    hiddenFileInputRef?.current?.click();
  };

  return (
    <Button onClick={handleUploadFileButtonClick}>
      <Stack
        justifyContent="center"
        alignItems="center"
        gap="16px"
        sx={{
          top: '0px',
          left: '0px',
          height: '100%',
          width: '100%',
          border: '1px dotted gray',
        }}
      >
        <Stack display={'flex'} justifyContent={'center'} flexDirection={'row'}>
          <AddCircleFill />
          <ButtonLabel>上傳檔案</ButtonLabel>
        </Stack>

        <div>{list}</div>
        {/* 讓使用者預覽點擊uploadFileCard後已選擇檔案，可多選，暫未做出刪除功能*/}
        <Label>點擊檔案進行上傳</Label>
      </Stack>
      <input ref={hiddenFileInputRef} type="file" onChange={handleChange} style={{ display: 'none' }} />
    </Button>
  );
}
