import { MonthlyReportFineAggAnalysis, MonthlyReportNameEnum } from '@Src/_basic/object/MonthlyReportType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonthlyReportSelect from '../components/MonthlyReportSelect';

const FineAggregateAnalysis = () => {
  const { t: i18T } = useTranslation();
  const [tableData, setTableData] = useState<Array<MonthlyReportFineAggAnalysis>>([]);

  const selectHandler = useCallback(
    (
      factoryId: number,
      exptUnitId: number,
      startTime: number,
      endTime: number,
      specificationId?: number,
      sourceId?: number,
      supplierId?: number,
      formId?: number,
    ) => {
      setTableData([]);
      if (!factoryId || !exptUnitId || !startTime || !endTime || !formId) return;
      console.log({
        factoryId,
        exptUnitId,
        startTime,
        endTime,
        formId,
        specificationId,
        ...(supplierId && { supplierId }),
        ...(sourceId && { sourceId }),
      });
      if (formId === 2) {
        MonthlyReportApi.getFineAggAnalysis(
          {
            factoryId,
            exptUnitId,
            startTime,
            endTime,
            formId,
          },
          (_data) => setTableData(_data),
        );
      } else {
        if (!specificationId) return;
        MonthlyReportApi.getFineAggAnalysis(
          {
            factoryId,
            exptUnitId,
            startTime,
            endTime,
            formId,
            specificationId,
            ...(supplierId && { supplierId }),
            ...(sourceId && { sourceId }),
          },
          (_data) => setTableData(_data),
          (_err) => console.log(_err),
        );
      }
    },
    [],
  );

  return (
    <MonthlyReportSelect
      title={i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_ANALYSIS.TITLE')}
      exptType={MonthlyReportNameEnum.FINE_AGGREGATE_ANALYSIS}
      selectHandler={selectHandler}
      tableData={tableData}
    />
  );
};

export default memo(FineAggregateAnalysis);
