import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Switch(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="19" height="20" viewBox="0 0 19 20" fill="none">
      <path
        d="M9.4043 0C9.07289 0 8.8043 0.266462 8.8043 0.595238V10.5952C8.8043 10.924 9.07289 11.1905 9.4043 11.1905C9.7357 11.1905 10.0043 10.924 10.0043 10.5952V0.595238C10.0043 0.266462 9.7357 0 9.4043 0Z"
        fill="white"
      />
      <path
        d="M3.79793 4.08668C4.18559 3.77976 4.7243 4.07413 4.7243 4.56566C4.7243 4.76377 4.62926 4.94931 4.47457 5.07441C2.72273 6.49368 1.6043 8.65281 1.6043 11.0714C1.6043 15.3451 5.09648 18.8095 9.4043 18.8095C13.7121 18.8095 17.2043 15.3451 17.2043 11.0714C17.2043 8.75651 16.1797 6.67922 14.5561 5.26135C14.4118 5.13532 14.3243 4.95582 14.3243 4.76516C14.3243 4.26153 14.8826 3.96903 15.2675 4.29734C17.188 5.93471 18.4043 8.36217 18.4043 11.0714C18.4043 16.0026 14.3748 20 9.4043 20C4.43379 20 0.404297 16.0026 0.404297 11.0714C0.404297 8.24312 1.72969 5.72219 3.79793 4.08668Z"
        fill="white"
      />
    </SvgIcon>
  );
}
