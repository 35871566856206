import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { CalcMoistureContent } from '@Src/_basic/helpers/CalcTestForm';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  ImportMoistureContent,
  MoistureContent,
  PutMoistureContentRequest,
} from '@Src/_basic/object/test/sandRock/MoistureContentType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: MoistureContent;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  wetWeight: basicNumberSchema,
  dryWeight: basicNumberSchema,
});

function ExportChlorideTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  const calcTestForm = useMemo(() => new CalcMoistureContent(testDetail), [testDetail]);

  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = {
    wetWeight: testDetail?.wetWeight,
    dryWeight: testDetail?.dryWeight,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportMoistureContent = {
        wetWeight: checkIsNaN(+values.wetWeight),
        dryWeight: checkIsNaN(+values.dryWeight),
      };

      TestApi.putTest<PutMoistureContentRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.MOISTURE_CONTENT.WET_WEIGHT`)}(g)`,
      values: [
        `${i18T(`TEST.MOISTURE_CONTENT.DRY_WEIGHT`)}(g)`,
        `${i18T(`TEST.MOISTURE_CONTENT.MOISTURE_CONTENT`)}(%)`,
      ],
    }),
    [i18T],
  );

  const firstData = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.wetWeight?.toFixed(1)),
        values: [
          formatNumber(testDetail?.dryWeight?.toFixed(1)),
          formatNumber(calcTestForm?.moistureContent?.toFixed(1)),
        ],
      },
    ],
    [testDetail, calcTestForm],
  );

  const editView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack flexDirection="row" gap={10}>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.MOISTURE_CONTENT.WET_WEIGHT`)}(g)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.wetWeight}
                isError={!!(formik.touched.wetWeight && formik.errors.wetWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('wetWeight', e.target.value)}
              />
            </ColFieldInfo>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.MOISTURE_CONTENT.DRY_WEIGHT`)}(g)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.dryWeight}
                isError={!!(formik.touched.dryWeight && formik.errors.dryWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('dryWeight', e.target.value)}
              />
            </ColFieldInfo>
          </Stack>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstData} />;
      default:
        break;
    }
  }, [edit, formik, firstHeader, firstData, i18T]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {editView}
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportChlorideTable);
