import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import BackButton from '@Src/_basic/icons/BackButton';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { TestInDistrictDetial, TestInDistrictDetialByData } from '@Src/_basic/object/TestInDistrictType';
import { ExptTestInDistrictApi } from '@Src/_basic/protocol/exptTestInDistrict/ExptTestInDistrictApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useTestDistrict } from '@Src/redux/testInDistrict/testInDistrictActions';
import { Grid, Pagination, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import TestInDistrictByDetailTable from '../components/TestInDistrictByDetailTable';

export default function TestInDistrictByDetailSreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { date } = useTestDistrict();

  const {
    state: { factoryName },
  } = useLocation();
  const lastFactoryName = factoryName;
  const { actionLoading } = useApi();

  const [testInDistrictDetailData, setTestInDistrictDetailData] = useState<Array<TestInDistrictDetialByData>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalCount: 0,
  });
  const [sortState, setSortState] = useState<boolean>(false);

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleSortClick = (page: number) => {
    if (sortState) {
      const { pageSize } = pageInfo;
      ExptTestInDistrictApi.getPaginationReqTestInDistrictFactory(
        { time: date, factoryId: Number(id), page: page, size: pageSize, sort: 'completed,asc' },
        (data: TestInDistrictDetial) => {
          setTestInDistrictDetailData(data.data);
          setPageInfo({
            ...pageInfo,
            page,
            total: data.maxPage,
          });
        },
        undefined,
        undefined,
      );
      setSortState(false);
    } else {
      const { pageSize } = pageInfo;
      ExptTestInDistrictApi.getPaginationReqTestInDistrictFactory(
        { time: date, factoryId: Number(id), page: page, size: pageSize, sort: 'completed,desc' },
        (data: TestInDistrictDetial) => {
          setTestInDistrictDetailData(data.data);
          setPageInfo({
            ...pageInfo,
            page,
            total: data.maxPage,
          });
        },
        undefined,
        undefined,
      );

      setSortState(true);
    }
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };
  useEffect(() => {
    const { pageSize } = pageInfo;

    if (!sortState) {
      ExptTestInDistrictApi.getPaginationReqTestInDistrictFactory(
        { time: date, factoryId: Number(id), page: pageInfo.page, size: pageSize, sort: 'completed,asc' },
        (data: TestInDistrictDetial) => {
          setTestInDistrictDetailData(data.data);
          setPageInfo({
            ...pageInfo,
            page: pageInfo.page,
            total: data.maxPage,
          });
        },
        undefined,
        undefined,
      );
      setSortState(false);
    } else {
      ExptTestInDistrictApi.getPaginationReqTestInDistrictFactory(
        { time: date, factoryId: Number(id), page: pageInfo.page, size: pageSize, sort: 'completed,desc' },
        (data: TestInDistrictDetial) => {
          setTestInDistrictDetailData(data.data);
          setPageInfo({
            ...pageInfo,
            page: pageInfo.page,
            total: data.maxPage,
          });
        },
        undefined,
        undefined,
      );
      setSortState(true);
    }
  }, [date, id, pageInfo.page]);
  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.TITLE') + `(${factoryName})`}
      body={
        <>
          <Stack direction="row" spacing={2} sx={{ py: 1 }} justifyContent="flex-end">
            <YatungButton
              disabled={actionLoading}
              text={i18T('GLOBAL.BACK_PAGE')}
              color="green"
              onClick={handleGoBack}
              startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
              sx={{ alignSelf: 'center' }}
            />
          </Stack>

          <TestInDistrictByDetailTable
            loading={actionLoading}
            testInDistrictDetailData={testInDistrictDetailData}
            lastFactoryName={lastFactoryName}
            onSortClick={(page: number) => handleSortClick(page)}
            sortState={sortState}
            pageInfo={pageInfo}
          />
          <Grid container justifyContent={'center'} item xs={12} sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid>
        </>
      }
      contentBgColor="#fff"
    />
  );
}
