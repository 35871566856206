import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function InfoTitle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.3444 7.01111L14.9778 1.64444C14.5667 1.23333 14 1 13.4111 1H3.22222C2 1 1 2 1 3.22222V18.7778C1 20 2 21 3.22222 21H18.7778C20 21 21 20 21 18.7778V8.58889C21 8 20.7667 7.43333 20.3444 7.01111ZM5.44444 5.44444H13.2222V7.66667H5.44444V5.44444ZM16.5556 16.5556H5.44444V14.3333H16.5556V16.5556ZM16.5556 12.1111H5.44444V9.88889H16.5556V12.1111Z"
        fill='#003087'
        stroke='#cccccc'
      />
    </SvgIcon>
  );
}
