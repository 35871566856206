import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@Redux/auth/authActions';

export default function LogoutScreen() {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      logout();
    }
    navigate('/');
  }, []);

  return (
    <div>
      <span>logout......</span>
    </div>
  );
}
