import { Protocol } from '../../Protocol';

export class LegalStandardV2Protocol {
  public static readonly LEGAL_STANDARD_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/standardV2/legal`;
  public static readonly LEGAL_STANDARDS_BY_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/standardV2/legal/search`;
  public static readonly PAGEABLE_LEGAL_STANDARDS_BY_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/standardV2/legal/search/pageable`;
  public static readonly TOGGLE_LEGAL_STANDARD_ENABLE_STATUS: string = `${Protocol.YATUNG_API_ROOT}/standardV2/legal/enableStatus`;
  public static readonly CREATE_LEGAL_STANDARD: string = `${Protocol.YATUNG_API_ROOT}/standardV2/legal`;
  public static readonly GET_ACTIVATED_LEGAL_STANDARD_IDS: string = `${Protocol.YATUNG_API_ROOT}/standardV2/legal/activated/ids`;
}
