import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Create(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M13.5,3.7c0-1-0.8-1.9-1.9-1.9S9.7,2.7,9.7,3.7c0,1,0.8,1.9,1.9,1.9S13.5,4.7,13.5,3.7z M12.7,3.7c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1C12.2,4.7,12.7,4.3,12.7,3.7z" />
      <path d="M11,19.6H4.1V4.7h2.1v1.1c0,1.2,1,2.1,2.1,2.1h6.4c1.2,0,2.1-1,2.1-2.1V4.7h2.1v7.8h2.1V4.7c0-1.2-1-2.1-2.1-2.1h-4.5c-0.4-1.2-1.6-2.1-3-2.1c-1.4,0-2.6,0.9-3,2.1H4.1C3,2.6,2,3.6,2,4.7v14.9c0,1.2,1,2.1,2.1,2.1H11V19.6zM3.3,3.9v16.5h6.9v0.5H4.1c-0.7,0-1.3-0.6-1.3-1.3V4.7c0-0.7,0.6-1.3,1.3-1.3h5l0.2-0.5c0.3-0.9,1.2-1.6,2.3-1.6c1,0,1.9,0.7,2.3,1.6l0.2,0.5h5c0.7,0,1.3,0.6,1.3,1.3V12h-0.5V3.9h-3.7v1.9c0,0.7-0.6,1.3-1.3,1.3H8.4c-0.7,0-1.3-0.6-1.3-1.3V3.9H3.3z" />
      <path d="M17,22.5c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S14.8,22.5,17,22.5z M16.9,15c0.2,0,0.3,0.1,0.3,0.3v2.7H20c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3h-2.7v2.8c0,0.2-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3v-2.8h-2.7c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3h2.7v-2.7C16.6,15.1,16.7,15,16.9,15z" />
    </SvgIcon>
  );
}
