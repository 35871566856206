import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SiteManagementData, SiteManagementDataRequire } from '@Src/_basic/object/SiteType';

interface initialState {
  targetId: number;
  siteData: SiteManagementData | any;
  siteUpdateData: any;
}

export const siteSlice = createSlice({
  name: 'siteSlice',
  initialState: {
    targetId: 0,
    siteData: [],
    siteUpdateData: {
      areaName: '',
      branchCode: '',
      factoryAddress: '',
      factoryCode: '',
      factoryId: 0,
      factoryName: '',
      factoryPhone: '',
      id: 0,
      removed: false,
      stations: []
    },
  } as initialState,
  reducers: {
    setTargetId: (state, action: PayloadAction<number>) => {
      state.targetId = action.payload;
    },
    setSiteData: (state, action: PayloadAction<SiteManagementData>) => {
      state.siteData = action.payload;
    },
    setSiteUpdateData: (state, action: PayloadAction<any>) => {
      state.siteUpdateData = action.payload;
    },
  },
});
