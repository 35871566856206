import MockAdapter from 'axios-mock-adapter/types';
import { testI18nProtocol } from '../testI18nProtocol';
import i18nTableMock from './i18nTableMock.json';

export default function i18nMockDetails(mock: MockAdapter) {
  mock.onGet(testI18nProtocol.I18n).reply(() => {
    return [
      200,
      {
        code: '00000',
        data: i18nTableMock,
        message: '成功',
      },
    ];
  });
}
