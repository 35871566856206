import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Specification(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="32" viewBox="0 0 36 32" fill="none">
      <rect x="1" y="1" width="12.9787" height="12.9787" rx="1" stroke="white" strokeWidth="2" />
      <rect x="18.3846" y="2.72363" width="17.0213" height="1.3617" rx="0.680851" fill="white" />
      <rect x="18.3846" y="11.5747" width="17.0213" height="1.3617" rx="0.680851" fill="white" />
      <rect x="1" y="18.0195" width="12.9787" height="12.9787" rx="1" stroke="white" strokeWidth="2" />
      <rect x="18.3846" y="19.7422" width="17.0213" height="1.3617" rx="0.680851" fill="white" />
      <rect x="18.3846" y="28.5933" width="17.0213" height="1.3617" rx="0.680851" fill="white" />
    </SvgIcon>
  );
}
