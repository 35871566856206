import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function Success(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 45 45" {...props}>
      <g clipPath="url(#clip0_671_57430)">
        <circle cx="22.5" cy="22.5" r="22.5" fill="#00D03A" />
        <path
          d="M9 23.6572L13.8347 34.229H19.7438L35 13.301H29.9504L18.0248 29.051H15.9835L13.2975 23.6572H9Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_671_57430">
          <rect width={45} height={45} fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
