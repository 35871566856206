import YatungButton from '@Src/_basic/components/YatungButton';
import DetailIcon from '@Src/_basic/icons/Detail';
import { TestInDistrictDetialByData } from '@Src/_basic/object/TestInDistrictType';
import { Stack } from '@mui/material';
import React from 'react';

export type ExtraData = {
  onViewDetail: (row: TestInDistrictDetialByData) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: TestInDistrictDetialByData;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <Stack direction="row" spacing={1}>
      <YatungButton
        text={formatExtraData.getButtonText('詳細')}
        startIcon={<DetailIcon />}
        color="yellow"
        onClick={() => {
          formatExtraData.onViewDetail(row);
        }}
      />
    </Stack>
  );
}
