import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import { Options } from '@Src/_basic/components/YatungSelect';
import YatungWindow from '@Src/_basic/components/YatungWindow';
import DefineName from '@Src/_basic/icons/DefineName';
import MixerBinding from '@Src/_basic/icons/MixerBinding';
import TroughManagementButton from '@Src/_basic/icons/TroughManagementButton';
import {
  CreateMixerData,
  CreateStorageIncludeMixerPropCode,
  MixerData,
  MixerDetailData,
} from '@Src/_basic/object/MixerTypes';
import CreateMixerManagement from './CreateMixerManagement';
import UpeateMixerDetailsMixerBindSelect from './UpeateMixerDetailsMixerBindSelect';

interface Props {
  value: MixerDetailData;
  proportionOptions: Options[];
  index: number;
  updateMixerData: MixerData;
  createMixer: CreateMixerData[];
  setUpdateMixerData: (e: MixerData) => void;
  setCreateMixer: (e: CreateMixerData[]) => void;
}
export default function UpdateMixerDetailsSettings(props: Props) {
  const { t: i18T } = useTranslation();
  const { value, updateMixerData, createMixer, setUpdateMixerData, setCreateMixer, proportionOptions } = props;

  const titleStyle = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 900,
    color: '#FFFFFF',
    my: 1,
    ml: 1,
  };
  const bodyStyle = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#697EA3',
    my: 1,
  };
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleMixerManagementButtonClick = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleSelectChange = (key: number, _value: string) => {
    const updatedArray = value.storages?.map((_data: any) => {
      if (_data.id === key) {
        return { ..._data, mixerPropCode: _value };
      }
      return _data;
    });

    const updatedArrayTemp = { ...value, storages: updatedArray };
    const updateArrayNew = updateMixerData.mixers.map((_data: MixerDetailData) =>
      _data.id === updatedArrayTemp.id ? updatedArrayTemp : _data,
    );

    const createArrayNew = createMixer.map((_data: CreateMixerData) =>
      _data.id === updatedArrayTemp.id ? updatedArrayTemp : _data,
    );

    const updateMixerDataTemp = { ...updateMixerData, mixers: updateArrayNew };

    setUpdateMixerData(updateMixerDataTemp);
    setCreateMixer(createArrayNew);
  };
  const [dymanicOptions, setDymanicOptions] = useState<Array<Options>>([]);

  const filterOptions = () => {
    const mixerBindValue = value.storages?.map((value: any) => {
      return value.mixerPropCode;
    });

    const mixerBindValueFilter = proportionOptions?.filter((value: Options) => {
      return !mixerBindValue.includes(value.value);
    });

    setDymanicOptions(mixerBindValueFilter);
  };
  useEffect(() => {
    filterOptions();
  }, [value]);
  return (
    <>
      <CreateMixerManagement
        modalOpen={modalOpen}
        onCloseModal={handleCloseModal}
        value={value}
        updateMixerData={updateMixerData}
        createMixer={createMixer}
        setUpdateMixerData={setUpdateMixerData}
        setCreateMixer={setCreateMixer}
      />
      <Card sx={{ width: '920px', height: '430px' }}>
        <CardHeader
          sx={{ color: '#ffffff', background: '#003087' }}
          title={
            <>
              <Stack justifyContent={'flex-end'} flexDirection="row">
                <YatungButton
                  text={i18T('USERSMANAGEMENU.MIXER_INFORMATION.TROUGH_MANAGEMENT')}
                  startIcon={<TroughManagementButton />}
                  onClick={handleMixerManagementButtonClick}
                  color="gray"
                />
              </Stack>
              <Grid container item xs={12} justifyContent={'center'} sx={{ mt: 1 }}>
                <Grid container item xs={6} alignItems={'center'}>
                  <DefineName />
                  <Typography sx={titleStyle}>{i18T('USERSMANAGEMENU.MIXER_INFORMATION.DEFINE')}</Typography>
                </Grid>
                <Grid container item xs={6} alignItems={'center'}>
                  <MixerBinding />
                  <Typography sx={titleStyle}>{i18T('USERSMANAGEMENU.MIXER_INFORMATION.MIXER_BIND')}</Typography>
                </Grid>
              </Grid>
            </>
          }
        ></CardHeader>
        <CardContent sx={{ height: '100%' }}>
          <Box sx={{ width: '100%', height: `calc(100% - 32px - 72px)` }}>
            <YatungWindow
              className="scroll-bar"
              data={value.storages}
              renderItem={(data: CreateStorageIncludeMixerPropCode) => {
                return (
                  <Grid container item xs={12} justifyContent={'center'} sx={{ my: 2 }}>
                    <Grid container item xs={6}>
                      <Typography sx={bodyStyle}>{data.name}</Typography>
                    </Grid>
                    <Grid container item xs={6}>
                      <UpeateMixerDetailsMixerBindSelect
                        proportionOptions={proportionOptions}
                        dymanicOptions={dymanicOptions}
                        selectedValue={data.mixerPropCode ? data.mixerPropCode : 0}
                        onSelected={(_value: string) => {
                          handleSelectChange(data.id, _value);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
