import React, { useMemo, CSSProperties } from 'react';
import ExptScheduleCard from '../../ExptScheduleCard';
import { Box, Grid, Stack, TextField, Typography, styled } from '@mui/material';
import MessageBubble from '../../../icons/MessageBubble';

interface Props {
  formik: any;
  invisible?: boolean;
}

const Title = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '24px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '0.28em',
}));

export default function MemoCard({ formik, invisible }: Props) {
  const header = useMemo(() => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ height: '100%', px: '24px' }}
      >
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }} gap="12px">
            <MessageBubble />
            <Title>備註：</Title>
          </Box>
        </Grid>
      </Grid>
    );
  }, []);

  return (
    <ExptScheduleCard
      header={header}
      body={
        <Box sx={{ padding: '24px 24px' }}>
          <TextField
            multiline={true}
            rows={13}
            fullWidth
            defaultValue={formik.values.memo}
            onBlur={(e) => {
              formik.setFieldValue('memo', e.target.value);
            }}
          />
        </Box>
      }
      invisible={invisible}
    />
  );
}
