import { Box, Stack } from '@mui/material';
import React, { useCallback } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';

import CloseIcon from '@Src/_basic/icons/Close';
import ViewIcon from '@Src/_basic/icons/View';
import { ExptScheduleData } from '@Src/_basic/object/ExptScheduleType';
import { useExptSchedule } from '@Src/redux/exptSchedule/exptScheduleActions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  rows: Array<ExptScheduleData>;
  loading: boolean;
  searchFn: () => void;
}

export default function ExptScheduleTable({ rows, loading, searchFn }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { deleteExptSchedule } = useExptSchedule();

  const handleEditButtonClick = useCallback(
    (row: ExptScheduleData) => {
      navigate(`/qualityManagement/exptSchedule/update/${row.id}`);
    },
    [navigate],
  );

  const handleDeleteButtonClick = useCallback(
    (row: ExptScheduleData) => {
      deleteExptSchedule(row.id, searchFn);
    },
    [deleteExptSchedule, searchFn],
  );

  return (
    <YatungTable
      loading={loading}
      data={rows}
      columns={[
        {
          text: 'No.',
          dataField: 'id',
          width: '20%',
        },
        {
          text: '需求單排程名稱',
          dataField: 'title',
          width: '20%',
        },
        {
          text: '排程設定',
          dataField: 'schedulePeriods',
          width: '20%',
          formatter: ({ row }) => {
            return (
              <>
                {row.schedulePeriods.map((schedulePeriod: string, index: number) => {
                  return <Box key={index}>{schedulePeriod}</Box>;
                })}
              </>
            );
          },
        },
        {
          text: '廠別',
          dataField: 'factoryName',
          width: '20%',
        },
        {
          text: i18T('GLOBAL.ACTION'),
          dataField: 'action',
          width: '20%',
          formatter: ({ row }) => {
            return (
              <Stack direction="row" spacing={1}>
                <YatungButton
                  text={'編輯'}
                  startIcon={<ViewIcon />}
                  color="yellow"
                  onClick={() => {
                    handleEditButtonClick(row);
                  }}
                />
                <YatungButton
                  text={'刪除'}
                  startIcon={<CloseIcon />}
                  color={'red'}
                  onClick={() => {
                    handleDeleteButtonClick(row);
                  }}
                />
              </Stack>
            );
          },
        },
      ]}
    />
  );
}
