import YatungPage from '@Src/_basic/components/YatungPage';
import { ExptScheduleData, ScheduleItemData, UpdateExptScheduleFormType } from '@Src/_basic/object/ExptScheduleType';
import { useExptSchedule } from '@Src/redux/exptSchedule/exptScheduleActions';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import UpdateExptScheduleForm from '../components/UpdateExptScheduleForm';

export default function UpdateExptScheduleScreen() {
  const { t: i18T } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { exptSchedule, getExptScheduleById, updateExptSchedule } = useExptSchedule();

  const goToList = useCallback(() => {
    navigate(`/qualityManagement/exptSchedule/list`);
  }, [navigate]);

  const exptScheduleDataToFormData = useCallback(
    (exptScheduleData: ExptScheduleData) => {
      return {
        ...exptScheduleData,
        isRequirement: exptScheduleData?.isRequirement?.toString(),
        period: {
          periodType: exptScheduleData.period.periodType,
          periodWeekDays:
            exptScheduleData.period.periodWeekDays ||
            [...Array(exptScheduleData.period.periodDates?.length)]
              .map((_, index) => ({
                weekDay: (index % 7) + 1,
                length: 1,
              }))
              .slice(0, 7),
          periodDates:
            exptScheduleData.period.periodDates ||
            [...Array(exptScheduleData.period.periodWeekDays?.length)].map(() => ({
              startMonth: 0,
              startDay: 1,
              endMonth: 11,
              endDay: 31,
            })),
        },
        exptItems: exptScheduleData.scheduleItems.map((item: ScheduleItemData) => ({
          exptItemId: item.exptItemId,
          groupId: item.groupId,
          operator: item.operator,
        })),
      };
    },
    [exptSchedule],
  );

  const formDataToUpdateExptScheduleRequest = useCallback((formData: UpdateExptScheduleFormType) => {
    return {
      ...formData,
      isRequirement: formData.isRequirement === 'true',
    };
  }, []);

  const handleSubmit = useCallback(
    (values: UpdateExptScheduleFormType) => {
      updateExptSchedule(formDataToUpdateExptScheduleRequest(values), goToList);
    },
    [formDataToUpdateExptScheduleRequest],
  );

  useEffect(() => {
    getExptScheduleById(Number(params.id));
  }, [params.id]);

  return (
    <YatungPage
      title={i18T(`QUALITY_MANAGE.SIDE_MENU.EXPT_MANAGEMENT.EXPT_SCHEDULE.LIST`)}
      body={<UpdateExptScheduleForm onSubmit={handleSubmit} initialValues={exptScheduleDataToFormData(exptSchedule)} />}
    />
  );
}
