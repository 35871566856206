import { Response } from '@Src/_basic/object/ApiType';
import { ExperimentItemData, SearchExperimentItemsParams } from '@Src/_basic/object/ExperimentItemType';
import { Api } from '../Api';
import { ExperimentItemProtocol } from './ExperimentItemProtocol';

export class ExperimentItemApi {
  public static getAllExperimentItems(
    params: SearchExperimentItemsParams,
    onSuccess?: (data: Array<ExperimentItemData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(ExperimentItemProtocol.ALL_EXPERIMENT_ITEMS, config, onSuccess, onFailed, onFinally);
  }
}
