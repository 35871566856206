import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LogoutButton(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="33" height="32" viewBox="0 0 33 32" fill="none">
      {/* <circle cx="16.9305" cy="16.0701" r="16.0701" fill="white"></circle> */}
      <circle cx="16.9315" cy="16.0701" r="15.5701" stroke="#CB333B"></circle>
      <path
        d="M16.9319 9C16.7003 9 16.5126 9.18618 16.5126 9.41589V16.4029C16.5126 16.6326 16.7003 16.8188 16.9319 16.8188C17.1634 16.8188 17.3511 16.6326 17.3511 16.4029V9.41589C17.3511 9.18618 17.1634 9 16.9319 9Z"
        fill="#CB333B"
      />
      <path
        d="M13.0147 11.8554C13.2855 11.6409 13.6619 11.8466 13.6619 12.19C13.6619 12.3284 13.5955 12.4581 13.4875 12.5455C12.2634 13.5371 11.482 15.0457 11.482 16.7356C11.482 19.7216 13.922 22.1422 16.9319 22.1422C19.9417 22.1422 22.3817 19.7216 22.3817 16.7356C22.3817 15.1182 21.6659 13.6668 20.5314 12.6761C20.4306 12.5881 20.3695 12.4626 20.3695 12.3294C20.3695 11.9775 20.7595 11.7732 21.0285 12.0026C22.3704 13.1466 23.2202 14.8427 23.2202 16.7356C23.2202 20.181 20.4048 22.974 16.9319 22.974C13.459 22.974 10.6436 20.181 10.6436 16.7356C10.6436 14.7595 11.5696 12.9981 13.0147 11.8554Z"
        fill="#CB333B"
      />
    </SvgIcon>
  );
}
