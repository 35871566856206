import { QrcodeData } from '@Src/_basic/object/QrcodeType';
import { AllTest } from '@Src/_basic/object/TestType';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Stack } from '@mui/material';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ColFieldInfo from './ColFieldInfo';
import QrcodeDataRows from './QrcodeDataRows';

interface Props {
  qrcodeData?: QrcodeData;
  testDetail?: AllTest;
  children?: React.ReactNode;
}

function TestFormHeader({ qrcodeData, testDetail, children }: Props) {
  const { t: i18T } = useTranslation();
  const [open, setOpen] = useState<boolean>(true);

  return (
    <>
      <Stack flexDirection="column" position="relative">
        <Stack flexDirection="row">
          <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.SAMPLING_TIME`)}>
            {qrcodeData?.samplingTime
              ? moment(qrcodeData?.samplingTime).format('yyyy/MM/DD HH:mm')
              : testDetail?.createTime
              ? moment(testDetail?.createTime).format('yyyy/MM/DD HH:mm')
              : i18T('GLOBAL.NO_INFO')}
          </ColFieldInfo>

          <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.TEST_COMPLETE_TIME`)}>
            {testDetail?.completeTime
              ? moment(testDetail?.completeTime).format('yyyy/MM/DD HH:mm')
              : i18T('GLOBAL.NO_INFO')}
          </ColFieldInfo>

          <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.CODE`)}>
            {testDetail?.sampleId ?? i18T('GLOBAL.NO_INFO')}
          </ColFieldInfo>
        </Stack>

        {(qrcodeData || children) && (
          <IconButton
            sx={{ height: 40, width: 40, position: 'absolute', top: 0, right: 0 }}
            onClick={() => setOpen((prev) => !prev)}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Stack>

      {open && qrcodeData && <QrcodeDataRows qrcodeData={qrcodeData} testDetail={testDetail} />}
      {open && children}
    </>
  );
}

export default memo(TestFormHeader);
