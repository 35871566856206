import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';

interface Props {
  onCreateGoBackButtonClick: () => void;
  onSubmit: () => void;
}
export default function AreaManagementCreateSubmit(props: Props) {
  const { onCreateGoBackButtonClick, onSubmit } = props;
  const { t: i18T } = useTranslation();

  return (
    <>
      <YatungButton
        text={i18T('GLOBAL.CANCEL')}
        variant="contained"
        color="error"
        size={'large'}
        type={'button'}
        sx={{
          color: '#ffffff',
          fontSize: '20px',
          mr: 2,
          px: 2,
          py: 'auto',
        }}
        onClick={onCreateGoBackButtonClick}
      />
      <YatungButton
        text={i18T('GLOBAL.FINISH')}
        variant={'contained'}
        size={'large'}
        type={'submit'}
        onSubmit={onSubmit}
        sx={{
          color: '#ffffff',
          bgcolor: '#003087',
          fontSize: '20px',
          px: 2,
          py: 'auto',
          '&.MuiButtonBase-root:hover': {
            bgcolor: '#003087',
          },
        }}
      />
    </>
  );
}
