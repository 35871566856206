import React from 'react';
import { Stack } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import DetailIcon from '@Src/_basic/icons/Detail';
import DeteleIcon from '@Src/_basic/icons/Delete';
import EditIcon from '@Src/_basic/icons/Edit';
import { YardReciptData } from '@Src/_basic/object/YardReceiptsType';

export type ExtraData = {
  onViewDetail: (row: any) => void;
  onEdit: (row: any) => void;
  onDelete: (row: any) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: YardReciptData;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;

  return (
    <Stack direction="row" spacing={1}>
      <YatungButton
        text={formatExtraData.getButtonText('詳細')}
        startIcon={<DetailIcon />}
        color="yellow"
        onClick={() => {
          formatExtraData.onViewDetail(row);
        }}
      />
      <YatungButton
        text={formatExtraData.getButtonText('編輯')}
        startIcon={<EditIcon />}
        color="blue"
        onClick={() => {
          formatExtraData.onEdit(row);
        }}
      />
      <YatungButton
        text={formatExtraData.getButtonText('刪除')}
        startIcon={<DeteleIcon />}
        color="red"
        onClick={() => {
          formatExtraData.onDelete(row.id);
        }}
      />
    </Stack>
  );
}
