import React from 'react';
import { useTranslation } from 'react-i18next';
import FactoryManagementCreateForm from './FactoryManagementCreateForm';
import YatungPage from '@Src/_basic/components/YatungPage';
import { PostFactoryDataRequest } from '@Src/_basic/object/FactoryType';

export const initiaFactory: PostFactoryDataRequest = {
  areaId: 0,
  factoryName: '',
  factoryAddress: '',
  factoryPhone: '',
  factoryCode: '',
  branchCode: '',
};

export default function FactoryManagementCreateCard() {
  const { t: i18T } = useTranslation();

  return (
    <YatungPage
      title={i18T('FACTORYMANAGEMENT.PAGE_CREATE_TITLE')}
      body={<FactoryManagementCreateForm initialValues={initiaFactory} />}
    />
  );
}
