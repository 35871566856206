import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungImg from '@Src/_basic/components/YatungImg';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';

const OrganizeFormImage = '/media/images/organizeBlack.png';

export default function OrganizeManagementUpdateField() {
  const { t: i18T } = useTranslation();
  return (
    <Grid container>
      <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 2 }}>
        <YatungFormLabel
          label={i18T('ORGANIZEMANAGEMENT.ORGANIZE_NAME')}
          icon={<YatungImg img={OrganizeFormImage} />}
        />
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12}>
        <Field
          id="organizationName"
          name="organizationName"
          type="text"
          label=""
          component={YatungFormInput}
          sx={{ minWidth: '100%' }}
          required
        />
      </Grid>
    </Grid>
  );
}
