import YatungTable from '@Src/_basic/components/YatungTable';
import { UnitData } from '@Src/_basic/object/WeightUnitManageType';
import { useApi } from '@Src/redux/api/apiAction';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import LastUpdateTimeColumnFormatter from './columnFormatter/LastUpdateTimeColumnFormatter';

interface Props {
  rows: Array<UnitData>;
  onEdit: (id: number) => void;
}
export default function WeightUnitManageTable({ rows, onEdit }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const getButtonText = (status: string) => {
    switch (status) {
      case '編輯':
        return i18T('GLOBAL.UPDATE');
      default:
        return '';
    }
  };

  return (
    <YatungTable
      loading={actionLoading}
      data={rows}
      verticalAlign="top"
      columns={[
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.NO.'),
          dataField: 'ids',
          width: '25%',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.UNIT'),
          dataField: 'name',
          width: '25%',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.UPDATE_TIME'),
          dataField: 'lastUpdateTime',
          formatter: LastUpdateTimeColumnFormatter,
          width: '25%',
        },
        {
          text: i18T('GLOBAL.ACTION'),
          dataField: 'action',
          formatter: ActionColumnFormatter,
          formatExtraData: {
            onEdit: onEdit,
            getButtonText: getButtonText,
          },
          width: '25%',
        },
      ]}
    />
  );
}
