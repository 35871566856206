import { Response } from '@Src/_basic/object/ApiType';
import {
  BasicInfoData,
  ConcreteExptData,
  ConcreteHeaderData,
  CreateFileParams,
  CreateProportionRequest,
  CreateSignatureRequest,
  FileData,
  PageableConcreteExptData,
  PageableProjectsData,
  ProjectData,
  ProjectInfoData,
  ProportionData,
  ReplaceProportionRequest,
  SearchConcreteExptsParams,
  SearchConcreteFilesParams,
  SearchConcreteHeaderParams,
  SearchPageableConcreteExptsParams,
  SearchPageableProjectsParams,
  UpdateBasicInfoRequest,
  UpdateProportionRequest,
} from '@Src/_basic/object/ProductionHistoryType';
import { SignatureData } from '@Src/_basic/object/SignatureType';
import { Api } from '../Api';
import { ProductionHistoryProtocol } from './ProductionHistoryProtocol';

export class ProductionHistoryApi {
  public static getProjectByOrderId(
    orderId: string,
    onSuccess?: (data: ProjectData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        orderId: orderId,
      },
    };
    Api.get(ProductionHistoryProtocol.PROJECT_BY_SHIP_ID, config, onSuccess, onFailed, onFinally);
  }

  public static getPageableProjectsBySearch(
    params: SearchPageableProjectsParams,
    onSuccess?: (data: PageableProjectsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(ProductionHistoryProtocol.PAGEABLE_PROJECTS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getCancelablePageableProjectsBySearch(
    params: SearchPageableProjectsParams,
    onSuccess?: (data: PageableProjectsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };

    Api.get(ProductionHistoryProtocol.PAGEABLE_PROJECTS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static createProjectInfoProfileByOrderId(
    orderId: string,
    onSuccess?: (data: ProjectInfoData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(
      ProductionHistoryProtocol.PROJECT_INFO_PROFILE_BY_PK,
      { orderId: orderId },
      undefined,
      onSuccess,
      onFailed,
      onFinally,
    );
  }

  public static getProjectInfoById(
    id: number,
    onSuccess?: (data: ProjectInfoData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(ProductionHistoryProtocol.PROJECT_INFO_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static getProjectInfoByOrderId(
    orderId: string,
    onSuccess?: (data: ProjectInfoData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        orderId: orderId,
      },
    };
    Api.get(ProductionHistoryProtocol.PROJECT_INFO_BY_ORDER_ID, config, onSuccess, onFailed, onFinally);
  }

  public static updateBasicInfo(
    request: UpdateBasicInfoRequest,
    onSuccess?: (data: BasicInfoData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(
      ProductionHistoryProtocol.PROJECT_INFO_BASIC_INFO_BY_ID,
      request,
      undefined,
      onSuccess,
      onFailed,
      onFinally,
    );
  }

  public static createSignature(
    request: CreateSignatureRequest,
    onSuccess?: (data: SignatureData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ProductionHistoryProtocol.SIGNATURE, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static createProportion(
    request: CreateProportionRequest,
    onSuccess?: (data: ProportionData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ProductionHistoryProtocol.PROJECT_INFO_PROPORTION, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static createProportions(
    request: Array<CreateProportionRequest>,
    onSuccess?: (data: Array<ProportionData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(
      ProductionHistoryProtocol.PROJECT_INFO_MULTIPLE_PROPORTION,
      request,
      undefined,
      onSuccess,
      onFailed,
      onFinally,
    );
  }

  public static updateProportion(
    request: UpdateProportionRequest,
    onSuccess?: (data: ProportionData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(ProductionHistoryProtocol.PROJECT_INFO_PROPORTION, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static replaceProportions(
    request: ReplaceProportionRequest,
    onSuccess?: (data: Array<ProportionData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(
      ProductionHistoryProtocol.PROJECT_INFO_REPLACE_PROPORTION,
      request,
      undefined,
      onSuccess,
      onFailed,
      onFinally,
    );
  }

  public static uploadReports(
    params: CreateFileParams,
    files: FileList,
    onSuccess?: (data: Array<FileData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    };

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    Api.post(ProductionHistoryProtocol.UPLOAD_REPORTS, formData, config, onSuccess, onFailed, onFinally);
  }

  public static deleteReport(
    id: number,
    onSuccess?: (data: FileData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };

    Api.delete(ProductionHistoryProtocol.DELETE_REPORTS, config, onSuccess, onFailed, onFinally);
  }

  public static getConcreteHeaderBySearch(
    params: SearchConcreteHeaderParams,
    onSuccess?: (data: ConcreteHeaderData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(ProductionHistoryProtocol.CONCRETE_HEADER, config, onSuccess, onFailed, onFinally);
  }

  public static getConcreteExptsBySearch(
    params: SearchConcreteExptsParams,
    onSuccess?: (data: Array<ConcreteExptData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(ProductionHistoryProtocol.CONCRETE_EXPTS, config, onSuccess, onFailed, onFinally);
  }

  public static getCancelablePageableConcreteExptsBySearch(
    params: SearchPageableConcreteExptsParams,
    onSuccess?: (data: PageableConcreteExptData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };

    Api.get(ProductionHistoryProtocol.PAGEABLE_CONCRETE_EXPTS, config, onSuccess, onFailed, onFinally);
  }

  public static getConcreteFileBySearch(
    params: SearchConcreteFilesParams,
    onSuccess?: (data: Array<FileData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(ProductionHistoryProtocol.CONCRETE_FILES, config, onSuccess, onFailed, onFinally);
  }
}
