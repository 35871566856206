import React, { useState } from 'react';
import YatungAlert from '@Src/_basic/components/YatungAlert';
import { UpdateOrganizationsRequset } from '@Src/_basic/object/AccountRightType';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import OrganizeManagementUpdateField from './components/OrganizeManagementUpdateField';
import OrganizeManagementUpdateSubmit from './components/OrganizeManagementUpdateSubmit';
import { OrganizationApi } from '@Src/_basic/protocol/accountRights/organization/OrganizationApi';

interface Props {
  initialValues: UpdateOrganizationsRequset;
  onGoBackButtonClick: () => void;
  setAlertIsOpen: (e: boolean) => void;
  setSetAlertTitle: (e: string) => void;
}
const organizeSchema = Yup.object().shape({
  organizationName: Yup.string().required('請輸入組織名稱'),
});
export default function OrganizeManagementUpdateForm(props: Props) {
  const { t: i18T } = useTranslation();
  const { initialValues, onGoBackButtonClick, setAlertIsOpen, setSetAlertTitle } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleSubmit = (request: UpdateOrganizationsRequset) => {
    if (initialValues.organizationName === request.organizationName) {
      onGoBackButtonClick();
    }
    OrganizationApi.putUpdateOrganization(
      request,
      () => {
        setAlertIsOpen(true);
        setSetAlertTitle(i18T('GLOBAL.UPDATE_SUCCESS'));
        onGoBackButtonClick();
      },
      () => {
        setIsOpen(true);
      },
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={organizeSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <Grid container>
              <OrganizeManagementUpdateField />
            </Grid>
            <Grid container justifyContent={'space-evenly'} sx={{ my: 4 }}>
              <OrganizeManagementUpdateSubmit
                onGoBackButtonClick={onGoBackButtonClick}
                onSubmit={formik.handleSubmit}
              />
            </Grid>
          </Form>
        )}
      </Formik>
      <YatungAlert
        title={i18T('USERSMANAGEMENU.ORGANIZATION_MANAGEMENT.ALERT_ERROR')}
        open={isOpen}
        setOpen={setIsOpen}
        type={'error'}
      />
    </>
  );
}
