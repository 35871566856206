import { Response } from '@Src/_basic/object/ApiType';
import { PostReportRequest, SieveReportData, SieveReportParams } from '@Src/_basic/object/ReportType';
import { SignatureData } from '@Src/_basic/object/SignatureType';
import { Api } from '../Api';
import { ReportProtocol } from './ReportProtocol';

export class ReportApi {
  // 建立報告
  public static postReport(
    request: PostReportRequest,
    onSuccess?: (data: SignatureData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ReportProtocol.REPORT_SIEVE, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 搜尋報告
  public static getSearchReport(
    params: SieveReportParams,
    onSuccess?: (data: SieveReportData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ReportProtocol.SEARCH_SIEVE_REPORT, config, onSuccess, onFailed, onFinally);
  }
}
