import { RouteConfig } from '@Src/app/BasicRouter';
import { useAuth } from '@Src/redux/auth/authActions';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export default function PermissionRoute(routesConfig: RouteConfig[]) {
  const { userApiPermission } = useAuth();

  return (
    <Routes>
      {routesConfig.map((route) => {
        const { permission, path } = route;
        return permission ? (
          userApiPermission.includes(permission) && <Route {...route} key={path} />
        ) : (
          <Route {...route} key={path} />
        );
      })}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
