import { KeyManagementData } from '@Src/_basic/object/KeyManagementType';
import { Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

interface Props {
  row: KeyManagementData;
}
export default function CreateTimeColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{moment(row.createTime).format('yyyy.MM.DD')}</Typography>;
}
