import React from 'react';
import SiteManagementCreateForm from './SiteManagementCreateForm';
import { useTranslation } from 'react-i18next';
import YatungPage from '@Src/_basic/components/YatungPage';

const background = '/media/images/background.png';

export default function SiteManagementCreateCard() {
  const { t: i18T } = useTranslation();
  return (
    <YatungPage
      title={i18T('SITEMANAGEMENT.PAGE_CREATE_TITLE')}
      backgroundImage={background}
      body={<SiteManagementCreateForm />}
    />
  );
}
