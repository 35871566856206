import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';

interface Props {
  onOpenCreateModal: () => void;
}
const createImage = '/media/images/create.png';

export default function AreaManagementBar(props: Props) {
  const { t: i18T } = useTranslation();
  const { onOpenCreateModal } = props;

  return (
    <Grid container justifyContent={'flex-end'} alignItems={'center'} item xs={12} sx={{ my: 2 }}>
      <YatungButton
        text={i18T('AREAMANAGEMENT.ADD_BUTTON')}
        variant={'contained'}
        type={'button'}
        size={'large'}
        startIcon={<YatungImg img={createImage} />}
        color="green"
        onClick={onOpenCreateModal}
      />
    </Grid>
  );
}
