import { Snackbar, Stack, Alert } from '@mui/material';
import React, { useEffect } from 'react';

interface Props {
  title: string;
  open: boolean;
  setOpen: (e:boolean) => void;
  type: "error" | "warning" | "info" | "success"
}
export default function YatungAlert(props: Props) {

  const closeAlert = () => {
    if(props.open){
      setTimeout(() => {
        props.setOpen(false)
      }, 1500)
    }
  }

  useEffect(() => {
    closeAlert()
  }, [props.open])

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={props.open}>
        <Alert severity={props.type} sx={{ width: '25vw' }} >
          {props.title}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
