import React from 'react';
import SiteManagementCard from '../components/SiteManagementCard';

export default function SiteManagementScreen() {
  return (
    <>
      <SiteManagementCard />
    </>
  );
}
