import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { TestInDistrictDetialByData } from '@Src/_basic/object/TestInDistrictType';
import { ArrowCircleDown, ArrowCircleUp } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import CompletedColumnFormatter from './columnFormatter/CompletedColumnFormatter';
import CreateTimeColumnFormatter from './columnFormatter/CreateTimeColumnFormatter';
import CreaterNameColumnFormatter from './columnFormatter/CreaterNameColumnFormatter';
import ExecuteCountColumnFormatter from './columnFormatter/ExecuteCountColumnFormatter';
import OperatorNameColumnFormatter from './columnFormatter/OperatorNameNameColumnFormatter';

interface Props {
  loading: boolean;
  testInDistrictDetailData: Array<TestInDistrictDetialByData>;
  lastFactoryName: string;
  onSortClick: (page: number) => void;
  sortState: boolean;
  pageInfo: PageInfo;
}
export default function TestInDistrictByDetailTable(props: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { loading, testInDistrictDetailData, lastFactoryName, onSortClick, sortState, pageInfo } = props;

  const getButtonText = (status: string) => {
    switch (status) {
      case '詳細':
        return i18T('GLOBAL.DETAIL');
      case '已完成':
        return i18T('GLOBAL.COMPLETE');
      case '未完成':
        return i18T('GLOBAL.INCOMPLETE');
    }
  };
  const getDataSort = () => {
    return (
      <Stack flexDirection={'row'} alignItems={'center'}>
        {i18T('APPLICATIONSMANAGEMENT.TABLE.STATUS')}
        {sortState ? (
          <ArrowCircleUp
            onClick={() => onSortClick(pageInfo.page)}
            sx={{ width: 28, height: 28, ml: 1, cursor: 'pointer' }}
          />
        ) : (
          <ArrowCircleDown
            onClick={() => onSortClick(pageInfo.page)}
            sx={{ width: 28, height: 28, ml: 1, cursor: 'pointer' }}
          />
        )}
      </Stack>
    );
  };
  const handleGoToUpdatePage = (row: TestInDistrictDetialByData) => {
    navigate(`/realTimeMonitoring/testInDistrict/testInDistrictByTestList/${row.id}`, {
      state: { row, lastFactoryName },
    });
    // setRequirementCode(row.requirementCode);
  };
  const columns: Array<Column> = [
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.CREATER_NAME'),
      dataField: 'createrName',
      formatter: CreaterNameColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.OPERATOR_NAME'),
      dataField: 'operatorName',
      formatter: OperatorNameColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.CREATE_TIME'),
      dataField: 'createTime',
      formatter: CreateTimeColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.EXE_COUNT'),
      dataField: 'executeCount',
      formatter: ExecuteCountColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.STATUS'),
      dataField: 'completed',
      formatter: CompletedColumnFormatter,
      titleFormatter: () => getDataSort(),
      formatExtraData: {
        getButtonText: getButtonText,
      },
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        // onGoToUpdatePage: handleGoToUpdatePage,
        getButtonText: getButtonText,
        onViewDetail: handleGoToUpdatePage,
      },
    },
  ];
  return <YatungTable columns={columns} data={testInDistrictDetailData} loading={loading} />;
}
