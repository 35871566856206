export default [
    {
      id: 1,
      area: { value: 4, text: '北區' },
      factory: '汐止廠',
      lastTime:1684166400
    },
    {
      id: 2,
      area: { value: 5, text: '高屏區' },
      factory: '高雄廠',
      lastTime:1684166400
    }
  ];
  