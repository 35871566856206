/* eslint-disable react/display-name */
/* eslint-disable no-prototype-builtins */

import { ListSubheader, Popper, SxProps, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import React, { createContext, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import TriangleDown from '../icons/TriangleDown';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const isAllSelected = () => {
    const selectedValue: Array<boolean> = [];
    Array.isArray(data) &&
      data.forEach((item: Array<any>) => {
        if (item[1].value !== 'all') {
          selectedValue.push(item[3]);
        }
      });

    return selectedValue.every((value: boolean) => value === true);
  };

  const isSomeSelected = () => {
    const selectedValue: Array<boolean> = [];
    Array.isArray(data) &&
      data.forEach((item: Array<any>) => {
        if (item[1].value !== 'all') {
          selectedValue.push(item[3]);
        }
      });
    return selectedValue.some((value: boolean) => value === true);
  };

  const isLastItem = () => {
    return index === data.length - 1;
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <>
      {dataSet[4] ? (
        <>
          {isLastItem() ? (
            <Typography ref={dataSet[5]} component="li" {...dataSet[0]} noWrap style={inlineStyle}>
              {dataSet[1].value === 'all' ? (
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={isAllSelected()}
                  indeterminate={isSomeSelected() && !isAllSelected()}
                />
              ) : (
                <Checkbox style={{ marginRight: 8 }} checked={dataSet[3]} />
              )}

              {dataSet[1].text}
            </Typography>
          ) : (
            <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
              {dataSet[1].value === 'all' ? (
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={isAllSelected()}
                  indeterminate={isSomeSelected() && !isAllSelected()}
                />
              ) : (
                <Checkbox style={{ marginRight: 8 }} checked={dataSet[3]} />
              )}

              {dataSet[1].text}
            </Typography>
          )}
        </>
      ) : (
        <>
          {isLastItem() ? (
            <Typography ref={dataSet[5]} component="li" {...dataSet[0]} noWrap style={inlineStyle}>
              {dataSet[1].text}
            </Typography>
          ) : (
            <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
              {dataSet[1].text}
            </Typography>
          )}
        </>
      )}
    </>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref,
) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& fieldset': {
    borderColor: '#6C708C',
  },
  '& .MuiTextField-root': {
    margin: '0px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    background: 'white',
  },
}));

const TriangleDownIcon = styled(TriangleDown)(() => ({
  color: '#003087',
  fill: '#003087',
}));

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export type Options = {
  value: number | string;
  text: string | undefined;
};

export interface Props {
  options: Options[] | any;
  multiple?: boolean;
  value?: any | undefined;
  defaultValue?: any;
  removeHadSelectedValues?: (string | number)[];
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  helperText?: string;
  width?: React.CSSProperties['width'];
  fullWidth?: boolean;
  limitTags?: number;
  sx?: SxProps;
  loading?: boolean;

  hasNextPage?: boolean;
  onLoadMore?: () => void;
}

export function YatungComboBox(props: Props) {
  const [options, setOptions] = useState(props.options);
  const [selectedOptions, setSelectedOptions] = useState<Array<Options>>([]);
  const [selectedOption, setSelectedOption] = useState<Options>();

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: props.loading || false,
    hasNextPage: props.hasNextPage || false,
    onLoadMore: () => props.onLoadMore?.(),
  });

  const isAllSelected = () => {
    if (props.multiple) {
      if (options.length > 1) {
        return selectedOptions.length === options.length - 1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const initOptions = () => {
    let _option: Options[];
    if (props?.removeHadSelectedValues && props.removeHadSelectedValues?.length > 0)
      _option = props.options.filter((option: Options) => !props?.removeHadSelectedValues?.includes(option.value));
    else _option = props.options;

    if (props.multiple) {
      setOptions([{ value: 'all', text: '全選' }, ..._option]);
    } else {
      setOptions([..._option]);
    }
  };

  const getSelectedOptions = () => {
    if (props.value && props.options.length) {
      setSelectedOptions(props.options.filter((option: Options) => props.value.includes(option.value)));
    } else if (props.defaultValue && props.options.length) {
      setSelectedOptions(props.options.filter((option: Options) => props.defaultValue.includes(option.value)));
    }
  };

  const getSelectedOption = () => {
    if (props.value && props.options.length) {
      setSelectedOption(props.options.find((option: any) => option.value === props.value) || props.options[0]);
    } else if (props.defaultValue && props.options.length) {
      setSelectedOption(props.options.find((option: any) => option.value === props.defaultValue) || props.options[0]);
    }
  };

  const handleChange = (value: any) => {
    if (props.multiple) {
      if (value[value.length - 1]?.value === 'all') {
        setSelectedOptions(isAllSelected() ? [] : options.slice(1, options.length));
        props.onChange?.(
          isAllSelected() ? [] : options.slice(1, options.length).map((option: Options) => option.value),
        );
      } else {
        setSelectedOptions(value);
        props.onChange?.(value.map((option: Options) => option.value));
      }
    } else {
      setSelectedOption(value);
      props.onChange?.(value?.value);
    }
  };

  useEffect(() => {
    initOptions();
  }, [props.options, props.removeHadSelectedValues]);

  useEffect(() => {
    if (props.multiple) {
      getSelectedOptions();
    } else {
      getSelectedOption();
    }
  }, [props.value, props.defaultValue, props.options]);

  return (
    <div
      style={{
        width: props.fullWidth ? '100%' : props.width ? props.width : '180px',
      }}
    >
      <StyledAutocomplete
        loading={props.loading}
        size="small"
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        ListboxProps={{ ref: rootRef }}
        getOptionLabel={(option: any) => option?.text}
        options={options}
        value={props.multiple ? selectedOptions : selectedOption}
        onBlur={() => {
          props.onBlur?.(
            props.multiple ? selectedOptions.map((option: Options) => option.value) : selectedOption?.value,
          );
        }}
        onChange={(event, value) => {
          handleChange(value);
        }}
        renderOption={(htmlProps, option, state) =>
          [htmlProps, option, state.index, state.selected, props.multiple, sentryRef] as React.ReactNode
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="normal"
            placeholder={'搜尋'}
            error={!!props.helperText}
            helperText={props.helperText}
            sx={{
              input: {
                color: 'black',
                borderRadius: '10px',
              },
              width: props.fullWidth ? '100%' : props.width ? props.width : '180px',
              ...props.sx,
            }}
          />
        )}
        popupIcon={<TriangleDownIcon />}
        multiple={props.multiple}
        limitTags={props.limitTags}
        noOptionsText={'沒有選項'}
        disableCloseOnSelect
      />
    </div>
  );
}
