import React from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import YatungImg from '@Src/_basic/components/YatungImg';
import { useTranslation } from 'react-i18next';
import YatungButton from '@Src/_basic/components/YatungButton';
import { AreaData, PutUpdateDataRequest } from '@Src/_basic/object/AreaType';
import AreaManagementUpdateWarnMessage from './AreaManagementUpdateWarnMessage';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import AreaManagementUpdateDulpicateMessage from './AreaManagementUpdateDulpicateMessage';

const areaNameImg = '/media/images/areaName.png';
const areaSortImg = '/media/images/sort.png';
interface Props {
  setValue: (name: string, value: boolean) => void;
  value: PutUpdateDataRequest;
  warningMoalOpen: boolean;
  dulpicateWarningMoalOpen: boolean;
  onClose: () => void;
  setWarningMoalOpen: (e: boolean) => void;
  onDulpicateWarningModalClose: () => void;
  onUpdateGoBackButtonClick: () => void;
  setAreaData: (e: Array<AreaData>) => void;
}
export default function AreaManagementUpdateField(props: Props) {
  const { t: i18T } = useTranslation();
  const {
    setValue,
    value,
    warningMoalOpen,
    onClose,
    setWarningMoalOpen,
    setAreaData,
    onDulpicateWarningModalClose,
    dulpicateWarningMoalOpen,
  } = props;
  const handleAreaState = () => {
    AreaApi.deleteAllAreaById(
      value.id,
      (datas: AreaData) => {
        setValue('removed', !value.removed);
        AreaApi.getAllAreas((data: Array<AreaData>) => {
          setAreaData(data);
        }),
          undefined;
      },
      () => {
        setWarningMoalOpen(true);
      },
      undefined,
    );
  };
  return (
    <>
      <AreaManagementUpdateWarnMessage value={value} warningMoalOpen={warningMoalOpen} onClose={onClose} />
      <AreaManagementUpdateDulpicateMessage
        dulpicateWarningMoalOpen={dulpicateWarningMoalOpen}
        onDulpicateWarningModalClose={onDulpicateWarningModalClose}
      />
      <Grid container justifyContent={'center'} item xs={12} sx={{ my: 2 }}>
        <Grid container justifyContent={'center'} alignItems={'center'} display={'flex'} item xs={10}>
          <Field
            id="name"
            name="name"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ width: '90%' }}
            position={'startEnd'}
            icon={<YatungImg img={areaNameImg} />}
            text={i18T('AREAMANAGEMENT.AREA_NAME')}
            endTextColor={value.removed ? '#CB333B' : '#3E9B2F'}
            disabledText={value.removed ? i18T('GLOBAL.CLOSING') : i18T('GLOBAL.RUNNING')}
            required
          />
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} display={'flex'} item xs={2}>
          <YatungButton
            text={value.removed ? i18T('FACTORYMANAGEMENT.ACTION_OPEN') : i18T('FACTORYMANAGEMENT.ACTION_CLOSE')}
            variant="contained"
            color="error"
            type={'button'}
            size={'large'}
            sx={{
              color: '#ffffff',
              fontSize: '20px',
              minWidth: '10%',
              bgcolor: value.removed ? '#3E9B2F' : '#CB333B',
              '&.MuiButtonBase-root:hover': {
                bgcolor: value.removed ? '#3E9B2F' : '#CB333B',
              },
            }}
            onClick={handleAreaState}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} item xs={12} sx={{ my: 2 }}>
        <Grid container justifyContent={'center'} alignItems={'center'} display={'flex'} item xs={10}>
          <Field
            id="sortingOrder"
            name="sortingOrder"
            type="number"
            label=""
            component={YatungFormInput}
            sx={{ width: '90%' }}
            position={'start'}
            icon={<YatungImg img={areaSortImg} />}
            text={i18T('AREAMANAGEMENT.AREA_SORT')}
            required
          />
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} display={'flex'} item xs={2}></Grid>
      </Grid>
    </>
  );
}
