import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { Grid } from '@mui/material';
import YatungImg from '@Src/_basic/components/YatungImg';
import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import { WarehouseAndStateData, WarehouseData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { useTranslation } from 'react-i18next';

const deleteButtonImage = '/media/images/deleteButton.png';

const checkImage = '/media/images/check.svg';
interface Props {
  data: WarehouseData;
}
export default function FeedTroughMaintenanceManagementUpdateActions(props: Props) {
  const { t: i18T } = useTranslation();
  const { data } = props;
  const { feedTroughMaintenanceData, setFeedTroughMaintenanceData } = useFeedTroughMaintenance();

  const handleCancelButton = () => {
    const mappedData = feedTroughMaintenanceData.map((values: WarehouseAndStateData) => {
      if (values.id === data.id) {
        return { ...values, state: false };
      } else {
        return values;
      }
    });
    setFeedTroughMaintenanceData(mappedData);
  };
  return (
    <Grid container justifyContent={'flex-end'} item xs={12} sx={{ mt: 2 }}>
      <YatungButton
        text={i18T('GLOBAL.CANCEL')}
        variant={'contained'}
        color="inherit"
        type={'button'}
        size={'large'}
        startIcon={<YatungImg img={deleteButtonImage} />}
        sx={{
          bgcolor: '#CB333B',
          mr: 2,
          color: '#ffffff',
          '&.MuiButtonBase-root:hover': {
            bgcolor: '#CB333B',
          },
        }}
        onClick={handleCancelButton}
      />
      <YatungButton
        text={i18T('GLOBAL.FINISH')}
        variant={'contained'}
        color="inherit"
        type={'submit'}
        size={'large'}
        startIcon={<YatungImg img={checkImage} />}
        sx={{
          bgcolor: '#00D03A',
          mr: 2,
          color: '#ffffff',
          '&.MuiButtonBase-root:hover': {
            bgcolor: '#00D03A',
          },
        }}
      />
    </Grid>
  );
}
