import {
  getSpecificationExtraDataFieldNameByExperimentResultTypeId,
  getTypeAmountByExperimentItemId,
} from '@Src/_basic/helpers/LegalStandardHelpers';
import { LegalStandardData } from '@Src/_basic/object/LegalStandardType';
import { Stack } from '@mui/material';
import React from 'react';
import BasicCard from './components/BasicCard';
import SpecificationsCard from './components/SpecificationsCard';

interface Props {
  legalStandard: LegalStandardData | undefined;
  typeId: number;
}
export default function ViewLegalStandardCard(props: Props) {
  return (
    <Stack spacing={2}>
      <BasicCard legalStandard={props.legalStandard} typeId={props.typeId} />
      <SpecificationsCard
        variant={getSpecificationExtraDataFieldNameByExperimentResultTypeId(
          props.legalStandard?.experimentResultType?.id || 1,
        )}
        experimentResultTypeId={props.legalStandard?.experimentResultType?.id || 1}
        specifications={props.legalStandard?.specifications || []}
        specificationGroupsSize={2}
        typeAmount={
          props.legalStandard
            ? getTypeAmountByExperimentItemId(props.legalStandard.experimentResultType.experimentItem.id)
            : 1
        }
      />
    </Stack>
  );
}
