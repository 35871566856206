import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Weight(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.6,22.3H2.4L4.7,6.2h3.9c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.6c0-1,0.3-1.8,1-2.5c0.7-0.7,1.5-1,2.5-1c1,0,1.8,0.3,2.5,1c0.7,0.7,1,1.5,1,2.5c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.3-0.1,0.5h3.9L21.6,22.3z M3.2,21.6h17.5L18.6,6.9h-4.3l0.2-0.8c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.3,0-0.5c0-0.8-0.3-1.4-0.8-2c-0.5-0.5-1.2-0.8-2-0.8c-0.8,0-1.4,0.3-2,0.8c-0.5,0.5-0.8,1.2-0.8,2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.3,0.1,0.5l0.2,0.8H5.4L3.2,21.6z M19.6,20.6H4.4L6.2,7.9h11.6L19.6,20.6z M5.2,19.9h13.6L17.2,8.6H6.8L5.2,19.9z M12,6.9c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.7-0.7,1.9-0.7,2.5,0l0,0c0.3,0.3,0.5,0.8,0.5,1.3S13.6,6,13.3,6.4C12.9,6.7,12.5,6.9,12,6.9z M12,4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8c0.4,0.4,1.1,0.4,1.5,0c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8C12.6,4.1,12.3,4,12,4z" />
    </SvgIcon>
  );
}
