import React from 'react';
import { Typography } from '@mui/material';
import { YardReciptData } from '@Src/_basic/object/YardReceiptsType';

interface Props {
  row: YardReciptData;
}
export default function SourceNameColumnFormatter(props: Props) {
  const { row } = props;
  return (
    <>
      {row.source1Name ? (
        <Typography variant="inherit">{row.source1Name}</Typography>
      ) : (
        <Typography variant="inherit">&nbsp;</Typography>
      )}
      {row.source2Name ? (
        <Typography variant="inherit">{row.source2Name}</Typography>
      ) : (
        <Typography variant="inherit">&nbsp;</Typography>
      )}
      {row.source3Name ? (
        <Typography variant="inherit">{row.source3Name}</Typography>
      ) : (
        <Typography variant="inherit">&nbsp;</Typography>
      )}
    </>
  );
}
