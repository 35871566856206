import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import BackButton from '@Src/_basic/icons/BackButton';
import { MaterialInspectionFactory } from '@Src/_basic/object/MaterialInspectionType';
import { ExptMaterialInspectionApi } from '@Src/_basic/protocol/exptMaterialInspection/ExptMaterialInspectionApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useMaterialInspection } from '@Src/redux/materialInspection/materialInspectionActions';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import MaterialInspectionByFactoryCard from '../components/MaterialInspectionByFactoryCard';

export default function MaterialInspectionByFactoryScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { areaName },
  } = useLocation();
  const { id } = useParams();
  const { setDate, date } = useMaterialInspection();
  const { userAreaOptionsData, userGroupsData } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [materialInspectionFactoryData, setMaterialInspectionFactoryData] = useState<Array<MaterialInspectionFactory>>(
    [],
  );

  const handleDateChange = (value: unknown) => {
    setDate(value);
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    setLoading(true);
    ExptMaterialInspectionApi.getExptMaterialInspectionByFactory(
      { currTime: date, factoryIds: userGroupsData?.factories.map((item) => item.value) },
      (data: Array<MaterialInspectionFactory>) => {
        getIncludeFactory(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [date, id]);
  const getIncludeFactory = (data: Array<MaterialInspectionFactory>) => {
    const factoryData = userAreaOptionsData.filter((item: any) => item.text === areaName);
    const findFactoryData = factoryData.map((item: any) => item.factories);
    const factoryIds: Array<number> = [];
    findFactoryData.forEach((item: any) => {
      item.forEach((item: any) => {
        factoryIds.push(item.value);
      });
    });
    const newData = data.filter((item) => factoryIds.includes(item.factoryId));
    setMaterialInspectionFactoryData(newData);
    setLoading(false);
  };
  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_INSPECTION_PASS_MONITORING.TITLE') + `(${areaName})`}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ py: 1 }}>
            <YatungDateSelect
              disabled={loading}
              value={date}
              disableFuture={true}
              onChange={(val) => val && handleDateChange(new Date(val).getTime())}
            />

            <YatungButton
              disabled={loading}
              text={i18T('GLOBAL.BACK_PAGE')}
              color="green"
              onClick={handleGoBack}
              startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
              sx={{ alignSelf: 'center' }}
            />
          </Stack>
          <MaterialInspectionByFactoryCard
            materialInspectionFactoryData={materialInspectionFactoryData}
            loading={loading}
          />
        </>
      }
      contentBgColor="#fff"
    />
  );
}
