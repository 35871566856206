import { Stack } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AggregateButton from '../../../../../usersManage/otherMaintenancePages/MaterialReceipts/components/MaterialsReceiptMaleUpOrder/components/AggregateButton';
import CementButton from './components/CementButton';
import FlyashButton from './components/FlyashButton';
import MedicineButton from './components/MedicineButton';
import SandButton from './components/SandButton';
import SlagButton from './components/SlagButton';

interface Props {
  selectedFactory: number;
}
export default function SelectTypeMenu(props: Props) {
  const { t: i18T } = useTranslation();
  const { selectedFactory } = props;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Stack
          direction="row"
          style={{
            backgroundColor: '#b6b7b8',
            borderRadius: '10px',
            border: '2px solid White',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#033991',
              height: '100%',
              width: '60px',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
            }}
          >
            <span
              style={{
                letterSpacing: '27px',
                textIndent: '27px',
                fontSize: '30px',
                color: 'white',
                writingMode: 'vertical-rl',
                WebkitWritingMode: 'vertical-rl',
                fontFamily: 'microsoft yahei',
              }}
            >
              {i18T('QUALITY_MANAGE.STANDARD.SELECT_TYPE_TITLE')}
            </span>
          </div>
          <Stack spacing={1} alignItems="center" style={{ padding: '8px' }}>
            <Stack direction="row" spacing={1} justifyContent="center">
              <SandButton selectedFactory={selectedFactory} />
              <AggregateButton selectedFactory={selectedFactory} />
              <CementButton selectedFactory={selectedFactory} />
            </Stack>
            <Stack direction="row" spacing={1}>
              <SlagButton selectedFactory={selectedFactory} />
              <FlyashButton selectedFactory={selectedFactory} />
              <MedicineButton selectedFactory={selectedFactory} />
            </Stack>
          </Stack>
        </Stack>
      </div>
    </>
  );
}
