import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../Api';
import { TestItemProtocol } from './TestItemProtocol';
import {
  SearchDemandOrderItemsParams,
  SearchTestItemParams,
  SearchTestOrderItemsParams,
  TestItemData,
} from '@Src/_basic/object/TestItemType';

export class TestItemApi {
  public static getTestItemBySearch(
    params: SearchTestItemParams,
    onSuccess?: (data: Array<TestItemData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(TestItemProtocol.ALL_TEST_ITEM, config, onSuccess, onFailed, onFinally);
  }

  public static getTestItemById(
    id: string,
    onSuccess?: (data: TestItemData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };

    Api.get(TestItemProtocol.TEST_ITEM, config, onSuccess, onFailed, onFinally);
  }

  public static getTestOrderItemsBySearch(
    params: SearchTestOrderItemsParams,
    onSuccess?: (data: Array<TestItemData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(TestItemProtocol.TEST_ITEM_BY_TEST_ORDER, config, onSuccess, onFailed, onFinally);
  }

  public static getDemandOrderItemsBySearch(
    params: SearchDemandOrderItemsParams,
    onSuccess?: (data: Array<TestItemData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(TestItemProtocol.TEST_ITEM_BY_DEMAND_ORDER, config, onSuccess, onFailed, onFinally);
  }
}
