import React from 'react';
import TotalPageTitle from '@Src/_basic/components/YatungPageTitle';
import { useTranslation } from 'react-i18next';
import PelletsBi from '../components/PelletsBi';

export default function PelletsScreen() {
  const { t: i18T } = useTranslation();
  return (
    <>
      <TotalPageTitle title={i18T('POWERBI.PELLETS')} />
      <PelletsBi />
    </>
  );
}
