import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PowerSwitch(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M11.9,2c-0.3,0-0.6,0.3-0.6,0.6v10c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6v-10C12.5,2.3,12.2,2,11.9,2z"/>
      <path d="M6.3,6.1c0.4-0.3,0.9,0,0.9,0.5c0,0.2-0.1,0.4-0.2,0.5c-1.8,1.4-2.9,3.6-2.9,6c0,4.3,3.5,7.7,7.8,7.7c4.3,0,7.8-3.5,7.8-7.7c0-2.3-1-4.4-2.6-5.8c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.5,0.6-0.8,0.9-0.5c1.9,1.6,3.1,4.1,3.1,6.8c0,4.9-4,8.9-9,8.9c-5,0-9-4-9-8.9C2.9,10.2,4.2,7.7,6.3,6.1z"/>
    </SvgIcon>
  );
}
