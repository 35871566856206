import { Box, Modal, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import YatungPageTitle from '@Src/_basic/components/YatungPageTitle';
import YatungQRCode from '@Src/_basic/components/YatungQRcode';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';

import CarPlateIcon from '@Src/_basic/icons/CarPlate';
import ClockIcon from '@Src/_basic/icons/Clock';
import ContentMangementIcon from '@Src/_basic/icons/ContentMangement';
import EditIcon from '@Src/_basic/icons/Edit';
import MenuIcon from '@Src/_basic/icons/Menu';
import SourceIcon from '@Src/_basic/icons/Source';
import StaffIcon from '@Src/_basic/icons/Staff';
import StorageIcon from '@Src/_basic/icons/Storage';
import WeightIcon from '@Src/_basic/icons/Weight';

import trunckImg from '@Basic/assets/trunck.svg';
import YatungAlert from '@Src/_basic/components/YatungAlert';
import {
  MaterialReceiptQRCodeDataResponse,
  MaterialReceiptsData,
  SearchSSSParams,
  SSSData,
} from '@Src/_basic/object/MaterialReceiptsType';
import { SearchStoragesParams, StorageData } from '@Src/_basic/object/StorageType';
import { MaterialReceiptsApi } from '@Src/_basic/protocol/materialReceipt/MaterialReceiptsApi';
import { StorageApi } from '@Src/_basic/protocol/storage/StorageApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useTranslation } from 'react-i18next';

const MaterialsReceiptInputlItem = ({ Icon, label, children }: any) => {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
      direction="row"
      sx={{ p: 1, mt: 1, mb: 1, width: '320px', fontSize: '20px' }}
    >
      {Icon && <Icon />}
      <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>{`${label}:`}</Typography>
      {children}
    </Stack>
  );
};
const statusOptions = [
  { value: '進場', text: '進場' },
  { value: '未正常結單', text: '未正常結單' },
  { value: '完成', text: '完成' },
];
function MaterialsReceiptUpdateContent({ id, handleCloseModal, suppliers, creators, getAllReceipts }: any) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>({});
  const [qrCodeData, setQRCodeData] = useState('');
  const [formData, setFormData] = useState<any>({
    plateNumber: '',
    sourceId: 0,
    specificationId: 0,
    supplierId: 0,
    grossWeight: '',
    emptyWeight: '',
    billOfLandingCode: '',
    deliveryTicketCode: '',
    exportValue: '',
    createrId: 0,
    storageId: 0,
    status: '',
  });
  const [specifications, setSpecifications] = useState<any[]>([]);
  const [sources, setSources] = useState<any[]>([]);
  const [storages, setStorages] = useState<Options[]>([]);
  const [reciptsDataById, setReciptsDataById] = useState<any>();
  const getReceipt = () => {
    MaterialReceiptsApi.getReceipt(id, onGetReceiptSuccess);
  };
  const onGetReceiptSuccess = (data: MaterialReceiptsData) => {
    setReciptsDataById(data);
    const SSSSourcesParams: SearchSSSParams = {
      factoryId: data.factoryId,
      typeId: data.typeId,
      removed: false,
    };
    const SSSSpecificationsParams: SearchSSSParams = {
      factoryId: data.factoryId,
      typeId: data.typeId,
      sourceId: data.sourceId,
      removed: false,
    };
    const searchStorageParams: SearchStoragesParams = {
      factoryId: data.factoryId,
      typeId: data.typeId,
    };
    MaterialReceiptsApi.getSSSArray(SSSSpecificationsParams, SSSSpecificationsToOptions);
    MaterialReceiptsApi.getSSSArray(SSSSourcesParams, SSSSourcesToOptions);
    StorageApi.getStoragesBySearch(searchStorageParams, StorageDataToOptions);
    const selectedCreator = creators.find((i: any) => i.value === data.createrId);

    setDetail(data);
    setFormData({
      plateNumber: data.plateNumber,
      specificationId: data.specificationId,
      sourceId: data.sourceId,
      supplierId: data.supplierId,
      grossWeight: data.grossWeight,
      emptyWeight: data.emptyWeight,
      billOfLandingCode: data.billOfLandingCode,
      deliveryTicketCode: data.deliveryTicketCode,
      exportValue: data.exportValue,
      createrId: selectedCreator ? data.createrId : creators[0].value,
      storageId: data.storageId,
      status: data.status,
    });
  };

  const SSSSourcesToOptions = (data: SSSData) => {
    const sourcesOptions = data.sources.map((source: any) => ({
      value: source.id,
      text: source.sourceName,
    }));

    setSources(sourcesOptions);
  };

  const SSSSpecificationsToOptions = (data: SSSData) => {
    const specificationsOptions = data.specifications.map((spec: any) => ({
      value: spec.id,
      text: spec.name,
    }));

    setSpecifications(specificationsOptions);
  };

  const StorageDataToOptions = (data: Array<StorageData>) => {
    const storageOptions = data.map((source: any) => ({
      value: source.id,
      text: source.name,
    }));
    setStorages(storageOptions);
  };
  const handleFormDataChange = (field: string) => (value: any) => {
    if (field === 'grossWeight' || field === 'emptyWeight' || field === 'exportValue') {
      if (new RegExp(/^[0-9]*$/).test(value)) {
        //只允許輸入數字
        setFormData({
          ...formData,
          [field]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };
  const handleSourceSelectChange = (sourceId: number) => {
    const searchSSSParams: SearchSSSParams = {
      factoryId: reciptsDataById.factoryId,
      typeId: reciptsDataById.typeId,
      sourceId: sourceId,
      removed: false,
    };
    MaterialReceiptsApi.getSSSArray(searchSSSParams, SSSSpecificationsToOptions);
    setFormData({
      ...formData,
      sourceId,
      specificationId: 0,
    });
  };
  const handleConfirmButtonClick = () => {
    if (formData.specificationId !== 0) {
      MaterialReceiptsApi.updateReceipt({ ...formData, id }, () => {
        getAllReceipts();
        handleCloseModal();
      });
    } else {
      setErrorOpen(true);
    }
    setTimeout(() => {
      setErrorOpen(false);
    }, 1500);
  };

  const getReceiptQRCode = () => {
    MaterialReceiptsApi.getReceiptQRCode(id, onGetReceiptQRCode);
  };

  const onGetReceiptQRCode = (data: MaterialReceiptQRCodeDataResponse) => {
    setQRCodeData(JSON.stringify(data));
  };

  useEffect(() => {
    getReceipt();
    getReceiptQRCode();
  }, []);

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Box
        sx={{
          minWidth: 680,
          bgcolor: '#003087',
          color: 'white',
          boxShadow: 16,
          borderRadius: '20px',
          p: 2,
          opacity: actionLoading ? 0.8 : 1,
        }}
      >
        <Stack spacing={1} direction="row">
          <div>
            <YatungPageTitle
              title={`編號:${id}`}
              sx={{ flex: '1 0 0', borderBottom: '1px solid #FFFFFF', pb: 1 }}
              textColor="white"
              iconColor="white"
            />
            <Stack spacing={1} direction="row" sx={{ flex: '1 0 0' }}>
              <Box sx={{ width: '50%' }}>
                <MaterialsReceiptInputlItem Icon={ClockIcon} label="建單時間">
                  {moment(detail.createTime).format('YYYY/MM/DD_hh:mm')}
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={CarPlateIcon} label="車號">
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('plateNumber')(e.target.value)}
                    value={formData['plateNumber']}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={StaffIcon} label="供應商">
                  <YatungSelect
                    disabled={actionLoading}
                    options={suppliers}
                    value={formData['supplierId']}
                    onChange={(e) => handleFormDataChange('supplierId')(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={SourceIcon} label="來源">
                  <YatungSelect
                    disabled={actionLoading}
                    options={sources}
                    value={formData['sourceId']}
                    onChange={(e: any) => handleSourceSelectChange(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={ContentMangementIcon} label="規格">
                  <YatungSelect
                    disabled={specifications.length === 0 || actionLoading}
                    options={specifications}
                    value={formData['specificationId']}
                    onChange={(e) => handleFormDataChange('specificationId')(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={StorageIcon} label="料庫">
                  <YatungSelect
                    disabled={storages.length === 0 || actionLoading}
                    options={storages}
                    value={formData['storageId']}
                    onChange={(e) => handleFormDataChange('storageId')(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={WeightIcon} label="總重">
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('grossWeight')(e.target.value)}
                    value={formData['grossWeight']}
                    position="end"
                    text="KG"
                    type="text"
                    onKeyPress={(e: any) => {
                      //只允許輸入數字
                      if (!new RegExp(/^[0-9]*$/).test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={WeightIcon} label="空重">
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('emptyWeight')(e.target.value)}
                    value={formData['emptyWeight']}
                    position="end"
                    text="KG"
                    type="text"
                    onKeyPress={(e: any) => {
                      //只允許輸入數字
                      if (!new RegExp(/^[0-9]*$/).test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={WeightIcon} label="淨重">
                  <Typography sx={{ padding: '0 0 0 8px', fontSize: '20px' }}>
                    {`${formData.grossWeight - formData.emptyWeight} KG`}
                  </Typography>
                </MaterialsReceiptInputlItem>
              </Box>
              <Box sx={{ width: '50%' }}>
                <MaterialsReceiptInputlItem Icon={StaffIcon} label="人員">
                  <YatungSelect
                    disabled={actionLoading}
                    options={creators}
                    value={formData['createrId']}
                    onChange={(e) => handleFormDataChange('createrId')(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={MenuIcon} label="送貨單淨重">
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('exportValue')(e.target.value)}
                    value={formData['exportValue']}
                    position="end"
                    text="KG"
                    type="text"
                    onKeyPress={(e: any) => {
                      //只允許輸入數字
                      if (!new RegExp(/^[0-9]*$/).test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={WeightIcon} label="差異量">
                  <Typography sx={{ padding: '0 0 0 8px', fontSize: '20px' }}>
                    {`${formData.grossWeight - formData.emptyWeight - formData.exportValue} KG`}
                  </Typography>
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={MenuIcon} label="提單單號">
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('billOfLandingCode')(e.target.value)}
                    value={formData['billOfLandingCode']}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem Icon={MenuIcon} label="送單單號">
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('deliveryTicketCode')(e.target.value)}
                    value={formData['deliveryTicketCode']}
                  />
                </MaterialsReceiptInputlItem>
                {reciptsDataById?.status === '未正常結單' && (
                  <MaterialsReceiptInputlItem Icon={StaffIcon} label="狀態">
                    <YatungSelect
                      disabled={actionLoading}
                      options={statusOptions}
                      value={formData['status']}
                      onChange={(e) => handleFormDataChange('status')(e.target.value)}
                      sx={{ width: '204px' }}
                    />
                  </MaterialsReceiptInputlItem>
                )}
              </Box>
            </Stack>
          </div>
          <Stack spacing={1} sx={{ p: 1, pt: 8, pl: 4 }} alignItems="center" justifyContent="space-between">
            <Box sx={{ width: '210px', height: '210px' }}>
              {qrCodeData && <YatungQRCode text={qrCodeData} width={210} height={210} />}
            </Box>
            <YatungImg img={trunckImg} sx={{ width: '180px', pt: 2, pb: 2 }} />
            <Typography sx={{ color: '#F0CA00', fontSize: '30px', letterSpacing: 0.515, pb: 2 }}>
              {detail.status}
            </Typography>
            <Stack alignItems="center" justifyContent="space-between" flexDirection="row">
              <YatungButton
                text="取消"
                startIcon={<EditIcon />}
                onClick={handleCloseModal}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#CB333B',
                  fontSize: '24px',
                  width: 120,
                  mr: 1,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#CB333B',
                  },
                }}
              />
              <YatungButton
                disabled={
                  formData.plateNumber === '' ||
                  formData.specificationId === 0 ||
                  formData.sourceId === 0 ||
                  formData.supplierId === 0 ||
                  formData.storageId === 0 ||
                  formData.createrId === 0 ||
                  !formData.grossWeight ||
                  !formData.emptyWeight
                }
                text="完成"
                startIcon={<EditIcon />}
                onClick={handleConfirmButtonClick}
                sx={{
                  border: '1px solid #fff',
                  color: '#ffffff',
                  bgcolor: '#002F8C',
                  fontSize: '24px',
                  width: 120,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#002F8C',
                  },
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {actionLoading && <YatungLoading overlay={true} />}
      <YatungAlert
        open={errorOpen}
        setOpen={setErrorOpen}
        title={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.SPECIFICATION_ALERT')}
        type="error"
      />
    </Stack>
  );
}

export default function MaterialsReceiptUpdateModal({ open = false, ...props }: any) {
  return (
    <Modal open={open}>
      <MaterialsReceiptUpdateContent {...props} />
    </Modal>
  );
}
