import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { CalcRmbt } from '@Src/_basic/helpers/CalcTestForm';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { AllTest } from '@Src/_basic/object/TestType';
import { ImportRmbt, PutRmbtRequest, Rmbt } from '@Src/_basic/object/test/sandRock/RmbtType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: Rmbt;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  beforeRinseDryWeight: basicNumberSchema,
  afterRinseDryWeight: basicNumberSchema,
  drops: basicNumberSchema,
});

function ExportRmbtTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  const calcTestForm = useMemo(() => new CalcRmbt(testDetail), [testDetail]);

  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      beforeRinseDryWeight: testDetail?.beforeRinseDryWeight,
      afterRinseDryWeight: testDetail?.afterRinseDryWeight,
      drops: testDetail?.drops,
    }),
    [testDetail],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportRmbt = {
        beforeRinseDryWeight: checkIsNaN(+values.beforeRinseDryWeight),
        afterRinseDryWeight: checkIsNaN(+values.afterRinseDryWeight),
        drops: checkIsNaN(+values.drops),
      };

      TestApi.putTest<PutRmbtRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const rinseInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.beforeRinseDryWeight?.toFixed(1)),
        values: [formatNumber(testDetail?.afterRinseDryWeight?.toFixed(1))],
      },
    ],
    [testDetail],
  );

  const siltInfo = useMemo(
    () => [
      {
        title: `${formatNumber(calcTestForm?.siltWeight?.toFixed(1))}`,
        values: [isFinite(calcTestForm?.rValue) ? formatNumber(calcTestForm?.rValue?.toFixed(2)) : '資料不正確'],
      },
    ],
    [calcTestForm],
  );

  const firstHeader = useMemo(
    () => ({
      values: [`${i18T(`TEST.RMBT.BEFORE_RINSE_DRY_WEIGHT`)}(g)`, `${i18T(`TEST.RMBT.AFTER_RINSE_DRY_WEIGHT`)}(g)`],
    }),
    [i18T],
  );

  const secondHeader = useMemo(
    () => ({
      values: [`${i18T(`TEST.RMBT.SILT_WEIGHT`)}(%)`, `${i18T(`TEST.RMBT.R_VALUE`)}`],
    }),
    [i18T],
  );

  const rinseDryWeightView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack flexDirection="row" gap={10}>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.RMBT.BEFORE_RINSE_DRY_WEIGHT`)}(g)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.beforeRinseDryWeight}
                isError={!!(formik.touched.beforeRinseDryWeight && formik.errors.beforeRinseDryWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('beforeRinseDryWeight', e.target.value)
                }
              />
            </ColFieldInfo>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.RMBT.AFTER_RINSE_DRY_WEIGHT`)}(g)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.afterRinseDryWeight}
                isError={!!(formik.touched.afterRinseDryWeight && formik.errors.afterRinseDryWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('afterRinseDryWeight', e.target.value)
                }
              />
            </ColFieldInfo>
          </Stack>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={rinseInfo} />;
      default:
        break;
    }
  }, [edit, formik, firstHeader, rinseInfo, i18T]);

  const dropsView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <ColFieldInfo
            titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
            title={i18T(`TEST.RMBT.DROPS`)}
          >
            <LabelInput
              valueWidth="150px"
              value={formik.values.drops}
              isError={!!(formik.touched.drops && formik.errors.drops)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('drops', e.target.value)}
            />
          </ColFieldInfo>
        );
      case false:
        return <TestTable headerData={secondHeader} rows={siltInfo} />;
      default:
        break;
    }
  }, [edit, formik, secondHeader, siltInfo, i18T]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {rinseDryWeightView}
      </TestCardContainer>
      <TestCardContainer>{dropsView}</TestCardContainer>
    </Stack>
  );
}

export default memo(ExportRmbtTable);
