import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SampleLocation(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="18" height="21" viewBox="0 0 18 21" fill="none">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
        <path
          d="M10.5244 5.40931L10.5238 5.40873C10.1027 5.00516 9.58571 4.8001 9.00031 4.8001C8.41489 4.8001 7.89801 5.00518 7.47735 5.40902C7.05569 5.81382 6.8336 6.31957 6.8336 6.90014C6.8336 7.48063 7.05564 7.9862 7.47743 8.39053C7.89797 8.79476 8.4148 9.00017 9.00031 9.00017C9.58598 9.00017 10.1031 8.79466 10.5241 8.39046C10.9454 7.98601 11.167 7.48044 11.167 6.90014C11.167 6.31986 10.9454 5.81419 10.5244 5.40931ZM4.39895 11.1219L4.39884 11.1217C3.32887 9.60421 2.8335 8.25416 2.8335 7.06014C2.8335 5.18457 3.45566 3.73448 4.67578 2.65443L4.67586 2.65436C5.92594 1.54732 7.36049 1 9.00031 1C10.6401 1 12.0747 1.54732 13.3248 2.65436L13.3248 2.65443C14.545 3.73448 15.1671 5.18457 15.1671 7.06014C15.1671 8.25423 14.672 9.60429 13.6026 11.1218C12.5854 12.565 11.0569 14.1422 9.00031 15.8524C6.94378 14.1422 5.41562 12.565 4.39895 11.1219Z"
          stroke="white"
        />
        <path
          d="M4.33334 13.9906C1.92774 14.6183 0.333252 15.7171 0.333252 16.9677C0.333252 18.9186 4.21354 20.5001 9.00011 20.5001C13.7867 20.5001 17.667 18.9186 17.667 16.9677C17.667 15.6049 15.7734 14.4224 13.0002 13.8333V14.4397C13.6871 14.5933 14.3093 14.7845 14.8491 15.0045C16.3682 15.6236 17.0003 16.3603 17.0003 16.9677C17.0003 17.5751 16.3682 18.3118 14.8491 18.9309C13.3866 19.527 11.3194 19.9113 9.00011 19.9113C6.68085 19.9113 4.61358 19.527 3.15109 18.9309C1.63197 18.3118 0.999933 17.5751 0.999933 16.9677C0.999933 16.3603 1.63197 15.6236 3.15109 15.0045C3.51041 14.858 3.90624 14.7244 4.33334 14.606V13.9906Z"
          fill="white"
        />
        <path
          d="M4.33334 13.9906C1.92774 14.6183 0.333252 15.7171 0.333252 16.9677C0.333252 18.9186 4.21354 20.5001 9.00011 20.5001C13.7867 20.5001 17.667 18.9186 17.667 16.9677C17.667 15.6049 15.7734 14.4224 13.0002 13.8333V14.4397C13.6871 14.5933 14.3093 14.7845 14.8491 15.0045C16.3682 15.6236 17.0003 16.3603 17.0003 16.9677C17.0003 17.5751 16.3682 18.3118 14.8491 18.9309C13.3866 19.527 11.3194 19.9113 9.00011 19.9113C6.68085 19.9113 4.61358 19.527 3.15109 18.9309C1.63197 18.3118 0.999933 17.5751 0.999933 16.9677C0.999933 16.3603 1.63197 15.6236 3.15109 15.0045C3.51041 14.858 3.90624 14.7244 4.33334 14.606V13.9906Z"
          stroke="white"
        />
      </svg>
    </SvgIcon>
  );
}
