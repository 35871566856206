import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import { getMappedSpecificationDetail } from '@Src/_basic/helpers/StandardHelpers';
import DetectionItemCard from './components/DetectionItemCard';
import { Options } from '@Src/_basic/components/YatungSelect';
import { NotificationPriorityApi } from '@Src/_basic/protocol/notificationPriority/NotificationPriorityApi';
import { ExperimentDetectionItemApi } from '@Src/_basic/protocol/experimentDetectionItem/ExperimentDetectionItemApi';
import { NotificationPriorityData } from '@Src/_basic/object/NotificationPriorityType';
import {
  ExperimentDetectionItemData,
  SearchExperimentDetectionItemsParams,
} from '@Src/_basic/object/ExperimentDetectionItemType';

interface Props {
  experimentResultTypeId: number;
  specifications: Array<any>;
  specificationGroupsSize: number;
  variant?: 'source' | 'specification' | 'default';
  typeAmount: number;
}

export default function SpecificationsCard(props: Props) {
  const [specificationGroupsSize, setSpecificationGroupsSize] = useState<number>(2);
  const [specificationGroups, setSpecificationGroups] = useState<Array<Array<any>>>([]);
  const [detectionItems, setDetectionItems] = useState<any[]>([]);
  const [notificationPriorityOptions, setNotificationPriorityOptions] = useState<Options[]>([]);

  const getSubTitle = (customStandatdSpecification: any) => {
    if (customStandatdSpecification) {
      if (props.variant === 'source') {
        return customStandatdSpecification.sources.map((source: any) => source.sourceName);
      } else if (props.variant === 'specification') {
        return customStandatdSpecification.specifications.map((specification: any) => specification.name);
      } else {
        return [];
      }
    }
  };

  const getNotificationPriorityOptions = () => {
    NotificationPriorityApi.getNotificationPriorities(onGetNotificationPriorityOptionsSuccess);
  };

  const onGetNotificationPriorityOptionsSuccess = (data: Array<NotificationPriorityData>) => {
    setNotificationPriorityOptions(data.map(({ id, name }: NotificationPriorityData) => ({ value: id, text: name })));
  };

  const getExperimentDetectionItems = async () => {
    const searchParams: SearchExperimentDetectionItemsParams = {
      experimentResultTypeId: props.experimentResultTypeId,
      sort: 'id,asc',
    };
    ExperimentDetectionItemApi.getExperimentDetectionItemsBySearch(searchParams, onGetExperimentDetectionItemsSuccess);
  };

  const onGetExperimentDetectionItemsSuccess = (data: Array<ExperimentDetectionItemData>) => {
    setDetectionItems(data);
  };

  const getSpecificationGroups = () => {
    const specificationGroupsTemp: Array<Array<any>> = [];
    const totalSpecificationsTemp: Array<any> = [...props.specifications];
    while (totalSpecificationsTemp.length > 0) {
      specificationGroupsTemp.push(
        totalSpecificationsTemp.splice(
          0,
          totalSpecificationsTemp.length > specificationGroupsSize
            ? specificationGroupsSize
            : totalSpecificationsTemp.length,
        ),
      );
    }

    setSpecificationGroups(specificationGroupsTemp);
  };

  useEffect(() => {
    if (props.experimentResultTypeId) {
      getExperimentDetectionItems();
    }
  }, [props.experimentResultTypeId]);

  useEffect(() => {
    getSpecificationGroups();
  }, [props.specifications]);

  useEffect(() => {
    if (props.specificationGroupsSize) {
      setSpecificationGroupsSize(props.specificationGroupsSize);
    }
  }, [props.specificationGroupsSize]);

  useEffect(() => {
    getNotificationPriorityOptions();
  }, []);

  return (
    <Stack divider={<YatungDivider />} spacing={3}>
      {specificationGroups.map((specifications: Array<any>, specificationGroupIndex) => {
        return (
          <Stack key={specificationGroupIndex} direction="row" spacing={1}>
            {specifications.map((specification, specificationIndex) => {
              return (
                <Grid key={specification.id} item xs>
                  <Stack spacing={2}>
                    {getMappedSpecificationDetail(detectionItems, specification.details).map(
                      (detectionItem: any, detectionIndex: number) => {
                        return (
                          <DetectionItemCard
                            key={detectionItem.id}
                            title={`規格${String.fromCharCode(
                              65 + specificationGroupIndex * specificationGroupsSize + specificationIndex,
                            )}`}
                            subTitle={getSubTitle(specification)}
                            detectionItem={detectionItem}
                            detectionIndex={detectionIndex}
                            notificationPriorityOptions={notificationPriorityOptions}
                            typeAmount={props.typeAmount ? props.typeAmount : 1}
                          />
                        );
                      },
                    )}
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
}
