import { TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungButton from '@Src/_basic/components/YatungButton';
import CoarseSieveReportTable from '@Src/_basic/components/YatungTest/CoarseSieveReportTable';
import FineSieveLineChart from '@Src/_basic/components/YatungTest/FineSieveLineChart';
import FineSieveReportTable from '@Src/_basic/components/YatungTest/FineSieveReportTable';
import TabCoarseSieveLineChart from '@Src/_basic/components/YatungTest/TabCoarseSieveLineChart';
import { AllTest, TestItemEnum } from '@Src/_basic/object/TestType';
import { CoarseSieveLegalTypeEnum } from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: 12,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#003087',
  },
}));

interface Props {
  open: boolean;
  closeHandler: () => void;
  form1Id: number;
  form2Id: number;
  _ratio: number;
  exptItemCode: TestItemEnum;
}

const AnalysisReportDialog = ({ open, closeHandler, form1Id, form2Id, exptItemCode, _ratio }: Props) => {
  const { state } = useLocation();
  const { t: i18T } = useTranslation();

  const [testFormS1, setTestFormS1] = useState<AllTest>();
  const [testFormS2, setTestFormS2] = useState<AllTest>();

  const switchReportTable = useCallback(
    (_testItemCode: TestItemEnum) => {
      if (!testFormS1 || !testFormS2) return null;

      switch (_testItemCode) {
        case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS:
          return <FineSieveReportTable testForm={[testFormS1, testFormS2]} ratio={_ratio} />;
        case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS:
          return (
            <CoarseSieveReportTable
              testForm={[testFormS1, testFormS2]}
              ratio={_ratio}
              type={CoarseSieveLegalTypeEnum.TYPE1}
            />
          );
        default:
          return null;
      }
    },
    [testFormS1, testFormS2, _ratio],
  );

  const switchLineChart = useCallback(
    (_testItemCode: TestItemEnum) => {
      if (!testFormS1 || !testFormS2) return null;

      switch (_testItemCode) {
        case TestItemEnum.FINE_AGG_SIEVE_ANALYSIS:
          return <FineSieveLineChart testFormS1={testFormS1} testFormS2={testFormS2} ratio={_ratio} />;
        case TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS:
          return <TabCoarseSieveLineChart testFormS1={testFormS1} testFormS2={testFormS2} ratio={_ratio} />;
        default:
          return null;
      }
    },
    [testFormS1, testFormS2, _ratio],
  );

  useEffect(() => {
    if (!form1Id || !form2Id || !exptItemCode) return;
    TestApi.getTestDetail({ exptItemCode: exptItemCode as TestItemEnum, exptId: Number(form1Id) }, (data) =>
      setTestFormS1(data),
    );
    TestApi.getTestDetail({ exptItemCode: exptItemCode as TestItemEnum, exptId: Number(form2Id) }, (data) =>
      setTestFormS2(data),
    );
  }, [form1Id, form2Id, exptItemCode]);

  return (
    <Dialog fullScreen open={open} onClose={closeHandler} TransitionComponent={Transition} sx={{ py: 2, px: 10 }}>
      <CustomDialogContent>
        <AppBar sx={{ position: 'absolute', width: '100%', bgcolor: '#003087' }}>
          <Toolbar
            sx={{
              bgcolor: '#003087',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
              sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ flex: 1, fontSize: 24, transform: 'translateX(-15%)' }}>
              {i18T(`VIEW.SIDE_MENU.SIEVE_REPORT`)}
            </Typography>
          </Toolbar>
        </AppBar>

        <CardContent
          sx={{ borderRadius: '30px', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2);', position: 'relative', pt: 10 }}
        >
          <Stack flexDirection="column" gap={2}>
            <TestCardContainer sx={{ height: '700px' }}>
              <Stack sx={{ height: state?._ratio ? '600px' : '550px' }}>
                {switchLineChart(exptItemCode as TestItemEnum)}
              </Stack>

              <Stack sx={state?._ratio ? { mt: 2 } : { mt: 6 }}>
                <Typography sx={{ fontSize: 22, alignSelf: 'center' }}>
                  {`S1${i18T('REPORT.SIEVE_REPORT.BASIC.SIEVE')}:`}
                  <span style={{ borderBottom: '1px solid gray', margin: '0 4px' }}>{(_ratio * 100).toFixed(0)}</span>%
                </Typography>
              </Stack>
            </TestCardContainer>

            {switchReportTable(exptItemCode as TestItemEnum)}

            <Stack flexDirection="row" justifyContent="flex-end" gap={4} sx={{ my: 4 }}>
              <YatungButton color="red" width={100} text={'返回'} onClick={closeHandler} />
            </Stack>
          </Stack>
        </CardContent>
      </CustomDialogContent>
    </Dialog>
  );
};

export default memo(AnalysisReportDialog);
