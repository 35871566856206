import React from 'react';
import TransferOrderGroupManagementCreateCard from '../components/TransferOrderGroupManagementCreateCard';

export default function TransferOrderGroupManagementCreateCardScreen() {
  return (
    <>
      <TransferOrderGroupManagementCreateCard />
    </>
  );
}
