export default [
  {
    value: 1,
    text: '細砂',
  },
  {
    value: 2,
    text: '粗砂',
  },
];
