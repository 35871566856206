import YatungPaper from '@Src/_basic/components/YatungPaper';
import {
  CreateInitialFeedTroughRequest,
  WarehouseAndStateData,
  WarehouseData,
} from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { FeedTroughMaintenanceApi } from '@Src/_basic/protocol/feedTroughMaintenanceManagement/FeedTroughMaintenanceApi';
import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FeedTroughMaintenanceManagementCreateActions from './components/FeedTroughMaintenanceManagementCreateActions';
import FeedTroughMaintenanceManagementCreateBody from './components/FeedTroughMaintenanceManagementCreateBody';

export interface Props {
  index: number;
  selectedFactory: number;
  data: WarehouseAndStateData;
}
export default function FeedTroughMaintenanceManagementCreatePaper(props: Props) {
  const { t: i18T } = useTranslation();
  const { index, selectedFactory } = props;
  const feedTroughMaintenanceSchema = Yup.object().shape({
    typeId: Yup.number().positive(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.TYPE')),
    name: Yup.string().required(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NAME')),
    specificationId: Yup.number().positive(i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.SPEFICATION')),
    volume: Yup.number().min(1, i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NUMBER')),
    specificWeight: Yup.number().min(1, i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.NUMBER')),
  });
  const {
    setFeedTroughMaintenanceData,
    setFeedTroughMaintenanceCreateDatas,
    feedTroughMaintenanceCreateDatas,
    feedTroughMaintenanceData,
  } = useFeedTroughMaintenance();
  const initialFeedTroughMaintenance: CreateInitialFeedTroughRequest = {
    typeId: 0,
    name: '',
    specificationId: 0,
    code: '',
    volume: 0,
    specificWeight: 0,
  };
  const handleSaveSubmit = async (request: CreateInitialFeedTroughRequest) => {
    FeedTroughMaintenanceApi.postStorage(
      { ...request, factoryId: selectedFactory },
      (datas: WarehouseData[]) => {
        FeedTroughMaintenanceApi.getStorageBySearch(
          { factoryId: selectedFactory, isYard: false },
          (values: WarehouseAndStateData[]) => {
            mappingData(values);
          },
          undefined,
        );
      },
      undefined,
    );
  };
  const mappingData = (values: WarehouseAndStateData[]) => {
    const mappedData = values.map((value: WarehouseAndStateData) => ({
      ...value,
      state: false,
    }));
    const dataMap: any = {};
    feedTroughMaintenanceData.forEach((element: WarehouseData) => {
      dataMap[element.id] = {
        ...element,
      };
    });
    const mappedDataTemp: Array<any> = [];
    mappedData.forEach((element: WarehouseData) => {
      mappedDataTemp.push({
        ...element,
        ...dataMap[element.id],
      });
    });
    setFeedTroughMaintenanceData(mappedDataTemp);
    const newDatas = Object.assign([], feedTroughMaintenanceCreateDatas);
    newDatas.splice(index, 1);
    setFeedTroughMaintenanceCreateDatas(newDatas);
  };
  return (
    <Box>
      <Formik
        initialValues={initialFeedTroughMaintenance}
        validationSchema={feedTroughMaintenanceSchema}
        onSubmit={(require) => handleSaveSubmit(require)}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Grid container justifyContent={'center'} item xs={12}>
                <YatungPaper
                  body={
                    <FeedTroughMaintenanceManagementCreateBody
                      values={values}
                      setValues={setFieldValue}
                      selectedFactory={selectedFactory}
                    />
                  }
                  actions={<FeedTroughMaintenanceManagementCreateActions index={index} />}
                  sx={{ borderRadius: 5, width: 610, height: 550, boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
