import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungButton from '@Src/_basic/components/YatungButton';

import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import PowerSwitch from '@Src/_basic/icons/PowerSwitch';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import { ResetForm, SubmitData } from '..';
import { basicStringSchema } from '@Src/_basic/helpers/TestForm';

interface Props {
  open?: boolean;
  handleClose: () => void;
  handleSubmit: (data: any) => void;
}

const formSchema = Yup.object().shape({
  tagName: basicStringSchema(),
});

const initData = {
  tagName: '',
};

const labelStyle = {
  fontSize: '24px',
  mb: 1,
};

const AddModal = ({ open, handleClose, handleSubmit }: Props) => {
  const { t: i18T } = useTranslation();
  const [status, setStatus] = useState<boolean>(false);

  const onSubmit = (value: SubmitData, { resetForm }: ResetForm) => {
    const _data = {
      ...value,
      status,
    };
    handleSubmit(_data);
    setStatus(true);
    resetForm();
  };

  return (
    <Formik initialValues={initData} validationSchema={formSchema} onSubmit={onSubmit} enableReinitialize>
      {(formik) => {
        return (
          <Form>
            <YatungDialog
              open={open}
              handleClose={handleClose}
              title={'新增標籤'}
              headerComponent={
                <Stack flexDirection="row" justifyContent="space-between">
                  <Stack flexDirection="row">
                    <AddCircleFill fontSize="large" />
                    <Typography sx={{ fontSize: '24px', pl: 1 }}>新增標籤</Typography>
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <YatungButton
                      sx={{
                        mr: 1,
                        backgroundColor: '#CB333B',
                        '&:hover': { backgroundColor: '#CB333B' },
                        borderRadius: '20px',
                        px: '16px',
                      }}
                      onClick={handleClose}
                    >
                      取消
                    </YatungButton>
                    <YatungButton
                      sx={{
                        ml: 1,
                        backgroundColor: '#00D03A',
                        '&:hover': { backgroundColor: '#00D03A' },
                        borderRadius: '20px',
                        px: '16px',
                      }}
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      完成
                    </YatungButton>
                  </Stack>
                </Stack>
              }
              body={
                <>
                  <Stack flexDirection="row" justifyContent="flex-start" sx={{ py: 2 }}>
                    <Box sx={{ mr: 3 }}>
                      <Typography sx={labelStyle}>標籤名稱*</Typography>
                      <Field
                        id="tagName"
                        name="tagName"
                        type="text"
                        label=""
                        component={YatungFormInput}
                        sx={{ minWidth: '70%' }}
                        required
                      />
                    </Box>
                    <Box>
                      <Stack flexDirection="row">
                        <Typography sx={{ ...labelStyle, pr: 2, width: '90px' }}>狀 態</Typography>
                        <Typography sx={{ ...labelStyle, pl: 2, width: '90px', color: status ? '#CB333B' : '#00D03A' }}>
                          {status ? '停 止' : '啟  用'}
                        </Typography>
                      </Stack>
                      <Stack flexDirection="row">
                        <YatungButton
                          startIcon={<PowerSwitch />}
                          sx={{ mr: 1, backgroundColor: '#00D03A', '&:hover': { backgroundColor: '#00D03A' } }}
                          onClick={() => setStatus(false)}
                        >
                          啟用
                        </YatungButton>
                        <YatungButton
                          startIcon={<PowerSwitch />}
                          sx={{ ml: 1, backgroundColor: '#CB333B', '&:hover': { backgroundColor: '#CB333B' } }}
                          onClick={() => setStatus(true)}
                        >
                          停止
                        </YatungButton>
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack flexDirection="row" justifyContent="flex-end" sx={{ py: 2 }}>
                    <Box>
                      <Typography sx={{ ...labelStyle, letterSpacing: 16 }}>修改時間</Typography>
                      <Typography sx={{ fontSize: '16px', letterSpacing: 8 }}>
                        {moment(new Date()).format('YYYY-MM-DD')} <br />
                        {moment(new Date()).format('HH:mm:ss')}
                      </Typography>
                    </Box>
                  </Stack>
                </>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default memo(AddModal)