import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import SampleLocation from '@Src/_basic/icons/SampleLocation';
import SearchIcon from '@Src/_basic/icons/Search';
import { SampleData, SearchPaginationParams } from '@Src/_basic/object/SampleLocationType';
import { TypeData } from '@Src/_basic/object/TypeType';
import { SampleApi } from '@Src/_basic/protocol/sample/SampleApi';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SampleSourceCreateCard from '../component/SampleSourceCreateCard';
import SampleSourceManagementTable from '../component/SampleSourceManagementTable';
import SampleSourceUpdateCard from '../component/SampleSourceUpdateCard';

type FilterValueType = {
  factoryId: number;
  areaId: number;
};

type QueryType = FilterValueType & {
  name: string;
};

export default function SampleSourceScreen() {
  const location = useLocation();
  const { actionLoading } = useApi();
  const { request, setSaveRequest } = useRequestSaving<QueryType>();
  const { t: i18T } = useTranslation();

  const initQuery = useMemo<QueryType>(() => {
    const { factoryId = 0, areaId = 0, name = '' } = (location.pathname === request?.page && request?.request) || {};

    return {
      factoryId,
      areaId,
      name,
    };
  }, [request, location.pathname]);

  const [{ areaId, factoryId, name }, setQuery] = useState<QueryType>(initQuery);
  const [sampleData, setSampleData] = useState<SampleData[] | undefined>();
  const [sampleByIdData, setSampleByIdData] = useState<SampleData>();
  const [types, setTypes] = useState<Options[]>([]);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<'CREATE' | 'UPDATE' | 'DELETE' | null>(null);

  const handleModelOpen = useCallback((modal: 'CREATE' | 'UPDATE' | 'DELETE') => {
    setModalOpen(modal);
  }, []);

  const handleModelClose = useCallback(() => {
    setModalOpen(null);
  }, []);

  const handleUpdateAndDelete = useCallback(
    //更新和刪除資料
    (id: number, modal: 'UPDATE' | 'DELETE') => {
      const selectedById = sampleData?.find((i: SampleData) => i.id === id);
      setModalOpen(modal);
      if (selectedById) setSampleByIdData(selectedById);
    },
    [sampleData],
  );

  const params: SearchPaginationParams = useMemo(
    () => ({
      ...(name && { name }),
      factoryId: factoryId,
      isAnalysis: false,
      removed: false,
    }),
    [name, factoryId],
  );

  const getAllTypes = () => {
    TypeApi.getAllTypes((data) => {
      setTypes(data.map((type: TypeData) => ({ value: type.id, text: type.name })));
    });
  };

  const reset = useCallback(() => {
    //重整資料
    setIsVisible(false);
    setSampleData([]);
  }, [setSampleData]);

  const getSampleSourceData = useCallback(() => {
    SampleApi.geAllSample(params, (data: SampleData[]) => {
      setIsVisible(true);
      setSaveRequest({ page: location.pathname, request: { areaId, factoryId, name } });
      setSampleData(data.map((item, index) => ({ ...item, number: index + 1 })));
    });
  }, [name, factoryId, areaId, location.pathname, setSaveRequest, params]); //監聽觸發條件刷新資料

  const handleDelete = useCallback(() => {
    if (!sampleByIdData) return;
    SampleApi.DeleteSample(sampleByIdData.id, () => {
      setModalOpen(null);
      getSampleSourceData(); //重整資料
    });
  }, [sampleByIdData, getSampleSourceData]);

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    setQuery((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    reset();
  }, [name, areaId, factoryId]);

  useEffect(() => {
    getAllTypes();
    if (request?.page === location.pathname && request?.request && request.request.factoryId) {
      getSampleSourceData();
    }
  }, []);

  return (
    <>
      <YatungPage
        title={i18T('SAMPLESOURCEMANAGEMENT.PAGE_TITLE')}
        body={
          <>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
                <YatungCombinationSelect<FilterValueType>
                  filterValue={{ areaId, factoryId }}
                  handleChange={handleChange}
                  selectOptions={['AreaAndFactory']}
                />
                <YatungInput value={name} onChange={(e: any) => handleChange('name')(e.target.value)} />
                <YatungButton
                  text={i18T('GLOBAL.SEARCh_BUTTON')}
                  startIcon={<SearchIcon />}
                  color="blue"
                  onClick={getSampleSourceData}
                />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <YatungButton
                  disabled={!factoryId || actionLoading}
                  text={i18T('SAMPLEMANAGEMENT.CREATE_SAMPLE_BUTTON')}
                  startIcon={<SampleLocation />}
                  onClick={() => handleModelOpen('CREATE')}
                  color={'green'}
                  sx={{
                    fontSize: '16px',
                  }}
                />
              </Stack>
            </Stack>
            <YatungFadeInOut isVisible={isVisible}>
              <SampleSourceManagementTable sampleData={sampleData} handleUpdateAndDelete={handleUpdateAndDelete} />
            </YatungFadeInOut>
          </>
        }
        contentBgColor="#FFF"
      />
      <SampleSourceCreateCard
        createModalOpen={modalOpen === 'CREATE'}
        onCreateGoBackButtonClick={handleModelClose}
        types={types}
        factoryId={factoryId}
        getSampleSourceData={getSampleSourceData}
      />
      {sampleByIdData && (
        <SampleSourceUpdateCard
          sampleByIdData={sampleByIdData}
          onUpdateGoBackButtonClick={handleModelClose}
          updateModalOpen={modalOpen === 'UPDATE'}
          types={types}
          getSampleSourceData={getSampleSourceData}
        />
      )}
      <YatungConfirmAlert isShowAlert={modalOpen === 'DELETE'} onClose={handleModelClose} onOk={handleDelete} />
    </>
  );
}
