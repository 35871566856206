import React from 'react';
import { SampleData, SampleOptions } from '@Src/_basic/object/SampleLocationType';
import NamesExpand from '@Src/_basic/components/NamesExpand';

interface Props {
  row: SampleData;
}
export default function TypeColumnFormatter(props: Props) {
  const { row } = props;

  return <NamesExpand names={row.types.map((factory: SampleOptions) => factory.name)} />;
}
