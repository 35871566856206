import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import CementitiousPage from './pages/Cementitious/CementitiousPage';
import FactoryDeviationPage from './pages/FactoryDeviation/FactoryDeviationPage';
import PelletsPage from './pages/Pellets/PelletsPage';
import RemainingKPIPage from './pages/RemainingKPI/RemainingKPIPage';
import ReturnAndLossPage from './pages/ReturnAndLoss/ReturnAndLossPage';
import WaterDecadePage from './pages/WaterDecade/WaterDecadePage';

const routesConfig: RouteConfig[] = [
  {
    path: '/WaterDecade/*',
    element: <WaterDecadePage />,
    permission: 'WD057',
  },
  {
    path: '/Pellets/*',
    element: <PelletsPage />,
    permission: 'WD058',
  },
  {
    path: '/Cementitious/*',
    element: <CementitiousPage />,
    permission: 'WD059',
  },
  {
    path: '/ReturnAndLoss/*',
    element: <ReturnAndLossPage />,
    permission: 'WD060',
  },
  {
    path: '/RemainingKPI/*',
    element: <RemainingKPIPage />,
    permission: 'WD061',
  },
  {
    path: '/FactoryDeviation/*',
    element: <FactoryDeviationPage />,
    permission: 'WD062',
  },
];

const PowerbiManageRouter = () => PermissionRoute(routesConfig);

export default memo(PowerbiManageRouter);
