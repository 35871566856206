import { RootState } from '@Redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { layoutSlice } from './layoutSlice';

export function useLayout() {
  const { isLeftMenuOpen, currentUrl } = useSelector((state: RootState) => state.layout);
  const dispatch = useDispatch();

  const switchLeftMenuOpen = () => dispatch(layoutSlice.actions.switchLeftMenuOpen());
  const setCurrentUrl = (_url: string) => dispatch(layoutSlice.actions.setCurrentUrl(_url));

  return {
    isLeftMenuOpen,
    switchLeftMenuOpen,
    currentUrl,
    setCurrentUrl,
  };
}
