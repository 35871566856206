export default [
  {
    id: 1,
    area: { value: 1, text: '北區' },
    factory: { value: 1, text: '汐止廠' },
    typeSetting: { value: 1, text: '砂' },
    definitionName: '砂一',
    feedTroughMaintenanceSymbol: '砂一',
    joinSpecification: { value: 1, text: '細砂' },
    volumeSettingCubicMeter: 1000,
    volumeSettingSpecificGravity: 2,
    totalVolumeSetting: 2000,
  },
  {
    id: 2,
    area: { value: 3, text: '南區' },
    factory: { value: 3, text: '高屏廠' },
    typeSetting: { value: 1, text: '砂' },
    definitionName: '砂二',
    feedTroughMaintenanceSymbol: '細沙專用槽',
    joinSpecification: { value: 1, text: '細砂' },
    volumeSettingCubicMeter: 1000,
    volumeSettingSpecificGravity: 2,
    totalVolumeSetting: 2000,
  },
];
