import {
  FileInfo,
  FineAndRejectFileInfo,
  GetFileRequest,
  GetFineAndRejectFileParams,
  PostFineAndRejectFileRequest,
} from '@Src/_basic/object/FileType';
import { Response } from '../../object/ApiType';
import { Api } from '../Api';
import { FileProtocol } from './FileProtocol';

export class FileApi {
  // getFile 取得檔案
  public static getFile(
    params: GetFileRequest,
    onSuccess?: (_data: Array<FileInfo>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FileProtocol.File, config, onSuccess, onFailed, onFinally);
  }

  // deleteFile 刪除檔案
  public static deleteFile(
    id: number | undefined,
    onSuccess?: (data: FileInfo) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(FileProtocol.File, config, onSuccess, onFailed, onFinally);
  }

  public static getFineAndRejectFile(
    params: GetFineAndRejectFileParams,
    onSuccess?: (_data: Array<FineAndRejectFileInfo>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FileProtocol.FineAndRejectFile, config, onSuccess, onFailed, onFinally);
  }

  public static postFineAndRejectFile(
    params: PostFineAndRejectFileRequest,
    files: FileList,
    onSuccess?: (_data: Array<FineAndRejectFileInfo>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    };

    const formData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append('files', files[i]);
    }

    Api.post(FileProtocol.UploadFineAndRejectFile, formData, config, onSuccess, onFailed, onFinally);
  }

  public static postFile(
    params: PostFineAndRejectFileRequest,
    files: FileList,
    onSuccess?: (_data: Array<FineAndRejectFileInfo>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    };

    const formData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append('files', files[i]);
    }

    Api.post(FileProtocol.Upload_File, formData, config, onSuccess, onFailed, onFinally);
  }
}
