import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function StorageYardSpecificationValue(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 16.5468 11.7466 H 11.2466 V 17.0468 C 11.2466 18.7322 12.6128 20.0985 14.2982 20.0985 H 16.5468 C 18.2322 20.0985 19.5985 18.7322 19.5985 17.0468 V 14.7982 C 19.5985 13.1128 18.2322 11.7466 16.5468 11.7466 Z M 11.0859 11.5859 V 17.0468 C 11.0859 18.8209 12.5241 20.2591 14.2982 20.2591 H 16.5468 C 18.3209 20.2591 19.7591 18.8209 19.7591 17.0468 V 14.7982 C 19.7591 13.0241 18.3209 11.5859 16.5468 11.5859 H 11.0859 Z" />
      <path d="M10.8438 11.3408H16.5486C18.4574 11.3408 20.0048 12.8882 20.0048 14.797V17.0456C20.0048 18.9544 18.4574 20.5018 16.5486 20.5018H14.3C12.3911 20.5018 10.8438 18.9544 10.8438 17.0456V11.3408ZM11.4922 11.9893V17.0456C11.4922 18.5963 12.7493 19.8534 14.3 19.8534H16.5486C18.0992 19.8534 19.3563 18.5963 19.3563 17.0456V14.797C19.3563 13.2464 18.0992 11.9893 16.5486 11.9893H11.4922Z" />
      <path d="M16.5468 9.2554H11.2466V3.95514C11.2466 2.26975 12.6128 0.903472 14.2982 0.903472H16.5468C18.2322 0.903472 19.5985 2.26975 19.5985 3.95514V6.20373C19.5985 7.88912 18.2322 9.2554 16.5468 9.2554ZM11.0859 9.41602V3.95514C11.0859 2.18104 12.5241 0.742858 14.2982 0.742858H16.5468C18.3209 0.742858 19.7591 2.18105 19.7591 3.95514V6.20373C19.7591 7.97783 18.3209 9.41602 16.5468 9.41602H11.0859Z" />
      <path d="M10.8438 9.66113H16.5486C18.4574 9.66113 20.0048 8.11373 20.0048 6.20492V3.95632C20.0048 2.04751 18.4574 0.500111 16.5486 0.500111H14.3C12.3911 0.500111 10.8438 2.04751 10.8438 3.95632V9.66113ZM11.4922 9.01265V3.95632C11.4922 2.40565 12.7493 1.14859 14.3 1.14859H16.5486C18.0992 1.14859 19.3563 2.40565 19.3563 3.95632V6.20492C19.3563 7.75559 18.0992 9.01265 16.5486 9.01265H11.4922Z" />
      <path d="M3.45319 11.7466H8.75345V17.0468C8.75345 18.7322 7.38717 20.0985 5.70178 20.0985H3.45319C1.7678 20.0985 0.401519 18.7322 0.401519 17.0468V14.7982C0.401519 13.1128 1.7678 11.7466 3.45319 11.7466ZM8.91406 11.5859V17.0468C8.91406 18.8209 7.47588 20.2591 5.70178 20.2591H3.45319C1.67909 20.2591 0.240905 18.8209 0.240905 17.0468V14.7982C0.240905 13.0241 1.67909 11.5859 3.45319 11.5859H8.91406Z" />
      <path d="M9.16406 11.3408H3.45925C1.55044 11.3408 0.00304031 12.8882 0.00304031 14.797V17.0456C0.00304031 18.9544 1.55044 20.5018 3.45925 20.5018H5.70785C7.61666 20.5018 9.16406 18.9544 9.16406 17.0456V11.3408ZM8.51558 11.9893V17.0456C8.51558 18.5963 7.25852 19.8534 5.70785 19.8534H3.45925C1.90858 19.8534 0.65152 18.5963 0.65152 17.0456V14.797C0.65152 13.2464 1.90858 11.9893 3.45925 11.9893H8.51558Z" />
      <path d="M3.45319 9.2554H8.75345V3.95514C8.75345 2.26975 7.38717 0.903472 5.70178 0.903472H3.45319C1.7678 0.903472 0.401519 2.26975 0.401519 3.95514V6.20373C0.401519 7.88912 1.7678 9.2554 3.45319 9.2554ZM8.91406 9.41602V3.95514C8.91406 2.18104 7.47588 0.742858 5.70178 0.742858H3.45319C1.67909 0.742858 0.240905 2.18105 0.240905 3.95514V6.20373C0.240905 7.97783 1.67909 9.41602 3.45319 9.41602H8.91406Z" />
      <path d="M9.16406 9.66113H3.45925C1.55044 9.66113 0.00304031 8.11373 0.00304031 6.20492V3.95632C0.00304031 2.04751 1.55044 0.500111 3.45925 0.500111H5.70785C7.61666 0.500111 9.16406 2.04751 9.16406 3.95632V9.66113ZM8.51558 9.01265V3.95632C8.51558 2.40565 7.25852 1.14859 5.70785 1.14859H3.45925C1.90858 1.14859 0.65152 2.40565 0.65152 3.95632V6.20492C0.65152 7.75559 1.90858 9.01265 3.45925 9.01265H8.51558Z" />
    </SvgIcon>
  );
}
