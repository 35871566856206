import { RequireItemList, TestInDistrictDetialByData } from '@Src/_basic/object/TestInDistrictType';
import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  row: TestInDistrictDetialByData;
}
export default function ExecuteCountColumnFormatter(props: Props) {
  const { row } = props;
  const getTotalLenghth = () => {
    return row.requireItemList.length;
  };
  const getFilterLength = () => {
    return row.requireItemList.filter((value: RequireItemList) => value.completed).length;
  };
  return <Typography variant="inherit">{`${getFilterLength()}/${getTotalLenghth()}`}</Typography>;
}
