export default [
  {
    value: 0,
    text: '請選擇---',
  },
  {
    value: 1,
    text: '警衛室',
  },
  {
    value: 2,
    text: '地磅室',
  },
  {
    value: 3,
    text: '入料口',
  },
  {
    value: 4,
    text: '品管室',
  },
];
