import { CardMedia } from '@mui/material';
import React from 'react';

export default function ReturnAndLossBi() {
  return (
    <CardMedia
      component="iframe"
      src="https://app.powerbi.com/reportEmbed?reportId=710d7bcf-37e8-430c-b803-e4baf210365b&autoAuth=true&ctid=ab35a555-3574-4bb8-9c15-c26a9300d0ff"
      sx={{ width: '80vw', height: '80vh' }}
    ></CardMedia>
  );
}
