import { AuthSysBaseResponse, Response } from '@Src/_basic/object/ApiType';
import { Api } from '../../../Api';
import { RoleProtocol } from './RoleProtocol';
import { Content, RoleContent, RightsData, RightsRequest, RoleCreateRequest, BelongActionRequest, RoleUpdateRequest } from '@Src/_basic/object/AccountRightType';

export class RoleApi {
  // 取得權限角色清單
  public static postGetRightsRoles(
    request: RightsRequest,
    onSuccess?: (data: RightsData<Content>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(RoleProtocol.POST_GET_RIGHTS_ROLES, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得權限角色
  public static postGetRightsRoleById(
    id: string,
    onSuccess?: (data: RoleContent) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id };
    Api.post(RoleProtocol.POST_GET_RIGHTS_ROLE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  //  設定角色Action權限
  public static postSetRightsRoleBelongAction(
    request: BelongActionRequest,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(RoleProtocol.POST_SET_RIGHTS_ROLE_BELONG_ACTION, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增角色權限
  public static postCreateRightsRoles(
    request: RoleCreateRequest,
    onSuccess?: (data: RightsData<Content>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(RoleProtocol.POST_CREATE_RIGHTS_ROLE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 編輯角色權限
  public static postUpdateRightsRoles(
    request: RoleUpdateRequest,
    onSuccess?: (data: RightsData<Content>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(RoleProtocol.POST_UPDATE_RIGHTS_ROLE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 刪除角色權限
  public static postDeleteRightsRoleById(
    id: string,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id };
    Api.post(RoleProtocol.POST_DELETE_RIGHTS_ROLE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

}