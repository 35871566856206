import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AuthFooter() {
  const { t: i18T } = useTranslation();
  return (
    <Typography
      sx={{
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Microsoft YaHei',
        color: '#ffffff',
      }}
    >
      {i18T('GLOBAL.PLATFORM_FOOTERNAME')}
    </Typography>
  );
}
