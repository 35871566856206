import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungTable from '@Src/_basic/components/YatungTable';

import CreateTagIcon from '@Src/_basic/icons/CreateTag';
import EditIcon from '@Src/_basic/icons/Edit';

import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import { TagData } from '@Src/_basic/object/TagType';
import { TagApi } from '@Src/_basic/protocol/tag/TagApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { useLocation } from 'react-router-dom';
import AddModal from './components/AddModal';
import EditModal from './components/EditModal';

type FilterValueType = {
  factoryId: number;
  areaId: number;
};

type AsyncStorageSavingType = FilterValueType;

export interface SubmitData {
  tagName: string;
  status?: boolean;
}

export interface ResetForm {
  resetForm: () => void;
}

export default function ProportionTagTable() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [selectedFactory, setSelectedFactory] = useState<number>(0);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<TagData>({
    id: 0,
    tagName: '',
    factoryId: 0,
    removed: false,
    lastUpdateTime: 0,
    index: 0,
  });

  const [tagItems, setTagItems] = useState<Array<TagData>>([]); //標籤選項

  const handleSelectedTagChange = useCallback(
    (tag: TagData) => {
      TagApi.getProportionTag(
        {
          factoryId: selectedFactory,
          tagName: tag.tagName,
          id: tag.id,
        },
        (_data) => setSelectedTag(_data),
      );
      setEditModalOpen(true);
    },
    [selectedFactory],
  );

  const getAllTags = useCallback(() => {
    TagApi.getAllProportionTags(
      {
        factoryId: selectedFactory,
      },
      (_data) =>
        setTagItems(
          _data.map((item, index) => ({
            ...item,
            index: index + 1,
            status: true,
            lastUpdated: new Date().getTime(),
          })),
        ),
    );
  }, [selectedFactory]);

  const handleSubmitCreateTag = useCallback(
    (data: SubmitData) => {
      TagApi.postCreateProportionTag(
        {
          factoryId: selectedFactory,
          tagName: data.tagName,
        },
        getAllTags,
      );
      setAddModalOpen(false);
    },
    [selectedFactory, getAllTags],
  );

  const handleSubmitEditTag = useCallback(
    (data: SubmitData) => {
      if (!selectedTag) return;
      const { tagName, status } = data;
      TagApi.putEditProportionTag(
        {
          tagName: tagName,
          removed: status as boolean,
          id: selectedTag.id,
        },
        getAllTags,
      );
      setEditModalOpen(false);
    },
    [getAllTags, selectedTag],
  );

  const handleChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    switch (field) {
      case 'factoryId':
        setSelectedFactory(value as number);
        break;
      case 'areaId':
        setSelectedArea(value as number);
        break;
    }
  };

  useEffect(() => {
    if (selectedArea) {
      if (!selectedFactory) {
        setTagItems([]);
      } else {
        getAllTags();
        setSaveRequest({ page: location.pathname, request: { areaId: selectedArea, factoryId: selectedFactory } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactory, selectedArea, location.pathname]);

  useEffect(() => {
    if (request?.page === location.pathname && request?.request) {
      const { areaId, factoryId } = request.request;
      setSelectedArea(areaId);
      setSelectedFactory(factoryId);
    }
  }, [request, location.pathname]);

  return (
    <YatungPage
      title={i18T('PROPORTION_MANAGEMENT.PROPORTION_TAG_TABLE.TITLE')}
      body={
        <>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <YatungCombinationSelect<FilterValueType>
              filterValue={{ areaId: selectedArea, factoryId: selectedFactory }}
              handleChange={handleChange}
              selectOptions={['AreaAndFactory']}
            />
            <YatungButton
              disabled={!selectedFactory}
              startIcon={<CreateTagIcon />}
              onClick={() => setAddModalOpen(true)}
              sx={{
                backgroundColor: '#00D03A',
                '&:hover': {
                  backgroundColor: '#00D03A',
                },
              }}
            >
              新增標籤
            </YatungButton>
          </Stack>
          {actionLoading ? (
            <YatungLoading overlay={true} />
          ) : (
            <YatungTable
              data={tagItems}
              columns={[
                {
                  text: 'No',
                  dataField: 'index',
                  width: '90px',
                },
                {
                  text: '標籤名稱',
                  dataField: 'tagName',
                  width: '360px',
                },
                {
                  text: '狀態',
                  dataField: 'removed',
                  formatter: ({ cellContent }) => (
                    <Typography sx={{ color: cellContent ? '#CB333B' : '#00D03A' }}>
                      {cellContent ? '停止' : '啟用'}
                    </Typography>
                  ),
                  width: '90px',
                },
                {
                  text: '最近更新',
                  dataField: 'lastUpdateTime',
                  formatter: ({ cellContent }) => {
                    const date = moment(cellContent).format('YYYY/MM/DD');
                    const time = moment(cellContent).format('HH:mm:ss');
                    return (
                      <>
                        {date}
                        <br />
                        {time}
                      </>
                    );
                  },
                  width: '120px',
                },
                {
                  text: '動作',
                  dataField: 'id',
                  formatter: ({ row }) => (
                    <YatungButton
                      startIcon={<EditIcon />}
                      onClick={() => {
                        handleSelectedTagChange(row);
                      }}
                      sx={{
                        backgroundColor: '#FFC700',
                        '&:hover': {
                          backgroundColor: '#FFC700',
                        },
                      }}
                    >
                      編輯
                    </YatungButton>
                  ),
                  width: '90px',
                },
              ]}
            />
          )}
          <AddModal
            open={addModalOpen}
            handleClose={() => setAddModalOpen(false)}
            handleSubmit={handleSubmitCreateTag}
          />
          <EditModal
            open={editModalOpen}
            handleClose={() => setEditModalOpen(false)}
            handleSubmit={handleSubmitEditTag}
            selectedData={selectedTag}
          />
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
