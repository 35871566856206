import { Stack } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentAndGroupsTableType } from '@Src/_basic/object/AccountRightType';
import { useApi } from '@Src/redux/api/apiAction';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import StatusColumnFormatter from './columnFormatter/StatusColumnFormatter';

import DeleteIcon from '@Src/_basic/icons/Delete';
import EditIcon from '@Src/_basic/icons/Edit';

interface Props {
  apiGroupData: Array<ContentAndGroupsTableType>;
  updateAndDeleteModel: (data: ContentAndGroupsTableType, model: 'UPDATE' | 'DELETE') => void;
}

function ApiGroupManagementTable({ apiGroupData, updateAndDeleteModel }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const columns: Array<Column> = [
    {
      text: i18T('GLOBAL.NO'),
      dataField: 'index',
      width: '10%',
    },
    {
      text: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.CODE'),
      dataField: 'code',
      width: '20%',
    },
    {
      text: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.NAME'),
      dataField: 'name',
      width: '20%',
    },
    {
      text: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.EDIT_TIME'),
      dataField: 'updateDt',
      width: '20%',
    },
    {
      text: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.STATUS'),
      dataField: 'state',
      formatter: StatusColumnFormatter,
      formatExtraData: {
        openText: i18T('GLOBAL.OPENING'),
        closeText: i18T('GLOBAL.STOPING'),
      },
      width: '10%',
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      width: '15%',
      formatter: ({ row }) => {
        return (
          <Stack display="flex" flexDirection="row" justifyContent="space-between">
            <YatungButton
              text={i18T('GLOBAL.UPDATE')}
              startIcon={<EditIcon />}
              color="yellow"
              onClick={() => updateAndDeleteModel(row, 'UPDATE')}
            />
            <YatungButton
              text={i18T('GLOBAL.DELETE')}
              startIcon={<DeleteIcon />}
              color="light_red"
              onClick={() => updateAndDeleteModel(row, 'DELETE')}
            />
          </Stack>
        );
      },
    },
  ];

  return <YatungTable loading={actionLoading} columns={columns} data={apiGroupData} />;
}

export default memo(ApiGroupManagementTable);
