import { Box, Stack } from '@mui/material';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { AreaData } from '@Src/_basic/object/AreaType';
import { FactoryItem } from '@Src/_basic/object/FactoryType';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import { ProportionContext } from '@Src/_basic/contexts/ProportionContext';
import { ProportionMaintenanceResponse } from '@Src/_basic/object/ProportionType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import CreatePropotionForm from '../../components/CreatePropotionForm';

interface AreaOptions extends Options {
  factories: Options[];
}

export interface AlertVersionProps {
  propName: string;
  factoryId: number;
}

const ProportionCreate = () => {
  const { t: i18T } = useTranslation();
  const state = useLocation();
  const navigate = useNavigate();
  const { userGroupsData } = useAuth();
  const { createPropportionSelect } = useContext(ProportionContext);

  // 區選項
  const [areaItems, setAreaItems] = useState<Array<AreaOptions>>([]);
  // 廠選項
  const [factoryItems, setFactoryItems] = useState<Array<Options>>([]);
  // 選擇區
  const [selectedArea, setSelectedArea] = useState<Options>({
    value: 0,
    text: '請選擇 - - -',
  });
  // 選擇廠
  const [selectedFactory, setSelectedFactory] = useState<Options>({
    value: 0,
    text: '請選擇 - - -',
  });
  // 控制loading
  const [loading, setLoading] = useState<boolean>(false);
  // 配比維護表
  const [proportionMaintenance, setProportionMaintenance] = useState<ProportionMaintenanceResponse | undefined>();

  // 選擇區
  const handleAreaSelectChange = useCallback(
    (area: number) => {
      setSelectedFactory({
        value: 0,
        text: '請選擇 - - -',
      });
      setProportionMaintenance(undefined);
      const selected = areaItems.find((i: AreaOptions) => i.value === area);
      if (selected) {
        setFactoryItems(selected.factories);
        setSelectedArea({
          value: area,
          text: selected.text,
        });
      }
    },
    [areaItems],
  );

  // 選擇廠
  const handleFactorySelectChange = useCallback(
    (factoryId: number) => {
      const selected = factoryItems.find((i: Options) => i.value === factoryId);
      if (selected) {
        setSelectedFactory({
          value: factoryId,
          text: selected.text,
        });
      }
    },
    [factoryItems, selectedArea],
  );

  // 取得所有區
  useEffect(() => {
    if (!userGroupsData?.areas) return;

    const areaOptions: AreaOptions[] = userGroupsData?.areas.map(({ id, name, factoryList }: AreaData) => ({
      value: id,
      text: name,
      factories: factoryList.map(({ factoryId, factoryName }: FactoryItem) => ({
        value: factoryId,
        text: factoryName,
      })),
    }));
    setAreaItems(areaOptions);
  }, [userGroupsData?.areas]);

  useEffect(() => {
    if (areaItems) {
      if (createPropportionSelect) {
        const prevArea = areaItems.find((item) => createPropportionSelect.areaId === item.value);
        if (prevArea) {
          setFactoryItems(prevArea.factories);
          const prevFactory = prevArea.factories.find((item) => createPropportionSelect.factoryId === item.value);
          setSelectedArea({
            text: prevArea.text,
            value: prevArea.value,
          });
          if (prevFactory) {
            setSelectedFactory({
              text: prevFactory.text,
              value: prevFactory.value,
            });
          }
        }
      } else if (state?.state?.area && state?.state?.factory) {
        const prevArea = areaItems.find((item) => state?.state?.area.value === item.value);
        if (prevArea) {
          setFactoryItems(prevArea.factories);
          const prevFactory = prevArea.factories.find((item) => state?.state?.factory.value === item.value);
          setSelectedArea({
            text: prevArea.text,
            value: prevArea.value,
          });
          if (prevFactory) {
            setSelectedFactory({
              text: prevFactory.text,
              value: prevFactory.value,
            });
          }
        }
      }
    }
  }, [areaItems, createPropportionSelect, state]);

  useEffect(() => {
    if (!selectedFactory.value) return;
    if (state && selectedFactory?.value === state?.state?.afterProportionMaintenance?.factoryId) {
      setProportionMaintenance(state.state.afterProportionMaintenance);
    } else {
      setLoading(true);
      ProportionApi.getDefaultProportionMaintenance(
        { factoryId: selectedFactory.value },
        (_data) => setProportionMaintenance(_data),
        () => setProportionMaintenance(undefined),
        () => setLoading(false),
      );
    }
  }, [selectedFactory.value, state]);

  return (
    <YatungPage
      title={i18T('PROPORTION_MANAGEMENT.PROPORTION_CREATE.TITLE')}
      body={
        <Box>
          <Stack flexDirection="row" justifyContent="space-between" sx={{ my: 2 }}>
            <Stack flexDirection="row" alignItems="center">
              <YatungSelect
                options={areaItems}
                value={selectedArea.value}
                onChange={(e) => handleAreaSelectChange(e.target.value as number)}
                sx={{ mr: 1 }}
              />
              <YatungSelect
                disabled={factoryItems.length === 0}
                options={factoryItems}
                value={selectedFactory.value}
                onChange={(e) => handleFactorySelectChange(e.target.value as number)}
              />
            </Stack>
            <YatungButton color="red" onClick={() => navigate('/proportionManage/proportionList')}>
              返回
            </YatungButton>
          </Stack>
          {proportionMaintenance && selectedFactory && !loading ? (
            <CreatePropotionForm
              proportionMaintenance={proportionMaintenance}
              selectFactory={selectedFactory.value}
              selectArea={selectedArea.value}
            />
          ) : (
            loading && <YatungLoading overlay={false} />
          )}
        </Box>
      }
      contentBgColor="#FFF"
    />
  );
};

export default memo(ProportionCreate);
