import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import CancelIcon from '@Src/_basic/icons/Cancel';
import { LegalStandardData } from '@Src/_basic/object/LegalStandardType';
import { StandardV2Api } from '@Src/_basic/protocol/standardV2/legal/LegalStandardV2Api';
import { Stack, Typography } from '@mui/material';
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import ViewLegalStandardCard from '../components/ViewLegalStandardCard';

const background = '/media/images/applicationsMange/legalStandardBG.png';

export default function ViewLegalStandardScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t: i18T } = useTranslation();
  const [typeId, setTypeId] = useState<number>(0);
  const [legalStandard, setLegalStandard] = useState<LegalStandardData | undefined>(undefined);

  const queryObject = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  const getLegalStandard = (id: number) => {
    StandardV2Api.getLegalStandardById(id, onGetLegalStandardSuccess);
  };

  const onGetLegalStandardSuccess = (data: LegalStandardData) => {
    setLegalStandard(data);
  };

  const handleCancelButtonClick = () => {
    navigate(`/qualityManagement/legalStandards/list?typeId=${queryObject.typeId}`, { replace: true });
  };

  useEffect(() => {
    if (location.search) {
      const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (queryObject.legalStandardId) {
        getLegalStandard(parseInt(queryObject.legalStandardId.toString()));
      }
      if (queryObject.typeId) {
        setTypeId(parseInt(queryObject.typeId.toString()));
      }
    }
  }, [location.search]);

  return (
    <YatungPage
      title={i18T('QUALITY_MANAGE.SIDE_MENU.STANDARDIZED_PENALTY_MANAGEMENT.LEGAL_STANDARD')}
      backgroundImage={background}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} sx={{ py: 1 }} />
            <Stack direction="row" spacing={2} sx={{ py: 1 }}>
              <YatungButton text="返回" startIcon={<CancelIcon />} color="blue" onClick={handleCancelButtonClick} />
            </Stack>
          </Stack>
          {legalStandard && (
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack direction="row" spacing={2} sx={{ py: 1 }} />
              <Stack direction="row" spacing={2} sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontFamily: 'Inter,sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '900',
                    lineHeight: '19px',
                    letterSpacing: '0.255em',
                  }}
                >
                  ID: {legalStandard.code}
                </Typography>
              </Stack>
            </Stack>
          )}
          <ViewLegalStandardCard legalStandard={legalStandard} typeId={typeId} />
        </>
      }
    />
  );
}
