import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import YatungFadeMenu from '@Src/_basic/components/YatungFadeMenu';
import { useAuth } from '@Src/redux/auth/authActions';

export default function ProportionManageMenu() {
  const { t: i18T } = useTranslation();
  const { userApiPermission } = useAuth();
  const [newRouteSideMenu, setNewRouteSideMenu] = React.useState<Array<any>>([]);

  const routeSideMenu = [
    {
      title: i18T('PROPORTION_MANAGEMENT.PROPORTION_LIST.TITLE'),
      toUrl: '/proportionManage/proportionList',
      code: 'WD036',
    },
    {
      title: i18T('PROPORTION_MANAGEMENT.PROPORTION_TAG_TABLE.TITLE'),
      toUrl: '/proportionManage/proportionTagTable',
      code: 'WD037',
    },
  ];

  useEffect(() => {
    const routeSideMenuTemp: Array<any> = [];
    routeSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeSideMenuTemp.push(item);
      }
    });
    setNewRouteSideMenu(routeSideMenuTemp);
  }, [userApiPermission]);
  return (
    <>
      {newRouteSideMenu.length > 0 && (
        <YatungFadeMenu text={i18T('PROPORTION_MANAGEMENT.SIDE_MENU.MENU_TITLE')} option={newRouteSideMenu} />
      )}
    </>
  );
}
