import React from 'react';
import SiteManagementCreateCard from '../components/SiteManagementCreateCard';

export default function SiteManagementCreateScreen() {
  return (
    <>
      <SiteManagementCreateCard />
    </>
  );
}
