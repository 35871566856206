import MonthlyReportIcon from '@Src/_basic/icons/MonthlyReport';
import { FileInfo } from '@Src/_basic/object/FileType';
import { AllTest, TestItemEnum } from '@Src/_basic/object/TestType';
import { GroupDataByCementCompStrResponse } from '@Src/_basic/object/test/cementtitiousPowder/CementCompStrType';
import { GroupDataByFlyAshActivityResponse } from '@Src/_basic/object/test/cementtitiousPowder/FlyAshActivityType';
import { GroupDataBySlagActivityResponse } from '@Src/_basic/object/test/cementtitiousPowder/SlagActivityType';
import { FileApi } from '@Src/_basic/protocol/file/FileApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import TextLine from '@Src/app/module/report/pages/ProductionHistory/screens/components/TextLine';
import { useAuth } from '@Src/redux/auth/authActions';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import moment from 'moment';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChildrenTypography,
  TestCardContainer,
  TestVerifyResultStyles,
  TitleTypography,
} from '../../MuiStyleComponent/TestComponent';
import UploadFileCard from '../../UploadFileCard';
import YatungButton from '../../YatungButton';
import YatungDialog from '../../YatungDialog';
import SwitchTestTable from './SwitchTestTable';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialogContent = styled(DialogContent)(() => ({
  '&::-webkit-scrollbar': {
    width: 12,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#003087',
  },
}));

interface Props {
  exptItemCode: TestItemEnum;
  exptId: number;
  open: boolean;
  closeHandler: () => void;
  allTable?: boolean;
  canEdit?: boolean;
}

const TestDetailDialog = ({ exptItemCode, exptId, open, closeHandler, allTable, canEdit = false }: Props) => {
  const { t: i18T } = useTranslation();
  const [files, setFiles] = useState<FileList>();
  const [testDetail, setTestDetail] = useState<AllTest | undefined | 0>(0);
  const [fileInfoList, setFileInfoList] = useState<Array<FileInfo>>([]);
  const [testDataByCementCompStr, setTestDataByCementCompStr] = useState<
    GroupDataByFlyAshActivityResponse | GroupDataBySlagActivityResponse | Array<GroupDataByCementCompStrResponse>
  >();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined); //刪除檔案的id
  const { userData } = useAuth();

  const handleCreateGoBackButtonClick = () => {
    setCreateModalOpen(false);
  };

  const handleDeleteFileButtonClick = (id: number | undefined) => {
    //開啟刪除提示
    setCreateModalOpen(true);
    setDeleteId(id);
  };

  const handleDeleteCheckButtonClick = (id: number | undefined) => {
    //確認刪除
    FileApi.deleteFile(id, () => {
      refreshFileData();
      setCreateModalOpen(false);
    });
  };

  const refreshFileData = () => {
    //重新整理檔案
    FileApi.getFile({ exptId, exptItemCode }, (_data) => setFileInfoList(_data));
  };

  const list = [''];

  if (files) {
    for (let i = 0; i < files?.length; i++) {
      list.push(i ? `、${files[i]?.name}` : files[i]?.name); //紀錄選擇的檔案
    }
  }

  const handleChange = (event: any) => {
    const files = event.target.files;
    if (files) {
      setFiles(files);
    }
  };

  useEffect(() => {
    handleChange;
  }, [files]);

  const handleUploadFile = () => {
    //上傳檔案
    if (files) {
      if (userData) {
        FileApi.postFile({ exptId, exptItemCode, uploaderId: userData.account }, files, () => {
          refreshFileData();
          setFiles(undefined); //上傳成功後清空選擇的檔案
        });
      }
    }
  };

  const showFiles: JSX.Element = useMemo(
    () => (
      <>
        <TestCardContainer>
          <TitleTypography color={TestVerifyResultStyles.textBlue} my={3}>
            {i18T(`VIEW.DIALOG.FILE`)}:
          </TitleTypography>
          <Stack flexDirection="column" justifyContent="center" alignItems="center" width="100%" gap={5} my={3}>
            {fileInfoList.map((file, index) => {
              let fileContent;
              switch (file.type) {
                case 'IMAGE': //處理圖片
                  fileContent = (
                    <a href={file.path} target={file.path}>
                      <img src={file.path} style={{ width: 500, height: 450 }} />
                    </a>
                  );
                  break;
                case 'DOCUMENT': //處理文件
                  if (file.download) {
                    fileContent = (
                      <a href={file.path} target={file.path}>
                        <TextLine icon={<MonthlyReportIcon stroke="#002F8C" />} content={file.name} />
                      </a>
                    );
                  } else {
                    // 處理不允許下載的情況
                    fileContent = <ChildrenTypography key={index}>{i18T('GLOBAL.DOWNLOAD_DENIED')}</ChildrenTypography>;
                  }
                  break;
                case 'VIDEO': //處理影片
                  fileContent = <video src={file.path} controls style={{ width: 500, objectFit: 'contain' }}></video>;
                  break;
                default:
                  fileContent = <ChildrenTypography key={index}>{i18T('GLOBAL.NO_INFO')}</ChildrenTypography>;
              }
              return (
                <Stack key={index} position="relative">
                  <Stack gap={2} mr={3}>
                    <Stack style={{ display: 'flex', flexDirection: 'row' }}>
                      <Stack style={{ fontSize: '20px', color: '#003087', marginRight: '5px' }}>{index + 1}.</Stack>
                      {fileContent}
                      <Button
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          textAlign: 'center',
                          verticalAlign: 'middle',
                          lineHeight: '20px',
                          maxHeight: '40px',
                        }} //可刪除
                        onClick={() => handleDeleteFileButtonClick(file.id)}
                      >
                        X
                      </Button>
                    </Stack>
                    {!!testDetail && (
                      <ChildrenTypography alignSelf="flex-end">
                        {`(${testDetail?.operatorName}/${moment(file.createTime).format('yyyy.MM.DD')})`}
                      </ChildrenTypography>
                    )}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </TestCardContainer>
      </>
    ),
    [fileInfoList, testDetail, i18T],
  );

  useEffect(() => {
    TestApi.getTestDetail(
      { exptId, exptItemCode },
      (_data) => {
        if (_data.completed) setTestDetail(_data);
        else {
          setTestDetail(undefined);
          setTimeout(() => closeHandler(), 3000);
        }
      },
      (err) => console.log(err),
    );

    FileApi.getFile({ exptId, exptItemCode }, (_data) => setFileInfoList(_data));

    switch (exptItemCode) {
      case TestItemEnum.FLY_ASH_ACTIVITY_TEST:
        TestApi.getGroupDataByFlyAshActivity({ exptId }, (data) => setTestDataByCementCompStr(data));
        break;
      case TestItemEnum.SLAG_ACTIVITY_TEST:
        TestApi.getGroupDataBySlagActivity({ exptId }, (data) => setTestDataByCementCompStr(data));
        break;
      case TestItemEnum.CEMENT_COMP_STR_TEST:
        TestApi.getGroupDataByCementCompStr({ exptId }, (data) => setTestDataByCementCompStr(data));
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exptId, exptItemCode]);

  return (
    <Dialog fullScreen open={open} onClose={closeHandler} TransitionComponent={Transition} sx={{ py: 2, px: 10 }}>
      <CustomDialogContent>
        <AppBar sx={{ position: 'absolute', width: '100%', bgcolor: '#003087' }}>
          <Toolbar
            sx={{
              bgcolor: '#003087',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
              sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}
            >
              <CloseIcon />
            </IconButton>
            {testDetail && (
              <Typography
                sx={{
                  flex: 1,
                  fontSize: 24,
                  transform: testDetail?.exptItemCode ? 'translateX(-15%)' : 'translateX(-4%)',
                }}
              >
                {testDetail?.exptItemCode ? i18T(`TEST.TEST_ITEM.${testDetail.exptItemCode}`) : i18T('GLOBAL.NO_INFO')}
              </Typography>
            )}
          </Toolbar>
        </AppBar>

        <Stack gap={2} pt={10} pb={4}>
          {typeof testDetail === 'undefined' ? (
            <Typography sx={{ textAlign: 'center' }}>{i18T(`VIEW.DIALOG.UNDEFINED_FILE`)}...</Typography>
          ) : (
            <SwitchTestTable
              testDetail={testDetail as AllTest}
              testDataByCementCompStr={testDataByCementCompStr}
              allTable={allTable}
              canEdit={canEdit}
            />
          )}

          {!!testDetail && testDetail?.completeTime && (
            <TestCardContainer>
              <Stack flexDirection="column" gap={2}>
                <TitleTypography color={TestVerifyResultStyles.textBlue}>{`${i18T(
                  `TEST.REMARK.TITLE`,
                )}:`}</TitleTypography>
                <Stack
                  sx={{
                    border: '1px solid gray',
                    minHeight: 100,
                    letterSpacing: 1.5,
                    mb: 3,
                  }}
                >
                  <ChildrenTypography sx={{ flexWrap: 'wrap', p: 2, wordWrap: 'break-word' }}>
                    {testDetail?.reportComment}
                  </ChildrenTypography>
                  {testDetail?.reportComment && (
                    <ChildrenTypography
                      sx={{
                        flexWrap: 'wrap',
                        wordWrap: 'break-word',
                        alignSelf: 'flex-end',
                        mt: 10,
                      }}
                    >
                      {`(${testDetail?.operatorName}/${moment(new Date(testDetail?.completeTime)).format(
                        'YYYY/MM/DD HH:mm',
                      )})`}
                    </ChildrenTypography>
                  )}
                </Stack>
              </Stack>
            </TestCardContainer>
          )}
          <TestCardContainer>
            <Stack flexDirection="column" gap={2}>
              <TitleTypography color={TestVerifyResultStyles.textBlue}>
                {`${i18T(`VIEW.DIALOG.FILE_MANAGE`)}:`}
                {files && (
                  <Stack flexDirection={'row'} sx={{ my: 1 }}>
                    <div style={{ fontWeight: 'bold' }}>{i18T(`VIEW.DIALOG.ADD_FILE`)}：</div>
                    {/* 讓使用者預覽點擊uploadFileCard後已選擇檔案，可多選，暫未做出刪除功能*/}
                    {list}
                  </Stack>
                )}
                <UploadFileCard handleChange={handleChange} />
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                  <YatungButton
                    text={i18T('GLOBAL.UPLOAD')}
                    type="submit"
                    onClick={handleUploadFile}
                    sx={{
                      border: '1px solid #fff',
                      color: '#ffffff',
                      bgcolor: '#002F8C',
                      fontSize: '24px',
                      marginTop: '10px',
                      width: 120,
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: '#002F8C',
                      },
                    }}
                  />
                </Stack>
              </TitleTypography>
            </Stack>
          </TestCardContainer>

          {fileInfoList.length > 0 && showFiles}
        </Stack>
      </CustomDialogContent>

      <YatungDialog
        open={createModalOpen}
        handleClose={handleCreateGoBackButtonClick}
        icon={<MonthlyReportIcon sx={{ width: 40, height: 50, mr: 1, alignSelf: 'center' }} />}
        headerComponent={
          <>
            <Stack flexDirection={'row'} justifyContent={'center'} display={'flex'}>
              <Typography sx={{ fontSize: '44px', pl: 1 }}>{i18T(`VIEW.DIALOG.DELETE_FILE`)}</Typography>
            </Stack>
          </>
        }
        body={
          <>
            <Stack display={'flex'} justifyContent={'center'}>
              <Stack flexDirection={'row'} justifyContent={'center'}>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', pl: 1, margin: '10px' }}>
                  {i18T('GLOBAL.CONFIRM_DELETE')}？
                </Typography>
              </Stack>

              <Stack
                spacing={3}
                sx={{ bgcolor: 'white', minWidth: 500, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
                display="flex"
                flexDirection="column"
              >
                <Stack alignItems="center" justifyContent="center" flexDirection="row">
                  <YatungButton
                    text={i18T('SAMPLEMANAGEMENT.CANCEL')}
                    onClick={handleCreateGoBackButtonClick}
                    sx={{
                      color: '#ffffff',
                      bgcolor: '#CB333B',
                      fontSize: '24px',
                      width: 120,
                      mr: 1,
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: '#CB333B',
                      },
                    }}
                  />
                  <YatungButton
                    text={i18T('GLOBAL.SURE')}
                    type="submit"
                    onClick={() => handleDeleteCheckButtonClick(deleteId)}
                    sx={{
                      border: '1px solid #fff',
                      color: '#ffffff',
                      bgcolor: '#002F8C',
                      fontSize: '24px',
                      width: 120,
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: '#002F8C',
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </>
        }
      />
    </Dialog>
  );
};

export default memo(TestDetailDialog);
