import { Typography } from '@mui/material';
import React, { memo } from 'react';

interface Props {
  text: string;
  style?: React.CSSProperties;
}

const YatungText = ({ text, style }: Props) => {
  return (
    <Typography sx={{ fontSize: 24, letterSpacing: 1, fontWeight: 900, color: '#003087', ...style }}>{text}</Typography>
  );
};

export default memo(YatungText);
