import ContentMangemenIcon from '@Src/_basic/icons/ContentMangement';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MonthlyReport from './components/MonthlyReport';
import ProductionHistory from './components/ProductionHistory';
import SieveReport from './components/SieveReport';

export default function ReportMenu() {
  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack alignItems="center" justifyContent="flex-start" flexDirection="row" sx={{ px: 1, color: 'white' }}>
        <Stack alignItems="center" justifyContent="center" sx={{ width: '40px' }}>
          <ContentMangemenIcon />
        </Stack>
        <Typography sx={{ fontSize: 24, fontWeight: 900 }}>{i18T('REPORT.SIDE_MENU.TITLE')}</Typography>
      </Stack>
      <SieveReport />
      <MonthlyReport />
      <ProductionHistory />
    </>
  );
}
