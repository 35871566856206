import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Field } from 'formik';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import YatungDivider from '@Src/_basic/components/YatungDivider';

export default function BasicWeightMeasuresField() {
  const { t: i18T } = useTranslation();
  return (
    <>
      <Stack
        direction={'column'}
        justifyContent={'space-between'}
        sx={{ border: '1px solid #6C708C', mt: 1, borderRadius: '8px', px: 4, pt: 1, pb: 5 }}
      >
        <Stack direction={'row'} spacing={12} sx={{ my: 2 }}>
          <Stack direction={'row'} sx={{ width: '80%' }}>
            <YatungFormLabel
              label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.UNIT')}
              sx={{ color: '#003087', fontSize: 20, fontWeight: 900 }}
            />
            <YatungFormLabel
              label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.UNIT_NUM')}
              sx={{ color: '#FF000D', fontSize: 12, fontWeight: 900 }}
            />
          </Stack>
          <YatungFormLabel
            label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.VALUE')}
            sx={{ color: '#003087', fontSize: 20, fontWeight: 900 }}
          />
          <YatungFormLabel label={''} />
        </Stack>
        <YatungDivider borderStyle />

        <Stack direction={'row'} spacing={2} sx={{ my: 2 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.TON')} />
          <Field
            id="ton"
            name="ton"
            type="number"
            label=""
            component={YatungFormInput}
            sx={{ width: '80%' }}
            disabled
            required
          />
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.KG')} />
        </Stack>
        <YatungDivider borderStyle />

        <Stack direction={'row'} spacing={2} sx={{ my: 2 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.METER')} />
          <Field
            id="meter"
            name="meter"
            type="number"
            label=""
            component={YatungFormInput}
            sx={{ width: '80%' }}
            disabled
            required
          />
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.CM_CHINESE')} />
        </Stack>
        <YatungDivider borderStyle />

        <Stack direction={'row'} spacing={2} sx={{ my: 2 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.KM')} />
          <Field
            id="kilometer"
            name="kilometer"
            type="number"
            label=""
            component={YatungFormInput}
            sx={{ width: '80%' }}
            disabled
            required
          />
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.METER')} />
        </Stack>
        <YatungDivider borderStyle />

        <Stack direction={'row'} spacing={2} sx={{ my: 2 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.CM')} />
          <Field
            id="cm"
            name="cm"
            type="number"
            label=""
            component={YatungFormInput}
            sx={{ width: '80%' }}
            disabled
            required
          />
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.MM')} />
        </Stack>
        <YatungDivider borderStyle />

        <Stack direction={'row'} spacing={2} sx={{ my: 2 }}>
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.PSI')} />
          <Field
            id="psi"
            name="psi"
            type="number"
            label=""
            component={YatungFormInput}
            sx={{ width: '80%' }}
            disabled
            required
          />
          <YatungFormLabel label={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.KGF')} />
        </Stack>
        <YatungDivider borderStyle />
      </Stack>
      <Stack direction={'row'} justifyContent={'center'}>
        <Typography sx={{ fontSize: 20, fontWeight: 900, color: '#CB333B' }}>{'此單位表為全廠共用'}</Typography>
      </Stack>
    </>
  );
}
