import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOrganize } from '@Src/redux/organize/organizeActions';
import OrganizeManagementDeleteSubmit from './OrganizeManagementDeleteSubmit';

interface Props {
  onGoBackButtonClick: () => void;
  targetId: number;
  targetName: string;
  setAlertIsOpen: (e: boolean) => void;
  setSetAlertTitle: (e: string) => void;
}
export default function OrganizeManagementDeleteMessage(props: Props) {
  const { t: i18T } = useTranslation();
  const { onGoBackButtonClick, targetId, targetName, setAlertIsOpen, setSetAlertTitle } = props;
  return (
    <>
      <Typography sx={{ fontSize: 40, fontFamily: 'Inter', fontWeight: 400, textAlign: 'center', my: 2 }}>
        {i18T('ORGANIZEMANAGEMENT.MESSAGEDIALOG_CONTENT_TEXT')}
      </Typography>
      <Typography sx={{ fontSize: 40, fontFamily: 'Inter', fontWeight: 400, textAlign: 'center' }}>
        {`${targetName}${i18T('GLOBAL.DELETE')}`}
      </Typography>
      <Grid container justifyContent={'center'} sx={{ mt: 2 }}>
        <OrganizeManagementDeleteSubmit onGoBackButtonClick={onGoBackButtonClick}
        targetId={targetId}
        setAlertIsOpen={setAlertIsOpen}
        setSetAlertTitle={setSetAlertTitle} />
      </Grid>
    </>
  );
}
