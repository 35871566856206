import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import BackButton from '@Src/_basic/icons/BackButton';
import { MonitorFactoryData } from '@Src/_basic/object/MonitorType';
import { MonitorApi } from '@Src/_basic/protocol/monitor/MonitorApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useMonitor } from '@Src/redux/monitor/monitorAction';
import { Stack } from '@mui/material';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import MonitorByFactoryCard from '../components/MonitorByFactoryCard';

export default function MonitorByFactoryScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true }); //從地址獲取areaId參數

  const [monitorFactory, setMonitorFactory] = useState<MonitorFactoryData[] | undefined>(undefined);

  const { setDate, date } = useMonitor();

  const { userAreaOptionsData, userGroupsData } = useAuth();

  const handleDateChange = (value: unknown) => {
    setDate(value);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const getMonitorFactory = (id: number) => {
    MonitorApi.getMonitorFactory(
      id,
      { date: moment(date).format('YYYY-MM-DD'), factoryIds: userGroupsData?.factories.map((item) => item.value) },
      getIncludeFactory,
    );
  };

  const getIncludeFactory = (data: Array<MonitorFactoryData>) => {
    const factoryData = userAreaOptionsData.filter((item: any) => item.text === queryObject.area);
    const findFactoryData = factoryData.map((item: any) => item.factories);
    const factoryIds: Array<number> = [];
    findFactoryData.forEach((item: any) => {
      item.forEach((item: any) => {
        factoryIds.push(item.value);
      });
    });
    const newData = data.filter((item) => factoryIds.includes(item.factoryId));
    setMonitorFactory(newData);
  };

  useEffect(() => {
    if (location.search) {
      if (queryObject.areaId) {
        getMonitorFactory(parseInt(queryObject.areaId.toString()));
      }
    }
  }, [date]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.PAGE_TITLE') + `(${queryObject.area})`}
      body={
        <>
          <Stack direction="row" spacing={2} sx={{ py: 1 }} justifyContent="space-between">
            <Stack>
              <YatungDateSelect
                value={date}
                disableFuture={true}
                onChange={(val) => val && handleDateChange(new Date(val).getTime())}
              />
            </Stack>
            <YatungButton
              text={i18T('GLOBAL.BACK_PAGE')}
              color="green"
              onClick={handleGoBack}
              startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
              sx={{ alignSelf: 'center' }}
            />
          </Stack>
          <MonitorByFactoryCard factoryData={monitorFactory} />
        </>
      }
      contentBgColor="#fff"
    />
  );
}
