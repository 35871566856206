import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Clock(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2s10,4.5,10,10S17.5,22,12,22z M12,2.7c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3c5.1,0,9.3-4.2,9.3-9.3C21.3,6.9,17.1,2.7,12,2.7z" />
      <path d="M12,7.7h-1.4v7.1h0.2l6.7,1.9l0.4-1.4L12,13.7V7.7z" />
    </SvgIcon>
  );
}
