import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';
import TestDetailDialog from '@Src/_basic/components/YatungTest/testDetail/TestDetailDialog';
import DetailIcon from '@Src/_basic/icons/Detail';
import { QrcodeData } from '@Src/_basic/object/QrcodeType';
import { AllTest, Record, TestItemEnum } from '@Src/_basic/object/TestType';
import { QrcodeApi } from '@Src/_basic/protocol/qrcode/QrcodeApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: Array<Record>;
  testType?: number;
}

const RecordStatusListTable = ({ data, testType }: Props) => {
  const { actionLoading } = useApi();
  const { t: i18T } = useTranslation();

  const [open, setOpen] = useState(false);
  const [recordDetail, setRecordDetail] = useState<Record>();

  const _dataDetailMap = new Map<string, AllTest | QrcodeData>();

  useEffect(() => {
    data.forEach((item) => {
      if (testType === 4) {
        TestApi.getTestDetail(
          {
            exptId: item.id,
            exptItemCode: item.exptItemCode,
          },
          (_data) => _dataDetailMap.set(`${item.exptItemCode}_${item.id}`, _data as AllTest),
        );
      } else {
        switch (item.exptItemCode) {
          case TestItemEnum.CHEM_ADMX_COMP_STR:
          case TestItemEnum.CHEM_ADMX_PENTA_TEST:
          case TestItemEnum.MIXING_WATER_CHL_CONTENT:
          case TestItemEnum.RECLAIMED_WATER_CHL_CONTENT:
          case TestItemEnum.RECLAIMED_WATER_SOLID_CONTENT:
          case TestItemEnum.RECLAIMED_WATER_SPECIFIC_WEIGHT:
            break;
          default:
            QrcodeApi.getQrcodeData(
              {
                ...(item?.sampleId && { sampleId: item?.sampleId }),
                ...(item?.materialReceiptId && { materialReceiptId: item?.materialReceiptId }),
                ...(item?.specimenId && { specimenId: item?.specimenId }),
              },
              (_data) => _dataDetailMap.set(`${item.exptItemCode}_${item.id}`, _data as QrcodeData),
            );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testType, data]);

  const openHandler = useCallback((row: Record) => {
    console.log(row)
    setRecordDetail(row);
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setRecordDetail(undefined);
  }, []);

  return (
    <>

      <YatungTable
        loading={actionLoading}
        data={data}
        columns={[
          {
            text: i18T('VIEW.STATUS_TABLE.CREATE_TYPE'),
            dataField: 'createTypeName',
            width: '150px',
          },
          {
            text: i18T('VIEW.STATUS_TABLE.CREATE_DATE'),
            dataField: 'createTime',
            width: '150px',
            formatter: ({ row }) => {
              return (
                <Stack direction="column">
                  <Typography>{`${moment(row.createTime).format('YYYY/MM/DD')}`}</Typography>
                </Stack>
              );
            },
          },
          {
            text: i18T('VIEW.STATUS_TABLE.AREA'),
            dataField: 'areaName',
            width: '120px',
          },
          {
            text: i18T('VIEW.STATUS_TABLE.FACTORY'),
            dataField: 'factoryName',
            width: '120px',
          },
          {
            text: i18T('VIEW.STATUS_TABLE.STATUS'),
            dataField: 'status',
            width: '120px',
          },
          {
            text: i18T('VIEW.STATUS_TABLE.CREATER'),
            dataField: 'creater',
            width: '120px',
          },
          {
            text: i18T('VIEW.STATUS_TABLE.OPERATOR'),
            dataField: 'operator',
            width: '120px',
          },
          {
            text: i18T('VIEW.STATUS_TABLE.TEST_TYPE'),
            dataField: 'exptTypeName',
            width: '120px',
          },
          {
            text: i18T('VIEW.STATUS_TABLE.TEST_ITEM'),
            dataField: 'exptItemCode',
            width: '120px',
            formatter: ({ row }) => {
              return (
                <Stack direction="column">
                  <Typography>{i18T(`TEST.TEST_ITEM.${row.exptItemCode}`)}</Typography>
                </Stack>
              );
            },
          },
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            width: '90px',
            formatter: ({ row }) => {
              return (
                <Stack direction="row" spacing={1}>
                  {row.status === "未完成" || row.status === "逾時" ? (
                    <YatungButton
                      disabled
                      text={i18T('GLOBAL.DETAIL')}
                      startIcon={<DetailIcon />}
                      color="yellow"
                      onClick={() => openHandler(row)}
                    />) : (
                    <YatungButton
                      text={i18T('GLOBAL.DETAIL')}
                      startIcon={<DetailIcon />}
                      color="yellow"
                      onClick={() => openHandler(row)}
                    />)
                  }
                </Stack>
              );
            },
          }
        ]}
      />



      {recordDetail && (
        <TestDetailDialog
          exptItemCode={recordDetail.exptItemCode}
          exptId={recordDetail.exptId}
          open={open}
          closeHandler={closeHandler}
          allTable={true}
          canEdit={true}
        />
      )}
    </>
  );
};

export default memo(RecordStatusListTable);
