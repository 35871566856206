import { Response } from '@Src/_basic/object/ApiType';
import { getBomHierarchyParams } from '@Src/_basic/object/MaterialFactoryCodeType';
import {
  PageableSignature,
  SearchPageableSignatureParams,
  SearchSignatureParams,
  SignatureData,
  UpdateSignatureRequest,
} from '@Src/_basic/object/SignatureType';
import { MaterialFactoryCodeApi } from '@Src/_basic/protocol/materialFactoryCode/MaterialFactoryCodeApi';
import { SignatureApi } from '@Src/_basic/protocol/signature/SignatureApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { signatureSlice } from './signatureSlice';

export function useSignature() {
  const { signature, signatures, pageableSignatures, bomHierarchy } = useSelector(
    (state: RootState) => state.signature,
  );

  const dispatch = useDispatch();

  const setSignature = (data: SignatureData) => dispatch(signatureSlice.actions.setSignature(data));

  const setSignatures = (data: Array<SignatureData>) => dispatch(signatureSlice.actions.setSignatures(data));

  const setPageableSignature = (data: PageableSignature) => dispatch(signatureSlice.actions.setPageableSignature(data));

  const getSignatureById = (id: number) => {
    SignatureApi.getSignatureById(id, setSignature);
  };

  const getSignaturesBySearch = (params: SearchSignatureParams) => {
    SignatureApi.getSignaturesBySearch(params, setSignatures);
  };

  const getPageableSignaturesBySearch = (params: SearchPageableSignatureParams) => {
    SignatureApi.getPageableSignaturesBySearch(params, setPageableSignature);
  };

  const getBomHierarchy = (params: getBomHierarchyParams) => {
    MaterialFactoryCodeApi.getBomHierarchy(params, (data: any) => {
      dispatch(signatureSlice.actions.setBomHierarchy(data));
    });
  };

  const updateSignature = (
    request: UpdateSignatureRequest,
    onSuccess?: (data: SignatureData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) => {
    SignatureApi.updateSignature(
      request,
      (data: SignatureData) => {
        setSignature(data);
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const clearSignature = () => dispatch(signatureSlice.actions.clearSignature());

  return {
    signature,
    signatures,
    pageableSignatures,
    getSignatureById,
    getSignaturesBySearch,
    getPageableSignaturesBySearch,
    updateSignature,
    clearSignature,
    bomHierarchy,
    getBomHierarchy,
  };
}
