import { Protocol } from '../Protocol';

export class LanguageProtocol {
  public static readonly GET_LANG: string = `${Protocol.YATUNG_API_ROOT}/lang/all`;
  public static readonly UPDATE_LANG: string = `${Protocol.YATUNG_API_ROOT}/lang`;
  public static readonly DELETE_LANG: string = `${Protocol.YATUNG_API_ROOT}/lang`;

  public static readonly POST_LANG_FILE: string = `${Protocol.YATUNG_API_ROOT}/lang/file`;
  public static readonly GET_FILE_CONTENT: string = `${Protocol.YATUNG_API_ROOT}/lang/file/content`;
  public static readonly GET_ALL_FILE: string = `${Protocol.YATUNG_API_ROOT}/lang/file/all`;
  public static readonly PUT_REPLACE_FILE: string = `${Protocol.YATUNG_API_ROOT}/lang/file/replace`;
}
