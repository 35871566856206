import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PlateNumber(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="64" height="36" viewBox="0 0 64 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1698 0.626996H4.83019C2.496 0.626996 0.603774 2.59201 0.603774 5.01597V30.984C0.603774 33.408 2.496 35.373 4.83019 35.373H59.1698C61.504 35.373 63.3962 33.408 63.3962 30.984V5.01596C63.3962 2.592 61.504 0.626996 59.1698 0.626996ZM4.83019 0C2.16255 0 0 2.24573 0 5.01597V30.984C0 33.7543 2.16255 36 4.83019 36H59.1698C61.8375 36 64 33.7543 64 30.984V5.01596C64 2.24572 61.8375 0 59.1698 0H4.83019Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.8056 3.0256H7.19464C4.86337 3.0256 2.9735 5.01564 2.9735 7.47047V28.5108C2.9735 30.9656 4.86337 32.9556 7.19464 32.9556H56.8056C59.1369 32.9556 61.0267 30.9656 61.0267 28.5108V7.47046C61.0267 5.01564 59.1369 3.0256 56.8056 3.0256ZM7.19464 2.39062C4.53033 2.39062 2.37048 4.66495 2.37048 7.47047V28.5108C2.37048 31.3163 4.53034 33.5906 7.19464 33.5906H56.8056C59.4699 33.5906 61.6297 31.3163 61.6297 28.5108V7.47046C61.6297 4.66495 59.4699 2.39062 56.8056 2.39062H7.19464Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29445 10.7903C9.60117 10.7903 10.6605 9.71773 10.6605 8.39468C10.6605 7.07162 9.60117 5.99908 8.29445 5.99908C6.98773 5.99908 5.92842 7.07162 5.92842 8.39468C5.92842 9.71773 6.98773 10.7903 8.29445 10.7903ZM8.29445 11.9947C10.2581 11.9947 11.85 10.3829 11.85 8.39468C11.85 6.40645 10.2581 4.79468 8.29445 4.79468C6.33077 4.79468 4.73889 6.40645 4.73889 8.39468C4.73889 10.3829 6.33077 11.9947 8.29445 11.9947Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8505 11.9257C12.0136 11.9647 12.1834 11.9853 12.3578 11.9853H16.6803C17.9402 11.9853 18.9616 10.9108 18.9616 9.5853C18.9616 8.25982 17.9402 7.1853 16.6803 7.1853H12.3578C12.2419 7.1853 12.128 7.1944 12.0167 7.21195C12.4575 7.84066 12.718 8.61782 12.718 9.45899C12.718 10.4011 12.3913 11.2628 11.8505 11.9257Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7046 10.7903C54.3979 10.7903 53.3386 9.71773 53.3386 8.39468C53.3386 7.07162 54.3979 5.99908 55.7046 5.99908C57.0113 5.99908 58.0706 7.07162 58.0706 8.39468C58.0706 9.71773 57.0113 10.7903 55.7046 10.7903ZM55.7046 11.9947C53.7409 11.9947 52.149 10.3829 52.149 8.39468C52.149 6.40645 53.7409 4.79468 55.7046 4.79468C57.6683 4.79468 59.2601 6.40645 59.2601 8.39468C59.2601 10.3829 57.6683 11.9947 55.7046 11.9947Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1486 11.9257C51.9854 11.9647 51.8156 11.9853 51.6412 11.9853H47.3187C46.0588 11.9853 45.0374 10.9108 45.0374 9.5853C45.0374 8.25982 46.0588 7.1853 47.3187 7.1853H51.6412C51.7571 7.1853 51.871 7.1944 51.9823 7.21195C51.5415 7.84066 51.281 8.61782 51.281 9.45899C51.281 10.4011 51.6078 11.2628 52.1486 11.9257Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29445 29.9867C9.60117 29.9867 10.6605 28.9141 10.6605 27.5911C10.6605 26.268 9.60117 25.1955 8.29445 25.1955C6.98773 25.1955 5.92842 26.268 5.92842 27.5911C5.92842 28.9141 6.98773 29.9867 8.29445 29.9867ZM8.29445 31.1911C10.2581 31.1911 11.85 29.5793 11.85 27.5911C11.85 25.6029 10.2581 23.9911 8.29445 23.9911C6.33077 23.9911 4.73889 25.6029 4.73889 27.5911C4.73889 29.5793 6.33077 31.1911 8.29445 31.1911Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8505 28.7315C12.0136 28.7705 12.1834 28.7911 12.3578 28.7911H16.6803C17.9402 28.7911 18.9616 27.7166 18.9616 26.3911C18.9616 25.0656 17.9402 23.9911 16.6803 23.9911H12.3578C12.2419 23.9911 12.128 24.0002 12.0167 24.0177C12.4575 24.6465 12.718 25.4236 12.718 26.2648C12.718 27.2068 12.3913 28.0686 11.8505 28.7315Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7046 29.9867C54.3979 29.9867 53.3386 28.9141 53.3386 27.5911C53.3386 26.268 54.3979 25.1955 55.7046 25.1955C57.0113 25.1955 58.0706 26.268 58.0706 27.5911C58.0706 28.9141 57.0113 29.9867 55.7046 29.9867ZM55.7046 31.1911C53.7409 31.1911 52.149 29.5793 52.149 27.5911C52.149 25.6029 53.7409 23.9911 55.7046 23.9911C57.6683 23.9911 59.2601 25.6029 59.2601 27.5911C59.2601 29.5793 57.6683 31.1911 55.7046 31.1911Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1486 28.7315C51.9854 28.7705 51.8156 28.7911 51.6412 28.7911H47.3187C46.0588 28.7911 45.0374 27.7166 45.0374 26.3911C45.0374 25.0656 46.0588 23.9911 47.3187 23.9911H51.6412C51.7571 23.9911 51.871 24.0002 51.9823 24.0177C51.5415 24.6465 51.281 25.4236 51.281 26.2648C51.281 27.2068 51.6078 28.0686 52.1486 28.7315Z"
        fill="#6C708C"
      />
    </SvgIcon>
  );
}
