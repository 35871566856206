import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { memo, useMemo } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';

import CloseIcon from '@Src/_basic/icons/Close';
import ViewIcon from '@Src/_basic/icons/View';
import { useApi } from '@Src/redux/api/apiAction';
import { useTranslation } from 'react-i18next';
import FactoriesNameFormatter from './columnFormatter/FactoriesNameFormatter';

function CustomStandardsTable({ onViewDetail, isActivatedCustomStandard, onToggleEnableStatus, rows }: any) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const columns = useMemo(
    () => [
      {
        text: '#',
        dataField: 'id',
      },
      {
        text: i18T('QUALITY_MANAGE.STANDARD.TABLE_HEADER.NAME'),
        dataField: 'name',
        formatter: ({ row }: any) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>{row.name}</Typography>
              {isActivatedCustomStandard(row.id) && <Typography color="#00D03A">(使用中)</Typography>}
            </Stack>
          );
        },
      },
      {
        text: i18T('QUALITY_MANAGE.STANDARD.TABLE_HEADER.FACTORIES'),
        dataField: 'factories',
        formatter: FactoriesNameFormatter,
      },
      {
        text: i18T('QUALITY_MANAGE.STANDARD.TABLE_HEADER.TYPE'),
        dataField: 'experimentResultType',
        formatter: ({ row }: any) => {
          return <>{row.experimentResultType?.name}</>;
        },
      },
      {
        text: i18T('QUALITY_MANAGE.STANDARD.TABLE_HEADER.START_DATE'),
        dataField: 'startDate',
        formatter: ({ row }: any) => {
          return <>{moment(row.startDate).format('YYYY/MM/DD HH:mm')}</>;
        },
      },
      {
        text: i18T('QUALITY_MANAGE.STANDARD.TABLE_HEADER.END_DATE'),
        dataField: 'endDate',
        formatter: ({ row }: any) => {
          return <>{moment(row.endDate).format('YYYY/MM/DD HH:mm')}</>;
        },
      },
      {
        text: i18T('QUALITY_MANAGE.STANDARD.TABLE_HEADER.DISABLED'),
        dataField: 'disabled',
        formatter: ({ row }: any) => {
          return <Typography color={row.disabled ? '#CB333B' : '#00D03A'}>{row.disabled ? '關閉' : '生效'}</Typography>;
        },
      },
      {
        text: i18T('QUALITY_MANAGE.STANDARD.TABLE_HEADER.ACTIONS'),
        dataField: 'action',
        formatter: ({ row }: any) => {
          return (
            <Stack direction="row" spacing={1}>
              <YatungButton
                text={i18T('QUALITY_MANAGE.STANDARD.TABLE_ACTION.VIEW')}
                startIcon={<ViewIcon />}
                color="yellow"
                onClick={() => {
                  onViewDetail(row);
                }}
              />
              <YatungButton
                text={
                  row.disabled
                    ? i18T('QUALITY_MANAGE.STANDARD.TABLE_ACTION.OPEN')
                    : i18T('QUALITY_MANAGE.STANDARD.TABLE_ACTION.CLOSE')
                }
                startIcon={<CloseIcon />}
                color={row.disabled ? 'green' : 'red'}
                onClick={() => {
                  onToggleEnableStatus(row);
                }}
              />
            </Stack>
          );
        },
      },
    ],
    [onToggleEnableStatus, onViewDetail, isActivatedCustomStandard, i18T],
  );

  return <YatungTable loading={actionLoading} data={rows} columns={columns} />;
}

export default memo(CustomStandardsTable);
