import { Response } from '@Src/_basic/object/ApiType';
import { PostMaterialNumberRequest, PutMaterialNumberRequest } from '@Src/_basic/object/MaterialNumberManageType';
import { SupplierData, SearchSupplierParams } from '@Src/_basic/object/SupplierType';
import { Api } from '../Api';
import { SupplierProtocol } from './SupplierProtocol';

export class SupplierApi {
  // 取得所有供應商
  public static getAllSuppliers(
    onSuccess?: (data: Array<SupplierData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(SupplierProtocol.ALL_SUPPLIERS, undefined, onSuccess, onFailed, onFinally);
  }

  public static getSuppliersBySearch(
    params: SearchSupplierParams,
    onSuccess?: (data: Array<SupplierData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SupplierProtocol.ALL_SUPPLIERS, config, onSuccess, onFailed, onFinally);
  }

  // 更新供應商
  public static putSupplier(
    request: PutMaterialNumberRequest,
    onSuccess?: (data: SupplierData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(SupplierProtocol.SUPPLIER_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增供應商
  public static postSupplier(
    request: PostMaterialNumberRequest,
    onSuccess?: (data: SupplierData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SupplierProtocol.SUPPLIER_URL, request, undefined, onSuccess, onFailed, onFinally);
  }
}
