import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungTable from '@Src/_basic/components/YatungTable';

import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import EditIcon from '@Src/_basic/icons/Edit';

import { customSort } from '@Src/_basic/helpers/SortHelper';
import { PostMaterialNumberRequest, PutMaterialNumberRequest } from '@Src/_basic/object/MaterialNumberManageType';
import { SourceData } from '@Src/_basic/object/SourceType';
import { TypeData } from '@Src/_basic/object/TypeType';
import { SourceApi } from '@Src/_basic/protocol/source/SourceApi';
import { useTranslation } from 'react-i18next';
import AddModal from './AddModal';
import EditModal, { SelectedItemType } from './EditModal';

const specIcon = '/media/images/icon_spec.png';

interface Props {
  filterType: number;
  types: TypeData[];
}

const ModalIcon = () => (
  <YatungImg
    img={specIcon}
    sx={{
      width: 48,
      height: 48,
      mr: 1,
    }}
  />
);

export default function SourcesPanel({ filterType, types }: Props) {
  const { t: i18T } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [sources, setSources] = useState<Array<SelectedItemType>>([]);
  const [filteredSources, setFilteredSources] = useState<Array<SelectedItemType>>([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [selectedSource, setSelectedSource] = useState<SelectedItemType>();

  const fetchAllSources = () => {
    SourceApi.getSourcesBySearch(undefined, onFetchAllSourcesSuccess, undefined, () => setLoading(false));
  };

  const onFetchAllSourcesSuccess = (data: Array<SourceData>) => {
    setSources(
      data
        .map((item: SourceData) => ({
          ...item,
          typeId: item.type.id,
          name: item.sourceName,
        }))
        .sort((a, b) => customSort(a.code, b.code)),
    );
  };

  const filterSources = (_sources: any) => {
    setFilteredSources(_sources.filter((supplier: any) => filterType === -1 || supplier.typeId === filterType));
  };

  const handleSupplierSelect = (data: SelectedItemType) => {
    setSelectedSource(data);
    setIsEditModalOpen(true);
  };

  const editSourcesHandler = (data: PutMaterialNumberRequest) => {
    if (data) {
      setLoading(true);
      SourceApi.putSource(data, fetchAllSources, undefined, () => {
        closeModal();
      });
    }
  };

  const addSourcesHandler = (data: PostMaterialNumberRequest) => {
    if (data) {
      setLoading(true);
      SourceApi.postSource(data, fetchAllSources, undefined, () => {
        closeModal();
      });
    }
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    filterSources(sources);
  }, [filterType, sources]);

  useEffect(() => {
    setLoading(true);
    fetchAllSources();
  }, []);

  return (
    <Box sx={{ border: '1px solid #6C708C', p: 1, pt: 2 }}>
      <Stack justifyContent={'flex-end'} flexDirection="row">
        <YatungButton
          text={i18T('GLOBAL.CREATE')}
          startIcon={<AddCircleFill />}
          onClick={() => setIsAddModalOpen(true)}
          color="green"
        />
      </Stack>
      <YatungTable
        loading={loading}
        data={filteredSources}
        sx={{
          maxHeight: 'calc(100vh - 340px)',
        }}
        columns={[
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE'),
            dataField: 'type',
            formatter: ({ cellContent }) => cellContent.name,
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.CODE'),
            dataField: 'code',
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.NAME'),
            dataField: 'sourceName',
          },
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            width: '180px',
            formatter: ({ row }) => {
              return (
                <YatungButton
                  text={i18T('GLOBAL.UPDATE')}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    handleSupplierSelect(row);
                  }}
                  color="yellow"
                />
              );
            },
          },
        ]}
      />
      <AddModal
        open={isAddModalOpen}
        handleSubmit={addSourcesHandler}
        handleClose={closeModal}
        allTypes={types}
        allItems={sources.map(({ code, sourceName, typeId }: any) => ({ code, name: sourceName, typeId }))}
        icon={<ModalIcon />}
        title={i18T('APPLICATIONSMANAGEMENT.TABLE.MATERIAL_SOURCE')}
        storage={true}
      />
      {selectedSource && (
        <EditModal
          open={isEditModalOpen}
          handleSubmit={editSourcesHandler}
          handleClose={closeModal}
          allTypes={types}
          allItems={sources.map(({ code, sourceName, typeId }: any) => ({ code, name: sourceName, typeId }))}
          icon={<ModalIcon />}
          title={i18T('APPLICATIONSMANAGEMENT.TABLE.MATERIAL_SOURCE')}
          selectedItem={selectedSource}
        />
      )}
    </Box>
  );
}
