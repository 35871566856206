import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DeliveryInformationWarning(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="46" height="46" viewBox="0 0 46 46" fill="none">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4304 5.69824L36.9918 34.3834C37.3767 35.0501 36.8956 35.8834 36.1258 35.8834H3.00298C2.23318 35.8834 1.75206 35.0501 2.13696 34.3834L18.6984 5.69824C19.0833 5.03158 20.0455 5.03157 20.4304 5.69824ZM17.8323 5.19824C18.6021 3.86491 20.5266 3.86491 21.2964 5.19824L37.8579 33.8834C38.6277 35.2168 37.6654 36.8834 36.1258 36.8834H3.00298C1.46338 36.8834 0.501133 35.2168 1.27093 33.8834L17.8323 5.19824ZM17.2539 14.2935C17.2539 13.7434 17.6924 13.2974 18.2333 13.2974H20.4368C20.9776 13.2974 21.4161 13.7434 21.4161 14.2935V26.7445C21.4161 27.2946 20.9776 27.7406 20.4368 27.7406H18.2333C17.6924 27.7406 17.2539 27.2946 17.2539 26.7445V14.2935ZM17.2539 29.4838C17.2539 28.9337 17.6924 28.4877 18.2333 28.4877H20.4368C20.6544 28.4877 20.8552 28.5597 21.0178 28.6818C21.2593 28.8632 21.4161 29.155 21.4161 29.4838V31.725C21.4161 32.2751 20.9776 32.7211 20.4368 32.7211H18.2333C17.6924 32.7211 17.2539 32.2751 17.2539 31.725V29.4838Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
