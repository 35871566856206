import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Staff(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M22.1,22.5H1.9l0-0.8c0-5.6,4.5-10.2,10.1-10.2c5.6,0,10.1,4.6,10.1,10.2L22.1,22.5z M2.9,21.5h18.3
		c-0.1-5-4.2-9-9.1-9S3,16.5,2.9,21.5z M11.8,10.8c-2.6,0-4.6-2.1-4.6-4.6c0-2.6,2.1-4.6,4.6-4.6c2.6,0,4.6,2.1,4.6,4.6
		C16.5,8.7,14.4,10.8,11.8,10.8z M11.8,2.5c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6S13.8,2.5,11.8,2.5z"
      />
    </SvgIcon>
  );
}
