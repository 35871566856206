import React from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import NamesExpand from '@Src/_basic/components/NamesExpand';

interface Props {
  title: string;
  subTitle: Array<string>;
  detectionItem: any;
  detectionIndex: any;
  notificationPriorityOptions: Options[];
  typeAmount: number;
}

export default function DetectionItemCard(props: Props) {
  return (
    <Paper key={props.detectionItem.id} elevation={2} sx={{ padding: '16px 32px', borderRadius: '5px' }}>
      <Stack spacing={3}>
        {props.detectionIndex === 0 && (
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item>
              <Typography variant="h2" color="#003087">
                {props.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" color="#003087">
                <NamesExpand names={props.subTitle} />
              </Typography>
            </Grid>
          </Grid>
        )}
        <Stack direction="row" spacing={1}>
          <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
            {`檢測項目(${props.detectionIndex + 1})：`}
          </Typography>
          <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>{props.detectionItem.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>{`回報等級：`}</Typography>
          <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
            {
              props.notificationPriorityOptions.find(
                (option) => option.value === props.detectionItem.notificationPriorityId,
              )?.text
            }
          </Typography>
        </Stack>

        {props.typeAmount > 1 ? (
          [...Array(props.typeAmount)].map((_, index) => {
            return (
              <React.Fragment key={index}>
                <Stack direction="row" spacing={1}>
                  <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                    {`檢測(${props.detectionIndex + 1})Type${index + 1}以上>=：`}
                  </Typography>
                  <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                    {props.detectionItem.minValue}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                    {`檢測(${props.detectionIndex + 1})Type${index + 1}以下<=：`}
                  </Typography>
                  <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                    {props.detectionItem.maxValue}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                    {`拒收(${props.detectionIndex + 1})Type${index + 1}以上>=：`}
                  </Typography>
                  <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                    {props.detectionItem.rejectMinValue}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                    {`拒收(${props.detectionIndex + 1})Type${index + 1}以下<=：`}
                  </Typography>
                  <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                    {props.detectionItem.rejectMaxValue}
                  </Typography>
                </Stack>
              </React.Fragment>
            );
          })
        ) : (
          <>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                {`檢測(${props.detectionIndex + 1})以上>=：`}
              </Typography>
              <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                {props.detectionItem.minValue}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                {`檢測(${props.detectionIndex + 1})以下<=：`}
              </Typography>
              <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                {props.detectionItem.maxValue}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1}>
              <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                {`拒收(${props.detectionIndex + 1})以上>=：`}
              </Typography>
              <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                {props.detectionItem.rejectMinValue}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                {`拒收(${props.detectionIndex + 1})以下<=：`}
              </Typography>
              <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>
                {props.detectionItem.rejectMaxValue}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  );
}
