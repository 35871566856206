import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { EquipmentApi } from '@Src/_basic/protocol/equipment/EquipmentApi';
import { FileData, GetUploadEquipmentReports, UploadEquipmentReports } from '@Src/_basic/object/EquipmentManagementType';

// interface FileList {
//   lastModified:number;
//   lastModifiedDate: Date;
//   name: string;
//   size: number;
//   type: string;
//   webkitRelativePath: string;
// }

export function useEquipment() {
  const { data } = useSelector((state: RootState) => state.equipment);
  const dispatch = useDispatch();

  const uploadEquipmentReports = (
    params: UploadEquipmentReports,
    files: FileList,
    onSuccess?: (data: Array<FileData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    EquipmentApi.uploadEquipmentReports(
      params,
      files,
      (data: Array<FileData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const getUploadEquipmentReports = (
    params: GetUploadEquipmentReports,
    onSuccess?: (data: Array<FileData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    EquipmentApi.getUploadEquipmentReports(
      params,
      (data: Array<FileData>) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };
  
  const deleteEquipmentReport = (
    id: number | null,
    onSuccess?: (data: FileData) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    EquipmentApi.deleteUploadEquipmentReport(
      id,
      (data: FileData) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const downloadEquipmentReport = (
    id: number | null,
    onSuccess?: (data: FileData) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    EquipmentApi.downloadEquipmentReport(
      id,
      (data: FileData) => {
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };


  return {
    data,
    getUploadEquipmentReports,
    uploadEquipmentReports,
    deleteEquipmentReport,
    downloadEquipmentReport,
  };
}
