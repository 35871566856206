import React from 'react';
import { Grid } from '@mui/material';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungImg from '@Src/_basic/components/YatungImg';
import { useTranslation } from 'react-i18next';
import { useOrganize } from '@Src/redux/organize/organizeActions';
import OrganizeManagementDeleteMessage from './components/OrganizeManagementDeleteMessage';

const deleteImage = '/media/images/delete.png';

interface Props {
  targetId: number;
  targetName: string;
  showDeleteCard: boolean;
  setShowDeleteCard: (e: boolean) => void;
  setAlertIsOpen: (e: boolean) => void;
  setSetAlertTitle: (e: string) => void;
}

export default function OrganizeManagementDeleteCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { targetId, targetName, showDeleteCard, setShowDeleteCard, setAlertIsOpen, setSetAlertTitle } = props;

  const handleGoBackButtonClick = () => {
    setShowDeleteCard(false);
  };
  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={showDeleteCard}
        handleClose={handleGoBackButtonClick}
        icon={
          <YatungImg
            img={deleteImage}
            sx={{
              width: 28,
              height: 28,
              zIndex: 999,
              alignSelf: 'center',
              display: 'flex',
              textAlign: 'center',
              mr: 1,
            }}
          />
        }
        title={i18T('ORGANIZEMANAGEMENT.CHECK_DELETE')}
        body={
          <OrganizeManagementDeleteMessage
            onGoBackButtonClick={handleGoBackButtonClick}
            targetId={targetId}
            targetName={targetName}
            setAlertIsOpen={setAlertIsOpen}
            setSetAlertTitle={setSetAlertTitle}
          />
        }
      />
    </Grid>
  );
}
