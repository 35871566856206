import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';

import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungSelect from '@Src/_basic/components/YatungSelect';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungTextInput from '@Src/_basic/components/YatungTextInput';
import { MaterialPriceData, PutMaterialPriceRequest } from '@Src/_basic/object/MaterialPriceType';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import EditForm from '../../../components/EditForm';

const textStyle = { fontSize: 20 };

interface Props {
  open?: boolean;
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (data: PutMaterialPriceRequest) => void;
  title: string;
  icon: JSX.Element;
  factoryId: number;
  selectedMaterialPrice: MaterialPriceData;
}

export default function EditModal({
  open,
  handleClose,
  handleSubmit,
  title,
  icon,
  factoryId,
  selectedMaterialPrice,
}: Props) {
  const { t: i18T } = useTranslation();

  const [selectedMaterialId, setSelectedMaterialId] = useState<number>(selectedMaterialPrice.id);
  const [costInput, seCostInput] = useState<string>(selectedMaterialPrice?.cost?.toString() ?? '');
  const [falseCostInput, setFalseCostInput] = useState<string>(selectedMaterialPrice?.falseCost?.toString() ?? '');

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const onSubmit = useCallback(() => {
    const data = {
      id: selectedMaterialId,
      cost: +costInput || 0,
      falseCost: +falseCostInput || 0,
    };

    const isValid = Object.values(data).every((value) => value !== 0 && !isNaN(value));

    if (isValid) handleSubmit(data);
    else {
      setAlertOpen(true);
      setAlertText('請確認輸入的數值是否正確');
    }
  }, [selectedMaterialId, costInput, falseCostInput, handleSubmit]);

  useEffect(() => {
    seCostInput(selectedMaterialPrice?.cost?.toString() ?? '');
    setFalseCostInput(selectedMaterialPrice?.falseCost?.toString() ?? '');
    setSelectedMaterialId(selectedMaterialPrice.id ?? 0);
  }, [selectedMaterialPrice, handleClose]);

  return (
    <YatungDialog
      open={open}
      handleClose={handleClose}
      icon={icon}
      title={title}
      body={
        <EditForm
          onSubmit={onSubmit}
          onCancel={handleClose}
          canSubmit={Boolean(selectedMaterialPrice && costInput && falseCostInput && factoryId)}
        >
          <Stack
            sx={{ pt: 4, pb: 2, position: 'relative' }}
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography sx={textStyle}>{`${i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE')}:`}</Typography>
              <YatungSelect
                disabled={true}
                options={[{ value: selectedMaterialPrice.typeId, text: selectedMaterialPrice.typeName }]}
                value={selectedMaterialPrice.typeId}
              />
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography sx={textStyle}>{`${i18T('APPLICATIONSMANAGEMENT.TABLE.MATERIAL_NAME')}:`}</Typography>
              <YatungSelect
                disabled={true}
                options={[{ value: selectedMaterialPrice.id, text: selectedMaterialPrice.specificationName }]}
                value={selectedMaterialPrice.id}
                onChange={(e) => setSelectedMaterialId(e.target.value as number)}
              />
            </Stack>
          </Stack>
          <Stack sx={{ pt: 2, pb: 4 }} direction="row" gap={1.5} alignItems="center" justifyContent="center">
            <Stack direction="row" alignItems="center">
              <Typography sx={{ ...textStyle, marginLeft: '5px' }}>{`${i18T(
                'APPLICATIONSMANAGEMENT.TABLE.COST',
              )}:`}</Typography>
              <YatungTextInput
                value={costInput}
                onChange={(e) => seCostInput(e.target.value)}
                size="small"
                style={{ width: '70%', marginLeft: '15px' }}
              />
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography sx={textStyle}>{`${i18T('APPLICATIONSMANAGEMENT.TABLE.FALSE_COST')}:`}</Typography>
              <YatungTextInput
                value={falseCostInput}
                onChange={(e) => setFalseCostInput(e.target.value)}
                size="small"
                style={{ width: '60%', marginLeft: '10px' }}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{ position: 'absolute', bottom: 20, right: 30 }}
            direction="column"
            alignItems="flex-end"
            justifyContent="center"
          >
            <Typography>{`${i18T('APPLICATIONSMANAGEMENT.TABLE.UPDATE_TIME')}`}</Typography>
            <Typography>{`${moment(selectedMaterialPrice.lastUpdateTime).format('YYYY-MM-DD')}`}</Typography>
            <Typography>{`${moment(selectedMaterialPrice.lastUpdateTime).format('HH:mm:ss')}`}</Typography>
          </Stack>
          <YatungConfirmAlert isShowAlert={alertOpen} onClose={() => setAlertOpen(false)} alertText={alertText} />
        </EditForm>
      }
    />
  );
}
