export default [
  {
    no: '01',
    email: 'test@test.com',
    useraccount: '20921',
    name: '叮叮叮',
    time: '2022/9/25',
    information: '汐止廠',
    action: false,
  },
  {
    no: '02',
    email: 'abc@test.com',
    useraccount: '20421',
    name: '叮叮叮',
    time: '2022/9/24',
    information: '美崙廠',
    action: true,
  },
  {
    no: '03',
    email: 'def@test.com',
    useraccount: '20931',
    name: '叮叮叮',
    time: '2023/9/23',
    information: '林口廠',
    action: false,
  },
  {
    no: '04',
    email: 'ghi@test.com',
    useraccount: '20991',
    name: '叮叮叮',
    time: '2022/9/22',
    information: '桃園廠',
    action: true,
  },
];
