import React, { useCallback, useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { useWindowResize } from './components/useWindowResize';

interface Props {
  className?: string;
  data: Array<any>;
  renderItem: ((data: any) => React.ReactNode) | ((data: any, index: number) => React.ReactNode);
}

const Row = ({ data, index, setSize, windowWidth, renderItem }: any) => {
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rowRef.current) {
      setSize(index, rowRef.current.getBoundingClientRect().height);
    }
  }, [setSize, index, windowWidth]);

  return <div ref={rowRef}>{renderItem(data[index], index)}</div>;
};

export default function YatungWindow(props: Props) {
  const listRef = useRef<any>(null);
  const sizeMap = useRef<any>({});

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getSize = (index: number) => sizeMap.current[index] || 50;

  const [windowWidth] = useWindowResize();

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <List
          ref={listRef}
          className={props.className}
          height={height}
          width={width}
          itemData={props.data}
          itemCount={props.data.length}
          itemSize={getSize}
        >
          {({ data, index, style }) => (
            <div style={style}>
              <Row
                data={data}
                index={index}
                setSize={setSize}
                windowWidth={windowWidth}
                renderItem={props.renderItem}
              />
            </div>
          )}
        </List>
      )}
    </AutoSizer>
  );
}
