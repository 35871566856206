import YatungButton from '@Src/_basic/components/YatungButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onGoBackButtonClick: () => void;
  onSubmit: () => void;
}
export default function OrganizeManagementUpdateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onGoBackButtonClick, onSubmit } = props;
  return (
    <>
      <YatungButton
        text={i18T('AREAMANAGEMENT.CANCEL')}
        variant="contained"
        color={'red'}
        size={'large'}
        type={'button'}
        sx={{
          px: 3,
          py: 'auto',
        }}
        onClick={onGoBackButtonClick}
      />
      <YatungButton
        text={i18T('AREAMANAGEMENT.COMPLETE')}
        variant={'contained'}
        size={'large'}
        type={'submit'}
        color={'blue'}
        onSubmit={onSubmit}
        sx={{
          px: 3,
        }}
      />
    </>
  );
}
