import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { Box, OutlinedInput, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCheckBox from '@Src/_basic/components/YatungCheckBox';
import YatungSelect, { AccordionModelMenuItem, DropdownItem } from '@Src/_basic/components/YatungSelect';
import YatungTextInput from '@Src/_basic/components/YatungTextInput';
import TestManageDetailItem from './TestManageDetailItem';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import { Options } from '@Src/_basic/components/YatungFormSelect';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import TestDetailDialog from '@Src/_basic/components/YatungTest/testDetail/TestDetailDialog';
import { caculateFutureDate, timeCalculate } from '@Src/_basic/helpers/TimeCalculate';
import CalendarIcon from '@Src/_basic/icons/Calendar';
import LandMark from '@Src/_basic/icons/LandMark';
import PieChart from '@Src/_basic/icons/PieChart';
import CreateIcon from '@Src/_basic/icons/StorageYardCreateTime';
import Stroke from '@Src/_basic/icons/Stroke';
import Weather from '@Src/_basic/icons/Weather';
import WeightIcon from '@Src/_basic/icons/Weight';
import { GroupBasicInfo } from '@Src/_basic/object/AuthType';
import { SearchSpecimenData, SpecimenExptIdAndCodeResponse } from '@Src/_basic/object/SpecimenType';
import { SpecimenApi } from '@Src/_basic/protocol/specimen/SpecimenApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import moment from 'moment';

interface NoticeSelectedDate {
  value: number;
  date: Date;
}

interface SelectDaysDetail {
  value: number;
  notificationTime: Date | number;
  startTime: Date | number;
  endTime: Date | number;
  quantity: number;
  oparatorGroupId: number;
  oparatorGroup: Array<DropdownItem<string>> | undefined;
  oparatorId: string | number;
}

interface Props {
  selectedTestDetail: SearchSpecimenData;
  onClose: () => void;
  selectedFactory: number;
  updateFn: () => void;
}

const ConcreteDetail = ({ selectedTestDetail, onClose, selectedFactory, updateFn }: Props) => {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { userGroupsData } = useAuth();

  const [daysOptions, setDaysOptions] = useState<Array<Options>>([
    {
      value: 3,
      text: '3天',
      disabled: false,
    },
    {
      value: 5,
      text: '5天',
      disabled: false,
    },
    {
      value: 7,
      text: '7天',
      disabled: false,
    },
    {
      value: 14,
      text: '14天',
      disabled: false,
    },
    {
      value: 28,
      text: '28天',
      disabled: false,
    },
    {
      value: 56,
      text: '56天',
      disabled: false,
    },
    {
      value: 90,
      text: '90天',
      disabled: false,
    },
  ]);

  const [allTestQuantity, setAllTestQuantity] = useState<number>(selectedTestDetail.specimenNum); // 試驗組試體數量
  const [orderType, setOrderType] = useState<string>('demand_order'); // 試驗單類型
  const [checkedNotice, setCheckedNotice] = useState<Array<number>>([]); // 勾選通知日期陣列
  const [noticeSelectedDate, setNoticeSelectedDate] = useState<Array<NoticeSelectedDate>>([]); // 勾選通知日期陣列

  const [open, setOpen] = useState<boolean>(false); // 是否開啟試驗單Dialog
  const [exptIdAndCode, setExptIdAndCode] = useState<SpecimenExptIdAndCodeResponse>(); // 試驗Id和Code

  const [prevSelectDays, setPrevSelectDays] = useState<Array<number>>([]); // 前次勾選的天數
  const [selectDays, setSelectDays] = useState<Array<number>>([]); // 選擇的天數
  const [selectDaysDetail, setSelectDaysDetail] = useState<Array<SelectDaysDetail>>([]); // 選擇的天數詳細資料

  const [operatorList, setOperatorList] = useState<Array<AccordionModelMenuItem<number, string>>>([]); // 指派對象
  const [selectOperatorGroup, setSelectOperatorGroup] = useState<number>(0); // 指派對象
  const [operatorGroup, setOperatorGroup] = useState<Array<DropdownItem<string>>>([]); // 指派對象群組
  const [operatorPerson, setOperatorPerson] = useState<string | number>(0); // 指派對象人員

  const [alertType, setAlertType] = useState<'confirm' | 'alert'>('alert'); // Alert類型
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false); // 是否顯示Alert
  const [alertText, setAlertText] = useState<string>(''); // Alert文字

  const [remark, setRemark] = useState<string>(''); // 備註

  // 標準時間
  const [standardTime, setStandardTime] = useState<Date>(
    selectedTestDetail.standardTime ? new Date(selectedTestDetail.standardTime) : new Date(),
  );

  // 選擇指派群組
  const selectOperatorGroupHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSelectOperatorGroup(e.target.value as number);
      setOperatorPerson(0);
      const group = operatorList.find((item) => item.value === e.target.value)?.extendValue;
      if (group) setOperatorGroup(group);
    },
    [operatorList],
  );

  // 選擇指派人員
  const selectOperatorPersonHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setOperatorPerson(e.target.value as string);
    },
    [operatorList],
  );

  // 選擇試驗指派群組
  const selectTestOperatorGroupHandler = useCallback(
    (e: SelectChangeEvent<unknown>, day: number) => {
      const selectValue = e.target.value;
      const groupList = operatorList.find((item) => item.value === selectValue)?.extendValue;
      if (groupList)
        setSelectDaysDetail((prev) =>
          prev.map((item) =>
            item.value === day ? { ...item, oparatorGroup: groupList, oparatorGroupId: selectValue as number } : item,
          ),
        );
    },
    [operatorList],
  );

  // 選擇試驗指派人員
  const selectTestOperatorHandler = useCallback(
    (e: SelectChangeEvent<unknown>, day: number) => {
      const selectValue = e.target.value;
      setSelectDaysDetail((prev) =>
        prev.map((item) => (item.value === day ? { ...item, oparatorId: selectValue as string } : item)),
      );
    },
    [setSelectDaysDetail],
  );

  // 完成送出
  const submitHandler = useCallback(() => {
    if (!userGroupsData) return;

    SpecimenApi.putSpecimen(
      {
        id: selectedTestDetail.id,
        specimenNum: allTestQuantity,
        ingredients: null,
        inspectionNotificationTime: noticeSelectedDate.find((item) => item.value === 1)
          ? (new Date(
            (noticeSelectedDate.find((item) => item.value === 1) as NoticeSelectedDate).date,
          )?.getTime() as number)
          : null,
        retrieveNotificationTime: noticeSelectedDate.find((item) => item.value === 2)
          ? (new Date(
            (noticeSelectedDate.find((item) => item.value === 2) as NoticeSelectedDate).date,
          )?.getTime() as number)
          : null,
        standardTime: new Date(standardTime).getTime(),
        notifiedAccount: operatorPerson !== 0 ? (operatorPerson as string) : '',
      },
      (_data) => {
        updateFn();
        const postDays = selectDaysDetail.filter((item) => !prevSelectDays.includes(item.value));
        postDays.length <= 0
          ? onClose()
          : postDays?.forEach((item) => {
            SpecimenApi.postConCreteStrTest(
              {
                specimenId: selectedTestDetail.id,
                operatorId: item.oparatorId !== 0 ? (item.oparatorId as string) : userGroupsData.account,
                factoryId: selectedFactory,
                incomingNum: item.quantity,
                age: item.value,
                createrId: userGroupsData.account,
                ...(orderType === 'demand_order' && {
                  exptNotificationTime: new Date(item.notificationTime).getTime() as number,
                  startTime: item.startTime as number,
                  endTime: item.endTime as number,
                }),
              },
              () => onClose(),
            );
          });
      },
    );
  }, [
    selectedTestDetail,
    userGroupsData,
    selectedFactory,
    selectDaysDetail,
    operatorPerson,
    noticeSelectedDate,
    allTestQuantity,
    onClose,
    updateFn,
    standardTime,
    orderType,
    prevSelectDays,
  ]);

  // 檢查試驗是否已被開過
  const checkUsedInExpt = useCallback(() => {
    const totalQuantity = selectDaysDetail.reduce((pre, curr) => pre + curr.quantity, 0);

    if (noticeSelectedDate.some((item) => item !== null) && !operatorPerson) {
      setAlertType('alert');
      setAlertText('請選擇指派對象');
      setIsShowAlert(true);
      return;
    } else if (totalQuantity > allTestQuantity || selectDaysDetail.find((item) => item.quantity < 1)) {
      setAlertType('alert');
      setIsShowAlert(true);
      setAlertText('試驗組試體數量異常');
      return;
    } else if (orderType === 'demand_order' && selectDaysDetail.some((item) => item.oparatorId === 0)) {
      setAlertType('alert');
      setIsShowAlert(true);
      setAlertText('請確認試驗皆有指派對象');
    } else submitHandler();
  }, [operatorPerson, selectDaysDetail, allTestQuantity, submitHandler, noticeSelectedDate, orderType]);

  // 天數checkBox onChange Function
  const handleSelectDays = useCallback(
    (e: any) => {
      const numValues = Number(e.target.value);

      if (allTestQuantity === 0) {
        setAlertType('alert');
        setIsShowAlert(true);
        setAlertText('試驗組試體數量異常');
      }

      if (!selectDays.includes(numValues)) {
        setSelectDays((prev: Array<number>) => [...prev, numValues]);
        setSelectDaysDetail((prev: Array<SelectDaysDetail>) => [
          ...prev,
          {
            value: numValues,
            notificationTime: caculateFutureDate(new Date(standardTime).getTime(), numValues - 1),
            startTime: caculateFutureDate(new Date(standardTime).getTime(), numValues),
            endTime: caculateFutureDate(new Date(standardTime).getTime(), numValues + 31),
            quantity: 1,
            oparatorGroupId: 0,
            oparatorGroup: undefined,
            oparatorId: 0,
          },
        ]);
      } else {
        setSelectDays((prev: Array<number>) => prev.filter((item: number) => item !== numValues));
        setSelectDaysDetail((prev: Array<SelectDaysDetail>) =>
          prev.filter((item: SelectDaysDetail) => item.value !== numValues),
        );
      }
    },
    [selectDays, allTestQuantity, standardTime],
  );

  // 試驗試體數量onChange Function
  const handleQuantity = useCallback(
    (val: number, item: number) => {
      setSelectDaysDetail((prev) => prev.map((_item) => (_item.value === item ? { ..._item, quantity: val } : _item)));
    },
    [setSelectDaysDetail],
  );

  const notificationCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (checkedNotice.includes(Number(e.target.value))) {
        setCheckedNotice(checkedNotice.filter((item) => item !== Number(e.target.value)));
        setNoticeSelectedDate((prev) => prev.filter((item) => item.value !== Number(e.target.value)));
      } else {
        setCheckedNotice((prev) => [...prev, Number(e.target.value)]);
        setNoticeSelectedDate((prev) => [...prev, { value: Number(e.target.value), date: new Date() }]);
      }
    },
    [checkedNotice],
  );

  const notificationDateHandler = useCallback(
    (val: Date | null, index: number) => {
      if (noticeSelectedDate.find((_item) => _item.value === index + 1)) {
        setNoticeSelectedDate((prev) =>
          prev.map((_item) => (_item.value === index + 1 ? { ..._item, date: val as Date } : _item)),
        );
      } else setNoticeSelectedDate((prev) => [...prev, { value: index + 1, date: val as Date }]);
    },
    [noticeSelectedDate],
  );

  // 試驗類型onChange Function
  const handleOrderType = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOrderType(value);

    if (value === 'test_order')
      setSelectDaysDetail((prev) => prev.map((item) => ({ ...item, startTime: 0, endTime: 0 })));
    else {
      setSelectDaysDetail((prev: Array<SelectDaysDetail>) =>
        prev.map((item) => ({
          ...item,
          startTime: timeCalculate((new Date(item.notificationTime) as Date).getTime()),
          endTime: timeCalculate((new Date(item.notificationTime) as Date).getTime(), 'add', item.value + 1),
        })),
      );
    }
  }, []);

  useEffect(() => {
    if (!userGroupsData) return;
    const afterOperatorList = userGroupsData.supervisorAssignGroups.reduce(
      (prev: Array<AccordionModelMenuItem<number, string>>, curr: GroupBasicInfo<DropdownItem<string>>) => [
        ...prev,
        { text: curr.groupName, value: curr.id, extendValue: curr.users },
      ],
      [] as Array<AccordionModelMenuItem<number, string>>,
    );
    setOperatorList(afterOperatorList);
  }, [userGroupsData]);

  useEffect(() => {
    if (!selectedTestDetail || !selectedFactory || !orderType) return;
    setSelectDays([]);
    setSelectDaysDetail([]);
    setDaysOptions((prev) => prev.map((item) => ({ ...item, disabled: false })));

    if (selectedTestDetail.inspectionNotificationTime) {
      setCheckedNotice((prev) => [...prev, 1]);
      setNoticeSelectedDate((prev) => [
        ...prev,
        { value: 1, date: new Date(selectedTestDetail.inspectionNotificationTime as number) },
      ]);
    }

    if (selectedTestDetail.retrieveNotificationTime) {
      setCheckedNotice((prev) => [...prev, 2]);
      setNoticeSelectedDate((prev) => [
        ...prev,
        { value: 2, date: new Date(selectedTestDetail.retrieveNotificationTime as number) },
      ]);
    }

    if (selectedTestDetail.notifiedAccount) {
      operatorList.forEach((item) => {
        const operator = item.extendValue?.find((item) => item.value === selectedTestDetail.notifiedAccount);
        if (operator) {
          selectOperatorGroupHandler({ target: { value: item.value } } as SelectChangeEvent<unknown>);
          setOperatorPerson(operator.value);
        }
      });
    }

    // 取得試驗Id和Code
    SpecimenApi.getSpecimenExptIdAndCode(
      {
        specimenId: selectedTestDetail.id,
        factoryId: selectedFactory,
      },
      (_data) => {
        if (_data.exptId) setExptIdAndCode(_data);
      },
    );

    // 取得前次開單設定
    SpecimenApi.getSpecimenExptSetting(
      {
        specimenId: selectedTestDetail.id,
        factoryId: selectedFactory,
        isRequired: orderType === 'demand_order' ? true : false,
      },
      (_data) => {
        const _ages = _data.map((item) => item.age);
        setDaysOptions((prev) =>
          prev.map((item) => {
            if (_ages.includes(item.value)) return { ...item, disabled: true };
            else return item;
          }),
        );

        setPrevSelectDays(_ages);
        setSelectDays(_ages);
        setSelectDaysDetail(
          _data.map((item) => {
            let _operatorGroup: AccordionModelMenuItem<number, string> | undefined;

            operatorList.some((_operatorList) => {
              const foundOperator = _operatorList.extendValue?.find((_operator) => _operator.value === item.operator);

              if (foundOperator) {
                _operatorGroup = _operatorList;
                return true; // Break out of the loop
              }

              return false;
            });

            return {
              value: item.age,
              notificationTime: item.exptNotificationTime ? new Date(item.exptNotificationTime as number) : 0,
              startTime: item.startTime ? new Date(item.startTime as number) : 0,
              endTime: item.endTime ? new Date(item.endTime as number) : 0,
              quantity: item.incomingSpecimenNum,
              oparatorGroupId: _operatorGroup?.value || 0,
              oparatorGroup: _operatorGroup?.extendValue,
              oparatorId: item.operator,
            };
          }),
        );
      },
    );
  }, [selectedTestDetail, selectedFactory, orderType, operatorList, selectOperatorGroupHandler]);

  return (
    <Stack direction="row" width="100%">
      <Stack sx={{ p: 2, bgcolor: 'white', color: 'gray', height: '100%', width: '55%' }}>
        <Box sx={{ borderBottom: 1, marginBottom: 2, flexWrap: 'wrap' }}>
          <TestManageDetailItem
            Icon={CalendarIcon}
            label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.SHIPMENT_TIME')}
            value={
              !selectedTestDetail.shipmentTimestamp
                ? i18T('GLOBAL.NO_INFO')
                : moment(selectedTestDetail.shipmentTimestamp).format('YYYY/MM/DD HH:mm')
            }
          />
          <TestManageDetailItem
            Icon={Stroke}
            label={i18T('APPLICATIONSMANAGEMENT.TABLE.SITE_NAME')}
            value={selectedTestDetail.civilEngrName ?? i18T('GLOBAL.NO_INFO')}
          />
          <TestManageDetailItem
            Icon={CalendarIcon}
            label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.PLATE_NUMBER')}
            value={(selectedTestDetail.plateNumber as string) ?? i18T('GLOBAL.NO_INFO')}
          />
          <TestManageDetailItem
            Icon={PieChart}
            label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.USE_PROPORTION')}
            value={selectedTestDetail.propName ?? i18T('GLOBAL.NO_INFO')}
          />
          <TestManageDetailItem
            Icon={LandMark}
            label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.SITE_ADDRESS')}
            value={selectedTestDetail.civilEngrAddr ?? i18T('GLOBAL.NO_INFO')}
          />
          <TestManageDetailItem
            Icon={Weather}
            label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.SITE_WEATHER')}
            value={i18T('GLOBAL.NO_INFO')}
          />
          <TestManageDetailItem
            Icon={PieChart}
            label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_UNIT')}
            value={selectedTestDetail.exptUnitName ?? i18T('GLOBAL.NO_INFO')}
          />
          <TestManageDetailItem Icon={WeightIcon} label={'試體時間'}>
            <YatungDateSelect
              label=""
              value={standardTime}
              onChange={(val: Date | null) => setStandardTime(val as Date)}
              icon={WeightIcon}
            />
          </TestManageDetailItem>
          <TestManageDetailItem Icon={CreateIcon} label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_GROUP_ITEM')}>
            <YatungTextInput
              value={allTestQuantity}
              onChange={(e) => setAllTestQuantity(checkIsNaN(parseInt(e.target.value)))}
              size="small"
              style={{
                width: '40%',
              }}
            />
          </TestManageDetailItem>
        </Box>
        <Stack spacing={1}>
          <Typography>{`${i18T('GLOBAL.REMARK')}:`}</Typography>
          <OutlinedInput rows={7} multiline={true} color="primary" onChange={(e) => setRemark(e.target.value)} />
        </Stack>
      </Stack>
      <Stack
        sx={{ p: 2, bgcolor: 'white', color: 'gray', height: '100%', width: '45%', maxHeight: 800, overflow: 'auto' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1 }}>
            <YatungSelect
              disabled={!noticeSelectedDate.some((item) => item !== null)}
              width={'100%'}
              options={operatorList}
              placeholder={`${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.CHOOSE_OPERATOR_GROUP')}...`}
              value={selectOperatorGroup}
              onChange={selectOperatorGroupHandler}
            />
            <YatungSelect
              disabled={operatorGroup.length <= 0}
              width={'100%'}
              options={operatorGroup}
              placeholder={`${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.CHOOSE_OPERATOR_PERSON')}...`}
              value={operatorPerson}
              onChange={selectOperatorPersonHandler}
            />
          </Box>
          <YatungCheckBox
            initialCheckedValue={checkedNotice}
            onChange={notificationCheckBox}
            options={[
              { value: 1, text: i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.INSPECTION_NOTICE') },
              { value: 2, text: i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.RETRIEVE_NOTICE') },
            ]}
            optionComponent={Array.from({ length: 2 }).map((_item, index) => (
              <YatungDateSelect
                key={index}
                label=""
                fullWidth={true}
                disabled={actionLoading || !checkedNotice.includes(index + 1)}
                value={noticeSelectedDate.find((_item) => _item.value === index + 1)?.date as Date}
                onChange={(val: Date | null) => notificationDateHandler(val, index)}
                icon={WeightIcon}
              />
            ))}
            column={true}
          />
          <Stack sx={{ px: 1 }}>
            <Stack display="flex" flexDirection="row" alignItems="center">
              <Typography sx={{ fontSize: 20, mr: 3 }}>
                {`${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_EXECUTE_DATE')} :`}
              </Typography>
              <CalendarIcon />
            </Stack>
            <YatungCheckBox
              options={daysOptions}
              onChange={handleSelectDays}
              labelTextStyle={{ fontSize: 20 }}
              initialCheckedValue={selectDays}
            />
          </Stack>
          {orderType === 'demand_order' &&
            selectDays
              .sort((a, b) => a - b)
              .map((item) => (
                <Stack key={item} direction="row" justifyContent="space-between" gap={1} sx={{ px: 1 }}>
                  <Stack width={'40%'}>
                    <Typography sx={{ fontSize: '20px' }}>
                      {`${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_EXECUTE_DATE')}( ${item}天 ) :`}
                    </Typography>
                  </Stack>
                  <Stack width={'60%'}>
                    <YatungDateSelect
                      label=""
                      fullWidth={true}
                      disabled={true}
                      value={new Date(caculateFutureDate(new Date(standardTime).getTime(), item - 1))}
                      onChange={() => ({})}
                      icon={WeightIcon}
                    />
                  </Stack>
                </Stack>
              ))}
        </Box>
        <YatungRadioButton
          label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.STRENGTH_TYPE')}
          onChange={handleOrderType}
          value={orderType}
          groupName={'order-group'}
          controlData={[
            {
              label: i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.DEMAND_ORDER'),
              value: 'demand_order',
            },
            {
              label: i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_ORDER'),
              value: 'test_order',
            },
          ]}
        />
        {selectDays
          .sort((a, b) => a - b)
          .map((item) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
                p: 2,
                borderBottom: '1px solid gray',
                my: 1,
              }}
              key={item}
            >
              <Typography>{`第${item}天抗壓試驗 :`}</Typography>
              <Stack display="flex" flexDirection="row" justifyContent="space-around" alignItems="center">
                <Typography>{'執行顆數:'}</Typography>
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <YatungTextInput
                    value={selectDaysDetail?.find((_item) => _item.value === item)?.quantity}
                    onChange={(e) => handleQuantity(checkIsNaN(parseInt(e.target.value)), item)}
                    size="small"
                    style={{
                      marginRight: 2,
                      width: '50%',
                    }}
                    text="顆"
                    disabled={daysOptions.find((_item) => _item.value === item)?.disabled}
                  />
                </Stack>
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                sx={{ mt: 1 }}
              >
                {orderType === 'demand_order' ? (
                  <Stack gap={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1 }}>
                      <YatungDateSelect
                        label={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.START')}
                        disabled={true}
                        value={new Date(caculateFutureDate(new Date(standardTime).getTime(), item))}
                        onChange={() => ({})}
                        fullWidth={true}
                      />
                      <YatungDateSelect
                        label={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.OVER')}
                        disabled={true}
                        value={new Date(caculateFutureDate(new Date(standardTime).getTime(), item + 31, true))}
                        onChange={() => ({})}
                        fullWidth={true}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1 }}>
                      <YatungSelect
                        width={'100%'}
                        disabled={prevSelectDays.includes(item) ? true : false}
                        options={operatorList}
                        value={selectDaysDetail.find((_item) => _item.value === item)?.oparatorGroupId ?? 0}
                        placeholder={`${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.CHOOSE_OPERATOR_GROUP')}...`}
                        onChange={(e) => selectTestOperatorGroupHandler(e, item)}
                      />
                      <YatungSelect
                        disabled={
                          selectDaysDetail.find((_item) => _item.value === item)?.oparatorGroup === undefined ||
                            prevSelectDays.includes(item)
                            ? true
                            : false
                        }
                        width={'100%'}
                        options={selectDaysDetail.find((_item) => _item.value === item)?.oparatorGroup ?? []}
                        placeholder={`${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.CHOOSE_OPERATOR_PERSON')}...`}
                        value={selectDaysDetail.find((_item) => _item.value === item)?.oparatorId ?? 0}
                        onChange={(e) => selectTestOperatorHandler(e, item)}
                      />
                    </Box>
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
            </Box>
          ))}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 1.5, mt: 3 }}>
          <Stack direction="row" gap={4}>
            {exptIdAndCode && (
              <YatungButton text={i18T('GLOBAL.DETAIL')} onClick={() => setOpen(true)} color="yellow" width={90} />
            )}
            <YatungButton text={i18T('GLOBAL.CANCEL')} onClick={onClose} color="red" width={90} />
            <YatungButton text={i18T('GLOBAL.FINISH')} onClick={checkUsedInExpt} color="blue" width={90} />
          </Stack>
        </Box>
      </Stack>
      <YatungConfirmAlert
        isShowAlert={isShowAlert}
        alertText={alertText}
        confirmTitle="確認"
        onOk={alertType === 'alert' ? undefined : submitHandler}
        onClose={() => setIsShowAlert(false)}
      />
      {exptIdAndCode && open && (
        <TestDetailDialog
          exptId={exptIdAndCode?.exptId}
          exptItemCode={exptIdAndCode?.exptItemCode}
          allTable={true}
          open={open}
          closeHandler={() => setOpen(false)}
        />
      )}
    </Stack>
  );
};

export default memo(ConcreteDetail);
