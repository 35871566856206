import SuccessIcon from '@Src/_basic/icons/Success';
import { ConcreteExptData } from '@Src/_basic/object/ProductionHistoryType';
import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)(() => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '0.16em',
  marginBottom: '4px',
}));

const BoldTitle = styled(Title)(() => ({
  fontWeight: '700',
}));

const Value = styled(Typography)(() => ({
  color: '#979797',
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '0.255em',
}));

const BoldValue = styled(Value)(() => ({
  color: '#7C878E',
}));

const SuccessLabel = styled(Value)(() => ({
  color: '#00D03A',
}));

interface Props {
  concreteExpt: ConcreteExptData;
}

export default function ExptInformationCard(props: Props) {
  const { t: i18T } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '53px',
        borderBottom: '1px dashed #7C878E',
      }}
    >
      <Box sx={{ margin: '8px', width: '600px' }}>
        <Grid container direction="row">
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="space-between">
              <Title>檢測日期</Title>
              <Value>
                {moment(props?.concreteExpt?.samplingTtime).format('YYYY/MM/DD')}
                <br />
                {moment(props?.concreteExpt?.samplingTtime).format('HH:mm')}
              </Value>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="space-between">
              <Title>配比名稱</Title>
              <Value>{props?.concreteExpt?.displayName ?? props?.concreteExpt?.falsePropName}</Value>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="space-between">
              <BoldTitle>受檢車輛車牌號碼</BoldTitle>
              <BoldValue>{props?.concreteExpt?.plateNumber ?? i18T('GLOBAL.NO_INFO')}</BoldValue>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="space-between">
              <Title>坍度/料相</Title>
              <Value>{`${props?.concreteExpt?.actualSlump || 0}cm/${props?.concreteExpt?.materialStatusName}`}</Value>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="space-between">
              <BoldTitle>澆置位置</BoldTitle>
              <BoldValue>{props?.concreteExpt?.workPart ?? i18T('GLOBAL.NO_INFO')}</BoldValue>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="space-between">
              <Title noWrap>設計強度(kgf/m2)</Title>
              <Value>{props?.concreteExpt?.designStr ?? i18T('GLOBAL.NO_INFO')}</Value>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column">
              <Title>
                檢測平均強度
                <br />
                (kgf/m2)
              </Title>
              <Value>{props?.concreteExpt?.avgCompStr ?? i18T('GLOBAL.NO_INFO')}</Value>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="flex-end" gap="21px" sx={{ height: '100%' }}>
              <Title noWrap>達成設計強度</Title>
              <Value>
                {props?.concreteExpt?.achivedDesignStr
                  ? `${props?.concreteExpt?.achivedDesignStr}%`
                  : i18T('GLOBAL.NO_INFO')}
              </Value>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" justifyContent="flex-end" sx={{ height: '100%' }}>
              <BoldTitle>是否達標</BoldTitle>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  pr: '30px',
                }}
              >
                <SuccessLabel>達標</SuccessLabel>
                <SuccessIcon sx={{ height: '45px', width: '45px' }} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ px: '16px' }}>
        {props?.concreteExpt?.previewType === 'VIDEO' ? (
          <video src={props?.concreteExpt?.previewUrl} controls style={{ width: '224px', height: '173px' }} />
        ) : (
          <img src={props?.concreteExpt?.previewUrl} style={{ width: '224px', height: '173px' }} />
        )}
      </Box>
    </Box>
  );
}
