import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import { Options } from '@Src/_basic/components/YatungSelect';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { concreteTestFormSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest, TestItemEnum } from '@Src/_basic/object/TestType';
import {
  ConcreteSlumpFlow,
  ImportConcreteSlumpFlow,
  PutConcreteSlumpFlowRequest,
} from '@Src/_basic/object/test/concrete/ConcreteSlumpFlowType';
import { MaterialStatusApi } from '@Src/_basic/protocol/materialStatus/MaterialStatusApi';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: ConcreteSlumpFlow;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  plateNumber: Yup.string().min(4).required(),
  slumpFlowDemand: concreteTestFormSchema,
  visObsSlumpFlow: concreteTestFormSchema,
  slumpFlowD1: concreteTestFormSchema,
  slumpFlowD2: concreteTestFormSchema,
  beforeTubeSlump: concreteTestFormSchema,
  afterTubeSlump: concreteTestFormSchema,
  b50: concreteTestFormSchema,
  airContent: concreteTestFormSchema,
  chlorideIonContent: concreteTestFormSchema,
  chlorideIonContent2: concreteTestFormSchema,
  chlorideIonContent3: concreteTestFormSchema,
  concreteTemp: concreteTestFormSchema,
  specimenNum: concreteTestFormSchema,
});

function ExportConcreteSlumpFlowTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const [edit, setEdit] = useState<boolean>(false);
  const [selectStatus, setSelectStatus] = useState<number>(testDetail?.materialStatusId);
  const [selectSccType, setSelectSccType] = useState<number>(testDetail?.specificationId ?? 0);
  const [materialStatus, setMaterialStatus] = useState<Array<Options>>();
  const [sccType, setSccType] = useState<Array<Options>>();

  const initialValues = useMemo(
    () => ({
      plateNumber: testDetail?.plateNumber,
      slumpFlowDemand: testDetail?.slumpFlowDemand,
      visObsSlumpFlow: testDetail?.visObsSlumpFlow,
      slumpFlowD1: testDetail?.slumpFlowD1,
      slumpFlowD2: testDetail?.slumpFlowD2,
      beforeTubeSlump: testDetail?.beforeTubeSlump,
      afterTubeSlump: testDetail?.afterTubeSlump,
      b50: testDetail?.b50,
      airContent: testDetail?.airContent,
      chlorideIonContent: testDetail?.chlorideIonContent,
      chlorideIonContent2: testDetail?.chlorideIonContent2,
      chlorideIonContent3: testDetail?.chlorideIonContent3,
      concreteTemp: testDetail?.concreteTemp,
      specimenNum: testDetail?.specimenNum,
    }),
    [testDetail],
  );

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE_FLOW.SLUMP_FLOW_DEMAND`)}(cm)`,
      values: [`${i18T(`TEST.CONCRETE_FLOW.VIS_OBS_SLUMP_FLOW`)}(cm)`, `${i18T(`TEST.CONCRETE_FLOW.B50`)}(Sec)`],
    }),
    [i18T],
  );

  const firstInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.slumpFlowDemand?.toFixed(4) ?? ''),
        values: [
          formatNumber(testDetail?.visObsSlumpFlow?.toFixed(4) ?? ''),
          formatNumber(testDetail?.b50?.toFixed(4) ?? ''),
        ],
        valueTextStyle: [
          {},
          testDetail?.verifyPassedResults?.b50 && testDetail?.verifyCustomResults?.b50?.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
        ],
      },
    ],
    [testDetail],
  );

  const secondHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE_FLOW.SLUMP_FLOW_01`)}(cm)`,
      values: [
        `${i18T(`TEST.CONCRETE_FLOW.SLUMP_FLOW_02`)}(cm)`,
        `${i18T(`TEST.CONCRETE_FLOW.ACTUAL_SLUMP_FLOW`)}(cm)`,
      ],
    }),
    [i18T],
  );

  const secondInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.slumpFlowD1?.toFixed(4) ?? ''),
        values: [
          formatNumber(testDetail?.slumpFlowD2?.toFixed(4) ?? ''),
          formatNumber(testDetail?.actualSlumpFlow?.toFixed(4) ?? ''),
        ],
        valueTextStyle: [
          {},
          testDetail?.verifyPassedResults?.actualSlumpFlow && testDetail?.verifyCustomResults?.actualSlumpFlow?.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
        ],
      },
    ],
    [testDetail],
  );

  const thirdHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE_FLOW.BEFORE_TUBE_SLUMP`)}(cm)`,
      values: [`${i18T(`TEST.CONCRETE_FLOW.AFTER_TUBE_SLUMP`)}(cm)`, `${i18T(`TEST.CONCRETE_FLOW.MATERIAL_STATUS`)}`],
    }),
    [i18T],
  );

  const thirdInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.beforeTubeSlump?.toFixed(4) ?? ''),
        values: [formatNumber(testDetail?.afterTubeSlump?.toFixed(4) ?? ''), testDetail?.materialStatus as string],
      },
    ],
    [testDetail],
  );

  const fourthHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE_FLOW.AIR_CONTENT`)}(%)`,
      values: [
        `${i18T(`TEST.CONCRETE_FLOW.TEMPERATURE`)}(°C)`,
        `${i18T(`TEST.CONCRETE_FLOW.CHLORIDE_CONTENT_AVG`)}(kg/m³)`
      ],
    }),
    [i18T],
  );

  const fourthInfo = useMemo(
    () => [
      {
        title: testDetail?.airContent?.toString(),
        values: [testDetail?.concreteTemp?.toString(), testDetail?.chlorideIonContentAvg?.toString() ?? ''],
        titleStyle: {
          ...(testDetail?.verifyPassedResults?.airContent && testDetail?.verifyCustomResults?.airContent?.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal),
        },
      },
    ],
    [testDetail],
  );

  const seventhHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE_FLOW.CHLORIDE_CONTENT`)}1(kg/m³)`,
      values: [
        `${i18T(`TEST.CONCRETE_FLOW.CHLORIDE_CONTENT`)}2(kg/m³)`,
        `${i18T(`TEST.CONCRETE_FLOW.CHLORIDE_CONTENT`)}3(kg/m³)`,
      ],
    }),
    [i18T],
  );

  const seventhInfo = useMemo(
    () => [
      {
        title: testDetail?.chlorideIonContent?.toString() ?? '',
        values: [testDetail?.chlorideIonContent2?.toString() ?? '', testDetail?.chlorideIonContent3?.toString() ?? ''],
      },
    ],
    [testDetail],
  );

  const fifthHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.CONCRETE_FLOW.SPECIMEN_COUNT`)}(${i18T(`TEST.CONCRETE_FLOW.PCS`)})`,
    }),
    [i18T],
  );

  const fifthInfo = useMemo(
    () => [
      {
        title: testDetail?.specimenNum?.toString(),
      },
    ],
    [testDetail],
  );

  const sixthHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const sixthInfo = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
          titles: verifyResultText,
          titlesStyle: [TestVerifyResultStyles.normal],
        }
        : {
          titles: verifyResultText,
          titlesStyle: [
            isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
          ],
        },
    ],
    [isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  useEffect(() => {
    MaterialStatusApi.getAllMaterialStatus((_data) =>
      setMaterialStatus(_data.map((item) => ({ text: item.name, value: item.id }))),
    );

    SpecificationApi.getSpecificationTypeByExptItemCode(
      {
        exptItemCode: TestItemEnum.CONCRETE_SLUMP_FLOW_TEST,
      },
      (_data) => setSccType(_data.map((item) => ({ text: item.name, value: item.id }))),
    );
  }, []);

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportConcreteSlumpFlow = {
        plateNumber: values.plateNumber,
        slumpFlowDemand: checkIsNaN(+values.slumpFlowDemand),
        visObsSlumpFlow: checkIsNaN(+values.visObsSlumpFlow),
        slumpFlowD1: checkIsNaN(+values.slumpFlowD1),
        slumpFlowD2: checkIsNaN(+values.slumpFlowD2),
        beforeTubeSlump: checkIsNaN(+values.beforeTubeSlump),
        afterTubeSlump: checkIsNaN(+values.afterTubeSlump),
        b50: checkIsNaN(+values.b50),
        airContent: checkIsNaN(+values.airContent),
        chlorideIonContent: checkIsNaN(+values.chlorideIonContent),
        chlorideIonContent2: checkIsNaN(+values.chlorideIonContent2),
        chlorideIonContent3: checkIsNaN(+values.chlorideIonContent3),
        concreteTemp: checkIsNaN(+values.concreteTemp),
        specificationId: selectSccType,
        isDoingVFunnel: testDetail?.isDoingVFunnel,
        isDoingUBox: testDetail?.isDoingUBox,
        materialStatusId: selectStatus,
        specimenNum: values.specimenNum,
      };

      TestApi.putTest<PutConcreteSlumpFlowRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const editView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack gap={2}>
            <Stack flexDirection="row">
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.SLUMP_FLOW_DEMAND`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.slumpFlowDemand}
                  isError={!!(formik.touched.slumpFlowDemand && formik.errors.slumpFlowDemand)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('slumpFlowDemand', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.VIS_OBS_SLUMP_FLOW`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.visObsSlumpFlow}
                  isError={!!(formik.touched.visObsSlumpFlow && formik.errors.visObsSlumpFlow)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('visObsSlumpFlow', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.B50`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.b50}
                  isError={!!(formik.touched.b50 && formik.errors.b50)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('b50', e.target.value)}
                />
              </ColFieldInfo>
            </Stack>
            <Stack flexDirection="row">
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.SLUMP_FLOW_01`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.slumpFlowD1}
                  isError={!!(formik.touched.slumpFlowD1 && formik.errors.slumpFlowD1)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('slumpFlowD1', e.target.value)}
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.SLUMP_FLOW_02`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.slumpFlowD2}
                  isError={!!(formik.touched.slumpFlowD2 && formik.errors.slumpFlowD2)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('slumpFlowD2', e.target.value)}
                />
              </ColFieldInfo>
            </Stack>
            <Stack flexDirection="row">
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.BEFORE_TUBE_SLUMP`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.beforeTubeSlump}
                  isError={!!(formik.touched.beforeTubeSlump && formik.errors.beforeTubeSlump)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('beforeTubeSlump', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.AFTER_TUBE_SLUMP`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.afterTubeSlump}
                  isError={!!(formik.touched.afterTubeSlump && formik.errors.afterTubeSlump)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('afterTubeSlump', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 22,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.MATERIAL_STATUS`)}
              >
                <YatungRadioButton
                  groupName="status"
                  value={selectStatus}
                  onChange={(e) => setSelectStatus(+e.target.value)}
                  controlData={materialStatus?.map((item) => ({ label: item.text as string, value: item.value })) ?? []}
                  labelTextStyle={{
                    fontSize: '20px',
                    fontWeight: '600',
                    fontFamily: 'Microsoft JhengHei',
                    color: '#002F8C',
                  }}
                />
              </ColFieldInfo>
            </Stack>
            <Stack flexDirection="row">
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.AIR_CONTENT`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.airContent}
                  isError={!!(formik.touched.airContent && formik.errors.airContent)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('airContent', e.target.value)}
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE_FLOW.CHLORIDE_CONTENT`)}1(kg/m³)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.chlorideIonContent}
                  isError={!!(formik.touched.chlorideIonContent && formik.errors.chlorideIonContent)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('chlorideIonContent', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE_FLOW.CHLORIDE_CONTENT`)}2(kg/m³)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.chlorideIonContent2}
                  isError={!!(formik.touched.chlorideIonContent2 && formik.errors.chlorideIonContent2)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('chlorideIonContent2', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={`${i18T(`TEST.CONCRETE_FLOW.CHLORIDE_CONTENT`)}3(kg/m³)`}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.chlorideIonContent3}
                  isError={!!(formik.touched.chlorideIonContent3 && formik.errors.chlorideIonContent3)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('chlorideIonContent3', e.target.value)
                  }
                />
              </ColFieldInfo>
              <ColFieldInfo
                titleStyle={{
                  ...TestVerifyResultStyles.resultTitle,
                  borderBottom: '1px solid #ccc',
                  paddingBottom: 20,
                }}
                title={i18T(`TEST.CONCRETE_FLOW.TEMPERATURE`)}
              >
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.concreteTemp}
                  isError={!!(formik.touched.concreteTemp && formik.errors.concreteTemp)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('concreteTemp', e.target.value)}
                />
              </ColFieldInfo>
            </Stack>
            {sccType && sccType?.length > 0 && (
              <Stack flexDirection="row">
                <ColFieldInfo
                  titleStyle={{
                    ...TestVerifyResultStyles.resultTitle,
                    borderBottom: '1px solid #ccc',
                    paddingBottom: 24,
                  }}
                  title={i18T(`TEST.CONCRETE_FLOW.SCC_CONCRETE_TYPE`)}
                >
                  <YatungRadioButton
                    groupName="status"
                    value={selectSccType}
                    onChange={(e) => setSelectSccType(+e.target.value)}
                    controlData={sccType?.map((item) => ({ label: item.text as string, value: item.value })) ?? []}
                    labelTextStyle={{
                      fontSize: '20px',
                      fontWeight: '600',
                      fontFamily: 'Microsoft JhengHei',
                      color: '#002F8C',
                    }}
                  />
                </ColFieldInfo>
              </Stack>
            )}
          </Stack>
        );
      case false:
        return (
          <>
            <TestTable headerData={firstHeader} rows={firstInfo} />
            <TestTable headerData={secondHeader} rows={secondInfo} />
            <TestTable headerData={thirdHeader} rows={thirdInfo} />
            <TestTable headerData={seventhHeader} rows={seventhInfo} />
            <TestTable headerData={fourthHeader} rows={fourthInfo} />
            <TestTable headerData={fifthHeader} rows={fifthInfo} />
          </>
        );
    }
  }, [
    edit,
    firstHeader,
    firstInfo,
    secondHeader,
    secondInfo,
    thirdHeader,
    thirdInfo,
    fourthHeader,
    fourthInfo,
    fifthHeader,
    fifthInfo,
    selectStatus,
    i18T,
    formik,
    materialStatus,
    sccType,
    selectSccType,
    seventhHeader,
    seventhInfo,
  ]);

  return (
    <Stack gap={3}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {editView}
      </TestCardContainer>
      <TestCardContainer>
        <TestTable headerData={sixthHeader} rows={sixthInfo} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportConcreteSlumpFlowTable);
