import { Typography } from '@mui/material';
import { AreaManagementData } from '@Src/_basic/object/AreaType';
import React from 'react';

interface Props {
  row: AreaManagementData;
}
export default function IdColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.sortingOrder}</Typography>;
}
