import { Typography } from '@mui/material';
import React from 'react';

export type ExtraData = {
  enableText: string;
  disabledText: string;
};
interface Props {
  cellContent: boolean;
  formatExtraData: ExtraData;
}
export default function StateColumnFormatter(props: Props) {
  const { cellContent, formatExtraData } = props;
  return (
    <Typography
      variant="inherit"
      sx={{
        color: cellContent ? '#3E9B2F' : '#CB333B',
        fontWeight: 400,
        fontSize: 16,
      }}
    >
      {cellContent ? formatExtraData.enableText : formatExtraData.disabledText}
    </Typography>
  );
}
