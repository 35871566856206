import { Protocol } from '../Protocol';

export class MonthlyReposrtProtocol {
  public static readonly MONTHLY_REPORT: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/expt/item/all`; // 取得試驗項目
  public static readonly FINE_AGG_ANALYSIS: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/fineAggregateAnalysis`; // 細粒料篩分析
  public static readonly FINE_AGG_AMS: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/fineAggregateAMS`; // 細粒料AMS檢測
  public static readonly FINE_AGG_ABSORPTION: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/fineAggregateFineSandProportionAbsorption`; // 細粒料比重吸水率
  public static readonly FINE_AGG_CHLORIDE: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/fineAggregateChlorideIons`; // 細粒料氯離子檢測
  public static readonly COARSE_AGG_ANALYSIS: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/coarseAggregateAnalysis`; // 粗粒料篩分析
  public static readonly COARSE_AGG_ABSORPTION: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/fineAggregateCoarseSandProportionAbsorption`; // 粗粒料比重吸水率
  public static readonly FLY_ASH_L_VALUE: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/flyAshLValue`; // 飛灰L值
  public static readonly FLY_ASH_LOSS_OF_IGNITION: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/flyAshLossOfIgnition`; // 飛灰燒失量
  public static readonly CONCRETE_ANTI_STRESS: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/concreteAntiStress`; // 混凝土抗壓試驗
  public static readonly FINE_AGG_R_VALUE: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/fineAggregateRValue`; // 細粒料R值試驗
  public static readonly MONTHLY_REPORT_VIEW: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/view/search`; // 取得月報檢視資訊
  public static readonly REPORT_VIEW: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/view`; // 透過Id取得指定月報
  public static readonly REPORT_VIEW_INFO: string = `${Protocol.YATUNG_API_ROOT}/monthlyReport/view/info`; // 透過Id取得指定月報
  public static readonly GET_MONTHLY_REPORT_EXCEL: string = `${Protocol.YATUNG_API_ROOT}/export/monthlyReportExcel`; // 取得所有月報Excel
}
