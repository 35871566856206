import YatungButton from '@Src/_basic/components/YatungButton';
import { Options } from '@Src/_basic/components/YatungDynamicComboBox';
import YatungDynamicSelect from '@Src/_basic/components/YatungDynamicSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import DeleteCircle from '@Src/_basic/icons/DeleteCircle';
import { CreateSignatureItemDetailRequest } from '@Src/_basic/object/SignatureType';
import { useSignature } from '@Src/redux/signature/signatureActions';
import { Box, IconButton, SelectChangeEvent } from '@mui/material';
import React, { useMemo } from 'react';

interface Props {
  data: Array<CreateSignatureItemDetailRequest>;
  specificationOptions: Array<Options>;
  dynamicSpecificationOptions: Array<Options>;
  onSpecificationChange?: (specificationId: number, detailOrder: number) => void;
  onBomChange?: (BOMId: number, detailOrder: number) => void;
  onDetailValueChange?: (detailValue: string, detailOrder: number) => void;
  onAddDetailsButtonClick?: () => void;
  onDeleteDetailButtonClick?: (detailOrder: number) => void;
}

export default function SignatrueItemDetailsForm({
  data,
  specificationOptions,
  dynamicSpecificationOptions,
  onSpecificationChange,
  onDetailValueChange,
  onBomChange,
  onAddDetailsButtonClick,
  onDeleteDetailButtonClick,
}: Props) {
  const { bomHierarchy } = useSignature();

  const isRenderAddSignatureItemDetailsButton = useMemo(() => {
    return (data?.length || 0) < specificationOptions?.length;
  }, [data?.length, specificationOptions?.length]);

  const getBomHierarchyOptions = (specificationId: number) => {
    let bomHierarchyOptions: Array<Options> = [];
    bomHierarchy.forEach((bom) =>
      bom.specificationList.forEach((specification) => {
        if (specification.specificationId === specificationId) {
          bomHierarchyOptions = Object.entries(specification.bomlist).map(([key, value]) => ({
            text: value,
            value: Number(key),
          }));
        }
      }),
    );
    return bomHierarchyOptions;
  };

  const handleSpecificatiOnChange = (specificationId: number, detailOrder: number) => {
    onSpecificationChange?.(specificationId, detailOrder);
    handleBomoOnChange(0, detailOrder);
  };

  const handleBomoOnChange = (BOMId: number, detailOrder: number) => {
    onBomChange?.(BOMId, detailOrder);
  };

  const handleDetailValueChange = (detailValue: string, detailOrder: number) => {
    onDetailValueChange?.(detailValue, detailOrder);
  };

  const handleAddDetailsButtonClick = () => {
    onAddDetailsButtonClick?.();
  };

  const handleDeleteDetailsButtonClick = (detailOrder: number) => {
    onDeleteDetailButtonClick?.(detailOrder);
  };

  return (
    <>
      {data?.map((item: CreateSignatureItemDetailRequest, index: number) => (
        <Box key={index} sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <YatungDynamicSelect
            totalOptions={specificationOptions}
            options={dynamicSpecificationOptions}
            value={item.specificationId}
            width="100%"
            onChange={(e: any) => {
              handleSpecificatiOnChange(e.target.value, item.order);
            }}
          />
          <YatungSelect
            options={getBomHierarchyOptions(item.specificationId)}
            value={item.BOMId}
            width="100%"
            onChange={(e: SelectChangeEvent<unknown>) => {
              handleBomoOnChange(e.target.value as number, item.order);
            }}
          />
          <YatungInput
            value={item.detailValue}
            onChange={(e: any) => {
              handleDetailValueChange(e.target.value, item.order);
            }}
          />
          <IconButton>
            <DeleteCircle onClick={() => handleDeleteDetailsButtonClick(item.order)} />
          </IconButton>
        </Box>
      ))}
      {isRenderAddSignatureItemDetailsButton && (
        <YatungButton text="新增" color="blue" onClick={handleAddDetailsButtonClick} />
      )}
    </>
  );
}
