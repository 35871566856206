import { Response } from '@Src/_basic/object/ApiType';
import {
  BomHierarchyData,
  MaterialFactoryCodeData,
  PostMaterialFactoryCodeRequest,
  PutMaterialFactoryCodeRequest,
  SearchMaterialFactoryCodeData,
  SearchMaterialFactoryCodeParams,
  getBomHierarchyParams,
} from '@Src/_basic/object/MaterialFactoryCodeType';
import { Api } from '../Api';
import { MaterialFactoryCodeProtocol } from './MaterialFactoryCodeProtocol';

export class MaterialFactoryCodeApi {
  // 取得所有物料表紀錄
  public static getMaterialFactory(
    params: SearchMaterialFactoryCodeParams,
    onSuccess?: (data: SearchMaterialFactoryCodeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(MaterialFactoryCodeProtocol.ALL_BOM, config, onSuccess, onFailed, onFinally);
  }

  // 修改物料表紀錄
  public static putMaterialFactory(
    request: PutMaterialFactoryCodeRequest,
    onSuccess?: (data: MaterialFactoryCodeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(MaterialFactoryCodeProtocol.BOM_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增物料表紀錄
  public static postMaterialFactory(
    request: PostMaterialFactoryCodeRequest,
    onSuccess?: (data: MaterialFactoryCodeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(MaterialFactoryCodeProtocol.BOM_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得階層式物料編碼
  public static getBomHierarchy(
    params: getBomHierarchyParams,
    onSuccess?: (data: BomHierarchyData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MaterialFactoryCodeProtocol.BOM_HIERARCHY, config, onSuccess, onFailed, onFinally);
  }
}
