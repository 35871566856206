import YatungAlert from '@Src/_basic/components/YatungAlert';
import YatungPage from '@Src/_basic/components/YatungPage';
import { OrganiztionsData } from '@Src/_basic/object/AccountRightType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { OrganizationApi } from '@Src/_basic/protocol/accountRights/organization/OrganizationApi';
import { Grid, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizeManagementCreateCard from '../OrganizeManagementCreateCard';
import OrganizeManagementDeleteCard from '../OrganizeManagementDeleteCard';
import OrganizeManagementUpdateCard from '../OrganizeManagementUpdateCard';
import OrganizeManagementSearchBar from './OrganizeManagementSearchBar';
import OrganizeManagementTable from './OrganizeManagementTable';

const background = '/media/images/background.png';

export default function OrganizeManagementCard() {
  const { t: i18T } = useTranslation();
  const [organizationData, setOrganizationData] = useState<Array<OrganiztionsData>>([]);
  const [targetId, setTargetId] = useState<number>(0);
  const [targetName, setTargetName] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertTitle, setSetAlertTitle] = useState<string>('');
  const [showCreateCard, setShowCreateCard] = useState<boolean>(false);
  const [showUpdateCard, setShowUpdateCard] = useState<boolean>(false);
  const [showDeleteCard, setShowDeleteCard] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 1,
    pageSize: 20,
    total: 0,
    totalCount: 0,
  });

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };

  const resetPage = (page: number) => {
    setPageInfo({
      ...pageInfo,
      page: 1,
    });
  };

  const getPageableOrganizeData = () => {
    OrganizationApi.getPageableOrganizationsBySearch(
      {
        pageNum: pageInfo.page,
        pageSize: pageInfo.pageSize,
        keyword: inputValue ?? '',
      },
      (_data) => {
        setOrganizationData(
          _data.data.map((item: OrganiztionsData, index: number) => ({
            ...item,
            index: index + 1 + (pageInfo.page - 1) * pageInfo.pageSize,
          })),
        );
        setPageInfo({
          ...pageInfo,
          total: _data.maxPage,
        });
      },
    );
  };

  useEffect(() => {
    getPageableOrganizeData();
  }, [pageInfo.page, inputValue, alertIsOpen]);

  return (
    <>
      <YatungPage
        title={i18T('ORGANIZEMANAGEMENT.PAGE_TITLE')}
        // backgroundImage={background}
        body={
          <>
            <OrganizeManagementSearchBar
              setInputValue={setInputValue}
              setShowCreateCard={setShowCreateCard}
              resetPage={resetPage}
            />
            <OrganizeManagementTable
              organizationData={organizationData}
              setTargetId={setTargetId}
              setTargetName={setTargetName}
              setShowUpdateCard={setShowUpdateCard}
              setShowDeleteCard={setShowDeleteCard}
            />
            <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
              <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
            </Grid>
          </>
        }
        contentBgColor="#FFF"
      />
      {showCreateCard ? (
        <OrganizeManagementCreateCard
          showCreateCard={showCreateCard}
          setShowCreateCard={setShowCreateCard}
          setAlertIsOpen={setAlertIsOpen}
          setSetAlertTitle={setSetAlertTitle}
        />
      ) : (
        <></>
      )}
      {showUpdateCard ? (
        <OrganizeManagementUpdateCard
          targetId={targetId}
          targetName={targetName}
          showUpdateCard={showUpdateCard}
          setShowUpdateCard={setShowUpdateCard}
          setAlertIsOpen={setAlertIsOpen}
          setSetAlertTitle={setSetAlertTitle}
        />
      ) : (
        <></>
      )}
      {showDeleteCard ? (
        <OrganizeManagementDeleteCard
          targetId={targetId}
          targetName={targetName}
          showDeleteCard={showDeleteCard}
          setShowDeleteCard={setShowDeleteCard}
          setAlertIsOpen={setAlertIsOpen}
          setSetAlertTitle={setSetAlertTitle}
        />
      ) : (
        <></>
      )}
      <YatungAlert title={alertTitle} open={alertIsOpen} setOpen={setAlertIsOpen} type={'success'} />
    </>
  );
}
