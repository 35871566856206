import {
  ChangeTestItemPassStatusParams,
  ChangeTestItemPassStatusResponseData,
  FindAndRejectRecordData,
  FindAndRejectRecordParams,
  FindAndRejectRecordRequest,
  FineRecordData,
  FineRecordParams,
  UpdateRecheckPassParams,
  UpdateRecheckPassResponse,
} from '@Src/_basic/object/FineRecordType';
import { Response } from '../../object/ApiType';
import { Api } from '../Api';
import { FineRecordProtocol } from './FineRecordProtocol';

export class FineRecordApi {
  public static getFineRecordList(
    params: FineRecordParams,
    onSuccess?: (_data: FineRecordData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FineRecordProtocol.SearchRecord, config, onSuccess, onFailed, onFinally);
  }

  public static getChangeTestItemPassStatus(
    params: ChangeTestItemPassStatusParams,
    onSuccess?: (_data: ChangeTestItemPassStatusResponseData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FineRecordProtocol.ChangeTestItemPassStatus, config, onSuccess, onFailed, onFinally);
  }

  public static getFindAndRejectRecord(
    params: FindAndRejectRecordParams,
    onSuccess?: (_data: FindAndRejectRecordData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FineRecordProtocol.FindAndRejectRecord, config, onSuccess, onFailed, onFinally);
  }

  public static postFindAndRejectRecord(
    request: FindAndRejectRecordRequest,
    onSuccess?: (_data: FindAndRejectRecordData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(FineRecordProtocol.FindAndRejectRecord, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static getUpdateRecheckPass(
    params: UpdateRecheckPassParams,
    onSuccess?: (_data: UpdateRecheckPassResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FineRecordProtocol.UpdateRecheckPass, config, onSuccess, onFailed, onFinally);
  }
}
