import { CardMedia } from '@mui/material';
import React from 'react';

export default function PelletsBi() {
  return (
    <CardMedia
      component="iframe"
      src="https://app.powerbi.com/reportEmbed?reportId=bddbc325-eca0-4c6f-a029-4bc8602d89a5&autoAuth=true&ctid=ab35a555-3574-4bb8-9c15-c26a9300d0ff"
      sx={{ width: '80vw', height: '80vh' }}
    ></CardMedia>
  );
}
