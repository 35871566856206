import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function Fail(props: SvgIconProps) {
  return (
    <SvgIcon width="3" height="14" viewBox="0 0 3 14" fill="none" {...props}>
      <path
        d="M0.698824 0C0.312901 0 0 0.31825 0 0.710769V9.59538C0 9.9879 0.312901 10.3062 0.698824 10.3062H2.27118C2.6571 10.3062 2.97 9.9879 2.97 9.59538V0.710769C2.97 0.31825 2.6571 0 2.27118 0H0.698824Z"
        fill="white"
      />
      <path
        d="M0.698824 10.8392C0.312901 10.8392 0 11.1575 0 11.55V13.1492C0 13.5417 0.312901 13.86 0.698824 13.86H2.27118C2.6571 13.86 2.97 13.5417 2.97 13.1492V11.55C2.97 11.3154 2.85808 11.1072 2.68576 10.9777C2.56975 10.8906 2.42643 10.8392 2.27118 10.8392H0.698824Z"
        fill="white"
      />
    </SvgIcon>
  );
}
