import { Grid, Pagination } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentAndGroupsTableType } from '@Src/_basic/object/AccountRightType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';

import useGetAllFactories from '@Src/_basic/apiHooks/useGetAllFactories';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { GroupApi } from '@Src/_basic/protocol/accountRights/fetAuth/group/GroupApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { useLocation } from 'react-router';
import AccountGroupManagementCreateCard from '../AccountGroupManagementCreateCard';
import AccountGroupManagementUpdateCard from '../AccountGroupManagementUpdateCard';
import AccountGroupManagementSearchBar from './AccountGroupManagementSearchBar';
import AccountGroupManagementTable from './AccountGroupManagementTable';

const background = '/media/images/background.png';

export type FilterValue = {
  inputValues: string;
  factory: number;
};

type Query = FilterValue & {
  page: number;
};

export default function AccountGroupManagementCard() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<Query>();
  const { withoutAllFactoryOption } = useGetAllFactories();

  const effectRef = useRef<boolean>(false);
  const [isVisable, setIsVisable] = useState<boolean>(false);
  const [modelOpen, setModelOpen] = useState<'CREATE' | 'UPDATE' | 'DELETE' | null>(null);

  const [accountGroupData, setAccountGroupData] = useState<Array<ContentAndGroupsTableType>>([]);
  const [selectedGroupData, setSelectedGroupData] = useState<ContentAndGroupsTableType | undefined>(undefined);

  const initFilterValue = useMemo(() => {
    const { inputValues = '', factory = 0 } =
      location.pathname === request?.page && request?.request ? request.request : {};

    return {
      inputValues,
      factory,
    };
  }, [location.pathname, request]);

  const initPageInfo = useMemo(
    () => ({
      page: location.pathname === request?.page && request?.request ? request.request.page : 1,
      pageSize: 10,
      total: 0,
      totalCount: 0,
    }),
    [location.pathname, request],
  );

  const [filterValue, filterDispatch] = useReducer<Reducer<FilterValue, Partial<FilterValue>>>(
    updateReducer,
    initFilterValue,
  );
  const [pageInfo, pageDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const postGetAccountGroupList = useCallback(
    (page: number) => {
      if (!filterValue.factory) return;
      GroupApi.postGetRightsGroups(
        {
          pageNumber: page - 1,
          pageSize: pageInfo.pageSize,
          attrs: [
            {
              code: 'type',
              attrVal: 'user',
            },
            {
              code: 'factory',
              attrVal: filterValue.factory.toString(),
            },
          ],
          ...(filterValue.inputValues && { keywords: [filterValue.inputValues] }),
        },
        (_data) => {
          setAccountGroupData(
            _data.content.map((item, index) => ({
              ...item,
              index: index + 1 + (page - 1) * pageInfo.pageSize,
            })),
          );
          pageDispatch({
            page,
            total: _data.totalPages,
          });
          setSaveRequest({
            page: location.pathname,
            request: {
              ...filterValue,
              page,
            },
          });
          setIsVisable(true);
        },
      );
    },
    [setSaveRequest, filterValue, location.pathname, pageInfo.pageSize],
  );

  const updateAndDeleteModel = useCallback((data: ContentAndGroupsTableType, model: 'UPDATE' | 'DELETE') => {
    setModelOpen(model);
    setSelectedGroupData(data);
  }, []);

  const closeModel = useCallback(() => {
    setModelOpen(null);
    setSelectedGroupData(undefined);
  }, []);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageDispatch({ page });
    postGetAccountGroupList(page);
  };

  const handleChange = useCallback((file: keyof FilterValue, value: FilterValue[keyof FilterValue]) => {
    filterDispatch({ [file]: value });
    pageDispatch({ page: 1, total: 0 });
    setAccountGroupData([]);
    setIsVisable(false);
  }, []);

  const getCurrentPageAccountGroupList = useCallback(
    () => postGetAccountGroupList(pageInfo.page),
    [pageInfo.page, postGetAccountGroupList],
  );

  const postDeleteGroupById = useCallback(() => {
    if (!selectedGroupData) return;
    GroupApi.postDeleteRightsGroupById(selectedGroupData.id, getCurrentPageAccountGroupList);
  }, [getCurrentPageAccountGroupList, selectedGroupData]);

  useEffect(() => {
    if (!effectRef.current) {
      effectRef.current = true;
      getCurrentPageAccountGroupList();
    }
  }, [effectRef]);

  return (
    <>
      <YatungPage
        title={i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.ROUTE_TITLE')}
        backgroundImage={background}
        body={
          <>
            <AccountGroupManagementSearchBar
              filterValue={filterValue}
              handleChange={handleChange}
              openCreateCard={() => setModelOpen('CREATE')}
              factoryOptions={withoutAllFactoryOption}
              postGetAccountGroupList={getCurrentPageAccountGroupList}
            />
            {
              <YatungFadeInOut isVisible={isVisable}>
                <>
                  <AccountGroupManagementTable
                    updateAndDeleteModel={updateAndDeleteModel}
                    accountGroupData={accountGroupData}
                    factoryOptions={withoutAllFactoryOption}
                  />
                  <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
                    <Pagination
                      count={pageInfo.total}
                      page={pageInfo.page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Grid>
                </>
              </YatungFadeInOut>
            }
          </>
        }
        contentBgColor="#FFF"
      />
      {modelOpen === 'CREATE' && (
        <AccountGroupManagementCreateCard
          open={modelOpen === 'CREATE'}
          closeModel={closeModel}
          factoryOptions={withoutAllFactoryOption}
          postGetAccountGroupList={getCurrentPageAccountGroupList}
        />
      )}
      {modelOpen === 'UPDATE' && !!selectedGroupData && (
        <AccountGroupManagementUpdateCard
          open={modelOpen === 'UPDATE'}
          closeModel={closeModel}
          factoryOptions={withoutAllFactoryOption}
          selectedGroupData={selectedGroupData}
          postGetAccountGroupList={getCurrentPageAccountGroupList}
        />
      )}
      <YatungConfirmAlert isShowAlert={modelOpen === 'DELETE'} onClose={closeModel} onOk={postDeleteGroupById} />
    </>
  );
}
