import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import DeliveryInformationByDetailSreen from './screens/MonitorByDetailSreen';
import MonitorByFactoryScreen from './screens/MonitorByFactoryScreen';
import DeliveryInformationDetailCardScreen from './screens/MonitorDetailCardScreen';
import SelectAreaScreen from './screens/SelectAreaScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/selectArea/*',
    element: <SelectAreaScreen />,
  },
  {
    path: '/deliveryInformationByFactory/*',
    element: <MonitorByFactoryScreen />,
  },
  {
    path: '/deliveryInformationByDetail/*',
    element: <DeliveryInformationByDetailSreen />,
  },
  {
    path: '/deliveryInformationDetailCard/*',
    element: <DeliveryInformationDetailCardScreen />,
  },
];

const MonitorInformationRouter = () => PermissionRoute(routesConfig);

export default memo(MonitorInformationRouter);
