import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function AddCircleFill(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12,22c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10C6.5,2,2,6.5,2,12C2,17.5,6.5,22,12,22z M11.8,3.3c0.5,0,0.8,0.4,0.8,0.8V11h6.9c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8h-6.9v6.9c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8v-6.9H4.1c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8H11V4.1C11,3.6,11.3,3.3,11.8,3.3z" />
    </SvgIcon>
  );
}
