import { ConcreteExptData } from '@Src/_basic/object/ProductionHistoryType';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { FixedSizeList } from 'react-window';
import ExptInformationCard from './components/ExptInformationCard';

interface Props {
  concreteExptsData: Array<ConcreteExptData>;
  height: number;
  itemSize: number;
  onEmptyText: string;
}

export default function ExptInformationCards({ concreteExptsData, height, itemSize, onEmptyText }: Props) {
  return (
    <>
      {concreteExptsData.length > 0 ? (
        <FixedSizeList
          className={'scroll-bar'}
          height={height}
          width={'100%'}
          itemData={concreteExptsData}
          itemCount={concreteExptsData.length}
          itemSize={itemSize}
        >
          {({ data, index, style }) => (
            <div style={style}>
              <ExptInformationCard concreteExpt={data[index]} />
            </div>
          )}
        </FixedSizeList>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: height, opacity: 0.3 }}>
          <Typography variant="h1">{onEmptyText}</Typography>
        </Box>
      )}
    </>
  );
}
