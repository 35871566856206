import { Protocol } from '../Protocol';

export class MixerProtocol {
  //   public static readonly ALL_FACTORY:string = `${Protocol.YATUNG_API_ROOT}/factory/all`;
  public static readonly ALL_MIXER_MOCK: string = `api/mixer`;
  public static readonly GET_ALL_MIXER: string = `${Protocol.YATUNG_API_ROOT}/mixer/V2/web/all`;
  public static readonly GET_MIXER_BYID: string = `${Protocol.YATUNG_API_ROOT}/mixer/V2/web`;
  public static readonly GET_MIXER_OPTION: string = `${Protocol.YATUNG_API_ROOT}/mixer/all`;
  public static readonly GET_MIXER_OPTION_V2: string = `${Protocol.YATUNG_API_ROOT}/mixer/V2/all`;
  public static readonly GET_MIXER_PROPORTION_OPTION: string = `${Protocol.YATUNG_API_ROOT}/mixer/option/all`;
}
