import React from 'react';
import ReportGroupManagementCreateCard from '../components/ReportGroupManagementCreateCard';

export default function ReportGroupManagementCreateCardScreen() {
  return (
    <>
      <ReportGroupManagementCreateCard />
    </>
  );
}
