import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';

import { QueryType, timeRangeOptions } from '../pages/ProportionList';

import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import { Options } from '@Src/_basic/components/YatungFormSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungRowField from '@Src/_basic/components/YatungRowField';
import CheckButton from './CheckButton';
import SortPanel from './SortPanel';
import TagsSelectPanel from './TagsSelectPanel';

export interface selectSortType {
  value: number;
  desc: boolean;
}

interface ProportionListFilterPanelProps {
  filterValue: QueryType;
  handleChange: <K extends keyof QueryType>(field: K) => (value: QueryType[K]) => void;
  tagItems: Array<Options>;
  selectTag: Array<Options>;
  handlerLastUsedTime: (value: number) => void;
  handleTagsSelect: (value: Options) => void;
  handleFilterMode: (value: number) => void;
  handleSortChange: (value: string) => void;
  sortItems: Array<Options>;
  filterMode: number;
  filterSearch: () => void;
}

const selectedTabBtnStyle = {
  fontSize: '24px',
  py: '16px',
  width: '240px',
};

const unSelectedTabBtnStyle = {
  fontSize: '16px',
  py: '8px',
  backgroundColor: '#697EA3',
  width: '144px',
};

const titleStyle = {
  fontSize: 20,
  color: '#003087',
  fontWeight: 900,
};

const ProportionListFilterPanel = ({
  handleTagsSelect,
  handleFilterMode,
  filterValue,
  handleChange,
  handlerLastUsedTime,
  handleSortChange,
  tagItems,
  selectTag,
  sortItems,
  filterMode,
  filterSearch,
}: ProportionListFilterPanelProps) => {
  return (
    <Card sx={{ mt: 2, minHeight: '400px' }}>
      <CardContent>
        <Stack flexDirection="row" justifyContent={'space-between'} gap={6}>
          <Card
            sx={{
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              flex: 1,
            }}
          >
            <CardContent>
              <YatungButton
                color="blue"
                onClick={() => handleFilterMode(0)}
                sx={{
                  mr: '16px',
                  ...(filterMode === 0 ? selectedTabBtnStyle : unSelectedTabBtnStyle),
                }}
              >
                條件篩選
              </YatungButton>
              <YatungButton
                color="blue"
                onClick={() => handleFilterMode(1)}
                sx={{
                  ...(filterMode === 1 ? selectedTabBtnStyle : unSelectedTabBtnStyle),
                }}
              >
                出貨日期
              </YatungButton>
              <Box sx={{ my: 2 }}>
                <YatungRowField label="配比名稱:" labelStyle={{ color: '#003087' }}>
                  <YatungInput
                    sx={{ flex: 1 }}
                    value={filterValue.blurPropName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('blurPropName')(e.target.value)}
                  />
                </YatungRowField>
              </Box>
              <Box sx={{ my: 2 }}>
                <YatungRowField label="設計強度(kgf/cm²):" labelStyle={{ color: '#003087' }}>
                  <YatungInput
                    type="number"
                    sx={{ flex: 1 }}
                    value={filterValue.dsgnStr}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('dsgnStr')(+e.target.value)}
                  />
                </YatungRowField>
              </Box>
              <Box sx={{ my: 2 }}>
                <YatungRowField label="坍度(cm):" labelStyle={{ color: '#003087' }}>
                  <YatungInput
                    type="number"
                    sx={{ flex: 1 }}
                    value={filterValue.slump}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('slump')(+e.target.value)}
                  />
                </YatungRowField>
              </Box>
              <Box sx={{ my: 2 }}>
                <YatungRowField label="粒徑(mm):" labelStyle={{ color: '#003087' }}>
                  <YatungInput
                    type="number"
                    sx={{ flex: 1 }}
                    value={filterValue.grainSize}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('grainSize')(+e.target.value)}
                  />
                </YatungRowField>
              </Box>
              <Box sx={{ mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <YatungButton color="blue" text="搜尋" width={120} onClick={filterSearch} />
              </Box>
            </CardContent>
          </Card>
          {filterMode === 0 ? (
            <Stack flexDirection="row" flex={2} gap={6}>
              <Box flex={1}>
                <Card
                  sx={{
                    mb: 2,
                    background: 'linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%)',
                    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2);',
                  }}
                >
                  <CardContent>
                    <Typography sx={titleStyle}>最近使用配比時間：</Typography>
                    {timeRangeOptions.map(({ text, value }, i) => (
                      <CheckButton
                        key={i}
                        checked={value === filterValue.lastUsedValue}
                        onClick={() => handlerLastUsedTime(value)}
                      >
                        {text}
                      </CheckButton>
                    ))}
                  </CardContent>
                </Card>
                <SortPanel selectSort={filterValue.sort} sortItems={sortItems} handleSortChange={handleSortChange} />
              </Box>
              <Box flex={1}>
                <TagsSelectPanel selectedTags={selectTag} tagItems={tagItems} handleTagsSelect={handleTagsSelect} />
              </Box>
            </Stack>
          ) : (
            <>
              <Box sx={{ width: '443px', maxHeight: '155px' }}>
                <Card
                  sx={{
                    boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
                    background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
                  }}
                >
                  <CardContent>
                    <Typography sx={titleStyle}>資料範圍：</Typography>
                    <Stack flexDirection="row" alignItems="center" sx={{ my: 2 }}>
                      <Typography sx={{ ...titleStyle, flex: '1 0 0' }}>起始時間：</Typography>
                      <YatungDateSelect
                        value={new Date(filterValue.startTime as number)}
                        onChange={(value: Date | null) => handleChange('startTime')((value as Date).getTime())}
                      />
                    </Stack>
                    <Stack flexDirection="row" alignItems="center" sx={{ my: 2 }}>
                      <Typography sx={{ ...titleStyle, flex: '1 0 0' }}>結束時間：</Typography>
                      <YatungDateSelect
                        value={new Date(filterValue.endTime as number)}
                        onChange={(value: Date | null) => handleChange('endTime')((value as Date).getTime())}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
              <Box sx={{ width: '443px' }}></Box>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProportionListFilterPanel;
