import YatungFadeMenu from '@Src/_basic/components/YatungFadeMenu';
import { useAuth } from '@Src/redux/auth/authActions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function RealTimeMonitoring() {
  const { t: i18T } = useTranslation();
  const { userApiPermission } = useAuth();
  const [newRouteSideMenu, setNewRouteSideMenu] = React.useState<Array<any>>([]);

  const routeSideMenu = [
    {
      title: i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.TITLE'),
      toUrl: '/realTimeMonitoring/testInDistrict/testsDistrictByArea',
      code: 'WD039',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.MATERIAL_INSPECTION_PASS_MONITORING.TITLE'),
      toUrl: '/realTimeMonitoring/materialInspection/materialInspectionByArea',
      code: 'WD040',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.PAGE_TITLE'),
      toUrl: '/realTimeMonitoring/deliveryInformation/selectArea',
      code: 'WD041',
    },
  ];

  useEffect(() => {
    const routeSideMenuTemp: Array<any> = [];
    routeSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeSideMenuTemp.push(item);
      }
    });
    setNewRouteSideMenu(routeSideMenuTemp);
  }, [userApiPermission]);
  return (
    <>
      {newRouteSideMenu.length > 0 && (
        <YatungFadeMenu
          text={i18T('APPLICATIONSMANAGEMENT.SIDE_MENU.REALTIME_MONITORING.TITLE')}
          option={newRouteSideMenu}
        />
      )}
    </>
  );
}
