import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import SearchIcon from '@Src/_basic/icons/Search';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { ProjectData, ProjectInfoData } from '@Src/_basic/object/ProductionHistoryType';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Grid, Pagination, Stack } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductionHistoryTable from './components/ProductionHistoryTable';

import { PrintInfoProps } from '@Src/_basic/object/PrintProductionType';
import { SignatureApi } from '@Src/_basic/protocol/signature/SignatureApi';
import PrintSelectProportion from './components/print/PrintSelectProportion';

type SelectType = {
  startTime: number;
  endTime: number;
  factoryId: number;
};

type QueryType = SelectType & {
  facCode: string;
  engName: string;
};

type FilterValueType = QueryType & {
  areaId: number;
};

type SearchParams = QueryType & {
  page: number;
};

type AsyncStorageSavingType = SearchParams & FilterValueType;

export default function ProductionHistoryScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();
  const { userAreaOptionsData } = useAuth();
  const { actionLoading } = useApi();

  const {
    pageableProjects,
    getCancelablePageableProjectsBySearch,
    createProjectInfoProfileByOrderId,
    cleanPageableProjects,
  } = useProductionHistory();

  const [viewSelectProportion, setViewSelectProportion] = useState(false);
  const [info, setInfo] = useState<PrintInfoProps>();
  const [templateId, setTemplateId] = useState<string>('');

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue = useMemo(() => {
    const defaultStart = new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 3;
    const defaultEnd = new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 3;

    const {
      areaId = 0,
      factoryId = 0,
      facCode = '',
      engName = '',
      startTime = defaultStart,
      endTime = defaultEnd,
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      facCode,
      engName,
      startTime,
      endTime,
    };
  }, [request, location.pathname]);

  const initQueryValue = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
      facCode: initFilterValue.facCode,
      engName: initFilterValue.engName,
      startTime: initFilterValue.startTime,
      endTime: initFilterValue.endTime,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const handleFactoryChange = (value: number) => {
    const facCodeValue = userAreaOptionsData
      .find((item) => item.value === filterValue.areaId)
      ?.factories.find((item) => item.value === value)?.facCode;
    handleFilterChange('facCode')(value);
    if (facCodeValue) handleQueryChange('facCode')(facCodeValue);
  };

  const handleSearchButtonClick = (page: { page: number }) => {
    if (!query.facCode) return;
    const { factoryId, ...params } = query;
    pageInfoDispatch(page);
    setSaveRequest({
      page: location.pathname,
      request: { ...query, areaId: filterValue.areaId, ...page },
    });
    getCancelablePageableProjectsBySearch({ ...params, ...page, pageSize: pageInfo.pageSize });
    SignatureApi.getSignatureTemplateOrderId({ factoryId }, (_data) => setTemplateId(_data.orderId));
  };

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
    handleSearchButtonClick({ page });
  };

  const handleGenerateButtonClick = (row: ProjectData) => {
    createProjectInfoProfileByOrderId(row.orderId, (data: ProjectInfoData) => {
      goToViewByOrderId(data.orderId);
    });
  };

  const handleDetailButtonClick = (row: ProjectData) => {
    goToViewByOrderId(row.orderId);
  };

  const goToViewByOrderId = (orderId: string) => {
    navigate(`/report/production-history/view/${orderId}`);
  };

  const handleSelectPrintProject = (orderId: string, engName: string) => {
    setViewSelectProportion(true);
    setInfo({ orderId, factoryId: filterValue.factoryId, engName });
  };

  const handleCloseSelectPrintProject = useCallback(() => {
    setViewSelectProportion(false);
    setInfo(undefined);
  }, []);

  useEffect(() => {
    if (pageableProjects) {
      pageInfoDispatch({
        total: pageableProjects.maxPage,
        totalCount: pageableProjects.totalElements,
      });
    }
  }, [pageableProjects]);

  useEffect(() => {
    handleFactoryChange(filterValue.factoryId);
  }, [filterValue.factoryId]);

  useEffect(() => {
    setTimeout(() => {
      cleanPageableProjects();
      pageInfoDispatch({ total: 0, totalCount: 0 });
    }, 500);
  }, [filterValue]);

  useEffect(() => {
    handleSearchButtonClick({ page: initPageInfo.page });

    return () => cleanPageableProjects();
  }, []);

  return (
    <>
      <YatungPage
        title={i18T('REPORT.PRODUCTION_HISTORY.TITLE')}
        body={
          <>
            <Stack direction="row" spacing={2} alignItems="center">
              <YatungCombinationSelect<SelectType, FilterValueType>
                filterValue={filterValue}
                handleChange={handleChange}
                handleFilterChange={handleFilterChange}
                selectOptions={['Date', 'AreaAndFactory']}
              />
              <YatungInput
                value={filterValue.engName}
                onChange={(e: any) => handleChange('engName')(e.target.value)}
                disabled={actionLoading}
              />
              <YatungButton
                disabled={actionLoading}
                text={i18T('FACTORYMANAGEMENT.SEARCh_BUTTON')}
                startIcon={<SearchIcon />}
                color="blue"
                onClick={() => handleSearchButtonClick({ page: pageInfo.page })}
              />
            </Stack>

            <ProductionHistoryTable
              rows={pageableProjects?.data || []}
              onGenerate={handleGenerateButtonClick}
              onViewDetail={handleDetailButtonClick}
              templateId={templateId}
              handleSelectPrintProject={handleSelectPrintProject}
            />
            <Grid container alignItems={'center'} item xs={12}>
              <Grid container justifyContent={'center'} item xs={11}>
                <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
              </Grid>
              <Grid container justifyContent={'flex-end'} item xs={1}>
                <YatungPaperLabel label={`總數：${pageInfo.totalCount}`} totalCount />
              </Grid>
            </Grid>
          </>
        }
      />
      {info && viewSelectProportion && (
        <PrintSelectProportion open={viewSelectProportion} onClose={handleCloseSelectPrintProject} info={info} />
      )}
    </>
  );
}
