import React from 'react';
import { useTranslation } from 'react-i18next';
import TotalPageTitle from '@Src/_basic/components/YatungPageTitle';
import CementitiousBi from '../components/CementitiousBi';

export default function CementitiousBiScreen() {
  const { t: i18T } = useTranslation();
  return (
    <>
      <TotalPageTitle title={i18T('POWERBI.CEMENTITIOUS')} />
      <CementitiousBi />
    </>
  );
}
