export default [
  {
    id: 1,
    area: { value: 4, text: '北區' },
    factory: '汐止廠',
    address: '221新北市汐止區大同路一段139號',
    phone: '(02)2641-7171',
    symbol: 'C0',
    uppercaseLetter: 'C',
    numbers: '0',
    state: '關閉中',
    disabled: false,
  },
  {
    id: 2,
    area: { value: 5, text: '高屏區' },
    factory: '高雄廠',
    address: '831高雄市大寮區萬丹路372號',
    phone: '(07)781-3088',
    symbol: 'P0',
    uppercaseLetter: 'P',
    numbers: '0',
    state: '運轉中',
    disabled: true,
  },
  {
    id: 3,
    area: { value: 5, text: '高屏區' },
    factory: '高雄廠',
    address: '831高雄市大寮區萬丹路372號',
    phone: '(07)781-3088',
    symbol: 'P0',
    uppercaseLetter: 'P',
    numbers: '0',
    state: '關閉中',
    disabled: false,
  },
];
