import React, { createContext, useContext, useReducer, useState } from 'react';
import { Stack } from '@mui/material';
import BasicFrom from './components/BasicFrom';
import SpecificationFrom from './components/SpecificationsFrom';
import {
  getSpecificationExtraDataFieldNameByExperimentResultTypeId,
  getTypeAmountByExperimentItemId,
} from '@Src/_basic/helpers/LegalStandardHelpers';
import { CreateLegalStandardFormData } from '@Src/_basic/object/LegalStandardType';

export type CreateLegalStandardUIEvents = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  formData: CreateLegalStandardFormData;
  formDataDispatch: React.Dispatch<any>;
  clearFormData: () => void;
};

const CreateLegalStandardUIContent = createContext<Partial<CreateLegalStandardUIEvents>>({});

export function useCreateLegalStandardUI() {
  return useContext(CreateLegalStandardUIContent);
}

export const CreateLegalStandardUIConsumer = CreateLegalStandardUIContent.Consumer;

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'updateValue':
      return { ...state, ...action.payload };
    default:
      return { state };
  }
}

interface Props {
  typeId: number;
  experimentItemId: number;
  initialValues?: CreateLegalStandardFormData;
  btnRef?: any;
  handleSubmit: (values: any) => void;
}

export default function CreateLegalStandardForm(props: Props) {
  const [loading, setLoading] = useState(false);
  const [formData, formDataDispatch] = useReducer(reducer, props.initialValues);

  const clearFormData = () => {
    formDataDispatch({ type: 'updateValue', payload: { ...props.initialValues } });
  };

  const value = {
    loading,
    setLoading,
    formData,
    formDataDispatch,
    clearFormData,
  };

  return (
    <CreateLegalStandardUIContent.Provider value={value}>
      <>
        <button
          ref={props.btnRef}
          onClick={() => {
            props.handleSubmit(formData);
          }}
          style={{ display: 'none' }}
        />
        <Stack spacing={2}>
          <BasicFrom typeId={props.typeId} experimentItemId={props.experimentItemId} />
          <SpecificationFrom
            variant={getSpecificationExtraDataFieldNameByExperimentResultTypeId(formData.experimentResultTypeId)}
            typeId={props.typeId}
            specificationGroupsSize={2}
            maxSpecificationsSize={26}
            typeAmount={getTypeAmountByExperimentItemId(props.experimentItemId)}
          />
        </Stack>
      </>
    </CreateLegalStandardUIContent.Provider>
  );
}
