import YatungPage from '@Src/_basic/components/YatungPage';
import { CreateDemandOrderRequest } from '@Src/_basic/object/DemandOrderType';
import {
  CreateExptScheduleFormType,
  CreateExptScheduleRequest,
  PeriodDayEnum,
} from '@Src/_basic/object/ExptScheduleType';
import { CreateTestRequest } from '@Src/_basic/object/TestType';
import { DemandOrderApi } from '@Src/_basic/protocol/demandOrder/DemandOrderApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useExptSchedule } from '@Src/redux/exptSchedule/exptScheduleActions';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import CreateExptScheduleForm from '../components/CreateExptScheduleForm';

export default function CreateExptScheduleScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { userData, userAreaOptionsData } = useAuth();
  const { createExptSchedule } = useExptSchedule();

  const goToList = useCallback(() => {
    navigate(`/qualityManagement/exptSchedule/list`);
  }, [navigate]);

  const initialValues: CreateExptScheduleFormType = useMemo(() => {
    return {
      areaId: userAreaOptionsData.map((option) => option.value)[0] || 0,
      factoryId: userAreaOptionsData.map((option) => option.factories[0].value)[0] || 0,
      creater: userData?.account || '',
      title: '',
      isRequirement: 'true',
      isTest: 'true',
      exptTypeId: 0,
      memo: '',
      sampleSourceId: undefined,
      sourceId: undefined,
      specificationId: undefined,
      storageId: undefined,
      sampleId: undefined,
      exptItems: [],
      period: {
        periodType: undefined,
        periodWeekDays: [
          {
            weekDay: PeriodDayEnum.MONDAY,
            length: 1,
          },
        ],
        periodDates: [
          {
            startMonth: 0,
            startDay: 1,
            endMonth: 11,
            endDay: 31,
          },
        ],
      },
      startTime: new Date().setHours(0, 0, 0, 0),
      endTime: new Date().setHours(23, 59, 59, 999) + 1000 * 60 * 60 * 24 * 1,
    };
  }, [userData?.account]);

  const createDemandOrder = useCallback(
    (request: CreateDemandOrderRequest) => {
      DemandOrderApi.createDemandOrder(request, goToList);
    },
    [goToList],
  );

  const createExpt = useCallback(
    (request: CreateTestRequest) => {
      TestApi.createTest(request, goToList);
    },
    [goToList],
  );

  const formDataToCreateExptScheduleRequest = useCallback((formData: CreateExptScheduleFormType) => {
    return {
      ...formData,
      isRequirement: formData.isRequirement === 'true',
      isTest: formData.isTest === 'true',
    };
  }, []);

  const formDataToCreateDemandOrderRequest = useCallback(
    (formData: CreateExptScheduleFormType) => {
      return {
        createrId: userData?.account || '0',
        memo: formData.memo,
        materialReceiptId: undefined,
        sampleId: formData.sampleId,
        storageId: formData.storageId,
        specificationId: formData.specificationId,
        sourceId: formData.sourceId,
        sampleSourceId: formData.sampleSourceId,
        supplierId: undefined,
        exptTypeId: formData.exptTypeId,
        specimenId: undefined,
        factoryId: formData.factoryId,
        startTime: new Date(formData.startTime).getTime(),
        endTime: new Date(formData.endTime).getTime(),
        requestItems: formData.exptItems?.map((item) => ({
          experimentItemId: item.exptItemId,
          operatorId: item.operator,
        })),
      };
    },
    [userData],
  );

  const formDataToCreateExptRequest = useCallback(
    (formData: CreateExptScheduleFormType) => {
      return {
        createrId: userData?.account || '0',
        memo: formData.memo,
        sampleId: formData.sampleId,
        storageId: formData.storageId,
        specificationId: formData.specificationId,
        exptTypeId: formData.exptTypeId,
        areaId: formData.areaId,
        factoryId: formData.factoryId,
        requestItems: formData.exptItems?.map((item) => ({
          experimentItemId: item.exptItemId,
          operatorId: userData?.account,
        })),
      };
    },
    [userData],
  );

  const handleSubmit = useCallback(
    (values: CreateExptScheduleFormType) => {
      const request: CreateExptScheduleRequest = formDataToCreateExptScheduleRequest(values);
      if (request.isRequirement) {
        if (request.period?.periodType) {
          createExptSchedule(request, goToList);
        } else {
          createDemandOrder(formDataToCreateDemandOrderRequest(values));
        }
      } else {
        createExpt(formDataToCreateExptRequest(values));
      }
    },
    [formDataToCreateExptScheduleRequest, formDataToCreateDemandOrderRequest, formDataToCreateExptRequest],
  );

  const handleSubmitByTest = useCallback(
    (values: CreateExptScheduleFormType) => {
      const request: CreateExptScheduleRequest = formDataToCreateExptScheduleRequest(values);
      if (request.isTest) {
        createExpt(formDataToCreateExptRequest(values));
      }
    },
    [formDataToCreateExptScheduleRequest, formDataToCreateExptRequest],
  );
  return (
    <YatungPage
      title={i18T(`QUALITY_MANAGE.SIDE_MENU.EXPT_MANAGEMENT.EXPT_SCHEDULE.CREATE`)}
      body={
        <CreateExptScheduleForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onSubmitByTest={handleSubmitByTest}
        />
      }
    />
  );
}
