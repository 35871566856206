import { SxProps, Typography } from '@mui/material';
import React from 'react';

interface Props {
  label: any;
  sx?: SxProps;
  totalCount?: true;
}

const textStyle = {
  fontSize: 18,
  fontWeight: 400,
  fontFamily: 'Inter',
  color: 'black',
};

export default function YatungPaperLabel(props: Props) {
  const { label, sx } = props;
  return (
    <Typography
      sx={
        sx ||
        (props?.totalCount && textStyle) || {
          fontSize: 24,
          fontFamily: 'Inter',
          fontWeight: 400,
          color: '#ffffff',
          my: 1,
          ml: 3,
        }
      }
    >
      {label}
    </Typography>
  );
}
