import React from 'react';
import { Grid, Typography } from '@mui/material';

interface Props {
  row : any;
  formatExtraData: {
    openText: string,
    closeText: string
  }
}

export default function StatusColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <>
      <Typography 
        variant="inherit"
        sx={{
          color: row.state === "1" ? "#00D03A" : "#FC0000",
          fontWeight: 600
        }}
      >
          {row.state === "1" ? formatExtraData.openText : formatExtraData.closeText}</Typography>
    </>
  );
}
