import { Response } from '@Src/_basic/object/ApiType';
import { GetQrcodeDataRequest, QrcodeData } from '@Src/_basic/object/QrcodeType';
import { Api } from '../Api';
import { QrcodeProtocol } from './QrcodeProtocol';

export class QrcodeApi {
  // getInfoFromQRCode 從QRCode取得需要的資訊
  public static getQrcodeData(
    params: GetQrcodeDataRequest,
    onSuccess?: (data: QrcodeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: params,
    };
    Api.get(QrcodeProtocol.QRCODE_DATA, config, onSuccess, onFailed, onFinally);
  }

  public static getSmapleByIdQrcodeData(
    params: GetQrcodeDataRequest,
    onSuccess?: (data: QrcodeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(QrcodeProtocol.QRCODE_DATA, config, onSuccess, onFailed, onFinally);
  }
}
