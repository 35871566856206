import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthScreen from './screens/AuthScreen';

export default function AuthRouter() {
  return (
    <Routes>
      <Route index element={<AuthScreen />} />
      <Route path="reset-password" element={<div />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
