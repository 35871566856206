import moment from 'moment';
import React, { useEffect, useState } from 'react';

import YatungTable from '@Src/_basic/components/YatungTable';
import { MaterialReceiptsData } from '@Src/_basic/object/MaterialReceiptsType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { useTranslation } from 'react-i18next';

interface Props {
  machineStatusDetail: Array<MaterialReceiptsData>;
  loading: boolean;
  pageInfo: PageInfo;
}
export default function FeedMachineStatusTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { machineStatusDetail, loading, pageInfo } = props;
  const [newMachineStatusDetail, setNewMachineStatusDetail] = useState<Array<MaterialReceiptsData>>([]);
  const getMachineStatusDetailTemp = () => {
    const machineStatusDetailTemp = machineStatusDetail?.map((data: any, index: number) => {
      return { ...data, ids: index + 1 + (pageInfo.page - 1) * pageInfo.pageSize };
    });
    setNewMachineStatusDetail(machineStatusDetailTemp);
  };
  useEffect(() => {
    getMachineStatusDetailTemp();
  }, [machineStatusDetail]);
  return (
    <YatungTable
      loading={loading}
      data={newMachineStatusDetail}
      columns={[
        {
          text: i18T('USERSMANAGEMENU.TABLE.NO'),
          dataField: 'ids',
        },
        {
          text: i18T('USERSMANAGEMENU.TABLE.PLATE_NUMBER'),
          dataField: 'plateNumber',
        },
        {
          text: i18T('USERSMANAGEMENU.TABLE.RECEIPTCOMPLETETIME'),
          dataField: 'receiptCompleteTime',
          formatter: ({ row }) => {
            return <>{moment(row.receiptCompleteTime).format('YYYY/MM/DD HH:mm')}</>;
          },
        },
        {
          text: i18T('USERSMANAGEMENU.TABLE.SPECIFICATION'),
          dataField: 'specification',
        },
        {
          text: i18T('USERSMANAGEMENU.TABLE.SOURCE'),
          dataField: 'source',
        },
        {
          text: i18T('USERSMANAGEMENU.TABLE.EMPTY'),
          dataField: 'emptyWeight',
          formatter: ({ row }) => {
            return <>{i18T('GLOBAL.EMPTY_WEIGHT_UNIT', { empty: row.grossWeight - row.emptyWeight })}</>;
          },
        },
      ]}
    />
  );
}
