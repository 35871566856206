// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/line
import { CalcComboSieveAnalysis, CalcFineSieve } from '@Src/_basic/helpers/CalcTestForm';
import { CustomStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/CustomStandardType';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { ExperimentResultType } from '@Src/_basic/object/TestType';
import { FineSieve } from '@Src/_basic/object/test/sandRock/FineSieveType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { ResponsiveLine } from '@nivo/line';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export interface Axis {
  x: string;
  y: number;
}

export interface LineData {
  id: string;
  data: Array<Axis>;
}

interface Props {
  testFormS1: FineSieve;
  testFormS2?: FineSieve;
  ratio?: number;
}

function sievePercentageConverter(formData: CalcFineSieve | CalcComboSieveAnalysis) {
  return [
    {
      x: '#200',
      y: formData?.poreDiameterRemandPercentage8,
    },
    {
      x: '#100',
      y: formData?.poreDiameterRemandPercentage7,
    },
    {
      x: '#50',
      y: formData?.poreDiameterRemandPercentage6,
    },
    {
      x: '#30',
      y: formData?.poreDiameterRemandPercentage5,
    },
    {
      x: '#16',
      y: formData?.poreDiameterRemandPercentage4,
    },
    {
      x: '#8',
      y: formData?.poreDiameterRemandPercentage3,
    },
    {
      x: '#4',
      y: formData?.poreDiameterRemandPercentage2,
    },
    {
      x: '3/8"',
      y: formData?.poreDiameterRemandPercentage1,
    },
  ];
}

const FineSieveLineChart = ({ testFormS1, testFormS2, ratio }: Props) => {
  const { t: i18T } = useTranslation();

  const calcTestFormS1 = useMemo(() => new CalcFineSieve(testFormS1), [testFormS1]);
  const calcTestFormS2 = useMemo(() => testFormS2 && new CalcFineSieve(testFormS2), [testFormS2]);

  const [lowerAxis, setLowerAxis] = useState<Array<Axis>>([]);
  const [upperAxis, setUpperAxis] = useState<Array<Axis>>([]);
  const [lineData, setLineData] = useState<Array<LineData>>();

  useEffect(() => {
    if (!lowerAxis || !upperAxis || !calcTestFormS1) return;

    if (!calcTestFormS2) {
      setLineData([
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.UPPER_STANDARD')})`,
          data: upperAxis,
        },
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.TEST_VALUE')})`,
          data: sievePercentageConverter(calcTestFormS1),
        },
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.LOWER_STANDARD')})`,
          data: lowerAxis,
        },
      ]);
    } else {
      const calcComboTestForm = new CalcComboSieveAnalysis(calcTestFormS1, calcTestFormS2, ratio as number);
      setLineData([
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.UPPER_STANDARD')})`,
          data: upperAxis,
        },
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.TEST_VALUE')})`,
          data: sievePercentageConverter(calcComboTestForm),
        },
        {
          id: `${i18T('TEST.BASE.PASS_SIEVE_PERCENTAGE')}(${i18T('TEST.BASE.LOWER_STANDARD')})`,
          data: lowerAxis,
        },
      ]);
    }
  }, [lowerAxis, upperAxis, calcTestFormS1, calcTestFormS2, ratio, i18T]);

  const sortFromLowestSievePercentage = useCallback(
    (
      _specs: Array<LegalStandardV2SpecificationDetailsResponse | CustomStandardV2SpecificationDetailsResponse>,
      type: 'min' | 'max',
    ) => {
      return _specs
        .sort(
          (a, b) =>
            Number(a.experimentDetectionItem.code.split('mm')[0]) -
            Number(b.experimentDetectionItem.code.split('mm')[0]),
        )
        .reduce((prev, current) => {
          if (current.experimentDetectionItem.name.includes('_')) {
            prev.push({
              x: current.experimentDetectionItem.name.split('_')[0],
              y: type === 'min' ? (current.minValue as number) || 0 : (current.maxValue as number) || 100,
            });
          }
          return prev;
        }, [] as Array<Axis>);
    },
    [],
  );

  useEffect(() => {
    if (testFormS1 && testFormS2) {
      // 混合篩分析報告
      LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
        {
          experimentResultTypeId: ExperimentResultType.FINE_AGG_MIX_SIEVE_ANALYSIS,
          effectiveUnixTime: Date.now(),
          type: 1,
        },
        (_data: Array<LegalStandardV2SpecificationDetailsResponse>) => {
          setUpperAxis(sortFromLowestSievePercentage(_data, 'max'));
          setLowerAxis(sortFromLowestSievePercentage(_data, 'min'));
        },
        () => undefined,
      );
    } else {
      LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
        {
          experimentResultTypeId: ExperimentResultType.FINE_AGG_SIEVE_ANALYSIS,
          effectiveUnixTime: testFormS1?.completeTime || Date.now(),
          type: 1,
        },
        (_data: Array<LegalStandardV2SpecificationDetailsResponse>) => {
          setUpperAxis(sortFromLowestSievePercentage(_data, 'max'));
          setLowerAxis(sortFromLowestSievePercentage(_data, 'min'));
        },
        () => undefined,
      );
    }
  }, [testFormS1, testFormS2]);

  return (
    <>
      {lineData && (
        <ResponsiveLine
          data={lineData}
          colors={['rgba(0, 28, 135, 1)', 'rgba(108, 220, 245, 1)', 'rgba(0, 28, 135, 0.8)']}
          margin={{ top: 60, right: 20, bottom: 40, left: 40 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          lineWidth={3}
          pointSize={8}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          useMesh={true}
          legends={[
            {
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 80,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </>
  );
};

export default memo(FineSieveLineChart);
