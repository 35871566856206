import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungInput from '@Src/_basic/components/YatungInput';
import SearchIcon from '@Src/_basic/icons/Search';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setShowCreateCard: React.Dispatch<React.SetStateAction<boolean>>;
  resetPage: (page: number) => void;
}

const createOrganizeImage = '/media/images/organize.png';

export default function OrganizeManagementSearchBar(props: Props) {
  const { setInputValue, setShowCreateCard, resetPage } = props;
  const { t: i18T } = useTranslation();
  const [inputValues, setInputValues] = useState<string>('');

  const handleGoToCreatePage = () => {
    setShowCreateCard(true);
  };
  const handleSearchButton = () => {
    resetPage(1);
    setInputValue(inputValues);
  };

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
          <YatungInput value={inputValues} onChange={(e: any) => setInputValues(e.target.value)} />
          <YatungButton
            text={i18T('GLOBAL.SEARCh_BUTTON')}
            startIcon={<SearchIcon />}
            color="blue"
            onClick={handleSearchButton}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <YatungButton
            text={i18T('ORGANIZEMANAGEMENT.CREATE_ORGANIZE')}
            variant={'contained'}
            type={'button'}
            size={'large'}
            startIcon={<YatungImg img={createOrganizeImage} />}
            onClick={handleGoToCreatePage}
            color={'green'}
            sx={{
              fontSize: '16px',
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}
