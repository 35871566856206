import YatungDatePicker from '@Src/_basic/components/YatungDatePicker';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { AreaItem } from '@Src/_basic/object/AuthType';
import {
  MonthlyReportExptItem,
  MonthlyReportNameEnum,
  SourceList,
  WithoutAnySelectList,
} from '@Src/_basic/object/MonthlyReportType';
import { SourceData } from '@Src/_basic/object/SourceType';
import { SpecificationData } from '@Src/_basic/object/SpecificationType';
import { SupplierData } from '@Src/_basic/object/SupplierType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import { SourceApi } from '@Src/_basic/protocol/source/SourceApi';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { UnitApi } from '@Src/_basic/protocol/unit/UnitApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { SelectChangeEvent, Stack } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  getReportSearch: (
    time: number,
    factory: number,
    unit: number,
    exptItem: number,
    specification?: number,
    source?: number,
    supplier?: number,
    formId?: number,
  ) => void;
}

const MonthlyReportSelection = ({ getReportSearch }: Props) => {
  const { userGroupsData } = useAuth();
  const { actionLoading } = useApi();
  const { t: i18T } = useTranslation();
  const AllItem = { text: i18T('GLOBAL.ALL'), value: 0 };

  const [factoryOptions, setFactoryOptions] = useState<Array<Options>>([]); // 工廠選項
  const [unitOptions, setUnitOptions] = useState<Array<Options>>([]); // 試驗場地選項
  const [specificationOptions, setSpecificationOptions] = useState<Array<Options>>([]); // 來源選項
  const [exptItemOptions, setExptItemOptions] = useState<Array<Options>>([]); // 試驗類別選項
  const [sourceOptions, setSourceOptions] = useState<Array<Options>>([]); // 來源選項
  const [supplierOptions, setSupplierOptions] = useState<Array<Options>>([]); // 供應商選項

  const [selectedFactory, setSelectedFactory] = useState<number>(0); // selected廠Id
  const [selectedTestUnit, setSelectedTestUnit] = useState<number>(0); // selected試驗場地
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date()); // selected開始時間
  const [selectedExptItem, setSelectedExptItem] = useState<MonthlyReportExptItem>(); // selected試驗類別
  const [selectedFormId, setSelectedFormId] = useState<number>(0); // selected表單
  const [selectedSpecification, setSelectedSpecification] = useState<SpecificationData | number>(0); // selected規格
  const [selectedSource, setSelectedSource] = useState<SourceData | number>(0); // selected來源
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierData | number>(0); // selected供應商

  // 區選項
  const areaOptions = useMemo(() => {
    if (!userGroupsData || !userGroupsData.areas) return [];

    return userGroupsData.areas.reduce(
      (prev, curr) => [...prev, { text: curr.name, value: curr }],
      [] as Array<Options>,
    );
  }, [userGroupsData]);

  // 選擇區
  const selectAreaHandler = useCallback((e: SelectChangeEvent<unknown>) => {
    const area = e.target.value as AreaItem;
    if (!area?.factoryList) setFactoryOptions([]);
    else {
      const afterFactoryOption = area?.factoryList?.reduce(
        (prev, curr) => [...prev, { text: curr.factoryName, value: curr.factoryId }],
        [] as Array<Options>,
      );
      setFactoryOptions(afterFactoryOption);
    }
  }, []);

  // 選擇規格
  const selectSpecificationHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const specification = e.target.value as SpecificationData;
      setSelectedSpecification(specification);
      setSelectedSource(0);
      setSelectedSupplier(0);

      // 取得來源
      SourceApi.getSourcesBySearch({ factoryId: selectedFactory, typeId: specification.typeId }, (_data) => {
        setSourceOptions(
          _data.reduce((prev, curr) => [...prev, { text: curr.sourceName, value: curr }], [] as Array<Options>),
        );
      });
      // 取得供應商
      SupplierApi.getSuppliersBySearch({ factoryId: selectedFactory, typeId: specification.typeId }, (_data) => {
        setSupplierOptions(
          _data.reduce((prev, curr) => [...prev, { text: curr.name, value: curr }], [] as Array<Options>),
        );
      });
    },
    [selectedFactory],
  );

  // 依選擇試驗類別顯示不同選項
  const showSelection = useMemo(() => {
    if (!selectedExptItem || WithoutAnySelectList.includes(selectedExptItem.itemCode)) return <></>;
    else if (SourceList.includes(selectedExptItem.itemCode)) {
      return (
        <>
          <YatungSelect
            disabled={actionLoading}
            options={specificationOptions} // 規格
            value={selectedSpecification}
            onChange={selectSpecificationHandler}
          />
          <YatungSelect
            disabled={actionLoading || !selectedSpecification}
            options={[AllItem, ...sourceOptions]} // 來源
            value={selectedSource}
            onChange={(e) => setSelectedSource(e.target.value as SourceData)}
          />
        </>
      );
    } else {
      return (
        <>
          <YatungSelect
            disabled={actionLoading}
            options={[
              { text: '紀錄表', value: 1 },
              { text: '篩分析報告', value: 2 },
            ]}
            value={selectedFormId}
            onChange={(e) => {
              setSelectedFormId(e.target.value as number);
              setSelectedSpecification(0);
              setSelectedSource(0);
              setSelectedSupplier(0);
            }}
          />
          {selectedExptItem.itemCode === MonthlyReportNameEnum.COARSE_AGGREGATE_ANALYSIS ? (
            selectedFormId === 1 ? (
              <>
                <YatungSelect
                  disabled={actionLoading}
                  options={[AllItem, ...sourceOptions]}
                  value={selectedSource}
                  onChange={(e) => setSelectedSource(e.target.value as SourceData)}
                />
                <YatungSelect
                  disabled={actionLoading}
                  options={[AllItem, ...supplierOptions]}
                  value={selectedSupplier}
                  onChange={(e) => setSelectedSupplier(e.target.value as SupplierData)}
                />
              </>
            ) : (
              <></>
            )
          ) : selectedFormId === 1 ? (
            <>
              <YatungSelect
                disabled={actionLoading}
                options={specificationOptions}
                value={selectedSpecification}
                onChange={selectSpecificationHandler}
              />
              <YatungSelect
                disabled={actionLoading || !selectedSpecification}
                options={[AllItem, ...sourceOptions]}
                value={selectedSource}
                onChange={(e) => setSelectedSource(e.target.value as SourceData)}
              />
              <YatungSelect
                disabled={actionLoading || !selectedSpecification}
                options={[AllItem, ...supplierOptions]}
                value={selectedSupplier}
                onChange={(e) => setSelectedSupplier(e.target.value as SupplierData)}
              />
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
  }, [
    selectedExptItem,
    specificationOptions,
    selectedFormId,
    selectedSpecification,
    sourceOptions,
    supplierOptions,
    actionLoading,
    selectedSupplier,
    selectedSource,
  ]);

  // 取得試驗場地 & 試驗類別
  useEffect(() => {
    UnitApi.getTestUnit((_data) => {
      setUnitOptions(
        _data.map((unit) => ({
          value: unit.id,
          text: unit.unitName,
        })),
      );
    });
    MonthlyReportApi.getAllMonthlyExptItem((_data) =>
      setExptItemOptions(_data.map((item) => ({ text: item.itemName, value: item }))),
    );
  }, []);

  // 取得規格
  useEffect(() => {
    if (!selectedExptItem || !selectedFactory) return;
    let typeId: number;
    switch (selectedExptItem.itemCode) {
      case MonthlyReportNameEnum.FLY_ASH_LOSS_OF_IGNITION:
      case MonthlyReportNameEnum.FLY_ASH_L_VALUE:
        typeId = 5;
        break;
      case MonthlyReportNameEnum.COARSE_AGGREGATE_ABSORPTION:
      case MonthlyReportNameEnum.COARSE_AGGREGATE_ANALYSIS:
        typeId = 2;
        break;
      default:
        typeId = 1;
        break;
    }

    // 取得規格
    SpecificationApi.getSpecificationsBySearch({ factoryId: selectedFactory, typeId }, (_data) => {
      typeId === 2
        ? setSpecificationOptions(
            _data
              .filter((item) => item.id === 1 || item.id === 2)
              .reduce((prev, curr) => [...prev, { text: curr.name, value: curr }], [] as Array<Options>),
          )
        : setSpecificationOptions(
            _data.reduce((prev, curr) => [...prev, { text: curr.name, value: curr }], [] as Array<Options>),
          );
    });
  }, [selectedExptItem, selectedFactory]);

  useEffect(() => {
    if (!selectedMonth || !selectedFactory || !selectedTestUnit || !selectedExptItem) return;

    getReportSearch(
      selectedMonth.getTime(),
      selectedFactory,
      selectedTestUnit,
      selectedExptItem.id,
      (selectedSpecification as SpecificationData).id,
      (selectedSource as SourceData).id,
      (selectedSupplier as SupplierData).id,
      selectedFormId,
    );
  }, [
    selectedMonth,
    selectedFactory,
    selectedTestUnit,
    selectedExptItem,
    selectedSpecification,
    selectedSource,
    selectedSupplier,
    selectedFormId,
  ]);

  return (
    <Stack direction="column" spacing={2} alignItems="flex-end" my={3}>
      <Stack direction="row" spacing={2} alignItems="center">
        <YatungDatePicker
          disabled={actionLoading}
          label="月份"
          mode="Month"
          value={selectedMonth}
          disableFuture={true}
          onChange={(val: Date | null) => {
            if (val) setSelectedMonth(new Date(val));
          }}
        />
        <YatungSelect disabled={actionLoading} options={areaOptions as Array<Options>} onChange={selectAreaHandler} />
        <YatungSelect
          disabled={factoryOptions.length === 0 || actionLoading}
          options={factoryOptions}
          onChange={(e) => setSelectedFactory(e.target.value as number)}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <YatungSelect
          disabled={actionLoading}
          options={unitOptions}
          onChange={(e) => setSelectedTestUnit(e.target.value as number)}
        />
        <YatungSelect
          disabled={actionLoading}
          options={exptItemOptions}
          onChange={(e) => {
            setSelectedExptItem(e.target.value as MonthlyReportExptItem);
            setSelectedFormId(0);
            setSelectedSpecification(0);
            setSelectedSource(0);
            setSelectedSupplier(0);
          }}
        />
        {showSelection}
      </Stack>
    </Stack>
  );
};

export default memo(MonthlyReportSelection);
