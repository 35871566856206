import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import AccountManagementCardScreen from './screens/AccountManagementCardScreen';
import AccountManagementCreateCardScreen from './screens/AccountManagementCreateCardScreen';
import AccountManagementUpdateCardScreen from './screens/AccountManagementUpdateCardScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <AccountManagementCardScreen />,
  },
  {
    path: '/AccountManagementCreate/*',
    element: <AccountManagementCreateCardScreen />,
  },
  {
    path: '/AccountManagementUpdate/:id',
    element: <AccountManagementUpdateCardScreen />,
  },
];

const AccountManagementRouter = () => PermissionRoute(routesConfig);

export default memo(AccountManagementRouter);
