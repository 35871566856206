import { Response } from '@Src/_basic/object/ApiType';
import { NotificationPriorityData } from '@Src/_basic/object/NotificationPriorityType';
import axios from 'axios';
import { Api } from '../Api';
import { NotificationPriorityProtocol } from './NotificationPriorityProtocol';

export class NotificationPriorityApi {
  public static getNotificationPriorities(
    onSuccess?: (data: Array<NotificationPriorityData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(NotificationPriorityProtocol.ALL_NOTIFICATION_PRIORITY, undefined, onSuccess, onFailed, onFinally);
  }
}
