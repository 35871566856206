import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import SearchIcon from '@Src/_basic/icons/Search';
import { useAuth } from '@Src/redux/auth/authActions';
import { Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { QueryType } from '..';
import YatungImg from '../../../../../../../../_basic/components/YatungImg';

interface Props {
  filterValue: QueryType;
  changeHandler: (field: keyof QueryType, value: string | number) => void;
  onClickSarchButton: (keywords: string) => void;
}
const createImage = '/media/images/create.png';
export default function SiteManagementSearchBar({ filterValue, changeHandler, onClickSarchButton }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { userAreaOptionsData } = useAuth();

  const handleGoToCreatePage = useCallback(() => {
    navigate('/UsersManagePage/SiteManagement/SiteManagementCreate');
  }, [navigate]);

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
        <YatungSelect
          helperText={i18T('GLOBAL.AREA')}
          options={[
            {
              value: 0,
              text: '全部區域',
            },
            ...userAreaOptionsData,
          ]}
          value={filterValue.areaId}
          onChange={(e) => changeHandler('areaId', e.target.value as number)}
        />
        <YatungInput
          value={filterValue.keywords}
          onChange={(e: any) => changeHandler('keywords', e.target.value as string)}
        />
        <YatungButton
          text={i18T('GLOBAL.SEARCh_BUTTON')}
          startIcon={<SearchIcon />}
          color="blue"
          onClick={onClickSarchButton}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <YatungButton
          text={i18T('SITEMANAGEMENT.CREATE_SITE_BUTTON')}
          variant={'contained'}
          type={'button'}
          size={'large'}
          startIcon={<YatungImg img={createImage} />}
          onClick={handleGoToCreatePage}
          sx={{
            color: '#ffffff',
            bgcolor: '#00D03A',
            fontSize: '16px',
            '&.MuiButtonBase-root:hover': {
              bgcolor: '#00D03A',
            },
          }}
        />
      </Stack>
    </Stack>
  );
}
