import { AllTest } from '@Src/_basic/object/TestType';
import { FineSieve } from '@Src/_basic/object/test/sandRock/FineSieveType';
import ExportFineSieveTable from '@Src/app/module/test/sandRock/fineSieve/ExportFineSieveTable';
import React, { memo } from 'react';
import { TestCardContainer } from '../../MuiStyleComponent/TestComponent';
import FineSieveLineChart from '../FineSieveLineChart';

interface Props {
  testDetail: FineSieve;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const FineSieveAnalysisCommonTable = ({ testDetail, afterUpdateTest, canEdit }: Props) => {
  return (
    <>
      <TestCardContainer sx={{ height: 600 }}>
        <FineSieveLineChart testFormS1={testDetail} />
      </TestCardContainer>
      <ExportFineSieveTable testDetail={testDetail} afterUpdateTest={afterUpdateTest} canEdit={canEdit} />
    </>
  );
};

export default memo(FineSieveAnalysisCommonTable);
