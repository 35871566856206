import { CreateLangData, MultilingualFileData } from '@Src/_basic/object/MultilingualManagementType';
import { LanguageApi } from '@Src/_basic/protocol/languageManagement/LanguageApi';
import { useLanguage } from '@Src/redux/language/languageAction';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import AddLangField from './components/AddLangField';

interface Props {
  initialValues: CreateLangData;
  handleAddGoBackButtonClick: () => void;
  inputNameValues: string;
  setInputNameValues: (e: string) => void;
  platformName: string;
  refreshOptionData: () => void;
}
const vahicleSchema = Yup.object().shape({});

export default function AddForm(props: Props) {
  const {
    initialValues,
    handleAddGoBackButtonClick,
    inputNameValues,
    setInputNameValues,
    platformName,
    refreshOptionData,
  } = props;
  const [file, setFile] = useState<FileList>();
  const [defaultName, setDefaultName] = useState<string>('');
  const { uploadLanguageReports } = useLanguage();
  const handleCreateLangName = (request: CreateLangData) => {
    if (file) {
      uploadLanguageReports(
        platformName,
        {
          languageName: inputNameValues,
        },
        file,
        () => {
          handleAddGoBackButtonClick();
          refreshOptionData();
        },
      );
    }
  };

  const handleDownloadDefaultFile = () => {
    LanguageApi.getLangPlatformDefaultFileData(
      platformName,
      (data) => {
        onGetLangNameSuccess(data);
      },
      () => {},
    );
  };

  const onGetLangNameSuccess = useCallback((data: MultilingualFileData) => {
    setDefaultName(data.download);
  }, []);

  const handleChange = (event: any) => {
    const file = event.target.files;
    if (file) {
      setFile(file);
    }
  };

  useEffect(() => {
    handleChange;
  }, [file]);

  useEffect(() => {
    handleDownloadDefaultFile();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={vahicleSchema}
      onSubmit={(request: CreateLangData) => handleCreateLangName(request)}
      enableReinitialize
    >
      {({ setFieldValue, values }) => {
        return (
          <Form>
            <AddLangField
              values={values}
              setValues={setFieldValue}
              handleAddGoBackButtonClick={handleAddGoBackButtonClick}
              inputNameValues={inputNameValues}
              setInputNameValues={setInputNameValues}
              file={file}
              handleChange={handleChange}
              defaultName={defaultName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
