import YatungButton from '@Src/_basic/components/YatungButton';
import EditIcon from '@Src/_basic/icons/Edit';
import {
  CreateSignatureItemRequest,
  SignatureItemData,
  UpdateSignatureFormType,
} from '@Src/_basic/object/SignatureType';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { useSignature } from '@Src/redux/signature/signatureActions';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubmitButtons from '../../SignatureFormLayout';
import CementitiousCard from './SignatrueItemForm/CementitiousCard';
import ChemicalCard from './SignatrueItemForm/ChemicalCard';
import SandAndRockCard from './SignatrueItemForm/SandAndRockCard';
import ViewSignature from './ViewSignature';

export default function UpdateSignatureFrom() {
  const { t: i18T } = useTranslation();
  const { signature, clearSignature } = useSignature();
  const { projectInfo, updateSignature } = useProductionHistory();

  const [editMode, setEditMode] = useState(false);

  const formData = useMemo(() => {
    const mapSignatureItems = (items: SignatureItemData[] | undefined) =>
      items?.map((item) => ({ ...item, details: item.details?.map((detail) => ({ ...detail })) })) || [];

    return {
      cementingMaterial: signature.title
        ? mapSignatureItems(signature?.cementingMaterial)
        : mapSignatureItems(projectInfo.signature?.cementingMaterial),
      chemicalAdmixture: signature.title
        ? mapSignatureItems(signature?.chemicalAdmixture)
        : mapSignatureItems(projectInfo.signature?.chemicalAdmixture),
      sandStone: signature.title
        ? mapSignatureItems(signature?.sandStone)
        : mapSignatureItems(projectInfo.signature?.sandStone),
    };
  }, [projectInfo.signature, signature]);

  const formik = useFormik({
    initialValues: formData,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const setFieldValueHandler = useCallback(
    (field: keyof UpdateSignatureFormType, value: Array<CreateSignatureItemRequest>) => {
      formik.setFieldValue(field, value);
    },
    [formik],
  );

  const handleSubmit = useCallback(
    (isTemplete: boolean) => {
      function filterWithOutZero(values: Array<SignatureItemData>) {
        return values
          .map((item) => ({
            ...item,
            details: item?.details?.filter((detail) => detail.specificationId !== 0 && detail.BOMId !== 0),
          }))
          .filter((item) => item.typeId !== 0 && item.details?.length !== 0);
      }

      const filteredCementingMaterial = filterWithOutZero(formik.values.cementingMaterial);
      const filteredChemicalAdmixture = filterWithOutZero(formik.values.chemicalAdmixture);
      const filteredSandStone = filterWithOutZero(formik.values.sandStone);

      updateSignature(
        {
          id: Number(projectInfo.signature?.id),
          title: projectInfo.signature?.title || '',
          items: [...filteredCementingMaterial, ...filteredChemicalAdmixture, ...filteredSandStone],
        },
        () => setEditMode(false),
        isTemplete,
      );
    },
    [formik, projectInfo, updateSignature],
  );

  const resetForm = useCallback(() => {
    formik.resetForm();
    clearSignature();
    setEditMode(false);
  }, [formik, clearSignature]);

  useEffect(() => {
    return () => {
      clearSignature();
    };
  }, []);

  return (
    <>
      {!editMode && (
        <YatungButton
          text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.EDIT`)}
          color="blue"
          startIcon={<EditIcon />}
          onClick={() => setEditMode((prev) => !prev)}
          sx={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer', zIndex: 10 }}
        />
      )}
      {editMode ? (
        <SubmitButtons cancelHandler={resetForm} confirmHandler={handleSubmit}>
          <>
            <CementitiousCard
              data={formik.values.cementingMaterial}
              onChange={(value) => setFieldValueHandler('cementingMaterial', value)}
            />
            <ChemicalCard
              data={formik.values.chemicalAdmixture}
              onChange={(value) => setFieldValueHandler('chemicalAdmixture', value)}
            />
            <SandAndRockCard
              data={formik.values.sandStone}
              onChange={(value) => setFieldValueHandler('sandStone', value)}
            />
          </>
        </SubmitButtons>
      ) : (
        <ViewSignature />
      )}
    </>
  );
}
