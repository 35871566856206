import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';
import EditIcon from '@Src/_basic/icons/Edit';
import { MaterialPriceData } from '@Src/_basic/object/MaterialPriceType';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: Array<MaterialPriceData>;
  // eslint-disable-next-line no-unused-vars
  selectPriceHandler: (row: MaterialPriceData) => void;
}

const MaterialPriceTable = ({ data, selectPriceHandler }: Props) => {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  return (
    <YatungTable
      loading={actionLoading}
      data={data}
      columns={[
        {
          text: 'No.',
          dataField: 'index',
          width: '10%',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE'),
          dataField: 'typeName',
          width: '10%',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.MATERIAL_NAME'),
          dataField: 'specificationName',
          width: '25%',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.COST'),
          dataField: 'cost',
          width: '10%',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.FALSE_COST'),
          dataField: 'falseCost',
          width: '10%',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.UPDATE_TIME'),
          dataField: 'lastUpdateTime',
          width: '25%',
          formatter: ({ row }) => {
            return (
              <Stack direction="column">
                {row.lastUpdateTime ? (
                  <>
                    <Typography>{`${moment(row.lastUpdateTime).format('YYYY-MM-DD')}`}</Typography>
                    <Typography>{`${moment(row.lastUpdateTime).format('HH:mm:ss')}`}</Typography>
                  </>
                ) : (
                  <Typography>{i18T('GLOBAL.NO_INFO')}</Typography>
                )}
              </Stack>
            );
          },
        },
        {
          text: i18T('GLOBAL.ACTION'),
          dataField: 'action',
          width: '10%',
          formatter: ({ row }) => {
            return (
              <YatungButton
                text={i18T('GLOBAL.UPDATE')}
                startIcon={<EditIcon />}
                width={90}
                onClick={() => selectPriceHandler(row)}
                color="yellow"
              />
            );
          },
        },
      ]}
    />
  );
};

export default memo(MaterialPriceTable);
