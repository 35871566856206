import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DeliveryInformation(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="46" height="46" viewBox="0 0 46 46" fill="none">
      <svg xmlns="http://www.w3.org/2000/svg" width="97" height="106" viewBox="0 0 97 106" fill="none">
        <g filter="url(#filter0_d_305_46399)">
          <path d="M27.8594 29.8027H58.4174V31.3698H27.8594V29.8027Z" fill="#EDEFF1" />
          <path d="M27.8594 32.1534H58.4174V64.2785H27.8594V32.1534Z" fill="#EDEFF1" />
          <path d="M58.4174 65.062H27.8594V66.6291H58.4174V65.062Z" fill="#EDEFF1" />
          <path
            d="M35.2315 43.9023H29.5083C29.4626 43.9023 29.4258 43.9394 29.4258 43.9851V44.4132C29.4258 44.4589 29.4626 44.496 29.5083 44.496H30.9825V44.8951H29.5083C29.4626 44.8951 29.4258 44.9322 29.4258 44.9779V46.7992C29.4258 46.8449 29.4626 46.882 29.5083 46.882H30.9825V47.2965H29.5083C29.4626 47.2965 29.4258 47.3337 29.4258 47.3793V47.8074C29.4258 47.8531 29.4626 47.8902 29.5083 47.8902H35.2315C35.2772 47.8902 35.314 47.8531 35.314 47.8074V47.3793C35.314 47.3337 35.2772 47.2965 35.2315 47.2965H33.7156V46.882H35.2315C35.2772 46.882 35.314 46.8449 35.314 46.7992V44.9779C35.314 44.9322 35.2772 44.8951 35.2315 44.8951H33.7156V44.496H35.2315C35.2772 44.496 35.314 44.4589 35.314 44.4132V43.9851C35.314 43.9394 35.2772 43.9023 35.2315 43.9023ZM32.9285 44.9784V45.4064C32.9285 45.4521 32.9653 45.4892 33.0109 45.4892H34.526V46.2879H33.0109C32.9653 46.2879 32.9285 46.325 32.9285 46.3707V47.2961H31.7705V46.3707C31.7705 46.325 31.7337 46.2879 31.688 46.2879H30.2138V45.4892H31.688C31.7337 45.4892 31.7705 45.4521 31.7705 45.4064V44.4965H32.9285V44.9784Z"
            fill="#7C878E"
          />
          <path
            d="M42.2572 47.2418H41.7751C41.2978 47.2494 40.831 47.1028 40.5251 46.8838H42.1683C42.2139 46.8838 42.2508 46.8467 42.2508 46.801V44.9054C42.2508 44.8598 42.2139 44.8226 42.1683 44.8226H39.8228V44.5556H42.2644C42.31 44.5556 42.3477 44.5186 42.3477 44.4728V44.1027C42.3477 44.057 42.31 44.0199 42.2644 44.0199H39.8228V43.8357C39.8228 43.79 39.7851 43.7529 39.7395 43.7529H39.0676C39.022 43.7529 38.9843 43.79 38.9843 43.8357V44.0199H36.5427C36.4963 44.0199 36.4594 44.057 36.4594 44.1027V44.4728C36.4594 44.5186 36.4963 44.5556 36.5427 44.5556H38.9843V44.8226H36.6228C36.5772 44.8226 36.5403 44.8598 36.5403 44.9054V46.801C36.5403 46.8467 36.5772 46.8838 36.6228 46.8838H38.2836C37.9777 47.1028 37.5109 47.2494 37.0336 47.2418H36.5515C36.4995 47.2418 36.457 47.2838 36.457 47.3354V47.7503C36.457 47.8024 36.4995 47.844 36.5515 47.844H37.1457C37.8608 47.844 38.5727 47.5653 38.9843 47.1417V47.8142C38.9843 47.8599 39.022 47.897 39.0676 47.897H39.7395C39.7851 47.897 39.8228 47.8599 39.8228 47.8142V47.1404C40.2336 47.5648 40.9463 47.844 41.6622 47.844H42.2564C42.3084 47.844 42.3509 47.8019 42.3509 47.7503V47.3354C42.3509 47.2838 42.3092 47.2418 42.2564 47.2418H42.2572ZM37.3171 46.1014H38.9843V46.3874H37.3171V46.1014ZM41.4724 46.3874H39.822V46.1014H41.4724V46.3874ZM41.4724 45.605H39.822V45.319H41.4724V45.605ZM38.9836 45.605H37.3171V45.319H38.9836V45.605Z"
            fill="#7C878E"
          />
          <path
            d="M45.6071 47.0091V45.5525C45.6071 45.4665 45.5126 45.4665 45.5126 45.4665H44.9944C45.3228 45.2113 45.5758 44.7493 45.6015 44.3633C45.6023 44.3592 45.6039 44.3556 45.6039 44.3515V44.032C45.6039 43.9864 45.5662 43.9492 45.5206 43.9492H43.6299C43.5842 43.9492 43.5474 43.9864 43.5474 44.032V44.3515C43.5474 44.3973 43.5842 44.4343 43.6299 44.4343H45.0225C44.9408 44.6665 44.7742 44.9714 44.4427 45.109L44.2649 44.8072C44.2521 44.7864 44.2057 44.7375 44.1544 44.7746L43.8301 44.985C43.7957 45.0122 43.8013 45.0629 43.8133 45.0837L44.0295 45.4665H43.6043C43.4649 45.4665 43.4297 45.5049 43.4297 45.5525V45.9113C43.4297 45.9588 43.4641 45.9972 43.6043 45.9972L44.1472 45.9855V47.3204H43.7292C43.6835 47.3204 43.6467 47.3575 43.6467 47.4032V47.7412C43.6467 47.7869 43.6835 47.824 43.7292 47.824H44.715C44.7606 47.824 44.7975 47.7869 44.7975 47.7412V45.9711L45.0881 45.9647V47.0087C45.0881 47.0543 45.125 47.0915 45.1706 47.0915H45.5238C45.5694 47.0915 45.6063 47.0543 45.6063 47.0087"
            fill="#7C878E"
          />
          <path
            d="M49.7931 47.3156H49.6337C49.3286 47.3156 48.9514 47.365 48.768 47.1731H49.5929C49.6385 47.1731 49.6754 47.1359 49.6754 47.0903V44.7205C49.6754 44.6748 49.6385 44.6377 49.5929 44.6377H48.117V44.397H49.5784C49.6241 44.397 49.6617 44.3598 49.6617 44.3142V44.0047C49.6617 43.959 49.6241 43.9219 49.5784 43.9219H45.8748C45.8291 43.9219 45.7915 43.959 45.7915 44.0047V44.3142C45.7915 44.3598 45.8291 44.397 45.8748 44.397H47.3362V44.6377H45.8748C45.8291 44.6377 45.7915 44.6748 45.7915 44.7205V47.0903C45.7915 47.1359 45.8291 47.1731 45.8748 47.1731H46.6844C46.5002 47.365 46.123 47.3156 45.8187 47.3156H45.5897C45.54 47.3156 45.5 47.3577 45.5 47.4093V47.7586C45.5 47.8102 45.54 47.8523 45.5897 47.8523H45.8868C46.6147 47.8523 47.3018 47.6926 47.5036 47.1735H47.948C48.1498 47.6921 48.8377 47.8523 49.5664 47.8523H49.7931C49.8427 47.8523 49.8819 47.8107 49.8819 47.7586V47.4093C49.8819 47.3577 49.8427 47.3156 49.7931 47.3156ZM48.8954 45.3382H46.5731V45.0717H48.8954V45.3382ZM46.5731 45.7721H48.8954V46.0386H46.5731V45.7721ZM46.5731 46.4726H48.8954V46.7391H46.5731V46.4726Z"
            fill="#7C878E"
          />
          <path
            d="M52.679 46.2769C52.719 46.2426 52.7422 46.1842 52.7422 46.1208V45.7928C52.7422 45.7072 52.6749 45.6638 52.6181 45.7018C52.4916 45.7846 52.365 45.8506 52.2401 45.9036V45.1389H52.6605C52.7062 45.1389 52.743 45.1017 52.743 45.0561V44.7009C52.743 44.6551 52.7062 44.6181 52.6605 44.6181H52.2401V43.9334C52.2401 43.8877 52.2025 43.8506 52.1568 43.8506H51.6315C51.5859 43.8506 51.5482 43.8877 51.5482 43.9334V44.6181H50.9276C50.882 44.6181 50.8451 44.6551 50.8451 44.7009V45.0561C50.8451 45.1017 50.882 45.1389 50.9276 45.1389H51.5482V46.0905C51.2663 46.1299 51.0325 46.1258 50.898 46.1367C50.8539 46.1403 50.8203 46.1887 50.8203 46.247V46.5869C50.8203 46.6484 50.858 46.6987 50.9044 46.6982C51.131 46.696 51.3472 46.6769 51.5482 46.6475V47.3597H51.1534C51.1078 47.3597 51.071 47.3969 51.071 47.4425V47.8055C51.071 47.8512 51.1078 47.8883 51.1534 47.8883H52.1568C52.2025 47.8883 52.2393 47.8512 52.2393 47.8055V46.4878C52.4483 46.4186 52.6021 46.3421 52.679 46.276"
            fill="#7C878E"
          />
          <path
            d="M55.5078 45.4627H55.9651C56.0276 45.4627 56.0836 45.4337 56.1333 45.3931C56.1333 45.3931 56.6506 44.8337 56.7923 44.0726C56.8011 44.0233 56.7515 43.9902 56.6906 43.9902H56.2213C56.1637 43.9902 56.1228 44.0283 56.1124 44.0749C55.9915 44.602 55.7777 44.9356 55.4173 45.326C55.3677 45.3795 55.4222 45.4627 55.507 45.4627"
            fill="#7C878E"
          />
          <path
            d="M53.5515 45.3935C53.6011 45.4341 53.658 45.4631 53.7197 45.4631H54.1769C54.2618 45.4631 54.3162 45.3799 54.2674 45.3264C53.9062 44.936 53.6932 44.6024 53.5715 44.0748C53.5611 44.0282 53.5203 43.9902 53.4626 43.9902H52.9933C52.9325 43.9902 52.8828 44.0232 52.8916 44.0726C53.0326 44.8337 53.5507 45.393 53.5507 45.393"
            fill="#7C878E"
          />
          <path
            d="M56.8096 46.7127H55.22V46.2543H56.5502C56.5958 46.2543 56.6326 46.2171 56.6326 46.1715V45.7239C56.6326 45.6783 56.5958 45.6411 56.5502 45.6411H55.22V43.8904C55.22 43.8447 55.1832 43.8076 55.1376 43.8076H54.5145C54.4681 43.8076 54.4313 43.8447 54.4313 43.8904V45.6411H53.174C53.1284 45.6411 53.0907 45.6783 53.0907 45.7239V46.1715C53.0907 46.2171 53.1284 46.2543 53.174 46.2543H54.4313V46.7127H52.8176C52.7712 46.7127 52.7344 46.7497 52.7344 46.7955V47.243C52.7344 47.2887 52.7712 47.3258 52.8176 47.3258H54.4313V47.8747C54.4313 47.9204 54.4681 47.9575 54.5145 47.9575H55.1376C55.1832 47.9575 55.22 47.9204 55.22 47.8747V47.3258H56.8096C56.8553 47.3258 56.8921 47.2887 56.8921 47.243V46.7955C56.8921 46.7497 56.8553 46.7127 56.8096 46.7127Z"
            fill="#7C878E"
          />
          <path
            d="M51.5671 52.3661C51.5671 52.3661 51.5679 52.3625 51.5679 52.3607V49.1841C51.5679 49.1366 51.5295 49.0981 51.4822 49.0981H50.6958C50.6486 49.0981 50.6102 49.1366 50.6102 49.1841V51.1231L49.0702 49.2253C49.0134 49.1579 48.9813 49.0977 48.8772 49.0977H47.8931C47.837 49.0977 47.793 49.1429 47.793 49.1982V52.3603C47.793 52.4077 47.8314 52.4462 47.8786 52.4462H48.6418C48.689 52.4462 48.7275 52.4077 48.7275 52.3603V50.357L50.3131 52.2575C50.4052 52.3661 50.4532 52.4521 50.5957 52.4521H51.4998C51.5287 52.4521 51.5511 52.4272 51.5615 52.3942C51.5623 52.3928 51.5623 52.3905 51.5631 52.3892C51.5655 52.3815 51.5663 52.3738 51.5671 52.3657"
            fill="#7C878E"
          />
          <path
            d="M41.3993 49.0771H37.9607C37.9134 49.0771 37.875 49.1156 37.875 49.1631V49.9464C37.875 49.9939 37.9134 50.0323 37.9607 50.0323H39.2011V52.2261C39.2011 52.4166 39.2395 52.4559 39.2884 52.4559H40.0724C40.1204 52.4559 40.1597 52.4171 40.1597 52.2261V50.0323H41.3993C41.4473 50.0323 41.4858 49.9939 41.4858 49.9464V49.1631C41.4858 49.1156 41.4473 49.0771 41.3993 49.0771Z"
            fill="#7C878E"
          />
          <path
            d="M46.4158 49.0977H45.5565C45.5093 49.0977 45.4708 49.1547 45.4708 49.2253V51.1647C45.4708 51.3557 45.3676 51.4393 45.2402 51.4393H43.9253C43.798 51.4393 43.6963 51.357 43.6939 51.1692V49.2253C43.6939 49.1547 43.6563 49.0977 43.609 49.0977H42.749C42.7025 49.0977 42.6641 49.1547 42.6641 49.2253V51.0172L42.6665 51.3022C42.6665 52.095 43.0957 52.4525 43.625 52.4525H45.5429C46.0722 52.4525 46.5015 52.095 46.5015 51.3022V49.2253C46.5015 49.1547 46.4638 49.0977 46.4166 49.0977"
            fill="#7C878E"
          />
          <path
            d="M56.713 49.743V49.1788C56.713 49.0832 56.6617 49.0059 56.5977 49.0059H53.6668C53.3312 49.0059 53.1759 49.0629 53.0437 49.1801C52.8956 49.3118 52.8203 49.5511 52.8203 49.8915V51.5892C52.8203 51.9295 52.8956 52.1688 53.0437 52.3006C53.1759 52.4177 53.3312 52.4747 53.6668 52.4747H56.0403C56.3758 52.4747 56.5304 52.4177 56.6625 52.3006C56.8107 52.1688 56.8867 51.9295 56.8867 51.5892V50.5878C56.8867 50.4489 56.8091 50.3362 56.7138 50.3362H54.6694C54.6213 50.3362 54.5821 50.3932 54.5821 50.4634V51.0276C54.5821 51.0978 54.6213 51.1548 54.6694 51.1548H56.0227C56.0547 51.1548 56.0795 51.1919 56.0795 51.2376V51.4693C56.0795 51.515 56.0547 51.5525 56.0227 51.5525H53.8726C53.7797 51.5525 53.7044 51.4426 53.7044 51.3073V50.2005C53.7044 50.0647 53.7797 49.9552 53.8726 49.9552H56.5977C56.6609 49.9552 56.713 49.8384 56.713 49.743Z"
            fill="#7C878E"
          />
          <path
            d="M33.2023 49.098H32.468C32.3303 49.0961 32.2678 49.098 32.1549 49.2269L31.3926 50.1134L30.631 49.2269C30.5173 49.098 30.4548 49.0961 30.3179 49.098H29.5828C29.4682 49.098 29.4018 49.2477 29.4779 49.3496L30.9161 51.0958V52.223C30.9161 52.4135 30.9545 52.4528 31.0034 52.4528H31.7873C31.8354 52.4528 31.8746 52.4139 31.8746 52.223V51.0895L33.3081 49.3496C33.3833 49.2482 33.3169 49.098 33.2023 49.098Z"
            fill="#7C878E"
          />
          <path
            d="M37.6056 52.2118L36.032 49.2266C35.9576 49.0918 35.8967 49.0977 35.7189 49.0977H35.2705C35.0935 49.0977 35.0318 49.0918 34.9574 49.2266L33.3838 52.2118C33.3438 52.2874 33.3294 52.4525 33.532 52.4525L34.0621 52.4534C34.2631 52.4525 34.3103 52.4232 34.4481 52.1417L34.6379 51.7797H36.3508L36.5405 52.1417C36.6791 52.4232 36.7255 52.4525 36.9265 52.4534L37.4574 52.4525C37.6592 52.4525 37.6448 52.2874 37.604 52.2118H37.6056ZM35.0807 50.9322L35.4939 50.1448L35.9063 50.9322H35.0807Z"
            fill="#FF0002"
          />
          <rect x="31.4922" y="35.7861" width="7.61894" height="4.48478" fill="white" stroke="#B0AEAE" />
          <rect x="34.9062" y="36.0654" width="0.78354" height="3.9177" fill="#B0AEAE" />
          <rect x="47.168" y="35.7861" width="7.61894" height="4.48478" fill="white" stroke="#B0AEAE" />
          <rect x="50.582" y="36.0654" width="0.78354" height="3.9177" fill="#B0AEAE" />
          <rect x="31.4922" y="55.3789" width="7.61894" height="4.48478" fill="white" stroke="#B0AEAE" />
          <rect x="34.9062" y="55.6582" width="0.78354" height="3.9177" fill="#B0AEAE" />
          <rect x="47.168" y="55.3789" width="7.61894" height="4.48478" fill="white" stroke="#B0AEAE" />
          <rect x="50.582" y="55.6582" width="0.78354" height="3.9177" fill="#B0AEAE" />
          <rect x="27.8594" y="67.416" width="30.5581" height="10.9696" fill="#EDEFF1" />
          <rect x="31.4922" y="70.2637" width="7.61894" height="4.48478" fill="white" stroke="#B0AEAE" />
          <rect x="34.9062" y="70.542" width="0.78354" height="3.9177" fill="#B0AEAE" />
          <rect x="47.168" y="70.2637" width="7.61894" height="4.48478" fill="white" stroke="#B0AEAE" />
          <rect x="50.582" y="70.543" width="0.78354" height="3.9177" fill="#B0AEAE" />
          <path d="M27.8594 94.8453V79.958H58.4174V94.8453H56.0668V82.3086H30.21V94.8453H27.8594Z" fill="#EDEFF1" />
          <path d="M54.4997 83.8757H31.7771V94.8453H54.4997V83.8757Z" fill="#B0AEAE" />
          <path
            d="M39.0195 7.21484C39.0195 6.11027 39.915 5.21484 41.0195 5.21484H45.2467C46.3513 5.21484 47.2467 6.11027 47.2467 7.21484V13.442H39.0195V7.21484Z"
            fill="#B0AEAE"
          />
          <rect x="31.8281" y="13.4404" width="22.6247" height="15.4259" fill="#EDEFF1" />
          <path
            d="M67.8125 5.15527C67.8125 4.0507 68.7079 3.15527 69.8125 3.15527H74.0397C75.1442 3.15527 76.0397 4.0507 76.0397 5.15527V11.3824H67.8125V5.15527Z"
            fill="#B0AEAE"
          />
          <path d="M69.2572 58.6928L60.6172 53.5508H83.2419L74.8861 58.6928H69.2572Z" fill="#EDEFF1" />
          <rect x="60.6172" y="11.3818" width="22.6247" height="41.1358" fill="#EDEFF1" />
          <path
            d="M83.243 55.5996L81.1862 56.7947V91.5935H80.1578V94.6787H84.2714V91.5935H83.243V55.5996Z"
            fill="#EDEFF1"
          />
          <path
            d="M62.675 56.9072L60.6182 55.7462V91.5935H59.5898V94.6787H63.7034V91.5935H62.675V56.9072Z"
            fill="#EDEFF1"
          />
          <path d="M72.959 59.7132H70.9022V91.5935H69.8738V94.6787H73.9874V91.5935H72.959V59.7132Z" fill="#EDEFF1" />
          <path
            d="M63.2635 59.5666V60.599L65.7944 62.7582L63.2635 64.9215V65.9519L66.3969 63.2744L69.5323 65.9519V64.9235L67.0015 62.7582L69.5323 60.597V59.5666L66.3969 62.244L63.2635 59.5666Z"
            fill="#EDEFF1"
          />
          <path
            d="M63.2635 67.4021V68.4325L65.7944 70.5937L63.2635 72.757V73.7874L66.3969 71.1099L69.5323 73.7874V72.759L67.0015 70.5937L69.5323 68.4325V67.4021L66.3969 70.0795L63.2635 67.4021Z"
            fill="#EDEFF1"
          />
          <path
            d="M63.2635 76.268V75.2376L66.3969 77.915L69.5323 75.2376V76.268L67.0015 78.4292L69.5323 80.5945V81.6229L66.3969 78.9454L63.2635 81.6229V80.5925L65.7944 78.4292L63.2635 76.268Z"
            fill="#EDEFF1"
          />
          <path
            d="M63.2635 83.0731V84.1035L65.7944 86.2647L63.2635 88.4279V89.4584L66.3969 86.7809L69.5323 89.4584V88.43L67.0015 86.2647L69.5323 84.1035V83.0731L66.3969 85.7505L63.2635 83.0731Z"
            fill="#EDEFF1"
          />
          <path
            d="M73.9874 60.599V59.5666L77.1208 62.244L80.2562 59.5666V60.597L77.7254 62.7582L80.2562 64.9235V65.9519L77.1208 63.2744L73.9874 65.9519V64.9215L76.5182 62.7582L73.9874 60.599Z"
            fill="#EDEFF1"
          />
          <path
            d="M73.9874 67.4021V68.4325L76.5182 70.5937L73.9874 72.757V73.7874L77.1208 71.1099L80.2562 73.7874V72.759L77.7254 70.5937L80.2562 68.4325V67.4021L77.1208 70.0795L73.9874 67.4021Z"
            fill="#EDEFF1"
          />
          <path
            d="M73.9874 76.268V75.2376L77.1208 77.915L80.2562 75.2376V76.268L77.7254 78.4292L80.2562 80.5945V81.6229L77.1208 78.9454L73.9874 81.6229V80.5925L76.5182 78.4292L73.9874 76.268Z"
            fill="#EDEFF1"
          />
          <path
            d="M73.9874 83.0731V84.1035L76.5182 86.2647L73.9874 88.4279V89.4584L77.1208 86.7809L80.2562 89.4584V88.43L77.7254 86.2647L80.2562 84.1035V83.0731L77.1208 85.7505L73.9874 83.0731Z"
            fill="#EDEFF1"
          />
          <path d="M60.6172 11.3818V15.4954H80.1567V52.5177H83.2419V11.3818H60.6172Z" fill="#B0AEAE" />
          <path
            d="M80.1386 55.4609L75.0147 58.6928H69.3586L74.3419 55.4609H63.8269L60.6172 53.5508H83.2419L80.1386 55.4609Z"
            fill="#B0AEAE"
          />
          <path
            d="M67.8125 4.69787C67.8366 4.69787 68.2544 5.0333 67.8125 4.69787C69.8874 4.728 72.0013 4.94091 72.2604 6.75466V11.3824H76.0397V5.21207C76.0397 4.07521 75.1197 3.15527 73.9829 3.15527H69.6894C68.3267 3.15527 68.1359 3.98482 67.8125 4.69787Z"
            fill="#959292"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.6172 6.38281V11.5248H61.3974V10.7902H66.0783V11.5248H66.8585V10.7902H71.5395V11.5248H72.3196V10.7902H77.0006V11.5248H77.7808V10.7902H82.4617V11.5248H83.2419V6.38281H60.6172ZM82.4617 7.11738H77.7808V7.85195H82.4617V7.11738ZM82.4617 8.58652H77.7808V10.0557H82.4617V8.58652ZM77.0006 8.58652V10.0557H72.3196V8.58652H77.0006ZM77.0006 7.11738V7.85195H72.3196V7.11738H77.0006ZM71.5395 7.11738H66.8585V7.85195H71.5395V7.11738ZM71.5395 8.58652H66.8585V10.0557H71.5395V8.58652ZM66.0783 8.58652V10.0557H61.3974V8.58652H66.0783ZM66.0783 7.11738V7.85195H61.3974V7.11738H66.0783Z"
            fill="#EDEFF1"
          />
          <path
            d="M10.2227 5.15527C10.2227 4.0507 11.1181 3.15527 12.2227 3.15527H16.4498C17.5544 3.15527 18.4498 4.0507 18.4498 5.15527V11.3824H10.2227V5.15527Z"
            fill="#B0AEAE"
          />
          <path d="M11.6674 58.6928L3.02734 53.5508H25.6521L17.2962 58.6928H11.6674Z" fill="#EDEFF1" />
          <rect x="3.02734" y="11.3887" width="22.6247" height="41.1358" fill="#EDEFF1" />
          <path
            d="M25.6531 55.6064L23.5963 56.8016V91.6003H22.5679V94.6855H26.6815V91.6003H25.6531V55.6064Z"
            fill="#EDEFF1"
          />
          <path d="M5.08519 56.914L3.0284 55.7531V91.6003H2V94.6855H6.11358V91.6003H5.08519V56.914Z" fill="#EDEFF1" />
          <path d="M15.3692 59.72H13.3124V91.6003H12.284V94.6855H16.3975V91.6003H15.3692V59.72Z" fill="#EDEFF1" />
          <path
            d="M5.6737 59.5734V60.6058L8.20452 62.765L5.6737 64.9283V65.9587L8.8071 63.2813L11.9425 65.9587V64.9303L9.41168 62.765L11.9425 60.6038V59.5734L8.8071 62.2509L5.6737 59.5734Z"
            fill="#EDEFF1"
          />
          <path
            d="M5.6737 67.4089V68.4393L8.20452 70.6005L5.6737 72.7638V73.7942L8.8071 71.1168L11.9425 73.7942V72.7658L9.41168 70.6005L11.9425 68.4393V67.4089L8.8071 70.0863L5.6737 67.4089Z"
            fill="#EDEFF1"
          />
          <path
            d="M5.6737 76.2748V75.2444L8.8071 77.9218L11.9425 75.2444V76.2748L9.41168 78.436L11.9425 80.6013V81.6297L8.8071 78.9522L5.6737 81.6297V80.5993L8.20452 78.436L5.6737 76.2748Z"
            fill="#EDEFF1"
          />
          <path
            d="M5.6737 83.0799V84.1103L8.20452 86.2715L5.6737 88.4348V89.4652L8.8071 86.7877L11.9425 89.4652V88.4368L9.41168 86.2715L11.9425 84.1103V83.0799L8.8071 85.7573L5.6737 83.0799Z"
            fill="#EDEFF1"
          />
          <path
            d="M16.3975 60.6058V59.5734L19.5309 62.2509L22.6663 59.5734V60.6038L20.1355 62.765L22.6663 64.9303V65.9587L19.5309 63.2813L16.3975 65.9587V64.9283L18.9284 62.765L16.3975 60.6058Z"
            fill="#EDEFF1"
          />
          <path
            d="M16.3975 67.4089V68.4393L18.9284 70.6005L16.3975 72.7638V73.7942L19.5309 71.1168L22.6663 73.7942V72.7658L20.1355 70.6005L22.6663 68.4393V67.4089L19.5309 70.0863L16.3975 67.4089Z"
            fill="#EDEFF1"
          />
          <path
            d="M16.3975 76.2748V75.2444L19.5309 77.9218L22.6663 75.2444V76.2748L20.1355 78.436L22.6663 80.6013V81.6297L19.5309 78.9522L16.3975 81.6297V80.5993L18.9284 78.436L16.3975 76.2748Z"
            fill="#EDEFF1"
          />
          <path
            d="M16.3975 83.0799V84.1103L18.9284 86.2715L16.3975 88.4348V89.4652L19.5309 86.7877L22.6663 89.4652V88.4368L20.1355 86.2715L22.6663 84.1103V83.0799L19.5309 85.7573L16.3975 83.0799Z"
            fill="#EDEFF1"
          />
          <path d="M3.02734 11.3887V15.5023H22.5669V52.5245H25.6521V11.3887H3.02734Z" fill="#B0AEAE" />
          <path
            d="M22.5488 55.4609L17.4249 58.6928H11.7687L16.752 55.4609H6.23706L3.02734 53.5508H25.6521L22.5488 55.4609Z"
            fill="#B0AEAE"
          />
          <path
            d="M10.2227 4.69787C10.2468 4.69787 10.6645 5.0333 10.2227 4.69787C12.2975 4.728 14.4115 4.94091 14.6706 6.75466V11.3824H18.4498V5.21207C18.4498 4.07521 17.5299 3.15527 16.393 3.15527H12.0996C10.7369 3.15527 10.546 3.98482 10.2227 4.69787Z"
            fill="#959292"
          />
          <path
            d="M39.0195 6.75744C39.0436 6.75744 39.4614 7.09287 39.0195 6.75744C41.0944 6.78757 43.2083 7.00048 43.4675 8.81423V13.442H47.2467V7.27164C47.2467 6.13478 46.3268 5.21484 45.1899 5.21484H40.8965C39.5337 5.21484 39.3429 6.04439 39.0195 6.75744Z"
            fill="#959292"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.02734 6.39258V11.5346H3.80751V10.8H8.48848V11.5346H9.26864V10.8H13.9496V11.5346H14.7298V10.8H19.4108V11.5346H20.1909V10.8H24.8719V11.5346H25.6521V6.39258H3.02734ZM24.8719 7.12715H20.1909V7.86172H24.8719V7.12715ZM24.8719 8.59628H20.1909V10.0654H24.8719V8.59628ZM19.4108 8.59628V10.0654H14.7298V8.59628H19.4108ZM19.4108 7.12715V7.86172H14.7298V7.12715H19.4108ZM13.9496 7.12715H9.26864V7.86172H13.9496V7.12715ZM13.9496 8.59628H9.26864V10.0654H13.9496V8.59628ZM8.48848 8.59628V10.0654H3.80751V8.59628H8.48848ZM8.48848 7.12715V7.86172H3.80751V7.12715H8.48848Z"
            fill="#EDEFF1"
          />
          <path
            d="M18.8366 16.4854H8.73542C8.65486 16.4854 8.58984 16.5508 8.58984 16.6315V17.3869C8.58984 17.4676 8.65486 17.5331 8.73542 17.5331H11.3374V18.2375H8.73542C8.65486 18.2375 8.58984 18.3029 8.58984 18.3836V21.5981C8.58984 21.6788 8.65486 21.7443 8.73542 21.7443H11.3374V22.4758H8.73542C8.65486 22.4758 8.58984 22.5414 8.58984 22.622V23.3775C8.58984 23.4582 8.65486 23.5237 8.73542 23.5237H18.8366C18.9172 23.5237 18.9822 23.4582 18.9822 23.3775V22.622C18.9822 22.5414 18.9172 22.4758 18.8366 22.4758H16.1611V21.7443H18.8366C18.9172 21.7443 18.9822 21.6788 18.9822 21.5981V18.3836C18.9822 18.3029 18.9172 18.2375 18.8366 18.2375H16.1611V17.5331H18.8366C18.9172 17.5331 18.9822 17.4676 18.9822 17.3869V16.6315C18.9822 16.5508 18.9172 16.4854 18.8366 16.4854ZM14.7718 18.3845V19.1399C14.7718 19.2206 14.8368 19.286 14.9174 19.286H17.5914V20.6957H14.9174C14.8368 20.6957 14.7718 20.7611 14.7718 20.8418V22.4751H12.7281V20.8418C12.7281 20.7611 12.6631 20.6957 12.5825 20.6957H9.98057V19.286H12.5825C12.6631 19.286 12.7281 19.2206 12.7281 19.1399V17.5339H14.7718V18.3845Z"
            fill="#012C7A"
          />
          <path
            d="M18.8267 31.4173H17.9759C17.1335 31.4309 16.3096 31.1721 15.7697 30.7855H18.6698C18.7504 30.7855 18.8154 30.7201 18.8154 30.6394V27.2939C18.8154 27.2133 18.7504 27.1477 18.6698 27.1477H14.5302V26.6765H18.8394C18.92 26.6765 18.9864 26.6111 18.9864 26.5304V25.8771C18.9864 25.7964 18.92 25.731 18.8394 25.731H14.5302V25.4059C14.5302 25.3252 14.4637 25.2598 14.3832 25.2598H13.1973C13.1168 25.2598 13.0503 25.3252 13.0503 25.4059V25.731H8.74108C8.65911 25.731 8.59409 25.7964 8.59409 25.8771V26.5304C8.59409 26.6111 8.65911 26.6765 8.74108 26.6765H13.0503V27.1477H8.88242C8.80186 27.1477 8.73684 27.2133 8.73684 27.2939V30.6394C8.73684 30.7201 8.80186 30.7855 8.88242 30.7855H11.8137C11.2738 31.1721 10.4498 31.4309 9.60746 31.4173H8.75663C8.66476 31.4173 8.58984 31.4915 8.58984 31.5825V32.3149C8.58984 32.4068 8.66476 32.4803 8.75663 32.4803H9.80533C11.0675 32.4803 12.3239 31.9883 13.0503 31.2408V32.4275C13.0503 32.5082 13.1168 32.5737 13.1973 32.5737H14.3832C14.4637 32.5737 14.5302 32.5082 14.5302 32.4275V31.2384C15.2552 31.9874 16.5131 32.4803 17.7766 32.4803H18.8253C18.9172 32.4803 18.9921 32.4059 18.9921 32.3149V31.5825C18.9921 31.4915 18.9186 31.4173 18.8253 31.4173H18.8267ZM10.1078 29.4047H13.0503V29.9094H10.1078V29.4047ZM17.4416 29.9094H14.5287V29.4047H17.4416V29.9094ZM17.4416 28.5285H14.5287V28.0238H17.4416V28.5285ZM13.049 28.5285H10.1078V28.0238H13.049V28.5285Z"
            fill="#012C7A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9708 40.2965H19.2518C19.3396 40.2965 19.4089 40.3705 19.4089 40.4616V41.0783C19.4089 41.1702 19.3396 41.2433 19.2518 41.2433H18.8518C17.5658 41.2433 16.3517 40.9607 15.9956 40.0456H15.2111C14.855 40.9618 13.6424 41.2433 12.3576 41.2433H11.8334C11.7457 41.2433 11.675 41.1694 11.675 41.0783V40.4616C11.675 40.3705 11.7457 40.2965 11.8334 40.2965H12.2376C12.3273 40.2965 12.4206 40.299 12.5153 40.3015C12.9876 40.3139 13.4947 40.3269 13.7652 40.0448H12.3364C12.2558 40.0448 12.1895 39.9793 12.1895 39.8987V35.7161C12.1895 35.6355 12.2558 35.5701 12.3364 35.5701H14.9157V35.1453H12.3364C12.2558 35.1453 12.1895 35.0796 12.1895 34.999V34.4527C12.1895 34.3721 12.2558 34.3066 12.3364 34.3066H18.8731C18.9537 34.3066 19.0202 34.3721 19.0202 34.4527V34.999C19.0202 35.0796 18.9537 35.1453 18.8731 35.1453H16.2938V35.5701H18.8985C18.9791 35.5701 19.0443 35.6355 19.0443 35.7161V39.8987C19.0443 39.9793 18.9791 40.0448 18.8985 40.0448H17.4429C17.7123 40.3269 18.2192 40.3139 18.6923 40.3015C18.7873 40.299 18.8808 40.2965 18.9708 40.2965ZM13.5689 36.8062H17.6676V36.3358H13.5689V36.8062ZM17.6676 37.5722H13.5689V38.0426H17.6676V37.5722ZM17.6676 38.8084H13.5689V39.279H17.6676V38.8084Z"
            fill="#012C7A"
          />
          <path
            d="M11.8701 37.1739V39.7447C11.8688 39.8245 11.8039 39.8899 11.7233 39.8899H11.1C11.0194 39.8899 10.9545 39.8245 10.9545 39.7439V37.9016L10.4413 37.9126V41.0369C10.4413 41.1175 10.3762 41.1829 10.2959 41.1829H8.55597C8.47536 41.1829 8.41049 41.1175 8.41049 41.0369V40.4403C8.41049 40.3595 8.47536 40.294 8.55597 40.294H9.29383V37.938L8.33541 37.9587C8.08807 37.9587 8.02734 37.8911 8.02734 37.8072V37.1739C8.02734 37.09 8.08945 37.0221 8.33541 37.0221H9.08597L8.7042 36.3466C8.68322 36.3096 8.67329 36.2202 8.73402 36.1724L9.30653 35.8009C9.3968 35.7354 9.47878 35.8216 9.50142 35.8586L9.81528 36.391C10.4005 36.1484 10.6945 35.6101 10.8386 35.2005H8.38068C8.30008 35.2005 8.2352 35.135 8.2352 35.0542V34.4902C8.2352 34.4099 8.30008 34.3442 8.38068 34.3442H11.7175C11.7981 34.3442 11.8646 34.4099 11.8646 34.4902V35.0542L11.8635 35.0624L11.8605 35.0751C11.8152 35.7561 11.3686 36.5716 10.7889 37.0221H11.7034C11.7034 37.0221 11.7526 37.0221 11.7975 37.0464C11.8354 37.0668 11.8701 37.1043 11.8701 37.1739Z"
            fill="#012C7A"
          />
          <path
            d="M16.2325 48.053H19.0382C19.0843 48.053 19.1257 48.0745 19.1525 48.1085C19.1721 48.1333 19.1837 48.1648 19.1837 48.199V48.9891C19.1837 49.0697 19.1185 49.1351 19.0382 49.1351H16.2325V50.1037C16.2325 50.1559 16.2055 50.2017 16.1646 50.2277C16.1422 50.2418 16.1157 50.2501 16.087 50.2501H14.9876C14.9056 50.2501 14.8404 50.1846 14.8404 50.1037V49.1351H11.9925C11.9618 49.1351 11.9337 49.126 11.9102 49.1105C11.871 49.0843 11.8456 49.0396 11.8456 48.9891V48.199C11.8456 48.1184 11.9105 48.053 11.9925 48.053H14.8404V47.2439H12.6216C12.541 47.2439 12.4744 47.1785 12.4744 47.0979V46.3079C12.4744 46.2273 12.541 46.1618 12.6216 46.1618H14.8404V43.0718C14.8404 42.9912 14.9056 42.9258 14.9876 42.9258H16.087C16.1676 42.9258 16.2325 42.9912 16.2325 43.0718V46.1618H18.5803C18.6609 46.1618 18.7257 46.2273 18.7257 46.3079V47.0979C18.7257 47.1374 18.7103 47.1733 18.6849 47.1995C18.6584 47.2268 18.6214 47.2439 18.5803 47.2439H16.2325V48.053Z"
            fill="#012C7A"
          />
          <path
            d="M11.8081 47.1763C11.7998 47.1973 11.7899 47.2169 11.778 47.2348C11.7631 47.2577 11.7457 47.2782 11.7258 47.2953C11.63 47.3759 11.4666 47.4675 11.2494 47.5572C11.1585 47.5945 11.0583 47.6315 10.9499 47.6674V49.9931C10.9499 50.0737 10.8847 50.1394 10.8041 50.1394H9.03329C8.95268 50.1394 8.88753 50.0737 8.88753 49.9931V49.3526C8.88753 49.272 8.95268 49.2063 9.03329 49.2063H9.73002V47.9495C9.3753 48.0014 8.99381 48.0348 8.59382 48.0389C8.51184 48.0398 8.44531 47.9509 8.44531 47.8424V47.2426C8.44531 47.1396 8.50466 47.0543 8.58251 47.0479C8.8199 47.0286 9.23259 47.0358 9.73002 46.9662V45.2868H8.63468C8.55407 45.2868 8.4892 45.2211 8.4892 45.1408V44.5136C8.4892 44.433 8.55407 44.3676 8.63468 44.3676H9.73002V43.159C9.73002 43.0787 9.79655 43.013 9.87688 43.013H10.8041C10.8847 43.013 10.9512 43.0787 10.9512 43.159V44.3676H11.6932C11.7738 44.3676 11.8387 44.433 11.8387 44.5136V45.1408C11.8387 45.1697 11.8302 45.1968 11.8155 45.2197C11.8017 45.241 11.7827 45.2584 11.7603 45.2702C11.7404 45.2807 11.7175 45.2868 11.6932 45.2868H10.9512V46.6364C11.1715 46.5431 11.3948 46.4263 11.6182 46.2803C11.7186 46.2132 11.8373 46.2899 11.8373 46.4409V47.0198C11.8373 47.0755 11.8271 47.1291 11.8081 47.1763Z"
            fill="#012C7A"
          />
          <path
            d="M17.5302 45.8659H16.7233C16.572 45.8659 16.4757 45.7188 16.5635 45.6247C17.1995 44.9354 17.5768 44.3469 17.7902 43.4163C17.8087 43.334 17.8808 43.267 17.9823 43.267H18.8108C18.9181 43.267 19.0056 43.3252 18.9902 43.4124C18.7401 44.7557 17.8269 45.7428 17.8269 45.7428C17.7394 45.8146 17.6403 45.8659 17.5302 45.8659Z"
            fill="#012C7A"
          />
          <path
            d="M13.273 45.7428C13.3608 45.8146 13.461 45.8656 13.5701 45.8656H14.3769C14.4713 45.8656 14.5445 45.8074 14.5641 45.7406C14.5754 45.7014 14.5685 45.6594 14.5368 45.6244C13.8994 44.9354 13.5234 44.3466 13.3084 43.4155C13.2902 43.3332 13.2181 43.2661 13.1162 43.2661H12.2881C12.1807 43.2661 12.093 43.3244 12.1087 43.4113C12.3574 44.7548 13.2717 45.742 13.273 45.7428Z"
            fill="#012C7A"
          />
          <path d="M50.3359 13.4404H54.4495V28.8664H50.3359V13.4404Z" fill="#B0AEAE" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.8281 8.44824V13.5902H32.6083V12.8557H37.2893V13.5902H38.0694V12.8557H42.7504V13.5902H43.5306V12.8557H48.2115V13.5902H48.9917V12.8557H53.6727V13.5902H54.4528V8.44824H31.8281ZM53.6727 9.18281H48.9917V9.91738H53.6727V9.18281ZM53.6727 10.6519H48.9917V12.1211H53.6727V10.6519ZM48.2115 10.6519V12.1211H43.5306V10.6519H48.2115ZM48.2115 9.18281V9.91738H43.5306V9.18281H48.2115ZM42.7504 9.18281H38.0694V9.91738H42.7504V9.18281ZM42.7504 10.6519H38.0694V12.1211H42.7504V10.6519ZM37.2893 10.6519V12.1211H32.6083V10.6519H37.2893ZM37.2893 9.18281V9.91738H32.6083V9.18281H37.2893Z"
            fill="#EDEFF1"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_305_46399"
            x="0"
            y="0.155273"
            width="96.2695"
            height="105.69"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="5" dy="4" />
            <feGaussianBlur stdDeviation="3.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_305_46399" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_305_46399" result="shape" />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
}
