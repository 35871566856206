import { MonthlyReportCoarseAggAnalysis, MonthlyReportNameEnum } from '@Src/_basic/object/MonthlyReportType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonthlyReportSelect from '../components/MonthlyReportSelect';

const CoarseAggregateAnalysis = () => {
  const { t: i18T } = useTranslation();
  const [tableData, setTableData] = useState<Array<MonthlyReportCoarseAggAnalysis>>([]);

  const selectHandler = useCallback(
    (
      factoryId: number,
      exptUnitId: number,
      startTime: number,
      endTime: number,
      specificationId?: number,
      sourceId?: number,
      supplierId?: number,
      formId?: number,
    ) => {
      setTableData([]);
      if (!factoryId || !exptUnitId || !startTime || !endTime || !formId) return;

      MonthlyReportApi.getCoarseAggAnalysis(
        {
          factoryId,
          exptUnitId,
          startTime,
          endTime,
          formId,
        },
        (_data) => setTableData(_data),
      );
    },
    [],
  );

  return (
    <MonthlyReportSelect
      title={i18T('REPORT.MONTHLY_REPORT.COARSE_AGGREGATE_ANALYSIS.TITLE')}
      exptType={MonthlyReportNameEnum.COARSE_AGGREGATE_ANALYSIS}
      selectHandler={selectHandler}
      tableData={tableData}
    />
  );
};

export default memo(CoarseAggregateAnalysis);
