import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import MaterialInspectionByAreaScreen from './screens/MaterialInspectionByAreaScreen';
import MaterialInspectionByDetailScreen from './screens/MaterialInspectionByDetailScreen';
import MaterialInspectionByFactoryScreen from './screens/MaterialInspectionByFactoryScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/materialInspectionByArea/*',
    element: <MaterialInspectionByAreaScreen />,
  },
  {
    path: '/materialInspectionByFactory/:id',
    element: <MaterialInspectionByFactoryScreen />,
  },
  {
    path: '/materialInspectionByDetail/:id',
    element: <MaterialInspectionByDetailScreen />,
  },
];

const MaterialInspection = () => PermissionRoute(routesConfig);

export default memo(MaterialInspection);
