import useGetAllFactories from '@Src/_basic/apiHooks/useGetAllFactories';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import NewStaffIcon from '@Src/_basic/icons/NewStaff';
import SearchIcon from '@Src/_basic/icons/Search';
import { useApi } from '@Src/redux/api/apiAction';
import { SelectChangeEvent, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Query } from '..';

interface Props {
  query: Query;
  handleChange: (file: keyof Query, value: Query[keyof Query]) => void;
  getTransferGroup: () => void;
}

export default function TransferOrderGroupManagementSearchBar({ query, handleChange, getTransferGroup }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const navigate = useNavigate();

  const { factoryOption } = useGetAllFactories();

  const handleGoToCreatePage = () => {
    navigate('/UsersManagePage/TransferOrderGroupManagement/TransferOrderGroupManagementCreate', {
      state: { factoryValue: query.factoryId },
    });
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
        <YatungSelect
          helperText={i18T('GLOBAL.FACTORY')}
          disabled={actionLoading}
          options={factoryOption}
          value={query.factoryId}
          onChange={(e: SelectChangeEvent<unknown>) => {
            handleChange('factoryId', e.target.value as number);
          }}
        />
        <YatungInput value={query.keyword} onChange={(e: any) => handleChange('keyword', e.target.value)} />
        <YatungButton
          text={i18T('GLOBAL.SEARCh_BUTTON')}
          startIcon={<SearchIcon />}
          color="blue"
          onClick={getTransferGroup}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <YatungButton
          text={i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.CREATE_TRANSFER_GROUP')}
          startIcon={<NewStaffIcon />}
          onClick={handleGoToCreatePage}
          color={'green'}
          sx={{
            fontSize: '16px',
          }}
        />
      </Stack>
    </Stack>
  );
}
