import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import React, { memo } from 'react';
import SieveReportScreen from './screen';

const routesConfig = [
  {
    path: '/',
    element: <SieveReportScreen />,
  },
];

const SieveReport = () => PermissionRoute(routesConfig);

export default memo(SieveReport);
