import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PotionBigIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} width="63" height="64" viewBox="0 0 63 64" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M33.6774 38.8571C36.8842 38.8571 39.4839 36.2988 39.4839 33.1429C39.4839 31.8564 39.0519 30.6693 38.323 29.7143H40.6371L52.8485 52.5909C54.4705 55.6299 52.4429 59.4286 49.1988 59.4286H13.5109C10.2668 59.4286 8.23914 55.6299 9.86119 52.5909L22.0725 29.7143H29.0318C28.3029 30.6693 27.871 31.8564 27.871 33.1429C27.871 36.2988 30.4706 38.8571 33.6774 38.8571ZM22.0645 50.2857C23.9886 50.2857 25.5484 48.7507 25.5484 46.8571C25.5484 44.9636 23.9886 43.4286 22.0645 43.4286C20.1404 43.4286 18.5806 44.9636 18.5806 46.8571C18.5806 48.7507 20.1404 50.2857 22.0645 50.2857ZM32.5161 57.1429C33.7989 57.1429 34.8387 56.1195 34.8387 54.8571C34.8387 53.5948 33.7989 52.5714 32.5161 52.5714C31.2334 52.5714 30.1936 53.5948 30.1936 54.8571C30.1936 56.1195 31.2334 57.1429 32.5161 57.1429Z" fill="#7C878E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M56.9745 64C61.6059 64 64.5007 59 62.185 55L44.7146 24.8248C44.1827 23.906 44.1914 22.8008 44.1999 21.7311C44.201 21.5978 44.202 21.4651 44.202 21.3334V4C44.202 1.79039 42.406 0 40.1909 0H22.8091C20.594 0 18.798 1.79039 18.798 4V21.3334C18.798 21.4651 18.799 21.5978 18.8001 21.731C18.8086 22.8007 18.8173 23.906 18.2854 24.8248L0.814987 55C-1.5007 59 1.39407 64 6.02545 64H56.9745ZM21.1206 4C21.1206 3.02441 21.9053 2.28571 22.8091 2.28571H40.1909C41.0947 2.28571 41.8794 3.02441 41.8794 4V21.3334C41.8794 21.4284 41.8785 21.5352 41.8775 21.6509C41.8733 22.1264 41.8679 22.7528 41.9204 23.3385C41.9896 24.11 42.1698 25.0465 42.6966 25.9563L60.167 56.1315C61.6267 58.6531 59.7711 61.7143 56.9745 61.7143H6.02545C3.22886 61.7143 1.37327 58.6531 2.83302 56.1315L20.3034 25.9563C20.8302 25.0465 21.0104 24.11 21.0796 23.3385C21.1321 22.7528 21.1267 22.1264 21.1225 21.6509C21.1215 21.5356 21.1206 21.4281 21.1206 21.3334V4Z" fill="#7C878E" />
        </SvgIcon>
    );
}