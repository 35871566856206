import React, { CSSProperties, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

export enum ClimateMonitoringOptionsValueEnum {
  AVERAGE_TEMPERATURE = 1,
  HIGHEST_TEMPERATURE = 2,
  LOWEST_TEMPERATURE = 3,
  CONCRETE_PRODUCTION = 4,
}

interface Props {
  data?: Array<any>;
  chartOptionsValue: Array<number>;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
}

export default function ClimateMonitoringChart(props: Props) {
  const chartRef = useRef<any>(null);

  const options: ApexCharts.ApexOptions = useMemo(() => {
    return {
      chart: {
        id: 'climate-monitoring-chart',
      },
      colors: ['#00D03A', '#003087', '#F0CA00', '#FFC700'],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          '1月1日',
          '2月1日',
          '3月1日',
          '4月1日',
          '5月1日',
          '6月1日',
          '7月1日',
          '8月1日',
          '9月1日',
          '10月1日',
          '11月1日',
          '12月1日',
        ],
      },
      grid: {
        show: true,
        borderColor: '#8695B2',
        strokeDashArray: 5,
        padding: {
          bottom: 10,
          left: 10,
        },
      },
    };
  }, []);

  const series = useMemo(() => {
    const chartOptionsValueMap = new Map<number, boolean>();

    props.chartOptionsValue.forEach((value) => {
      chartOptionsValueMap.set(value, true);
    });

    const seriesTemp: Array<any> = [];

    if (chartOptionsValueMap.get(ClimateMonitoringOptionsValueEnum.AVERAGE_TEMPERATURE)) {
      seriesTemp.push({
        name: '平均溫度',
        data: [16.5, 18.5, 21.5, 23.5, 24.5, 27.5, 32.5, 31.5, 26.5, 18.5, 16.5, 13.5],
      });
    }

    if (chartOptionsValueMap.get(ClimateMonitoringOptionsValueEnum.LOWEST_TEMPERATURE)) {
      seriesTemp.push({
        name: '最低溫度',
        data: [15, 17, 20, 22, 23, 26, 31, 30, 25, 17, 15, 12],
      });
    }

    if (chartOptionsValueMap.get(ClimateMonitoringOptionsValueEnum.HIGHEST_TEMPERATURE)) {
      seriesTemp.push({
        name: '最高溫度',
        data: [18, 20, 23, 25, 26, 29, 34, 33, 28, 20, 18, 15],
      });
    }

    if (chartOptionsValueMap.get(ClimateMonitoringOptionsValueEnum.CONCRETE_PRODUCTION)) {
      seriesTemp.push({
        name: '混凝土生產方數',
        data: [100, 130, 150, 200, 300, 400, 100, 130, 150, 200, 300, 400],
      });
    }

    return seriesTemp;
  }, [props.chartOptionsValue]);

  return <Chart ref={chartRef} options={options} series={series} type="area" />;
}
