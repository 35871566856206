import { Response } from '@Src/_basic/object/ApiType';
import {
  AllTest,
  CreateTestRequest,
  GetExptStatusRequest,
  GetRecordRequest,
  GetTestDetailRequest,
  PutBaseTestRequest,
  PutTestRequest,
  RecordSearch,
  RecordSearchParams,
  TestData,
} from '@Src/_basic/object/TestType';
import {
  GetGroupDataByCementCompStrRequest,
  GroupDataByCementCompStrResponse,
} from '@Src/_basic/object/test/cementtitiousPowder/CementCompStrType';
import {
  GetGroupDataByFlyAshActivityRequest,
  GroupDataByFlyAshActivityResponse,
} from '@Src/_basic/object/test/cementtitiousPowder/FlyAshActivityType';
import {
  GetGroupDataBySlagActivityRequest,
  GroupDataBySlagActivityResponse,
} from '@Src/_basic/object/test/cementtitiousPowder/SlagActivityType';
import { Api } from '../Api';
import { TestProtocol } from './TestProtocol';

export class TestApi {
  public static createTest(
    request: CreateTestRequest,
    onSuccess?: (data: TestData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(TestProtocol.TEST, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static getExptRecordsBySearch(
    params: RecordSearchParams,
    onSuccess?: (data: Array<TestData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TestProtocol.RECORD_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  // searchExpt 搜尋試驗
  public static getRecord(
    params: GetRecordRequest,
    onSuccess?: (data: RecordSearch) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TestProtocol.RECORD_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  //exptStatus 取得試驗狀態
  public static getExptStatus(
    params: GetExptStatusRequest,
    onSuccess?: (data: RecordSearch) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TestProtocol.GET_EXPT_STATUS, config, onSuccess, onFailed, onFinally);
  }

  // getExpt 取得試驗單詳細內容
  public static getTestDetail(
    params: GetTestDetailRequest,
    onSuccess?: (data: AllTest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TestProtocol.TEST_DETAIL, config, onSuccess, onFailed, onFinally);
  }

  // 取得 控制組+實驗組
  public static getGroupDataByFlyAshActivity(
    params: GetGroupDataByFlyAshActivityRequest,
    onSuccess?: (data: GroupDataByFlyAshActivityResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TestProtocol.GROUP_DATA_BY_FLY_ASH_ACTIVITY, config, onSuccess, onFailed, onFinally);
  }

  // 取得 控制組+實驗組
  public static getGroupDataBySlagActivity(
    params: GetGroupDataBySlagActivityRequest,
    onSuccess?: (data: GroupDataBySlagActivityResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TestProtocol.GROUP_DATA_BY_SLAG_ACTIVITY, config, onSuccess, onFailed, onFinally);
  }

  // 取得 控制組+實驗組
  public static getGroupDataByCementCompStr(
    params: GetGroupDataByCementCompStrRequest,
    onSuccess?: (data: Array<GroupDataByCementCompStrResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TestProtocol.GROUP_DATA_BY_CEMENT_COMP_STR, config, onSuccess, onFailed, onFinally);
  }

  // updateExpt 修改試驗
  public static putTest<T = PutBaseTestRequest>(
    request: PutTestRequest<T>,
    onSuccess?: (data: AllTest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(TestProtocol.TEST, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改試驗
  public static putChemAdmxPentaTest(
    request: any,
    onSuccess?: (data: AllTest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(TestProtocol.CHEM_ADMX_PENTA_TEST, request, undefined, onSuccess, onFailed, onFinally);
  }
}
