import { ExptScheduleData, PageableExptScheduleData } from '@Src/_basic/object/ExptScheduleType';
import exptScheduleTableMock from '@Src/_basic/protocol/exptSchedule/_mock_/ExptScheduleTableMock';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialState {
  exptSchedule: ExptScheduleData;
  exptSchedules: Array<ExptScheduleData>;
  pageableExptSchedules: PageableExptScheduleData | undefined;
}

export const exptScheduleSlice = createSlice({
  name: 'exptScheduleSlice',
  initialState: {
    exptSchedule: exptScheduleTableMock[0],
    exptSchedules: [],
    pageableExptSchedules: undefined,
  } as initialState,
  reducers: {
    setExptSchedule: (state, action: PayloadAction<ExptScheduleData>) => {
      state.exptSchedule = action.payload;
    },
    setExptSchedules: (state, action: PayloadAction<Array<ExptScheduleData>>) => {
      state.exptSchedules = action.payload;
    },
    setPageableExptSchedules: (state, action: PayloadAction<PageableExptScheduleData | undefined>) => {
      state.pageableExptSchedules = action.payload;
    },
  },
});
