import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungTestPage from '@Src/_basic/components/YatungTest/YatungTestPage';
import BackButton from '@Src/_basic/icons/BackButton';
import { MonitorOrderData } from '@Src/_basic/object/MonitorType';
import { MonitorApi } from '@Src/_basic/protocol/monitor/MonitorApi';
import { useMonitor } from '@Src/redux/monitor/monitorAction';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import MonitorDetailCard from '../components/MonitorDetailCard';
import ViewImageCard from '../components/ViewImageCard';
import ViewVideoCard from '../components/ViewVideoCard';

export default function MonitorDetailCardScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const [updateModalOpen, setUpdateModalOpen] = useState<any>(false);
  const [viewVideoCardOpen, setViewVideoCardOpen] = useState<any>(false);
  const location = useLocation();
  const [monitorOrder, setMonitorOrder] = useState<MonitorOrderData[]>([]);
  const [count, setCount] = useState<number>(0); //計算車次總數
  const { date } = useMonitor();
  const [imageUrl, setImageUrl] = useState<string[]>([]); //拿取照片

  const [monitorByIdData, setMonitorByIdData] = useState<MonitorOrderData | undefined>({
    //拿取選擇的客戶資料
    shipmentId: 0,
    factoryId: 0,
    factoryName: '',
    mixerId: 0,
    mixerName: '',
    customerId: '',
    customerName: '',
    orderId: 0,
    orderName: '',
    proportionName: '',
    carId: '',
    strength: 0,
    slumpDemand: 0,
    actualSlump: 0,
    beforeShippingSlump: 0,
    visualSlump: 0,
    actualSlump2: 0,
    beforeShippingSlump2: 0,
    visualSlump2: 0,
    pouringPlace: '',
    imageUrl: [],
    imageUrl2: [],
    frontTubeSlump: 0,
    endTubeSlump: 0,
    materialStatus: '',
    lastExperimentOperator: '',
    status: true,
    reason: '',
    remark: '',
    frontTubeSlump2: 0,
    endTubeSlump2: 0,
    materialStatus2: '',
    lastExperimentOperator2: '',
    status2: true,
    reason2: '',
    remark2: '',
    shipmentDate: 0,
    createDate: 0,
    exptItemId: 0,
    exptItemId2: 0,
    exptTypeCode: '',
    exptTypeCode2: '',
    exptUnitName: '',
    exptUnitName2: '',
    slumpFlowDemand: 0,
    slumpFlowDemand2: 0,
    actualSlumpFlow: 0,
    actualSlumpFlow2: 0,
    beforeShippingSlumpFlow: 0,
    beforeShippingSlumpFlow2: 0,
    visualSlumpFlow: 0,
    visualSlumpFlow2: 0,
    imageUrlFlow: [],
    imageUrlFlow2: [],
    frontTubeSlumpFlow: 0,
    endTubeSlumpFlow: 0,
    materialStatusFlow: '',
    lastExperimentOperatorFlow: '',
    statusFlow: true,
    reasonFlow: '',
    remarkFlow: '',
    frontTubeSlumpFlow2: 0,
    endTubeSlumpFlow2: 0,
    materialStatusFlow2: '',
    lastExperimentOperatorFlow2: '',
    statusFlow2: true,
    reasonFlow2: '',
    remarkFlow2: '',
    exptFlowItemId: 0,
    exptFlowTypeCode: '',
    exptFlowUnitName: '',
    exptFlowItemId2: 0,
    exptFlowTypeCode2: '',
    exptFlowUnitName2: '',
  });

  const [currentTypeIndex, setCurrentTypeIndex] = useState(0);
  const [warningData, setWarningData] = useState<Array<number>>([]); //警告資料

  const handleImageButtonClick = (shipmentId: number) => {
    handleImageModalOpen();
    const selectedById = monitorOrder?.find((i: MonitorOrderData) => i.shipmentId === shipmentId); //按鈕點擊後拿取該筆照片資料
    setMonitorByIdData(selectedById);
  };

  const handleVideoButtonClick = (shipmentId: number) => {
    handleViewVideoCardOpen();
    const selectedById = monitorOrder?.find((i: MonitorOrderData) => i.shipmentId === shipmentId); //按鈕點擊後拿取該筆影片資料
    setMonitorByIdData(selectedById);
  };

  const handleButtonClick = (options: Array<number>) => {
    //按鈕跳轉到指定的警告地點，每次點擊依次執行
    const elementId = options[currentTypeIndex];
    const elements = document.getElementById(`${elementId}`);
    const paddingTop = 30; // 设置预留的空间
    if (elements) {
      const pos = elements.style.position;
      const top = elements.style.top;
      elements.style.position = 'relative';
      elements.style.top = `-${paddingTop}px`;
      elements.scrollIntoView({ behavior: 'smooth' }); //使用scrollIntoView()方法，滾動到指定元素
      elements.style.top = top;
      elements.style.position = pos;
    }

    if (currentTypeIndex < options.length - 1) {
      setCurrentTypeIndex(currentTypeIndex + 1);
    } else {
      setCurrentTypeIndex(0);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleImageModalOpen = () => {
    setUpdateModalOpen(true);
  };

  const onGoBackButtonClick = () => {
    setUpdateModalOpen(false);
  };

  const handleViewVideoCardOpen = () => {
    setViewVideoCardOpen(true);
  };

  const onGoBackVideoButtonClick = () => {
    setViewVideoCardOpen(false);
  };

  const getMonitorOrderQuest = (factoryId: number, customerId: string, orderId: string) => {
    MonitorApi.getMonitorOrder(
      factoryId,
      customerId,
      orderId,
      { date: moment(date).format('YYYY-MM-DD') },
      onGetMonitorSuccess,
    );
  };

  const onGetMonitorSuccess = (data: MonitorOrderData[]) => {
    setMonitorOrder(data);
  };

  const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true }); //useLocatioin拿取網址參數

  const testWarningData = () => {
    //測試用，確認有幾筆警告資料
    monitorOrder?.filter((i: MonitorOrderData) => {
      i.status === false || i.status2 === false || i.statusFlow === false || i.statusFlow2 === false
        ? setWarningData([i.shipmentId])
        : null;
    });
  };

  useEffect(() => {
    if (location.search) {
      if (queryObject.factoryId) {
        if (queryObject.customerId) {
          if (queryObject.orderId) {
            if (queryObject.count) {
              getMonitorOrderQuest(
                parseInt(queryObject.factoryId.toString()),
                queryObject.customerId.toString(),
                `${queryObject.orderId}`,
              );
              setCount(parseInt(queryObject?.count?.toString()));
            }
          }
        }
      }
    }
  }, [location.search]);

  useEffect(() => {
    testWarningData();
  }, [getMonitorOrderQuest]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.PAGE_TITLE') + `(${queryObject.factory})`}
      body={
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Stack
                style={{
                  marginLeft: '50px',
                  color: '#CB333B',
                  border: '1px solid #CB333B',
                  textAlign: 'center',
                  lineHeight: '50px',
                  background: 'rgba(255,0,0,0.15)',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  borderRadius: '10px',
                }}
              >{`${warningData.length}車異常，退${warningData.length}車`}</Stack>
              {warningData.length > 0 && (
                <Stack
                  onClick={() => handleButtonClick(warningData)}
                  sx={{
                    p: 0.5,
                    height: '30px',
                    borderRadius: '50%',
                    bgcolor: '#CB333B',
                    color: '#fff',
                    placeItems: 'center',
                    marginLeft: '10px',
                    marginTop: '10px',
                    ':hover': {
                      transition: '0.7s',
                      transform: 'scale(1.6)',
                      bgcolor: 'rgba(90, 96, 174, 0.6)',
                    },
                  }}
                >
                  <ExpandMoreIcon />
                </Stack>
              )}
            </Stack>
            <YatungButton
              text={i18T('GLOBAL.BACK_PAGE')}
              onClick={handleGoBack}
              startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
              sx={{ alignSelf: 'center', background: '#003087' }}
            />
          </Box>
          <YatungTestPage
            sx={{ marginTop: '20px' }}
            body={
              <>
                <MonitorDetailCard
                  handleImageButtonClick={(shipmentId: number) => handleImageButtonClick(shipmentId)}
                  handleVideoButtonClick={(shipmentId: number) => handleVideoButtonClick(shipmentId)}
                  setImageUrl={setImageUrl}
                  monitorOrderData={monitorOrder}
                  count={count}
                />
                <ViewImageCard
                  data={monitorByIdData}
                  updateModalOpen={updateModalOpen}
                  onGoBackButtonClick={onGoBackButtonClick}
                  imageUrl={imageUrl}
                />
                <ViewVideoCard
                  data={monitorByIdData}
                  updateModalOpen={viewVideoCardOpen}
                  onGoBackButtonClick={onGoBackVideoButtonClick}
                />
              </>
            }
          />
        </>
      }
      contentBgColor="#fff"
    />
  );
}
