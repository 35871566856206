import { Protocol } from "../../../Protocol";

export class RoleProtocol {

  public static readonly POST_GET_RIGHTS_ROLES: string = `${Protocol.YATUNG_AUTH}CA007`; // 取得權限角色清單

  public static readonly POST_GET_RIGHTS_ROLE_BY_ID: string = `${Protocol.YATUNG_AUTH}CA039`; // 取得角色組織
  public static readonly POST_SET_RIGHTS_ROLE_BELONG_ACTION: string = `${Protocol.YATUNG_AUTH}CA053`; // 設定角色Action權限

  public static readonly POST_CREATE_RIGHTS_ROLE_BY_ID: string = `${Protocol.YATUNG_AUTH}CA005`; // 建立角色權限
  public static readonly POST_UPDATE_RIGHTS_ROLE_BY_ID: string = `${Protocol.YATUNG_AUTH}CA006`; // 修改角色權限
  public static readonly POST_DELETE_RIGHTS_ROLE_BY_ID: string = `${Protocol.YATUNG_AUTH}CA008`; // 刪除角色權限
  
}