import YatungPage from '@Src/_basic/components/YatungPage';
import { AccountDataFormRequire } from '@Src/_basic/object/AccountManagementType';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateAcountFrom from '../CreateAcountForm';

export const initialAccount: AccountDataFormRequire = {
  state: '0',
  account: '',
  password: '',
  userName: '',
  jobTitle: '',
  organizationIds: [],
  email: '',
  phone: '',
  createDt: moment().format('YYYY/MM/DD'),
  updateDt: moment().format('YYYY/MM/DD'),
  sourceKind: 0,
};

export default function AccountManagementCreateCard() {
  const { t: i18T } = useTranslation();

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.CREATE_ACCOUNT')}
      contentBgColor="#FFF"
      body={<CreateAcountFrom initialValues={initialAccount} />}
    />
  );
}
