import YatungDialog from '@Src/_basic/components/YatungDialog';
import { Options } from '@Src/_basic/components/YatungSelect';
import SampleLocation from '@Src/_basic/icons/SampleLocation';
import { CreateSampleRequest } from '@Src/_basic/object/SampleLocationType';
import { SampleApi } from '@Src/_basic/protocol/sample/SampleApi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import SampleCreateField from './component/SampleCreateField';

interface Props {
  createModalOpen: boolean;
  onCreateGoBackButtonClick: () => void;
  factoryId: number;
  types: Options[];
  getSampleData: () => void;
}

const createSampleSchema = Yup.object().shape({
  name: Yup.string().required('請輸入取樣位置名稱'),
  typeIds: Yup.number().typeError('請選擇類別').min(1, '請選擇至少一個類別').required('請選擇類別'),
});

export default function SampleCreateCrad(props: Props) {
  const { t: i18T } = useTranslation();
  const { createModalOpen, onCreateGoBackButtonClick, factoryId, types, getSampleData } = props;

  const initialValues: CreateSampleRequest = useMemo(
    () => ({
      name: '',
      isAnalysis: true,
      factoryId: factoryId,
      typeIds: [],
    }),
    [factoryId],
  );

  const handleSubmit = useCallback(
    (request: CreateSampleRequest) => {
      SampleApi.CreateSample(request, () => {
        getSampleData();
        onCreateGoBackButtonClick();
      });
    },
    [getSampleData, onCreateGoBackButtonClick],
  );

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={createModalOpen}
        handleClose={onCreateGoBackButtonClick}
        icon={<SampleLocation sx={{ width: 40, height: 40, mr: 1, alignSelf: 'center' }} />}
        title={i18T('SAMPLEMANAGEMENT.CREATE_SAMPLE_BUTTON')}
        body={
          <Formik
            initialValues={initialValues}
            validationSchema={createSampleSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <SampleCreateField
                    values={values}
                    setValues={setFieldValue}
                    types={types}
                    onCreateGoBackButtonClick={onCreateGoBackButtonClick}
                  />
                </Form>
              );
            }}
          </Formik>
        }
      />
    </Grid>
  );
}
