import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import SampleCourseScreens from './screens/SampleCourseScreens';
import SampleCourseTreeScreens from './screens/SampleCourseTreeScreens';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <SampleCourseScreens />,
  },
  {
    path: '/tree/*',
    element: <SampleCourseTreeScreens />,
  },
];

const SampleCourseRouter = () => PermissionRoute(routesConfig);

export default memo(SampleCourseRouter);
