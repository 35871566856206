import { Snackbar, Stack, Alert, Button } from '@mui/material';
import React, { useEffect } from 'react';

interface Props {
  title: string;
  open: boolean;
  setOpen: (e: boolean) => void;
  type: 'error' | 'warning' | 'info' | 'success';
}
export default function YutungButtonAlert(props: Props) {
  const closeAlert = () => {
    props.setOpen(false);
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={props.open}>
        <Alert severity={props.type} sx={{ width: '26vw' }}>
          {props.title}
          <Button onClick={closeAlert} style={{background:'#003087',marginLeft:'10px',color:'#FFF'}}>確認</Button>
        </Alert>
      </Snackbar>
    </Stack>
  );
}
