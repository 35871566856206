import React from 'react';
import AssignGroupManagementCreateCard from '../components/AssignGroupManagementCreateCard';

export default function AssignGroupManagementCreateCardScreen() {
  return (
    <>
      <AssignGroupManagementCreateCard />
    </>
  );
}
