import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Spec(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M8.4,11.4H4c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h4.4c1.1,0,2,0.9,2,2v4.4C10.4,10.5,9.5,11.4,8.4,11.4z M4,4C3.4,4,3,4.4,3,5v4.4c0,0.6,0.4,1,1,1h4.4c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1H4z" />
      <path d="M21.7,5.4h-9.2c-0.2,0-0.4-0.2-0.4-0.5c0-0.3,0.2-0.5,0.4-0.5h9.2c0.2,0,0.4,0.2,0.4,0.5C22.1,5.2,21.9,5.4,21.7,5.4z M12.5,4.7v0.2l0,0h9.2l-9.2,0V4.7z" />
      <path d="M21.7,10.4h-9.2c-0.2,0-0.4-0.2-0.4-0.5s0.2-0.5,0.4-0.5h9.2c0.2,0,0.4,0.2,0.4,0.5S21.9,10.4,21.7,10.4zM12.5,9.6v0.2l0,0l9.2,0h-9.2V9.6z" />
      <path d="M8.4,21H4c-1.1,0-2-0.9-2-2v-4.4c0-1.1,0.9-2,2-2h4.4c1.1,0,2,0.9,2,2V19C10.4,20.1,9.5,21,8.4,21z M4,13.6c-0.6,0-1,0.4-1,1V19c0,0.6,0.4,1,1,1h4.4c0.6,0,1-0.4,1-1v-4.4c0-0.6-0.4-1-1-1H4z" />
      <path d="M21.7,15h-9.2c-0.2,0-0.4-0.2-0.4-0.5s0.2-0.5,0.4-0.5h9.2c0.2,0,0.4,0.2,0.4,0.5S21.9,15,21.7,15z M12.5,14.5L12.5,14.5h9.2H12.5z" />
      <path d="M21.7,19.9h-9.2c-0.2,0-0.4-0.2-0.4-0.5c0-0.3,0.2-0.5,0.4-0.5h9.2c0.2,0,0.4,0.2,0.4,0.5C22.1,19.7,21.9,19.9,21.7,19.9z M12.5,19.2l0,0.3v0h0V19.2z" />
    </SvgIcon>
  );
}
