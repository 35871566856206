import { CommonTest, CustomResultItem, PutBaseTestRequest } from '../../TestType';

export interface PutCoarseSieveRequest extends PutBaseTestRequest, ImportCoarseSieve {}

export interface ImportCoarseSieve {
  wetWeight: number;
  beforeRinseDryWeight: number;
  afterRinseDryWeight: number;

  // 篩孔距重量 (孔距由大至小)
  poreDiameter1: number;
  poreDiameter2: number;
  poreDiameter3: number;
  poreDiameter4: number;
  poreDiameter5: number;
  poreDiameter6: number;
  poreDiameter7: number;
  poreDiameter8: number;
  underPan: number;
}

export interface CoarseSieveVerifyPassedResults {
  type1: CoarseSieveVerifyPassedResultItems;
  type2: CoarseSieveVerifyPassedResultItems;
}

export interface CoarseSieveVerifyPassedResultItems {
  '37.5mm': boolean;
  '25.0mm': boolean;
  '19.0mm': boolean;
  '12.5mm': boolean;
  '9.5mm': boolean;
  '4.75mm': boolean;
  '2.36mm': boolean;
  '0.075mm': boolean;
  dirtContentRatio: boolean;
  lossPercentage: boolean;
}

export interface CoarseSieveVerifyCustomResults {
  type1: CoarseSieveVerifyCustomResultItems;
  type2: CoarseSieveVerifyCustomResultItems;
}

export interface CoarseSieveVerifyCustomResultItems {
  '37.5mm': CustomResultItem;
  '25.0mm': CustomResultItem;
  '19.0mm': CustomResultItem;
  '12.5mm': CustomResultItem;
  '9.5mm': CustomResultItem;
  '4.75mm': CustomResultItem;
  '2.36mm': CustomResultItem;
  '0.075mm': CustomResultItem;
  dirtContentRatio: CustomResultItem;
  lossPercentage: CustomResultItem;
}

export interface CoarseSieve extends CommonTest, ImportCoarseSieve {
  wetWeight: number;
  beforeRinseDryWeight: number;
  afterRinseDryWeight: number;
  poreDiameter1: number;
  poreDiameter2: number;
  poreDiameter3: number;
  poreDiameter4: number;
  poreDiameter5: number;
  poreDiameter6: number;
  poreDiameter7: number;
  poreDiameter8: number;
  underPan: number;
  waterAbsorption: number;
  poreDiameter1Percentage: number;
  sievingPoreDiameter1Percentage: number;
  poreDiameter2Percentage: number;
  sievingPoreDiameter2Percentage: number;
  poreDiameter3Percentage: number;
  sievingPoreDiameter3Percentage: number;
  poreDiameter4Percentage: number;
  sievingPoreDiameter4Percentage: number;
  poreDiameter5Percentage: number;
  sievingPoreDiameter5Percentage: number;
  poreDiameter6Percentage: number;
  sievingPoreDiameter6Percentage: number;
  poreDiameter7Percentage: number;
  sievingPoreDiameter7Percentage: number;
  poreDiameter8Percentage: number;
  sievingPoreDiameter8Percentage: number;
  sievingUnderPanPercentage: number;
  lossPercentage: number;
  dirtContentRatio: number;
  waterWeight: number;
  waterContent: number;
  surfaceWaterContent: number;
}

export enum CoarseSieveLegalTypeEnum {
  TYPE1,
  TYPE2,
}
