import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AreaManagementData } from '@Src/_basic/object/AreaType';

interface initialState {
  targetId: number;
  isWarningModalOpen: boolean;
  isUpdateModalOpen: boolean;
  isModalOpen: boolean;
  areaData: AreaManagementData | any;
  areaState: AreaManagementData | any;
  areaUpdateData: AreaManagementData | any;
}

export const areaSlice = createSlice({
  name: 'areaSlice',
  initialState: {
    targetId: 0,
    isWarningModalOpen: false,
    isUpdateModalOpen: false,
    isModalOpen: false,
    areaData: [],
    areaUpdateData: [],
  } as initialState,
  reducers: {
    setTargetId: (state, action: PayloadAction<number>) => {
      state.targetId = action.payload;
    },
    setAreaData: (state, action: PayloadAction<AreaManagementData | any>) => {
      state.areaData = action.payload;
    },
    setAreaUpdateData: (state, action: PayloadAction<AreaManagementData | any>) => {
      state.areaUpdateData = action.payload;
    },
    switchWarningModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWarningModalOpen = action.payload;
      console.log('switch menu to', state.isWarningModalOpen);
    },
    switchModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
      console.log('switch menu to', state.isModalOpen);
    },
    switchUpdateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isUpdateModalOpen = action.payload;
      console.log('switch menu to', state.isUpdateModalOpen);
    },
  },
});
