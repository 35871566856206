import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDateTimePicker from '@Src/_basic/components/YatungDateTimePicker';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPageTitle from '@Src/_basic/components/YatungPageTitle';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import CarPlateIcon from '@Src/_basic/icons/CarPlate';
import ClockIcon from '@Src/_basic/icons/Clock';
import ContentMangementIcon from '@Src/_basic/icons/ContentMangement';
import EditIcon from '@Src/_basic/icons/Edit';
import MenuIcon from '@Src/_basic/icons/Menu';
import SampleLocation from '@Src/_basic/icons/SampleLocation';
import SourceIcon from '@Src/_basic/icons/Source';
import StaffIcon from '@Src/_basic/icons/Staff';
import StorageIcon from '@Src/_basic/icons/Storage';
import WeightIcon from '@Src/_basic/icons/Weight';
import { SSSData } from '@Src/_basic/object/MaterialReceiptsType';
import { NameData } from '@Src/_basic/object/Name';
import { StorageData } from '@Src/_basic/object/StorageType';
import { SupplierData } from '@Src/_basic/object/SupplierType';
import { MaterialReceiptsApi } from '@Src/_basic/protocol/materialReceipt/MaterialReceiptsApi';
import { NameApi } from '@Src/_basic/protocol/name/NameApi';
import { StationApi } from '@Src/_basic/protocol/site/StationApi';
import { StorageApi } from '@Src/_basic/protocol/storage/StorageApi';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { useDate } from '@Src/redux/date/dateActions';
import { Box, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  clickedIdRef: any;
  slagOpen: boolean;
  onClose: () => void;
  selectedFactory: number;
}

const MaterialsReceiptInputlItem = ({ Icon, label, children, sx }: any) => {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
      direction="row"
      sx={sx || { p: 1, mt: 1, mb: 1, width: '320px', fontSize: '20px' }}
    >
      {Icon && <Icon />}
      <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none' }}>{`${label}：`}</Typography>
      {children}
    </Stack>
  );
};
function MaterialsReceiptMakeUpOrderContent(props: Props) {
  const { clickedIdRef, selectedFactory, onClose } = props;
  const clickedId = clickedIdRef.current;
  const { t: i18T } = useTranslation();
  const { dateTime } = useDate();

  const [formData, setFormData] = useState<any>({
    createTime: new Date().getTime(),
    plateNumber: '',
    sourceId: 0,
    specificationId: 0,
    supplierId: 0,
    grossWeight: '',
    emptyWeight: '',
    billOfLandingCode: '',
    deliveryTicketCode: '',
    exportValue: '',
    createrId: 0,
    storageId: 0,
    stationId: 0,
    status: '完成',
  });

  const [specifications, setSpecifications] = useState<any[]>([]);
  const [sources, setSources] = useState<any[]>([]);
  const [storages, setStorages] = useState<Options[]>([]);

  const [suppliers, setSuppliers] = useState<Options[]>([]);
  const [creators, setCreators] = useState<Options[]>([]);
  const [stationOptions, setStationOptions] = useState<Options[]>([]);

  useEffect(() => {
    NameApi.getNamesBySearch({ factoryId: selectedFactory, stationId: formData.stationId }, (data: Array<NameData>) => {
      setCreators(data.map(({ id, name }: any) => ({ value: id, text: name })));
    });
  }, [formData.stationId]);
  useEffect(() => {
    MaterialReceiptsApi.getSSSArray(
      {
        factoryId: selectedFactory,
        ...(clickedId && { typeId: clickedId }),
        removed: false,
      },
      (data: SSSData) => {
        SSSSourcesToOptions(data);
      },
      undefined,
      undefined,
    );
    const SSSSourcesToOptions = (data: SSSData) => {
      const sourcesOptions = data.sources.map((source: any) => ({
        value: source.id,
        text: source.sourceName,
      }));

      setSources(sourcesOptions);
    };

    SupplierApi.getSuppliersBySearch(
      {
        factoryId: selectedFactory,
      },
      (data: SupplierData[]) => {
        suppliersDataToOptions(data);
      },
    );
    const suppliersDataToOptions = (data: Array<SupplierData>) => {
      const suppliersOptions = data.map((supplier: SupplierData) => ({ value: supplier.id, text: supplier.name }));
      setSuppliers(suppliersOptions);
    };

    StorageApi.getStoragesBySearch(
      {
        factoryId: selectedFactory,
        typeId: clickedId,
      },
      (data: StorageData[]) => {
        StorageDataToOptions(data);
      },
    );
    const StorageDataToOptions = (data: Array<StorageData>) => {
      const storageOptions = data.map((source: any) => ({
        value: source.id,
        text: source.name,
      }));
      setStorages(storageOptions);
    };
    StationApi.getStationBySearch(
      selectedFactory,
      (data: any) => {
        const stationOptions = data.map((value: any) => {
          return { value: value.id, text: value.stationName + value.stationCode };
        });
        setStationOptions(stationOptions);
      },
      undefined,
      undefined,
    );
  }, [selectedFactory, clickedId]);

  const handleFormDataChange = (field: string) => (value: any) => {
    if (field === 'grossWeight' || field === 'emptyWeight' || field === 'exportValue') {
      if (new RegExp(/^[0-9]*$/).test(value)) {
        //只允許輸入數字
        setFormData({
          ...formData,
          [field]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };
  const handleSourceSelectChange = (sourceId: number) => {
    MaterialReceiptsApi.getSSSArray(
      {
        factoryId: selectedFactory,
        ...(clickedId && { typeId: clickedId }),
        sourceId: sourceId,
        removed: false,
      },
      (data: SSSData) => {
        SSSSpecificationsToOptions(data);
      },
    );
    setFormData({
      ...formData,
      sourceId,
      specificationId: 0,
    });
  };
  const SSSSpecificationsToOptions = (data: SSSData) => {
    const specificationsOptions = data.specifications.map((spec: any) => ({
      value: spec.id,
      text: spec.name,
    }));

    setSpecifications(specificationsOptions);
  };
  const handleStationSelectChange = (stationId: number) => {
    setFormData({
      ...formData,
      stationId,
      createrId: 0,
    });
  };
  const handleSubmit = () => {
    const formNewData = { ...formData, factoryId: selectedFactory, typeId: clickedId };
    MaterialReceiptsApi.postReceiptMaterial(
      formNewData,
      () => {
        onClose();
      },
      undefined,
      undefined,
    );
  };
  const getMonth = () => {
    if (dateTime.getMonth() + 1 < 10) {
      return `0${dateTime.getMonth() + 1}`;
    } else {
      return dateTime.getMonth() + 1;
    }
  };
  const getDate = () => {
    if (dateTime.getDate() < 10) {
      return `0${dateTime.getDate()}`;
    } else {
      return dateTime.getDate();
    }
  };
  const getHours = () => {
    if (dateTime.getHours() < 10) {
      return `0${dateTime.getHours()}`;
    } else {
      return dateTime.getHours();
    }
  };
  const getMinutes = () => {
    if (dateTime.getMinutes() < 10) {
      return `0${dateTime.getMinutes()}`;
    } else {
      return dateTime.getMinutes();
    }
  };

  const handleQueryChange = (field: string) => async (value: unknown) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Box
        sx={{
          minWidth: 680,
          bgcolor: '#003087',
          color: 'white',
          boxShadow: 16,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Stack spacing={1} direction="row">
          <div>
            <YatungPageTitle
              title={``}
              sx={{ flex: '1 0 0', borderBottom: '1px solid #FFFFFF', pb: 1 }}
              textColor="white"
              iconColor="white"
            />
            <Stack spacing={1} direction="row" sx={{ flex: '1 0 0' }}>
              <Box sx={{ width: '55%' }}>
                <MaterialsReceiptInputlItem
                  Icon={ClockIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.CREATE_TIME')}
                  sx={{ p: 1, mt: 1, mb: 1, width: '380px', fontSize: '20px' }}
                >
                  <YatungDateTimePicker
                    value={formData['createTime']}
                    disableFuture={true}
                    onChange={(val) => val && handleQueryChange('createTime')(new Date(val).getTime())}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={CarPlateIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.VEHICLE_NUMBER')}
                >
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('plateNumber')(e.target.value)}
                    value={formData['plateNumber']}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={StaffIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.SUPPLIER')}
                >
                  <YatungSelect
                    options={suppliers}
                    value={formData['supplierId']}
                    onChange={(e) => handleFormDataChange('supplierId')(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={SourceIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.SOURCE')}
                >
                  <YatungSelect
                    options={sources}
                    value={formData['sourceId']}
                    onChange={(e: any) => handleSourceSelectChange(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={ContentMangementIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.SPECIFICATION')}
                >
                  <YatungSelect
                    disabled={specifications.length === 0}
                    options={specifications}
                    value={formData['specificationId']}
                    onChange={(e: any) => handleFormDataChange('specificationId')(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={StorageIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.WARHOUSE')}
                >
                  <YatungSelect
                    options={storages}
                    value={formData['storageId']}
                    onChange={(e) => handleFormDataChange('storageId')(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={WeightIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.GROSS_WEIGHT')}
                >
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('grossWeight')(e.target.value)}
                    value={formData['grossWeight']}
                    position="end"
                    text="KG"
                    type="text"
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={WeightIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.EMPTY_WEIGHT')}
                >
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('emptyWeight')(e.target.value)}
                    value={formData['emptyWeight']}
                    position="end"
                    text="KG"
                    type="text"
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={SampleLocation}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.STATION')}
                >
                  <YatungSelect
                    options={stationOptions}
                    value={formData['stationId']}
                    onChange={(e: any) => handleStationSelectChange(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
              </Box>
              <Box sx={{ width: '45%' }}>
                <MaterialsReceiptInputlItem
                  Icon={MenuIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.DELIVERY_ORDER_QUANTITY')}
                >
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('exportValue')(e.target.value)}
                    value={formData['exportValue']}
                    position="end"
                    text="KG"
                    type="text"
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={WeightIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.DIFFERENCE')}
                >
                  <Typography sx={{ padding: '0 0 0 8px', fontSize: '20px' }}>
                    {`${formData.grossWeight - formData.emptyWeight - formData.exportValue} KG`}
                  </Typography>
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={MenuIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.BILL_OF_LADING_CODE')}
                >
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('billOfLandingCode')(e.target.value)}
                    value={formData['billOfLandingCode']}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={MenuIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.DELIVERY_TICKET_CODE')}
                >
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('deliveryTicketCode')(e.target.value)}
                    value={formData['deliveryTicketCode']}
                  />
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={MenuIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.STATUS')}
                >
                  <Typography sx={{ padding: '0 0 0 8px', fontSize: '20px' }}>{formData['status']}</Typography>
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={WeightIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.NET_WEIGHT')}
                  sx={{ p: 1, mt: 20, mb: 1, width: '320px', fontSize: '20px' }}
                >
                  <Typography sx={{ padding: '0 0 0 8px', fontSize: '20px' }}>
                    {`${formData.grossWeight - formData.emptyWeight} KG`}
                  </Typography>
                </MaterialsReceiptInputlItem>
                <MaterialsReceiptInputlItem
                  Icon={StaffIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.PERSONNEL')}
                >
                  <YatungSelect
                    disabled={formData.stationId === 0}
                    options={creators}
                    value={formData['createrId']}
                    onChange={(e) => handleFormDataChange('createrId')(e.target.value)}
                    sx={{ width: '204px' }}
                  />
                </MaterialsReceiptInputlItem>
              </Box>
            </Stack>
          </div>
          <Stack spacing={1} sx={{ p: 1, pt: 8, pl: 4 }} alignItems="center" justifyContent="space-between">
            <Box sx={{ width: '210px', height: '210px' }}>
              <MaterialsReceiptInputlItem
                Icon={ClockIcon}
                label={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MODAL_DATA.REFILL_TIME')}
              />
              <Typography sx={{ padding: '0 0 0 8px', fontSize: '20px' }}>
                {`${dateTime.getFullYear()} / ${getMonth()} /
                ${getDate()}_${getHours()}：${getMinutes()}`}
              </Typography>
            </Box>
            <Stack alignItems="center" justifyContent="flex-end" flexDirection="row">
              <YatungButton
                text={i18T('GLOBAL.CANCEL')}
                startIcon={<EditIcon />}
                onClick={() => onClose()}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#CB333B',
                  fontSize: '24px',
                  width: 120,
                  mr: 1,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#CB333B',
                  },
                }}
              />
              <YatungButton
                disabled={
                  formData.plateNumber === '' ||
                  formData.specificationId === 0 ||
                  formData.sourceId === 0 ||
                  formData.supplierId === 0 ||
                  formData.storageId === 0 ||
                  formData.stationId === 0 ||
                  formData.createrId === 0 ||
                  formData.status === 0 ||
                  !formData.grossWeight ||
                  !formData.emptyWeight
                }
                text={i18T('GLOBAL.FINISH')}
                startIcon={<EditIcon />}
                onClick={handleSubmit}
                sx={{
                  border: '1px solid #fff',
                  color: '#ffffff',
                  bgcolor: '#002F8C',
                  fontSize: '24px',
                  width: 120,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#002F8C',
                  },
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
export default function MaterialsReceiptMakeUpOrderModal({ modalOpen = false, ...props }: any) {
  return (
    <Modal open={modalOpen}>
      <>
        <MaterialsReceiptMakeUpOrderContent {...props} />
      </>
    </Modal>
  );
}
