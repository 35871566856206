import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { useSignature } from '@Src/redux/signature/signatureActions';
import { Paper } from '@mui/material';
import React, { useEffect } from 'react';
import CreateSignatureFrom from './components/CreateSignatureFrom';
import UpdateSignatureFrom from './components/UpdateSignatureFrom';

export default function Signature() {
  const { projectInfo } = useProductionHistory();
  const { getSignaturesBySearch, getBomHierarchy } = useSignature();

  useEffect(() => {
    const params = { factoryId: projectInfo.factoryId };
    getSignaturesBySearch(params);
    getBomHierarchy(params);
  }, []);

  return (
    <Paper elevation={4} sx={{ position: 'relative', height: '100%' }}>
      {projectInfo.signature ? <UpdateSignatureFrom /> : <CreateSignatureFrom />}
    </Paper>
  );
}
