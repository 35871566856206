import { JWTData, LoginRequire } from '@Basic/object/AuthType';
import { useAuth } from '@Redux/auth/authActions';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import { AuthSysBaseResponse } from '@Src/_basic/object/ApiType';
import { AuthApi } from '@Src/_basic/protocol/auth/AuthApi';
import React, { memo, useCallback, useState } from 'react';
import * as Yup from 'yup';
import LoginFormCard from './LoginFormCard';

const initialValues: LoginRequire = {
  userName: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  userName: Yup.string().min(1).required('require userName'),
  password: Yup.string().min(1).required('require password'),
});

function LoginForm() {
  const { setToken } = useAuth();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const onGetJWTSuccess = useCallback(
    (data: JWTData) => {
      setToken(data.token);
    },
    [setToken],
  );

  const onGetJWTFaild = useCallback((res: AuthSysBaseResponse<null>) => {
    setIsShowAlert(true);
    setAlertText(typeof res?.message === 'string' ? res?.message : `Get JWT Error ! ${res?.message}`);
  }, []);

  const onLoginFaild = useCallback((res: AuthSysBaseResponse<null>) => {
    setIsShowAlert(true);
    setAlertText(typeof res?.message === 'string' ? res?.message : `Login Error ! ${res?.message}`);
  }, []);

  const onLoginSuccess = useCallback(() => {
    AuthApi.getJWT(onGetJWTSuccess, onGetJWTFaild);
  }, [onGetJWTSuccess, onGetJWTFaild]);

  const handleLogin = useCallback(
    (params: LoginRequire) => {
      console.log('try login : ', params.userName, params.password);
      AuthApi.login(params, onLoginSuccess, onLoginFaild);
    },
    [onLoginSuccess, onLoginFaild],
  );

  return (
    <>
      <LoginFormCard initialValues={initialValues} validationSchema={validationSchema} handleLogin={handleLogin} />
      <YatungConfirmAlert isShowAlert={isShowAlert} onClose={() => setIsShowAlert(false)} alertText={alertText} />
    </>
  );
}

export default memo(LoginForm);
