import React from 'react';
import ReportGroupManagementUpdateCard from '../components/ReportGroupManagementUpdateCard';

export default function ReportGroupManagementUpdateCardScreen() {
  return (
    <>
      <ReportGroupManagementUpdateCard />
    </>
  );
}
