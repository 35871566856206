import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import PelletsScreen from './screens/PelletsScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <PelletsScreen />,
  },
];

const PelletsRouter = () => PermissionRoute(routesConfig);

export default memo(PelletsRouter);
