import { Response } from '@Src/_basic/object/ApiType';
import { StorageData, SearchStoragesParams } from '@Src/_basic/object/StorageType';
import { Api } from '../Api';
import { StorageProtocol } from './StorageProtocol';

export class StorageApi {
  // 取得所有人員
  public static getAllStorages(
    onSuccess?: (data: Array<StorageData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(StorageProtocol.ALL_STORAGES, undefined, onSuccess, onFailed, onFinally);
  }

  public static getStoragesBySearch(
    params: SearchStoragesParams,
    onSuccess?: (data: Array<StorageData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(StorageProtocol.ALL_STORAGES, config, onSuccess, onFailed, onFinally);
  }
}
