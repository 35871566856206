import { useEffect, useState } from 'react';
import { Options } from '../components/YatungSelect';
import { OrganizationApi } from '../protocol/accountRights/organization/OrganizationApi';

export default function useGetAllOrganize() {
  const [organizeOption, setOrganizeOption] = useState<Array<Options>>([]);

  useEffect(() => {
    OrganizationApi.getAllOrganization((_data) => {
      setOrganizeOption(
        _data.reduce(
          (prev, { id, organiztionName }) => [
            ...prev,
            {
              value: id,
              text: organiztionName,
            },
          ],
          [
            {
              value: 0,
              text: '全部組織',
            },
          ] as Array<Options>,
        ),
      );
    });
  }, []);

  return {
    organizeOption,
  } as const;
}
