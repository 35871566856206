import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function MonthlyReport(props: SvgIconProps) {
  return (
    <svg width={18} height={24} viewBox="0 0 18 24" fill="none" {...props}>
      <rect x={1} y={1} width={16} height="21.3333" rx={2} strokeWidth="1.5" />
      <rect x="3.03442" y="4.13257" width="11.5901" height="2.95946" strokeWidth="0.5" />
      <rect x="2.8793" y="8.20767" width="5.15916" height="0.192192" fill="white" strokeWidth="0.192192" />
      <rect x="2.8793" y="9.55264" width="5.15916" height="0.192192" fill="white" strokeWidth="0.192192" />
      <rect x="2.8793" y="10.8981" width="5.15916" height="0.192192" fill="white" strokeWidth="0.192192" />
      <rect x="2.8793" y="12.2428" width="5.15916" height="0.192192" fill="white" strokeWidth="0.192192" />
      <rect x="2.92857" y="13.4444" width="0.306306" height="0.288288" fill="white" strokeWidth="0.288288" />
      <rect x="4.51451" y="13.4444" width="0.306306" height="0.288288" fill="white" strokeWidth="0.288288" />
      <rect x="6.09971" y="13.4444" width="0.306306" height="0.288288" fill="white" strokeWidth="0.288288" />
      <rect x="7.68565" y="13.4444" width="0.306306" height="0.288288" fill="white" strokeWidth="0.288288" />
      <rect x="9.9707" y="11.8198" width="0.689189" height="1.80631" strokeWidth="0.5" />
      <rect x="11.9524" y="10.6677" width="0.689189" height="2.95946" strokeWidth="0.5" />
      <rect x="13.9343" y="8.74487" width="0.689189" height="4.88138" strokeWidth="0.5" />
      <mask id="path-14-inside-1_1172_8657" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.78438 15.2224V21.1799V21.1804H4.36997V21.1799L14.8745 21.1799V15.222L2.78438 15.222L2.78438 15.2224Z"
        />
      </mask>
      <path
        d="M2.78442 15.2224H3.28442L3.28442 15.222L2.78442 15.2224ZM2.78442 21.1803H2.28442V21.6803H2.78442V21.1803ZM4.37001 21.1803V21.6803H4.87001V21.1803H4.37001ZM4.37001 21.1799V20.6799H3.87001V21.1799H4.37001ZM14.8745 21.1799V21.6799H15.3745V21.1799H14.8745ZM14.8745 15.2219H15.3745V14.7219H14.8745V15.2219ZM2.78442 15.2219L2.78442 14.7219L2.28405 14.7219L2.28442 15.2223L2.78442 15.2219ZM3.28442 21.1799V15.2224H2.28442V21.1799H3.28442ZM3.28442 21.1803V21.1799H2.28442V21.1803H3.28442ZM4.37001 20.6803H2.78442V21.6803H4.37001V20.6803ZM3.87001 21.1799V21.1803H4.87001V21.1799H3.87001ZM14.8745 20.6799L4.37001 20.6799V21.6799L14.8745 21.6799V20.6799ZM14.3745 15.2219V21.1799H15.3745V15.2219H14.3745ZM2.78442 15.7219L14.8745 15.7219V14.7219L2.78442 14.7219L2.78442 15.7219ZM3.28442 15.222L3.28442 15.2216L2.28442 15.2223L2.28442 15.2227L3.28442 15.222Z"
        fill="#002F8C"
        mask="url(#path-14-inside-1_1172_8657)"
      />
      <rect x="3.75482" y="16.4623" width="10.4905" height="0.176149" fill="white" strokeWidth="0.176149" />
      <rect x="3.75482" y="17.1669" width="10.4905" height="0.176149" fill="white" strokeWidth="0.176149" />
      <rect x="3.75482" y="17.8723" width="10.4905" height="0.176149" fill="white" strokeWidth="0.176149" />
      <rect x="3.79886" y="18.9737" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="5.53934" y="18.9737" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="7.06375" y="18.9737" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="8.80643" y="18.9737" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="10.5462" y="18.9737" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="12.0703" y="18.9737" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="13.8123" y="18.9737" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="3.79886" y="20.0296" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="5.53934" y="20.0296" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="7.06375" y="20.0296" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="8.80643" y="20.0296" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="10.5462" y="20.0296" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="12.0703" y="20.0296" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <rect x="13.8123" y="20.0296" width="0.388836" height="0.264223" fill="white" strokeWidth="0.264223" />
      <path
        d="M5.29377 2.43939H1.50264C1.53934 1.85862 1.95188 1.5 2.33333 1.5H5.14595C5.08862 1.61578 5.05859 1.7431 5.05859 1.8653C5.05859 2.06789 5.14114 2.28458 5.29377 2.43939ZM16.4974 2.43939H12.3645C12.5171 2.28458 12.5997 2.06789 12.5997 1.8653C12.5997 1.7431 12.5696 1.61578 12.5123 1.5H15.6667C16.0481 1.5 16.4607 1.85862 16.4974 2.43939Z"
        fill="white"
      />
      <rect x={1} y={1} width={16} height="21.3333" rx={2} strokeWidth="0.2" />
    </svg>
  );
}
