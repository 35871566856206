export function getSpecificationExtraDataFieldNameByExperimentResultTypeId(ExperimentResultTypeId: number) {
  const specificationIds: Array<number> = [];
  const sourceIds: Array<number> = [
    // 砂
    1,
    // 石
    9, 12,
    // 飛灰
    22, 23,
  ];

  if (specificationIds.includes(ExperimentResultTypeId)) {
    return 'specification';
  } else if (sourceIds.includes(ExperimentResultTypeId)) {
    return 'source';
  } else {
    return 'default';
  }
}

export function getTypeAmountByExperimentItemId(experimentItemId: number) {
  switch (experimentItemId) {
    case 3:
      return 2;
    default:
      return 1;
  }
}
