import { Response } from '@Src/_basic/object/ApiType';
import {
  CreateExptScheduleRequest,
  ExptScheduleData,
  PageableExptScheduleData,
  SearchPageableExptSchedulesParams,
  UpdateExptScheduleRequest,
} from '@Src/_basic/object/ExptScheduleType';
import { Api } from '../Api';
import { ExptScheduleProtocol } from './ExptScheduleProtocol';

export class ExptScheduleApi {
  public static getExptScheduleById(
    id: number,
    onSuccess?: (data: ExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(ExptScheduleProtocol.EXPT_SCHEDULE_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static getPageableExptSchedulesBySearch(
    params: SearchPageableExptSchedulesParams,
    onSuccess?: (data: PageableExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(ExptScheduleProtocol.PAGEABLE_EXPT_SCHEDULE_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getCancelablePageableExptSchedulesBySearch(
    params: SearchPageableExptSchedulesParams,
    onSuccess?: (data: PageableExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };

    Api.get(ExptScheduleProtocol.PAGEABLE_EXPT_SCHEDULE_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static createExptSchedule(
    request: CreateExptScheduleRequest,
    onSuccess?: (data: ExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ExptScheduleProtocol.EXPT_SCHEDULE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static updateExptSchedule(
    request: UpdateExptScheduleRequest,
    onSuccess?: (data: ExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(ExptScheduleProtocol.EXPT_SCHEDULE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static deleteExptSchedule(
    id: number,
    onSuccess?: (data: ExptScheduleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(ExptScheduleProtocol.EXPT_SCHEDULE_BY_ID, config, onSuccess, onFailed, onFinally);
  }
}
