import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function LangUpload(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
        <path
          d="M3.4987 22.1667C2.76537 22.1667 2.13737 21.9053 1.6147 21.3827C1.09203 20.86 0.831145 20.2325 0.832034 19.5V15.5H3.4987V19.5H19.4987V15.5H22.1654V19.5C22.1654 20.2333 21.904 20.8613 21.3814 21.384C20.8587 21.9067 20.2311 22.1676 19.4987 22.1667H3.4987ZM10.1654 16.8333V5.96668L6.6987 9.43334L4.83203 7.50001L11.4987 0.833344L18.1654 7.50001L16.2987 9.43334L12.832 5.96668V16.8333H10.1654Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
