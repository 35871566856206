import { Response } from '@Src/_basic/object/ApiType';
import {
  CreateStationRequest,
  GetStationByIdParams,
  PostStationData,
  SearchRequest,
  SiteManagementDataParams,
  SiteManagementDataRequest,
  SiteManagementDataSettingsRequire,
  StationNewData,
  StationOption,
  StationsData,
  StationsDataById,
} from '@Src/_basic/object/SiteType';
import axios from 'axios';
import { Api } from '../Api';
import { StationProtocol } from './StationProtocol';

export class StationApi {
  public static getAllStation(
    onSuccess?: (data: Array<StationNewData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(StationProtocol.ALL_STATION, undefined, onSuccess, onFailed, onFinally);
  }
  public static getStationBySearch(
    id: number,
    onSuccess?: (data: Array<StationNewData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        factoryId: id,
      },
    };
    Api.get(StationProtocol.ALL_STATION, config, onSuccess, onFailed, onFinally);
  }
  public static getStationFactory(
    onSuccess?: (data: Array<number>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(StationProtocol.GET_STATION_FACTORY, undefined, onSuccess, onFailed, onFinally);
  }

  public static getPaginationStation(
    params: SiteManagementDataParams,
    onSuccess?: (data: SiteManagementDataRequest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(StationProtocol.GET_STATION_PAGINATION, config, onSuccess, onFailed, onFinally);
  }

  public static getAllStationByFactoryId(
    factoryId: number,
    onSuccess?: (data: Array<StationNewData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        factoryId,
      },
    };
    Api.get(StationProtocol.ALL_STATION, config, onSuccess, onFailed, onFinally);
  }

  public static getStationAccountByFactoryId(
    params: GetStationByIdParams,
    onSuccess?: (data: Array<StationsDataById>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(StationProtocol.GET_STATION_ACCOUNT_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static GetStaionTypeOption(
    onSuccess?: (data: StationOption[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(StationProtocol.GET_STATION_OPTION, undefined, onSuccess, onFailed, onFinally);
  }
  public static DeleteStationById(
    id: number,
    onSuccess?: (data: Array<StationNewData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(StationProtocol.DELETE_STATION, config, onSuccess, onFailed, onFinally);
  }

  public static CreateStation(require: CreateStationRequest) {
    return axios.post(StationProtocol.CREATE_STATION, require).then((res) => {
      return res.data;
    });
  }

  public static UpdateStation(require: CreateStationRequest) {
    return axios.put(StationProtocol.UPDATE_STATION, require).then((res) => {
      return res.data;
    });
  }

  public static PostStation(
    request: PostStationData,
    onSuccess?: (data: StationsData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(StationProtocol.GET_STATION_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }
  public static PutStation(
    request: StationsDataById[],
    onSuccess?: (data: StationsDataById[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(StationProtocol.GET_STATION_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static GetAllStationMock(
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(StationProtocol.SITE_MANAGEMENT_DATA, undefined, onSuccess, onFailed, onFinally);
  }
  public static siteManagementCreateData(require: SiteManagementDataSettingsRequire) {
    return axios.post(StationProtocol.SITE_MANAGEMENT_CREATE_DATA, require).then((res) => {
      return res.data;
    });
  }
  public static siteManagementUpdateData(require: SiteManagementDataSettingsRequire) {
    return axios.put(StationProtocol.SITE_MANAGEMENT_UPDATE_DATA, require).then((res) => {
      return res.data;
    });
  }
  public static siteManagementSearchData(require: SearchRequest) {
    return axios.post(StationProtocol.SITE_MANAGEMENT_SEARCH_DATA, require).then((res) => {
      return res.data;
    });
  }
  public static GetStationByIdMock(
    id: number,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(StationProtocol.SITE_MANAGEMENT_GET_ID, config, onSuccess, onFailed, onFinally);
  }
}
