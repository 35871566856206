import { GetQrcodeDataRequest } from './QrcodeType';
import {
  ChemAdmxActivity,
  ChemAdmxActivityVerifyCustomResults,
  ChemAdmxActivityVerifyPassedResults,
} from './test/cementtitiousLiquid/ChemAdmxActivityType';
import {
  ChemAdmxPenta,
  ChemAdmxPentaVerifyCustomResults,
  ChemAdmxPentaVerifyPassedResults,
} from './test/cementtitiousLiquid/ChemAdmxPentaType';
import { ChemicalChloride } from './test/cementtitiousLiquid/ChemicalChlorideType';
import { ChemicalSolidContent } from './test/cementtitiousLiquid/ChemicalSolidContentType';
import { ChemicalSpecificGravity } from './test/cementtitiousLiquid/ChemicalSpecificGravityType';
import { MixingChloride, MixingChlorideVerifyPassedResults } from './test/cementtitiousLiquid/MixingChlorideType';
import { RecycleChloride } from './test/cementtitiousLiquid/RecycleChlorideType';
import { RecycleSolidContent } from './test/cementtitiousLiquid/RecycleSolidContentType';
import { RecycleSpecificGravity } from './test/cementtitiousLiquid/RecycleSpecificGravityType';
import { CementCompStrTest } from './test/cementtitiousPowder/CementCompStrType';
import {
  FlyAshActivity,
  FlyAshActivityVerifyCustomResults,
  FlyAshActivityVerifyPassedResults,
} from './test/cementtitiousPowder/FlyAshActivityType';
import {
  FlyAshLValue,
  FlyAshLValueVerifyCustomResults,
  FlyAshLValueVerifyPassedResults,
} from './test/cementtitiousPowder/FlyAshLValueType';
import {
  FlyAshLossOnIgnition,
  FlyAshLossOnIgnitionVerifyCustomResults,
  FlyAshLossOnIgnitionVerifyPassedResults,
} from './test/cementtitiousPowder/FlyAshLossOnIgnitionType';
import { FlyAshSlump } from './test/cementtitiousPowder/FlyAshSlumpType';
import {
  FlyAshWaterDemand,
  FlyAshWaterDemandVerifyCustomResults,
  FlyAshWaterDemandVerifyPassedResults,
} from './test/cementtitiousPowder/FlyAshWaterDemandType';
import {
  SlagActivity,
  SlagActivityVerifyCustomResults,
  SlagActivityVerifyPassedResults,
} from './test/cementtitiousPowder/SlagActivityType';
import { SlagWaterDemand } from './test/cementtitiousPowder/SlagWaterDemandType';
import { SpecificSurface } from './test/cementtitiousPowder/SpecificSurfaceType';
import { ConcreteStrVerifyCustomResults, ConcreteStrVerifyPassedResults } from './test/concrete/ConcreteApiType';
import {
  ConcreteSlumpFlow,
  ConcrtetSlumpFlowVerifyCustomResults,
  ConcrtetSlumpFlowVerifyPassedResults,
} from './test/concrete/ConcreteSlumpFlowType';
import {
  ConcreteSlump,
  ConcreteSlumpVerifyCustomResults,
  ConcreteSlumpVerifyPassedResults,
} from './test/concrete/ConcreteSlumpType';
import { ConcreteStrength } from './test/concrete/ConcreteStrengthType';
import { UTest, UTestVerifyCustomResults, UTestVerifyPassedResults } from './test/concrete/UTestType';
import { VTest, VTestVerifyCustomResults, VTestVerifyPassedResults } from './test/concrete/VTestType';
import { Ams, AmsVerifyCustomResults, AmsVerifyPassedResults } from './test/sandRock/AmsType';
import { AutoclaveTest, AutoclaveVerifyPassedResults } from './test/sandRock/AutoclaveTestType';
import { Chloride, ChlorideVerifyCustomResults, ChlorideVerifyPassedResults } from './test/sandRock/ChlorideType';
import {
  CoarseAbrasive,
  CoarseAbrasiveVerifyCustomResults,
  CoarseAbrasiveVerifyPassedResults,
} from './test/sandRock/CoarseAbrasiveType';
import { CoarseAggOblateness } from './test/sandRock/CoarseAggOblatenessType';
import { CoarseAggSgWabs } from './test/sandRock/CoarseAggSgWabsType';
import {
  CoarseSieve,
  CoarseSieveVerifyCustomResults,
  CoarseSieveVerifyPassedResults,
} from './test/sandRock/CoarseSieveType';
import {
  FineAggSgWabs,
  FineAggSgWabsVerifyPassedResults,
  FineAggSgWebVerifyCustomResults,
} from './test/sandRock/FineAggSgWabsType';
import { FineSieve, FineSieveVerifyCustomResults, FineSieveVerifyPassedResults } from './test/sandRock/FineSieveType';
import {
  HighTempCooking,
  HighTempCookingVerifyCustomResults,
  HighTempCookingVerifyPassedResults,
} from './test/sandRock/HighTempCookingType';
import { MoistureContent } from './test/sandRock/MoistureContentType';
import { Rmbt } from './test/sandRock/RmbtType';
import { Xrf, XrfVerifyPassedResults } from './test/sandRock/XrfType';

export interface PutBaseTestRequest {
  id: number;
  operator?: string;
  memo?: string;
  deadline?: number;
  completed?: boolean;
  reportComment?: string;
  reportResponse?: string;
  exptUnitId?: number;
  abnormal?: boolean;

  sampleId?: string;
  materialReceiptId?: string;
  samplingTime?: number;
  plateNumber?: string;
  exptResultId?: number;
}

export interface PutTestRequest<T> {
  exptItemCode: TestItemEnum;
  fields?: T;
}

export interface MixingRecycleHeader {
  samplingTime: number;
  specificationName: string;
  sourceName: string;
  storageName: string;
  supplierName: string;
  staffName: string;
  sampleSourceName: string;
  testFactory?: string;
  factoryToTest?: string;
  PotionAttribute?: string;
}

export interface TestData {
  exptItemName: string;
  exptItemCode: TestItemEnum;
  exptId: number;
  operator: string;
  startTime: null;
  endTime: null;
  completed: boolean;
  abnormal: boolean;
  transferred: boolean;
  reqItemId: null;
}

export interface TestMaterialData {
  id: number;
  name: string;
  code: string;
  removed: boolean;
}

export interface AssignTestData {
  experimentItemId: number;
  experimentItemCode?: string;
  operatorId?: string;
}

export interface RecordSearchParams {
  pageNum: number;
  factoryId?: number;
  startBetween?: number;
  endBetween?: number;
  exptUnitId?: number;
  exptTypeId?: number;
  specificationId?: number;
  exptItemCode?: string;
}

export interface CreateTestRequest {
  createrId: string;
  memo?: string;
  materialReceiptId?: string;
  sampleId?: string;
  storageId?: number;
  specificationId?: number;
  specification2Id?: number;
  specimenId?: string;
  exptTypeId?: number;
  areaId?: number;
  factoryId?: number;
  requestItems?: Array<AssignTestData>;
}

export interface GetExptStatusRequest {
  createType?: number;
  creator?: string;
  operator?: string;
  pageNum: number;
  id?: number;
  exptItemCode?: TestItemEnum;
  code?: string;
  materialReceiptId?: string;
  sampleId?: string;
  reqItemId?: number;
  specificationId?: number;
  factoryId?: number;
  exptUnitId?: number;
  transferred?: boolean;
  abnormal?: boolean;
  completed?: boolean;
  removed?: boolean;
  completeTime?: number;
  timeZone?: string;
  startBetween?: number;
  endBetween?: number;
  exptTypeId?: number;
  startCompleteTime?: number;
  endCompleteTime?: number;
}

export interface GetRecordRequest {
  pageNum: number;
  id?: number;
  exptItemCode?: TestItemEnum;
  code?: string;
  operator?: string;
  materialReceiptId?: string;
  sampleId?: string;
  reqItemId?: number;
  specificationId?: number;
  factoryId?: number;
  exptUnitId?: number;
  transferred?: boolean;
  abnormal?: boolean;
  completed?: boolean;
  removed?: boolean;
  completeTime?: number;
  timeZone?: string;
  startBetween?: number;
  endBetween?: number;
  exptTypeId?: number;
  startCompleteTime?: number;
  endCompleteTime?: number;
}

export interface RecordSearch {
  maxPage: number;
  data: Array<Record>;
  last: boolean;
}

export interface Record extends BaseTest, GetQrcodeDataRequest {
  specificationName: string | undefined;
  sourceId: number | undefined;
  sourceName: string | undefined;
  sampleSourceId: number | undefined;
  sampleSourceName: string | undefined;
  samplerId: number | undefined;
  samplerName: string | undefined;
  storageId: number | undefined;
  storageName: string | undefined;
  supplierId: number | undefined;
  supplierName: string | undefined;
  samplingTime?: number;
  specificationId: number;
  staffId: string;
  staffName: string;
  reqItemTitle: string | undefined;

  createTypeName?: string;
  areaName?: string;
  createTime: number;
  creater: string;
  exptId: number;
  exptItemCode: TestItemEnum;
  exptTypeName: string;
  exptUnitName: string;
  operator: string;
  factoryName: string;
  status: string;

  testFactory?: string;
  factoryToTest?: string;
  PotionAttribute?: string;
  plateNumber: string | undefined;
}

export interface BaseTest {
  id: number;
  removed: boolean;
  operator: string;
  operatorName: string;
  lastOperator: string;
  completeTime: number | null;
  createTime: number;
  lastUpdateTime: number;
  reqItemId: number;
  transferred: boolean;
  memo: string | undefined;
  completed: boolean;
  abnormal: boolean;
  factoryId: number;
  specificationId: number;
  specification2Id: number | undefined;
  reportComment: string | undefined;
  reportResponse: string | undefined;
  exptUnitId: number | undefined;
  exptUnitName: string | undefined;
  specificationName: string | undefined;
  sourceName: string | undefined;
  sourceId: number | undefined;
  supplierName: string | undefined;
  supplierId: number | undefined;
  storageId: number | undefined;
  storageName: string | undefined;
  sampleSourceId: number | undefined;

  code: string;
  exptItemCode: TestItemEnum;
  holderName: string | undefined;
  specimenId?: string;
}

export interface CommonTest extends BaseTest {
  materialReceiptId: string;
  sampleId: string;
  startTime: number | null;
  endTime: number | null;
  verifyPassedResults: AllVerifyPassedResults;
  verifyCustomResults: AllVerifyCustomResults;
}

export interface ConcreteTest {
  samplingTime?: number;
  civilEngrName?: string;
  placement?: string;
  civilEngrAddr?: string;
  weather?: string;
  temp?: number;
  windSpeed?: number;
  humidity?: number;
  propName?: string;
  plateNumber?: string;
  concreteType?: string;
  designStr?: number;
  actualSlumpFlow?: number;
  mixerNumber?: string;
  mixerName?: string;
  memo: string | undefined;

  verifyPassedResults: AllVerifyPassedResults;
  verifyCustomResults: AllVerifyCustomResults;
}

export interface CustomResultItem {
  passed: boolean;
  rejected: boolean;
}

export interface GetTestDetailRequest {
  exptItemCode: TestItemEnum;
  exptId: number;
}

export interface AllTest
  extends FineSieve,
    Chloride,
    ConcreteSlump,
    ConcreteSlumpFlow,
    ConcreteStrength,
    VTest,
    UTest,
    Rmbt,
    HighTempCooking,
    FineAggSgWabs,
    MoistureContent,
    AutoclaveTest,
    Ams,
    Xrf,
    SlagWaterDemand,
    FlyAshWaterDemand,
    FlyAshLValue,
    FlyAshLossOnIgnition,
    CoarseAggOblateness,
    CoarseAggSgWabs,
    CoarseAbrasive,
    SpecificSurface,
    CementCompStrTest,
    FlyAshActivity,
    ChemicalSolidContent,
    ChemicalSpecificGravity,
    ChemicalChloride,
    SlagActivity,
    ChemAdmxActivity,
    ChemAdmxPenta,
    MixingChloride,
    RecycleChloride,
    RecycleSolidContent,
    RecycleSpecificGravity,
    FlyAshSlump,
    CoarseSieve {}

export interface AllVerifyPassedResults
  extends HighTempCookingVerifyPassedResults,
    XrfVerifyPassedResults,
    FineSieveVerifyPassedResults,
    CoarseSieveVerifyPassedResults,
    CoarseAbrasiveVerifyPassedResults,
    FineAggSgWabsVerifyPassedResults,
    AmsVerifyPassedResults,
    AutoclaveVerifyPassedResults,
    MixingChlorideVerifyPassedResults,
    ConcreteSlumpVerifyPassedResults,
    ConcrtetSlumpFlowVerifyPassedResults,
    UTestVerifyPassedResults,
    VTestVerifyPassedResults,
    ChlorideVerifyPassedResults,
    SlagActivityVerifyPassedResults,
    FlyAshActivityVerifyPassedResults,
    FlyAshWaterDemandVerifyPassedResults,
    FlyAshLValueVerifyPassedResults,
    FlyAshLossOnIgnitionVerifyPassedResults,
    ChemAdmxActivityVerifyPassedResults,
    ChemAdmxPentaVerifyPassedResults,
    ConcreteStrVerifyPassedResults {}

export interface AllVerifyCustomResults
  extends FineSieveVerifyCustomResults,
    FineAggSgWebVerifyCustomResults,
    AmsVerifyCustomResults,
    HighTempCookingVerifyCustomResults,
    ChlorideVerifyCustomResults,
    CoarseSieveVerifyCustomResults,
    CoarseAbrasiveVerifyCustomResults,
    SlagActivityVerifyCustomResults,
    FlyAshActivityVerifyCustomResults,
    FlyAshWaterDemandVerifyCustomResults,
    FlyAshLValueVerifyCustomResults,
    FlyAshLossOnIgnitionVerifyCustomResults,
    ChemAdmxActivityVerifyCustomResults,
    ChemAdmxPentaVerifyCustomResults,
    ConcreteSlumpVerifyCustomResults,
    UTestVerifyCustomResults,
    VTestVerifyCustomResults,
    ConcrtetSlumpFlowVerifyCustomResults,
    ConcreteStrVerifyCustomResults {}

export enum TestItemEnum {
  // 粒料試驗
  FINE_AGG_SIEVE_ANALYSIS = 'FINE_AGG_SIEVE_ANALYSIS', // "砂_細粒料篩分析"
  AMS_ANALYSIS = 'AMS_ANALYSIS', // "砂_AMS爐碴檢測"
  XRF_ANALYSIS = 'XRF_ANALYSIS', // "砂_XRF成分分析"
  HIGH_TEMP_COOKING_ANALYSIS = 'HIGH_TEMP_COOKING_ANALYSIS', // "砂_高溫蒸煮試驗"
  AUTOCLAVE_TEST = 'AUTOCLAVE_TEST', // "砂_熱壓膨脹試驗"
  FINE_AGG_SG_WABS_ANALYSIS = 'FINE_AGG_SG_WABS_ANALYSIS', // "砂_細粒料比重吸水率"
  RMBT = 'RMBT', // "砂_亞甲基藍試驗"
  CHLORIDE_ION_CONTENT_TEST = 'CHLORIDE_ION_CONTENT_TEST', // "砂_氯離子含量試驗"
  SAND_MOISTURE_CONTENT_TEST = 'SAND_MOISTURE_CONTENT_TEST', // "砂_總含水率試驗"

  COARSE_AGG_SIEVE_ANALYSIS = 'COARSE_AGG_SIEVE_ANALYSIS', // "石_粗粒料篩分析"
  COARSE_AGG_SG_WABS_ANALYSIS = 'COARSE_AGG_SG_WABS_ANALYSIS', // "石_粗粒料比重吸水率"
  LOS_ANGELES_ABRASION_TEST = 'LOS_ANGELES_ABRASION_TEST', // "石_洛杉磯試驗"
  STONE_MOISTURE_CONTENT_TEST = 'STONE_MOISTURE_CONTENT_TEST', // "石_總含水率試驗"
  OBLATENESS_TEST = 'OBLATENESS_TEST', // "石_扁平率試驗"

  // 膠結材 (粉料)
  SLAG_SPECIFIC_SURFACE = 'SLAG_SPECIFIC_SURFACE', // "爐石_比表面積"

  SLAG_WATER_DEMAND_TEST = 'SLAG_WATER_DEMAND_TEST', // 爐石_需水量試驗
  SLAG_ACTIVITY_TEST = 'SLAG_ACTIVITY_TEST', // 爐石_活性試驗

  CEMENT_SPECIFIC_SURFACE = 'CEMENT_SPECIFIC_SURFACE', // 水泥_比表面積
  CEMENT_COMP_STR_TEST = 'CEMENT_COMP_STR_TEST', // 水泥_抗壓試驗

  FLY_ASH_SPECIFIC_SURFACE_TEST = 'FLY_ASH_SPECIFIC_SURFACE_TEST', // 飛灰_比表面積試驗
  FLY_ASH_ACTIVITY_TEST = 'FLY_ASH_ACTIVITY_TEST', // 飛灰_活性試驗
  FLY_ASH_WATER_DEMAND_TEST = 'FLY_ASH_WATER_DEMAND_TEST', // 飛灰_需水量試驗
  FLY_ASH_L_VALUE_TEST = 'FLY_ASH_L_VALUE_TEST', // 飛灰_L值試驗
  FLY_ASH_LOSS_ON_IGNITION_TEST = 'FLY_ASH_LOSS_ON_IGNITION_TEST', // 飛灰_燒失量試驗
  FLY_ASH_SLUMP_TEST = 'FLY_ASH_SLUMP_TEST', // 飛灰_淨漿流度試驗

  // 膠結材 (液料)
  TEMP_CODE = 'TEMP_CODE', // 化學摻劑_減水率試驗
  CHEM_ADMX_SOLID_CONTENT = 'CHEM_ADMX_SOLID_CONTENT', // 藥劑_固含量試驗
  CHEM_ADMX_SPECIFIC_WEIGHT = 'CHEM_ADMX_SPECIFIC_WEIGHT', // 藥劑_比重試驗
  CHEM_ADMX_CHL_CONTENT = 'CHEM_ADMX_CHL_CONTENT', // 藥劑_氯離子試驗
  CHEM_ADMX_PENTA_TEST = 'CHEM_ADMX_PENTA_TEST', // 藥劑_減水率試驗
  CHEM_ADMX_MULTI_TEST = 'CHEM_ADMX_MULTI_TEST', // 藥劑_六項試驗
  CHEM_ADMX_COMP_STR = 'CHEM_ADMX_COMP_STR', // 藥劑_抗壓試驗
  MIXING_WATER_CHL_CONTENT = 'MIXING_WATER_CHL_CONTENT', // 拌合水_氯離子試驗
  RECLAIMED_WATER_CHL_CONTENT = 'RECLAIMED_WATER_CHL_CONTENT', // 回收水_氯離子試驗
  RECLAIMED_WATER_SPECIFIC_WEIGHT = 'RECLAIMED_WATER_SPECIFIC_WEIGHT', // 回收水_比重試驗
  RECLAIMED_WATER_SOLID_CONTENT = 'RECLAIMED_WATER_SOLID_CONTENT', // 回收水_固含量試驗

  // 混擬土
  CONCRETE_SLUMP_TEST = 'CONCRETE_SLUMP_TEST', // 混凝土_坍度試驗
  CONCRETE_SLUMP_FLOW_TEST = 'CONCRETE_SLUMP_FLOW_TEST', // 混凝土_坍流度試驗
  CONCRETE_COMP_STR_TEST = 'CONCRETE_COMP_STR_TEST', // 混凝土_抗壓強度試驗
  CONCRETE_V_FUNNEL_TEST = 'CONCRETE_V_FUNNEL_TEST', // 混凝土_V型試驗
  CONCRETE_U_BOX_TEST = 'CONCRETE_U_BOX_TEST', // 混凝土_V型試驗
}

export enum ExperimentResultType {
  // 粒料試驗
  FINE_AGG_SIEVE_ANALYSIS = 1, // "砂_細粒料篩分析"
  FINE_AGG_MIX_SIEVE_ANALYSIS = 2, // "砂_細粒料混合篩分析"
  AMS_ANALYSIS = 3, // "砂_AMS爐碴檢測"
  XRF_ANALYSIS = 4, // "砂_XRF成分分析"
  HIGH_TEMP_COOKING_ANALYSIS = 5, // "砂_高溫蒸煮試驗"
  AUTOCLAVE_TEST = 6, // "砂_熱壓膨脹試驗"
  FINE_AGG_SG_WABS_ANALYSIS = 7, // "砂_細粒料比重吸水率"
  // RMBT = 'RMBT', // "砂_亞甲基藍試驗"
  CHLORIDE_ION_CONTENT_TEST = 8, // "砂_氯離子含量試驗"
  // SAND_MOISTURE_CONTENT_TEST = 'SAND_MOISTURE_CONTENT_TEST', // "砂_總含水率試驗"

  COARSE_AGG_SIEVE_ANALYSIS = 9, // "石_粗粒料篩分析"
  COARSE_AGG_MIX_SIEVE_ANALYSIS = 10, // "石_粗粒料混合篩分析"
  COARSE_AGG_SG_WABS_ANALYSIS = 11, // "石_粗粒料比重吸水率"
  LOS_ANGELES_ABRASION_TEST = 12, // "石_洛杉磯試驗"
  // STONE_MOISTURE_CONTENT_TEST = 'STONE_MOISTURE_CONTENT_TEST', // "石_總含水率試驗"
  // OBLATENESS_TEST = 'OBLATENESS_TEST', // "石_扁平率試驗"

  // 膠結材 (粉料)
  // SLAG_SPECIFIC_SURFACE = 'SLAG_SPECIFIC_SURFACE', // "爐石_比表面積"
  // SLAG_WATER_DEMAND_TEST = 'SLAG_WATER_DEMAND_TEST', // 爐石_需水量試驗
  SLAG_ACTIVITY_TEST = 13, // 爐石_活性試驗

  // CEMENT_SPECIFIC_SURFACE = 'CEMENT_SPECIFIC_SURFACE', // 水泥_比表面積
  // CEMENT_COMP_STR_TEST = 'CEMENT_COMP_STR_TEST', // 水泥_抗壓試驗

  // FLY_ASH_SPECIFIC_SURFACE_TEST = 'FLY_ASH_SPECIFIC_SURFACE_TEST', // 飛灰_比表面積試驗
  FLY_ASH_ACTIVITY_TEST = 14, // 飛灰_活性試驗
  FLY_ASH_WATER_DEMAND_TEST = 15, // 飛灰_需水量試驗
  FLY_ASH_L_VALUE_TEST = 22, // 飛灰_L值試驗
  FLY_ASH_LOSS_ON_IGNITION_TEST = 23, // 飛灰_燒失量試驗
  // FLY_ASH_SLUMP_TEST = 'FLY_ASH_SLUMP_TEST', // 飛灰_淨漿流度試驗

  // 膠結材 (液料)
  // TEMP_CODE = 'TEMP_CODE', // 化學摻劑_減水率試驗
  // CHEM_ADMX_SOLID_CONTENT = 'CHEM_ADMX_SOLID_CONTENT', // 藥劑_固含量試驗
  // CHEM_ADMX_SPECIFIC_WEIGHT = 'CHEM_ADMX_SPECIFIC_WEIGHT', // 藥劑_比重試驗
  CHEM_ADMX_CHL_CONTENT = 25, // 藥劑_氯離子試驗
  CHEM_ADMX_PENTA_TEST = 24, // 藥劑_減水率試驗
  CHEM_ADMX_MULTI_TEST = 21, // 藥劑_六項試驗
  // CHEM_ADMX_COMP_STR = 'CHEM_ADMX_COMP_STR', // 藥劑_抗壓試驗
  MIXING_WATER_CHL_CONTENT = 27, // 拌合水_氯離子試驗
  RECLAIMED_WATER_CHL_CONTENT = 26, // 回收水_氯離子試驗
  // RECLAIMED_WATER_SPECIFIC_WEIGHT = 'RECLAIMED_WATER_SPECIFIC_WEIGHT', // 回收水_比重試驗
  // RECLAIMED_WATER_SOLID_CONTENT = 'RECLAIMED_WATER_SOLID_CONTENT', // 回收水_固含量試驗

  // 混擬土
  CONCRETE_SLUMP_TEST = 16, // 混凝土_坍度試驗
  CONCRETE_SLUMP_FLOW_TEST = 17, // 混凝土_坍流度試驗
  CONCRETE_COMP_STR_TEST = 20, // 混凝土_抗壓強度試驗
  CONCRETE_V_FUNNEL_TEST = 18, // 混凝土_V型試驗
  CONCRETE_U_BOX_TEST = 19, // 混凝土_V型試驗
}
