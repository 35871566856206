import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import { WarehouseAndStateData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import StorageYardWindowCreatePaper from './StorageYardWindowPaper/StorageYardWindowCreatePaper';

interface Props {
  selectedArea: number;
  selectedFactory: number;
  groupsSize: number;
}
export default function StorageYardCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { selectedArea, selectedFactory } = props;
  const {
    storageYardeData,
    storageYardCreateDatas,
    storageYardMappingDatas,
    setStorageYardMappingDatas,
    setStorageYardCreateDatas,
  } = useFeedTroughMaintenance();
  const mappgingData = () => {
    const mappingData = [...storageYardCreateDatas, ...storageYardeData];
    setStorageYardMappingDatas(mappingData);
  };

  const handleCreatePage = () => {
    const objectData = {
      id: Math.random(),
      typeId: 0,
      name: '',
      specificationId: 0,
      volume: 0,
      specificWeight: 0,
      state: false,
      code: '',
      factoryId: selectedFactory,
    };
    if (selectedArea !== 0 && selectedFactory !== 0) {
      const newDatas = Object.assign([], storageYardCreateDatas);
      newDatas.push(objectData);

      setStorageYardCreateDatas(newDatas);
    }
  };
  useEffect(() => {
    mappgingData();
  }, [storageYardCreateDatas, storageYardeData]);

  useEffect(() => {
    if (selectedFactory > 0) {
      setStorageYardCreateDatas([]);
    }
  }, [selectedFactory]);
  const actionBarRef = useRef<HTMLDivElement>(null);

  const [storageYardrMapGroups, setStorageYardrMapGroups] = useState<Array<Array<WarehouseAndStateData>>>([]);

  const [mixerGroupsSize, setMixerGroupsSize] = useState<number>(2);
  const getMixerMapGroup = () => {
    const storageYardMapGroupsTemp: Array<Array<WarehouseAndStateData>> = [];
    const TotalStorageYardMapGroupsTemp: Array<WarehouseAndStateData> = [...storageYardMappingDatas];
    while (TotalStorageYardMapGroupsTemp.length > 0) {
      storageYardMapGroupsTemp.push(
        TotalStorageYardMapGroupsTemp.splice(
          0,
          TotalStorageYardMapGroupsTemp.length > mixerGroupsSize
            ? mixerGroupsSize
            : TotalStorageYardMapGroupsTemp.length,
        ),
      );
    }

    setStorageYardrMapGroups(storageYardMapGroupsTemp);
  };

  useEffect(() => {
    getMixerMapGroup();
  }, [storageYardMappingDatas]);

  useEffect(() => {
    if (props.groupsSize) {
      setMixerGroupsSize(props.groupsSize);
    }
  }, [props.groupsSize]);
  return (
    <Box
      sx={{
        border: '2px solid #E9E9E9',
        p: 1,
        pt: 2,
        height: '82%',
      }}
    >
      <Stack justifyContent={'flex-end'} flexDirection="row" sx={{ mb: 0.6 }} ref={actionBarRef}>
        <YatungButton
          text={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.CREATE')}
          startIcon={<AddCircleFill />}
          onClick={handleCreatePage}
          color="green"
          disabled={selectedFactory === 0}
        />
      </Stack>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <FixedSizeList
            height={
              actionBarRef.current
                ? (height as number) - actionBarRef.current.getBoundingClientRect().height
                : (height as number)
            }
            itemData={storageYardrMapGroups}
            itemCount={storageYardrMapGroups.length}
            itemSize={560}
            width={width as number}
          >
            {StorageYardWindowCreatePaper}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  );
}
